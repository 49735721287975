import React from 'react';
import { Box, useTheme } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/lib/index.types';
import { Link } from 'react-router-dom';

import { ProfileMenuBarProps } from './ProfileMenuBar.types';
import { useStyles } from './ProfileMenuBar.styles';

export const ProfileMenuBar: React.FC<ProfileMenuBarProps> = ({ items }) => {
  const theme = useTheme<HrMeTheme>();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      <ul className={classes.list}>
        {items.map((item, index) => (
          <div className={classes.listItem} key={index}>
            <li>
              <Link to={item.link} className={classes.linkStyles}>
                {item.label}
              </Link>
            </li>
            {items.length - 1 !== index && <Box mr={4} />}
          </div>
        ))}
      </ul>
    </div>
  );
};
