import React from 'react';
import { ButtonDefault, Link } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';
import { Redirect, useHistory } from 'react-router-dom';

import { MainFlowLayout } from 'ui/layouts/mainFlowLayout/MainFlowLayout';
import { ProfilePaths } from 'routing/profile/ProfilePaths';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { MainPaths } from 'routing/main/MainPaths';
import { Topic } from 'ui/topic/Topic';

import { useStyles } from './Congratulations.styles';

export const Congratulations = (): JSX.Element => {
  const { user } = useUserContext();
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const goToCareer = () => {
    history.replace(ProfilePaths.dashboard, { openCareer: true });
  };

  const goToDashboard = () => {
    history.replace(ProfilePaths.dashboard, { openCareer: false });
  };

  if (user === undefined) {
    return <Redirect to={MainPaths.welcomeMain} />;
  }

  return (
    <MainFlowLayout topic={<Topic />} progress={{ label: 'Portfolio', length: 10, current: 10 }}>
      <div className={classes.container}>
        <Typography variant="h2" className={classes.header}>
          Gratulacje! 🎉
        </Typography>
        <Box mb={3} />
        <Typography variant="body2" className={classes.subheader}>
          Twój profil został zapisany! Teraz możesz stworzyć swoją pierwszą ścieżkę kariery.
        </Typography>
        <Box mb={2} />
        <Typography variant="body2" className={classes.subheader}>
          <strong>Jesteś zainteresowany ofertami pracy?</strong>
          <br />
          Oczekując na match z rekruterem stwórz swoją pierwszą ścieżkę kariery. Dzięki ścieżce zwiększysz swoje szanse
          w rekrutacji ucząc się bezpłatnie nowych umiejętności
        </Typography>
        <Box mb={2} />
        <Typography variant="subtitle1" className={classes.subheader}>
          *Rekruterzy kontaktują się mailowo z kandydatami, którzy najlepiej pasują do danej oferty
        </Typography>
        <Box mb={6} />
        <div className={classes.wrapper}>
          <ButtonDefault onClick={goToCareer}>Stwórz ścieżkę kariery</ButtonDefault>
          <Box mb={2} />
          <Link>
            <Typography onClick={goToDashboard}>przejdź do strony głównej</Typography>
          </Link>
        </div>
      </div>
    </MainFlowLayout>
  );
};
