import React from 'react';
import { useTheme } from '@material-ui/core';

import ArrowLeftIcon from 'assets/icon/arrow-left.svg';

import { useStyles } from './ScrollTo.styles';
import { ScrollToProps } from './ScrollTo.types';

export const ScrollTo: React.FC<ScrollToProps> = ({ index, showScroll }: ScrollToProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <a href={`#section${index}`}>
      <div style={{ opacity: `${showScroll ? 1 : 0}` }} className={classes.wrapper}>
        <img src={ArrowLeftIcon} alt="arrow_left" className={classes.arrow} />
      </div>
    </a>
  );
};
