import React, { useEffect } from 'react';

export const Facebook: React.FC = () => {
  useEffect(() => {
    window.location.href = 'https://www.facebook.com/HR-ME-272300457033469/';
  }, []);

  return (
    <div>
      <h2>Redirecting to HR-ME Facebook ...</h2>
    </div>
  );
};
