import { makeStyles } from '@material-ui/core';
import { CONTAINER_WIDTH, WRAPPER_WIDTH } from '@hrme/shared/lib/theme/theme';

export const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    width: CONTAINER_WIDTH,
    minWidth: CONTAINER_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
  },
  header: {
    width: '100%',
  },
  subheader: {
    width: '100%',
  },

  form: {
    width: WRAPPER_WIDTH,
  },
}));
