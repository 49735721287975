import { makeStyles, withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  heading: {
    fontWeight: 600,
  },
  select: {
    textAlign: 'left',
  },
  checkIcon: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const Accordion = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 3),
    backgroundColor: theme.palette.background.default,
    marginBottom: 0,
    '&:not(:last-child)': {},
    '&:before': {},
    '&$expanded': {
      padding: theme.spacing(0, 3, 3),
      marginTop: 0,
      marginBottom: 0,
    },
  },
  expanded: { margin: 0 },
}))(MuiAccordion);

export const AccordionSummary = withStyles((theme) => ({
  root: {
    padding: 0,
    borderBottom: 'none',
    marginBottom: 'none',
    minHeight: theme.spacing(7),
    '&$expanded': {
      minHeight: theme.spacing(7),
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(0, 3),
    backgroundColor: theme.palette.background.paper,
  },
}))(MuiAccordionDetails);
