import React from 'react';
import { ButtonDefault, Link, TextInput, TextSelect } from '@hrme/shared';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { MainFlowLayout } from 'ui/layouts/mainFlowLayout/MainFlowLayout';
import { Topic } from 'ui/topic/Topic';
import { checkKeyDown } from 'helpers/checkKeyDown';

import { useStyles } from './FillData.styles';
import { FillDataProps, GENDER_ITEMS } from './FillData.types';

export const FillData: React.FC<FillDataProps> = ({ methods, onFormSubmit }) => {
  const { register, control, errors, handleSubmit } = methods;

  const saveData = () => onFormSubmit(methods.getValues(), true);

  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);

  const fields = methods.watch();
  const { age, city, gender } = fields;

  return (
    <MainFlowLayout topic={<Topic />} progress={{ label: 'Dane osobowe', length: 10, current: 10 }}>
      <div className={classes.container}>
        <Typography variant="h2" className={classes.header}>
          Uzupełnij swoje dane
        </Typography>
        <Box mb={3} />
        <Typography variant="body2" className={classes.subheader}>
          Wprowadź podstawowe dane o sobie.
        </Typography>
        <Box mb={6} />
        <div className={classes.wrapper}>
          <form
            className={classes.form}
            onSubmit={handleSubmit((d) => onFormSubmit(d))}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            <TextInput
              required
              error={!!errors.city}
              ref={register}
              name="city"
              placeholder="Wpisz swoje miasto"
              label="miasto zamieszkania"
              onBlur={saveData}
            />
            <Grid container spacing={3}>
              <Grid item xs={9}>
                <Controller
                  control={control}
                  name={'gender'}
                  render={({ onChange, value, ref }) => (
                    <TextSelect
                      required
                      items={GENDER_ITEMS}
                      label="płeć"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                        saveData();
                      }}
                      ref={ref}
                      error={!!errors.city}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  required
                  error={!!errors.age}
                  ref={register}
                  type="number"
                  name="age"
                  placeholder="20"
                  label="wiek"
                  onBlur={saveData}
                  limitChars={2}
                />
              </Grid>
            </Grid>
            <Box mb={4} />
            <ButtonDefault type="submit" disabled={!(!!age && !!city && !!gender)}>
              następny krok
            </ButtonDefault>
            <Box mb={2} />
            <Link>
              <Typography onClick={() => history.goBack()}>cofnij</Typography>
            </Link>
          </form>
        </div>
      </div>
    </MainFlowLayout>
  );
};
