import { Redirect, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { AlertMessage } from '@hrme/shared';

import { useCareerPathFormDispatch } from 'hooks/useCareerPathFormDispatch/useCareerPathFormDispatch';
import { EducationState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';
import { setEducation } from 'context/careerPathForm/careerPathActionCreators/careerPathActionCreators';
import { MainPaths } from 'routing/main/MainPaths';
import { User } from 'context/user/UserContext.types';
import { useUserContext } from 'hooks/useUserContext/useUserContext';

import { Education } from './Education';

export const EducationContainer = (): JSX.Element => {
  const { user, setUserData } = useUserContext();
  const history = useHistory();
  const dispatch = useCareerPathFormDispatch();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });

  const methods = useForm<EducationState>({ defaultValues: { education: user?.education?.education } });

  if (!user) {
    return <Redirect to={MainPaths.welcomeMain} />;
  }

  const onFormSubmit = async (data: EducationState, isBack?: boolean, isIdle?: boolean) => {
    if (data.education === undefined) return;
    if (user.education === data) {
      history.push(MainPaths.languages);

      return;
    }

    const newUser: User = { ...user, education: data };

    const response = await setUserData(newUser);

    if (!response.success) setShowAlertMessage({ show: true, success: false });
    if (response.success && isIdle) return dispatch(setEducation(data));

    if (response.success) {
      dispatch(setEducation(data));
      !isBack && history.push(MainPaths.languages);
    }
  };

  return (
    <>
      <Education onFormSubmit={onFormSubmit} methods={methods} />
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} />
    </>
  );
};
