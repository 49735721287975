import { makeStyles } from '@material-ui/core';
import { CSSProperties } from 'react';

import { BoardOrigin } from './Board.types';

const backgroundColorForOrigin = (isDraggingOver: boolean, origin: BoardOrigin): string => {
  const defaultResult = isDraggingOver ? '#EDEDED' : '#F5F5F5';

  if (origin === BoardOrigin.PROFILE) {
    return isDraggingOver ? '#FFF' : '#FFF';
  }
  if (origin === BoardOrigin.MAIN) {
    return isDraggingOver ? '#F5F5F5' : '#F5F5F5';
  }

  return defaultResult;
};

export const getListStyle = (isDraggingOver: boolean, origin: BoardOrigin): CSSProperties => ({
  backgroundColor: backgroundColorForOrigin(isDraggingOver, origin),
  width: '100%',
  padding: 16,
});

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },

  rectangle: {
    display: 'block',
    height: 24,
    width: 6,
    borderRadius: 100,
  },

  input: {
    padding: theme.spacing(1, 2),
    background: '#fff',
    borderBottom: '1px solid #DADADA',
  },
  boardItem: {
    [theme.breakpoints.down('sm')]: {
      padding: '0px!important',
      marginBottom: theme.spacing(5),
    },
  },
  boardStyles: {
    border: '1px solid #DADADA',
    [theme.breakpoints.up('md')]: {
      border: 'none',
    },
  },
  droppable: {
    background: 'red',
  },
}));
