import { UseFormMethods } from 'react-hook-form';

import { FormControlLabelItem } from 'ui/inputs/radioChipGroup/RadioChipGroup.types';
import { EducationState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';

export type EducationTypes = {
  methods: UseFormMethods<EducationState>;
  onFormSubmit: (data: EducationState, isBack?: boolean, isIdle?: boolean) => Promise<void>;
};

export const EDUCATION_ITEMS: FormControlLabelItem[] = [
  { value: 'academic', label: 'wyższe' },
  { value: 'secondary', label: 'średnie' },
  { value: 'primary', label: 'podstawowe' },
  { value: 'none', label: 'brak' },
];
