import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { ButtonDefault, TextInput } from '@hrme/shared';
import { useForm } from 'react-hook-form';
import { Prompt, Redirect } from 'react-router-dom';
import { AlertMessage } from '@hrme/shared';

import { SettingsLayout } from 'ui/layouts/settingsLayout/SettingsLayout';
import { BoxWrapper } from '../components/BoxWrapper';
import { TextInputExtendableList } from 'ui/textInputExtendableList/TextInputExtendableList';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { PortfolioSocialMediaState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';
import { ProfilePaths } from 'routing/profile/ProfilePaths';
import { User } from 'context/user/UserContext.types';
import { checkKeyDown } from 'helpers/checkKeyDown';

import { PortfolioSettingsProps } from './PortfolioSettings.types';

export const PortfolioSettings: React.FC<PortfolioSettingsProps> = () => {
  const { user, setUserData } = useUserContext();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });
  const [showSavePrompt, setShowSavePrompt] = useState(false);

  const methods = useForm<PortfolioSocialMediaState>({ defaultValues: user?.portfolioSocialMedia });

  if (!user) {
    return <Redirect to={ProfilePaths.dashboard} />;
  }

  const onContentChange = async () => setShowSavePrompt(true);

  const onFormSubmit = async (data: PortfolioSocialMediaState) => {
    if (user.portfolioSocialMedia === data) {
      return;
    }

    const newUser: User = { ...user, portfolioSocialMedia: data };

    const result = await setUserData(newUser);
    setShowAlertMessage({ show: true, success: result.success });
    setShowSavePrompt(false);
  };

  return (
    <SettingsLayout>
      <BoxWrapper>
        <Prompt when={showSavePrompt} message="Twoje dane nie zostały zapisane. Czy chcesz kontynuować?" />
        <form
          onSubmit={methods.handleSubmit(onFormSubmit)}
          onKeyDown={(e) => checkKeyDown(e)}
          onChange={onContentChange}
        >
          <Typography align="left" variant="h4">
            Portfolio
          </Typography>
          <Box mt={4}>
            <TextInput ref={methods.register} placeholder="Wprowadź adres" label="github" name="github" />
            <TextInput ref={methods.register} placeholder="Wprowadź adres" label="linkedin" name="linkedin" />
            <TextInput ref={methods.register} placeholder="Wprowadź adres" label="facebook" name="facebook" />
            <TextInput ref={methods.register} placeholder="Wprowadź adres" label="instagram" name="instagram" />
            <Box mb={2} />
            <TextInputExtendableList
              methods={methods}
              label="inne portale"
              addItemLabel="dodaj kolejny link"
              name="otherLinks"
              buttonCaption="dodaj link"
              addInputPlaceholder="Wpisz link"
              listItems={user.portfolioSocialMedia?.otherLinks}
            />
            <Box mb={4} />
            <ButtonDefault>zapisz zmiany</ButtonDefault>
            <Box mb={4} />
          </Box>
        </form>
      </BoxWrapper>
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} />
    </SettingsLayout>
  );
};
