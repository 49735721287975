import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { DividerWithText } from '@hrme/shared/lib/components/ui';
import { TextInput, TextSelect } from '@hrme/shared';
import { Controller } from 'react-hook-form';

import DrawIcon from 'assets/draw-icon.svg';
import { careerPathDraw } from 'helpers/careerPathDraw/careerPathDraw';

import { SectionPathProps } from './SectionPath.types';
import { useStyles } from './SectionPath.styles';

export const SectionPath: React.FC<SectionPathProps> = ({
  methods,
  pathTypeItems,
  fullPathTypeItems,
  pathId,
  valueIndex,
}) => {
  const items = pathId === undefined ? pathTypeItems : fullPathTypeItems;
  const theme = useTheme();
  const classes = useStyles(theme);

  const { control, register, errors, setValue } = methods;

  const handleClick = () => {
    setValue('pathName', careerPathDraw());
  };
  const index = valueIndex ? (valueIndex !== -1 ? valueIndex : 0) : 0;

  return (
    <div className={classes.container}>
      <DividerWithText title={'Ścieżka kariery'} />
      <Box mb={0.75}>
        {/* 2.25 comes as bottom margin of TextSelect */}
        <Controller
          name={'pathRef'}
          control={control}
          defaultValue={items[index]?.value}
          render={({ onChange, value, ref }) => (
            <TextSelect
              label={'rodzaj ścieżki'}
              required
              items={items}
              value={value}
              onChange={(event) => onChange(event.target.value)}
              ref={ref}
              disabled={!!pathId}
            />
          )}
        />
      </Box>
      <TextInput
        name={'pathName'}
        ref={register}
        label={'nazwa ścieżki'}
        required
        placeholder={'Będę jak Elon Musk'}
        helperText={errors?.pathName?.message}
        error={!!errors?.pathName?.message}
      />
      <div className={classes.draw} onClick={handleClick}>
        <img src={DrawIcon} alt={'draw-icon'} />
        <Box mr={0.75} />
        <Typography variant="subtitle1">losuj</Typography>
      </div>
    </div>
  );
};
