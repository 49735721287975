import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';

import { CareerPathStepSummary } from 'ui/careerPath/careerSteps/careerPathStepSummary/CareerPathStepSummary';
import { CareerPathStepDetails } from '../careerPathStepDetails/CareerPathStepDetails';

import { Accordion, AccordionDetails, AccordionSummary } from './CareerPathStepAccordion.styles';
import { CareerPathStepAccordionProps } from './CareerPathStepAccordion.types';

export const CareerPathStepAccordion: React.FC<CareerPathStepAccordionProps> = ({
  expanded,
  index,
  handleChange,
  item,
  setVisible,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      {isDesktop ? (
        <Accordion
          square
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          key={index}
          id={`section${index}`}
        >
          <AccordionSummary aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
            <CareerPathStepSummary
              data={item}
              tasks={item.details.tasks}
              isExpanded={expanded === `panel${index}`}
              setVisible={setVisible}
              index={index}
            />
          </AccordionSummary>
          {expanded === `panel${index}` && isDesktop && (
            <AccordionDetails>
              <CareerPathStepDetails data={item.details} index={index} />
            </AccordionDetails>
          )}
        </Accordion>
      ) : (
        <CareerPathStepSummary
          data={item}
          tasks={item.details.tasks}
          isExpanded={expanded === `panel${index}`}
          setVisible={setVisible}
          index={index}
        />
      )}
    </>
  );
};
