import { TextSelectItem } from '@hrme/shared/lib/components/inputs/textSelect/TextSelect.types';
import { UseFormMethods } from 'react-hook-form';

import {
  AvailabilityHours,
  AvailabilityState,
  AvailabilityWork,
  AvailabilityWorkType,
} from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';

export type AvailabilityProps = {
  methods: UseFormMethods<AvailabilityState>;
  onFormSubmit: (data: AvailabilityState, isBack?: boolean, isIdle?: boolean) => Promise<void>;
};

export const AVAILABILITY_ITEMS: TextSelectItem[] = [
  { value: AvailabilityWork.ALL, label: 'Od zaraz' },
  { value: AvailabilityWork.ONEMONTH, label: 'Do miesiąca' },
  { value: AvailabilityWork.OTHER, label: 'Powyżej miesiąca' },
  { value: AvailabilityWork.NONE, label: 'Nie szukam pracy' },
];

export const AVAILABILITY_HOURS: TextSelectItem[] = [
  { value: AvailabilityHours.OVER_FOURTY, label: '40 godzin tygodniowo (pełny etat)' },
  { value: AvailabilityHours.TO_FOURTY, label: '20 - 40 godzin tygodniowo' },
  { value: AvailabilityHours.TO_TWENTY, label: '20 godzin tygodniowo (pół etatu)' },
  { value: AvailabilityHours.TO_TEN, label: 'Poniżej 20 godzin tygodniowo' },
];

export const AVAILABILITY_TYPES: TextSelectItem[] = [
  { value: AvailabilityWorkType.OFFICE, label: 'Biuro' },
  { value: AvailabilityWorkType.REMOTE, label: 'Zdalnie' },
  { value: AvailabilityWorkType.HYBRID, label: 'Hybrydowo' },
];
