import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { ButtonBoxAdd } from '@hrme/shared/lib/components/buttons';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { CareerPathBox } from 'ui/careerPath/careerPathBox/CareerPathBox';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { careerPathsConverter } from 'helpers/careerPathsConverter';
import { ProfilePaths } from 'routing/profile/ProfilePaths';
import { findBarValues } from 'helpers/findBarValues/findBarValues';
import { SKILL_LEVEL } from 'helpers/findSkillLevelByTechnologyName/findSkillLevelByTechnologyName.constants';
import { AddEditCareerPathModal } from 'ui/careerPath/addEditCareerPathModal/AddEditCareerPathModal';
import { CareerPathViewRouteParams } from 'app/careerPathView/CareerPathView.types';
import { useCareerPathContext } from 'hooks/useCareerPathContext/useCareerPathContext';
import { HandleAddNewCareerPathParams } from 'ui/boxes/boxSummary/BoxSummary.types';
import { CareerPath, UserCareerPath } from 'context/careerPath/CareerPathContext.types';

import { CareerPathsSectionProps } from './CareerPathsSection.types';
import { useStyles } from './CareerPathsSection.styles';
import { NewCareerPathContainer } from './newCareerPath/NewCareerPathContainer';

export const CareerPathsSection: React.FC<CareerPathsSectionProps> = ({ initialOpen = false }) => {
  const { user } = useUserContext();
  const [isModalOpen, setIsModalOpen] = useState(initialOpen);
  const [isNewCareerPathAdded, setIsNewCareerPathAdded] = useState(false);
  const [newCareerPathRef, setNewCareerPathRef] = useState('');

  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const { id } = useParams<CareerPathViewRouteParams>();

  const [technology, setTechnology] = useState<CareerPath[]>([]);
  const { careerPaths, fetchCareerPaths } = useCareerPathContext();

  const maxPaths = user?.careerPaths?.length === careerPaths.length;

  useEffect(() => {
    if (technology.length > 0) return;

    const fetchCareers = async () => {
      const res = await fetchCareerPaths();
      if (res.success) setTechnology(careerPaths);
    };
    fetchCareers();
  }, [technology]);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleAddNewCareerPath = (data: HandleAddNewCareerPathParams) => {
    if (data?.pathRef) setNewCareerPathRef(data.pathRef);
    setIsNewCareerPathAdded(true);
  };

  useEffect(() => {
    if (!user || !isNewCareerPathAdded) return;

    setIsNewCareerPathAdded(false);

    if (newCareerPathRef) {
      history.push(ProfilePaths.careerPathView.replace(':id', newCareerPathRef));
    }
  }, [isNewCareerPathAdded]);

  const dataToShow = useMemo(() => {
    if (!user) return;
    if (technology.length === 0) return;

    const userCareerPaths = careerPathsConverter(user?.careerPaths);

    return userCareerPaths.map((userCareerPath) => {
      const currentPath = technology.filter((item: CareerPath) => item.pathRef === userCareerPath.pathRef)[0];

      const barValues = findBarValues({ careerPath: currentPath as UserCareerPath, user });

      return {
        header: userCareerPath.pathName,
        subheader: userCareerPath.name,
        barData: [
          { label: SKILL_LEVEL.KNOWN.label.toLowerCase(), color: SKILL_LEVEL.KNOWN.color, value: barValues.KNOWN },
          { label: SKILL_LEVEL.NONE.label.toLowerCase(), color: SKILL_LEVEL.NONE.color, value: barValues.NONE },
          {
            label: SKILL_LEVEL.LEARNING.label.toLowerCase(),
            color: SKILL_LEVEL.LEARNING.color,
            value: barValues.LEARNING,
          },
          {
            label: SKILL_LEVEL.WANT_TO_LEARN.label.toLowerCase(),
            color: SKILL_LEVEL.WANT_TO_LEARN.color,
            value: barValues.WANT_TO_LEARN,
          },
        ],
        onClick: () => {
          history.push(ProfilePaths.careerPathView.replace(':id', userCareerPath.pathRef));
        },
      };
    });
  }, [user, technology]);

  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.header}>
        Twoje ścieżki kariery
      </Typography>
      <Box mb={3} />
      <div className={classes.innerContainer}>
        {/* TODO: condition to be removed later */}
        {dataToShow &&
          dataToShow.length > 0 &&
          dataToShow.map((item, index) => (
            <CareerPathBox
              key={index}
              barData={item.barData}
              header={item.header}
              subheader={`Ścieżka: ${item.subheader}`}
              onClick={item.onClick}
            />
          ))}
        {!maxPaths && <ButtonBoxAdd onClick={handleOpen}>Dodaj nową ścieżkę kariery</ButtonBoxAdd>}
      </div>

      <AddEditCareerPathModal isModalOpen={isModalOpen} onClose={handleClose}>
        {/*
        wrapping with <></> fought down this message: https://photos.app.goo.gl/b3ikxwt9Y9WX78HS6
*/}
        <>
          <NewCareerPathContainer
            isOpen={isModalOpen}
            onClose={handleClose}
            onAddNewCareerPath={handleAddNewCareerPath}
            pathId={id}
          />
        </>
      </AddEditCareerPathModal>
    </div>
  );
};
