import { Dispatch, SetStateAction } from 'react';

import { CareerPathStepTask } from 'app/careerPathView/careerPathViewPanelLeft/careerPathStepDetails/CareerPathStepDetails.types';
import { CareerPathData } from 'app/careerPathView/CareerPathView.types';

export type CareerPathStepSummaryProps = {
  data: CareerPathData;
  isExpanded: boolean;
  tasks?: CareerPathStepTask[];
  setVisible?: Dispatch<SetStateAction<{ isVisible: boolean; index: number }>>;
  index: number;
};

export type CareerPathStepSummary = {
  status: StepStatus;
  title: string;
  skillsCompleted: number | string;
  skills: number | string;
  pathName: string;
  shareOfPositions: string;
  shareOfOffers: string;
};

export enum StepStatus {
  GREEN = 0,
  ORANGE = -1,
  YELLOW = -2,
  RED = -3,
}
export enum ModalType {
  INFO = 0,
  KNOWLEDGE = 1,
  TASKS = 2,
  LEVEL = 3,
}
