import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    // padding: theme.spacing(10, 0),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  title: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 3),
    },
  },
  subtitle: {
    alignSelf: 'self-start',
    textAlign: 'left',
    maxWidth: 552,
  },
  divider: {
    backgroundColor: '#ddd',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - 2 * ${theme.spacing(3)}px)`,
    },
  },
}));
