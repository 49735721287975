import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { StatsLinear } from '@hrme/shared/lib/components/ui';

import { PathChart } from 'ui/pathChart/PathChart';

import { BoxStatsProps } from './BoxStats.types';
import { useStyles } from './BoxStats.styles';

export const BoxStats: React.FC<BoxStatsProps> = ({ data, careerPathCategory }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { popularityPerCategory } = data;

  const categoryData = popularityPerCategory.filter((element) => {
    return element.category === careerPathCategory;
  });

  const chartData = categoryData[0] ? categoryData[0] : undefined;

  const colorPalette = ['#432967', '#782D65', '#C3305D', '#F0445E', '#FD9367', '#FDCD83'].reverse();
  const numberOfSkillsToShow = 5;

  if (chartData !== undefined) {
    return (
      <div className={classes.box}>
        <Typography className={classes.boxDescriptionTitle}>{'Statystyki'}</Typography>
        <Box mb={4} />
        <Typography variant="body2" className={classes.progressTitle}>
          Popularność umiejętności w ofertach pracy
        </Typography>
        <Box mb={2} />
        <PathChart data={chartData.trends} careerPathCategory={careerPathCategory} />
        <Box mb={2} />

        <Typography variant="body2" className={classes.progressTitle}>
          Najbardziej popularne umiejętności
        </Typography>
        <Box mb={2} />
        {chartData.currentTopSkills.map((item, index) => (
          <div key={index} style={{ width: '100%' }}>
            <StatsLinear label={item.name} value={item.popularity} color={colorPalette[index]} />
            {index !== numberOfSkillsToShow - 1 && <Box mb={2} />}
          </div>
        ))}
        {/* <Box mb={4} />
        <ButtonSimple fullWidth onClick={() => console.log('GIEŁDA TECHNOLOGII clicked...')}>
          Giełda technologii
        </ButtonSimple> */}
      </div>
    );
  } else {
    return (
      <div>
        <Typography className={classes.boxDescriptionTitle}>{'Statystyki niedostępne'}</Typography>
      </div>
    );
  }
};
