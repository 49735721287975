import { ReactNode } from 'react';

import { MainPaths } from 'routing/main/MainPaths';
import { SettingsPaths } from 'routing/settings/SettingsPaths';
import { MenuBarItem } from 'ui/profileMenuBar/ProfileMenuBar.types';

export type ProfileLayoutProps = {
  children?: ReactNode;
};

export const PROFILE_MENU_ITEMS: MenuBarItem[] = [
  { label: 'Moje ścieżki', link: '/profile-dashboard' },
  { label: 'Tablica technologii', link: '/profile-technology' },
  { label: 'Oferty pracy', link: '/profile-joboffers' },
  { label: 'Zniżki na kursy', link: '/discounts-on-tutorials' },
  // { label: 'Giełda technologii', link: '' },
  // { label: 'Blog', link: MainPaths.blog },
  // { label: 'Konfiguracja', link: MainPaths.position },
  { label: 'Ustawienia', link: '/settings' + SettingsPaths.profile },
];

export const PROFILE_FOOTER_LINKS: MenuBarItem[] = [
  // { label: 'Facebook', link: MainPaths.facebook },
  { label: 'Blog', link: MainPaths.blog, target: '_blank' },
];
