import React from 'react';
import { ButtonDefault } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';

import { MainAuthLayout } from 'ui/layouts/mainAuthLayout/MainAuthLayout';

import { useStyles } from './PasswordResetConfirmation.styles';

export const PasswordResetConfirmation = (): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <MainAuthLayout>
      <Typography variant="h4" className={classes.header}>
        Twoje hasło zostało zresetowane
      </Typography>
      <Box mb={3} />
      <Typography variant="subtitle1" className={classes.subheader}>
        Możesz już zalogowac się do swojego konta podając nowe hasło.
      </Typography>
      <Box mb={4} />
      <ButtonDefault onClick={() => console.log(`ZALOGUJ button clicked`)}>zaloguj</ButtonDefault>
    </MainAuthLayout>
  );
};
