import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '200px',
  },
  legend: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    color: '#a4a4a4',
    textTransform: 'lowercase',
    margin: '16px 0 0 0',
  },
  circle: {
    margin: '0 4px 0 0 ',
    borderRadius: '50%',
    height: '10px',
    width: '10px',
    border: '1px solid',
    display: 'flex',
  },
}));
