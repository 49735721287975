import React from 'react';
import { Box, Divider, Typography, useTheme } from '@material-ui/core';

import { BoxListProps } from './BoxList.types';
import { useStyles } from './BoxList.styles';

export const BoxList: React.FC<BoxListProps> = ({ title, subtitle, content }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.box}>
      <Typography className={classes.boxDescriptionTitle}>{title}</Typography>
      <Box mb={2} />
      {subtitle && (
        <>
          <Typography>{subtitle}</Typography>
          <Box mb={2} />
        </>
      )}
      <Box mb={1} />
      {content.map((item, index) => (
        <div key={index} style={{ width: '100%' }}>
          {item}
          {index !== content.length - 1 && (
            <>
              <Box mb={2} />
              <Divider style={{ width: '100%' }} />
              <Box mb={2} />
            </>
          )}
        </div>
      ))}
    </div>
  );
};
