import { UseFormMethods } from 'react-hook-form';

import { LanguagesState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';

export type LanguagesProps = {
  methods: UseFormMethods<LanguageFormState>;
  onFormSubmit: (data: LanguageFormState, isBack?: boolean, isIdle?: boolean) => Promise<void>;
};

export type LanguageFormState = {
  language: LanguagesState;
};

export const ADD_LANGUAGE_ITEM = {
  language: '',
  level: '',
  certificate: [],
};
