import React from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { MainRoutes } from 'routing/main/MainRoutes';

import { useStyles } from './ProgressBarElement.styles';
import { ProgressBarElementProps } from './ProgressBarElement.types';

export const ProgressBarElement: React.FC<ProgressBarElementProps> = ({ completed = false, index, active }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const routeIndex = index ? index : 0;

  return (
    <div
      className={clsx({ [classes.wrapper]: true, [classes.completed]: completed, [classes.active]: active })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(completed && { onClick: () => history.push(MainRoutes[routeIndex].path) })}
    />
  );
};
