import React, { useEffect } from 'react';
import { Link, TextInput } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';

import { useStyles } from './CertificateItem.styles';
import { CertificateItemProps } from './CertificateItem.types';

export const CertificateItem: React.FC<CertificateItemProps> = ({
  certificate,
  prefix,
  index,
  methods,
  deleteItem,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { register, setValue } = methods;

  useEffect(() => {
    setValue(`${prefix}.certificate`, certificate.certificate);
    setValue(`${prefix}.company`, certificate.company);
    setValue(`${prefix}.issueDate`, certificate.issueDate);
  }, []);

  return (
    <div className={classes.container}>
      <TextInput
        name={`${prefix}.certificate`}
        label="nazwa certyfikatu"
        placeholder="Podaj nazwę certyfikatu"
        ref={register}
      />
      <TextInput
        name={`${prefix}.company`}
        label="nazwa firmy wystawiającej certyfikat"
        placeholder="Podaj nazwę firmy"
        ref={register}
      />
      <TextInput name={`${prefix}.issueDate`} label="data wydania" placeholder="DD.MM.RRRR" ref={register} />
      <Box mb={2} />
      {deleteItem && (
        <Link>
          <Typography
            onClick={() => {
              if (deleteItem) deleteItem(index);
            }}
          >
            usuń
          </Typography>
        </Link>
      )}
    </div>
  );
};
