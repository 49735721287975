import { SchoolItem } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';

export const EMPTY_EDUCATION: SchoolItem = {
  name: '',
  major: '',
  stillLearning: false,
  city: '',
  country: '',
  start: '',
  end: '',
};
