import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingBottom: '64px',
  },
  header: {
    maxWidth: 551,
    padding: theme.spacing(3, 0),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  headerLinkBack: {
    color: theme.palette.grey['800'],
  },
  title: {
    textAlign: 'left',
  },
  main: {
    width: '100%',
    display: 'grid',
    columnGap: theme.spacing(2.75),
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('md')]: {
      columnGap: theme.spacing(2.75),
      gridTemplateColumns: '643fr 456fr',
    },
  },
  arrowIcon: {
    marginRight: '8px',
  },
  divider: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey['400']}`,
  },
}));
