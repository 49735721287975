import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    textAlign: 'left',
  },
  subheader: {
    width: '100%',
    textAlign: 'left',
  },
  link: {
    fontWeight: 600,
  },
  confirmContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
    marginBottom: theme.spacing(1),
    width: 'fit-content',
    cursor: 'pointer',
  },
  confirmCheckbox: {
    padding: 0,
    marginRight: theme.spacing(1),
  },
  confirmLabel: {
    textAlign: 'left',
  },
  hyperlink: {
    fontSize: '14px',
  },
}));
