import { makeStyles } from '@material-ui/core';
import { CONTAINER_WIDTH, CONTAINER_WIDTH_TABLET } from '@hrme/shared/lib/theme/theme';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: '100vh',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    width: '100%',
    maxWidth: CONTAINER_WIDTH_TABLET,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: '1',
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('md')]: {
      maxWidth: CONTAINER_WIDTH,
    },
  },
  content: {
    width: '100%',
    flex: '1',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12, 0),
      width: 'auto',
    },
  },
  header: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4, 1, 1, 1),
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 3),
    },
  },
  headerWrapper: {
    maxWidth: 1388,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      //   display: 'flex',
      //   alignItems: 'center',
    },
  },
}));
