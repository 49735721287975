import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  innerContainer: {
    width: '100%',
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'start',
  },
  header: {
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightBold,
  },
  subheader: {
    color: theme.palette.grey['A200'],
    marginTop: theme.spacing(1),
  },
}));
