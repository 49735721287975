import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  header: {
    padding: theme.spacing(3, 0),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    cursor: 'pointer',
  },
  headerLinkBack: {
    color: theme.palette.grey['800'],
  },
  title: {},
  main: {
    display: 'grid',
    columnGap: theme.spacing(2.75),
    gridTemplateColumns: '1fr',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '360fr 744fr',
    },
  },
}));
