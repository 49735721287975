import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    minHeight: '100%',
    width: 420,
    minWidth: 420,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkIcon: {
    width: 64,
    height: 64,
    alignSelf: 'center',
  },
  header: {},
  description: {
    color: theme.palette.grey.A200,
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
  },
}));
