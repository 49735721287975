import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    textAlign: 'left',
  },
  label: {
    fontWeight: 400,
    color: theme.palette.grey[800],
  },
  subtitle: {
    fontSize: '12px',
  },
  root: {
    minHeight: 64,
    width: `calc(100% - 2 * ${theme.spacing(0.5)}px)`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(1.5, 0.5),
    margin: 0,
    backgroundColor: theme.palette.background.paper,
  },
  rootInverse: {
    backgroundColor: theme.palette.background.default,
  },
  rootNoMinHeight: {
    minHeight: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    borderRadius: 3,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
  },
  chipTiny: {
    padding: theme.spacing(0.5, 0),
  },
  chipInverse: {
    backgroundColor: theme.palette.common.white,
  },
  autocomplete: {
    width: '50%',
  },
  textField: {
    paddingLeft: theme.spacing(0.5),
  },
}));
