import { HrMeTheme } from '@hrme/shared/lib/index.types';
import { makeStyles } from '@material-ui/core';
import React from 'react';

export const useStyles = makeStyles((theme: HrMeTheme) => ({
  box: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.borders.light}`,
  },
}));

export const BoxWrapper: React.FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.box}>{children}</div>;
};
