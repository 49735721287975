/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from 'react';
import { UseFormMethods } from 'react-hook-form';

import { FormControlLabelItem } from 'ui/inputs/radioChipGroup/RadioChipGroup.types';
import { ChipData } from 'ui/inputs/tagInputExtended/TagInputExtended.types';

export type LanguageItemProps = {
  language: string;
  level: string;
  certificate: ChipData[];
  methods?: UseFormMethods<any>;
  prefix?: string;
  indexKey?: number;
  deleteItem?: () => void;
  setFieldValid?: Dispatch<SetStateAction<{ chosenLanguage: string; chosenLevel: string; fieldIndex: number }>>;
  setChips?: Dispatch<SetStateAction<ChipData[]>>;
};

export const LANGUAGES: FormControlLabelItem[] = [
  { value: 'pl', label: 'Polski' },
  { value: 'en', label: 'Angielski' },
  { value: 'cs', label: 'Czeski' },
  { value: 'es', label: 'Hiszpański' },
  { value: 'de', label: 'Niemiecki' },
  { value: 'ru', label: 'Rosyjski' },
  { value: 'uk', label: 'Ukraiński' },
];

export const LEVEL_ITEMS: FormControlLabelItem[] = [
  { value: 'basic', label: 'podstawowy' },
  { value: 'communicative', label: 'komunikatywny' },
  { value: 'fluent', label: 'biegły' },
];
