import React from 'react';
import { Box, Divider, Typography, useTheme } from '@material-ui/core';
import { ButtonProfile } from '@hrme/shared/lib/components/buttons';
import SearchIcon from '@material-ui/icons/Search';
import { CircularProgressWithLabel } from '@hrme/shared/lib/components/ui';

import { InvitationStatus } from 'context/jobOffer/JobOfferContext.types';

import { JobOfferBoxProps } from './JobOfferBox.types';
import { useStyles } from './JobOfferBox.styles';
import { JobOfferBoxDetails } from './jobOfferBoxDetails/JobOfferBoxDetails';

export const JobOfferBox: React.FC<JobOfferBoxProps> = ({ details }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { progress, title, offerStatus } = details;
  const { actionAllow, actionView, actionAbandon } = details;

  return (
    <div className={classes.container}>
      <div className={classes.topWrapper}>
        <div className={classes.topLeft}>
          <CircularProgressWithLabel value={progress} />
          <Box mr={3} />
          <Typography variant="body1" className={classes.circularLabel}>
            {title}
          </Typography>
        </div>
        <div className={classes.topRight}>
          <ButtonProfile startIcon={<SearchIcon />} variant="contained" onClick={actionView}>
            zobacz ofertę
          </ButtonProfile>
          <ButtonProfile variant="outlined" onClick={actionAllow} disabled={offerStatus === InvitationStatus.ACCEPTED}>
            {offerStatus === InvitationStatus.ACCEPTED ? 'zaakceptowano' : 'zezwól na kontakt'}
          </ButtonProfile>
          <ButtonProfile onClick={actionAbandon}>odrzuć</ButtonProfile>
        </div>
      </div>
      <Divider className={classes.divider} />
      <JobOfferBoxDetails details={details} />
    </div>
  );
};
