/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { MainPaths } from 'routing/main/MainPaths';

export const MainRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const { currentUser, authPending } = useAuthentication();
  const { user } = useUserContext();

  const canOpenProtectedRoute = authPending || (currentUser !== null && currentUser.emailVerified);
  const userDataPresent = user && user.profileLevel > 0;

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        canOpenProtectedRoute && userDataPresent ? (
          //@ts-ignore
          <Component {...routeProps} />
        ) : (
          <Redirect to={MainPaths.welcomeMain} />
        )
      }
    />
  );
};
