import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AlertMessage } from '@hrme/shared';

import { useCareerPathFormDispatch } from 'hooks/useCareerPathFormDispatch/useCareerPathFormDispatch';
import { setLanguages } from 'context/careerPathForm/careerPathActionCreators/careerPathActionCreators';
import { MainPaths } from 'routing/main/MainPaths';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { User } from 'context/user/UserContext.types';

import { Languages } from './Languages';
import { LanguageFormState } from './Languages.types';

export const LanguagesContainer = (): JSX.Element => {
  const { user, setUserData } = useUserContext();
  const history = useHistory();
  const dispatch = useCareerPathFormDispatch();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });

  const methods = useForm<LanguageFormState>({
    defaultValues: { language: user?.languages },
  });

  if (user === undefined) {
    return <Redirect to={MainPaths.welcomeMain} />;
  }

  const onFormSubmit = async (data: LanguageFormState, isBack?: boolean, isIdle?: boolean) => {
    if (data.language === user.languages) {
      history.push(MainPaths.portfolioSocialMedia);

      return;
    }
    const filteredArray = data?.language?.filter((item) => item.language || item.level !== '');

    const newUser: User = { ...user, languages: filteredArray || null };
    const response = await setUserData(newUser);

    if (!response.success) setShowAlertMessage({ show: true, success: false });
    if (response.success && isIdle) return dispatch(setLanguages(data.language));

    if (response.success) {
      dispatch(setLanguages(data.language));
      !isBack && history.push(MainPaths.portfolioSocialMedia);
    }
  };

  return (
    <>
      <Languages onFormSubmit={onFormSubmit} methods={methods} />
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} />
    </>
  );
};
