import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  topWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  status: {
    width: '16px',
    height: '16px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    marginLeft: -32,
    marginRight: 32,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  pathTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    marginRight: theme.spacing(1),
    display: 'block',
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  top: {
    width: '100%',
    height: 68,
    padding: theme.spacing(2.25, 3),
    paddingLeft: `calc(${theme.spacing(3)}px - 16px)`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.25, 2),
    },
  },
  bottom: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 600,
  },
  counterAndButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  counter: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonWrapper: {
    width: '100%',
    height: 56,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  taskButton: {
    width: '22%',
    height: 40,
    backgroundColor: theme.palette.background.default,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
