import React from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { Box, Grid } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/lib/theme/theme';

import { NavMenu } from 'ui/navMenu/NavMenu';
import { PlatformFooterMain } from 'ui/platformFooterMain/PlatformFooterMain';
import { PROFILE_FOOTER_LINKS } from '../profileLayout/ProfileLayout.types';

import { useStyles } from './SettingsLayout.styles';
import { SettingsLayoutProps } from './SettingsLayout.types';
import { SettingsLayoutAside } from './components/SettingsLayoutAside';

export const SettingsLayout: React.FC<SettingsLayoutProps> = ({ children }) => {
  const theme: HrMeTheme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.wrapper}>
      <NavMenu />
      <Box mt={4} mb={8} className={classes.siteWrapper}>
        <Box mb={4} mt={4} width="100%">
          <Typography align="left" variant="h2">
            Konfiguracja
          </Typography>
        </Box>
        <div className={classes.contentContainer}>
          <Grid container>
            <SettingsLayoutAside />
            <Grid xs={12} md={9} lg={8}>
              <main className={classes.container}>{children}</main>
            </Grid>
          </Grid>
        </div>
      </Box>
      <PlatformFooterMain items={PROFILE_FOOTER_LINKS} />
    </div>
  );
};
