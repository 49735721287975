import { HrMeTheme } from '@hrme/shared/lib/index.types';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: HrMeTheme) => ({
  linkStyles: {
    color: theme.palette.common.black,
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
