import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    borderColor: 'rgb(216, 213, 220)',
    marginTop: '48px',
    borderRadius: '8px',
    border: '1px solid',
    width: '100%',
    backgroundColor: '#fff',
  },
  imageBox: {
    width: '400px',
    borderColor: 'rgb(216, 213, 220) !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRight: 'none',
    },
  },
  image: {
    width: '324px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      padding: '20px',
    },
    margin: '0 20px',
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '32px',
  },
  discountBox: {
    backgroundColor: '#f7f1e5',
    padding: '8px',
    color: '#d2a349',
    fontWeight: 400,
    borderRadius: '8px',
    marginBottom: '10px',
  },
  name: {
    fontWeight: 'bold',
    fontSize: '22px',
    marginBottom: '24px',
  },
  description: {
    marginBottom: '16px',
    textAlign: 'justify',
  },
  autor: {
    marginBottom: '24px',
  },
  codeBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    alignItems: 'center',
  },
  code: {
    marginLeft: '8px',
    color: '#bc2869',
    padding: '15px 24px',
    '@media (max-width:470px)': {
      marginLeft: 0,
      padding: '5px 15px',
    },
    border: '1px',
    borderColor: '#bc2869',
    borderStyle: 'dashed',
    borderRadius: '50px 50px 50px 0px',
    fontWeight: 800,
  },
  link: {
    cursor: 'pointer',
    fontSize: '14px',
    color: '#FFF',
    padding: '14px 24px',
    '@media (max-width:470px)': {
      marginLeft: 0,
      padding: '4px 14px',
    },
    border: '1px',
    backgroundColor: '#151334',
    borderRadius: '50px 50px 50px 0px',
    fontWeight: 800,
    '&:hover': {
      backgroundColor: '#bc2869',
    },
  },
}));
