import React from 'react';
import { ButtonDefault } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import { MainFlowLayout } from 'ui/layouts/mainFlowLayout/MainFlowLayout';
import { Topic } from 'ui/topic/Topic';

import { ErrorNotFoundProps } from './ErrorNotFound.types';
import { useStyles } from './ErrorNotFound.styles';

export const ErrorNotFound: React.FC<ErrorNotFoundProps> = ({ message, comment, caption, handleClick }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <MainFlowLayout topic={<Topic />}>
      <div className={classes.container}>
        <NotInterestedIcon color="secondary" className={classes.checkIcon} />
        <Box mb={4} />
        <Typography variant="h2" className={classes.header}>
          {message}
        </Typography>
        <Box mb={1} />
        <Typography variant="body2" className={classes.description} align="center">
          {comment}
        </Typography>
        <Box mb={5} />
        <div className={classes.buttonContainer}>
          <ButtonDefault onClick={handleClick}>{caption}</ButtonDefault>
        </div>
      </div>
    </MainFlowLayout>
  );
};
