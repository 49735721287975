import { HrMeTheme } from '@hrme/shared/lib/theme/theme';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: HrMeTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      marginTop: 0,
    },
  },
  completed: {
    color: theme.progress.dark,
  },
}));
