import React from 'react';
import { ButtonDefault, Link, TextInput } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';

import { LoginRegisterFormContainer } from 'ui/loginRegisterFormContainer/LoginRegisterFormContainer';
import { AuthenticationPaths } from 'routing/auth/AuthenticationPaths';

import { useStyles } from './LoginForm.styles';
import { LoginFormProps } from './LoginForm.types';

export const LoginForm = ({ loading, onFormSubmit, methods }: LoginFormProps): JSX.Element => {
  const { register, errors, handleSubmit } = methods;
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <LoginRegisterFormContainer>
      <Box mb={8}>
        <Typography variant="h4" className={classes.header}>
          Zaloguj się
        </Typography>
        <Box mb={2} />
        <Typography variant="subtitle1" className={classes.subheader}>
          Wprowadź dane do logowania i przejdź dalej
        </Typography>
        <Box mb={5} />
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <TextInput
            ref={register}
            error={!!errors.email}
            helperText={errors.email && errors.email.message}
            name="email"
            placeholder="adres-email"
            label="adres-email"
          />
          <TextInput
            ref={register}
            error={!!errors.password}
            helperText={errors.password && errors.password.message}
            name="password"
            placeholder="hasło"
            label="hasło"
            type="password"
          />
          <Box mb={2} />
          <ButtonDefault loading={loading} type="submit">
            zaloguj się
          </ButtonDefault>
        </form>
        <Box mb={4} />
        <Link href={AuthenticationPaths.resetPassword}>
          <Typography variant="body2" className={classes.link}>
            Zapomniałeś hasła?
          </Typography>
        </Link>
        <Box mb={1} />
        <Typography variant="body2">
          Nie posiadasz konta?{' '}
          <Link href={AuthenticationPaths.register} className={classes.link}>
            Zarejestruj się
          </Link>{' '}
        </Typography>
      </Box>
    </LoginRegisterFormContainer>
  );
};
