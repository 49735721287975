import * as Yup from 'yup';

export type NewPasswordProps = {
  password: string;
  secondPassword: string;
};

export const newPasswordValidation = Yup.object().shape({
  password: Yup.string().min(6, 'Password must be minimum 6 characters.').required('Field required.'),
  secondPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export type EnterNewPasswordParams = {
  actionCode: string;
};
