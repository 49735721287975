import { CONTAINER_WIDTH } from '@hrme/shared/lib/theme/theme';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    maxWidth: CONTAINER_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    width: '100%',
  },
  subheader: {
    width: '100%',
  },
  wrapper: {
    width: '100%',
    maxWidth: CONTAINER_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignSelf: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));
