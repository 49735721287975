import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    listStyle: 'none',
    // [theme.breakpoints.down('lg')]: {
    //   display: 'flex',
    //   position: 'absolute',
    //   left: 0,
    //   top: '100%',
    // },
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  linkStyles: {
    color: theme.palette.common.black,
    textDecoration: 'none',
  },
}));
