/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Typography, useTheme } from '@material-ui/core';
import React from 'react';

import { useStyles, getColor, getHeader } from './BoardHeader.styles';
import { BoardHeaderProps } from './BoardHeader.types';
//@ts-ignore

export const BoardHeader = React.memo(({ index }: BoardHeaderProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      <span className={classes.rectangle} style={{ background: getColor(index) }} />
      <Box mr={2} />
      <Typography>{getHeader(index)}</Typography>
    </div>
  );
});
