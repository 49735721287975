import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));
