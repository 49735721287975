import { UseFormMethods } from 'react-hook-form';
import { TextSelectItem } from '@hrme/shared/lib/components/inputs/textSelect/TextSelect.types';

import { RegisterUserProps } from 'context/authentication/AuthenticationContext.types';

export type RegisterPageProps = {
  loading: boolean;
  methods: UseFormMethods<RegisterUserProps>;
  onFormSubmit: (data: RegisterUserProps) => Promise<void>;
  captchaError: boolean;
  setCaptchaError: (value: boolean) => void;
  captchaValue: string | null;
  setCaptchaValue: (value: string | null) => void;
  reset: boolean;
  setNewsletterAccepted: (value: boolean) => void;
  newsletterAccepted: boolean;
};

export const objectiveItems: TextSelectItem[] = [
  { value: 'rebranding', label: 'Przebranżowienie' },
  { value: 'jobChange', label: 'Zmiana pracy' },
  { value: 'improveSkills', label: 'Podniesienie kompetencji' },
  { value: 'careerPlanning', label: 'Planowanie ścieżki kariery' },
];
