import React from 'react';
import { Typography, useTheme, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';

import LogoBlack from 'assets/logo/logo-black.svg';

import { useStyles } from './PlatformHeader.styles';

export const PlatformHeader = (): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.wrapper}>
      <Link to="/" className={classes.logoWrapper}>
        <img src={LogoBlack} className={classes.logo} alt="Logo HR-ME" />
      </Link>
      <Box className={classes.logoInfo}>
        <span className={classes.divider} />
        <Typography variant="body2">Platforma rozwoju kariery</Typography>
      </Box>
    </div>
  );
};
