import { useContext } from 'react';

import { CareerPathDispatchContextType } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';
import { CareerPathFormDispatchContext } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext';

type UseFormDispatch = () => CareerPathDispatchContextType;

export const useCareerPathFormDispatch: UseFormDispatch = () => {
  const context = useContext(CareerPathFormDispatchContext);

  if (context === undefined) {
    throw new Error('useFormDispatch must be used within an CareerPathFormContextController');
  }

  return context;
};
