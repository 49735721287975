import React, { useEffect, useState } from 'react';
import { Box, InputLabel, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { TextInput, TextSelect } from '@hrme/shared';
import { TextSelectVariant } from '@hrme/shared/lib/components/inputs/textSelect/TextSelect.types';

import { ButtonOther } from 'ui/buttonOther/ButtonOther';
import { TextInputWithClose } from 'ui/textInputWithClose/TextInputWithClose';

import { TextInputExtendableListProps, TextInputVariant } from './TextInputExtendableList.types';
import { useStyles } from './TextInputExtendableList.styles';

// TODO: this component will need some refactoring as it is not using "normal" way of injecting react-hook-form.
export const TextInputExtendableList: React.FC<TextInputExtendableListProps> = ({
  label,
  buttonCaption,
  addInputLabel,
  addInputPlaceholder,
  variant = TextInputVariant.White,
  selectItems,
  name = '',
  methods,
  listItems = [],
  addItemLabel,
  onBlur,
  setTriggerSave,
}) => {
  const hrMeTheme = useTheme();
  const classes = useStyles(hrMeTheme);

  const listContainerStyle = clsx({
    [classes.listContainer]: true,
    [classes.listContainerGrey]: variant === TextInputVariant.Grey,
  });

  const [items, setItems] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [inputValueError, setInputValueError] = useState(false);
  const [inputValueHelperText, setInputValueHelperText] = useState('');
  const [selectValue, setSelectValue] = useState('');
  const [selectValueError, setSelectValueError] = useState(false);

  const handleAddButtonClick = () => {
    if (selectItems && !selectValue) {
      setSelectValueError(true);

      return;
    }
    if (!inputValue) {
      setInputValueError(true);
      setInputValueHelperText('adres nie może być pusty');

      return;
    }

    const newValue = selectValue ? `${selectValue} - ${inputValue}` : `${inputValue}`;

    const elementExists = items.find((el) => el.toLowerCase() === newValue.toLowerCase());

    if (elementExists === undefined) {
      setItems([...items, newValue]);
    }

    setSelectValue('');
    setInputValue('');
  };

  useEffect(() => {
    methods?.setValue('otherLinks', [...items, inputValue]);
  }, [inputValue]);

  useEffect(() => {
    methods?.setValue(name, items);
  }, [items, methods, name]);

  useEffect(() => {
    methods?.register(name);
    setItems(listItems);
  }, []);

  const addWithEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') handleAddButtonClick();
  };

  return (
    <>
      <div className={listContainerStyle}>
        <InputLabel className={classes.label}>{label}</InputLabel>
        {items.map((item, key) => {
          return (
            <TextInputWithClose
              key={key}
              value={item}
              onClick={() => {
                setItems(items.filter((element) => element !== item));
                if (setTriggerSave) setTriggerSave(Math.random());
              }}
              variant={variant}
            />
          );
        })}
      </div>
      <div className={classes.container} onKeyDown={(e) => addWithEnter(e)}>
        <Box mb={2} />
        {addItemLabel && <InputLabel className={classes.label}>{addItemLabel}</InputLabel>}
        <div className={classes.inputContainer}>
          {selectItems && (
            <TextSelect
              items={selectItems}
              label="metodologia"
              wideOrNarrow={TextSelectVariant.NARROW}
              value={selectValue}
              onChange={(event) => {
                setSelectValueError(false);
                setSelectValue(event.target.value as string);
              }}
              error={selectValueError}
            />
          )}
          <TextInput
            onBlur={onBlur}
            label={addInputLabel}
            placeholder={addInputPlaceholder}
            error={inputValueError}
            helperText={inputValueHelperText}
            value={inputValue}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setInputValueError(false);
              setInputValueHelperText('');
              setInputValue(event.target.value);
            }}
          />
        </div>
        <ButtonOther disabled={!inputValue} onClick={handleAddButtonClick}>
          {buttonCaption}
        </ButtonOther>
      </div>
    </>
  );
};
