/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@material-ui/core';

import { CircularProgressWithLabelProps } from './CircularProgressWithLabel.types';
import { useStyles } from './CircularProgressWithLabel.styles';

export const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { value, fontSize = 18 } = props;

  return (
    <>
      <Box position="relative" display="inline-flex" style={{ marginLeft: 'auto' }}>
        <CircularProgress variant="determinate" className={classes.circular} size={'2rem'} {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography component="div" style={{ fontSize }} className={classes.label}>{`${Math.round(
            value,
          )}`}</Typography>
        </Box>
      </Box>
    </>
  );
};
