import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import React from 'react';

import { ConfirmationModalProps } from './ConfirmationModal.types';

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ modalOpen, setModalOpen, handleDelete }) => {
  const handleClose = () => setModalOpen(false);
  const handleConfirm = () => {
    setModalOpen(false);
    if (handleDelete) handleDelete();
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Potwierdź usunięcie ścieżki'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Czy jesteś pewnien, że chcesz usunąć tę ścieżkę kariery?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          anuluj
        </Button>
        <Button color="primary" onClick={handleConfirm}>
          potwierdź
        </Button>
      </DialogActions>
    </Dialog>
  );
};
