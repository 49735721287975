import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  header: {
    width: '100%',
    textAlign: 'left',
  },
  subheader: {
    width: '100%',
    textAlign: 'left',
  },
  link: {
    fontWeight: 600,
  },
}));
