import { UseFormMethods } from 'react-hook-form';
import { TextSelectItem } from '@hrme/shared/lib/components/inputs/textSelect/TextSelect.types';

import { FillState, Gender } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';

export type FillDataProps = {
  methods: UseFormMethods<FillState>;
  onFormSubmit: (data: FillState, isIdle?: boolean) => void;
};

export const GENDER_ITEMS: TextSelectItem[] = [
  { value: Gender.MALE, label: 'Mężczyzna' },
  { value: Gender.FEMALE, label: 'Kobieta' },
  { value: Gender.OTHER, label: 'Inne' },
  { value: Gender.NONE, label: 'Nie chcę podawać odpowiedzi' },
];
