import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import { BoxDescriptionProps } from './BoxDescription.types';
import { useStyles } from './BoxDescription.styles';

export const BoxDescription: React.FC<BoxDescriptionProps> = ({ title, subtitle, content }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.box}>
      <Typography className={classes.boxDescriptionTitle}>{title}</Typography>
      <Box mb={2} />
      {subtitle && (
        <>
          <Typography>{subtitle}</Typography>
          <Box mb={2} />
        </>
      )}
      <Typography variant="body2" className={classes.boxDescriptionContent}>
        {content}
      </Typography>
    </div>
  );
};
