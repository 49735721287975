import React, { useEffect } from 'react';
import { ButtonDefault } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import { Redirect, useHistory } from 'react-router-dom';

import { MainFlowLayout } from 'ui/layouts/mainFlowLayout/MainFlowLayout';
import { MainPaths } from 'routing/main/MainPaths';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { ProfilePaths } from 'routing/profile/ProfilePaths';
import { Topic } from 'ui/topic/Topic';
import { Loader } from 'ui/loader/Loader';

import { useStyles } from './WelcomeMain.styles';

export const WelcomeMain = (): JSX.Element => {
  const { user, loading, fetchUserData } = useUserContext();
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const goToSpecialization = async () => {
    history.push(MainPaths.position);
  };

  useEffect(() => {
    const initUser = async () => {
      await fetchUserData();
    };

    if (!user) {
      initUser();
    }
  }, [fetchUserData, user]);

  if (user && user.profileLevel > 0) {
    return <Redirect to={ProfilePaths.dashboard} />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <MainFlowLayout topic={<Topic />}>
      <div className={classes.container}>
        <Typography variant="h2" className={classes.header}>
          Witaj w hr-me
        </Typography>
        <Box mb={3} />
        <Typography variant="body2" className={classes.subheader}>
          {`Wprowadź podstawowe dane o sobie.`}
        </Typography>
        <Box mb={4.25} />
        <div className={classes.bullets}>
          <div style={{ display: 'inherit' }}>
            <LocalOfferIcon />
            <Box mr={1.75} />
            <Typography variant="body2">Za darmo</Typography>
          </div>
          <Box mr={6.25} />
          <div style={{ display: 'inherit' }}>
            <AlarmOnIcon />
            <Box mr={1.75} />
            <Typography variant="body2">5 minut</Typography>
          </div>
        </div>
        <Box mb={4.25} />
        <ButtonDefault onClick={goToSpecialization}>zaczynamy!</ButtonDefault>
      </div>
    </MainFlowLayout>
  );
};
