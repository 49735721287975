import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import CheckIcon from 'assets/icon/check.svg';

import { useStyles } from './LabelAccepted.styles';

export const LabelAccepted = (): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      <img src={CheckIcon} alt="check-icon" />
      <Box mr={0.5} />
      <Typography variant="subtitle1" className={classes.label}>
        zaakceptowano ofertę
      </Typography>
    </div>
  );
};
