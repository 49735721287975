import React, { useEffect } from 'react';

import { Loader } from 'ui/loader/Loader';

export const Blog: React.FC = () => {
  useEffect(() => {
    window.location.href = 'https://hr-me.co/blog/';
  }, []);

  return (
    <div
      style={{
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Loader />
    </div>
  );
};
