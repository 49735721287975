export const skillPopularityPerCategory = [
  { item: 'Git', category: 'all', popularity: '30' },
  { item: 'JavaScript', category: 'all', popularity: '25' },
  { item: 'Docker', category: 'all', popularity: '24' },
  { item: 'Java', category: 'all', popularity: '24' },
  { item: 'SQL', category: 'all', popularity: '22' },
  { item: 'REST', category: 'all', popularity: '20' },
  { item: 'Python', category: 'all', popularity: '20' },
  { item: 'React', category: 'all', popularity: '18' },
  { item: 'AWS', category: 'all', popularity: '18' },
  { item: 'TypeScript', category: 'all', popularity: '15' },
  { item: 'CSS', category: 'all', popularity: '14' },
  { item: 'Linux', category: 'all', popularity: '13' },
  { item: 'HTML', category: 'all', popularity: '13' },
  { item: 'Spring', category: 'all', popularity: '13' },
  { item: 'Angular', category: 'all', popularity: '12' },
  { item: 'Kubernetes', category: 'all', popularity: '11' },
  { item: 'JIRA', category: 'all', popularity: '11' },
  { item: 'Azure', category: 'all', popularity: '11' },
  { item: '.NET', category: 'all', popularity: '10' },
  { item: 'Leadership skills', category: 'all', popularity: '9.3' },
  { item: 'Jenkins', category: 'all', popularity: '8.9' },
  { item: 'Node.js', category: 'all', popularity: '8.2' },
  { item: 'PostgreSQL', category: 'all', popularity: '7.7' },
  { item: 'C#', category: 'all', popularity: '6.3' },
  { item: 'PHP', category: 'all', popularity: '5.9' },
  { item: 'MySQL', category: 'all', popularity: '5.6' },
  { item: 'CI/CD', category: 'all', popularity: '5.3' },
  { item: 'Scrum', category: 'all', popularity: '5.2' },
  { item: 'Terraform', category: 'all', popularity: '5.1' },
  { item: 'Vue.js', category: 'all', popularity: '4.9' },
  { item: 'Hibernate', category: 'all', popularity: '4.7' },
  { item: 'Microservices', category: 'all', popularity: '4.5' },
  { item: 'Selenium', category: 'all', popularity: '4.5' },
  { item: 'Agile', category: 'all', popularity: '4.3' },
  { item: 'Kotlin', category: 'all', popularity: '4.3' },
  { item: 'Ansible', category: 'all', popularity: '4.1' },
  { item: 'Android', category: 'all', popularity: '4.1' },
  { item: 'Kafka', category: 'all', popularity: '4.0' },
  { item: 'MongoDB', category: 'all', popularity: '3.9' },
  { item: 'iOS', category: 'all', popularity: '3.8' },
  { item: 'NoSQL', category: 'all', popularity: '3.7' },
  { item: 'Maven', category: 'all', popularity: '3.7' },
  { item: 'Redux', category: 'all', popularity: '3.6' },
  { item: 'Confluence', category: 'all', popularity: '3.6' },
  { item: 'C++', category: 'all', popularity: '3.5' },
  { item: 'Elasticsearch', category: 'all', popularity: '3.5' },
  { item: 'Business analysis', category: 'all', popularity: '3.1' },
  { item: 'Redis', category: 'all', popularity: '3.1' },
  { item: 'MS SQL', category: 'all', popularity: '3.0' },
  { item: 'JUnit', category: 'all', popularity: '2.9' },
  { item: '.NET Core', category: 'all', popularity: '2.7' },
  { item: 'Scala', category: 'all', popularity: '2.7' },
  { item: 'Django', category: 'all', popularity: '2.6' },
  { item: 'webpack', category: 'all', popularity: '2.6' },
  { item: 'DevOps', category: 'all', popularity: '2.6' },
  { item: 'GraphQL', category: 'all', popularity: '2.6' },
  { item: 'GCP', category: 'all', popularity: '2.6' },
  { item: 'Spark', category: 'all', popularity: '2.6' },
  { item: 'Postman', category: 'all', popularity: '2.5' },
  { item: 'UX', category: 'all', popularity: '2.5' },
  { item: 'RabbitMQ', category: 'all', popularity: '2.4' },
  { item: 'Sass', category: 'all', popularity: '2.4' },
  { item: 'Project management', category: 'all', popularity: '2.4' },
  { item: 'Oracle', category: 'all', popularity: '2.4' },
  { item: 'Symfony', category: 'all', popularity: '2.3' },
  { item: 'TDD', category: 'all', popularity: '2.3' },
  { item: 'Entity Framework', category: 'all', popularity: '2.3' },
  { item: 'Bash', category: 'all', popularity: '2.2' },
  { item: 'GitLab', category: 'all', popularity: '2.2' },
  { item: 'ETL', category: 'all', popularity: '2.2' },
  { item: 'Security', category: 'all', popularity: '2.1' },
  { item: 'jQuery', category: 'all', popularity: '2.1' },
  { item: 'Unit Testing', category: 'all', popularity: '2.1' },
  { item: 'Swift', category: 'all', popularity: '2.0' },
  { item: 'SQL Server', category: 'all', popularity: '2.0' },
  { item: 'Machine Learning', category: 'all', popularity: '2.0' },
  { item: 'German', category: 'all', popularity: '1.9' },
  { item: 'React Native', category: 'all', popularity: '1.8' },
  { item: 'JSON', category: 'all', popularity: '1.8' },
  { item: 'Visual Studio', category: 'all', popularity: '1.8' },
  { item: 'Golang', category: 'all', popularity: '1.8' },
  { item: 'API', category: 'all', popularity: '1.7' },
  { item: 'Cloud', category: 'all', popularity: '1.6' },
  { item: 'MVVM', category: 'all', popularity: '1.6' },
  { item: 'UI', category: 'all', popularity: '1.6' },
  { item: 'Cypress', category: 'all', popularity: '1.6' },
  { item: 'Windows', category: 'all', popularity: '1.6' },
  { item: 'Azure DevOps', category: 'all', popularity: '1.6' },
  { item: 'Flask', category: 'all', popularity: '1.5' },
  { item: 'JPA', category: 'all', popularity: '1.5' },
  { item: 'Shell', category: 'all', popularity: '1.5' },
  { item: 'Gradle', category: 'all', popularity: '1.5' },
  { item: 'Ruby on Rails', category: 'all', popularity: '1.5' },
  { item: 'Hadoop', category: 'all', popularity: '1.5' },
  { item: 'RxJS', category: 'all', popularity: '1.5' },
  { item: 'Figma', category: 'all', popularity: '1.4' },
  { item: 'Design Patterns', category: 'all', popularity: '1.4' },
  { item: 'UML', category: 'all', popularity: '1.4' },
  { item: 'OOP', category: 'all', popularity: '1.4' },
  { item: 'MVC', category: 'all', popularity: '1.4' },
  { item: 'C', category: 'all', popularity: '1.3' },
  { item: 'SOAP', category: 'all', popularity: '1.3' },
  { item: 'Power BI', category: 'all', popularity: '1.3' },
  { item: 'Test Automation', category: 'all', popularity: '1.3' },
  { item: 'Product Management', category: 'all', popularity: '1.3' },
  { item: 'Databases', category: 'all', popularity: '1.3' },
  { item: 'Testing', category: 'all', popularity: '1.2' },
  { item: 'Powershell', category: 'all', popularity: '1.2' },
  { item: 'DDD', category: 'all', popularity: '1.2' },
  { item: 'SOLID', category: 'all', popularity: '1.2' },
  { item: 'Ruby', category: 'all', popularity: '1.2' },
  { item: 'Team management', category: 'all', popularity: '1.2' },
  { item: 'Backlog management', category: 'all', popularity: '1.2' },
  { item: 'web services', category: 'all', popularity: '1.2' },
  {
    item: 'Continuous Integration',
    category: 'all',
    popularity: '1.1',
  },
  { item: 'Virtualization', category: 'all', popularity: '1.1' },
  { item: 'Google Cloud Platform', category: 'all', popularity: '1.1' },
  { item: 'Puppet', category: 'all', popularity: '1.1' },
  { item: 'Microsoft', category: 'all', popularity: '1.1' },
  { item: 'BPMN', category: 'all', popularity: '1.1' },
  { item: 'Web API', category: 'all', popularity: '1.1' },
  { item: 'RxJava', category: 'all', popularity: '1.1' },
  { item: 'Bootstrap', category: 'all', popularity: '1.0' },
  { item: 'Sketch', category: 'all', popularity: '1.0' },
  { item: 'Data Engineering', category: 'all', popularity: '1.0' },
  { item: 'Jest', category: 'all', popularity: '1.0' },
  { item: 'Clean Code', category: 'all', popularity: '0.97' },
  { item: 'Windows Server', category: 'all', popularity: '0.97' },
  { item: 'Objective-C', category: 'all', popularity: '0.97' },
  { item: 'Java EE', category: 'all', popularity: '0.97' },
  { item: 'SoapUI', category: 'all', popularity: '0.97' },
  { item: 'Salesforce', category: 'all', popularity: '0.97' },
  { item: 'Airflow', category: 'all', popularity: '0.97' },
  { item: 'Laravel', category: 'all', popularity: '0.93' },
  { item: 'HTTP', category: 'all', popularity: '0.93' },
  { item: 'System analysis', category: 'all', popularity: '0.93' },
  { item: 'Nginx', category: 'all', popularity: '0.90' },
  { item: 'Less', category: 'all', popularity: '0.90' },
  { item: 'Grafana', category: 'all', popularity: '0.90' },
  { item: 'Groovy', category: 'all', popularity: '0.90' },
  { item: 'Defining priorities', category: 'all', popularity: '0.90' },
  {
    item: 'Personal organization',
    category: 'all',
    popularity: '0.90',
  },
  {
    item: 'Cooperation with stakeholders',
    category: 'all',
    popularity: '0.90',
  },
  { item: 'XML', category: 'all', popularity: '0.87' },
  { item: 'Firebase', category: 'all', popularity: '0.87' },
  { item: 'ASP.NET', category: 'all', popularity: '0.84' },
  { item: 'Kanban', category: 'all', popularity: '0.84' },
  { item: 'Active Directory', category: 'all', popularity: '0.84' },
  { item: 'Prometheus', category: 'all', popularity: '0.84' },
  { item: 'Excel', category: 'all', popularity: '0.84' },
  { item: 'Apache', category: 'all', popularity: '0.81' },
  { item: 'Cassandra', category: 'all', popularity: '0.77' },
  { item: 'Celery', category: 'all', popularity: '0.77' },
  { item: 'Express.js', category: 'all', popularity: '0.74' },
  { item: 'Cucumber', category: 'all', popularity: '0.74' },
  { item: 'Flutter', category: 'all', popularity: '0.74' },
  { item: 'Big Data', category: 'all', popularity: '0.71' },
  { item: 'OpenShift', category: 'all', popularity: '0.71' },
  { item: 'WCF', category: 'all', popularity: '0.71' },
  { item: 'WPF', category: 'all', popularity: '0.71' },
  { item: 'TensorFlow', category: 'all', popularity: '0.71' },
  { item: 'Risk management', category: 'all', popularity: '0.71' },
  { item: 'Pandas', category: 'all', popularity: '0.71' },
  { item: 'Visual Design', category: 'all', popularity: '0.71' },
  { item: 'Product vision', category: 'all', popularity: '0.71' },
  { item: 'CI', category: 'all', popularity: '0.68' },
  { item: 'ISTQB', category: 'all', popularity: '0.68' },
  { item: 'TCP/IP', category: 'all', popularity: '0.68' },
  { item: 'Mobile', category: 'all', popularity: '0.68' },
  { item: 'Spock', category: 'all', popularity: '0.68' },
  { item: 'min B2 English', category: 'all', popularity: '0.68' },
  { item: 'Unix', category: 'all', popularity: '0.64' },
  { item: 'VMware', category: 'all', popularity: '0.64' },
  { item: 'SAP', category: 'all', popularity: '0.64' },
  { item: 'Prototyping', category: 'all', popularity: '0.64' },
  { item: 'Goal-oriented', category: 'all', popularity: '0.64' },
  { item: 'Photoshop', category: 'all', popularity: '0.64' },
  { item: 'Go', category: 'all', popularity: '0.61' },
  { item: 'Analytical Thinking', category: 'all', popularity: '0.61' },
  { item: 'Xcode', category: 'all', popularity: '0.61' },
  { item: 'Bitbucket', category: 'all', popularity: '0.61' },
  { item: 'R', category: 'all', popularity: '0.61' },
  { item: 'Next.js', category: 'all', popularity: '0.61' },
  { item: 'MS Office', category: 'all', popularity: '0.61' },
  { item: 'Mockito', category: 'all', popularity: '0.61' },
  {
    item: 'Defining business requirements',
    category: 'all',
    popularity: '0.61',
  },
  { item: 'Magento', category: 'all', popularity: '0.58' },
  { item: 'Tableau', category: 'all', popularity: '0.58' },
  { item: 'SVN', category: 'all', popularity: '0.58' },
  { item: 'ASP.NET Core', category: 'all', popularity: '0.58' },
  { item: 'Retrofit', category: 'all', popularity: '0.58' },
  { item: 'NgRx', category: 'all', popularity: '0.58' },
  {
    item: 'Building relationships',
    category: 'all',
    popularity: '0.58',
  },
  { item: 'ASP.NET MVC', category: 'all', popularity: '0.55' },
  { item: 'Adobe XD', category: 'all', popularity: '0.55' },
  { item: 'Wireframing', category: 'all', popularity: '0.55' },
  { item: 'Swagger', category: 'all', popularity: '0.55' },
  { item: 'Dagger', category: 'all', popularity: '0.55' },
  { item: 'CocoaPods', category: 'all', popularity: '0.55' },
  {
    item: 'Organizational skills',
    category: 'all',
    popularity: '0.55',
  },
  { item: 'Enterprise Architect', category: 'all', popularity: '0.52' },
  { item: 'GitHub', category: 'all', popularity: '0.52' },
  { item: 'JMeter', category: 'all', popularity: '0.52' },
  { item: 'Distributed systems', category: 'all', popularity: '0.52' },
  { item: 'PyTorch', category: 'all', popularity: '0.52' },
  { item: 'PHPUnit', category: 'all', popularity: '0.52' },
  { item: 'Jasmine', category: 'all', popularity: '0.52' },
  { item: 'Scikit-learn', category: 'all', popularity: '0.52' },
  { item: 'CosmosDB', category: 'all', popularity: '0.52' },
  { item: 'ITIL', category: 'all', popularity: '0.48' },
  { item: 'Networking', category: 'all', popularity: '0.48' },
  { item: 'gulp', category: 'all', popularity: '0.48' },
  { item: 'Nest.js', category: 'all', popularity: '0.48' },
  { item: 'UI Design', category: 'all', popularity: '0.48' },
  { item: 'ECMAScript', category: 'all', popularity: '0.48' },
  { item: 'RxSwift', category: 'all', popularity: '0.48' },
  { item: 'Snowflake', category: 'all', popularity: '0.48' },
  { item: 'Appium', category: 'all', popularity: '0.48' },
  { item: 'Akka', category: 'all', popularity: '0.48' },
  { item: 'Embedded', category: 'all', popularity: '0.48' },
  { item: 'Illustrator', category: 'all', popularity: '0.48' },
  { item: 'SCSS', category: 'all', popularity: '0.45' },
  { item: 'Kibana', category: 'all', popularity: '0.45' },
  { item: 'E-commerce', category: 'all', popularity: '0.45' },
  { item: 'Helm', category: 'all', popularity: '0.45' },
  { item: 'CRM', category: 'all', popularity: '0.45' },
  { item: 'Mac OS', category: 'all', popularity: '0.45' },
  { item: 'pytest', category: 'all', popularity: '0.45' },
  {
    item: 'Recruitment experience',
    category: 'all',
    popularity: '0.45',
  },
  { item: 'BDD', category: 'all', popularity: '0.42' },
  { item: 'RWD', category: 'all', popularity: '0.42' },
  { item: 'Google Analytics', category: 'all', popularity: '0.42' },
  { item: 'Tomcat', category: 'all', popularity: '0.42' },
  { item: 'SSIS', category: 'all', popularity: '0.42' },
  { item: 'Karma', category: 'all', popularity: '0.42' },
  { item: 'Negotiation skills', category: 'all', popularity: '0.42' },
  { item: 'TeamCity', category: 'all', popularity: '0.42' },
  { item: 'fastlane', category: 'all', popularity: '0.42' },
  { item: 'PySpark', category: 'all', popularity: '0.42' },
  { item: 'Creativity', category: 'all', popularity: '0.42' },
  { item: 'B2B sales', category: 'all', popularity: '0.42' },
  { item: 'Sourcing', category: 'all', popularity: '0.42' },
  { item: 'IaC', category: 'all', popularity: '0.42' },
  { item: 'Manual Testing', category: 'all', popularity: '0.39' },
  { item: 'Relational Databases', category: 'all', popularity: '0.39' },
  { item: 'JVM', category: 'all', popularity: '0.39' },
  { item: 'CD', category: 'all', popularity: '0.39' },
  { item: 'SDLC', category: 'all', popularity: '0.39' },
  { item: 'Continuous Delivery', category: 'all', popularity: '0.39' },
  { item: 'Apex', category: 'all', popularity: '0.39' },
  { item: 'Dart', category: 'all', popularity: '0.39' },
  { item: 'Presentation skills', category: 'all', popularity: '0.39' },
  { item: 'Spring Cloud', category: 'all', popularity: '0.39' },
  { item: 'BigQuery', category: 'all', popularity: '0.39' },
  { item: 'WordPress', category: 'all', popularity: '0.35' },
  { item: 'ES6', category: 'all', popularity: '0.35' },
  { item: 'Attention to detail', category: 'all', popularity: '0.35' },
  { item: 'Market analytics', category: 'all', popularity: '0.35' },
  { item: 'Databricks', category: 'all', popularity: '0.35' },
  { item: 'Flexibility', category: 'all', popularity: '0.35' },
  { item: 'T-SQL', category: 'all', popularity: '0.32' },
  { item: 'InVision', category: 'all', popularity: '0.32' },
  { item: 'API Testing', category: 'all', popularity: '0.32' },
  { item: 'JBoss', category: 'all', popularity: '0.32' },
  { item: 'RSpec', category: 'all', popularity: '0.32' },
  { item: 'MVP', category: 'all', popularity: '0.32' },
  { item: 'CQRS', category: 'all', popularity: '0.32' },
  { item: 'Zend', category: 'all', popularity: '0.32' },
  { item: 'PWA', category: 'all', popularity: '0.32' },
  { item: 'Mongo', category: 'all', popularity: '0.32' },
  { item: 'SEO', category: 'all', popularity: '0.32' },
  { item: 'CloudFormation', category: 'all', popularity: '0.32' },
  { item: 'Robot Framework', category: 'all', popularity: '0.32' },
  { item: 'design thinking', category: 'all', popularity: '0.32' },
  { item: 'TestRail', category: 'all', popularity: '0.32' },
  { item: 'Espresso', category: 'all', popularity: '0.32' },
  { item: 'CMake', category: 'all', popularity: '0.32' },
  { item: 'DWH', category: 'all', popularity: '0.32' },
  { item: 'CISSP', category: 'all', popularity: '0.32' },
  { item: 'Storybook', category: 'all', popularity: '0.32' },
  {
    item: 'Machine learning experience',
    category: 'all',
    popularity: '0.32',
  },
  { item: 'SaaS', category: 'all', popularity: '0.29' },
  { item: 'Microsoft Azure', category: 'all', popularity: '0.29' },
  { item: 'DynamoDB', category: 'all', popularity: '0.29' },
  { item: 'Xamarin', category: 'all', popularity: '0.29' },
  { item: 'JMS', category: 'all', popularity: '0.29' },
  { item: 'NHibernate', category: 'all', popularity: '0.29' },
  { item: 'Sales experience', category: 'all', popularity: '0.29' },
  { item: 'OpenCV', category: 'all', popularity: '0.29' },
  { item: 'Ajax', category: 'all', popularity: '0.29' },
  { item: 'JSP', category: 'all', popularity: '0.29' },
  { item: 'Computer Science', category: 'all', popularity: '0.29' },
  { item: 'Coroutines', category: 'all', popularity: '0.29' },
  { item: 'JS', category: 'all', popularity: '0.26' },
  { item: 'Android Studio', category: 'all', popularity: '0.26' },
  { item: 'Blockchain', category: 'all', popularity: '0.26' },
  { item: 'MariaDB', category: 'all', popularity: '0.26' },
  { item: 'Bamboo', category: 'all', popularity: '0.26' },
  { item: 'ServiceNow', category: 'all', popularity: '0.26' },
  {
    item: 'Functional Programming',
    category: 'all',
    popularity: '0.26',
  },
  { item: 'Networks', category: 'all', popularity: '0.26' },
  { item: 'NumPy', category: 'all', popularity: '0.26' },
  { item: 'Cisco', category: 'all', popularity: '0.26' },
  { item: 'Zeplin', category: 'all', popularity: '0.26' },
  { item: 'IoT', category: 'all', popularity: '0.26' },
  { item: 'ELK', category: 'all', popularity: '0.26' },
  { item: 'Nagios', category: 'all', popularity: '0.26' },
  { item: 'Axure', category: 'all', popularity: '0.26' },
  { item: 'Azure Data Factory', category: 'all', popularity: '0.26' },
  { item: 'Network Protocols', category: 'all', popularity: '0.26' },
  { item: 'Lambda', category: 'all', popularity: '0.26' },
  { item: 'Babel', category: 'all', popularity: '0.26' },
  { item: 'WebSockets', category: 'all', popularity: '0.26' },
  { item: 'Performance testing', category: 'all', popularity: '0.26' },
  { item: 'Ionic', category: 'all', popularity: '0.26' },
  { item: 'Data Warehouse', category: 'all', popularity: '0.26' },
  { item: 'HR experience', category: 'all', popularity: '0.26' },
  { item: 'REST APIs', category: 'all', popularity: '0.26' },
  { item: 'Redux-saga', category: 'all', popularity: '0.26' },
  { item: 'LWC', category: 'all', popularity: '0.26' },
  {
    item: 'good understanding of agile development',
    category: 'all',
    popularity: '0.26',
  },
  {
    item: 'At least 3 years of commercial experience',
    category: 'all',
    popularity: '0.26',
  },
  { item: 'MS SQL Server', category: 'all', popularity: '0.23' },
  { item: 'Code Review', category: 'all', popularity: '0.23' },
  { item: 'ERP', category: 'all', popularity: '0.23' },
  { item: 'Perl', category: 'all', popularity: '0.23' },
  { item: 'SharePoint', category: 'all', popularity: '0.23' },
  { item: 'RDBMS', category: 'all', popularity: '0.23' },
  { item: 'Protractor', category: 'all', popularity: '0.23' },
  { item: 'Data Science', category: 'all', popularity: '0.23' },
  { item: 'DNS', category: 'all', popularity: '0.23' },
  { item: 'SOA', category: 'all', popularity: '0.23' },
  { item: 'OpenStack', category: 'all', popularity: '0.23' },
  { item: 'Data modeling', category: 'all', popularity: '0.23' },
  { item: 'GitFLow', category: 'all', popularity: '0.23' },
  { item: 'DAX', category: 'all', popularity: '0.23' },
  { item: 'SSRS', category: 'all', popularity: '0.23' },
  { item: 'HAProxy', category: 'all', popularity: '0.23' },
  { item: 'Splunk', category: 'all', popularity: '0.23' },
  { item: 'SIEM', category: 'all', popularity: '0.23' },
  { item: 'OpenAPI', category: 'all', popularity: '0.23' },
  { item: 'Routing', category: 'all', popularity: '0.23' },
  { item: 'Deals closing', category: 'all', popularity: '0.23' },
  { item: 'SAS', category: 'all', popularity: '0.23' },
  { item: 'STL', category: 'all', popularity: '0.23' },
  { item: 'Sales skills', category: 'all', popularity: '0.23' },
  { item: 'K8s', category: 'all', popularity: '0.23' },
  { item: 'CircleCI', category: 'all', popularity: '0.23' },
  { item: 'xUnit', category: 'all', popularity: '0.23' },
  { item: 'EC2', category: 'all', popularity: '0.23' },
  { item: 'Boost', category: 'all', popularity: '0.23' },
  { item: 'Slack', category: 'all', popularity: '0.23' },
  { item: 'Clean Architecture', category: 'all', popularity: '0.23' },
  { item: 'CEH', category: 'all', popularity: '0.23' },
  { item: 'Cats', category: 'all', popularity: '0.23' },
  { item: 'Autosar', category: 'all', popularity: '0.23' },
  { item: 'API Gateway', category: 'all', popularity: '0.23' },
  { item: 'kubeflow', category: 'all', popularity: '0.23' },
  { item: 'Min B2 English level', category: 'all', popularity: '0.23' },
  {
    item: 'Software Architecture',
    category: 'all',
    popularity: '0.19',
  },
  { item: '.Net Framework', category: 'all', popularity: '0.19' },
  { item: 'Hive', category: 'all', popularity: '0.19' },
  { item: 'ORM', category: 'all', popularity: '0.19' },
  { item: 'Algorithms', category: 'all', popularity: '0.19' },
  { item: 'Unity', category: 'all', popularity: '0.19' },
  { item: 'TFS', category: 'all', popularity: '0.19' },
  { item: 'Ubuntu', category: 'all', popularity: '0.19' },
  { item: 'Keras', category: 'all', popularity: '0.19' },
  { item: 'VBA', category: 'all', popularity: '0.19' },
  { item: 'NUnit', category: 'all', popularity: '0.19' },
  { item: 'SSAS', category: 'all', popularity: '0.19' },
  { item: 'SonarQube', category: 'all', popularity: '0.19' },
  { item: 'IDS', category: 'all', popularity: '0.19' },
  { item: 'IPS', category: 'all', popularity: '0.19' },
  { item: 'Digital Marketing', category: 'all', popularity: '0.19' },
  { item: 'Express', category: 'all', popularity: '0.19' },
  { item: 'integration tests', category: 'all', popularity: '0.19' },
  { item: 'FastAPI', category: 'all', popularity: '0.19' },
  { item: 'Cloud Services', category: 'all', popularity: '0.19' },
  { item: 'GitLab CI', category: 'all', popularity: '0.19' },
  { item: 'Koin', category: 'all', popularity: '0.19' },
  { item: 'Kotlin Coroutines', category: 'all', popularity: '0.19' },
  { item: 'Client service', category: 'all', popularity: '0.19' },
  { item: 'WAF', category: 'all', popularity: '0.19' },
  { item: 'Marketing campaigns', category: 'all', popularity: '0.19' },
  { item: 'IntelliJ IDEA', category: 'all', popularity: '0.19' },
  { item: 'Automotive', category: 'all', popularity: '0.19' },
  {
    item: 'Open-source contributor',
    category: 'all',
    popularity: '0.19',
  },
  { item: 'Pet-project', category: 'all', popularity: '0.19' },
  { item: 'Play', category: 'all', popularity: '0.19' },
  { item: 'Sentry', category: 'all', popularity: '0.19' },
  { item: 'Automated tests', category: 'all', popularity: '0.19' },
  { item: 'Haskell', category: 'all', popularity: '0.19' },
  { item: 'ZIO', category: 'all', popularity: '0.19' },
  { item: 'CCSP', category: 'all', popularity: '0.19' },
  { item: 'web design', category: 'all', popularity: '0.19' },
  {
    item: 'Degree in Computer Science',
    category: 'all',
    popularity: '0.19',
  },
  { item: 'English - C1', category: 'all', popularity: '0.19' },
  { item: 'Collaboration', category: 'all', popularity: '0.19' },
  { item: 'Software Development', category: 'all', popularity: '0.16' },
  { item: 'SAFe', category: 'all', popularity: '0.16' },
  { item: 'Architecture', category: 'all', popularity: '0.16' },
  { item: 'Data analysis', category: 'all', popularity: '0.16' },
  { item: 'Zabbix', category: 'all', popularity: '0.16' },
  { item: 'Gerrit', category: 'all', popularity: '0.16' },
  { item: 'Shell Scripting', category: 'all', popularity: '0.16' },
  { item: 'CMS', category: 'all', popularity: '0.16' },
  { item: 'UIKit', category: 'all', popularity: '0.16' },
  { item: 'Gherkin', category: 'all', popularity: '0.16' },
  { item: 'TestNG', category: 'all', popularity: '0.16' },
  { item: 'WinForms', category: 'all', popularity: '0.16' },
  {
    item: 'Financial business domain',
    category: 'all',
    popularity: '0.16',
  },
  { item: 'Network', category: 'all', popularity: '0.16' },
  { item: 'RTOS', category: 'all', popularity: '0.16' },
  { item: 'Flink', category: 'all', popularity: '0.16' },
  { item: 'MS Azure', category: 'all', popularity: '0.16' },
  { item: 'Employer Branding', category: 'all', popularity: '0.16' },
  { item: 'SwiftUI', category: 'all', popularity: '0.16' },
  { item: 'Styled Components', category: 'all', popularity: '0.16' },
  { item: 'Budget management', category: 'all', popularity: '0.16' },
  { item: 'Redshift', category: 'all', popularity: '0.16' },
  { item: 'self-motivated', category: 'all', popularity: '0.16' },
  { item: 'automation', category: 'all', popularity: '0.16' },
  { item: 'Blazor', category: 'all', popularity: '0.16' },
  { item: 'O365', category: 'all', popularity: '0.16' },
  { item: 'ATS', category: 'all', popularity: '0.16' },
  { item: 'UI testing', category: 'all', popularity: '0.16' },
  { item: 'TestLink', category: 'all', popularity: '0.16' },
  { item: 'Unreal Engine', category: 'all', popularity: '0.16' },
  { item: 'Product design', category: 'all', popularity: '0.16' },
  { item: 'RESTful APIs', category: 'all', popularity: '0.16' },
  { item: 'TSQL', category: 'all', popularity: '0.16' },
  { item: 'CloudWatch', category: 'all', popularity: '0.16' },
  { item: 'Puppeteer', category: 'all', popularity: '0.16' },
  { item: 'Data modelling', category: 'all', popularity: '0.16' },
  { item: 'Personal blog', category: 'all', popularity: '0.16' },
  { item: 'GitHub Actions', category: 'all', popularity: '0.16' },
  { item: 'SignalR', category: 'all', popularity: '0.16' },
  { item: 'CISA', category: 'all', popularity: '0.16' },
  { item: 'Hazelcast', category: 'all', popularity: '0.16' },
  { item: 'Willingness to learn', category: 'all', popularity: '0.16' },
  { item: 'Attention to details', category: 'all', popularity: '0.16' },
  { item: 'Scalability', category: 'all', popularity: '0.16' },
  { item: 'GatsbyJS', category: 'all', popularity: '0.16' },
  { item: 'Multitasking', category: 'all', popularity: '0.16' },
  {
    item: 'Theoretical Computer Science degree',
    category: 'all',
    popularity: '0.16',
  },
  { item: 'XCUITest', category: 'all', popularity: '0.16' },
  { item: 'Mathematics', category: 'all', popularity: '0.16' },
  { item: 'Google BigQuery', category: 'all', popularity: '0.16' },
  { item: 'matplotlib', category: 'all', popularity: '0.16' },
  { item: 'Atlassian ecosystem', category: 'all', popularity: '0.16' },
  {
    item: 'Strong programming skills',
    category: 'all',
    popularity: '0.16',
  },
  {
    item: 'experience in a consulting company',
    category: 'all',
    popularity: '0.16',
  },
  { item: 'AWS RDS', category: 'all', popularity: '0.16' },
  { item: 'Web Applications', category: 'all', popularity: '0.13' },
  { item: 'Elixir', category: 'all', popularity: '0.13' },
  { item: 'Drupal', category: 'all', popularity: '0.13' },
  { item: 'AI', category: 'all', popularity: '0.13' },
  { item: 'NPM', category: 'all', popularity: '0.13' },
  { item: 'IntelliJ', category: 'all', popularity: '0.13' },
  { item: 'LAN', category: 'all', popularity: '0.13' },
  { item: 'Programming', category: 'all', popularity: '0.13' },
  { item: 'Red Hat', category: 'all', popularity: '0.13' },
  { item: 'Heroku', category: 'all', popularity: '0.13' },
  {
    item: 'Business Intelligence',
    category: 'all',
    popularity: '0.13',
  },
  {
    item: 'Django Rest Framework',
    category: 'all',
    popularity: '0.13',
  },
  { item: 'WebSocket', category: 'all', popularity: '0.13' },
  { item: 'Qt', category: 'all', popularity: '0.13' },
  { item: 'Composer', category: 'all', popularity: '0.13' },
  { item: 'Eclipse', category: 'all', popularity: '0.13' },
  { item: 'WooCommerce', category: 'all', popularity: '0.13' },
  { item: 'NLP', category: 'all', popularity: '0.13' },
  { item: 'Dagger 2', category: 'all', popularity: '0.13' },
  { item: 'Atlassian', category: 'all', popularity: '0.13' },
  { item: 'ES6+', category: 'all', popularity: '0.13' },
  { item: 'SQLAlchemy', category: 'all', popularity: '0.13' },
  { item: 'IIS', category: 'all', popularity: '0.13' },
  { item: 'Mocha', category: 'all', popularity: '0.13' },
  { item: 'Vagrant', category: 'all', popularity: '0.13' },
  { item: 'OAuth', category: 'all', popularity: '0.13' },
  { item: 'WebLogic', category: 'all', popularity: '0.13' },
  { item: 'AWS Lambda', category: 'all', popularity: '0.13' },
  { item: 'Communication', category: 'all', popularity: '0.13' },
  { item: 'QlikView', category: 'all', popularity: '0.13' },
  { item: 'Yocto', category: 'all', popularity: '0.13' },
  { item: 'OWASP', category: 'all', popularity: '0.13' },
  { item: 'Facilitation', category: 'all', popularity: '0.13' },
  { item: 'Varnish', category: 'all', popularity: '0.13' },
  { item: 'KVM', category: 'all', popularity: '0.13' },
  { item: 'ELK Stack', category: 'all', popularity: '0.13' },
  { item: 'Computer Vision', category: 'all', popularity: '0.13' },
  { item: 'Nexus', category: 'all', popularity: '0.13' },
  { item: 'Miro', category: 'all', popularity: '0.13' },
  { item: 'DHCP', category: 'all', popularity: '0.13' },
  { item: 'RPA', category: 'all', popularity: '0.13' },
  { item: 'ActiveMQ', category: 'all', popularity: '0.13' },
  { item: 'OpenGL', category: 'all', popularity: '0.13' },
  { item: 'Event Sourcing', category: 'all', popularity: '0.13' },
  { item: 'Spring Framework', category: 'all', popularity: '0.13' },
  { item: 'Docker Compose', category: 'all', popularity: '0.13' },
  { item: 'Docker', category: 'DevOps', popularity: '63' },
  { item: 'AWS', category: 'DevOps', popularity: '59' },
  { item: 'Linux', category: 'DevOps', popularity: '55' },
  { item: 'Kubernetes', category: 'DevOps', popularity: '52' },
  { item: 'Python', category: 'DevOps', popularity: '50' },
  { item: 'Terraform', category: 'DevOps', popularity: '47' },
  { item: 'Git', category: 'DevOps', popularity: '38' },
  { item: 'Jenkins', category: 'DevOps', popularity: '35' },
  { item: 'Ansible', category: 'DevOps', popularity: '35' },
  { item: 'Azure', category: 'DevOps', popularity: '31' },
  { item: 'CI/CD', category: 'DevOps', popularity: '19' },
  { item: 'Java', category: 'DevOps', popularity: '14' },
  { item: 'Shell', category: 'DevOps', popularity: '14' },
  { item: 'Bash', category: 'DevOps', popularity: '13' },
  { item: 'GCP', category: 'DevOps', popularity: '11' },
  { item: 'Puppet', category: 'DevOps', popularity: '11' },
  { item: 'Virtualization', category: 'DevOps', popularity: '10' },
  { item: 'PostgreSQL', category: 'DevOps', popularity: '9.7' },
  { item: 'Golang', category: 'DevOps', popularity: '8.4' },
  { item: 'Nginx', category: 'DevOps', popularity: '8.4' },
  { item: '.NET', category: 'DevOps', popularity: '7.9' },
  { item: 'Prometheus', category: 'DevOps', popularity: '7.9' },
  { item: 'DevOps', category: 'DevOps', popularity: '7.5' },
  { item: 'GitLab', category: 'DevOps', popularity: '7.0' },
  { item: 'SQL', category: 'DevOps', popularity: '6.6' },
  { item: 'Kafka', category: 'DevOps', popularity: '6.6' },
  { item: 'Grafana', category: 'DevOps', popularity: '6.6' },
  { item: 'MySQL', category: 'DevOps', popularity: '5.7' },
  { item: 'Helm', category: 'DevOps', popularity: '5.3' },
  { item: 'JIRA', category: 'DevOps', popularity: '4.8' },
  { item: 'Apache', category: 'DevOps', popularity: '4.8' },
  { item: 'Agile', category: 'DevOps', popularity: '4.4' },
  { item: 'Leadership skills', category: 'DevOps', popularity: '4.4' },
  { item: 'Elasticsearch', category: 'DevOps', popularity: '4.4' },
  { item: 'MongoDB', category: 'DevOps', popularity: '4.4' },
  { item: 'Powershell', category: 'DevOps', popularity: '4.0' },
  { item: 'CloudFormation', category: 'DevOps', popularity: '4.0' },
  { item: 'JavaScript', category: 'DevOps', popularity: '3.5' },
  { item: 'C#', category: 'DevOps', popularity: '3.5' },
  { item: 'Maven', category: 'DevOps', popularity: '3.5' },
  { item: 'Redis', category: 'DevOps', popularity: '3.5' },
  { item: 'Cloud', category: 'DevOps', popularity: '3.5' },
  { item: 'Oracle', category: 'DevOps', popularity: '3.5' },
  {
    item: 'Continuous Integration',
    category: 'DevOps',
    popularity: '3.5',
  },
  { item: 'OpenShift', category: 'DevOps', popularity: '3.5' },
  { item: 'Confluence', category: 'DevOps', popularity: '3.1' },
  { item: 'Node.js', category: 'DevOps', popularity: '2.6' },
  { item: 'C++', category: 'DevOps', popularity: '2.6' },
  { item: 'Windows Server', category: 'DevOps', popularity: '2.6' },
  {
    item: 'Google Cloud Platform',
    category: 'DevOps',
    popularity: '2.6',
  },
  { item: 'Cassandra', category: 'DevOps', popularity: '2.6' },
  { item: 'Networking', category: 'DevOps', popularity: '2.6' },
  { item: 'Kibana', category: 'DevOps', popularity: '2.6' },
  { item: 'ELK', category: 'DevOps', popularity: '2.6' },
  { item: 'Nagios', category: 'DevOps', popularity: '2.6' },
  { item: 'Lambda', category: 'DevOps', popularity: '2.6' },
  { item: 'IaC', category: 'DevOps', popularity: '2.6' },
  { item: 'Microservices', category: 'DevOps', popularity: '2.2' },
  { item: 'Windows', category: 'DevOps', popularity: '2.2' },
  { item: 'Azure DevOps', category: 'DevOps', popularity: '2.2' },
  { item: 'Hadoop', category: 'DevOps', popularity: '2.2' },
  { item: 'Go', category: 'DevOps', popularity: '2.2' },
  { item: 'Databases', category: 'DevOps', popularity: '2.2' },
  { item: 'Groovy', category: 'DevOps', popularity: '2.2' },
  { item: 'Tomcat', category: 'DevOps', popularity: '2.2' },
  { item: 'EC2', category: 'DevOps', popularity: '2.2' },
  { item: 'CloudWatch', category: 'DevOps', popularity: '2.2' },
  { item: 'REST', category: 'DevOps', popularity: '1.8' },
  { item: 'PHP', category: 'DevOps', popularity: '1.8' },
  { item: 'Scrum', category: 'DevOps', popularity: '1.8' },
  { item: 'Spark', category: 'DevOps', popularity: '1.8' },
  { item: 'Ruby', category: 'DevOps', popularity: '1.8' },
  { item: 'Unix', category: 'DevOps', popularity: '1.8' },
  { item: 'VMware', category: 'DevOps', popularity: '1.8' },
  { item: 'HAProxy', category: 'DevOps', popularity: '1.8' },
  { item: 'CircleCI', category: 'DevOps', popularity: '1.8' },
  { item: 'Nexus', category: 'DevOps', popularity: '1.8' },
  { item: 'min B2 English', category: 'DevOps', popularity: '1.8' },
  { item: 'AWS CloudFormation', category: 'DevOps', popularity: '1.8' },
  { item: 'TypeScript', category: 'DevOps', popularity: '1.3' },
  { item: 'NoSQL', category: 'DevOps', popularity: '1.3' },
  { item: 'MS SQL', category: 'DevOps', popularity: '1.3' },
  { item: 'Machine Learning', category: 'DevOps', popularity: '1.3' },
  { item: 'JSON', category: 'DevOps', popularity: '1.3' },
  { item: 'Gradle', category: 'DevOps', popularity: '1.3' },
  { item: 'GitHub', category: 'DevOps', popularity: '1.3' },
  { item: 'Bitbucket', category: 'DevOps', popularity: '1.3' },
  { item: 'E-commerce', category: 'DevOps', popularity: '1.3' },
  { item: 'DynamoDB', category: 'DevOps', popularity: '1.3' },
  { item: 'OpenStack', category: 'DevOps', popularity: '1.3' },
  { item: 'BigQuery', category: 'DevOps', popularity: '1.3' },
  { item: 'GitOps', category: 'DevOps', popularity: '1.3' },
  { item: 'API Gateway', category: 'DevOps', popularity: '1.3' },
  { item: 'AD', category: 'DevOps', popularity: '1.3' },
  { item: 'RDS', category: 'DevOps', popularity: '1.3' },
  { item: 'VPC', category: 'DevOps', popularity: '1.3' },
  { item: 'YAML', category: 'DevOps', popularity: '1.3' },
  { item: 'NIFI', category: 'DevOps', popularity: '1.3' },
  {
    item: 'Machine learning experience',
    category: 'DevOps',
    popularity: '1.3',
  },
  { item: 'Rancher', category: 'DevOps', popularity: '1.3' },
  { item: 'Selenium', category: 'QA/Testing', popularity: '44' },
  { item: 'JIRA', category: 'QA/Testing', popularity: '42' },
  { item: 'Java', category: 'QA/Testing', popularity: '37' },
  { item: 'SQL', category: 'QA/Testing', popularity: '35' },
  { item: 'Python', category: 'QA/Testing', popularity: '28' },
  { item: 'REST', category: 'QA/Testing', popularity: '27' },
  { item: 'Postman', category: 'QA/Testing', popularity: '27' },
  { item: 'JavaScript', category: 'QA/Testing', popularity: '26' },
  { item: 'Git', category: 'QA/Testing', popularity: '24' },
  { item: 'Jenkins', category: 'QA/Testing', popularity: '20' },
  { item: 'Docker', category: 'QA/Testing', popularity: '15' },
  { item: 'Linux', category: 'QA/Testing', popularity: '12' },
  { item: 'Cypress', category: 'QA/Testing', popularity: '11' },
  { item: 'Test Automation', category: 'QA/Testing', popularity: '10' },
  { item: 'C#', category: 'QA/Testing', popularity: '9.9' },
  { item: 'SoapUI', category: 'QA/Testing', popularity: '9.6' },
  { item: '.NET', category: 'QA/Testing', popularity: '9.2' },
  { item: 'Agile', category: 'QA/Testing', popularity: '8.5' },
  { item: 'Scrum', category: 'QA/Testing', popularity: '7.4' },
  { item: 'ISTQB', category: 'QA/Testing', popularity: '7.4' },
  { item: 'CI/CD', category: 'QA/Testing', popularity: '7.1' },
  { item: 'Testing', category: 'QA/Testing', popularity: '7.1' },
  {
    item: 'Leadership skills',
    category: 'QA/Testing',
    popularity: '6.7',
  },
  { item: 'API', category: 'QA/Testing', popularity: '6.7' },
  { item: 'Confluence', category: 'QA/Testing', popularity: '5.7' },
  { item: 'Cucumber', category: 'QA/Testing', popularity: '5.3' },
  { item: 'JMeter', category: 'QA/Testing', popularity: '5.3' },
  { item: 'Bash', category: 'QA/Testing', popularity: '5.0' },
  { item: 'SOAP', category: 'QA/Testing', popularity: '5.0' },
  { item: 'Appium', category: 'QA/Testing', popularity: '5.0' },
  { item: 'AWS', category: 'QA/Testing', popularity: '4.6' },
  { item: 'HTML', category: 'QA/Testing', popularity: '4.3' },
  { item: 'BDD', category: 'QA/Testing', popularity: '4.3' },
  { item: 'TypeScript', category: 'QA/Testing', popularity: '3.9' },
  { item: 'Manual Testing', category: 'QA/Testing', popularity: '3.9' },
  { item: 'Azure', category: 'QA/Testing', popularity: '3.5' },
  { item: 'Maven', category: 'QA/Testing', popularity: '3.5' },
  { item: 'API Testing', category: 'QA/Testing', popularity: '3.5' },
  { item: 'TestRail', category: 'QA/Testing', popularity: '3.5' },
  { item: 'Spring', category: 'QA/Testing', popularity: '3.2' },
  { item: 'JUnit', category: 'QA/Testing', popularity: '3.2' },
  { item: 'Azure DevOps', category: 'QA/Testing', popularity: '3.2' },
  { item: 'CSS', category: 'QA/Testing', popularity: '2.8' },
  { item: 'Kubernetes', category: 'QA/Testing', popularity: '2.8' },
  { item: 'JSON', category: 'QA/Testing', popularity: '2.8' },
  { item: 'web services', category: 'QA/Testing', popularity: '2.8' },
  { item: 'Android', category: 'QA/Testing', popularity: '2.5' },
  { item: 'GitLab', category: 'QA/Testing', popularity: '2.5' },
  { item: 'pytest', category: 'QA/Testing', popularity: '2.5' },
  {
    item: 'Robot Framework',
    category: 'QA/Testing',
    popularity: '2.5',
  },
  {
    item: 'Performance testing',
    category: 'QA/Testing',
    popularity: '2.5',
  },
  { item: 'React', category: 'QA/Testing', popularity: '2.1' },
  { item: 'PHP', category: 'QA/Testing', popularity: '2.1' },
  { item: 'iOS', category: 'QA/Testing', popularity: '2.1' },
  { item: 'Oracle', category: 'QA/Testing', popularity: '2.1' },
  {
    item: 'Continuous Integration',
    category: 'QA/Testing',
    popularity: '2.1',
  },
  { item: 'Shell', category: 'QA/Testing', popularity: '2.1' },
  { item: 'CI', category: 'QA/Testing', popularity: '2.1' },
  { item: 'Mobile', category: 'QA/Testing', popularity: '2.1' },
  { item: 'TeamCity', category: 'QA/Testing', popularity: '2.1' },
  { item: 'Protractor', category: 'QA/Testing', popularity: '2.1' },
  { item: 'Embedded', category: 'QA/Testing', popularity: '2.1' },
  { item: 'Microservices', category: 'QA/Testing', popularity: '1.8' },
  { item: 'Unit Testing', category: 'QA/Testing', popularity: '1.8' },
  { item: 'Gherkin', category: 'QA/Testing', popularity: '1.8' },
  {
    item: 'integration tests',
    category: 'QA/Testing',
    popularity: '1.8',
  },
  { item: 'TestLink', category: 'QA/Testing', popularity: '1.8' },
  {
    item: 'Automated tests',
    category: 'QA/Testing',
    popularity: '1.8',
  },
  { item: 'XCUITest', category: 'QA/Testing', popularity: '1.8' },
  { item: 'Angular', category: 'QA/Testing', popularity: '1.4' },
  { item: 'PostgreSQL', category: 'QA/Testing', popularity: '1.4' },
  { item: 'MS SQL', category: 'QA/Testing', popularity: '1.4' },
  { item: 'Swift', category: 'QA/Testing', popularity: '1.4' },
  { item: 'Powershell', category: 'QA/Testing', popularity: '1.4' },
  { item: 'Hadoop', category: 'QA/Testing', popularity: '1.4' },
  { item: 'Jest', category: 'QA/Testing', popularity: '1.4' },
  { item: 'XML', category: 'QA/Testing', popularity: '1.4' },
  { item: 'Salesforce', category: 'QA/Testing', popularity: '1.4' },
  {
    item: 'Analytical Thinking',
    category: 'QA/Testing',
    popularity: '1.4',
  },
  { item: 'SAP', category: 'QA/Testing', popularity: '1.4' },
  { item: 'TestNG', category: 'QA/Testing', popularity: '1.4' },
  { item: 'Espresso', category: 'QA/Testing', popularity: '1.4' },
  { item: 'test case', category: 'QA/Testing', popularity: '1.4' },
  { item: 'SpecFlow', category: 'QA/Testing', popularity: '1.4' },
  { item: 'WebDriver', category: 'QA/Testing', popularity: '1.4' },
  { item: 'Node.js', category: 'QA/Testing', popularity: '1.1' },
  { item: 'Kotlin', category: 'QA/Testing', popularity: '1.1' },
  { item: 'DevOps', category: 'QA/Testing', popularity: '1.1' },
  { item: 'GraphQL', category: 'QA/Testing', popularity: '1.1' },
  { item: 'TDD', category: 'QA/Testing', popularity: '1.1' },
  { item: 'OOP', category: 'QA/Testing', popularity: '1.1' },
  { item: 'Visual Studio', category: 'QA/Testing', popularity: '1.1' },
  { item: 'German', category: 'QA/Testing', popularity: '1.1' },
  { item: 'HTTP', category: 'QA/Testing', popularity: '1.1' },
  { item: 'Groovy', category: 'QA/Testing', popularity: '1.1' },
  { item: 'Excel', category: 'QA/Testing', popularity: '1.1' },
  { item: 'CD', category: 'QA/Testing', popularity: '1.1' },
  { item: 'SDLC', category: 'QA/Testing', popularity: '1.1' },
  {
    item: 'Continuous Delivery',
    category: 'QA/Testing',
    popularity: '1.1',
  },
  { item: 'Swagger', category: 'QA/Testing', popularity: '1.1' },
  { item: 'JavaScript', category: 'Fullstack', popularity: '55' },
  { item: 'React', category: 'Fullstack', popularity: '46' },
  { item: 'Angular', category: 'Fullstack', popularity: '38' },
  { item: 'Git', category: 'Fullstack', popularity: '35' },
  { item: 'Java', category: 'Fullstack', popularity: '35' },
  { item: 'CSS', category: 'Fullstack', popularity: '33' },
  { item: 'HTML', category: 'Fullstack', popularity: '32' },
  { item: 'TypeScript', category: 'Fullstack', popularity: '31' },
  { item: 'Docker', category: 'Fullstack', popularity: '30' },
  { item: '.NET', category: 'Fullstack', popularity: '29' },
  { item: 'REST', category: 'Fullstack', popularity: '28' },
  { item: 'SQL', category: 'Fullstack', popularity: '27' },
  { item: 'Spring', category: 'Fullstack', popularity: '25' },
  { item: 'Node.js', category: 'Fullstack', popularity: '19' },
  { item: 'AWS', category: 'Fullstack', popularity: '19' },
  { item: 'Python', category: 'Fullstack', popularity: '18' },
  { item: 'Azure', category: 'Fullstack', popularity: '14' },
  { item: 'C#', category: 'Fullstack', popularity: '14' },
  { item: 'PHP', category: 'Fullstack', popularity: '13' },
  { item: 'MySQL', category: 'Fullstack', popularity: '12' },
  { item: 'PostgreSQL', category: 'Fullstack', popularity: '11' },
  { item: 'Vue.js', category: 'Fullstack', popularity: '11' },
  {
    item: 'Leadership skills',
    category: 'Fullstack',
    popularity: '10',
  },
  { item: 'Hibernate', category: 'Fullstack', popularity: '9.7' },
  { item: 'Kubernetes', category: 'Fullstack', popularity: '9.2' },
  {
    item: 'Entity Framework',
    category: 'Fullstack',
    popularity: '9.2',
  },
  { item: 'Linux', category: 'Fullstack', popularity: '8.7' },
  { item: '.NET Core', category: 'Fullstack', popularity: '8.4' },
  { item: 'MongoDB', category: 'Fullstack', popularity: '7.1' },
  { item: 'MS SQL', category: 'Fullstack', popularity: '6.8' },
  { item: 'jQuery', category: 'Fullstack', popularity: '6.8' },
  { item: 'JIRA', category: 'Fullstack', popularity: '6.6' },
  { item: 'Visual Studio', category: 'Fullstack', popularity: '5.8' },
  { item: 'Redux', category: 'Fullstack', popularity: '5.2' },
  { item: 'MVC', category: 'Fullstack', popularity: '5.2' },
  { item: 'Microservices', category: 'Fullstack', popularity: '5.0' },
  { item: 'Elasticsearch', category: 'Fullstack', popularity: '5.0' },
  { item: 'Django', category: 'Fullstack', popularity: '5.0' },
  { item: 'Jenkins', category: 'Fullstack', popularity: '4.7' },
  { item: 'Maven', category: 'Fullstack', popularity: '4.5' },
  { item: 'Kotlin', category: 'Fullstack', popularity: '3.9' },
  { item: 'NoSQL', category: 'Fullstack', popularity: '3.9' },
  { item: 'JUnit', category: 'Fullstack', popularity: '3.9' },
  { item: 'Laravel', category: 'Fullstack', popularity: '3.9' },
  { item: 'Ruby on Rails', category: 'Fullstack', popularity: '3.9' },
  { item: 'Flask', category: 'Fullstack', popularity: '3.9' },
  { item: 'CI/CD', category: 'Fullstack', popularity: '3.7' },
  { item: 'GitLab', category: 'Fullstack', popularity: '3.7' },
  { item: 'Agile', category: 'Fullstack', popularity: '3.4' },
  { item: 'GraphQL', category: 'Fullstack', popularity: '3.4' },
  { item: 'Scrum', category: 'Fullstack', popularity: '3.1' },
  { item: 'Symfony', category: 'Fullstack', popularity: '3.1' },
  { item: 'TDD', category: 'Fullstack', popularity: '3.1' },
  { item: 'SQL Server', category: 'Fullstack', popularity: '3.1' },
  { item: 'Bootstrap', category: 'Fullstack', popularity: '3.1' },
  { item: 'Redis', category: 'Fullstack', popularity: '2.9' },
  { item: 'RabbitMQ', category: 'Fullstack', popularity: '2.9' },
  { item: 'JPA', category: 'Fullstack', popularity: '2.9' },
  { item: 'Kafka', category: 'Fullstack', popularity: '2.6' },
  { item: 'SOAP', category: 'Fullstack', popularity: '2.4' },
  { item: 'Ruby', category: 'Fullstack', popularity: '2.4' },
  { item: 'SOLID', category: 'Fullstack', popularity: '2.4' },
  { item: 'Microsoft', category: 'Fullstack', popularity: '2.4' },
  { item: 'WCF', category: 'Fullstack', popularity: '2.4' },
  { item: 'web services', category: 'Fullstack', popularity: '2.4' },
  { item: 'Terraform', category: 'Fullstack', popularity: '2.1' },
  { item: 'Confluence', category: 'Fullstack', popularity: '2.1' },
  { item: 'webpack', category: 'Fullstack', popularity: '2.1' },
  { item: 'DevOps', category: 'Fullstack', popularity: '2.1' },
  { item: 'Oracle', category: 'Fullstack', popularity: '2.1' },
  { item: 'OOP', category: 'Fullstack', popularity: '2.1' },
  { item: 'German', category: 'Fullstack', popularity: '2.1' },
  { item: 'WPF', category: 'Fullstack', popularity: '2.1' },
  { item: 'ASP.NET Core', category: 'Fullstack', popularity: '2.1' },
  { item: 'Sass', category: 'Fullstack', popularity: '1.8' },
  { item: 'React Native', category: 'Fullstack', popularity: '1.8' },
  { item: 'JSON', category: 'Fullstack', popularity: '1.8' },
  { item: 'ASP.NET', category: 'Fullstack', popularity: '1.8' },
  { item: 'Unit Testing', category: 'Fullstack', popularity: '1.8' },
  { item: 'C++', category: 'Fullstack', popularity: '1.6' },
  { item: 'Scala', category: 'Fullstack', popularity: '1.6' },
  { item: 'API', category: 'Fullstack', popularity: '1.6' },
  { item: 'Design Patterns', category: 'Fullstack', popularity: '1.6' },
  { item: 'XML', category: 'Fullstack', popularity: '1.6' },
  { item: 'Web API', category: 'Fullstack', popularity: '1.6' },
  { item: 'Apache', category: 'Fullstack', popularity: '1.6' },
  { item: 'Next.js', category: 'Fullstack', popularity: '1.6' },
  { item: 'Ansible', category: 'Fullstack', popularity: '1.3' },
  { item: 'Golang', category: 'Fullstack', popularity: '1.3' },
  { item: 'Clean Code', category: 'Fullstack', popularity: '1.3' },
  { item: 'DDD', category: 'Fullstack', popularity: '1.3' },
  { item: 'Java EE', category: 'Fullstack', popularity: '1.3' },
  { item: 'Databases', category: 'Fullstack', popularity: '1.3' },
  {
    item: 'Google Cloud Platform',
    category: 'Fullstack',
    popularity: '1.3',
  },
  { item: 'ASP.NET MVC', category: 'Fullstack', popularity: '1.3' },
  { item: 'Express.js', category: 'Fullstack', popularity: '1.3' },
  {
    item: 'Analytical Thinking',
    category: 'Fullstack',
    popularity: '1.3',
  },
  { item: 'Nest.js', category: 'Fullstack', popularity: '1.3' },
  { item: 'Celery', category: 'Fullstack', popularity: '1.3' },
  { item: 'PHPUnit', category: 'Fullstack', popularity: '1.3' },
  { item: 'JavaScript', category: 'Frontend', popularity: '81' },
  { item: 'React', category: 'Frontend', popularity: '68' },
  { item: 'TypeScript', category: 'Frontend', popularity: '64' },
  { item: 'CSS', category: 'Frontend', popularity: '59' },
  { item: 'HTML', category: 'Frontend', popularity: '56' },
  { item: 'Angular', category: 'Frontend', popularity: '41' },
  { item: 'Git', category: 'Frontend', popularity: '40' },
  { item: 'REST', category: 'Frontend', popularity: '26' },
  { item: 'Node.js', category: 'Frontend', popularity: '23' },
  { item: 'Redux', category: 'Frontend', popularity: '20' },
  { item: 'Vue.js', category: 'Frontend', popularity: '20' },
  { item: 'webpack', category: 'Frontend', popularity: '17' },
  { item: 'Sass', category: 'Frontend', popularity: '17' },
  { item: 'Docker', category: 'Frontend', popularity: '12' },
  { item: 'RxJS', category: 'Frontend', popularity: '11' },
  { item: 'GraphQL', category: 'Frontend', popularity: '8.5' },
  { item: 'AWS', category: 'Frontend', popularity: '8.0' },
  {
    item: 'Leadership skills',
    category: 'Frontend',
    popularity: '8.0',
  },
  { item: 'React Native', category: 'Frontend', popularity: '8.0' },
  { item: 'Less', category: 'Frontend', popularity: '7.0' },
  { item: 'Jest', category: 'Frontend', popularity: '6.0' },
  { item: 'jQuery', category: 'Frontend', popularity: '5.7' },
  { item: 'Java', category: 'Frontend', popularity: '5.5' },
  { item: 'Azure', category: 'Frontend', popularity: '5.0' },
  { item: 'Python', category: 'Frontend', popularity: '4.5' },
  { item: 'Bootstrap', category: 'Frontend', popularity: '4.5' },
  { item: 'NgRx', category: 'Frontend', popularity: '4.5' },
  { item: '.NET', category: 'Frontend', popularity: '4.0' },
  { item: 'Cypress', category: 'Frontend', popularity: '3.7' },
  { item: 'SQL', category: 'Frontend', popularity: '3.5' },
  { item: 'PHP', category: 'Frontend', popularity: '3.5' },
  { item: 'CI/CD', category: 'Frontend', popularity: '3.5' },
  { item: 'gulp', category: 'Frontend', popularity: '3.5' },
  { item: 'JIRA', category: 'Frontend', popularity: '3.2' },
  { item: 'ECMAScript', category: 'Frontend', popularity: '3.2' },
  { item: 'GitLab', category: 'Frontend', popularity: '3.0' },
  { item: 'Unit Testing', category: 'Frontend', popularity: '3.0' },
  { item: 'Next.js', category: 'Frontend', popularity: '3.0' },
  { item: 'Jasmine', category: 'Frontend', popularity: '3.0' },
  { item: 'Karma', category: 'Frontend', popularity: '3.0' },
  { item: 'Kubernetes', category: 'Frontend', popularity: '2.7' },
  { item: 'UX', category: 'Frontend', popularity: '2.7' },
  { item: 'RWD', category: 'Frontend', popularity: '2.7' },
  { item: 'Agile', category: 'Frontend', popularity: '2.5' },
  { item: 'Microservices', category: 'Frontend', popularity: '2.5' },
  { item: 'SCSS', category: 'Frontend', popularity: '2.5' },
  { item: 'Storybook', category: 'Frontend', popularity: '2.5' },
  { item: 'Express.js', category: 'Frontend', popularity: '2.2' },
  { item: 'Scrum', category: 'Frontend', popularity: '2.0' },
  { item: 'PWA', category: 'Frontend', popularity: '2.0' },
  { item: 'Babel', category: 'Frontend', popularity: '2.0' },
  { item: 'PostgreSQL', category: 'Frontend', popularity: '1.7' },
  { item: 'C#', category: 'Frontend', popularity: '1.7' },
  { item: 'Confluence', category: 'Frontend', popularity: '1.7' },
  { item: 'TDD', category: 'Frontend', popularity: '1.7' },
  { item: 'ES6', category: 'Frontend', popularity: '1.7' },
  { item: 'UI Design', category: 'Frontend', popularity: '1.7' },
  { item: 'Ionic', category: 'Frontend', popularity: '1.7' },
  { item: 'Redux-saga', category: 'Frontend', popularity: '1.7' },
  { item: 'MongoDB', category: 'Frontend', popularity: '1.5' },
  { item: 'Django', category: 'Frontend', popularity: '1.5' },
  { item: 'Azure DevOps', category: 'Frontend', popularity: '1.5' },
  { item: 'Design Patterns', category: 'Frontend', popularity: '1.5' },
  { item: 'Linux', category: 'Frontend', popularity: '1.2' },
  { item: 'Spring', category: 'Frontend', popularity: '1.2' },
  { item: 'MySQL', category: 'Frontend', popularity: '1.2' },
  { item: 'Jenkins', category: 'Frontend', popularity: '1.2' },
  { item: 'Selenium', category: 'Frontend', popularity: '1.2' },
  { item: 'Testing', category: 'Frontend', popularity: '1.2' },
  { item: 'HTTP', category: 'Frontend', popularity: '1.2' },
  { item: 'GatsbyJS', category: 'Frontend', popularity: '1.2' },
  { item: '.NET Core', category: 'Frontend', popularity: '1.0' },
  { item: 'Scala', category: 'Frontend', popularity: '1.0' },
  { item: 'API', category: 'Frontend', popularity: '1.0' },
  { item: 'Clean Code', category: 'Frontend', popularity: '1.0' },
  { item: 'WordPress', category: 'Frontend', popularity: '1.0' },
  { item: 'Nest.js', category: 'Frontend', popularity: '1.0' },
  {
    item: 'Styled Components',
    category: 'Frontend',
    popularity: '1.0',
  },
  { item: 'min B2 English', category: 'Frontend', popularity: '1.0' },
  { item: 'Elasticsearch', category: 'Frontend', popularity: '0.75' },
  { item: 'Maven', category: 'Frontend', popularity: '0.75' },
  { item: 'Android', category: 'Frontend', popularity: '0.75' },
  { item: 'Redis', category: 'Frontend', popularity: '0.75' },
  { item: 'Laravel', category: 'Frontend', popularity: '0.75' },
  { item: 'OOP', category: 'Frontend', popularity: '0.75' },
  { item: 'Figma', category: 'Frontend', popularity: '0.75' },
  { item: 'JSON', category: 'Frontend', popularity: '0.75' },
  { item: 'Flutter', category: 'Frontend', popularity: '0.75' },
  { item: 'Bitbucket', category: 'Frontend', popularity: '0.75' },
  { item: 'Mac OS', category: 'Frontend', popularity: '0.75' },
  { item: 'NPM', category: 'Frontend', popularity: '0.75' },
  { item: 'SEO', category: 'Frontend', popularity: '0.75' },
  { item: 'ES6+', category: 'Frontend', popularity: '0.75' },
  { item: 'WebSockets', category: 'Frontend', popularity: '0.75' },
  { item: 'Grunt', category: 'Frontend', popularity: '0.75' },
  { item: 'Express', category: 'Frontend', popularity: '0.75' },
  { item: 'Nuxt.js', category: 'Frontend', popularity: '0.75' },
  { item: 'ESLint', category: 'Frontend', popularity: '0.75' },
  { item: 'Cloud Services', category: 'Frontend', popularity: '0.75' },
  { item: 'material UI', category: 'Frontend', popularity: '0.75' },
  { item: 'SQL', category: 'Support', popularity: '38' },
  { item: 'Linux', category: 'Support', popularity: '29' },
  { item: 'Windows', category: 'Support', popularity: '27' },
  { item: 'Active Directory', category: 'Support', popularity: '23' },
  { item: 'Microsoft', category: 'Support', popularity: '18' },
  { item: 'German', category: 'Support', popularity: '14' },
  { item: 'VMware', category: 'Support', popularity: '14' },
  { item: 'ITIL', category: 'Support', popularity: '12' },
  { item: 'SAP', category: 'Support', popularity: '12' },
  { item: 'Security', category: 'Support', popularity: '11' },
  { item: 'Windows Server', category: 'Support', popularity: '11' },
  { item: 'Docker', category: 'Support', popularity: '9.1' },
  { item: 'Azure', category: 'Support', popularity: '7.6' },
  { item: 'MS Office', category: 'Support', popularity: '7.6' },
  { item: 'JavaScript', category: 'Support', popularity: '6.1' },
  { item: 'Python', category: 'Support', popularity: '6.1' },
  { item: 'HTML', category: 'Support', popularity: '6.1' },
  { item: 'JIRA', category: 'Support', popularity: '6.1' },
  { item: 'MySQL', category: 'Support', popularity: '6.1' },
  { item: 'Mac OS', category: 'Support', popularity: '6.1' },
  { item: 'AWS', category: 'Support', popularity: '4.5' },
  { item: 'REST', category: 'Support', popularity: '4.5' },
  { item: 'Kubernetes', category: 'Support', popularity: '4.5' },
  { item: 'Leadership skills', category: 'Support', popularity: '4.5' },
  { item: 'Unix', category: 'Support', popularity: '4.5' },
  { item: 'Shell', category: 'Support', popularity: '4.5' },
  { item: 'HTTP', category: 'Support', popularity: '4.5' },
  { item: 'Multitasking', category: 'Support', popularity: '4.5' },
  { item: 'Git', category: 'Support', popularity: '3.0' },
  { item: 'Angular', category: 'Support', popularity: '3.0' },
  { item: '.NET', category: 'Support', popularity: '3.0' },
  { item: 'SQL Server', category: 'Support', popularity: '3.0' },
  { item: 'Powershell', category: 'Support', popularity: '3.0' },
  { item: 'ServiceNow', category: 'Support', popularity: '3.0' },
  { item: 'DNS', category: 'Support', popularity: '3.0' },
  { item: 'Programming', category: 'Support', popularity: '3.0' },
  { item: 'Citrix', category: 'Support', popularity: '3.0' },
  { item: 'OKTA', category: 'Support', popularity: '3.0' },
  { item: 'Technical Support', category: 'Support', popularity: '3.0' },
  { item: 'Java', category: 'Support', popularity: '1.5' },
  { item: 'React', category: 'Support', popularity: '1.5' },
  { item: 'TypeScript', category: 'Support', popularity: '1.5' },
  { item: 'CSS', category: 'Support', popularity: '1.5' },
  { item: 'PHP', category: 'Support', popularity: '1.5' },
  { item: 'C#', category: 'Support', popularity: '1.5' },
  { item: 'Ansible', category: 'Support', popularity: '1.5' },
  { item: 'iOS', category: 'Support', popularity: '1.5' },
  { item: 'Confluence', category: 'Support', popularity: '1.5' },
  { item: 'MS SQL', category: 'Support', popularity: '1.5' },
  { item: 'Testing', category: 'Support', popularity: '1.5' },
  { item: 'Power BI', category: 'Support', popularity: '1.5' },
  { item: 'Azure DevOps', category: 'Support', popularity: '1.5' },
  { item: 'Team management', category: 'Support', popularity: '1.5' },
  { item: 'Java EE', category: 'Support', popularity: '1.5' },
  {
    item: 'Google Cloud Platform',
    category: 'Support',
    popularity: '1.5',
  },
  {
    item: 'Analytical Thinking',
    category: 'Support',
    popularity: '1.5',
  },
  { item: 'TCP/IP', category: 'Support', popularity: '1.5' },
  { item: 'Excel', category: 'Support', popularity: '1.5' },
  { item: 'web services', category: 'Support', popularity: '1.5' },
  { item: 'Google Analytics', category: 'Support', popularity: '1.5' },
  { item: 'ERP', category: 'Support', popularity: '1.5' },
  { item: 'SharePoint', category: 'Support', popularity: '1.5' },
  { item: 'Cisco', category: 'Support', popularity: '1.5' },
  { item: 'Ubuntu', category: 'Support', popularity: '1.5' },
  { item: 'Zabbix', category: 'Support', popularity: '1.5' },
  { item: 'ABAP', category: 'Support', popularity: '1.5' },
  { item: 'Network', category: 'Support', popularity: '1.5' },
  { item: 'Routing', category: 'Support', popularity: '1.5' },
  { item: 'MS Azure', category: 'Support', popularity: '1.5' },
  { item: 'Debian', category: 'Support', popularity: '1.5' },
  { item: 'CCNA', category: 'Support', popularity: '1.5' },
  { item: 'DHCP', category: 'Support', popularity: '1.5' },
  { item: 'Flexibility', category: 'Support', popularity: '1.5' },
  { item: 'switching', category: 'Support', popularity: '1.5' },
  { item: 'O365', category: 'Support', popularity: '1.5' },
  { item: 'SAP SD', category: 'Support', popularity: '1.5' },
  { item: 'Slack', category: 'Support', popularity: '1.5' },
  {
    item: 'Network Administration',
    category: 'Support',
    popularity: '1.5',
  },
  { item: 'Azure AD', category: 'Support', popularity: '1.5' },
  { item: 'ITSM', category: 'Support', popularity: '1.5' },
  { item: 'Exchange', category: 'Support', popularity: '1.5' },
  { item: 'finance', category: 'Support', popularity: '1.5' },
  { item: 'SAP MM', category: 'Support', popularity: '1.5' },
  { item: 'CSV', category: 'Support', popularity: '1.5' },
  { item: 'MDM', category: 'Support', popularity: '1.5' },
  { item: 'Italian', category: 'Support', popularity: '1.5' },
  { item: 'Customer Service', category: 'Support', popularity: '1.5' },
  { item: 'IBM', category: 'Support', popularity: '1.5' },
  { item: 'GTM', category: 'Support', popularity: '1.5' },
  { item: 'ITIL v3', category: 'Support', popularity: '1.5' },
  {
    item: 'System Administration',
    category: 'Support',
    popularity: '1.5',
  },
  { item: 'SAP BASIS', category: 'Support', popularity: '1.5' },
  { item: 'G Suite', category: 'Support', popularity: '1.5' },
  {
    item: 'cloud infrastructure',
    category: 'Support',
    popularity: '1.5',
  },
  { item: 'MS Windows', category: 'Support', popularity: '1.5' },
  { item: 'Accounting', category: 'Support', popularity: '1.5' },
  { item: 'Commvault', category: 'Support', popularity: '1.5' },
  { item: 'NetApp', category: 'Support', popularity: '1.5' },
  { item: 'VirtualBox', category: 'Support', popularity: '1.5' },
  { item: 'Russian', category: 'Support', popularity: '1.5' },
  { item: 'Docker', category: 'Backend', popularity: '42' },
  { item: 'Java', category: 'Backend', popularity: '42' },
  { item: 'Git', category: 'Backend', popularity: '40' },
  { item: 'REST', category: 'Backend', popularity: '33' },
  { item: 'Spring', category: 'Backend', popularity: '33' },
  { item: 'SQL', category: 'Backend', popularity: '29' },
  { item: 'AWS', category: 'Backend', popularity: '27' },
  { item: 'Python', category: 'Backend', popularity: '20' },
  { item: 'PostgreSQL', category: 'Backend', popularity: '18' },
  { item: '.NET', category: 'Backend', popularity: '16' },
  { item: 'Kubernetes', category: 'Backend', popularity: '16' },
  { item: 'JavaScript', category: 'Backend', popularity: '15' },
  { item: 'PHP', category: 'Backend', popularity: '13' },
  { item: 'Hibernate', category: 'Backend', popularity: '13' },
  { item: 'Linux', category: 'Backend', popularity: '12' },
  { item: 'Microservices', category: 'Backend', popularity: '12' },
  { item: 'React', category: 'Backend', popularity: '11' },
  { item: 'Azure', category: 'Backend', popularity: '11' },
  { item: 'MySQL', category: 'Backend', popularity: '11' },
  { item: 'Jenkins', category: 'Backend', popularity: '10' },
  { item: 'NoSQL', category: 'Backend', popularity: '9.7' },
  { item: 'Kafka', category: 'Backend', popularity: '9.5' },
  { item: 'C#', category: 'Backend', popularity: '9.4' },
  { item: 'Node.js', category: 'Backend', popularity: '9.1' },
  { item: 'Maven', category: 'Backend', popularity: '9.1' },
  { item: 'Leadership skills', category: 'Backend', popularity: '8.8' },
  { item: 'Elasticsearch', category: 'Backend', popularity: '8.7' },
  { item: 'Angular', category: 'Backend', popularity: '8.0' },
  { item: 'Redis', category: 'Backend', popularity: '8.0' },
  { item: 'MongoDB', category: 'Backend', popularity: '7.9' },
  { item: 'JUnit', category: 'Backend', popularity: '7.4' },
  { item: 'Symfony', category: 'Backend', popularity: '6.8' },
  { item: 'TypeScript', category: 'Backend', popularity: '6.7' },
  { item: 'RabbitMQ', category: 'Backend', popularity: '6.7' },
  { item: 'Django', category: 'Backend', popularity: '6.3' },
  { item: 'CI/CD', category: 'Backend', popularity: '6.1' },
  { item: 'Scala', category: 'Backend', popularity: '5.4' },
  { item: 'MS SQL', category: 'Backend', popularity: '5.2' },
  { item: 'JIRA', category: 'Backend', popularity: '5.0' },
  { item: 'Kotlin', category: 'Backend', popularity: '5.0' },
  { item: '.NET Core', category: 'Backend', popularity: '5.0' },
  { item: 'TDD', category: 'Backend', popularity: '4.9' },
  { item: 'DevOps', category: 'Backend', popularity: '4.8' },
  { item: 'C++', category: 'Backend', popularity: '4.4' },
  { item: 'JPA', category: 'Backend', popularity: '4.4' },
  { item: 'HTML', category: 'Backend', popularity: '4.2' },
  { item: 'Entity Framework', category: 'Backend', popularity: '3.9' },
  { item: 'DDD', category: 'Backend', popularity: '3.8' },
  { item: 'CSS', category: 'Backend', popularity: '3.7' },
  { item: 'Ruby on Rails', category: 'Backend', popularity: '3.6' },
  { item: 'Oracle', category: 'Backend', popularity: '3.6' },
  { item: 'Vue.js', category: 'Backend', popularity: '3.5' },
  { item: 'Visual Studio', category: 'Backend', popularity: '3.5' },
  { item: 'Flask', category: 'Backend', popularity: '3.5' },
  { item: 'Scrum', category: 'Backend', popularity: '3.2' },
  { item: 'GraphQL', category: 'Backend', popularity: '3.2' },
  { item: 'Golang', category: 'Backend', popularity: '3.2' },
  { item: 'GCP', category: 'Backend', popularity: '3.1' },
  { item: 'Spark', category: 'Backend', popularity: '3.1' },
  { item: 'Web API', category: 'Backend', popularity: '3.1' },
  { item: 'SQL Server', category: 'Backend', popularity: '3.0' },
  { item: 'Design Patterns', category: 'Backend', popularity: '3.0' },
  { item: 'Terraform', category: 'Backend', popularity: '2.9' },
  { item: 'Unit Testing', category: 'Backend', popularity: '2.9' },
  { item: 'OOP', category: 'Backend', popularity: '2.7' },
  { item: 'Java EE', category: 'Backend', popularity: '2.6' },
  { item: 'Ansible', category: 'Backend', popularity: '2.5' },
  { item: 'Ruby', category: 'Backend', popularity: '2.5' },
  { item: 'Databases', category: 'Backend', popularity: '2.5' },
  { item: 'MVC', category: 'Backend', popularity: '2.4' },
  { item: 'SOLID', category: 'Backend', popularity: '2.4' },
  { item: 'Celery', category: 'Backend', popularity: '2.3' },
  { item: 'ASP.NET', category: 'Backend', popularity: '2.1' },
  { item: 'Gradle', category: 'Backend', popularity: '2.1' },
  { item: 'Spock', category: 'Backend', popularity: '2.1' },
  { item: 'Agile', category: 'Backend', popularity: '2.0' },
  { item: 'JSON', category: 'Backend', popularity: '2.0' },
  { item: 'Clean Code', category: 'Backend', popularity: '2.0' },
  { item: 'Groovy', category: 'Backend', popularity: '2.0' },
  { item: 'Confluence', category: 'Backend', popularity: '1.9' },
  { item: 'API', category: 'Backend', popularity: '1.9' },
  { item: 'SOAP', category: 'Backend', popularity: '1.9' },
  { item: 'Cloud', category: 'Backend', popularity: '1.8' },
  { item: 'jQuery', category: 'Backend', popularity: '1.8' },
  { item: 'GitLab', category: 'Backend', popularity: '1.8' },
  { item: 'Hadoop', category: 'Backend', popularity: '1.8' },
  {
    item: 'Google Cloud Platform',
    category: 'Backend',
    popularity: '1.7',
  },
  { item: 'Cassandra', category: 'Backend', popularity: '1.7' },
  { item: 'web services', category: 'Backend', popularity: '1.7' },
  { item: 'Akka', category: 'Backend', popularity: '1.7' },
  { item: 'Azure DevOps', category: 'Backend', popularity: '1.5' },
  { item: 'German', category: 'Backend', popularity: '1.5' },
  { item: 'WCF', category: 'Backend', popularity: '1.5' },
  { item: 'WPF', category: 'Backend', popularity: '1.5' },
  { item: 'Machine Learning', category: 'Backend', popularity: '1.4' },
  { item: 'Big Data', category: 'Backend', popularity: '1.4' },
  { item: 'ASP.NET MVC', category: 'Backend', popularity: '1.4' },
  { item: 'Laravel', category: 'Backend', popularity: '1.3' },
  { item: 'PHPUnit', category: 'Backend', popularity: '1.3' },
  { item: 'XML', category: 'Backend', popularity: '1.2' },
  {
    item: 'Business analysis',
    category: 'Business Analytics',
    popularity: '64',
  },
  { item: 'SQL', category: 'Business Analytics', popularity: '51' },
  { item: 'UML', category: 'Business Analytics', popularity: '40' },
  { item: 'JIRA', category: 'Business Analytics', popularity: '35' },
  { item: 'BPMN', category: 'Business Analytics', popularity: '34' },
  {
    item: 'System analysis',
    category: 'Business Analytics',
    popularity: '34',
  },
  {
    item: 'Confluence',
    category: 'Business Analytics',
    popularity: '24',
  },
  { item: 'Excel', category: 'Business Analytics', popularity: '19' },
  {
    item: 'Enterprise Architect',
    category: 'Business Analytics',
    popularity: '16',
  },
  {
    item: 'Leadership skills',
    category: 'Business Analytics',
    popularity: '12',
  },
  { item: 'Python', category: 'Business Analytics', popularity: '8.1' },
  { item: 'Scrum', category: 'Business Analytics', popularity: '8.1' },
  {
    item: 'Tableau',
    category: 'Business Analytics',
    popularity: '7.0',
  },
  {
    item: 'Financial business domain',
    category: 'Business Analytics',
    popularity: '5.8',
  },
  { item: 'Agile', category: 'Business Analytics', popularity: '4.7' },
  {
    item: 'Salesforce',
    category: 'Business Analytics',
    popularity: '4.7',
  },
  { item: 'C#', category: 'Business Analytics', popularity: '3.5' },
  {
    item: 'Project management',
    category: 'Business Analytics',
    popularity: '3.5',
  },
  {
    item: 'Machine Learning',
    category: 'Business Analytics',
    popularity: '3.5',
  },
  {
    item: 'Power BI',
    category: 'Business Analytics',
    popularity: '3.5',
  },
  {
    item: 'Google Analytics',
    category: 'Business Analytics',
    popularity: '3.5',
  },
  { item: 'ERP', category: 'Business Analytics', popularity: '3.5' },
  { item: 'CRM', category: 'Business Analytics', popularity: '3.5' },
  {
    item: 'Data analysis',
    category: 'Business Analytics',
    popularity: '3.5',
  },
  { item: 'Java', category: 'Business Analytics', popularity: '2.3' },
  { item: 'Azure', category: 'Business Analytics', popularity: '2.3' },
  { item: '.NET', category: 'Business Analytics', popularity: '2.3' },
  { item: 'UX', category: 'Business Analytics', popularity: '2.3' },
  { item: 'German', category: 'Business Analytics', popularity: '2.3' },
  { item: 'XML', category: 'Business Analytics', popularity: '2.3' },
  {
    item: 'Product Management',
    category: 'Business Analytics',
    popularity: '2.3',
  },
  {
    item: 'Magento',
    category: 'Business Analytics',
    popularity: '2.3',
  },
  { item: 'R', category: 'Business Analytics', popularity: '2.3' },
  { item: 'SAP', category: 'Business Analytics', popularity: '2.3' },
  { item: 'SaaS', category: 'Business Analytics', popularity: '2.3' },
  {
    item: 'E-commerce',
    category: 'Business Analytics',
    popularity: '2.3',
  },
  {
    item: 'Swagger',
    category: 'Business Analytics',
    popularity: '2.3',
  },
  { item: 'SOA', category: 'Business Analytics', popularity: '2.3' },
  {
    item: 'self-motivated',
    category: 'Business Analytics',
    popularity: '2.3',
  },
  {
    item: 'Blue Prism',
    category: 'Business Analytics',
    popularity: '2.3',
  },
  {
    item: 'Data modelling',
    category: 'Business Analytics',
    popularity: '2.3',
  },
  {
    item: 'Willingness to learn',
    category: 'Business Analytics',
    popularity: '2.3',
  },
  {
    item: 'Frontend',
    category: 'Business Analytics',
    popularity: '2.3',
  },
  { item: 'CASE', category: 'Business Analytics', popularity: '2.3' },
  {
    item: 'Automation Anywhere',
    category: 'Business Analytics',
    popularity: '2.3',
  },
  {
    item: 'Lucidchart',
    category: 'Business Analytics',
    popularity: '2.3',
  },
  {
    item: 'Cloud experience',
    category: 'Business Analytics',
    popularity: '2.3',
  },
  {
    item: 'Technological background',
    category: 'Business Analytics',
    popularity: '2.3',
  },
  {
    item: 'At least 2 years of experience as Business Analyst',
    category: 'Business Analytics',
    popularity: '2.3',
  },
  {
    item: 'Experience in software engineering area',
    category: 'Business Analytics',
    popularity: '2.3',
  },
  {
    item: 'Aspiring to develop a successful career with a leading financial technology company',
    category: 'Business Analytics',
    popularity: '2.3',
  },
  {
    item: 'JavaScript',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  { item: 'Git', category: 'Business Analytics', popularity: '1.2' },
  { item: 'AWS', category: 'Business Analytics', popularity: '1.2' },
  { item: 'HTML', category: 'Business Analytics', popularity: '1.2' },
  { item: 'Spark', category: 'Business Analytics', popularity: '1.2' },
  {
    item: 'Postman',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'SQL Server',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  { item: 'Oracle', category: 'Business Analytics', popularity: '1.2' },
  {
    item: 'Testing',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  { item: 'ETL', category: 'Business Analytics', popularity: '1.2' },
  { item: 'JSON', category: 'Business Analytics', popularity: '1.2' },
  {
    item: 'Azure DevOps',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  { item: 'Hadoop', category: 'Business Analytics', popularity: '1.2' },
  {
    item: 'Manual Testing',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'Databases',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  { item: 'SoapUI', category: 'Business Analytics', popularity: '1.2' },
  {
    item: 'Analytical Thinking',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'web services',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'Web Applications',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'Microsoft Azure',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'MS Office',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'Snowflake',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'Cooperation with stakeholders',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  { item: 'SDLC', category: 'Business Analytics', popularity: '1.2' },
  {
    item: 'SharePoint',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'ServiceNow',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'Data modeling',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'Defining business requirements',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  { item: 'VBA', category: 'Business Analytics', popularity: '1.2' },
  { item: 'CMS', category: 'Business Analytics', popularity: '1.2' },
  {
    item: 'Azure Data Factory',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  { item: 'NLP', category: 'Business Analytics', popularity: '1.2' },
  {
    item: 'Backend',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'Microsoft Office',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'Market analytics',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'Databricks',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'SAP HANA',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  { item: 'RPA', category: 'Business Analytics', popularity: '1.2' },
  { item: 'O365', category: 'Business Analytics', popularity: '1.2' },
  {
    item: 'Qlik Sense',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'Google Tag Manager',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  { item: 'GUI', category: 'Business Analytics', popularity: '1.2' },
  {
    item: 'Training',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'Ability to work independently',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'min B2 English',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'Google Data Studio',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'Data Management',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  {
    item: 'User Stories',
    category: 'Business Analytics',
    popularity: '1.2',
  },
  { item: 'UiPath', category: 'Business Analytics', popularity: '1.2' },
  {
    item: 'Python',
    category: 'Artificial Intelligence',
    popularity: '84',
  },
  {
    item: 'Machine Learning',
    category: 'Artificial Intelligence',
    popularity: '70',
  },
  {
    item: 'Azure',
    category: 'Artificial Intelligence',
    popularity: '41',
  },
  {
    item: 'TensorFlow',
    category: 'Artificial Intelligence',
    popularity: '41',
  },
  {
    item: 'PyTorch',
    category: 'Artificial Intelligence',
    popularity: '35',
  },
  {
    item: 'AWS',
    category: 'Artificial Intelligence',
    popularity: '24',
  },
  {
    item: 'Scikit-learn',
    category: 'Artificial Intelligence',
    popularity: '24',
  },
  {
    item: 'SQL',
    category: 'Artificial Intelligence',
    popularity: '22',
  },
  {
    item: 'Cloud',
    category: 'Artificial Intelligence',
    popularity: '16',
  },
  {
    item: 'Docker',
    category: 'Artificial Intelligence',
    popularity: '14',
  },
  {
    item: 'C++',
    category: 'Artificial Intelligence',
    popularity: '14',
  },
  {
    item: 'GCP',
    category: 'Artificial Intelligence',
    popularity: '14',
  },
  {
    item: 'Spark',
    category: 'Artificial Intelligence',
    popularity: '14',
  },
  { item: 'R', category: 'Artificial Intelligence', popularity: '14' },
  {
    item: 'Pandas',
    category: 'Artificial Intelligence',
    popularity: '14',
  },
  {
    item: 'OpenCV',
    category: 'Artificial Intelligence',
    popularity: '14',
  },
  {
    item: 'Git',
    category: 'Artificial Intelligence',
    popularity: '11',
  },
  {
    item: 'Scala',
    category: 'Artificial Intelligence',
    popularity: '11',
  },
  {
    item: 'Keras',
    category: 'Artificial Intelligence',
    popularity: '11',
  },
  {
    item: 'Linux',
    category: 'Artificial Intelligence',
    popularity: '8.1',
  },
  {
    item: 'REST',
    category: 'Artificial Intelligence',
    popularity: '8.1',
  },
  {
    item: 'Kubernetes',
    category: 'Artificial Intelligence',
    popularity: '8.1',
  },
  {
    item: 'Jenkins',
    category: 'Artificial Intelligence',
    popularity: '8.1',
  },
  {
    item: 'C#',
    category: 'Artificial Intelligence',
    popularity: '8.1',
  },
  {
    item: 'Big Data',
    category: 'Artificial Intelligence',
    popularity: '8.1',
  },
  {
    item: 'Data Engineering',
    category: 'Artificial Intelligence',
    popularity: '8.1',
  },
  {
    item: 'AI',
    category: 'Artificial Intelligence',
    popularity: '8.1',
  },
  {
    item: 'Data Science',
    category: 'Artificial Intelligence',
    popularity: '8.1',
  },
  {
    item: 'NumPy',
    category: 'Artificial Intelligence',
    popularity: '8.1',
  },
  {
    item: 'CosmosDB',
    category: 'Artificial Intelligence',
    popularity: '8.1',
  },
  {
    item: 'Java',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'TypeScript',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'JIRA',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'Leadership skills',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'Kafka',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'Project management',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'GitLab',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'Hadoop',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'BigQuery',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'Deep Learning',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'NLP',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'SAS',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'Computer Vision',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'Matlab',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'kubeflow',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'Good Coding Skills',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'aad',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'QnA Maker',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'LUIS',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'Microsoft Bot Framework',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'MLflow',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'Cognitive Services',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'Azure AD Identity',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'experience in a consulting company',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'Metaflow',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'Julia',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'statistical techniques',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: '2+ years of experience in neural networks programming',
    category: 'Artificial Intelligence',
    popularity: '5.4',
  },
  {
    item: 'JavaScript',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'PostgreSQL',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Agile',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'CI/CD',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Ansible',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Confluence',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'DevOps',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Test Automation',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'UML',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'SQL Server',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Powershell',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'BPMN',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Google Cloud Platform',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Enterprise Architect',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Puppet',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Airflow',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Excel',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Blockchain',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Snowflake',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Bamboo',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Hive',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Data modeling',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Data analysis',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Gerrit',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'design thinking',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Azure Data Factory',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Statistics',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Flink',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Azure Databricks',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Azure Data Lake',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'DSP',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Programming skills',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Jupyter',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Azure Synapse Analytics',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'DICOM',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'SciPy',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Azure Machine Learning',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'matplotlib',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Reinforcement Learning',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Jupyter Notebooks',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Azure Cognitive Services',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  {
    item: 'Azure Stream Analytics',
    category: 'Artificial Intelligence',
    popularity: '2.7',
  },
  { item: 'Linux', category: 'IT Administration', popularity: '65' },
  { item: 'Windows', category: 'IT Administration', popularity: '29' },
  { item: 'Ansible', category: 'IT Administration', popularity: '21' },
  {
    item: 'Powershell',
    category: 'IT Administration',
    popularity: '17',
  },
  { item: 'TCP/IP', category: 'IT Administration', popularity: '17' },
  { item: 'JIRA', category: 'IT Administration', popularity: '15' },
  {
    item: 'Virtualization',
    category: 'IT Administration',
    popularity: '15',
  },
  { item: 'Docker', category: 'IT Administration', popularity: '14' },
  { item: 'Azure', category: 'IT Administration', popularity: '14' },
  {
    item: 'Windows Server',
    category: 'IT Administration',
    popularity: '14',
  },
  { item: 'Bash', category: 'IT Administration', popularity: '12' },
  {
    item: 'Active Directory',
    category: 'IT Administration',
    popularity: '12',
  },
  { item: 'Git', category: 'IT Administration', popularity: '11' },
  { item: 'MySQL', category: 'IT Administration', popularity: '11' },
  { item: 'Python', category: 'IT Administration', popularity: '9.1' },
  {
    item: 'Kubernetes',
    category: 'IT Administration',
    popularity: '9.1',
  },
  {
    item: 'PostgreSQL',
    category: 'IT Administration',
    popularity: '9.1',
  },
  {
    item: 'Confluence',
    category: 'IT Administration',
    popularity: '9.1',
  },
  { item: 'MS SQL', category: 'IT Administration', popularity: '9.1' },
  { item: 'SQL', category: 'IT Administration', popularity: '7.6' },
  {
    item: 'Terraform',
    category: 'IT Administration',
    popularity: '7.6',
  },
  { item: 'VMware', category: 'IT Administration', popularity: '7.6' },
  { item: 'Nginx', category: 'IT Administration', popularity: '7.6' },
  { item: 'Apache', category: 'IT Administration', popularity: '7.6' },
  { item: 'AWS', category: 'IT Administration', popularity: '6.1' },
  { item: 'CI/CD', category: 'IT Administration', popularity: '6.1' },
  { item: 'Oracle', category: 'IT Administration', popularity: '6.1' },
  {
    item: 'Leadership skills',
    category: 'IT Administration',
    popularity: '4.5',
  },
  {
    item: 'Azure DevOps',
    category: 'IT Administration',
    popularity: '4.5',
  },
  {
    item: 'Databases',
    category: 'IT Administration',
    popularity: '4.5',
  },
  {
    item: 'Prometheus',
    category: 'IT Administration',
    popularity: '4.5',
  },
  { item: 'RDBMS', category: 'IT Administration', popularity: '4.5' },
  { item: 'DNS', category: 'IT Administration', popularity: '4.5' },
  {
    item: 'OpenStack',
    category: 'IT Administration',
    popularity: '4.5',
  },
  { item: 'Cisco', category: 'IT Administration', popularity: '4.5' },
  { item: 'Red Hat', category: 'IT Administration', popularity: '4.5' },
  { item: 'HAProxy', category: 'IT Administration', popularity: '4.5' },
  { item: 'VPN', category: 'IT Administration', popularity: '4.5' },
  {
    item: 'Network Administration',
    category: 'IT Administration',
    popularity: '4.5',
  },
  { item: 'Ceph', category: 'IT Administration', popularity: '4.5' },
  { item: 'Jenkins', category: 'IT Administration', popularity: '3.0' },
  { item: 'Scrum', category: 'IT Administration', popularity: '3.0' },
  { item: 'Android', category: 'IT Administration', popularity: '3.0' },
  { item: 'iOS', category: 'IT Administration', popularity: '3.0' },
  { item: 'Redis', category: 'IT Administration', popularity: '3.0' },
  { item: 'Cloud', category: 'IT Administration', popularity: '3.0' },
  { item: 'ETL', category: 'IT Administration', popularity: '3.0' },
  {
    item: 'Salesforce',
    category: 'IT Administration',
    popularity: '3.0',
  },
  { item: 'Puppet', category: 'IT Administration', popularity: '3.0' },
  { item: 'ITIL', category: 'IT Administration', popularity: '3.0' },
  { item: 'Grafana', category: 'IT Administration', popularity: '3.0' },
  {
    item: 'OpenShift',
    category: 'IT Administration',
    popularity: '3.0',
  },
  { item: 'Tomcat', category: 'IT Administration', popularity: '3.0' },
  {
    item: 'MS Office',
    category: 'IT Administration',
    popularity: '3.0',
  },
  { item: 'JBoss', category: 'IT Administration', popularity: '3.0' },
  {
    item: 'ServiceNow',
    category: 'IT Administration',
    popularity: '3.0',
  },
  { item: 'LAN', category: 'IT Administration', popularity: '3.0' },
  { item: 'Zabbix', category: 'IT Administration', popularity: '3.0' },
  { item: 'Nagios', category: 'IT Administration', popularity: '3.0' },
  {
    item: 'Shell Scripting',
    category: 'IT Administration',
    popularity: '3.0',
  },
  { item: 'Hyper-V', category: 'IT Administration', popularity: '3.0' },
  {
    item: 'Atlassian',
    category: 'IT Administration',
    popularity: '3.0',
  },
  { item: 'IIS', category: 'IT Administration', popularity: '3.0' },
  { item: 'KVM', category: 'IT Administration', popularity: '3.0' },
  { item: 'DHCP', category: 'IT Administration', popularity: '3.0' },
  { item: 'O365', category: 'IT Administration', popularity: '3.0' },
  { item: 'VLAN', category: 'IT Administration', popularity: '3.0' },
  {
    item: 'Oracle RAC',
    category: 'IT Administration',
    popularity: '3.0',
  },
  {
    item: 'administration',
    category: 'IT Administration',
    popularity: '3.0',
  },
  { item: 'FreeBSD', category: 'IT Administration', popularity: '3.0' },
  { item: 'CSS', category: 'IT Administration', popularity: '1.5' },
  { item: 'HTML', category: 'IT Administration', popularity: '1.5' },
  { item: 'PHP', category: 'IT Administration', popularity: '1.5' },
  { item: '.NET', category: 'IT Administration', popularity: '1.5' },
  { item: 'C#', category: 'IT Administration', popularity: '1.5' },
  { item: 'Node.js', category: 'IT Administration', popularity: '1.5' },
  { item: 'Agile', category: 'IT Administration', popularity: '1.5' },
  { item: 'C++', category: 'IT Administration', popularity: '1.5' },
  { item: 'Kafka', category: 'IT Administration', popularity: '1.5' },
  {
    item: 'RabbitMQ',
    category: 'IT Administration',
    popularity: '1.5',
  },
  { item: 'GCP', category: 'IT Administration', popularity: '1.5' },
  { item: 'API', category: 'IT Administration', popularity: '1.5' },
  { item: 'GitLab', category: 'IT Administration', popularity: '1.5' },
  { item: 'UML', category: 'IT Administration', popularity: '1.5' },
  { item: 'Unix', category: 'IT Administration', popularity: '1.5' },
  { item: 'German', category: 'IT Administration', popularity: '1.5' },
  { item: 'JS', category: 'IT Administration', popularity: '1.5' },
  {
    item: 'Security',
    category: 'IT Administration',
    popularity: '1.5',
  },
  { item: 'HTTP', category: 'IT Administration', popularity: '1.5' },
  { item: 'GitHub', category: 'IT Administration', popularity: '1.5' },
  { item: 'Groovy', category: 'IT Administration', popularity: '1.5' },
  {
    item: 'Bitbucket',
    category: 'IT Administration',
    popularity: '1.5',
  },
  {
    item: 'Microsoft',
    category: 'IT Administration',
    popularity: '1.5',
  },
  { item: 'SVN', category: 'IT Administration', popularity: '1.5' },
  {
    item: 'web services',
    category: 'IT Administration',
    popularity: '1.5',
  },
  {
    item: 'Networking',
    category: 'IT Administration',
    popularity: '1.5',
  },
  {
    item: 'Microsoft Azure',
    category: 'IT Administration',
    popularity: '1.5',
  },
  { item: 'SSIS', category: 'IT Administration', popularity: '1.5' },
  { item: 'Chef', category: 'IT Administration', popularity: '1.5' },
  {
    item: 'Blockchain',
    category: 'IT Administration',
    popularity: '1.5',
  },
  { item: 'Android', category: 'Mobile', popularity: '70' },
  { item: 'iOS', category: 'Mobile', popularity: '67' },
  { item: 'Git', category: 'Mobile', popularity: '52' },
  { item: 'Kotlin', category: 'Mobile', popularity: '45' },
  { item: 'Swift', category: 'Mobile', popularity: '35' },
  { item: 'Java', category: 'Mobile', popularity: '33' },
  { item: 'REST', category: 'Mobile', popularity: '31' },
  { item: 'MVVM', category: 'Mobile', popularity: '31' },
  { item: 'Objective-C', category: 'Mobile', popularity: '19' },
  { item: 'RxJava', category: 'Mobile', popularity: '19' },
  { item: 'Gradle', category: 'Mobile', popularity: '14' },
  { item: 'Firebase', category: 'Mobile', popularity: '14' },
  { item: 'Flutter', category: 'Mobile', popularity: '12' },
  { item: 'Xcode', category: 'Mobile', popularity: '12' },
  { item: 'Retrofit', category: 'Mobile', popularity: '12' },
  { item: 'JSON', category: 'Mobile', popularity: '11' },
  { item: 'Dagger', category: 'Mobile', popularity: '11' },
  { item: 'CocoaPods', category: 'Mobile', popularity: '11' },
  { item: 'React Native', category: 'Mobile', popularity: '10' },
  { item: 'Unit Testing', category: 'Mobile', popularity: '9.8' },
  { item: 'RxSwift', category: 'Mobile', popularity: '9.8' },
  { item: 'Jenkins', category: 'Mobile', popularity: '9.2' },
  { item: 'Leadership skills', category: 'Mobile', popularity: '7.2' },
  { item: 'Dart', category: 'Mobile', popularity: '7.2' },
  { item: 'fastlane', category: 'Mobile', popularity: '7.2' },
  { item: 'Mockito', category: 'Mobile', popularity: '5.9' },
  { item: 'Coroutines', category: 'Mobile', popularity: '5.9' },
  { item: 'JavaScript', category: 'Mobile', popularity: '5.2' },
  { item: 'SQL', category: 'Mobile', popularity: '5.2' },
  { item: 'CI/CD', category: 'Mobile', popularity: '5.2' },
  { item: 'MVP', category: 'Mobile', popularity: '5.2' },
  { item: 'React', category: 'Mobile', popularity: '4.6' },
  { item: 'C++', category: 'Mobile', popularity: '4.6' },
  { item: 'SOLID', category: 'Mobile', popularity: '4.6' },
  { item: 'Android Studio', category: 'Mobile', popularity: '4.6' },
  { item: 'TypeScript', category: 'Mobile', popularity: '3.9' },
  { item: 'Scrum', category: 'Mobile', popularity: '3.9' },
  { item: 'Redux', category: 'Mobile', popularity: '3.9' },
  { item: 'Espresso', category: 'Mobile', popularity: '3.9' },
  { item: 'Koin', category: 'Mobile', popularity: '3.9' },
  { item: 'Kotlin Coroutines', category: 'Mobile', popularity: '3.9' },
  { item: 'API', category: 'Mobile', popularity: '3.3' },
  {
    item: 'Continuous Integration',
    category: 'Mobile',
    popularity: '3.3',
  },
  { item: 'SwiftUI', category: 'Mobile', popularity: '3.3' },
  { item: 'Clean Architecture', category: 'Mobile', popularity: '3.3' },
  { item: 'GraphQL', category: 'Mobile', popularity: '2.6' },
  { item: 'Test Automation', category: 'Mobile', popularity: '2.6' },
  { item: 'Design Patterns', category: 'Mobile', popularity: '2.6' },
  { item: 'Dagger 2', category: 'Mobile', popularity: '2.6' },
  { item: 'Room', category: 'Mobile', popularity: '2.6' },
  { item: 'MVI', category: 'Mobile', popularity: '2.6' },
  {
    item: 'Android Architecture Components',
    category: 'Mobile',
    popularity: '2.6',
  },
  {
    item: 'HTTP Live Streaming',
    category: 'Mobile',
    popularity: '2.6',
  },
  { item: 'Angular', category: 'Mobile', popularity: '2.0' },
  { item: 'Azure', category: 'Mobile', popularity: '2.0' },
  { item: '.NET', category: 'Mobile', popularity: '2.0' },
  { item: 'Cucumber', category: 'Mobile', popularity: '2.0' },
  { item: 'CI', category: 'Mobile', popularity: '2.0' },
  {
    item: 'Distributed systems',
    category: 'Mobile',
    popularity: '2.0',
  },
  { item: 'Xamarin', category: 'Mobile', popularity: '2.0' },
  { item: 'UIKit', category: 'Mobile', popularity: '2.0' },
  { item: 'OpenGL', category: 'Mobile', popularity: '2.0' },
  { item: 'UI testing', category: 'Mobile', popularity: '2.0' },
  { item: 'Android Jetpack', category: 'Mobile', popularity: '2.0' },
  { item: 'Combine', category: 'Mobile', popularity: '2.0' },
  { item: 'vulkan', category: 'Mobile', popularity: '2.0' },
  { item: 'Protobuf', category: 'Mobile', popularity: '2.0' },
  {
    item: 'Atlassian ecosystem',
    category: 'Mobile',
    popularity: '2.0',
  },
  {
    item: 'Mobile SDK Development',
    category: 'Mobile',
    popularity: '2.0',
  },
  { item: 'Hilt', category: 'Mobile', popularity: '2.0' },
  { item: 'Linux', category: 'Mobile', popularity: '1.3' },
  { item: 'JIRA', category: 'Mobile', popularity: '1.3' },
  { item: 'C#', category: 'Mobile', popularity: '1.3' },
  { item: 'Confluence', category: 'Mobile', popularity: '1.3' },
  { item: 'JUnit', category: 'Mobile', popularity: '1.3' },
  { item: 'OOP', category: 'Mobile', popularity: '1.3' },
  { item: 'XML', category: 'Mobile', popularity: '1.3' },
  { item: 'HTTP', category: 'Mobile', popularity: '1.3' },
  { item: 'UI Design', category: 'Mobile', popularity: '1.3' },
  {
    item: 'Continuous Delivery',
    category: 'Mobile',
    popularity: '1.3',
  },
  { item: 'Swagger', category: 'Mobile', popularity: '1.3' },
  { item: 'GitFLow', category: 'Mobile', popularity: '1.3' },
  { item: 'IoT', category: 'Mobile', popularity: '1.3' },
  { item: 'OAuth', category: 'Mobile', popularity: '1.3' },
  { item: 'RxJava 2', category: 'Mobile', popularity: '1.3' },
  { item: 'self-motivated', category: 'Mobile', popularity: '1.3' },
  { item: 'Realm', category: 'Mobile', popularity: '1.3' },
  { item: 'Cocoa Touch', category: 'Mobile', popularity: '1.3' },
  { item: 'RxKotlin', category: 'Mobile', popularity: '1.3' },
  {
    item: 'dependency injection',
    category: 'Mobile',
    popularity: '1.3',
  },
  { item: 'Viper', category: 'Mobile', popularity: '1.3' },
  { item: 'BLE', category: 'Mobile', popularity: '1.3' },
  { item: 'SDK', category: 'Mobile', popularity: '1.3' },
  { item: 'Xamarin.Forms', category: 'Mobile', popularity: '1.3' },
  {
    item: 'Google Play Services',
    category: 'Mobile',
    popularity: '1.3',
  },
  { item: 'AndroidTV', category: 'Mobile', popularity: '1.3' },
  { item: 'JNI', category: 'Mobile', popularity: '1.3' },
  { item: 'Android Internals', category: 'Mobile', popularity: '1.3' },
  {
    item: 'Swift Package Manager',
    category: 'Mobile',
    popularity: '1.3',
  },
  { item: 'NFC', category: 'Mobile', popularity: '1.3' },
  { item: 'SQL', category: 'businessIntelligence', popularity: '74' },
  { item: 'ETL', category: 'businessIntelligence', popularity: '56' },
  {
    item: 'Power BI',
    category: 'businessIntelligence',
    popularity: '43',
  },
  {
    item: 'Python',
    category: 'businessIntelligence',
    popularity: '38',
  },
  { item: 'Azure', category: 'businessIntelligence', popularity: '33' },
  {
    item: 'SQL Server',
    category: 'businessIntelligence',
    popularity: '26',
  },
  {
    item: 'Tableau',
    category: 'businessIntelligence',
    popularity: '20',
  },
  { item: 'AWS', category: 'businessIntelligence', popularity: '13' },
  { item: 'R', category: 'businessIntelligence', popularity: '13' },
  { item: 'SSIS', category: 'businessIntelligence', popularity: '13' },
  {
    item: 'Oracle',
    category: 'businessIntelligence',
    popularity: '11',
  },
  {
    item: 'Machine Learning',
    category: 'businessIntelligence',
    popularity: '11',
  },
  { item: 'DWH', category: 'businessIntelligence', popularity: '11' },
  { item: 'DAX', category: 'businessIntelligence', popularity: '9.8' },
  { item: 'Git', category: 'businessIntelligence', popularity: '8.2' },
  { item: 'SSRS', category: 'businessIntelligence', popularity: '8.2' },
  { item: 'SSAS', category: 'businessIntelligence', popularity: '8.2' },
  {
    item: 'Spark',
    category: 'businessIntelligence',
    popularity: '6.6',
  },
  {
    item: 'T-SQL',
    category: 'businessIntelligence',
    popularity: '6.6',
  },
  {
    item: 'Azure Data Factory',
    category: 'businessIntelligence',
    popularity: '6.6',
  },
  {
    item: 'QlikView',
    category: 'businessIntelligence',
    popularity: '6.6',
  },
  {
    item: 'Leadership skills',
    category: 'businessIntelligence',
    popularity: '4.9',
  },
  {
    item: 'MS SQL',
    category: 'businessIntelligence',
    popularity: '4.9',
  },
  {
    item: 'Hadoop',
    category: 'businessIntelligence',
    popularity: '4.9',
  },
  {
    item: 'Microsoft',
    category: 'businessIntelligence',
    popularity: '4.9',
  },
  {
    item: 'Snowflake',
    category: 'businessIntelligence',
    popularity: '4.9',
  },
  {
    item: 'Databricks',
    category: 'businessIntelligence',
    popularity: '4.9',
  },
  {
    item: 'Microsoft Dynamics',
    category: 'businessIntelligence',
    popularity: '4.9',
  },
  {
    item: 'Informatica PowerCenter',
    category: 'businessIntelligence',
    popularity: '4.9',
  },
  {
    item: 'Alteryx',
    category: 'businessIntelligence',
    popularity: '4.9',
  },
  {
    item: 'Docker',
    category: 'businessIntelligence',
    popularity: '3.3',
  },
  {
    item: 'PostgreSQL',
    category: 'businessIntelligence',
    popularity: '3.3',
  },
  {
    item: 'CI/CD',
    category: 'businessIntelligence',
    popularity: '3.3',
  },
  {
    item: 'MongoDB',
    category: 'businessIntelligence',
    popularity: '3.3',
  },
  { item: 'GCP', category: 'businessIntelligence', popularity: '3.3' },
  {
    item: 'Cloud',
    category: 'businessIntelligence',
    popularity: '3.3',
  },
  {
    item: 'Grafana',
    category: 'businessIntelligence',
    popularity: '3.3',
  },
  {
    item: 'Business Intelligence',
    category: 'businessIntelligence',
    popularity: '3.3',
  },
  {
    item: 'Scikit-learn',
    category: 'businessIntelligence',
    popularity: '3.3',
  },
  {
    item: 'Analytics',
    category: 'businessIntelligence',
    popularity: '3.3',
  },
  { item: 'SAS', category: 'businessIntelligence', popularity: '3.3' },
  {
    item: 'Data Warehouse',
    category: 'businessIntelligence',
    popularity: '3.3',
  },
  {
    item: 'Microsoft Power BI',
    category: 'businessIntelligence',
    popularity: '3.3',
  },
  {
    item: 'SnapLogic',
    category: 'businessIntelligence',
    popularity: '3.3',
  },
  { item: 'Java', category: 'businessIntelligence', popularity: '1.6' },
  {
    item: 'Linux',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'MySQL',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Jenkins',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  { item: 'C#', category: 'businessIntelligence', popularity: '1.6' },
  {
    item: 'Scrum',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Kafka',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Maven',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: '.NET Core',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'DevOps',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  { item: 'UX', category: 'businessIntelligence', popularity: '1.6' },
  {
    item: 'Windows',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  { item: 'Unix', category: 'businessIntelligence', popularity: '1.6' },
  {
    item: 'Visual Studio',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'German',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Google Cloud Platform',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Salesforce',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Relational Databases',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Airflow',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  { item: 'SAP', category: 'businessIntelligence', popularity: '1.6' },
  {
    item: 'TensorFlow',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'PyTorch',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Pandas',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Data Engineering',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'ServiceNow',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Data Science',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Data modeling',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'PySpark',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'BigQuery',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Keras',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  { item: 'VBA', category: 'businessIntelligence', popularity: '1.6' },
  {
    item: 'design thinking',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  { item: 'BI', category: 'businessIntelligence', popularity: '1.6' },
  {
    item: 'ETL tools',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Azure Services',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Statistics',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  { item: 'DB2', category: 'businessIntelligence', popularity: '1.6' },
  {
    item: 'Qlik Sense',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Infrastructure as Code',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Energy',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Informatica',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Data Warehousing',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Data modelling',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Data Management',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Teradata',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  { item: 'qlik', category: 'businessIntelligence', popularity: '1.6' },
  {
    item: 'SAP BW',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Test Driven Development',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Azure Synapse Analytics',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Cloud platforms',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Cobol',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Oracle Data Integrator',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'Subversion',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'IBM DB2',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'AWS Athena',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  {
    item: 'matplotlib',
    category: 'businessIntelligence',
    popularity: '1.6',
  },
  { item: 'UX', category: 'UX', popularity: '74' },
  { item: 'UI', category: 'UX', popularity: '67' },
  { item: 'Figma', category: 'UX', popularity: '58' },
  { item: 'Sketch', category: 'UX', popularity: '45' },
  { item: 'Visual Design', category: 'UX', popularity: '32' },
  { item: 'Prototyping', category: 'UX', popularity: '28' },
  { item: 'Photoshop', category: 'UX', popularity: '28' },
  { item: 'Wireframing', category: 'UX', popularity: '25' },
  { item: 'Adobe XD', category: 'UX', popularity: '23' },
  { item: 'Illustrator', category: 'UX', popularity: '20' },
  { item: 'CSS', category: 'UX', popularity: '14' },
  { item: 'HTML', category: 'UX', popularity: '14' },
  { item: 'Mobile', category: 'UX', popularity: '14' },
  { item: 'InVision', category: 'UX', popularity: '14' },
  { item: 'Zeplin', category: 'UX', popularity: '12' },
  { item: 'Axure', category: 'UX', popularity: '12' },
  { item: 'Leadership skills', category: 'UX', popularity: '8.7' },
  { item: 'JIRA', category: 'UX', popularity: '7.2' },
  { item: 'design thinking', category: 'UX', popularity: '7.2' },
  { item: 'Product design', category: 'UX', popularity: '5.8' },
  { item: 'Scrum', category: 'UX', popularity: '4.3' },
  { item: 'Hotjar', category: 'UX', popularity: '4.3' },
  { item: 'Miro', category: 'UX', popularity: '4.3' },
  { item: 'User Research', category: 'UX', popularity: '4.3' },
  { item: 'Confluence', category: 'UX', popularity: '2.9' },
  { item: 'Business analysis', category: 'UX', popularity: '2.9' },
  { item: 'German', category: 'UX', popularity: '2.9' },
  { item: 'Web Applications', category: 'UX', popularity: '2.9' },
  { item: 'UI Design', category: 'UX', popularity: '2.9' },
  { item: 'Google Analytics', category: 'UX', popularity: '2.9' },
  { item: 'UX Design', category: 'UX', popularity: '2.9' },
  { item: 'UX Pin', category: 'UX', popularity: '2.9' },
  { item: 'UX Research', category: 'UX', popularity: '2.9' },
  { item: 'Principle', category: 'UX', popularity: '2.9' },
  { item: 'Slack', category: 'UX', popularity: '2.9' },
  { item: 'Abstact', category: 'UX', popularity: '2.9' },
  { item: 'User Centered Design', category: 'UX', popularity: '2.9' },
  {
    item: 'conducting qualitative research',
    category: 'UX',
    popularity: '2.9',
  },
  {
    item: 'Conducting business workshops',
    category: 'UX',
    popularity: '2.9',
  },
  { item: 'Design Systemes', category: 'UX', popularity: '2.9' },
  { item: 'G Suite', category: 'UX', popularity: '2.9' },
  { item: 'Portfolio', category: 'UX', popularity: '2.9' },
  { item: 'Motion Design', category: 'UX', popularity: '2.9' },
  { item: 'Problem Thinking', category: 'UX', popularity: '2.9' },
  { item: 'Design Process', category: 'UX', popularity: '2.9' },
  { item: 'Adobe After Effects', category: 'UX', popularity: '2.9' },
  { item: 'User tests', category: 'UX', popularity: '2.9' },
  { item: 'JavaScript', category: 'UX', popularity: '1.4' },
  { item: 'Python', category: 'UX', popularity: '1.4' },
  { item: 'C#', category: 'UX', popularity: '1.4' },
  { item: 'Agile', category: 'UX', popularity: '1.4' },
  { item: 'C++', category: 'UX', popularity: '1.4' },
  { item: 'JS', category: 'UX', popularity: '1.4' },
  { item: 'Personal organization', category: 'UX', popularity: '1.4' },
  { item: 'Presentation skills', category: 'UX', popularity: '1.4' },
  { item: 'Adobe Photoshop', category: 'UX', popularity: '1.4' },
  { item: 'Redshift', category: 'UX', popularity: '1.4' },
  { item: 'Flexibility', category: 'UX', popularity: '1.4' },
  { item: 'adobe suite', category: 'UX', popularity: '1.4' },
  { item: 'User Stories', category: 'UX', popularity: '1.4' },
  { item: 'Attention to details', category: 'UX', popularity: '1.4' },
  { item: 'Adobe Creative Suite', category: 'UX', popularity: '1.4' },
  { item: 'Webflow', category: 'UX', popularity: '1.4' },
  { item: 'After Effects', category: 'UX', popularity: '1.4' },
  { item: 'workshops', category: 'UX', popularity: '1.4' },
  { item: 'Proaktywność', category: 'UX', popularity: '1.4' },
  { item: 'Adobe CC', category: 'UX', popularity: '1.4' },
  { item: 'Adobe Illustrator', category: 'UX', popularity: '1.4' },
  { item: 'Google Optimize', category: 'UX', popularity: '1.4' },
  { item: 'interaction design', category: 'UX', popularity: '1.4' },
  { item: 'Design Systems', category: 'UX', popularity: '1.4' },
  { item: '3D', category: 'UX', popularity: '1.4' },
  { item: 'Mockups', category: 'UX', popularity: '1.4' },
  {
    item: 'Znajomość języka angielskiego na poziomie min.B2',
    category: 'UX',
    popularity: '1.4',
  },
  { item: 'web design', category: 'UX', popularity: '1.4' },
  { item: 'Russian', category: 'UX', popularity: '1.4' },
  {
    item: 'Znajomość narzędzi do prototypowania',
    category: 'UX',
    popularity: '1.4',
  },
  { item: 'Lean UX', category: 'UX', popularity: '1.4' },
  { item: 'Framer', category: 'UX', popularity: '1.4' },
  {
    item: 'Znajomość narzędzi Design Thinking',
    category: 'UX',
    popularity: '1.4',
  },
  { item: 'InDesign', category: 'UX', popularity: '1.4' },
  { item: 'Documentation', category: 'UX', popularity: '1.4' },
  { item: "Tworzenie wireframe'ów", category: 'UX', popularity: '1.4' },
  {
    item: 'Umiejętność organizowania własnej pracy',
    category: 'UX',
    popularity: '1.4',
  },
  { item: 'Znajomość rynku IT', category: 'UX', popularity: '1.4' },
  {
    item: 'Znajomość Motion Design (animacje)',
    category: 'UX',
    popularity: '1.4',
  },
  { item: 'Workshop facilitation', category: 'UX', popularity: '1.4' },
  { item: 'After Effect', category: 'UX', popularity: '1.4' },
  { item: 'Mobile application', category: 'UX', popularity: '1.4' },
  { item: 'Adobe Creative Cloud', category: 'UX', popularity: '1.4' },
  { item: 'Animation skills', category: 'UX', popularity: '1.4' },
  { item: 'usability tests', category: 'UX', popularity: '1.4' },
  { item: 'Maya', category: 'UX', popularity: '1.4' },
  { item: 'Marvel', category: 'UX', popularity: '1.4' },
  {
    item: 'min. 3 lata w projektowaniu UX',
    category: 'UX',
    popularity: '1.4',
  },
  { item: 'tworzenie person', category: 'UX', popularity: '1.4' },
  { item: 'Adobe Animate', category: 'UX', popularity: '1.4' },
  { item: 'Craft', category: 'UX', popularity: '1.4' },
  { item: 'Collaboration', category: 'UX', popularity: '1.4' },
  { item: 'Zbrush', category: 'UX', popularity: '1.4' },
  { item: 'C++', category: 'Embedded', popularity: '76' },
  { item: 'C', category: 'Embedded', popularity: '67' },
  { item: 'Linux', category: 'Embedded', popularity: '58' },
  { item: 'Python', category: 'Embedded', popularity: '38' },
  { item: 'Git', category: 'Embedded', popularity: '24' },
  { item: 'JIRA', category: 'Embedded', popularity: '16' },
  { item: 'German', category: 'Embedded', popularity: '13' },
  { item: 'SVN', category: 'Embedded', popularity: '13' },
  { item: 'Embedded', category: 'Embedded', popularity: '13' },
  { item: 'Autosar', category: 'Embedded', popularity: '13' },
  { item: 'Docker', category: 'Embedded', popularity: '8.9' },
  { item: 'Networking', category: 'Embedded', popularity: '8.9' },
  { item: 'RTOS', category: 'Embedded', popularity: '8.9' },
  { item: 'Yocto', category: 'Embedded', popularity: '8.9' },
  { item: 'Linux Kernel', category: 'Embedded', popularity: '8.9' },
  { item: 'Security', category: 'Embedded', popularity: '6.7' },
  { item: 'CMake', category: 'Embedded', popularity: '6.7' },
  { item: 'Routing', category: 'Embedded', popularity: '6.7' },
  { item: 'Java', category: 'Embedded', popularity: '4.4' },
  {
    item: 'Leadership skills',
    category: 'Embedded',
    popularity: '4.4',
  },
  { item: 'TDD', category: 'Embedded', popularity: '4.4' },
  { item: 'Perl', category: 'Embedded', popularity: '4.4' },
  { item: 'IoT', category: 'Embedded', popularity: '4.4' },
  { item: 'Qt', category: 'Embedded', popularity: '4.4' },
  {
    item: 'Network Protocols',
    category: 'Embedded',
    popularity: '4.4',
  },
  { item: 'Embedded Systems', category: 'Embedded', popularity: '4.4' },
  { item: 'WiFi', category: 'Embedded', popularity: '4.4' },
  { item: 'ARM', category: 'Embedded', popularity: '4.4' },
  { item: 'Bluetooth', category: 'Embedded', popularity: '4.4' },
  { item: 'Ethernet', category: 'Embedded', popularity: '4.4' },
  { item: 'QNX', category: 'Embedded', popularity: '4.4' },
  { item: 'ASPICE', category: 'Embedded', popularity: '4.4' },
  { item: 'electronics', category: 'Embedded', popularity: '4.4' },
  { item: 'JavaScript', category: 'Embedded', popularity: '2.2' },
  { item: 'SQL', category: 'Embedded', popularity: '2.2' },
  { item: 'REST', category: 'Embedded', popularity: '2.2' },
  { item: 'PHP', category: 'Embedded', popularity: '2.2' },
  { item: 'Azure', category: 'Embedded', popularity: '2.2' },
  { item: 'Jenkins', category: 'Embedded', popularity: '2.2' },
  { item: 'NoSQL', category: 'Embedded', popularity: '2.2' },
  { item: 'Django', category: 'Embedded', popularity: '2.2' },
  { item: 'Bash', category: 'Embedded', popularity: '2.2' },
  { item: 'MS SQL', category: 'Embedded', popularity: '2.2' },
  { item: 'Cloud', category: 'Embedded', popularity: '2.2' },
  { item: 'UML', category: 'Embedded', popularity: '2.2' },
  { item: 'Windows', category: 'Embedded', popularity: '2.2' },
  { item: 'OOP', category: 'Embedded', popularity: '2.2' },
  { item: 'Unix', category: 'Embedded', popularity: '2.2' },
  { item: 'Unit Testing', category: 'Embedded', popularity: '2.2' },
  { item: 'TCP/IP', category: 'Embedded', popularity: '2.2' },
  { item: 'AI', category: 'Embedded', popularity: '2.2' },
  { item: 'Networks', category: 'Embedded', popularity: '2.2' },
  { item: 'Gerrit', category: 'Embedded', popularity: '2.2' },
  { item: 'OpenCV', category: 'Embedded', popularity: '2.2' },
  { item: 'STL', category: 'Embedded', popularity: '2.2' },
  { item: 'Hardware', category: 'Embedded', popularity: '2.2' },
  { item: 'Debian', category: 'Embedded', popularity: '2.2' },
  { item: 'Computer Vision', category: 'Embedded', popularity: '2.2' },
  { item: 'C++11', category: 'Embedded', popularity: '2.2' },
  { item: 'Multithreading', category: 'Embedded', popularity: '2.2' },
  { item: 'MQTT', category: 'Embedded', popularity: '2.2' },
  { item: 'Open source', category: 'Embedded', popularity: '2.2' },
  { item: 'Automotive', category: 'Embedded', popularity: '2.2' },
  {
    item: 'Analityczne Myślenie',
    category: 'Embedded',
    popularity: '2.2',
  },
  { item: 'FreeRTOS', category: 'Embedded', popularity: '2.2' },
  { item: 'Zephyr', category: 'Embedded', popularity: '2.2' },
  { item: 'BLE', category: 'Embedded', popularity: '2.2' },
  { item: 'OOD', category: 'Embedded', popularity: '2.2' },
  { item: 'Clang', category: 'Embedded', popularity: '2.2' },
  {
    item: 'Scripting languages',
    category: 'Embedded',
    popularity: '2.2',
  },
  { item: 'GCC', category: 'Embedded', popularity: '2.2' },
  { item: 'Ownership', category: 'Embedded', popularity: '2.2' },
  { item: 'firmware', category: 'Embedded', popularity: '2.2' },
  { item: 'Gtest', category: 'Embedded', popularity: '2.2' },
  { item: 'CUDA', category: 'Embedded', popularity: '2.2' },
  { item: 'QML', category: 'Embedded', popularity: '2.2' },
  { item: 'STM32', category: 'Embedded', popularity: '2.2' },
  { item: 'ISO 13485', category: 'Embedded', popularity: '2.2' },
  { item: 'DPDK', category: 'Embedded', popularity: '2.2' },
  { item: 'Qualcomm', category: 'Embedded', popularity: '2.2' },
  { item: 'assembler', category: 'Embedded', popularity: '2.2' },
  { item: 'microcontrollers', category: 'Embedded', popularity: '2.2' },
  { item: 'U-Boot', category: 'Embedded', popularity: '2.2' },
  { item: 'Bugzilla', category: 'Embedded', popularity: '2.2' },
  { item: 'GPU', category: 'Embedded', popularity: '2.2' },
  { item: 'MISRA', category: 'Embedded', popularity: '2.2' },
  { item: 'CAPL', category: 'Embedded', popularity: '2.2' },
  {
    item: 'Testy automatyczne',
    category: 'Embedded',
    popularity: '2.2',
  },
  { item: 'SPICE', category: 'Embedded', popularity: '2.2' },
  { item: 'Bash scripting', category: 'Embedded', popularity: '2.2' },
  { item: 'konteneryzacja', category: 'Embedded', popularity: '2.2' },
  {
    item: 'Low Level Programming',
    category: 'Embedded',
    popularity: '2.2',
  },
  { item: 'GoogleTest', category: 'Embedded', popularity: '2.2' },
  { item: 'Kernel', category: 'Embedded', popularity: '2.2' },
  {
    item: 'Functional safety',
    category: 'Embedded',
    popularity: '2.2',
  },
  {
    item: 'low-level programming',
    category: 'Embedded',
    popularity: '2.2',
  },
  { item: 'Video processing', category: 'Embedded', popularity: '2.2' },
  {
    item: 'continuous integration tools',
    category: 'Embedded',
    popularity: '2.2',
  },
  {
    item: 'ST microcontrolers',
    category: 'Embedded',
    popularity: '2.2',
  },
  { item: 'Atlassian JIRA', category: 'Embedded', popularity: '2.2' },
  { item: 'Python', category: 'Other', popularity: '21' },
  {
    item: 'Organizational skills',
    category: 'Other',
    popularity: '17',
  },
  { item: 'Leadership skills', category: 'Other', popularity: '16' },
  { item: 'Project management', category: 'Other', popularity: '16' },
  { item: 'Salesforce', category: 'Other', popularity: '16' },
  { item: 'Git', category: 'Other', popularity: '14' },
  { item: 'Goal-oriented', category: 'Other', popularity: '14' },
  {
    item: 'Building relationships',
    category: 'Other',
    popularity: '14',
  },
  { item: 'Java', category: 'Other', popularity: '12' },
  { item: 'Docker', category: 'Other', popularity: '12' },
  { item: 'AWS', category: 'Other', popularity: '12' },
  { item: 'SQL', category: 'Other', popularity: '10' },
  { item: 'Azure', category: 'Other', popularity: '10' },
  { item: 'Terraform', category: 'Other', popularity: '10' },
  { item: 'JavaScript', category: 'Other', popularity: '8.6' },
  { item: 'CSS', category: 'Other', popularity: '8.6' },
  { item: 'Linux', category: 'Other', popularity: '8.6' },
  { item: 'HTML', category: 'Other', popularity: '8.6' },
  { item: 'JIRA', category: 'Other', popularity: '8.6' },
  { item: 'Jenkins', category: 'Other', popularity: '8.6' },
  { item: 'Presentation skills', category: 'Other', popularity: '8.6' },
  { item: 'Kubernetes', category: 'Other', popularity: '6.9' },
  { item: 'C++', category: 'Other', popularity: '6.9' },
  { item: 'DevOps', category: 'Other', popularity: '6.9' },
  { item: 'Apex', category: 'Other', popularity: '6.9' },
  { item: 'LWC', category: 'Other', popularity: '6.9' },
  { item: 'C#', category: 'Other', popularity: '5.2' },
  { item: 'CI/CD', category: 'Other', popularity: '5.2' },
  { item: 'Microservices', category: 'Other', popularity: '5.2' },
  { item: 'Cloud', category: 'Other', popularity: '5.2' },
  { item: 'German', category: 'Other', popularity: '5.2' },
  { item: 'Negotiation skills', category: 'Other', popularity: '5.2' },
  { item: 'web design', category: 'Other', popularity: '5.2' },
  { item: 'Client facing', category: 'Other', popularity: '5.2' },
  { item: '.NET', category: 'Other', popularity: '3.4' },
  { item: 'Node.js', category: 'Other', popularity: '3.4' },
  { item: 'Agile', category: 'Other', popularity: '3.4' },
  { item: 'Kotlin', category: 'Other', popularity: '3.4' },
  { item: 'Ansible', category: 'Other', popularity: '3.4' },
  { item: 'Kafka', category: 'Other', popularity: '3.4' },
  { item: 'GCP', category: 'Other', popularity: '3.4' },
  { item: 'Powershell', category: 'Other', popularity: '3.4' },
  { item: 'Visual Studio', category: 'Other', popularity: '3.4' },
  {
    item: 'Google Cloud Platform',
    category: 'Other',
    popularity: '3.4',
  },
  { item: 'OpenShift', category: 'Other', popularity: '3.4' },
  { item: 'Attention to detail', category: 'Other', popularity: '3.4' },
  { item: 'Ubuntu', category: 'Other', popularity: '3.4' },
  { item: 'IaC', category: 'Other', popularity: '3.4' },
  { item: 'Sales skills', category: 'Other', popularity: '3.4' },
  { item: 'SAP SD', category: 'Other', popularity: '3.4' },
  {
    item: 'Microsoft Dynamics 365',
    category: 'Other',
    popularity: '3.4',
  },
  { item: 'Aura Components', category: 'Other', popularity: '3.4' },
  { item: 'SAP CS', category: 'Other', popularity: '3.4' },
  { item: 'React', category: 'Other', popularity: '1.7' },
  { item: 'MySQL', category: 'Other', popularity: '1.7' },
  { item: 'Selenium', category: 'Other', popularity: '1.7' },
  { item: 'Confluence', category: 'Other', popularity: '1.7' },
  { item: '.NET Core', category: 'Other', popularity: '1.7' },
  { item: 'RabbitMQ', category: 'Other', popularity: '1.7' },
  { item: 'TDD', category: 'Other', popularity: '1.7' },
  { item: 'Scala', category: 'Other', popularity: '1.7' },
  { item: 'Business analysis', category: 'Other', popularity: '1.7' },
  { item: 'Golang', category: 'Other', popularity: '1.7' },
  { item: 'Spark', category: 'Other', popularity: '1.7' },
  { item: 'Test Automation', category: 'Other', popularity: '1.7' },
  { item: 'API', category: 'Other', popularity: '1.7' },
  { item: 'SQL Server', category: 'Other', popularity: '1.7' },
  { item: 'Oracle', category: 'Other', popularity: '1.7' },
  { item: 'ETL', category: 'Other', popularity: '1.7' },
  { item: 'Power BI', category: 'Other', popularity: '1.7' },
  { item: 'JSON', category: 'Other', popularity: '1.7' },
  { item: 'Azure DevOps', category: 'Other', popularity: '1.7' },
  { item: 'Hadoop', category: 'Other', popularity: '1.7' },
  { item: 'Go', category: 'Other', popularity: '1.7' },
  { item: 'DDD', category: 'Other', popularity: '1.7' },
  { item: 'XML', category: 'Other', popularity: '1.7' },
  { item: 'Product Management', category: 'Other', popularity: '1.7' },
  { item: 'Puppet', category: 'Other', popularity: '1.7' },
  { item: 'ITIL', category: 'Other', popularity: '1.7' },
  { item: 'Groovy', category: 'Other', popularity: '1.7' },
  { item: 'TCP/IP', category: 'Other', popularity: '1.7' },
  { item: 'Airflow', category: 'Other', popularity: '1.7' },
  { item: 'Backlog management', category: 'Other', popularity: '1.7' },
  { item: 'MS SQL Server', category: 'Other', popularity: '1.7' },
  { item: 'SAP', category: 'Other', popularity: '1.7' },
  { item: 'Networking', category: 'Other', popularity: '1.7' },
  { item: 'Risk management', category: 'Other', popularity: '1.7' },
  { item: 'Microsoft Azure', category: 'Other', popularity: '1.7' },
  { item: 'Google Analytics', category: 'Other', popularity: '1.7' },
  {
    item: 'Software Development',
    category: 'Other',
    popularity: '1.7',
  },
  { item: 'SSIS', category: 'Other', popularity: '1.7' },
  { item: 'UX Design', category: 'Other', popularity: '1.7' },
  { item: 'CRM', category: 'Other', popularity: '1.7' },
  { item: 'Snowflake', category: 'Other', popularity: '1.7' },
  { item: 'Swagger', category: 'Other', popularity: '1.7' },
  { item: 'Bamboo', category: 'Other', popularity: '1.7' },
  { item: 'LAN', category: 'Other', popularity: '1.7' },
  { item: 'Cisco', category: 'Other', popularity: '1.7' },
  { item: 'Architecture', category: 'Other', popularity: '1.7' },
  { item: 'Scripting', category: 'Other', popularity: '1.7' },
  {
    item: 'Product Management',
    category: 'Product Management',
    popularity: '71',
  },
  {
    item: 'Cooperation with stakeholders',
    category: 'Product Management',
    popularity: '51',
  },
  {
    item: 'Product vision',
    category: 'Product Management',
    popularity: '43',
  },
  {
    item: 'Leadership skills',
    category: 'Product Management',
    popularity: '41',
  },
  {
    item: 'Backlog management',
    category: 'Product Management',
    popularity: '39',
  },
  {
    item: 'Business analysis',
    category: 'Product Management',
    popularity: '37',
  },
  {
    item: 'Defining business requirements',
    category: 'Product Management',
    popularity: '35',
  },
  { item: 'Scrum', category: 'Product Management', popularity: '24' },
  { item: 'Agile', category: 'Product Management', popularity: '24' },
  {
    item: 'Market analytics',
    category: 'Product Management',
    popularity: '20',
  },
  { item: 'JIRA', category: 'Product Management', popularity: '12' },
  {
    item: 'Confluence',
    category: 'Product Management',
    popularity: '9.8',
  },
  {
    item: 'Budget management',
    category: 'Product Management',
    popularity: '9.8',
  },
  { item: 'SQL', category: 'Product Management', popularity: '7.8' },
  {
    item: 'Project management',
    category: 'Product Management',
    popularity: '7.8',
  },
  { item: 'SDLC', category: 'Product Management', popularity: '7.8' },
  { item: 'SaaS', category: 'Product Management', popularity: '5.9' },
  {
    item: 'Organizational skills',
    category: 'Product Management',
    popularity: '5.9',
  },
  { item: 'Java', category: 'Product Management', popularity: '3.9' },
  { item: 'Cloud', category: 'Product Management', popularity: '3.9' },
  {
    item: 'Analytical Thinking',
    category: 'Product Management',
    popularity: '3.9',
  },
  {
    item: 'Defining priorities',
    category: 'Product Management',
    popularity: '3.9',
  },
  {
    item: 'Personal organization',
    category: 'Product Management',
    popularity: '3.9',
  },
  {
    item: 'Building relationships',
    category: 'Product Management',
    popularity: '3.9',
  },
  {
    item: 'People Management',
    category: 'Product Management',
    popularity: '3.9',
  },
  {
    item: 'Technical background',
    category: 'Product Management',
    popularity: '3.9',
  },
  {
    item: 'JavaScript',
    category: 'Product Management',
    popularity: '2.0',
  },
  { item: 'Git', category: 'Product Management', popularity: '2.0' },
  { item: 'Docker', category: 'Product Management', popularity: '2.0' },
  { item: 'Python', category: 'Product Management', popularity: '2.0' },
  { item: 'AWS', category: 'Product Management', popularity: '2.0' },
  { item: 'React', category: 'Product Management', popularity: '2.0' },
  { item: 'REST', category: 'Product Management', popularity: '2.0' },
  { item: 'HTML', category: 'Product Management', popularity: '2.0' },
  { item: 'PHP', category: 'Product Management', popularity: '2.0' },
  {
    item: 'Jenkins',
    category: 'Product Management',
    popularity: '2.0',
  },
  { item: '.NET', category: 'Product Management', popularity: '2.0' },
  { item: 'iOS', category: 'Product Management', popularity: '2.0' },
  {
    item: 'Windows',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'SQL Server',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Machine Learning',
    category: 'Product Management',
    popularity: '2.0',
  },
  { item: 'Figma', category: 'Product Management', popularity: '2.0' },
  {
    item: 'Azure DevOps',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Design Patterns',
    category: 'Product Management',
    popularity: '2.0',
  },
  { item: 'German', category: 'Product Management', popularity: '2.0' },
  { item: 'Kanban', category: 'Product Management', popularity: '2.0' },
  {
    item: 'Windows Server',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Team management',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Big Data',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Salesforce',
    category: 'Product Management',
    popularity: '2.0',
  },
  { item: 'Xcode', category: 'Product Management', popularity: '2.0' },
  {
    item: 'Google Analytics',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Software Development',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Android Studio',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'MS Office',
    category: 'Product Management',
    popularity: '2.0',
  },
  { item: 'Mac OS', category: 'Product Management', popularity: '2.0' },
  {
    item: 'Attention to detail',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Presentation skills',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Creativity',
    category: 'Product Management',
    popularity: '2.0',
  },
  { item: 'Miro', category: 'Product Management', popularity: '2.0' },
  {
    item: 'Flexibility',
    category: 'Product Management',
    popularity: '2.0',
  },
  { item: 'Slack', category: 'Product Management', popularity: '2.0' },
  {
    item: 'Product design',
    category: 'Product Management',
    popularity: '2.0',
  },
  { item: 'AEM', category: 'Product Management', popularity: '2.0' },
  {
    item: 'User Stories',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Interpersonal skills',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Software Engineering',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Google Optimize',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Certificate',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Finance instruments area',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Strong analytical skills',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'consulting background',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'open mindset',
    category: 'Product Management',
    popularity: '2.0',
  },
  { item: 'EDA', category: 'Product Management', popularity: '2.0' },
  {
    item: 'Start-up experience',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'telecommunication network',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'development experience',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Development team management',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Product Owner Scrum Certificate',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Knowledge of the complete software development cycle',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'English - C1',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Service-oriented architecture',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Software delivery',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Management 3.0',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'LeSS framework',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'Ecommerce Experience',
    category: 'Product Management',
    popularity: '2.0',
  },
  {
    item: 'TypeScript',
    category: 'Product Management',
    popularity: '0.0',
  },
  { item: 'CSS', category: 'Product Management', popularity: '0.0' },
  { item: 'Linux', category: 'Product Management', popularity: '0.0' },
  {
    item: 'Angular',
    category: 'Product Management',
    popularity: '0.0',
  },
  {
    item: 'Kubernetes',
    category: 'Product Management',
    popularity: '0.0',
  },
  { item: 'Spring', category: 'Product Management', popularity: '0.0' },
  { item: 'Azure', category: 'Product Management', popularity: '0.0' },
  { item: 'MySQL', category: 'Product Management', popularity: '0.0' },
  {
    item: 'PostgreSQL',
    category: 'Product Management',
    popularity: '0.0',
  },
  { item: 'C#', category: 'Product Management', popularity: '0.0' },
  {
    item: 'Node.js',
    category: 'Product Management',
    popularity: '0.0',
  },
  { item: 'CI/CD', category: 'Product Management', popularity: '0.0' },
  { item: 'Vue.js', category: 'Product Management', popularity: '0.0' },
  {
    item: 'Hibernate',
    category: 'Product Management',
    popularity: '0.0',
  },
  {
    item: 'Project management',
    category: 'Project Management',
    popularity: '75',
  },
  { item: 'Scrum', category: 'Project Management', popularity: '54' },
  { item: 'JIRA', category: 'Project Management', popularity: '53' },
  {
    item: 'Team management',
    category: 'Project Management',
    popularity: '50',
  },
  {
    item: 'Leadership skills',
    category: 'Project Management',
    popularity: '43',
  },
  {
    item: 'Defining priorities',
    category: 'Project Management',
    popularity: '38',
  },
  {
    item: 'Personal organization',
    category: 'Project Management',
    popularity: '35',
  },
  {
    item: 'Business analysis',
    category: 'Project Management',
    popularity: '29',
  },
  {
    item: 'Risk management',
    category: 'Project Management',
    popularity: '26',
  },
  {
    item: 'Backlog management',
    category: 'Project Management',
    popularity: '22',
  },
  {
    item: 'Confluence',
    category: 'Project Management',
    popularity: '19',
  },
  { item: 'Agile', category: 'Project Management', popularity: '15' },
  { item: 'Kanban', category: 'Project Management', popularity: '13' },
  { item: 'UX', category: 'Project Management', popularity: '10' },
  { item: 'SQL', category: 'Project Management', popularity: '4.4' },
  { item: 'Asana', category: 'Project Management', popularity: '4.4' },
  {
    item: 'JavaScript',
    category: 'Project Management',
    popularity: '2.9',
  },
  { item: 'Git', category: 'Project Management', popularity: '2.9' },
  { item: 'Azure', category: 'Project Management', popularity: '2.9' },
  { item: 'Oracle', category: 'Project Management', popularity: '2.9' },
  {
    item: 'Azure DevOps',
    category: 'Project Management',
    popularity: '2.9',
  },
  { item: 'German', category: 'Project Management', popularity: '2.9' },
  {
    item: 'Coaching',
    category: 'Project Management',
    popularity: '2.9',
  },
  {
    item: 'E-commerce experience',
    category: 'Project Management',
    popularity: '2.9',
  },
  {
    item: 'Change Management',
    category: 'Project Management',
    popularity: '2.9',
  },
  { item: 'Python', category: 'Project Management', popularity: '1.5' },
  { item: 'AWS', category: 'Project Management', popularity: '1.5' },
  { item: 'React', category: 'Project Management', popularity: '1.5' },
  {
    item: 'Jenkins',
    category: 'Project Management',
    popularity: '1.5',
  },
  { item: '.NET', category: 'Project Management', popularity: '1.5' },
  {
    item: 'Node.js',
    category: 'Project Management',
    popularity: '1.5',
  },
  { item: 'C++', category: 'Project Management', popularity: '1.5' },
  {
    item: 'MongoDB',
    category: 'Project Management',
    popularity: '1.5',
  },
  { item: 'MS SQL', category: 'Project Management', popularity: '1.5' },
  { item: 'DevOps', category: 'Project Management', popularity: '1.5' },
  { item: 'Swift', category: 'Project Management', popularity: '1.5' },
  { item: 'GCP', category: 'Project Management', popularity: '1.5' },
  {
    item: 'Testing',
    category: 'Project Management',
    popularity: '1.5',
  },
  { item: 'Figma', category: 'Project Management', popularity: '1.5' },
  {
    item: 'Continuous Integration',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Java EE',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Magento',
    category: 'Project Management',
    popularity: '1.5',
  },
  { item: 'SAP', category: 'Project Management', popularity: '1.5' },
  {
    item: 'Networking',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Blockchain',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Cooperation with stakeholders',
    category: 'Project Management',
    popularity: '1.5',
  },
  { item: 'QA', category: 'Project Management', popularity: '1.5' },
  {
    item: 'Embedded',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Programming',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Business Intelligence',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'B2B sales',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Cryptography',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Feedback',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'SonarQube',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'PRINCE2',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'automation',
    category: 'Project Management',
    popularity: '1.5',
  },
  { item: 'French', category: 'Project Management', popularity: '1.5' },
  {
    item: 'Client service',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Technical background',
    category: 'Project Management',
    popularity: '1.5',
  },
  { item: 'PMI', category: 'Project Management', popularity: '1.5' },
  {
    item: 'Waterfall',
    category: 'Project Management',
    popularity: '1.5',
  },
  { item: 'Trello', category: 'Project Management', popularity: '1.5' },
  { item: 'PSM I', category: 'Project Management', popularity: '1.5' },
  {
    item: 'System Integration',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Release management',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Digital Experience',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Agile Project Management',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'PMP Certificate',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Strong analytical skills',
    category: 'Project Management',
    popularity: '1.5',
  },
  { item: 'PSM II', category: 'Project Management', popularity: '1.5' },
  {
    item: 'Programming background',
    category: 'Project Management',
    popularity: '1.5',
  },
  { item: 'Monday', category: 'Project Management', popularity: '1.5' },
  {
    item: 'Oracle BI',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'IT Infrastructure',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'IT technical Project Management',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'quickly understand business needs',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'building new features',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'system migration knowledge',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Good understanding of IT',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Certification in Agile',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Problem Management',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Background in technology',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Program Management',
    category: 'Project Management',
    popularity: '1.5',
  },
  { item: 'PMBOK', category: 'Project Management', popularity: '1.5' },
  {
    item: 'English - B2',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Presales',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'International experience',
    category: 'Project Management',
    popularity: '1.5',
  },
  { item: 'Prince', category: 'Project Management', popularity: '1.5' },
  {
    item: 'Experience in leading software projects',
    category: 'Project Management',
    popularity: '1.5',
  },
  {
    item: 'Issue tracking tool',
    category: 'Project Management',
    popularity: '1.5',
  },
  { item: 'Java', category: 'Project Management', popularity: '0.0' },
  { item: 'Docker', category: 'Project Management', popularity: '0.0' },
  {
    item: 'TypeScript',
    category: 'Project Management',
    popularity: '0.0',
  },
  { item: 'CSS', category: 'Project Management', popularity: '0.0' },
  { item: 'Linux', category: 'Project Management', popularity: '0.0' },
  { item: 'REST', category: 'Project Management', popularity: '0.0' },
  { item: 'HTML', category: 'Project Management', popularity: '0.0' },
  {
    item: 'Angular',
    category: 'Project Management',
    popularity: '0.0',
  },
  {
    item: 'Kubernetes',
    category: 'Project Management',
    popularity: '0.0',
  },
  { item: 'PHP', category: 'Project Management', popularity: '0.0' },
  { item: 'SQL', category: 'Data Engineering', popularity: '66' },
  { item: 'Python', category: 'Data Engineering', popularity: '66' },
  { item: 'Spark', category: 'Data Engineering', popularity: '44' },
  { item: 'AWS', category: 'Data Engineering', popularity: '35' },
  {
    item: 'Data Engineering',
    category: 'Data Engineering',
    popularity: '35',
  },
  { item: 'ETL', category: 'Data Engineering', popularity: '30' },
  { item: 'Java', category: 'Data Engineering', popularity: '29' },
  { item: 'Scala', category: 'Data Engineering', popularity: '25' },
  { item: 'Airflow', category: 'Data Engineering', popularity: '24' },
  { item: 'Hadoop', category: 'Data Engineering', popularity: '19' },
  { item: 'Azure', category: 'Data Engineering', popularity: '16' },
  {
    item: 'Kubernetes',
    category: 'Data Engineering',
    popularity: '15',
  },
  { item: 'Kafka', category: 'Data Engineering', popularity: '15' },
  { item: 'GCP', category: 'Data Engineering', popularity: '15' },
  { item: 'Git', category: 'Data Engineering', popularity: '14' },
  { item: 'NoSQL', category: 'Data Engineering', popularity: '14' },
  { item: 'Docker', category: 'Data Engineering', popularity: '8.8' },
  { item: 'MongoDB', category: 'Data Engineering', popularity: '8.8' },
  { item: 'MySQL', category: 'Data Engineering', popularity: '7.5' },
  {
    item: 'Leadership skills',
    category: 'Data Engineering',
    popularity: '7.5',
  },
  {
    item: 'Machine Learning',
    category: 'Data Engineering',
    popularity: '7.5',
  },
  {
    item: 'PostgreSQL',
    category: 'Data Engineering',
    popularity: '6.3',
  },
  { item: 'Power BI', category: 'Data Engineering', popularity: '6.3' },
  {
    item: 'Snowflake',
    category: 'Data Engineering',
    popularity: '6.3',
  },
  { item: 'PySpark', category: 'Data Engineering', popularity: '6.3' },
  { item: 'Linux', category: 'Data Engineering', popularity: '5.0' },
  {
    item: 'Terraform',
    category: 'Data Engineering',
    popularity: '5.0',
  },
  { item: 'Big Data', category: 'Data Engineering', popularity: '5.0' },
  {
    item: 'OpenShift',
    category: 'Data Engineering',
    popularity: '5.0',
  },
  { item: 'Pandas', category: 'Data Engineering', popularity: '5.0' },
  {
    item: 'Data modeling',
    category: 'Data Engineering',
    popularity: '5.0',
  },
  {
    item: 'Data Warehouse',
    category: 'Data Engineering',
    popularity: '5.0',
  },
  {
    item: 'English - C1',
    category: 'Data Engineering',
    popularity: '5.0',
  },
  { item: 'C#', category: 'Data Engineering', popularity: '3.8' },
  { item: 'CI/CD', category: 'Data Engineering', popularity: '3.8' },
  {
    item: 'Elasticsearch',
    category: 'Data Engineering',
    popularity: '3.8',
  },
  { item: 'Redis', category: 'Data Engineering', popularity: '3.8' },
  { item: 'Cloud', category: 'Data Engineering', popularity: '3.8' },
  {
    item: 'Cassandra',
    category: 'Data Engineering',
    popularity: '3.8',
  },
  { item: 'RDBMS', category: 'Data Engineering', popularity: '3.8' },
  { item: 'BigQuery', category: 'Data Engineering', popularity: '3.8' },
  { item: 'SAS', category: 'Data Engineering', popularity: '3.8' },
  {
    item: 'Databricks',
    category: 'Data Engineering',
    popularity: '3.8',
  },
  { item: 'REST', category: 'Data Engineering', popularity: '2.5' },
  { item: 'JIRA', category: 'Data Engineering', popularity: '2.5' },
  { item: 'Jenkins', category: 'Data Engineering', popularity: '2.5' },
  { item: '.NET', category: 'Data Engineering', popularity: '2.5' },
  { item: 'DevOps', category: 'Data Engineering', popularity: '2.5' },
  { item: 'Golang', category: 'Data Engineering', popularity: '2.5' },
  { item: 'Oracle', category: 'Data Engineering', popularity: '2.5' },
  { item: 'Shell', category: 'Data Engineering', popularity: '2.5' },
  {
    item: 'Google Cloud Platform',
    category: 'Data Engineering',
    popularity: '2.5',
  },
  {
    item: 'Distributed systems',
    category: 'Data Engineering',
    popularity: '2.5',
  },
  { item: 'JVM', category: 'Data Engineering', popularity: '2.5' },
  { item: 'SSIS', category: 'Data Engineering', popularity: '2.5' },
  { item: 'MVP', category: 'Data Engineering', popularity: '2.5' },
  { item: 'Hive', category: 'Data Engineering', popularity: '2.5' },
  { item: 'pytest', category: 'Data Engineering', popularity: '2.5' },
  { item: 'IoT', category: 'Data Engineering', popularity: '2.5' },
  { item: 'CosmosDB', category: 'Data Engineering', popularity: '2.5' },
  { item: 'Redshift', category: 'Data Engineering', popularity: '2.5' },
  {
    item: 'Azure Databricks',
    category: 'Data Engineering',
    popularity: '2.5',
  },
  { item: 'Kinesis', category: 'Data Engineering', popularity: '2.5' },
  { item: 'Presto', category: 'Data Engineering', popularity: '2.5' },
  {
    item: 'Data Warehousing',
    category: 'Data Engineering',
    popularity: '2.5',
  },
  {
    item: 'Data modelling',
    category: 'Data Engineering',
    popularity: '2.5',
  },
  { item: 'Talend', category: 'Data Engineering', popularity: '2.5' },
  {
    item: 'Data Factory',
    category: 'Data Engineering',
    popularity: '2.5',
  },
  {
    item: 'Experience in building complex data pipelines',
    category: 'Data Engineering',
    popularity: '2.5',
  },
  {
    item: 'JavaScript',
    category: 'Data Engineering',
    popularity: '1.3',
  },
  { item: 'Spring', category: 'Data Engineering', popularity: '1.3' },
  { item: 'Agile', category: 'Data Engineering', popularity: '1.3' },
  { item: 'Kotlin', category: 'Data Engineering', popularity: '1.3' },
  {
    item: 'Microservices',
    category: 'Data Engineering',
    popularity: '1.3',
  },
  { item: 'Ansible', category: 'Data Engineering', popularity: '1.3' },
  { item: 'Django', category: 'Data Engineering', popularity: '1.3' },
  {
    item: 'Confluence',
    category: 'Data Engineering',
    popularity: '1.3',
  },
  { item: 'Bash', category: 'Data Engineering', popularity: '1.3' },
  { item: 'API', category: 'Data Engineering', popularity: '1.3' },
  { item: 'GitLab', category: 'Data Engineering', popularity: '1.3' },
  {
    item: 'Powershell',
    category: 'Data Engineering',
    popularity: '1.3',
  },
  { item: 'Flask', category: 'Data Engineering', popularity: '1.3' },
  { item: 'German', category: 'Data Engineering', popularity: '1.3' },
  {
    item: 'Unit Testing',
    category: 'Data Engineering',
    popularity: '1.3',
  },
  {
    item: 'Databases',
    category: 'Data Engineering',
    popularity: '1.3',
  },
  {
    item: 'Enterprise Architect',
    category: 'Data Engineering',
    popularity: '1.3',
  },
  { item: 'R', category: 'Data Engineering', popularity: '1.3' },
  { item: 'Perl', category: 'Data Engineering', popularity: '1.3' },
  { item: 'MariaDB', category: 'Data Engineering', popularity: '1.3' },
  { item: 'DynamoDB', category: 'Data Engineering', popularity: '1.3' },
  {
    item: 'Data Science',
    category: 'Data Engineering',
    popularity: '1.3',
  },
  {
    item: 'OpenStack',
    category: 'Data Engineering',
    popularity: '1.3',
  },
  {
    item: 'Algorithms',
    category: 'Data Engineering',
    popularity: '1.3',
  },
  { item: 'NumPy', category: 'Data Engineering', popularity: '1.3' },
  { item: 'DAX', category: 'Data Engineering', popularity: '1.3' },
  {
    item: 'Web Development',
    category: 'Data Engineering',
    popularity: '1.3',
  },
  {
    item: 'Azure Data Factory',
    category: 'Data Engineering',
    popularity: '1.3',
  },
  {
    item: 'Scikit-learn',
    category: 'Data Engineering',
    popularity: '1.3',
  },
  {
    item: 'Communication',
    category: 'Data Engineering',
    popularity: '1.3',
  },
  { item: 'MS Azure', category: 'Data Engineering', popularity: '1.3' },
  { item: 'B2B sales', category: 'sales', popularity: '75' },
  { item: 'Sales experience', category: 'sales', popularity: '56' },
  {
    item: 'Building relationships',
    category: 'sales',
    popularity: '44',
  },
  { item: 'Deals closing', category: 'sales', popularity: '44' },
  { item: 'CRM', category: 'sales', popularity: '38' },
  { item: 'Goal-oriented', category: 'sales', popularity: '31' },
  { item: 'Sales skills', category: 'sales', popularity: '31' },
  { item: 'Client service', category: 'sales', popularity: '31' },
  { item: 'Presentation skills', category: 'sales', popularity: '25' },
  { item: 'Negotiation skills', category: 'sales', popularity: '19' },
  { item: 'Business culture', category: 'sales', popularity: '19' },
  { item: 'HR experience', category: 'sales', popularity: '13' },
  { item: 'IT Knowledge', category: 'sales', popularity: '13' },
  { item: 'Data Center', category: 'sales', popularity: '13' },
  { item: 'Cloud', category: 'sales', popularity: '6.3' },
  { item: 'Salesforce', category: 'sales', popularity: '6.3' },
  {
    item: 'Software Architecture',
    category: 'sales',
    popularity: '6.3',
  },
  { item: 'MS Office', category: 'sales', popularity: '6.3' },
  {
    item: 'Organizational skills',
    category: 'sales',
    popularity: '6.3',
  },
  { item: 'integration', category: 'sales', popularity: '6.3' },
  { item: 'Technical Writing', category: 'sales', popularity: '6.3' },
  { item: 'English min C1', category: 'sales', popularity: '6.3' },
  { item: 'Praca w zespole', category: 'sales', popularity: '6.3' },
  { item: 'Sandler', category: 'sales', popularity: '6.3' },
  {
    item: 'Język angielski na poziomie min. B2',
    category: 'sales',
    popularity: '6.3',
  },
  {
    item: 'Min 2 lata doświadczenia w sprzedaży usług',
    category: 'sales',
    popularity: '6.3',
  },
  {
    item: 'programming experience',
    category: 'sales',
    popularity: '6.3',
  },
  { item: 'Cold calling', category: 'sales', popularity: '6.3' },
  {
    item: 'HR industry experience',
    category: 'sales',
    popularity: '6.3',
  },
  {
    item: 'Active network of B2B Clients',
    category: 'sales',
    popularity: '6.3',
  },
  { item: 'Security Operations', category: 'sales', popularity: '6.3' },
  { item: 'JavaScript', category: 'sales', popularity: '0.0' },
  { item: 'Git', category: 'sales', popularity: '0.0' },
  { item: 'Java', category: 'sales', popularity: '0.0' },
  { item: 'Docker', category: 'sales', popularity: '0.0' },
  { item: 'SQL', category: 'sales', popularity: '0.0' },
  { item: 'Python', category: 'sales', popularity: '0.0' },
  { item: 'AWS', category: 'sales', popularity: '0.0' },
  { item: 'React', category: 'sales', popularity: '0.0' },
  { item: 'TypeScript', category: 'sales', popularity: '0.0' },
  { item: 'CSS', category: 'sales', popularity: '0.0' },
  { item: 'Linux', category: 'sales', popularity: '0.0' },
  { item: 'REST', category: 'sales', popularity: '0.0' },
  { item: 'HTML', category: 'sales', popularity: '0.0' },
  { item: 'Angular', category: 'sales', popularity: '0.0' },
  { item: 'Kubernetes', category: 'sales', popularity: '0.0' },
  { item: 'JIRA', category: 'sales', popularity: '0.0' },
  { item: 'PHP', category: 'sales', popularity: '0.0' },
  { item: 'Spring', category: 'sales', popularity: '0.0' },
  { item: 'Azure', category: 'sales', popularity: '0.0' },
  { item: 'MySQL', category: 'sales', popularity: '0.0' },
  { item: 'Jenkins', category: 'sales', popularity: '0.0' },
  { item: '.NET', category: 'sales', popularity: '0.0' },
  { item: 'PostgreSQL', category: 'sales', popularity: '0.0' },
  { item: 'C#', category: 'sales', popularity: '0.0' },
  { item: 'Node.js', category: 'sales', popularity: '0.0' },
  { item: 'Scrum', category: 'sales', popularity: '0.0' },
  { item: 'Agile', category: 'sales', popularity: '0.0' },
  { item: 'Leadership skills', category: 'sales', popularity: '0.0' },
  { item: 'CI/CD', category: 'sales', popularity: '0.0' },
  { item: 'Vue.js', category: 'sales', popularity: '0.0' },
  { item: 'Hibernate', category: 'sales', popularity: '0.0' },
  { item: 'Terraform', category: 'sales', popularity: '0.0' },
  { item: 'Redux', category: 'sales', popularity: '0.0' },
  { item: 'Kotlin', category: 'sales', popularity: '0.0' },
  { item: 'Microservices', category: 'sales', popularity: '0.0' },
  { item: 'C++', category: 'sales', popularity: '0.0' },
  { item: 'Ansible', category: 'sales', popularity: '0.0' },
  { item: 'Selenium', category: 'sales', popularity: '0.0' },
  { item: 'Kafka', category: 'sales', popularity: '0.0' },
  { item: 'Elasticsearch', category: 'sales', popularity: '0.0' },
  { item: 'NoSQL', category: 'sales', popularity: '0.0' },
  { item: 'MongoDB', category: 'sales', popularity: '0.0' },
  { item: 'Maven', category: 'sales', popularity: '0.0' },
  { item: 'Android', category: 'sales', popularity: '0.0' },
  { item: 'Django', category: 'sales', popularity: '0.0' },
  { item: 'Symfony', category: 'sales', popularity: '0.0' },
  { item: 'iOS', category: 'sales', popularity: '0.0' },
  { item: 'Confluence', category: 'sales', popularity: '0.0' },
  { item: '.NET Core', category: 'sales', popularity: '0.0' },
  { item: 'Bash', category: 'sales', popularity: '0.0' },
  { item: 'Redis', category: 'sales', popularity: '0.0' },
  { item: 'webpack', category: 'sales', popularity: '0.0' },
  { item: 'MS SQL', category: 'sales', popularity: '0.0' },
  { item: 'RabbitMQ', category: 'sales', popularity: '0.0' },
  { item: 'Sass', category: 'sales', popularity: '0.0' },
  { item: 'DevOps', category: 'sales', popularity: '0.0' },
  { item: 'GraphQL', category: 'sales', popularity: '0.0' },
  { item: 'Swift', category: 'sales', popularity: '0.0' },
  { item: 'TDD', category: 'sales', popularity: '0.0' },
  { item: 'Scala', category: 'sales', popularity: '0.0' },
  { item: 'React Native', category: 'sales', popularity: '0.0' },
  { item: 'UX', category: 'sales', popularity: '0.0' },
  { item: 'GCP', category: 'sales', popularity: '0.0' },
  { item: 'Project management', category: 'sales', popularity: '0.0' },
  { item: 'JUnit', category: 'sales', popularity: '0.0' },
  { item: 'Business analysis', category: 'sales', popularity: '0.0' },
  { item: 'Golang', category: 'sales', popularity: '0.0' },
  { item: 'Spark', category: 'sales', popularity: '0.0' },
  { item: 'jQuery', category: 'sales', popularity: '0.0' },
  {
    item: 'Digital Marketing',
    category: 'marketing',
    popularity: '46',
  },
  {
    item: 'Marketing campaigns',
    category: 'marketing',
    popularity: '46',
  },
  { item: 'Google Analytics', category: 'marketing', popularity: '38' },
  { item: 'Goal-oriented', category: 'marketing', popularity: '38' },
  { item: 'SEO', category: 'marketing', popularity: '38' },
  { item: 'E-commerce', category: 'marketing', popularity: '31' },
  {
    item: 'Leadership skills',
    category: 'marketing',
    popularity: '23',
  },
  { item: 'Social Media', category: 'marketing', popularity: '23' },
  { item: 'SEM', category: 'marketing', popularity: '23' },
  { item: 'Google Adwords', category: 'marketing', popularity: '23' },
  {
    item: 'Marketing Automation',
    category: 'marketing',
    popularity: '23',
  },
  { item: 'Copywriting', category: 'marketing', popularity: '15' },
  {
    item: 'nastawienie na realizację wyznaczonych celów',
    category: 'marketing',
    popularity: '15',
  },
  { item: 'Facebook Ads', category: 'marketing', popularity: '15' },
  { item: 'SQL', category: 'marketing', popularity: '7.7' },
  { item: 'Python', category: 'marketing', popularity: '7.7' },
  { item: 'Power BI', category: 'marketing', popularity: '7.7' },
  { item: 'Excel', category: 'marketing', popularity: '7.7' },
  { item: 'SaaS', category: 'marketing', popularity: '7.7' },
  { item: 'MS Office', category: 'marketing', popularity: '7.7' },
  { item: 'CRM', category: 'marketing', popularity: '7.7' },
  {
    item: 'Content Marketing',
    category: 'marketing',
    popularity: '7.7',
  },
  { item: 'Autonomy', category: 'marketing', popularity: '7.7' },
  { item: 'HubSpot', category: 'marketing', popularity: '7.7' },
  { item: 'Email marketing', category: 'marketing', popularity: '7.7' },
  { item: 'Reporting', category: 'marketing', popularity: '7.7' },
  { item: 'Spanish', category: 'marketing', popularity: '7.7' },
  { item: 'GTM', category: 'marketing', popularity: '7.7' },
  { item: 'Google BigQuery', category: 'marketing', popularity: '7.7' },
  {
    item: 'Interest in new technologies',
    category: 'marketing',
    popularity: '7.7',
  },
  { item: 'Data Studio', category: 'marketing', popularity: '7.7' },
  { item: 'Russian', category: 'marketing', popularity: '7.7' },
  { item: 'Google Ads', category: 'marketing', popularity: '7.7' },
  {
    item: 'min 5 lat doświadczenia w prowadzeniu kampanii PPC',
    category: 'marketing',
    popularity: '7.7',
  },
  {
    item: 'problem-solving attitude',
    category: 'marketing',
    popularity: '7.7',
  },
  { item: 'SentiOne', category: 'marketing', popularity: '7.7' },
  {
    item: '2 years in Performance Marketing',
    category: 'marketing',
    popularity: '7.7',
  },
  {
    item: 'B2-C1 English level',
    category: 'marketing',
    popularity: '7.7',
  },
  {
    item: 'perfect writing skills in Hungarian',
    category: 'marketing',
    popularity: '7.7',
  },
  {
    item: 'knowledge of graphic design',
    category: 'marketing',
    popularity: '7.7',
  },
  { item: 'SoTrender', category: 'marketing', popularity: '7.7' },
  { item: 'JavaScript', category: 'marketing', popularity: '0.0' },
  { item: 'Git', category: 'marketing', popularity: '0.0' },
  { item: 'Java', category: 'marketing', popularity: '0.0' },
  { item: 'Docker', category: 'marketing', popularity: '0.0' },
  { item: 'AWS', category: 'marketing', popularity: '0.0' },
  { item: 'React', category: 'marketing', popularity: '0.0' },
  { item: 'TypeScript', category: 'marketing', popularity: '0.0' },
  { item: 'CSS', category: 'marketing', popularity: '0.0' },
  { item: 'Linux', category: 'marketing', popularity: '0.0' },
  { item: 'REST', category: 'marketing', popularity: '0.0' },
  { item: 'HTML', category: 'marketing', popularity: '0.0' },
  { item: 'Angular', category: 'marketing', popularity: '0.0' },
  { item: 'Kubernetes', category: 'marketing', popularity: '0.0' },
  { item: 'JIRA', category: 'marketing', popularity: '0.0' },
  { item: 'PHP', category: 'marketing', popularity: '0.0' },
  { item: 'Spring', category: 'marketing', popularity: '0.0' },
  { item: 'Azure', category: 'marketing', popularity: '0.0' },
  { item: 'MySQL', category: 'marketing', popularity: '0.0' },
  { item: 'Jenkins', category: 'marketing', popularity: '0.0' },
  { item: '.NET', category: 'marketing', popularity: '0.0' },
  { item: 'PostgreSQL', category: 'marketing', popularity: '0.0' },
  { item: 'C#', category: 'marketing', popularity: '0.0' },
  { item: 'Node.js', category: 'marketing', popularity: '0.0' },
  { item: 'Scrum', category: 'marketing', popularity: '0.0' },
  { item: 'Agile', category: 'marketing', popularity: '0.0' },
  { item: 'CI/CD', category: 'marketing', popularity: '0.0' },
  { item: 'Vue.js', category: 'marketing', popularity: '0.0' },
  { item: 'Hibernate', category: 'marketing', popularity: '0.0' },
  { item: 'Terraform', category: 'marketing', popularity: '0.0' },
  { item: 'Redux', category: 'marketing', popularity: '0.0' },
  { item: 'Kotlin', category: 'marketing', popularity: '0.0' },
  { item: 'Microservices', category: 'marketing', popularity: '0.0' },
  { item: 'C++', category: 'marketing', popularity: '0.0' },
  { item: 'Ansible', category: 'marketing', popularity: '0.0' },
  { item: 'Selenium', category: 'marketing', popularity: '0.0' },
  { item: 'Kafka', category: 'marketing', popularity: '0.0' },
  { item: 'Elasticsearch', category: 'marketing', popularity: '0.0' },
  { item: 'NoSQL', category: 'marketing', popularity: '0.0' },
  { item: 'MongoDB', category: 'marketing', popularity: '0.0' },
  { item: 'Maven', category: 'marketing', popularity: '0.0' },
  { item: 'Android', category: 'marketing', popularity: '0.0' },
  { item: 'Django', category: 'marketing', popularity: '0.0' },
  { item: 'Symfony', category: 'marketing', popularity: '0.0' },
  { item: 'iOS', category: 'marketing', popularity: '0.0' },
  { item: 'Confluence', category: 'marketing', popularity: '0.0' },
  { item: '.NET Core', category: 'marketing', popularity: '0.0' },
  { item: 'Bash', category: 'marketing', popularity: '0.0' },
  { item: 'Redis', category: 'marketing', popularity: '0.0' },
  { item: 'webpack', category: 'marketing', popularity: '0.0' },
  { item: 'MS SQL', category: 'marketing', popularity: '0.0' },
  { item: 'RabbitMQ', category: 'marketing', popularity: '0.0' },
  { item: 'Sass', category: 'marketing', popularity: '0.0' },
  { item: 'DevOps', category: 'marketing', popularity: '0.0' },
  { item: 'GraphQL', category: 'marketing', popularity: '0.0' },
  { item: 'Swift', category: 'marketing', popularity: '0.0' },
  { item: 'TDD', category: 'marketing', popularity: '0.0' },
  { item: 'Scala', category: 'marketing', popularity: '0.0' },
  { item: 'React Native', category: 'marketing', popularity: '0.0' },
  { item: 'UX', category: 'marketing', popularity: '0.0' },
  { item: 'Security', category: 'Security', popularity: '92' },
  { item: 'Linux', category: 'Security', popularity: '34' },
  { item: 'Python', category: 'Security', popularity: '25' },
  { item: 'AWS', category: 'Security', popularity: '25' },
  { item: 'Azure', category: 'Security', popularity: '19' },
  { item: 'CISSP', category: 'Security', popularity: '19' },
  { item: 'Bash', category: 'Security', popularity: '13' },
  { item: 'Windows Server', category: 'Security', popularity: '13' },
  { item: 'SIEM', category: 'Security', popularity: '13' },
  { item: 'CEH', category: 'Security', popularity: '13' },
  { item: 'Kubernetes', category: 'Security', popularity: '11' },
  { item: 'Networks', category: 'Security', popularity: '11' },
  { item: 'IDS', category: 'Security', popularity: '11' },
  { item: 'WAF', category: 'Security', popularity: '11' },
  { item: 'CCSP', category: 'Security', popularity: '11' },
  { item: 'Java', category: 'Security', popularity: '9.4' },
  { item: 'Docker', category: 'Security', popularity: '9.4' },
  { item: 'IPS', category: 'Security', popularity: '9.4' },
  { item: 'CISA', category: 'Security', popularity: '9.4' },
  { item: 'SQL', category: 'Security', popularity: '5.7' },
  { item: 'MS SQL', category: 'Security', popularity: '5.7' },
  { item: 'Risk management', category: 'Security', popularity: '5.7' },
  { item: 'Cisco', category: 'Security', popularity: '5.7' },
  {
    item: 'Information Security',
    category: 'Security',
    popularity: '5.7',
  },
  { item: 'DevOps', category: 'Security', popularity: '3.8' },
  { item: 'Powershell', category: 'Security', popularity: '3.8' },
  { item: 'Shell', category: 'Security', popularity: '3.8' },
  { item: 'ITIL', category: 'Security', popularity: '3.8' },
  { item: 'Mac OS', category: 'Security', popularity: '3.8' },
  { item: 'Splunk', category: 'Security', popularity: '3.8' },
  { item: 'OWASP', category: 'Security', popularity: '3.8' },
  { item: 'OSCP', category: 'Security', popularity: '3.8' },
  { item: 'CISM', category: 'Security', popularity: '3.8' },
  {
    item: 'penetration testing',
    category: 'Security',
    popularity: '3.8',
  },
  { item: 'EDR', category: 'Security', popularity: '3.8' },
  { item: 'Git', category: 'Security', popularity: '1.9' },
  { item: 'JIRA', category: 'Security', popularity: '1.9' },
  { item: 'PHP', category: 'Security', popularity: '1.9' },
  { item: 'MySQL', category: 'Security', popularity: '1.9' },
  { item: 'PostgreSQL', category: 'Security', popularity: '1.9' },
  {
    item: 'Leadership skills',
    category: 'Security',
    popularity: '1.9',
  },
  { item: 'CI/CD', category: 'Security', popularity: '1.9' },
  { item: 'Terraform', category: 'Security', popularity: '1.9' },
  { item: 'C++', category: 'Security', popularity: '1.9' },
  { item: 'Ansible', category: 'Security', popularity: '1.9' },
  { item: 'Cloud', category: 'Security', popularity: '1.9' },
  { item: 'Windows', category: 'Security', popularity: '1.9' },
  { item: 'Oracle', category: 'Security', popularity: '1.9' },
  { item: 'Ruby', category: 'Security', popularity: '1.9' },
  { item: 'Power BI', category: 'Security', popularity: '1.9' },
  { item: 'Unix', category: 'Security', popularity: '1.9' },
  { item: 'Azure DevOps', category: 'Security', popularity: '1.9' },
  { item: 'German', category: 'Security', popularity: '1.9' },
  { item: 'Databases', category: 'Security', popularity: '1.9' },
  { item: 'Virtualization', category: 'Security', popularity: '1.9' },
  { item: 'HTTP', category: 'Security', popularity: '1.9' },
  { item: 'Grafana', category: 'Security', popularity: '1.9' },
  { item: 'Active Directory', category: 'Security', popularity: '1.9' },
  { item: 'TCP/IP', category: 'Security', popularity: '1.9' },
  { item: 'Kibana', category: 'Security', popularity: '1.9' },
  { item: 'MS Office', category: 'Security', popularity: '1.9' },
  { item: 'SDLC', category: 'Security', popularity: '1.9' },
  { item: 'Ubuntu', category: 'Security', popularity: '1.9' },
  { item: 'IT Security', category: 'Security', popularity: '1.9' },
  { item: 'Cryptography', category: 'Security', popularity: '1.9' },
  { item: 'IAM', category: 'Security', popularity: '1.9' },
  { item: 'Design', category: 'Security', popularity: '1.9' },
  { item: 'TOGAF', category: 'Security', popularity: '1.9' },
  { item: 'MQTT', category: 'Security', popularity: '1.9' },
  { item: 'Firewalls', category: 'Security', popularity: '1.9' },
  { item: 'Autosar', category: 'Security', popularity: '1.9' },
  { item: 'CI/CD pipelines', category: 'Security', popularity: '1.9' },
  { item: 'VPNs', category: 'Security', popularity: '1.9' },
  { item: 'ISO 27001', category: 'Security', popularity: '1.9' },
  { item: 'AD', category: 'Security', popularity: '1.9' },
  {
    item: 'Azure Active Directory',
    category: 'Security',
    popularity: '1.9',
  },
  { item: 'Containerization', category: 'Security', popularity: '1.9' },
  { item: 'Elastic Stack', category: 'Security', popularity: '1.9' },
  { item: 'SAST', category: 'Security', popularity: '1.9' },
  { item: 'SoC', category: 'Security', popularity: '1.9' },
  { item: 'IP routing', category: 'Security', popularity: '1.9' },
  { item: 'Cloud platforms', category: 'Security', popularity: '1.9' },
  {
    item: 'cloud architecture',
    category: 'Security',
    popularity: '1.9',
  },
  {
    item: 'Incident management',
    category: 'Security',
    popularity: '1.9',
  },
  { item: 'Pentesting', category: 'Security', popularity: '1.9' },
  { item: 'CAN', category: 'Security', popularity: '1.9' },
  {
    item: 'excellent communication skills',
    category: 'Security',
    popularity: '1.9',
  },
  { item: 'Solaris', category: 'Security', popularity: '1.9' },
  { item: 'PAM', category: 'Security', popularity: '1.9' },
  { item: 'DAST', category: 'Security', popularity: '1.9' },
  { item: 'OKTA', category: 'Security', popularity: '1.9' },
  { item: 'OWASP ASVS', category: 'Security', popularity: '1.9' },
  { item: 'GDPR', category: 'Security', popularity: '1.9' },
  { item: 'MFA', category: 'Security', popularity: '1.9' },
  { item: 'Google Workspace', category: 'Security', popularity: '1.9' },
  { item: 'Documentation', category: 'Security', popularity: '1.9' },
  { item: 'S-SDLC', category: 'Security', popularity: '1.9' },
  { item: 'Mainframe', category: 'Security', popularity: '1.9' },
  { item: 'OT', category: 'Security', popularity: '1.9' },
  {
    item: 'Network monitoring',
    category: 'Security',
    popularity: '1.9',
  },
  { item: 'Agile', category: 'Agile', popularity: '1.0e+2' },
  { item: 'Scrum', category: 'Agile', popularity: '88' },
  { item: 'JIRA', category: 'Agile', popularity: '42' },
  { item: 'Kanban', category: 'Agile', popularity: '38' },
  { item: 'Leadership skills', category: 'Agile', popularity: '27' },
  { item: 'Confluence', category: 'Agile', popularity: '19' },
  { item: 'SAFe', category: 'Agile', popularity: '15' },
  { item: 'Facilitation', category: 'Agile', popularity: '15' },
  { item: 'Project management', category: 'Agile', popularity: '12' },
  { item: 'Java', category: 'Agile', popularity: '3.8' },
  { item: 'UX', category: 'Agile', popularity: '3.8' },
  { item: 'Azure DevOps', category: 'Agile', popularity: '3.8' },
  { item: 'ITIL', category: 'Agile', popularity: '3.8' },
  { item: 'GitHub', category: 'Agile', popularity: '3.8' },
  {
    item: 'Personal organization',
    category: 'Agile',
    popularity: '3.8',
  },
  { item: 'Mobile Apps', category: 'Agile', popularity: '3.8' },
  { item: 'Lean', category: 'Agile', popularity: '3.8' },
  {
    item: 'Interpersonal skills',
    category: 'Agile',
    popularity: '3.8',
  },
  { item: 'Trello', category: 'Agile', popularity: '3.8' },
  {
    item: 'problem-solving skills',
    category: 'Agile',
    popularity: '3.8',
  },
  {
    item: 'At least 3 years of commercial experience',
    category: 'Agile',
    popularity: '3.8',
  },
  {
    item: 'Min B2 English level',
    category: 'Agile',
    popularity: '3.8',
  },
  {
    item: 'experience in a consulting company',
    category: 'Agile',
    popularity: '3.8',
  },
  { item: 'Advanced Analytics', category: 'Agile', popularity: '3.8' },
  { item: 'lean startup', category: 'Agile', popularity: '3.8' },
  {
    item: 'Working with cross-functional teams',
    category: 'Agile',
    popularity: '3.8',
  },
  { item: 'Prince', category: 'Agile', popularity: '3.8' },
  {
    item: 'Scrum Master Certification',
    category: 'Agile',
    popularity: '3.8',
  },
  { item: 'JavaScript', category: 'Agile', popularity: '0.0' },
  { item: 'Git', category: 'Agile', popularity: '0.0' },
  { item: 'Docker', category: 'Agile', popularity: '0.0' },
  { item: 'SQL', category: 'Agile', popularity: '0.0' },
  { item: 'Python', category: 'Agile', popularity: '0.0' },
  { item: 'AWS', category: 'Agile', popularity: '0.0' },
  { item: 'React', category: 'Agile', popularity: '0.0' },
  { item: 'TypeScript', category: 'Agile', popularity: '0.0' },
  { item: 'CSS', category: 'Agile', popularity: '0.0' },
  { item: 'Linux', category: 'Agile', popularity: '0.0' },
  { item: 'REST', category: 'Agile', popularity: '0.0' },
  { item: 'HTML', category: 'Agile', popularity: '0.0' },
  { item: 'Angular', category: 'Agile', popularity: '0.0' },
  { item: 'Kubernetes', category: 'Agile', popularity: '0.0' },
  { item: 'PHP', category: 'Agile', popularity: '0.0' },
  { item: 'Spring', category: 'Agile', popularity: '0.0' },
  { item: 'Azure', category: 'Agile', popularity: '0.0' },
  { item: 'MySQL', category: 'Agile', popularity: '0.0' },
  { item: 'Jenkins', category: 'Agile', popularity: '0.0' },
  { item: '.NET', category: 'Agile', popularity: '0.0' },
  { item: 'PostgreSQL', category: 'Agile', popularity: '0.0' },
  { item: 'C#', category: 'Agile', popularity: '0.0' },
  { item: 'Node.js', category: 'Agile', popularity: '0.0' },
  { item: 'CI/CD', category: 'Agile', popularity: '0.0' },
  { item: 'Vue.js', category: 'Agile', popularity: '0.0' },
  { item: 'Hibernate', category: 'Agile', popularity: '0.0' },
  { item: 'Terraform', category: 'Agile', popularity: '0.0' },
  { item: 'Redux', category: 'Agile', popularity: '0.0' },
  { item: 'Kotlin', category: 'Agile', popularity: '0.0' },
  { item: 'Microservices', category: 'Agile', popularity: '0.0' },
  { item: 'C++', category: 'Agile', popularity: '0.0' },
  { item: 'Ansible', category: 'Agile', popularity: '0.0' },
  { item: 'Selenium', category: 'Agile', popularity: '0.0' },
  { item: 'Kafka', category: 'Agile', popularity: '0.0' },
  { item: 'Elasticsearch', category: 'Agile', popularity: '0.0' },
  { item: 'NoSQL', category: 'Agile', popularity: '0.0' },
  { item: 'MongoDB', category: 'Agile', popularity: '0.0' },
  { item: 'Maven', category: 'Agile', popularity: '0.0' },
  { item: 'Android', category: 'Agile', popularity: '0.0' },
  { item: 'Django', category: 'Agile', popularity: '0.0' },
  { item: 'Symfony', category: 'Agile', popularity: '0.0' },
  { item: 'iOS', category: 'Agile', popularity: '0.0' },
  { item: '.NET Core', category: 'Agile', popularity: '0.0' },
  { item: 'Bash', category: 'Agile', popularity: '0.0' },
  { item: 'Redis', category: 'Agile', popularity: '0.0' },
  { item: 'webpack', category: 'Agile', popularity: '0.0' },
  { item: 'MS SQL', category: 'Agile', popularity: '0.0' },
  { item: 'RabbitMQ', category: 'Agile', popularity: '0.0' },
  { item: 'Sass', category: 'Agile', popularity: '0.0' },
  { item: 'DevOps', category: 'Agile', popularity: '0.0' },
  { item: 'GraphQL', category: 'Agile', popularity: '0.0' },
  { item: 'Swift', category: 'Agile', popularity: '0.0' },
  { item: 'TDD', category: 'Agile', popularity: '0.0' },
  { item: 'Scala', category: 'Agile', popularity: '0.0' },
  { item: 'React Native', category: 'Agile', popularity: '0.0' },
  { item: 'GCP', category: 'Agile', popularity: '0.0' },
  { item: 'JUnit', category: 'Agile', popularity: '0.0' },
  { item: 'Business analysis', category: 'Agile', popularity: '0.0' },
  { item: 'Golang', category: 'Agile', popularity: '0.0' },
  { item: 'Cloud', category: 'Agile', popularity: '0.0' },
  { item: 'Spark', category: 'Agile', popularity: '0.0' },
  { item: 'jQuery', category: 'Agile', popularity: '0.0' },
  { item: 'Postman', category: 'Agile', popularity: '0.0' },
  { item: 'Test Automation', category: 'Agile', popularity: '0.0' },
  { item: 'Laravel', category: 'Agile', popularity: '0.0' },
  { item: 'C', category: 'Agile', popularity: '0.0' },
  { item: 'API', category: 'Agile', popularity: '0.0' },
  { item: 'Ruby on Rails', category: 'Agile', popularity: '0.0' },
  { item: 'GitLab', category: 'Agile', popularity: '0.0' },
  { item: 'SOAP', category: 'Agile', popularity: '0.0' },
  { item: 'UML', category: 'Agile', popularity: '0.0' },
  { item: 'Windows', category: 'Agile', popularity: '0.0' },
  { item: 'Recruitment experience', category: 'hr', popularity: '82' },
  { item: 'Sourcing', category: 'hr', popularity: '76' },
  { item: 'HR experience', category: 'hr', popularity: '35' },
  { item: 'Employer Branding', category: 'hr', popularity: '29' },
  { item: 'ATS', category: 'hr', popularity: '29' },
  { item: 'Negotiation skills', category: 'hr', popularity: '24' },
  { item: 'Employment law', category: 'hr', popularity: '12' },
  { item: 'Candidate experience', category: 'hr', popularity: '12' },
  { item: 'JIRA', category: 'hr', popularity: '5.9' },
  { item: 'German', category: 'hr', popularity: '5.9' },
  { item: 'Teambuilding', category: 'hr', popularity: '5.9' },
  { item: 'Coaching', category: 'hr', popularity: '5.9' },
  { item: 'Payroll experience', category: 'hr', popularity: '5.9' },
  { item: 'Conflict resolution', category: 'hr', popularity: '5.9' },
  {
    item: 'Znajomość języka angielskiego na poziomie min.B2',
    category: 'hr',
    popularity: '5.9',
  },
  { item: 'HR Analytics', category: 'hr', popularity: '5.9' },
  { item: 'HRBP Experience', category: 'hr', popularity: '5.9' },
  { item: 'Passion for HR', category: 'hr', popularity: '5.9' },
  { item: 'Student status', category: 'hr', popularity: '5.9' },
  {
    item: 'doskonała organizacja swojej pracy',
    category: 'hr',
    popularity: '5.9',
  },
  { item: 'JavaScript', category: 'hr', popularity: '0.0' },
  { item: 'Git', category: 'hr', popularity: '0.0' },
  { item: 'Java', category: 'hr', popularity: '0.0' },
  { item: 'Docker', category: 'hr', popularity: '0.0' },
  { item: 'SQL', category: 'hr', popularity: '0.0' },
  { item: 'Python', category: 'hr', popularity: '0.0' },
  { item: 'AWS', category: 'hr', popularity: '0.0' },
  { item: 'React', category: 'hr', popularity: '0.0' },
  { item: 'TypeScript', category: 'hr', popularity: '0.0' },
  { item: 'CSS', category: 'hr', popularity: '0.0' },
  { item: 'Linux', category: 'hr', popularity: '0.0' },
  { item: 'REST', category: 'hr', popularity: '0.0' },
  { item: 'HTML', category: 'hr', popularity: '0.0' },
  { item: 'Angular', category: 'hr', popularity: '0.0' },
  { item: 'Kubernetes', category: 'hr', popularity: '0.0' },
  { item: 'PHP', category: 'hr', popularity: '0.0' },
  { item: 'Spring', category: 'hr', popularity: '0.0' },
  { item: 'Azure', category: 'hr', popularity: '0.0' },
  { item: 'MySQL', category: 'hr', popularity: '0.0' },
  { item: 'Jenkins', category: 'hr', popularity: '0.0' },
  { item: '.NET', category: 'hr', popularity: '0.0' },
  { item: 'PostgreSQL', category: 'hr', popularity: '0.0' },
  { item: 'C#', category: 'hr', popularity: '0.0' },
  { item: 'Node.js', category: 'hr', popularity: '0.0' },
  { item: 'Scrum', category: 'hr', popularity: '0.0' },
  { item: 'Agile', category: 'hr', popularity: '0.0' },
  { item: 'Leadership skills', category: 'hr', popularity: '0.0' },
  { item: 'CI/CD', category: 'hr', popularity: '0.0' },
  { item: 'Vue.js', category: 'hr', popularity: '0.0' },
  { item: 'Hibernate', category: 'hr', popularity: '0.0' },
  { item: 'Terraform', category: 'hr', popularity: '0.0' },
  { item: 'Redux', category: 'hr', popularity: '0.0' },
  { item: 'Kotlin', category: 'hr', popularity: '0.0' },
  { item: 'Microservices', category: 'hr', popularity: '0.0' },
  { item: 'C++', category: 'hr', popularity: '0.0' },
  { item: 'Ansible', category: 'hr', popularity: '0.0' },
  { item: 'Selenium', category: 'hr', popularity: '0.0' },
  { item: 'Kafka', category: 'hr', popularity: '0.0' },
  { item: 'Elasticsearch', category: 'hr', popularity: '0.0' },
  { item: 'NoSQL', category: 'hr', popularity: '0.0' },
  { item: 'MongoDB', category: 'hr', popularity: '0.0' },
  { item: 'Maven', category: 'hr', popularity: '0.0' },
  { item: 'Android', category: 'hr', popularity: '0.0' },
  { item: 'Django', category: 'hr', popularity: '0.0' },
  { item: 'Symfony', category: 'hr', popularity: '0.0' },
  { item: 'iOS', category: 'hr', popularity: '0.0' },
  { item: 'Confluence', category: 'hr', popularity: '0.0' },
  { item: '.NET Core', category: 'hr', popularity: '0.0' },
  { item: 'Bash', category: 'hr', popularity: '0.0' },
  { item: 'Redis', category: 'hr', popularity: '0.0' },
  { item: 'webpack', category: 'hr', popularity: '0.0' },
  { item: 'MS SQL', category: 'hr', popularity: '0.0' },
  { item: 'RabbitMQ', category: 'hr', popularity: '0.0' },
  { item: 'Sass', category: 'hr', popularity: '0.0' },
  { item: 'DevOps', category: 'hr', popularity: '0.0' },
  { item: 'GraphQL', category: 'hr', popularity: '0.0' },
  { item: 'Swift', category: 'hr', popularity: '0.0' },
  { item: 'TDD', category: 'hr', popularity: '0.0' },
  { item: 'Scala', category: 'hr', popularity: '0.0' },
  { item: 'React Native', category: 'hr', popularity: '0.0' },
  { item: 'UX', category: 'hr', popularity: '0.0' },
  { item: 'GCP', category: 'hr', popularity: '0.0' },
  { item: 'Project management', category: 'hr', popularity: '0.0' },
  { item: 'JUnit', category: 'hr', popularity: '0.0' },
  { item: 'Business analysis', category: 'hr', popularity: '0.0' },
  { item: 'Golang', category: 'hr', popularity: '0.0' },
  { item: 'Cloud', category: 'hr', popularity: '0.0' },
  { item: 'Spark', category: 'hr', popularity: '0.0' },
  { item: 'jQuery', category: 'hr', popularity: '0.0' },
  { item: 'Postman', category: 'hr', popularity: '0.0' },
  { item: 'Test Automation', category: 'hr', popularity: '0.0' },
  { item: 'Laravel', category: 'hr', popularity: '0.0' },
  { item: 'C', category: 'hr', popularity: '0.0' },
  { item: 'API', category: 'hr', popularity: '0.0' },
  { item: 'Ruby on Rails', category: 'hr', popularity: '0.0' },
  { item: 'GitLab', category: 'hr', popularity: '0.0' },
  { item: 'SOAP', category: 'hr', popularity: '0.0' },
  { item: 'UML', category: 'hr', popularity: '0.0' },
  { item: 'Windows', category: 'hr', popularity: '0.0' },
  { item: 'SQL Server', category: 'hr', popularity: '0.0' },
  {
    item: 'Unreal Engine',
    category: 'Game Development',
    popularity: '63',
  },
  { item: 'Unity', category: 'Game Development', popularity: '50' },
  { item: 'C#', category: 'Game Development', popularity: '38' },
  { item: 'C++', category: 'Game Development', popularity: '38' },
  {
    item: 'JavaScript',
    category: 'Game Development',
    popularity: '25',
  },
  { item: 'Java', category: 'Game Development', popularity: '13' },
  { item: 'CSS', category: 'Game Development', popularity: '13' },
  { item: '.NET', category: 'Game Development', popularity: '13' },
  { item: 'TDD', category: 'Game Development', popularity: '13' },
  { item: 'OOP', category: 'Game Development', popularity: '13' },
  {
    item: 'Clean Code',
    category: 'Game Development',
    popularity: '13',
  },
  {
    item: 'Design Patterns',
    category: 'Game Development',
    popularity: '13',
  },
  { item: 'CI', category: 'Game Development', popularity: '13' },
  { item: 'Mobile', category: 'Game Development', popularity: '13' },
  { item: 'CD', category: 'Game Development', popularity: '13' },
  { item: 'adobe', category: 'Game Development', popularity: '13' },
  {
    item: 'Unreal Engine 4',
    category: 'Game Development',
    popularity: '13',
  },
  {
    item: 'objectivec',
    category: 'Game Development',
    popularity: '13',
  },
  { item: 'Git', category: 'Game Development', popularity: '0.0' },
  { item: 'Docker', category: 'Game Development', popularity: '0.0' },
  { item: 'SQL', category: 'Game Development', popularity: '0.0' },
  { item: 'Python', category: 'Game Development', popularity: '0.0' },
  { item: 'AWS', category: 'Game Development', popularity: '0.0' },
  { item: 'React', category: 'Game Development', popularity: '0.0' },
  {
    item: 'TypeScript',
    category: 'Game Development',
    popularity: '0.0',
  },
  { item: 'Linux', category: 'Game Development', popularity: '0.0' },
  { item: 'REST', category: 'Game Development', popularity: '0.0' },
  { item: 'HTML', category: 'Game Development', popularity: '0.0' },
  { item: 'Angular', category: 'Game Development', popularity: '0.0' },
  {
    item: 'Kubernetes',
    category: 'Game Development',
    popularity: '0.0',
  },
  { item: 'JIRA', category: 'Game Development', popularity: '0.0' },
  { item: 'PHP', category: 'Game Development', popularity: '0.0' },
  { item: 'Spring', category: 'Game Development', popularity: '0.0' },
  { item: 'Azure', category: 'Game Development', popularity: '0.0' },
  { item: 'MySQL', category: 'Game Development', popularity: '0.0' },
  { item: 'Jenkins', category: 'Game Development', popularity: '0.0' },
  {
    item: 'PostgreSQL',
    category: 'Game Development',
    popularity: '0.0',
  },
  { item: 'Node.js', category: 'Game Development', popularity: '0.0' },
  { item: 'Scrum', category: 'Game Development', popularity: '0.0' },
  { item: 'Agile', category: 'Game Development', popularity: '0.0' },
  {
    item: 'Leadership skills',
    category: 'Game Development',
    popularity: '0.0',
  },
  { item: 'CI/CD', category: 'Game Development', popularity: '0.0' },
  { item: 'Vue.js', category: 'Game Development', popularity: '0.0' },
  {
    item: 'Hibernate',
    category: 'Game Development',
    popularity: '0.0',
  },
  {
    item: 'Terraform',
    category: 'Game Development',
    popularity: '0.0',
  },
  { item: 'Redux', category: 'Game Development', popularity: '0.0' },
  { item: 'Kotlin', category: 'Game Development', popularity: '0.0' },
  {
    item: 'Microservices',
    category: 'Game Development',
    popularity: '0.0',
  },
  { item: 'Ansible', category: 'Game Development', popularity: '0.0' },
  { item: 'Selenium', category: 'Game Development', popularity: '0.0' },
  { item: 'Kafka', category: 'Game Development', popularity: '0.0' },
  {
    item: 'Elasticsearch',
    category: 'Game Development',
    popularity: '0.0',
  },
  { item: 'NoSQL', category: 'Game Development', popularity: '0.0' },
  { item: 'MongoDB', category: 'Game Development', popularity: '0.0' },
  { item: 'Maven', category: 'Game Development', popularity: '0.0' },
  { item: 'Android', category: 'Game Development', popularity: '0.0' },
  { item: 'Django', category: 'Game Development', popularity: '0.0' },
  { item: 'Symfony', category: 'Game Development', popularity: '0.0' },
  { item: 'iOS', category: 'Game Development', popularity: '0.0' },
  {
    item: 'Confluence',
    category: 'Game Development',
    popularity: '0.0',
  },
  {
    item: '.NET Core',
    category: 'Game Development',
    popularity: '0.0',
  },
  { item: 'Bash', category: 'Game Development', popularity: '0.0' },
  { item: 'Redis', category: 'Game Development', popularity: '0.0' },
  { item: 'webpack', category: 'Game Development', popularity: '0.0' },
  { item: 'MS SQL', category: 'Game Development', popularity: '0.0' },
  { item: 'RabbitMQ', category: 'Game Development', popularity: '0.0' },
  { item: 'Sass', category: 'Game Development', popularity: '0.0' },
  { item: 'DevOps', category: 'Game Development', popularity: '0.0' },
  { item: 'GraphQL', category: 'Game Development', popularity: '0.0' },
  { item: 'Swift', category: 'Game Development', popularity: '0.0' },
  { item: 'Scala', category: 'Game Development', popularity: '0.0' },
  {
    item: 'React Native',
    category: 'Game Development',
    popularity: '0.0',
  },
  { item: 'UX', category: 'Game Development', popularity: '0.0' },
  { item: 'GCP', category: 'Game Development', popularity: '0.0' },
  {
    item: 'Project management',
    category: 'Game Development',
    popularity: '0.0',
  },
  { item: 'JUnit', category: 'Game Development', popularity: '0.0' },
  {
    item: 'Business analysis',
    category: 'Game Development',
    popularity: '0.0',
  },
  { item: 'Golang', category: 'Game Development', popularity: '0.0' },
  { item: 'Cloud', category: 'Game Development', popularity: '0.0' },
  { item: 'Spark', category: 'Game Development', popularity: '0.0' },
  { item: 'jQuery', category: 'Game Development', popularity: '0.0' },
  { item: 'Postman', category: 'Game Development', popularity: '0.0' },
  {
    item: 'Test Automation',
    category: 'Game Development',
    popularity: '0.0',
  },
  { item: 'Laravel', category: 'Game Development', popularity: '0.0' },
  { item: 'C', category: 'Game Development', popularity: '0.0' },
  { item: 'API', category: 'Game Development', popularity: '0.0' },
  {
    item: 'Ruby on Rails',
    category: 'Game Development',
    popularity: '0.0',
  },
  { item: 'GitLab', category: 'Game Development', popularity: '0.0' },
  { item: 'SOAP', category: 'Game Development', popularity: '0.0' },
  { item: 'UML', category: 'Game Development', popularity: '0.0' },
  { item: 'Windows', category: 'Game Development', popularity: '0.0' },
  {
    item: 'SQL Server',
    category: 'Game Development',
    popularity: '0.0',
  },
  { item: 'Oracle', category: 'Game Development', popularity: '0.0' },
  { item: 'Testing', category: 'Game Development', popularity: '0.0' },
  {
    item: 'Entity Framework',
    category: 'Game Development',
    popularity: '0.0',
  },
  {
    item: 'Machine Learning',
    category: 'Game Development',
    popularity: '0.0',
  },
  { item: 'ETL', category: 'Game Development', popularity: '0.0' },
  { item: 'UI', category: 'Game Development', popularity: '0.0' },
  { item: 'Ruby', category: 'Game Development', popularity: '0.0' },
  {
    item: 'Powershell',
    category: 'Game Development',
    popularity: '0.0',
  },
  { item: 'Excel', category: 'backoffice', popularity: '1.0e+2' },
  { item: 'MS Office', category: 'backoffice', popularity: '1.0e+2' },
  {
    item: 'Accounting experience',
    category: 'backoffice',
    popularity: '1.0e+2',
  },
  { item: 'Goal-oriented', category: 'backoffice', popularity: '50' },
  { item: 'Data analysis', category: 'backoffice', popularity: '50' },
  { item: 'Autonomy', category: 'backoffice', popularity: '50' },
  { item: 'MS Windows', category: 'backoffice', popularity: '50' },
  {
    item: 'Financial planning',
    category: 'backoffice',
    popularity: '50',
  },
  { item: 'JavaScript', category: 'backoffice', popularity: '0.0' },
  { item: 'Git', category: 'backoffice', popularity: '0.0' },
  { item: 'Java', category: 'backoffice', popularity: '0.0' },
  { item: 'Docker', category: 'backoffice', popularity: '0.0' },
  { item: 'SQL', category: 'backoffice', popularity: '0.0' },
  { item: 'Python', category: 'backoffice', popularity: '0.0' },
  { item: 'AWS', category: 'backoffice', popularity: '0.0' },
  { item: 'React', category: 'backoffice', popularity: '0.0' },
  { item: 'TypeScript', category: 'backoffice', popularity: '0.0' },
  { item: 'CSS', category: 'backoffice', popularity: '0.0' },
  { item: 'Linux', category: 'backoffice', popularity: '0.0' },
  { item: 'REST', category: 'backoffice', popularity: '0.0' },
  { item: 'HTML', category: 'backoffice', popularity: '0.0' },
  { item: 'Angular', category: 'backoffice', popularity: '0.0' },
  { item: 'Kubernetes', category: 'backoffice', popularity: '0.0' },
  { item: 'JIRA', category: 'backoffice', popularity: '0.0' },
  { item: 'PHP', category: 'backoffice', popularity: '0.0' },
  { item: 'Spring', category: 'backoffice', popularity: '0.0' },
  { item: 'Azure', category: 'backoffice', popularity: '0.0' },
  { item: 'MySQL', category: 'backoffice', popularity: '0.0' },
  { item: 'Jenkins', category: 'backoffice', popularity: '0.0' },
  { item: '.NET', category: 'backoffice', popularity: '0.0' },
  { item: 'PostgreSQL', category: 'backoffice', popularity: '0.0' },
  { item: 'C#', category: 'backoffice', popularity: '0.0' },
  { item: 'Node.js', category: 'backoffice', popularity: '0.0' },
  { item: 'Scrum', category: 'backoffice', popularity: '0.0' },
  { item: 'Agile', category: 'backoffice', popularity: '0.0' },
  {
    item: 'Leadership skills',
    category: 'backoffice',
    popularity: '0.0',
  },
  { item: 'CI/CD', category: 'backoffice', popularity: '0.0' },
  { item: 'Vue.js', category: 'backoffice', popularity: '0.0' },
  { item: 'Hibernate', category: 'backoffice', popularity: '0.0' },
  { item: 'Terraform', category: 'backoffice', popularity: '0.0' },
  { item: 'Redux', category: 'backoffice', popularity: '0.0' },
  { item: 'Kotlin', category: 'backoffice', popularity: '0.0' },
  { item: 'Microservices', category: 'backoffice', popularity: '0.0' },
  { item: 'C++', category: 'backoffice', popularity: '0.0' },
  { item: 'Ansible', category: 'backoffice', popularity: '0.0' },
  { item: 'Selenium', category: 'backoffice', popularity: '0.0' },
  { item: 'Kafka', category: 'backoffice', popularity: '0.0' },
  { item: 'Elasticsearch', category: 'backoffice', popularity: '0.0' },
  { item: 'NoSQL', category: 'backoffice', popularity: '0.0' },
  { item: 'MongoDB', category: 'backoffice', popularity: '0.0' },
  { item: 'Maven', category: 'backoffice', popularity: '0.0' },
  { item: 'Android', category: 'backoffice', popularity: '0.0' },
  { item: 'Django', category: 'backoffice', popularity: '0.0' },
  { item: 'Symfony', category: 'backoffice', popularity: '0.0' },
  { item: 'iOS', category: 'backoffice', popularity: '0.0' },
  { item: 'Confluence', category: 'backoffice', popularity: '0.0' },
  { item: '.NET Core', category: 'backoffice', popularity: '0.0' },
  { item: 'Bash', category: 'backoffice', popularity: '0.0' },
  { item: 'Redis', category: 'backoffice', popularity: '0.0' },
  { item: 'webpack', category: 'backoffice', popularity: '0.0' },
  { item: 'MS SQL', category: 'backoffice', popularity: '0.0' },
  { item: 'RabbitMQ', category: 'backoffice', popularity: '0.0' },
  { item: 'Sass', category: 'backoffice', popularity: '0.0' },
  { item: 'DevOps', category: 'backoffice', popularity: '0.0' },
  { item: 'GraphQL', category: 'backoffice', popularity: '0.0' },
  { item: 'Swift', category: 'backoffice', popularity: '0.0' },
  { item: 'TDD', category: 'backoffice', popularity: '0.0' },
  { item: 'Scala', category: 'backoffice', popularity: '0.0' },
  { item: 'React Native', category: 'backoffice', popularity: '0.0' },
  { item: 'UX', category: 'backoffice', popularity: '0.0' },
  { item: 'GCP', category: 'backoffice', popularity: '0.0' },
  {
    item: 'Project management',
    category: 'backoffice',
    popularity: '0.0',
  },
  { item: 'JUnit', category: 'backoffice', popularity: '0.0' },
  {
    item: 'Business analysis',
    category: 'backoffice',
    popularity: '0.0',
  },
  { item: 'Golang', category: 'backoffice', popularity: '0.0' },
  { item: 'Cloud', category: 'backoffice', popularity: '0.0' },
  { item: 'Spark', category: 'backoffice', popularity: '0.0' },
  { item: 'jQuery', category: 'backoffice', popularity: '0.0' },
  { item: 'Postman', category: 'backoffice', popularity: '0.0' },
  {
    item: 'Test Automation',
    category: 'backoffice',
    popularity: '0.0',
  },
  { item: 'Laravel', category: 'backoffice', popularity: '0.0' },
  { item: 'C', category: 'backoffice', popularity: '0.0' },
  { item: 'API', category: 'backoffice', popularity: '0.0' },
  { item: 'Ruby on Rails', category: 'backoffice', popularity: '0.0' },
  { item: 'GitLab', category: 'backoffice', popularity: '0.0' },
  { item: 'SOAP', category: 'backoffice', popularity: '0.0' },
  { item: 'UML', category: 'backoffice', popularity: '0.0' },
  { item: 'Windows', category: 'backoffice', popularity: '0.0' },
  { item: 'SQL Server', category: 'backoffice', popularity: '0.0' },
  { item: 'Oracle', category: 'backoffice', popularity: '0.0' },
  { item: 'Testing', category: 'backoffice', popularity: '0.0' },
  {
    item: 'Entity Framework',
    category: 'backoffice',
    popularity: '0.0',
  },
  {
    item: 'Machine Learning',
    category: 'backoffice',
    popularity: '0.0',
  },
  { item: 'OOP', category: 'backoffice', popularity: '0.0' },
  { item: 'ETL', category: 'backoffice', popularity: '0.0' },
  { item: 'UI', category: 'backoffice', popularity: '0.0' },
  { item: 'Ruby', category: 'backoffice', popularity: '0.0' },
  { item: 'Powershell', category: 'backoffice', popularity: '0.0' },
  { item: 'Figma', category: 'backoffice', popularity: '0.0' },
  { item: 'Power BI', category: 'backoffice', popularity: '0.0' },
];
