import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { Link } from '@hrme/shared';

import FacebookLogo from 'assets/logo/facebook.svg';
import LinkedinLogo from 'assets/logo/linkedin.svg';
import LanguageIcon from 'assets/logo/language.svg';

import { BoxSocialMediaProps } from './BoxSocialMedia.types';
import { useStyles } from './BoxSocialMedia.styles';

export const BoxSocialMedia: React.FC<BoxSocialMediaProps> = ({ data }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { home, facebook, linkedin } = data;

  return (
    <div className={classes.box}>
      <Typography className={classes.boxDescriptionTitle}>{'Social media'}</Typography>
      <Box mb={3} />

      {home?.map((homePage, idx) => (
        <>
          <div className={classes.socialMediaItem}>
            <div className={classes.logoContainer} style={{ backgroundImage: `url(${LanguageIcon})` }} />
            <Box mr={2} />
            <Link href={homePage}>strona domowa {idx + 1}</Link>
          </div>
          <Box mb={2} />
        </>
      ))}

      {!!facebook && (
        <>
          <div className={classes.socialMediaItem}>
            <div className={classes.logoContainer} style={{ backgroundImage: `url(${FacebookLogo})` }} />
            <Box mr={2} />
            <Link href={facebook}>facebook</Link>
          </div>
          <Box mb={2} />
        </>
      )}
      {!!linkedin && (
        <>
          <div className={classes.socialMediaItem}>
            <div className={classes.logoContainer} style={{ backgroundImage: `url(${LinkedinLogo})` }} />
            <Box mr={2} />
            <Link href={linkedin}>linkedin</Link>
          </div>
        </>
      )}
    </div>
  );
};
