import React from 'react';
import { Typography, useTheme } from '@material-ui/core';

import { BoxListItemProps } from './BoxListItem.types';
import { useStyles } from './BoxListItem.styles';

export const BoxListItem: React.FC<BoxListItemProps> = ({ label, description, content }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      <div className={classes.labelWrapper}>
        <Typography variant="body2" style={{ textAlign: 'left' }}>
          {label}
        </Typography>
        {!!description && <Typography variant="body2">{description}</Typography>}
      </div>
      {typeof content === 'string' && (
        <Typography variant="body2" style={{ fontWeight: 600 }}>
          {content}
        </Typography>
      )}

      {typeof content === 'object' && <Typography>{content}</Typography>}
    </div>
  );
};
