import React, { useState } from 'react';
import firebase from 'firebase';

import { onError, onSuccess } from 'providers/providersHelpers';
import { UserContext } from 'context/user/UserContext';
import { User } from 'context/user/UserContext.types';
import { firebaseRef } from 'api/firebaseRef';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { UserRequestCareerPath } from 'context/careerPath/CareerPathContext.types';

import { USER_PATH } from './UserProvider.constants';

export const UserProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | undefined>(undefined);
  const { currentUser } = useAuthentication();

  const fetchUserData = async () => {
    setLoading(true);

    const userSnapshot = await firebaseRef.firestore().collection(USER_PATH).doc(currentUser?.uid).get();

    const userData = userSnapshot.data() as User | undefined;
    if (currentUser !== null && userData !== undefined) {
      const { job_offers, ...data } = userData;

      setUser({ ...data });

      return onSuccess(setLoading, 'User data fetched');
    } else {
      return onError(setLoading, 'Unable to fetch user data.');
    }
  };

  const setUserData = async (user: User) => {
    setLoading(true);
    try {
      if (currentUser !== null) {
        const { uid } = currentUser;

        await firebaseRef.firestore().collection(USER_PATH).doc(uid).update(user);

        setUser(user);

        return onSuccess(setLoading, 'We have successfully updated your data.');
      } else {
        return onError(setLoading, 'Current user can not be null');
      }
    } catch (ex) {
      return onError(setLoading, `Unable to update user data. ${ex}`);
    }
  };

  const setInitialData = async () => {
    setLoading(true);

    try {
      if (currentUser !== null) {
        const { uid, email } = currentUser;

        const documentObject = {
          name: '',
          email,
        };

        await firebaseRef.firestore().collection(USER_PATH).doc(uid).set(documentObject);

        return onSuccess(setLoading, 'Account successfully created');
      } else {
        return onError(setLoading, 'Current user can not be null');
      }
    } catch (ex) {
      return onError(setLoading, 'Unable to set user initial data.');
    }
  };

  const addCareerPath = async (careerPath: UserRequestCareerPath) => {
    const firebaseUserPath = firebaseRef.firestore().collection(USER_PATH);
    const careerObject = { ...careerPath, pathId: firebaseUserPath.doc().id };

    setLoading(true);

    try {
      if (currentUser !== null && user) {
        const { uid } = currentUser;
        const collectionReference = firebaseUserPath.doc(uid);

        const result = await collectionReference.get();
        const careerPathData = result.data();
        const arrayAnswer = careerPathData?.taskHashes;

        if (arrayAnswer === undefined) collectionReference.set({ taskHashes: [] }, { merge: true });

        await collectionReference.update({
          careerPaths: firebase.firestore.FieldValue.arrayUnion(careerObject),
        });

        fetchUserData();

        setUser({ ...user, careerPaths: user.careerPaths });

        return onSuccess(setLoading, 'Career path added successfully.');
      } else {
        return onError(setLoading, 'Current user can not be null');
      }
    } catch (ex) {
      return onError(setLoading, `Unable to set new career path. ${ex}`);
    }
  };

  const updateCareerPath = async (pathId: string, data: UserRequestCareerPath) => {
    setLoading(true);

    try {
      if (currentUser !== null && user) {
        const { uid } = currentUser;
        const firebaseUserPath = firebaseRef.firestore().collection(USER_PATH);

        const collectionReference = firebaseUserPath.doc(uid);

        const careerPathReference = await firebaseRef.firestore().collection(USER_PATH).doc(uid).get();

        const result = careerPathReference.data();
        const indexKey = result?.careerPaths.findIndex((item: Record<string, string>) => item.pathId === pathId);

        const updatedArray = result?.careerPaths.map((item: Record<string, string>, index: number) =>
          index === indexKey ? { ...item, ...data } : item,
        );

        await collectionReference.update({
          careerPaths: updatedArray,
        });
        setUser({ ...user, careerPaths: updatedArray });

        return onSuccess(setLoading, 'Career path updated successfully.');
      } else {
        return onError(setLoading, 'Current user can not be null');
      }
    } catch (ex) {
      return onError(setLoading, `Unable to update career path. ${ex}`);
    }
  };

  const deleteCareerPath = async (pathId: string) => {
    setLoading(true);
    try {
      if (currentUser !== null && user && user.careerPaths) {
        const { uid } = currentUser;

        const careerPathsReference = firebaseRef.firestore().collection(USER_PATH).doc(uid);

        const result = await careerPathsReference.get();

        const careerPathData = result.data();

        const filteredArray = careerPathData?.careerPaths.filter(
          (item: Record<string, string>) => item.pathId !== pathId,
        );

        await careerPathsReference.update({
          careerPaths: filteredArray,
        });

        await fetchUserData();

        return onSuccess(setLoading, 'Career path deleted successfully.');
      } else {
        return onError(setLoading, 'Current user can not be null');
      }
    } catch (ex) {
      return onError(setLoading, `Unable to delete career path. ${ex}`);
    }
  };

  const updateTasksData = async (hash: string | undefined) => {
    setLoading(true);

    try {
      if (currentUser !== null && user) {
        const { uid } = currentUser;

        const careerPathReference = firebaseRef.firestore().collection(USER_PATH).doc(uid);
        const result = await careerPathReference.get();
        const careerPathData = result.data();
        const hashArray = careerPathData?.taskHashes;

        if (!hashArray.includes(hash)) {
          const updatedArray = [...hashArray, hash];

          await careerPathReference.update({ taskHashes: updatedArray });
          setUser({ ...user, taskHashes: updatedArray });
        } else {
          const index = hashArray.indexOf(hash);

          if (index > -1) {
            hashArray.splice(index, 1);
          }
          await careerPathReference.update({ taskHashes: hashArray });
          setUser({ ...user, taskHashes: hashArray });
        }

        return onSuccess(setLoading, 'Tasks updated successfully.');
      } else {
        return onError(setLoading, 'Current user can not be null');
      }
    } catch (ex) {
      return onError(setLoading, `Unable to update tasks. ${ex}`);
    }
  };

  return (
    <UserContext.Provider
      value={{
        loading,
        user,
        setUserData,
        addCareerPath,
        updateCareerPath,
        deleteCareerPath,
        fetchUserData,
        setInitialData,
        updateTasksData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
