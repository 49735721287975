import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory } from 'react-router-dom';
import { AlertMessage } from '@hrme/shared';

import { PortfolioSocialMediaState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';
import { useCareerPathFormDispatch } from 'hooks/useCareerPathFormDispatch/useCareerPathFormDispatch';
import { setPortfolioSocialMedia } from 'context/careerPathForm/careerPathActionCreators/careerPathActionCreators';
import { MainPaths } from 'routing/main/MainPaths';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { User } from 'context/user/UserContext.types';

import { PortfolioSocialMedia } from './PortfolioSocialMedia';

export const PortfolioSocialMediaContainer: React.FC = () => {
  const { user, setUserData } = useUserContext();
  const history = useHistory();
  const dispatch = useCareerPathFormDispatch();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });

  const methods = useForm<PortfolioSocialMediaState>({ defaultValues: user?.portfolioSocialMedia });
  if (!user) {
    return <Redirect to={MainPaths.welcomeMain} />;
  }

  const onFormSubmit = async (data: PortfolioSocialMediaState, isBack?: boolean, isIdle?: boolean) => {
    if (data.otherLinks === undefined) return;
    if (user.portfolioSocialMedia === data) {
      history.push(MainPaths.fillData);

      return;
    }

    const newUser: User = { ...user, portfolioSocialMedia: data };

    const result = await setUserData(newUser);

    if (!result.success) setShowAlertMessage({ show: true, success: false });
    if (result.success && isIdle) return dispatch(setPortfolioSocialMedia(data));

    if (result.success) {
      dispatch(setPortfolioSocialMedia(data));
      !isBack && history.push(MainPaths.fillData);
    }
  };

  return (
    <>
      <PortfolioSocialMedia onFormSubmit={onFormSubmit} methods={methods} />
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} />
    </>
  );
};
