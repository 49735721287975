import { UseFormMethods } from 'react-hook-form';

import { ExperienceState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';
import { ExperienceItemType } from 'ui/experienceItem/ExperienceItem.types';

export type ExperienceProps = {
  methods: UseFormMethods<ExperienceFormState>;
  onFormSubmit: (data: ExperienceFormState, isBack?: boolean, isIdle?: boolean) => Promise<void>;
};

export type ExperienceFormState = {
  experience: ExperienceState;
};

export const ADD_EXPERIENCE_ITEM: ExperienceItemType = {
  company: '',
  position: '',
  city: '',
  country: '',
  seniority: '',
  skills: [],
  type: '',
  stillWorking: false,
  start: '',
  end: '',
};
