import React, { useMemo } from 'react';
import { Box, useTheme, useMediaQuery } from '@material-ui/core';

import { BoxWrapper } from 'ui/boxes/boxWrapper/BoxWrapper';
import { BoxSummary } from 'ui/boxes/boxSummary/BoxSummary';
import { BoxStats } from 'ui/boxes/boxStats/BoxStats';
import { ExperienceTypesLabel } from 'app/profile/dashboard/careerPathsSection/newCareerPath/sectionInfo/SectionInfo.constants';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { findBarValues } from 'helpers/findBarValues/findBarValues';
import { getCareerPathSummaryBanner } from 'ui/baners/Banners';
import { useDiscountsOnTutorials } from 'hooks/useDiscountsOnTutorials/useDiscountsOnTutorials';

import { BoxPathSummaryData, CareerPathViewPanelRightProps } from './CareerPathViewPanelRight.types';
import { useStyles } from './CareerPathViewPanelRight.styles';
import { BOX_STAT_DATA } from './CareerPathViewPanelRight.constants';

export const CareerPathViewPanelRight: React.FC<CareerPathViewPanelRightProps> = React.memo(
  ({ careerPath, careerPathCategory, openModal, closeModal, idPath }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { user } = useUserContext();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const { banners } = useDiscountsOnTutorials();
    const [bannerOnThisPath] = banners.filter(
      (obj) => obj.career_path_with_banner.includes(idPath) && obj.showOnCareerPathSummeryBanner,
    );
    const showBanner = () => {
      if (bannerOnThisPath) return getCareerPathSummaryBanner(bannerOnThisPath);
    };
    const boxPathSummaryData: BoxPathSummaryData = useMemo(() => {
      const item = {
        title: 'Podsumowanie ścieżki',
        information: {
          'Twoj poziom:': ExperienceTypesLabel[careerPath.experienceLevel],
          'szukam pracy:': careerPath.openForOffers ? 'tak' : 'nie',
        },
        barValues: { ...findBarValues({ careerPath, user }) },
        pathId: careerPath.pathId,
        name: careerPath.name,
        pathName: careerPath.pathName,
      };

      return item;
    }, [user]);

    return (
      <div className={classes.panel}>
        <BoxWrapper>
          <BoxSummary data={boxPathSummaryData} openModal={openModal} closeModal={closeModal} />
        </BoxWrapper>
        <Box mb={1} />
        <BoxWrapper>
          <BoxStats data={BOX_STAT_DATA} careerPathCategory={careerPathCategory} />
        </BoxWrapper>

        {/*TODO: hidden temporarily; uncover opportunely*/}
        {/*
      <Box mb={1} />
      <BoxWrapper>
        <BoxOffers data={BOX_OFFERS_DATA} />
      </BoxWrapper>
*/}
        {isDesktop && showBanner()}
      </div>
    );
  },
);
