import React, { useState } from 'react';
import firebase from 'firebase';

import { JobOfferContext } from 'context/jobOffer/JobOfferContext';
import { firebaseRef } from 'api/firebaseRef';
import { onError, onSuccess } from 'providers/providersHelpers';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { InvitationStatus, JobOffers, JobOffer, UpdateJobStatus } from 'context/jobOffer/JobOfferContext.types';

export const JobOfferProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [jobOffers, setJobOffers] = useState<JobOffers>([]);
  const { currentUser } = useAuthentication();
  const [jobOfferDetails, setJobOfferDetails] = useState<JobOffer | null>(null);

  const fetchJobOffers = async () => {
    setLoading(true);

    // update candidate matching
    await firebase.app().functions('europe-west1').httpsCallable('matchMeWithJobOffers')();

    //get all matched offers
    const jobOffers = await firebaseRef.firestore().collection('users_customers').doc(currentUser?.uid).get();

    if (currentUser !== null && !!jobOffers) {
      const data = jobOffers?.data();
      if (data?.job_offers) {
        const offers = data.job_offers?.map(async (offer: string) => {
          const item = await firebaseRef.firestore().doc(offer).parent?.parent?.get();
          const extraData = await firebaseRef.firestore().doc(offer).get();
          const companyData = await firebaseRef.firestore().collection('companies').doc(item?.data()?.companyId).get();
          const collectionType = firebaseRef.firestore().doc(offer).parent?.id;

          const isActive = item?.data()?.status === 'active';
          const isRejected = extraData?.data()?.status === 'rejected';

          if (isActive && !isRejected) {
            return {
              ...item?.data(),
              jobOfferRef: item?.ref.path,
              score: extraData?.data()?.generalScore,
              growthScore: extraData?.data()?.growthScore,
              offerStatus: extraData?.data()?.status,
              companyData: companyData.data(),
              collectionType,
            } as JobOffer;
          }
        });

        await Promise.all(offers).then((returnedOffers) => {
          setJobOffers(returnedOffers.filter((i) => i).reverse() as JobOffers);
        });

        return onSuccess(setLoading, `Job offers have been successfully fetched`);
      }

      return onError(setLoading, 'Unable to fetch any job offers');
    } else {
      return onError(setLoading, 'Unable to fetch any job offers');
    }
  };

  const updateJobStatus = async ({ jobOfferRef, status, userName, collection }: UpdateJobStatus) => {
    if (currentUser !== null) {
      const updateObject = {
        status,
        userName: status === InvitationStatus.ACCEPTED ? userName : null,
      };

      await firebaseRef.firestore().doc(`${jobOfferRef}/${collection}/${currentUser?.uid}`).update(updateObject);

      await fetchJobOffers();

      return onSuccess(setLoading, `Job status updated`);
    } else {
      return onError(setLoading, 'Unable to update job status');
    }
  };

  const checkOfferID = async (offerID: string) => {
    setLoading(true);

    try {
      const result = await firebaseRef.functions('europe-west1').httpsCallable('returnCandidateJobOffer')(offerID);
      setJobOfferDetails(result.data);
    } catch (err) {
      setJobOfferDetails(null);

      return onError(setLoading, 'No offer found.');
    }

    return onSuccess(setLoading, `Fetched Job Offer`);
  };

  const value = { fetchJobOffers, loading, jobOffers, updateJobStatus, checkOfferID, jobOfferDetails };

  return <JobOfferContext.Provider value={value}>{children}</JobOfferContext.Provider>;
};
