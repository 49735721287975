import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { ButtonDefault } from '@hrme/shared';
import { Redirect } from 'react-router';
import { Prompt } from 'react-router-dom';
import { AlertMessage } from '@hrme/shared';

import { SettingsLayout } from 'ui/layouts/settingsLayout/SettingsLayout';
import { BoxWrapper } from '../components/BoxWrapper';
import { ButtonOther } from 'ui/buttonOther/ButtonOther';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { User } from 'context/user/UserContext.types';
import { ProfilePaths } from 'routing/profile/ProfilePaths';

import { CertificatesFormState, CertificatesSettingsProps } from './CertificatesSettings.types';
import { CertificateItem } from './components/CertificateItem/CertificateItem';
import { TrainingItem } from './components/TrainingItem/TrainingItem';
import { Training } from './components/TrainingItem/TrainingItem.types';
import { EMPTY_CERTIFICATE, EMPTY_TRAINING } from './CertificatesSettings.constants';
import { Certificate } from './components/CertificateItem/CertificateItem.types';

export const CertificatesSettings: React.FC<CertificatesSettingsProps> = () => {
  const { user, setUserData } = useUserContext();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });

  const [certificates, setCertificates] = useState<Certificate[]>(
    user?.certificates ? user.certificates : [EMPTY_CERTIFICATE],
  );
  const [trainings, setTrainings] = useState<Training[]>(user?.trainings ? user.trainings : [EMPTY_TRAINING]);
  const [showSavePrompt, setShowSavePrompt] = useState(false);

  const defaultValues = {
    certificates: user?.certificates,
    trainings: user?.trainings,
  };

  const methods = useForm<CertificatesFormState>({ defaultValues });

  if (user === undefined) {
    return <Redirect to={ProfilePaths.dashboard} />;
  }

  const onCertificateDelete = (index: number) => {
    setCertificates((prevState) => {
      const newCertificates = [...prevState];
      newCertificates.splice(index, 1);

      return [...newCertificates];
    });
  };

  const onAddCertificate = () => {
    setCertificates((prevState) => {
      const newCertificates = [...prevState];
      newCertificates.push(EMPTY_CERTIFICATE);

      return [...newCertificates];
    });
  };

  const onAddTraining = () => {
    setTrainings((prevState) => {
      const newTrainings = [...prevState];
      newTrainings.push(EMPTY_TRAINING);

      return [...newTrainings];
    });
  };

  const deleteTraining = (index: number) => {
    setTrainings((prevState) => {
      const newTrainings = [...prevState];
      newTrainings.splice(index, 1);

      return [...newTrainings];
    });
  };

  const onContentChange = async () => setShowSavePrompt(true);

  const onFormSubmit = async (data: CertificatesFormState) => {
    const { trainings, certificates } = data;

    if (trainings === user.trainings && certificates === user.certificates) {
      return;
    }

    // TODO: find out why trainings: undefined works and certificates: undefined doesn't
    // why does trainings contain at least an EMPTY_TRAINING?
    const newUser: User = {
      ...user,
      trainings,
      certificates: certificates || [],
    };

    const result = await setUserData(newUser);
    setShowAlertMessage({ show: true, success: result.success });
    setShowSavePrompt(false);
  };

  return (
    <SettingsLayout>
      <Prompt when={showSavePrompt} message="Twoje dane nie zostały zapisane. Czy chcesz kontynuować?" />
      <form onSubmit={methods.handleSubmit(onFormSubmit)} onChange={onContentChange}>
        <BoxWrapper>
          <Typography align="left" variant="h4">
            Szkolenia
          </Typography>
          <Box mb={4} />
          {trainings.map((item, index) => (
            <Box mb={4}>
              <TrainingItem
                prefix={`trainings[${index}]`}
                index={index}
                methods={methods}
                deleteItem={deleteTraining}
                training={item}
              />
            </Box>
          ))}
          <Box mb={4} />
          <ButtonOther onClick={onAddTraining}>dodaj szkolenie</ButtonOther>
        </BoxWrapper>
        <Box mb={4} />
        <BoxWrapper>
          <Typography align="left" variant="h4">
            Certyfikaty
          </Typography>
          <Box mb={4} />
          {certificates.map((item, index) => (
            <Box mb={4}>
              <CertificateItem
                prefix={`certificates[${index}]`}
                certificate={item}
                index={index}
                methods={methods}
                deleteItem={onCertificateDelete}
              />
            </Box>
          ))}
          <Box mb={4} />
          <ButtonOther onClick={onAddCertificate}>dodaj certyfikat</ButtonOther>
        </BoxWrapper>
        <Box mb={4} />
        <BoxWrapper>
          <ButtonDefault>zapisz zmiany</ButtonDefault>
        </BoxWrapper>
      </form>
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} />
    </SettingsLayout>
  );
};
