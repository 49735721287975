import { useEffect } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';

import { AuthenticationPaths } from 'routing/auth/AuthenticationPaths';
import { Loader } from 'ui/loader/Loader';

import { FirebaseUserManagementAction } from './AuthPage.types';

export const AuthPage = (): JSX.Element | null => {
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    performActionOnMode();
  }, []);

  const { oobCode, mode } = queryString.parse(search);
  if (typeof oobCode !== 'string') {
    alert('Nieprawidłowy typ kodu akcji. Skontaktuj się z administratorem.');

    return null;
  }

  const performActionOnMode = () => {
    switch (mode) {
      case FirebaseUserManagementAction.VERIFY_EMAIL:
        history.replace(AuthenticationPaths.emailVerification.replace(':actionCode', oobCode));
        break;
      case FirebaseUserManagementAction.RECOVER_EMAIL:
        // TODO: waits for implementation
        break;
      case FirebaseUserManagementAction.RESET_PASSWORD:
        history.replace(AuthenticationPaths.enterNewPassword.replace(':actionCode', oobCode));
        break;
      default:
        break;
    }
  };

  return <Loader />;
};
