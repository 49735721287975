import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useTheme,
} from '@material-ui/core';
import parse from 'html-react-parser';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Check from '@material-ui/icons/Check';

import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { LearningSources } from 'ui/careerPath/careerSteps/careerPathStepDetails/learningSources/LearningSources';
import { CareerPathTasks } from 'ui/careerPath/careerSteps/careerPathStepDetails/careerPathTasks/CareerPathTasks';
import { SKILL_LEVEL } from 'helpers/findSkillLevelByTechnologyName/findSkillLevelByTechnologyName.constants';
import { findSkillLevelByTechnologyName } from 'helpers/findSkillLevelByTechnologyName/findSkillLevelByTechnologyName';
import { User } from 'context/user/UserContext.types';
import { ModalType } from 'ui/careerPath/careerSteps/careerPathStepSummary/CareerPathStepSummary.types';

import { CareerPathStepDetailsProps, CareerPathStepTask } from './CareerPathStepDetails.types';
import { useStyles, Accordion, AccordionSummary, AccordionDetails } from './CareerPathStepDetails.styles';

export const CareerPathStepDetails: React.FC<CareerPathStepDetailsProps> = ({ data, index, type, techBoard }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { updateTasksData, user, setUserData } = useUserContext();

  const [value, setValue] = useState<number>(0);

  const { info, sources, tasks, name } = data;
  const [pathTasks, setPathTasks] = useState<CareerPathStepTask[]>([]);

  const handlePathTasksChange = useCallback(
    async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const taskId = target.id;
      const newPathTasks = [...pathTasks];
      newPathTasks[parseInt(taskId)].completed = !newPathTasks[parseInt(taskId)].completed;
      setPathTasks([...newPathTasks]);
      if (tasks) tasks[parseInt(taskId)].completed = !tasks[parseInt(taskId)].completed;

      await updateTasksData(pathTasks[parseInt(taskId)].hash);
    },
    [pathTasks],
  );

  // takes care of select value changes
  const handleSelectChange = async ({ target }: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (!user) return;

    const { value } = target;
    setValue(value as number);
    const { technology } = user;
    if (technology) {
      technology.known = technology.known.filter((item) => item !== name);
      technology.learning = technology.learning.filter((item) => item !== name);
      technology.wantToLearn = technology.wantToLearn.filter((item) => item !== name);
      switch (value) {
        case SKILL_LEVEL.WANT_TO_LEARN.selectValue:
          technology.wantToLearn.push(name);
          break;
        case SKILL_LEVEL.LEARNING.selectValue:
          technology.learning.push(name);
          break;
        case SKILL_LEVEL.KNOWN.selectValue:
          technology.known.push(name);
          break;
        default:
          break;
      }
    }

    const newUser: User = {
      ...user,
      technology,
    };

    await setUserData(newUser);
  };

  useEffect(() => {
    if (tasks) {
      const rows = tasks.map((task) => ({
        completed: !!user?.taskHashes?.includes(task.hash || ''),
        title: task.title,
        link: task.link,
        hash: task.hash,
      }));
      setPathTasks(rows);
    }

    setValue(findSkillLevelByTechnologyName({ name, user }));
  }, []);

  const optionsArray = [
    { value: +SKILL_LEVEL.KNOWN.selectValue, label: SKILL_LEVEL.KNOWN.label },
    { value: +SKILL_LEVEL.LEARNING.selectValue, label: SKILL_LEVEL.LEARNING.label },
    { value: +SKILL_LEVEL.WANT_TO_LEARN.selectValue, label: SKILL_LEVEL.WANT_TO_LEARN.label },
    { value: +SKILL_LEVEL.NONE.selectValue, label: SKILL_LEVEL.NONE.label },
  ];

  const selectables = techBoard ? optionsArray.slice(0, -1) : optionsArray;

  if (type === ModalType.LEVEL)
    return (
      <FormControl component="fieldset">
        <RadioGroup value={+value} onChange={handleSelectChange}>
          {selectables.map((item, index) => (
            <>
              {index !== 0 && <Divider light />}
              <FormControlLabel
                value={item.value}
                control={
                  <Radio
                    checkedIcon={
                      <span className={classes.checkIcon} style={{ border: '1px solid red' }}>
                        <Check />
                      </span>
                    }
                    icon={
                      <span className={classes.checkIcon} style={{ border: '1px solid gray' }}>
                        <Check />
                      </span>
                    }
                  />
                }
                label={item.label}
                labelPlacement="start"
                style={{ margin: 0, justifyContent: 'space-between', padding: '10px 0' }}
              />
            </>
          ))}
        </RadioGroup>
      </FormControl>
    );
  if (type === ModalType.KNOWLEDGE) return <LearningSources sources={sources} mobile />;
  if (type === ModalType.TASKS)
    return <CareerPathTasks tasks={pathTasks} onPathTaskChange={handlePathTasksChange} mobile />;

  return (
    <div className={classes.root}>
      <Accordion variant="outlined" style={{ border: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel0a-content"
          id="panel0a-header"
          style={{ padding: 0 }}
        >
          <Typography variant="body2" className={classes.heading}>
            Poziom umiejętności
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          <FormControl variant="outlined" fullWidth>
            <Select
              value={value}
              onChange={handleSelectChange}
              className={classes.select}
              IconComponent={ExpandMoreIcon}
            >
              <MenuItem value={SKILL_LEVEL.NONE.selectValue}>{SKILL_LEVEL.NONE.label}</MenuItem>
              <MenuItem value={SKILL_LEVEL.WANT_TO_LEARN.selectValue}>{SKILL_LEVEL.WANT_TO_LEARN.label}</MenuItem>
              <MenuItem value={SKILL_LEVEL.LEARNING.selectValue}>{SKILL_LEVEL.LEARNING.label}</MenuItem>
              <MenuItem value={SKILL_LEVEL.KNOWN.selectValue}>{SKILL_LEVEL.KNOWN.label}</MenuItem>
            </Select>
          </FormControl>
        </AccordionDetails>
      </Accordion>
      <Accordion variant="outlined" style={{ border: 0 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="body2" className={classes.heading}>
            Informacje
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ padding: theme.spacing(3, 0) }}>
            <Typography variant="body2" style={{ textAlign: 'left' }}>
              {parse(info)}
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      {sources && sources.length > 0 && (
        <>
          <Divider style={{ width: '100%', backgroundColor: '#d8d5dc' }} />
          <Accordion variant="outlined" style={{ border: 0 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <Typography className={classes.heading}>Źródła nauki</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <LearningSources sources={sources} />
            </AccordionDetails>
          </Accordion>
        </>
      )}
      {tasks && tasks.length > 0 && (
        <>
          <Divider style={{ width: '100%', backgroundColor: '#d8d5dc' }} />
          <Accordion variant="outlined" style={{ border: 0 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <Typography className={classes.heading}>{`Zadania (${tasks.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.completed ? 1 : 0),
                0,
              )}/${tasks.length})`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CareerPathTasks tasks={pathTasks} onPathTaskChange={handlePathTasksChange} />
            </AccordionDetails>
            <Box mb={2} />
          </Accordion>
        </>
      )}
    </div>
  );
};
