import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { ButtonsAcceptCancelProps } from './ButtonsAcceptCancel.types';
import { useStyles } from './ButtonsAcceptCancel.styles';

export const ButtonsAcceptCancel: React.FC<ButtonsAcceptCancelProps> = ({ onAccept, onReject }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.buttons}>
      <div className={clsx({ [classes.button]: true, [classes.buttonAccept]: true })} onClick={onAccept}>
        <Typography variant="subtitle1" className={classes.buttonCaption}>
          akceptuj
        </Typography>
        <Box mb={1} />
      </div>
      <div className={clsx({ [classes.button]: true, [classes.buttonCancel]: true })} onClick={onReject}>
        <Typography variant="subtitle1" className={classes.buttonCaption}>
          odrzuć
        </Typography>
        <Box mb={1} />
      </div>
    </div>
  );
};
