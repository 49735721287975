import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { StatsBar } from '@hrme/shared/lib/components/ui';
import { StyledButtonLink } from '@hrme/shared/lib/components/buttons';

import { StatsSectionProps } from './StatsSection.types';
import { useStyles } from './StatsSection.styles';

export const StatsSection: React.FC<StatsSectionProps> = ({ action, data }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.titleBar}>
          <Typography variant="body2">Statystyki technologii</Typography>
          <StyledButtonLink onClick={action}>{'Zarządzaj >'}</StyledButtonLink>
        </div>
        <Box mb={3} />
        <StatsBar data={data} valuesInLabels />
      </div>
    </div>
  );
};
