/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AlertMessage } from '@hrme/shared';

import { SpecializationState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';
import { useCareerPathFormDispatch } from 'hooks/useCareerPathFormDispatch/useCareerPathFormDispatch';
import { setSpecialization } from 'context/careerPathForm/careerPathActionCreators/careerPathActionCreators';
import { MainPaths } from 'routing/main/MainPaths';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { User } from 'context/user/UserContext.types';

import { Specialization } from './Specialization';

export const SpecializationContainer = (): JSX.Element => {
  const { setUserData, user } = useUserContext();
  const history = useHistory();
  const dispatch = useCareerPathFormDispatch();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });

  const methods = useForm<SpecializationState>({
    defaultValues: user?.specialization ? user.specialization : { specialization: [], other: [] },
  });

  if (user === undefined) {
    return <Redirect to={MainPaths.welcomeMain} />;
  }

  const onFormSubmit = async (data: SpecializationState, isBack?: boolean, isIdle?: boolean) => {
    if (data.other.length === 0 && data.specialization.length === 0) return;

    const { specialization, other } = data;

    const payload: SpecializationState = {
      specialization: specialization.filter((el) => el !== ''),
      other: other,
    };

    if (payload === user.specialization) {
      history.push(MainPaths.availability);

      return;
    }

    const newUser: User = { ...user, specialization: payload };
    const result = await setUserData(newUser);

    if (!result.success) setShowAlertMessage({ show: true, success: false });
    if (result.success && isIdle) return dispatch(setSpecialization(data));

    if (result.success) {
      dispatch(setSpecialization(data));
      !isBack && history.push(MainPaths.availability);
    }
  };

  return (
    <>
      <Specialization onFormSubmit={onFormSubmit} methods={methods} />
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} />
    </>
  );
};
