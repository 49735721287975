import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { ButtonProfile } from '@hrme/shared/lib/components/buttons';
import { StatsBar } from '@hrme/shared/lib/components/ui';

import { CareerPathBoxProps } from './CareerPathBox.types';
import { useStyles } from './CareerPathBox.styles';

export const CareerPathBox: React.FC<CareerPathBoxProps> = ({ barData, header, subheader, onClick }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <Typography variant="body2" align="left" className={classes.header}>
          {header}
        </Typography>
        <Typography variant="subtitle1" align="left" className={classes.subheader}>
          {subheader}
        </Typography>
        <Box mb={4} />
        <StatsBar data={barData} />
      </div>
      <Box mb={4} />
      <ButtonProfile fullWidth variant="contained" onClick={onClick}>
        podgląd ścieżki
      </ButtonProfile>
    </div>
  );
};
