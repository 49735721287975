import React, { useEffect, useMemo } from 'react';
import { ButtonDefault, Link } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router';

import { MainFlowLayout } from 'ui/layouts/mainFlowLayout/MainFlowLayout';
import { Topic } from 'ui/topic/Topic';
import { arrayOfStringToFormControlLabelItem } from 'helpers/arrayOfStringToFormControlLabelItem';
import { useKnowledge } from 'hooks/useKnowledge/useKnowledge';
import { checkKeyDown } from 'helpers/checkKeyDown';
import { RadioChipGroupComponent } from 'ui/radioChipGroupComponent/RadioChipGroupComponent';
import { RadioChipGroupComponentType } from 'ui/radioChipGroupComponent/RadioChipGroupComponent.types';
import { Loader } from 'ui/loader/Loader';

import { PositionProps } from './Position.types';
import { useStyles } from './Position.styles';

export const Position: React.FC<PositionProps> = ({ methods, onFormSubmit }) => {
  const { handleSubmit } = methods;

  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const { loading, fetchSuggestions, categories } = useKnowledge();
  const categoriesOrNew = ['New to IT', ...categories];

  const positionItems = useMemo(() => {
    const items = arrayOfStringToFormControlLabelItem(categoriesOrNew);

    return items;
  }, [categoriesOrNew]);

  const positionSelected = methods.watch('position_name');

  const isOtherPosition = !!positionItems.find(({ value }) => value.toLowerCase() === positionSelected?.toLowerCase())
    ? []
    : positionSelected
    ? [positionSelected]
    : [];

  const userOtherPosition = isOtherPosition?.map((el, index) => {
    return { key: index, label: el };
  });

  const positions = methods.watch(['position_name', 'other_position']);
  const { position_name, other_position } = positions;

  const allowNextStep =
    (position_name === 'other' && other_position.length > 0) ||
    (position_name !== undefined && position_name !== 'other' && position_name !== null);

  useEffect(() => {
    const getSuggestions = async () => {
      await fetchSuggestions();
    };
    getSuggestions();
  }, []);

  useEffect(() => {
    if (!loading) onFormSubmit(methods.getValues(), true);
  }, [position_name, other_position]);

  if (loading) return <Loader />;

  return (
    <>
      <MainFlowLayout topic={<Topic />} progress={{ label: 'Specjalizacja', length: 10, current: 1 }}>
        <form
          className={classes.container}
          onSubmit={handleSubmit((d) => onFormSubmit(d))}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <Typography variant="h2" className={classes.header}>
            Twoja specjalizacja
          </Typography>
          <Box mb={3} />
          <Typography variant="body2" className={classes.subheader}>
            W jakiej dziedzinie IT głównie pracujesz lub uczysz się?
          </Typography>
          <Box mb={9} />
          <RadioChipGroupComponent
            positionItems={positionItems}
            methods={methods}
            userOtherPosition={userOtherPosition}
            name={RadioChipGroupComponentType.POSITION_NAME}
          />
          <Box mb={3.75} />
          <div className={classes.wrapper}>
            <ButtonDefault disabled={!allowNextStep}>nas†ępny krok (zainteresowania)</ButtonDefault>
            <Box mb={2} />
            <Link>
              <Typography onClick={() => history.goBack()}>cofnij</Typography>
            </Link>
          </div>
        </form>
      </MainFlowLayout>
    </>
  );
};
