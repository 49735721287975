import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { ButtonDefault } from '@hrme/shared';
import { useForm } from 'react-hook-form';
import { AlertMessage } from '@hrme/shared';

import { MainFlowLayout } from 'ui/layouts/mainFlowLayout/MainFlowLayout';
import { Topic } from 'ui/topic/Topic';
import { EducationItem } from 'app/common/education/educationItem/EducationItem';
import { ButtonOther } from 'ui/buttonOther/ButtonOther';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { EMPTY_EDUCATION } from 'app/common/education/EducationSettings.constants';
import { User } from 'context/user/UserContext.types';
import { SchoolState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';

import { useStyles } from './Schools.styles';
import { SchoolsForm } from './Schools.types';

export const Schools = (): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { user, setUserData } = useUserContext();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });

  const [schoolItems, setSchoolItems] = useState([EMPTY_EDUCATION]);

  const methods = useForm<SchoolsForm>({
    defaultValues: { school: user?.school },
  });

  useEffect(() => {
    if (!user) return;

    const { school } = user;
    methods.setValue('school', school);

    if (!school) return;

    setSchoolItems([...school]);
  }, []);

  if (user === undefined) {
    return <></>;
  }

  const onFormSubmit = async (data: SchoolsForm) => {
    const school: SchoolState = [];

    if (data.school) data.school.map((item) => school.push(item));

    if (school === user.school) return;

    const newUser: User = {
      ...user,
      school,
    };

    const result = await setUserData(newUser);
    setShowAlertMessage({ show: true, success: result.success });
  };

  const addSchool = () => {
    setSchoolItems((prevState) => {
      const newSchools = [...prevState];
      newSchools.push(EMPTY_EDUCATION);

      return [...newSchools];
    });
  };

  const deleteSchool = (index: number) => {
    setSchoolItems((prevState) => {
      const newSchools = [...prevState];
      newSchools.splice(index, 1);

      return [...newSchools];
    });
  };

  return (
    <MainFlowLayout topic={<Topic />} progress={{ label: 'Edukacja', length: 10, current: 7 }}>
      <div className={classes.container}>
        <Box mb={3}>
          <Typography variant="h2" className={classes.header}>
            Twoja edukacja
          </Typography>
        </Box>
        <Box mb={6}>
          <Typography variant="body2" className={classes.subheader}>
            Wpisz swoje miejsca edukacji
          </Typography>
        </Box>

        <form onSubmit={methods.handleSubmit(onFormSubmit)} className={classes.form}>
          {schoolItems.map((item, index) => {
            const fieldName = `school[${index}]`;

            return (
              <Box mb={4}>
                <EducationItem
                  prefix={fieldName}
                  educationItem={item}
                  index={index}
                  methods={methods}
                  deleteItem={deleteSchool}
                />
              </Box>
            );
          })}
          <ButtonOther onClick={addSchool}>dodaj uczelnię</ButtonOther>
          <Box mb={4} />
          <ButtonDefault>zapisz zmiany</ButtonDefault>
        </form>
      </div>
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} />
    </MainFlowLayout>
  );
};
