import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import { AlertMessage } from '@hrme/shared';

import { FillState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';
import { useCareerPathFormDispatch } from 'hooks/useCareerPathFormDispatch/useCareerPathFormDispatch';
import { setFill } from 'context/careerPathForm/careerPathActionCreators/careerPathActionCreators';
import { MainPaths } from 'routing/main/MainPaths';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { User } from 'context/user/UserContext.types';

import { FillData } from './FillData';

const validationSchema = Yup.object().shape({
  city: Yup.string().required(),
  gender: Yup.string().required(),
  age: Yup.number().required(),
});

export const FillDataContainer: React.FC = () => {
  const { user, setUserData } = useUserContext();
  const dispatch = useCareerPathFormDispatch();
  const history = useHistory();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });

  const methods = useForm<FillState>({
    defaultValues: user?.fillData,
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  });

  if (!user) {
    return <Redirect to={MainPaths.welcomeMain} />;
  }

  const onFormSubmit = async (data: FillState, isIdle?: boolean) => {
    if (data === user.fillData) {
      history.push(MainPaths.congratulations);

      return;
    }

    const newUser: User = { ...user, fillData: data, profileLevel: 1, lastEvent: 'CompletedOnboarding' };

    const result = await setUserData(newUser);

    if (!result.success) setShowAlertMessage({ show: true, success: false });
    if (result.success && isIdle) return dispatch(setFill(data));

    if (result.success) {
      dispatch(setFill(data));
      history.push(MainPaths.congratulations);
    }
  };

  return (
    <>
      <FillData onFormSubmit={onFormSubmit} methods={methods} />
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} />
    </>
  );
};
