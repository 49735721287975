import { Certificate } from './components/CertificateItem/CertificateItem.types';
import { Training } from './components/TrainingItem/TrainingItem.types';

export const EMPTY_TRAINING: Training = {
  training: '',
  company: '',
  skillsLearned: [],
  dateStarted: '',
  dateEnded: '',
  trainingTime: '',
};

export const EMPTY_CERTIFICATE: Certificate = {
  certificate: '',
  company: '',
  issueDate: '',
};
