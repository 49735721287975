import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.grey[800],
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  container: {
    width: '100%',
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textFieldAdd: {
    marginTop: theme.spacing(2),
  },
  listContainer: {
    width: '100%',
  },
  listContainerGrey: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
}));
