import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    // [theme.breakpoints.down('md')]: {
    //   width: 'unset',
    //   margin: theme.spacing(0, -3),
    // },
  },
}));
