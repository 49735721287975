/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { checkEventKey } from 'helpers/checkEventKey';

import { AutocompleteOptionType, ChipAutocompleteProps } from './ChipAutocomplete.types';

export const ChipAutocomplete: React.FC<ChipAutocompleteProps> = ({
  autocompleteOptions = [],
  addChip = () => {},
  autocompleteValue,
  setAutocompleteValue,
  disableUnderline = false,
  autocompleteClassName,
  textFieldClassName,
}) => {
  const [helperValue, setHelperValue] = useState({ title: '' });
  const crossButton = useRef<HTMLInputElement>(null);

  const onAutocompleteChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: string | AutocompleteOptionType | null,
  ) => {
    event.preventDefault();

    if (typeof newValue === 'string') {
      if (newValue.includes(',')) return;

      setAutocompleteValue({
        title: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setAutocompleteValue({
        title: newValue.inputValue,
      });
    } else {
      setAutocompleteValue(newValue);
    }
  };

  const onAutocompleteInputKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (checkEventKey(event)) setAutocompleteValue(helperValue);
  };

  const onChange = (value: string) => {
    if (value.includes(',')) return;

    setHelperValue({
      title: value,
    });
  };

  useEffect(() => {
    addChip();
    setHelperValue({ title: '' });
  }, [autocompleteValue]);

  useEffect(() => {
    if (crossButton.current) {
      const cancelCrosses = crossButton.current.querySelectorAll('.MuiAutocomplete-clearIndicator');

      cancelCrosses.forEach((cancelCross) => {
        cancelCross.addEventListener('click', () => setHelperValue({ title: '' }));

        helperValue.title.length > 0
          ? cancelCross.setAttribute('style', 'visibility: visible')
          : cancelCross.setAttribute('style', 'visibility: hidden');
      });
    }
  }, [helperValue]);

  return (
    <Autocomplete
      options={autocompleteOptions}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }

        // Regular option
        return option.title;
      }}
      fullWidth
      className={autocompleteClassName}
      noOptionsText="Brak opcji"
      onChange={onAutocompleteChange}
      value={autocompleteValue}
      onClick={addChip}
      onClose={addChip}
      freeSolo
      autoComplete
      clearText="wyczyść"
      renderInput={(params) => (
        <TextField
          ref={crossButton}
          {...params}
          placeholder="Wprowadź"
          label=""
          className={textFieldClassName}
          onChange={(e) => onChange(e.target.value)}
          onKeyPress={onAutocompleteInputKeyPress}
          inputProps={{ ...params.inputProps, value: helperValue.title.replace(',', '') }}
          InputProps={{
            ...params.InputProps,
            disableUnderline,
          }}
        />
      )}
      selectOnFocus
      handleHomeEndKeys
    />
  );
};
