import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { isDesktop } from 'react-device-detect';

import { AuthenticationRoutes } from 'routing/auth/AuthenticationRoutes';
import { MainRoutes } from 'routing/main/MainRoutes';
import { ProfileRoutes } from 'routing/profile/ProfileRoutes';
import { JobOfferView } from 'app/jobOfferView/JobOfferView';
import { SettingsRoutes } from 'routing/settings/SettingsRoutes';
import { ProjectTheme } from 'ui/projectTheme/ProjectTheme';
import { CookieAlert } from 'ui/cookieAlert/CookieAlert';
import { checkEnv } from 'helpers/envChecker';
import { JobOfferDetails } from 'app/jobOfferDetails/JobOfferDetails';
import { DiscountsOnTutorials } from 'app/discountsOnTutorials/DiscountsOnTutorials';

import { ErrorNotFound } from './errorNotFound/ErrorNotFound';
import { AppProviders } from './AppProviders';
import { ProtectedRoute } from './protectedRoute/ProtectedRoute';
import { DebugHelper } from './debugHelper';
import { MainRoute } from './mainRoute/MainRoute';

import './Root.css';

export const Root: React.FC = () => {
  const development: boolean = checkEnv('REACT_APP_DEBUGGER') === 'true';
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <AppProviders>
      {((!matches && matches) || (!isDesktop && isDesktop)) && (
        <Box className="desktop-only">
          Beta testy dostępne są jedynie na komputerach.
          <br />
          Pilnie pracujemy nad rozwiązaniem tego problemu! ✌
        </Box>
      )}
      {development && <DebugHelper />}
      <CookieAlert />
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Route path="/jobOfferDetails/:id" component={JobOfferDetails} />
        <Route path="/profile-jobofferview" component={JobOfferView} />
        <Route path="/discounts-on-tutorials" component={DiscountsOnTutorials} />
        {AuthenticationRoutes.map((item) => (
          <Route key={item.id} path={item.path} exact={true} component={item.component} />
        ))}
        {MainRoutes.map((item, index) => (
          <ProtectedRoute key={index} path={item.path} exact={true} component={item.component} />
        ))}
        {ProfileRoutes.map((item, index) => (
          <MainRoute key={index} path={item.path} exact={true} component={item.component} />
        ))}
        {SettingsRoutes.map((item, index) => (
          <MainRoute key={index} path={item.path} exact={true} component={item.component} />
        ))}
        <Route exact path="/theme" component={ProjectTheme} />
        <Route path="*">
          <ErrorNotFound
            message={'Błąd 404'}
            comment={'Przykro nam, nic tu nie ma'}
            caption={'dashboard'}
            handleClick={() => console.log('DASHBOARD button clicked')}
          />
        </Route>
      </Switch>
    </AppProviders>
  );
};
