import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AlertMessage } from '@hrme/shared';

import { useCareerPathFormDispatch } from 'hooks/useCareerPathFormDispatch/useCareerPathFormDispatch';
import { setExperience } from 'context/careerPathForm/careerPathActionCreators/careerPathActionCreators';
import { MainPaths } from 'routing/main/MainPaths';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { User } from 'context/user/UserContext.types';
import { TechnologyState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';
import { ExperienceItemType } from 'ui/experienceItem/ExperienceItem.types';

import { Experience } from './Experience';
import { ExperienceFormState } from './Experience.types';

export const ExperienceContainer = (): JSX.Element => {
  const { user, setUserData } = useUserContext();
  const history = useHistory();
  const dispatch = useCareerPathFormDispatch();
  const methods = useForm<ExperienceFormState>({
    defaultValues: { experience: user?.experience },
    reValidateMode: 'onSubmit',
  });
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });

  if (user === undefined) {
    return <Redirect to={MainPaths.welcomeMain} />;
  }

  const onFormSubmit = async (data: ExperienceFormState, isBack?: boolean, isIdle?: boolean) => {
    const experience: ExperienceItemType[] = [];

    if (data && data.experience) {
      experience.push(
        ...data.experience.map((el) => {
          const { end, ...expValues } = el;

          return {
            ...expValues,
            end: end || null,
            seniority: el.seniority ? el.seniority : '',
            type: el.type ? el.type : '',
          };
        }),
      );
    }

    if (experience === user.experience) {
      history.push(MainPaths.technology);

      return;
    }

    const technologyKnown = experience
      .map((item) => {
        return item.skills;
      }) // get all skills as string[][]
      .reduce((acc, curVal) => {
        return acc.concat(curVal);
      }, []) // flatten the array to be one-dimension
      .reduce((a, b) => {
        if (a.indexOf(b) < 0) a.push(b);

        return a;
      }, [] as string[]); // remove all duplicates in array

    const technologyState: TechnologyState = {
      known: technologyKnown,
      wantToLearn: [],
      learning: [],
    };

    const technologyKnownNotInTechnologyTable: string[] = [];
    technologyKnown.map((item) => {
      if (
        !user.technology?.known.includes(item) &&
        !user.technology?.learning.includes(item) &&
        !user.technology?.wantToLearn.includes(item)
      )
        technologyKnownNotInTechnologyTable.push(item);

      return null;
    });

    const newUser: User = {
      ...user,
      experience,
      technology: user.technology
        ? {
            ...user.technology,
            known: user.technology.known.concat(technologyKnownNotInTechnologyTable),
            learning: user.technology.learning,
            wantToLearn: user.technology.wantToLearn,
          }
        : technologyState,
    };

    const response = await setUserData(newUser);

    if (!response.success) setShowAlertMessage({ show: true, success: false });
    if (response.success && isIdle) return dispatch(setExperience(data.experience));

    if (response.success) {
      dispatch(setExperience(data.experience));
      !isBack && history.push(MainPaths.technology);
    }
  };

  return (
    <>
      <Experience onFormSubmit={onFormSubmit} methods={methods} />
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} />
    </>
  );
};
