import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  subtitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  subtitleStrong: {
    fontWeight: 600,
  },
}));
