import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AlertMessage } from '@hrme/shared';

import { useCareerPathFormDispatch } from 'hooks/useCareerPathFormDispatch/useCareerPathFormDispatch';
import { AvailabilityState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';
import { setAvailability } from 'context/careerPathForm/careerPathActionCreators/careerPathActionCreators';
import { MainPaths } from 'routing/main/MainPaths';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { User } from 'context/user/UserContext.types';

import { Availability } from './Availability';

export const AvailabilityContainer = (): JSX.Element => {
  const { user, setUserData } = useUserContext();
  const history = useHistory();
  const dispatch = useCareerPathFormDispatch();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });

  const methods = useForm<AvailabilityState>({
    defaultValues: user?.availability,
  });

  if (user === undefined) {
    return <Redirect to={MainPaths.welcomeMain} />;
  }

  const onFormSubmit = async (data: AvailabilityState, isBack?: boolean, isIdle?: boolean) => {
    if (data.work !== 'none' && (data.hours === undefined || data.workType === undefined)) return;

    if (user.availability === data) {
      history.push(MainPaths.experience);

      return;
    }

    const newUser: User = { ...user, availability: data };

    const result = await setUserData(newUser);

    if (!result.success) setShowAlertMessage({ show: true, success: false });
    if (result.success && isIdle) return dispatch(setAvailability(data));

    if (result.success) {
      dispatch(setAvailability(data));
      !isBack && history.push(MainPaths.experience);
    }
  };

  return (
    <>
      <Availability onFormSubmit={onFormSubmit} methods={methods} />
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} />
    </>
  );
};
