import { makeStyles } from '@material-ui/core';

import square from 'assets/square-bg.png';
import eclipse from 'assets/eclipse-bg.png';
import triangle from 'assets//triangle-bg.png';

export const useStyles = makeStyles((theme) => ({
  innerWrapper: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${square}), url(${eclipse}), url(${triangle})`,
      backgroundSize: 'top left, bottom left, top right',
      backgroundPositionY: '10%, 80%, 8%',
      backgroundPositionX: '0, 15%, 85%',
      backgroundRepeat: 'no-repeat',
    },
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      maxWidth: 580,
      marginTop: theme.spacing(3),
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(0, 10, 5),
    },
  },
}));
