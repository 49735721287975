import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  tableContainer: {},
  table: {
    width: '100%',
    minWidth: 350,
    border: 0,
  },
  tableHeader: {
    color: theme.palette.grey['800'],
  },
  checkIcon: {
    width: '50px',
    height: '50px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    fontSize: '12px',
  },
}));
