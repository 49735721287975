import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HrMeThemeProvider } from '@hrme/shared';
import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import 'dayjs/locale/pl';
import { Helmet } from 'react-helmet';
import DayJsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { CareerPathFormController } from 'context/careerPathForm/careerPathFormController/CareerPathFormController';
import { AuthenticationProvider } from 'providers/authentication/AuthenticationProvider';
import { UserProvider } from 'providers/user/UserProvider';
import { CareerPathProvider } from 'providers/careerPath/CareerPathProvider';
import { KnowledgeProvider } from 'providers/knowledge/KnowledgeProvider';
import { checkEnv } from 'helpers/envChecker';
import { JobOfferProvider } from 'providers/jobOffer/jobOfferProvider';
import { DiscountsOnTutorialsProvider } from 'providers/discountsOnTutorials/DiscountsOnTutorialsProvider';

const generateClassName = createGenerateClassName({
  productionPrefix: 'b2c',
});

const hotJarID = checkEnv('REACT_APP_HJ_ID');
const hotJarVer = checkEnv('REACT_APP_HJ_VER');

export const AppProviders: React.FC = ({ children }) => {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <MuiPickersUtilsProvider utils={DayJsUtils} locale="pl">
        <HrMeThemeProvider>
          <AuthenticationProvider>
            <UserProvider>
              <CareerPathFormController>
                <CareerPathProvider>
                  <KnowledgeProvider>
                    <JobOfferProvider>
                      <DiscountsOnTutorialsProvider>
                        <Helmet>
                          <title>HR-ME Customer</title>
                          <script async defer>
                            {`
                      (function(h,o,t,j,a,r){
                          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                          h._hjSettings={hjid:${hotJarID},hjsv:${hotJarVer}};
                          a=o.getElementsByTagName('head')[0];
                          r=o.createElement('script');r.async=1;
                          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                          a.appendChild(r);
                        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                        `}
                          </script>
                          <script src="https://d2twz9av6or5hk.cloudfront.net/1.6/adally.js"></script>
                        </Helmet>
                        <Router>{children}</Router>
                      </DiscountsOnTutorialsProvider>
                    </JobOfferProvider>
                  </KnowledgeProvider>
                </CareerPathProvider>
              </CareerPathFormController>
            </UserProvider>
          </AuthenticationProvider>
        </HrMeThemeProvider>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  );
};
