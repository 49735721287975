import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { CircularProgress } from '@material-ui/core';

import { useJobOffers } from 'hooks/useJobOffers/useJobOffers';
import { ProfilePaths } from 'routing/profile/ProfilePaths';

import { JobOfferDetailsProps } from './JobOfferDetails.types';

export const JobOfferDetails: React.FC = () => {
  const { id } = useParams<JobOfferDetailsProps>();
  const { checkOfferID, loading, jobOfferDetails } = useJobOffers();
  const [offerFetched, setOfferFetched] = useState(false);
  const history = useHistory();

  const pushToLocation = (dash?: boolean) => {
    const pathToRedirect = dash ? ProfilePaths.dashboard : ProfilePaths.jobOfferView;

    history.push({
      pathname: pathToRedirect,
      state: { job: jobOfferDetails },
    });
  };

  useEffect(() => {
    const checkOffer = async () => {
      const checkOffer = await checkOfferID(id);
      if (checkOffer.success) {
        setOfferFetched(true);
      } else {
        pushToLocation(true);
      }
    };
    checkOffer();
  }, []);

  useEffect(() => {
    if (offerFetched) {
      if (jobOfferDetails) pushToLocation();
    }
  }, [offerFetched]);

  return (
    <>
      <p>Sprawdzanie ogłoszenia:{loading ? ' w toku' : ' sprawdzono'}</p>
      <CircularProgress size={'2rem'} />
    </>
  );
};
