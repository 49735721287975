import React, { useEffect } from 'react';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { BaseAuthLayout } from 'ui/layouts/baseAuthLayout/BaseAuthLayout';
import SeekerImageTorch from 'assets/seeker-image-torch.svg';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { MainPaths } from 'routing/main/MainPaths';
import { useUserContext } from 'hooks/useUserContext/useUserContext';

import { LoginFormContainer } from './loginForm/LoginFormContainer';
import { useStyles } from './LoginPage.style';

export const LoginPage: React.FC = () => {
  const { currentUser } = useAuthentication();
  const { fetchUserData, setInitialData } = useUserContext();
  const history = useHistory();

  const theme = useTheme();

  const classes = useStyles(theme);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchUserData();

      if (response.success) {
        history.replace(MainPaths.welcomeMain);
      } else {
        const dataCreateResponse = await setInitialData();

        if (dataCreateResponse.success) {
          history.replace(MainPaths.welcomeMain);
        }
      }
    };

    if (currentUser && currentUser.emailVerified) {
      fetchData();
    }
  }, [currentUser]);

  return (
    <BaseAuthLayout>
      <Grid container>
        <Grid className={classes.narrowColumn} item xs={12} md={7} lg={5}>
          <LoginFormContainer />
        </Grid>
        {/* <Grid item md={7} sx={{ display: { xs: 'none', md: 'block' }}}> */}
        <Grid item md={5} lg={7} component={Box} display={{ xs: 'none', md: 'block' }}>
          <div className={classes.wideColumn}>
            <img src={SeekerImageTorch} alt="HR ME seeker" className={classes.image} />
            <Box mb={3.5} />
            <Typography variant="h2" className={classes.title}>
              Odkryj z nami swoją drogę w <span className={classes.highlighted}>świecie IT</span>
            </Typography>
            <Box mb={1} />
            <Typography variant="body2" className={classes.subtitle}>
              Pomożemy Ci stworzyć plan rozwoju prowadzący prosto do Twojego celu.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </BaseAuthLayout>
  );
};
