import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { ButtonDefault } from '@hrme/shared';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { HrMeTheme } from '@hrme/shared/lib/theme/theme';

import { firebaseRef } from '../../../../api/firebaseRef';
import { SettingsRoutes } from '../../../../routing/settings/SettingsRoutes';
import { SettingsPaths } from '../../../../routing/settings/SettingsPaths';
import { useUserContext } from '../../../../hooks/useUserContext/useUserContext';
import { useStyles } from '../SettingsLayout.styles';

import { AsideItem } from './AsideItem';

export const SettingsLayoutAside: React.FC = React.memo(() => {
  const location = useLocation();
  const { user } = useUserContext();
  const classes = useStyles();
  const theme: HrMeTheme = useTheme();

  const displayAlert = (pathName: string) => {
    switch (pathName.replace('/settings', '')) {
      case SettingsPaths.certificates: {
        return user?.certificates === undefined;
      }
      case SettingsPaths.education: {
        return user?.school === undefined;
      }
    }

    return false;
  };

  return (
    <Grid xs={12} md={3} lg={4}>
      <aside className={classes.aside}>
        <Typography variant="h6">Nawigacja</Typography>
        <Box mb={2} />
        {SettingsRoutes.map((item, index) => (
          <Box mb={1} key={index}>
            {index === SettingsRoutes.length - 1 && <Box mb={1} height={1} borderTop={'1px solid #D8D5DC'} />}
            <AsideItem hasAlert={displayAlert(item.path)} item={item} theme={theme} pathname={location.pathname} />
          </Box>
        ))}
        <Box mb={1}>
          <ButtonDefault onClick={() => firebaseRef.auth().signOut()}>wyloguj się</ButtonDefault>
        </Box>
      </aside>
    </Grid>
  );
});
