import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { Controller } from 'react-hook-form';

import { CheckboxChipGrouControllerProps, CheckboxChipGroupProps } from './CheckboxChipGroup.types';
import { useStyles } from './CheckboxChipGroup.styles';

export const CheckboxChipGroup: React.FC<CheckboxChipGroupProps> = ({
  items,
  name,
  background = false,
  methods,
  inSettings,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <FormControl component="fieldset">
      <Controller
        control={methods.control}
        name={name}
        render={({ onChange, value, ref }: CheckboxChipGrouControllerProps) => (
          <FormGroup
            className={clsx({
              [classes.formGroup]: true,
              [inSettings ? classes.justify_settings : classes.justify]: true,
            })}
          >
            {items.map((item, index) => (
              <FormControlLabel
                key={index}
                className={clsx({
                  [classes.formControlLabel]: true,
                  [inSettings ? classes.spacings_settings : classes.spacings]: true,
                  [classes.formControlLabelChecked]: value.indexOf(item.value) > -1,
                  [classes.formControlLabelBackground]: background && value.indexOf(item.value) <= -1,
                })}
                control={
                  <Checkbox
                    checkedIcon={<CheckCircleOutlineOutlinedIcon />}
                    icon={<CheckCircleOutlineOutlinedIcon className={classes.icon} />}
                    onChange={(e) => {
                      const index = value.indexOf(item.value);

                      return index === -1
                        ? onChange([...value, item.value])
                        : onChange(value.filter((el) => el !== item.value));
                    }}
                    defaultChecked={false}
                    checked={value !== undefined && value.indexOf(item.value) > -1}
                    ref={ref}
                  />
                }
                label={item.label}
                classes={{ label: classes.checkbox }}
              />
            ))}
          </FormGroup>
        )}
        defaultValue={['']}
      />
    </FormControl>
  );
};
