import React, { useEffect, useState } from 'react';
import { LabeledCheckbox, Link, TextInput, TextSelect, DateInput } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import { TagInputExtended } from 'ui/inputs/tagInputExtended/TagInputExtended';
import { useKnowledge } from 'hooks/useKnowledge/useKnowledge';
import { AutocompleteOptionType } from 'ui/inputs/chipAutocomplete/ChipAutocomplete.types';
import { ChipData } from 'ui/inputs/tagInputExtended/TagInputExtended.types';
import { Loader } from 'ui/loader/Loader';

import { ExperienceItemProps, SENIORITY, WORKING_TIME_TYPE } from './ExperienceItem.types';
import { useStyles } from './ExperienceItem.styles';

export const ExperienceItem: React.FC<ExperienceItemProps> = ({
  seniority,
  type,
  skills,
  stillWorking,
  methods,
  prefix,
  deleteItem,
  saveOnBlur,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { loading } = useKnowledge();
  const knowledgeSkills = useKnowledge().skills;

  const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOptionType[]>([]);

  const { register, watch, unregister, control } = methods;

  const checkboxName = `${prefix}.stillWorking`;
  const checkboxValue: boolean = watch(checkboxName, false);

  const minDateToPick = `${prefix}.start`;
  const minDateToPickValue = watch(minDateToPick);

  const chipSkills: ChipData[] = skills.map((item, index) => {
    return { key: index, label: item };
  });

  useEffect(() => {
    setAutocompleteOptions(knowledgeSkills.map((optionItem) => ({ title: optionItem })));
  }, [knowledgeSkills]);

  useEffect(() => {
    return () => {
      unregister([
        `${prefix}.skills`,
        `${prefix}.seniority`,
        `${prefix}.type`,
        `${prefix}.city`,
        `${prefix}.company`,
        `${prefix}.country`,
        `${prefix}.end`,
        `${prefix}.position`,
        `${prefix}.start`,
        `${prefix}.stillWorking`,
      ]);
    };
  }, []);

  if (loading) return <Loader />;

  return (
    <div className={classes.container}>
      <TextInput
        label="nazwa firmy"
        placeholder="Podaj nazwę firmy"
        name={`${prefix}.company`}
        ref={register}
        onBlur={saveOnBlur}
      />
      <TextInput
        label="stanowisko"
        placeholder="Podaj nazwę stanowiska"
        name={`${prefix}.position`}
        ref={register}
        onBlur={saveOnBlur}
      />
      <Controller
        control={methods?.control}
        name={`${prefix}.seniority`}
        defaultValue=""
        render={({ onChange, value = seniority, ref }) => (
          <TextSelect
            label="poziom doświadczenia"
            items={SENIORITY}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
              if (saveOnBlur) saveOnBlur();
            }}
            ref={ref}
          />
        )}
      />
      <TextInput
        label="miasto"
        placeholder="Podaj nazwę miasta"
        name={`${prefix}.city`}
        ref={register}
        onBlur={saveOnBlur}
      />
      <TextInput label="kraj" placeholder="Podaj kraj" name={`${prefix}.country`} ref={register} onBlur={saveOnBlur} />
      <Controller
        control={methods?.control}
        name={`${prefix}.type`}
        defaultValue=""
        render={({ onChange, value = type, ref }) => (
          <TextSelect
            label="wymiar zatrudnienia"
            items={WORKING_TIME_TYPE}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
              if (saveOnBlur) saveOnBlur();
            }}
            ref={ref}
          />
        )}
      />
      <TagInputExtended
        label={'główne umiejętności, które zdobyłeś (max 5)'}
        items={chipSkills}
        subtitle={'Jakie umiejętności zostały nabyte podczas pracy na stanowisku?'}
        maxItems={5}
        inverse={true}
        tiny={true}
        noMinHeight={true}
        name={`${prefix}.skills`}
        methods={methods}
        autocompleteOptions={autocompleteOptions}
        saveOnBlur={saveOnBlur}
      />
      <Box mb={3} />
      <LabeledCheckbox
        label={'dalej pracuję na tym stanowisku'}
        methods={methods}
        checked={stillWorking}
        name={`${prefix}.stillWorking`}
        onBlur={saveOnBlur}
      />
      <Box mb={3} />
      <div className={classes.periodContainer}>
        <DateInput
          label="rozpoczęcie pracy"
          placeholder="MM.RRRR"
          name={`${prefix}.start`}
          control={control}
          saveOnBlur={saveOnBlur}
        />
        <DateInput
          label="koniec pracy"
          placeholder="MM.RRRR"
          name={`${prefix}.end`}
          control={control}
          disabled={checkboxValue}
          minDate={minDateToPickValue}
          saveOnBlur={saveOnBlur}
        />
      </div>
      <Box mb={3} />
      {deleteItem && (
        <Link>
          <Typography
            onClick={() => {
              if (deleteItem) deleteItem();
            }}
          >
            usuń
          </Typography>
        </Link>
      )}
    </div>
  );
};
