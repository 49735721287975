/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { DateInput, LabeledCheckbox, Link, TextInput } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';

import { useStyles } from './EducationItem.styles';
import { EducationItemProps } from './EducationItem.types';

export const EducationItem: React.FC<EducationItemProps> = ({ methods, index, prefix, deleteItem }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { register, watch, control } = methods;

  const checkboxName = `${prefix}.stillLearning`;
  const checkboxValue: boolean = watch(checkboxName, false);

  const minDateToPick = `${prefix}.start`;
  const minDateToPickValue: string = watch(minDateToPick);

  return (
    <div className={classes.container}>
      <TextInput name={`${prefix}.name`} label="nazwa uczelni" placeholder="Podaj nazwę uczelni" ref={register} />
      <TextInput name={`${prefix}.major`} label="kierunek" placeholder="Podaj nazwę specjalizacji" ref={register} />
      <TextInput name={`${prefix}.city`} label="miasto" placeholder="Podaj nazwę miasta" ref={register} />
      <TextInput name={`${prefix}.country`} label="kraj" placeholder="Podaj nazwę kraju" ref={register} />
      <LabeledCheckbox
        label={'dalej się tu uczę'}
        //@ts-ignore
        methods={methods}
        checked={checkboxValue}
        name={`${prefix}.stillLearning`}
      />
      <Box mb={2} />
      <div className={classes.periodContainer}>
        <DateInput label="rozpoczęcie" placeholder="MM.RRRR" name={`${prefix}.start`} control={control} />
        <DateInput
          label="zakończenie"
          placeholder="MM.RRRR"
          name={`${prefix}.end`}
          control={control}
          disabled={checkboxValue}
          minDate={minDateToPickValue}
        />
      </div>

      <Box mb={3} />
      {deleteItem && (
        <Link>
          <Typography
            onClick={() => {
              if (deleteItem) deleteItem(index);
            }}
          >
            usuń
          </Typography>
        </Link>
      )}
    </div>
  );
};
