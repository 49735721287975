import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  panel: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    margin: 0,
    marginBottom: theme.spacing(3),
    gridRow: '1',
    [theme.breakpoints.up('md')]: {
      gridRow: '1',
      gridColumn: '2',
      margin: 0,
    },
  },
}));
