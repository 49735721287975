import { useContext } from 'react';

import { JobOfferContext } from 'context/jobOffer/JobOfferContext';
import { JobOfferContextProps } from 'context/jobOffer/JobOfferContext.types';

export const useJobOffers: () => JobOfferContextProps = () => {
  const context = useContext(JobOfferContext);

  if (context === undefined) {
    throw Error('useJobOffers must be used within a jobOfferContext');
  }

  return context;
};
