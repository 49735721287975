import { EmailConfirmation } from 'ui/emailConfirmation/EmailConfirmation';

export const WelcomeRegister = (): JSX.Element => {
  return (
    <EmailConfirmation
      title={'Witaj w gronie HR-ME'}
      subtitle={'Na Twój adres e-mail został wysłany link aktywacyjny.'}
      comment={'Kliknij w niego, a następnie zaloguj się do platformy.'}
    />
  );
};
