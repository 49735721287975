import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ButtonSimple } from '@hrme/shared/lib/components/buttons';
import parse from 'html-react-parser';
import { useInView } from 'react-intersection-observer';

import InfoIcon from 'assets/icon/info.svg';
import Knowledge from 'assets/icon/knowledge.svg';
import Task from 'assets/icon/task.svg';
import Pen from 'assets/icon/pen.svg';
import ForkIcon from 'assets/icon/fork_icon.svg';
import { stepStatusIcon } from 'helpers/stepStatusIcon';
import { MobilePopup } from 'ui/mobilePopup/MobilePopup';

import { CareerPathStepSummaryProps, ModalType } from './CareerPathStepSummary.types';
import { useStyles } from './CareerPathStepSummary.styles';

export const CareerPathStepSummary: React.FC<CareerPathStepSummaryProps> = ({
  data,
  isExpanded,
  tasks,
  setVisible,
  index,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const { pathName, shareOfPositions, shareOfOffers, title, status } = data.summary;
  const [openModal, setOpenModal] = useState({ openModal: false, type: ModalType.INFO });
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (setVisible && isExpanded) {
      setVisible({ isVisible: inView, index });
    }
  }, [inView]);

  return (
    <>
      {isDesktop ? (
        <div className={classes.container} ref={isExpanded ? ref : null}>
          <div className={classes.topWrapper}>
            <div className={classes.status} style={{ backgroundImage: `url(${stepStatusIcon(status)})` }} />
            <div className={classes.top}>
              <Typography variant="body2" className={classes.pathTitle}>
                <img src={stepStatusIcon(status)} alt="" className={classes.statusIcon} />
                {title}
              </Typography>
              <div className={classes.counterAndButton}>
                {tasks && tasks.length > 0 && (
                  <div className={classes.counter}>
                    <img src={ForkIcon} alt="fork-icon" />
                    <Box mr={0.5} />
                    <Typography variant="subtitle1">
                      {`Zadania (${tasks.reduce(
                        (accumulator, currentValue) => accumulator + (currentValue.completed ? 1 : 0),
                        0,
                      )}/${tasks.length})`}
                    </Typography>
                  </div>
                )}
                <Box mr={1} />
                <ButtonSimple>
                  <Typography variant="subtitle1">{isExpanded ? 'Zwiń' : 'Rozwiń'}</Typography>
                </ButtonSimple>
              </div>
            </div>
          </div>
          <Divider style={{ width: '100%', backgroundColor: theme.palette.background.default }} />
          <div className={classes.bottom}>
            <Typography variant="body1" style={{ fontSize: '12px', color: theme.palette.grey.A200 }}>
              {shareOfPositions === '0%' && shareOfOffers === '0%' ? (
                <>Brak danych o ofertach pracy</>
              ) : (
                <>
                  Udział w ofertach pracy {pathName}{' '}
                  {parse(`<span style="font-weight: 600; color: black">${shareOfPositions}</span>`)}, wszystkie oferty{' '}
                  {parse(`<span style="font-weight: 600; color: black">${shareOfOffers}</span>`)}
                </>
              )}
            </Typography>
          </div>
        </div>
      ) : (
        <div className={classes.container} ref={isExpanded ? ref : null}>
          <div className={classes.topWrapper}>
            <div className={classes.top}>
              <Typography variant="body2" className={classes.pathTitle}>
                {title}
              </Typography>
              <img src={stepStatusIcon(status, true)} alt="" className={classes.statusIcon} />
            </div>
          </div>
          <Divider style={{ width: '100%', backgroundColor: theme.palette.background.default }} />
          <div className={classes.buttonWrapper}>
            <Box className={classes.taskButton} onClick={() => setOpenModal({ openModal: true, type: ModalType.INFO })}>
              <img src={InfoIcon} alt="info" />
            </Box>
            <Box
              className={classes.taskButton}
              onClick={() => setOpenModal({ openModal: true, type: ModalType.KNOWLEDGE })}
            >
              <img src={Knowledge} alt="knowledge" />
            </Box>
            <Box
              className={classes.taskButton}
              onClick={() => setOpenModal({ openModal: true, type: ModalType.TASKS })}
            >
              <img src={Task} alt="tasks" />
              <Box mr={0.5} />
              <Typography variant="caption">
                {`${tasks?.reduce((accumulator, currentValue) => accumulator + (currentValue.completed ? 1 : 0), 0)}/${
                  tasks?.length
                }`}
              </Typography>
            </Box>
            <Box
              className={classes.taskButton}
              onClick={() => setOpenModal({ openModal: true, type: ModalType.LEVEL })}
            >
              <img src={Pen} alt="pen" />
            </Box>
          </div>
          <Divider style={{ width: '100%', backgroundColor: theme.palette.background.default }} />
          <div className={classes.bottom}>
            <Typography variant="body1" style={{ fontSize: '9px', color: theme.palette.grey.A200 }}>
              {shareOfPositions === '0%' && shareOfOffers === '0%' ? (
                <>Brak danych o ofertach pracy</>
              ) : (
                <>
                  Udział w ofertach pracy {pathName}{' '}
                  {parse(`<span style="font-weight: 600; color: black">${shareOfPositions}</span>`)}, wszystkie oferty{' '}
                  {parse(`<span style="font-weight: 600; color: black">${shareOfOffers}</span>`)}
                </>
              )}
            </Typography>
            <MobilePopup openModal={openModal} data={data} setOpenModal={setOpenModal} />
          </div>
        </div>
      )}
    </>
  );
};
