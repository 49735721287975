import React, { forwardRef, useState } from 'react';
import { Box, Fade, Typography, useTheme } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { ButtonDefault, Link } from '@hrme/shared';

import { ConfirmationModal } from 'ui/confirmationModal/ConfirmationModal';

import { NewCareerPathProps } from './NewCareerPath.types';
import { useStyles } from './NewCareerPath.styles';
import { SectionPath } from './sectionPath/SectionPath';

export const NewCareerPath: React.FC<NewCareerPathProps> = forwardRef(
  (
    { isOpen, onClose, careerPathItems, fullPathTypeItems, handleSubmit, pathId, methods, onDelete, valueIndex },
    ref,
  ) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [modalOpen, setModalOpen] = useState(false);

    return (
      // adding forwardRef fought down this message: https://photos.app.goo.gl/HJuyZcYqmbjtTuEu7
      <Fade in={isOpen} ref={ref}>
        <div className={classes.paper}>
          <div className={classes.title}>
            <Typography variant="h4" id="transition-modal-title">
              {!!pathId ? 'Edytuj dane' : 'Nowa ścieżka kariery'}
            </Typography>
            <CloseOutlinedIcon style={{ cursor: 'pointer' }} onClick={onClose} />
          </div>
          {!pathId && (
            <>
              <Box mb={3} />
              <Typography id="transition-modal-description" className={classes.subtitle}>
                Wybierz ścieżkę kariery i uzupełnij dodatkowe informacje. Nasza ekipa ciągle pracuje nad nowymi
                ścieżkami kariery, które niebawem będą dostępne w platformie.
              </Typography>
            </>
          )}
          <Box mb={5} />
          <form onSubmit={methods.handleSubmit(handleSubmit)} className={classes.form}>
            <SectionPath
              methods={methods}
              pathTypeItems={careerPathItems}
              fullPathTypeItems={fullPathTypeItems}
              pathId={pathId}
              valueIndex={valueIndex}
            />
            {/* career path specifics like seniority and pay - hidden
             <Box mb={3} />
            <LabeledCheckbox
              label={'jestem otwarty na oferty pracy'}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              methods={methods as UseFormMethods<any>}
              name={'openForOffers'}
            />
            <Box mb={3} />

            {readyForWork && <SectionInfo methods={methods} />} 
            also needed in the lines before return statement:
            // const readyForWork = methods.watch('openForOffers');*/}

            <Box mb={5} />
            <ButtonDefault>{!pathId ? 'stwórz nową ścieżkę kariery' : 'zapisz zmiany'}</ButtonDefault>
            {pathId && (
              <Link>
                <Box mb={2} />
                <Typography onClick={() => setModalOpen(true)}>usuń ścieżkę</Typography>
              </Link>
            )}
            <ConfirmationModal setModalOpen={setModalOpen} modalOpen={modalOpen} handleDelete={onDelete} />
          </form>
        </div>
      </Fade>
    );
  },
);
