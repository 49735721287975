import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: 78,
    top: 0,
    left: 0,
    position: 'sticky',
    zIndex: 2,
  },
  header: {
    width: '100%',
    maxWidth: 1288,
    padding: '0 32px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  navWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuWrapper: {
    top: 77,
    left: 0,
    borderTop: `1px solid ${theme.palette.background.default}`,
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    zIndex: 999,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    listStyle: 'none',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 0),
  },
  linkStyles: {
    color: theme.palette.common.black,
    textDecoration: 'none',
  },
}));
