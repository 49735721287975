import {
  CareerPathFormContextType,
  SpecializationState,
  AvailabilityState,
  PositionState,
  EducationState,
  PortfolioSocialMediaState,
  ExperienceState,
  FillState,
  TechnologyState,
  LanguagesState,
  SoftSkillsState,
} from '../careerPathFormContext/CareerPathFormContext.types';

export const RESET_FORM = 'RESET_FORM';
export const SET_FORM_SPECIALIZATION = 'SET_FORM_SPECIALIZATION';
export const SET_FORM_AVAILABILITY = 'SET_FORM_AVAILABILITY';
export const SET_FORM_POSITION = 'SET_FORM_POSITION';
export const SET_FORM_SOFTSKILLS = 'SET_FORM_SOFTSKILLS';
export const SET_FORM_EDUCATION = 'SET_FORM_EDUCATION';
export const SET_FORM_FILL = 'SET_FORM_FILL';
export const SET_FORM_TECHNOLOGY = 'SET_FORM_TECHNOLOGY';
export const SET_FORM_PORTFOLIO_SOCIAL_MEDIA = 'SET_FORM_PORTFOLIO_SOCIAL_MEDIA';
export const SET_FORM_EXPERIENCE = 'SET_FORM_EXPERIENCE';
export const SET_FORM_LANGUAGES = 'SET_FORM_LANGUAGES';

type ReducerAction<Type, Payload = undefined> = {
  type: Type;
  payload: Payload;
};

export type FormAction =
  | ReducerAction<typeof RESET_FORM>
  | ReducerAction<typeof SET_FORM_SPECIALIZATION, SpecializationState>
  | ReducerAction<typeof SET_FORM_AVAILABILITY, AvailabilityState>
  | ReducerAction<typeof SET_FORM_POSITION, PositionState>
  | ReducerAction<typeof SET_FORM_SOFTSKILLS, SoftSkillsState>
  | ReducerAction<typeof SET_FORM_EDUCATION, EducationState>
  | ReducerAction<typeof SET_FORM_TECHNOLOGY, TechnologyState>
  | ReducerAction<typeof SET_FORM_FILL, FillState>
  | ReducerAction<typeof SET_FORM_EXPERIENCE, ExperienceState>
  | ReducerAction<typeof SET_FORM_LANGUAGES, LanguagesState>
  | ReducerAction<typeof SET_FORM_PORTFOLIO_SOCIAL_MEDIA, PortfolioSocialMediaState>;

export type FormReducerType = (state: CareerPathFormContextType, action: FormAction) => CareerPathFormContextType;
