import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Close } from '@material-ui/icons';

import { AuthenticationPaths } from 'routing/auth/AuthenticationPaths';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';

import { useStyles } from './CookieAlert.styles';

export const CookieAlert: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { currentUser, authPending } = useAuthentication();
  const [showAlert, setShowAlert] = useState(true);

  const showCookieAlert = localStorage.getItem('acceptedCookies') !== 'true';

  const handleCloseAlert = () => {
    localStorage.setItem('acceptedCookies', 'true');
    setShowAlert(false);
  };

  if (authPending || currentUser || !showAlert || !showCookieAlert) return null;

  return (
    <Box className={classes.wrapper}>
      <Typography className={classes.text}>
        Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony
        oznacza, że zgadzasz się na ich użycie.{' '}
        <Link to={AuthenticationPaths.policy} className={classes.text}>
          Polityka prywatności
        </Link>
      </Typography>
      <Close htmlColor="white" className={classes.closeIcon} onClick={handleCloseAlert} />
    </Box>
  );
};
