import React, { useEffect, useMemo } from 'react';
import { ButtonDefault, Link } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router';

import { MainFlowLayout } from 'ui/layouts/mainFlowLayout/MainFlowLayout';
import { Topic } from 'ui/topic/Topic';
import { arrayOfStringToFormControlLabelItem } from 'helpers/arrayOfStringToFormControlLabelItem';
import { useKnowledge } from 'hooks/useKnowledge/useKnowledge';
import { checkKeyDown } from 'helpers/checkKeyDown';
import { RadioChipGroupComponent } from 'ui/radioChipGroupComponent/RadioChipGroupComponent';
import { RadioChipGroupComponentType } from 'ui/radioChipGroupComponent/RadioChipGroupComponent.types';
import { Loader } from 'ui/loader/Loader';

import { SpecializationProps } from './Specialization.types';
import { useStyles } from './Specialization.styles';

export const Specialization: React.FC<SpecializationProps> = ({ methods, onFormSubmit }) => {
  const { handleSubmit } = methods;
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const { loading, fetchSuggestions, categories } = useKnowledge();

  const specItems = useMemo(() => {
    const items = arrayOfStringToFormControlLabelItem(categories);

    return items;
  }, [categories]);

  const positions = methods.watch(['specialization', 'other']);
  const { specialization, other } = positions;
  const allowNextStep = specialization?.length > 0 || other?.length > 0;

  useEffect(() => {
    const getSuggestions = async () => {
      await fetchSuggestions();
    };
    getSuggestions();
  }, []);

  useEffect(() => {
    if (!loading) onFormSubmit(methods.getValues(), undefined, true);
  }, [specialization, other]);

  const stepBack = () => {
    onFormSubmit(methods.getValues(), true);
    history.goBack();
  };

  if (loading) return <Loader />;

  return (
    <>
      <MainFlowLayout topic={<Topic />} progress={{ label: 'Zainteresowania', length: 10, current: 2 }}>
        <form
          className={classes.container}
          onSubmit={handleSubmit((d) => onFormSubmit(d))}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <div className={classes.contentContainer}>
            <Typography variant="h2" className={classes.header}>
              Zainteresowania
            </Typography>
            <Box mb={3} />
            <Typography variant="body2" className={classes.subheader}>
              Co Cię interesuje? W jakiej dziedzinie IT chcesz się rozwijać? Możesz zaznaczyć więcej niż jedną opcję.
            </Typography>
            <Box mb={4.25} />
            <RadioChipGroupComponent
              positionItems={specItems}
              name={RadioChipGroupComponentType.SPECIALIZATION}
              methods={methods}
            />
            <Box mb={3.75} />
            <div className={classes.wrapper}>
              <ButtonDefault disabled={!allowNextStep}>następny krok (dostępność)</ButtonDefault>
              <Box mb={2} />
              <Link>
                <Typography onClick={stepBack}>cofnij</Typography>
              </Link>
            </div>
          </div>
        </form>
      </MainFlowLayout>
    </>
  );
};
