import { bannerOfTutorialFromFB } from 'context/discountsOnTutorials/DiscountsOnTutorialsContext.types';

import { CareerPathBanner, CareerPathSummaryBanner } from './bannersViews/BannersViews';
import { Variants } from './banners.types';
const randomVariant: Variants = Math.random() > 0.5 ? 'light' : 'dark';

export const getCareerPathSkillsBanner = (
  banner: bannerOfTutorialFromFB,
  variant: Variants = randomVariant,
): JSX.Element | undefined => {
  return <CareerPathBanner variant={variant} content={banner} />;
};
export const getCareerPathSummaryBanner = (
  banner: bannerOfTutorialFromFB,
  variant: Variants = randomVariant,
): JSX.Element | undefined => {
  return <CareerPathSummaryBanner variant={variant} content={banner} />;
};
