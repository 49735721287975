import { useTheme, Box, useMediaQuery } from '@material-ui/core';
import firebase from 'firebase';

import { bannerOfTutorialFromFB } from 'context/discountsOnTutorials/DiscountsOnTutorialsContext.types';
import { Variants } from '../banners.types';

import { useStylesLight, useStylesDark } from './CareerPathBanner.styles';
import { useStylesLightSquere, useStylesDarkSquere } from './CareerPathSummaryBanner.styles';

export const CareerPathBanner = ({
  variant,
  content,
}: {
  variant: Variants;
  content: bannerOfTutorialFromFB;
}): JSX.Element => {
  const theme = useTheme();
  const light = useStylesLight(theme);
  const dark = useStylesDark(theme);
  const sendAnalytics = () => {
    firebase.analytics().logEvent(`CareerPathBanner variant ${variant} clicked`);
  };
  const classes = variant === 'light' ? light : dark;
  const { banner, code, link } = content;
  const onclickHandler = () => {
    sendAnalytics();
    window.open(link);
  };
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = useMediaQuery('(max-width:400px)');
  const checkToShow = (pargagraf: string) => {
    if (!isMobile) return true;
    if (pargagraf.length > 30 && !isMobile) return true;
    else return false;
  };

  return (
    <div className={classes.container} onClick={onclickHandler}>
      <div className={classes.content}>
        <div className={classes.left}>
          <div className={classes.p1}>{checkToShow(banner[0]) && banner[0]}</div>
          <div className={classes.p2}>
            {banner[1]}&nbsp;
            {!isDesktop && '\n'}
            <span className={classes.span}>{banner[2]}</span>
          </div>
          <div className={classes.p3}>{[banner[3]]}</div>
        </div>
        <div className={classes.right}>
          <div className={classes.button}>
            <span>Kod: </span>
            <span className={classes.code}>{code}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export const CareerPathSummaryBanner = ({
  variant,
  content,
}: {
  variant: Variants;
  content: bannerOfTutorialFromFB;
}): JSX.Element => {
  const theme = useTheme();
  const light = useStylesLightSquere(theme);
  const dark = useStylesDarkSquere(theme);
  const { banner, code, link } = content;
  const sendAnalytics = () => {
    firebase.analytics().logEvent(`CareerPathSummaryBanner variant ${variant} clicked`);
  };
  const classes = variant === 'light' ? light : dark;
  const onclickHandler = () => {
    sendAnalytics();
    window.open(link);
  };

  return (
    <>
      <Box mb={1} />
      <div className={classes.container} onClick={onclickHandler}>
        <div className={classes.top}>
          <div className={classes.logo}></div>
          <div className={classes.topText}>KURS</div>
        </div>
        <div className={classes.center}>
          <div className={classes.p1}>{banner[0]}</div>
          <div className={classes.p2}>
            {banner[1]} {banner[2]}
          </div>
          <div className={classes.p3}>{banner[3]}</div>
          <div className={classes.button}>
            <span>Kod: </span> <span className={classes.code}>{code}</span>
          </div>
        </div>
      </div>
    </>
  );
};
