import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    textAlign: 'left',
    fontSize: '24px',
    fontWeight: 700,
  },
  subheader: {
    width: '100%',
    textAlign: 'left',
  },
  link: {
    fontWeight: 600,
  },
}));
