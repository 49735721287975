import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  boxDescriptionTitle: {
    fontSize: '18px',
    fontWeight: 600,
  },
  socialMediaItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  logoContainer: {
    width: '24px',
    height: '24px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
}));
