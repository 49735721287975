import { makeStyles } from '@material-ui/core';

import bglight from './bglight.png';
import bgdark from './bgdark.png';

export const useStylesLight = makeStyles((theme) => ({
  container: {
    position: 'relative',
    marginTop: '16px',
    marginBottom: '16px',
    width: '100%',
    height: '102px',
    backgroundImage: `url(${bglight})`,
    backgroundSize: 'cover',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '2px',
    boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.05)',
  },
  content: {
    height: '100%',
    padding: '0px',
    position: 'relative',
    margin: '0 20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  left: {
    fontFamily: 'Silka',
    fontStyle: 'normal',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  p1: {
    position: 'relative',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '130%',
    color: '#D33078',
  },
  p2: {
    position: 'relative',
    fontWeight: 700,
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
    lineHeight: '110%',
    color: '#1D194B',
  },
  span: {
    color: '#D33078',
  },
  p3: {
    position: 'relative',
    fontWeight: 600,
    fontSize: '12px',
    lineheight: '130%',
    color: '#1D194B',
  },
  right: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    position: 'relative',
    top: '-3px',
    padding: '6px 12px 6px 12px',
    background: '#1D194B',
    borderRadius: '18px 18px 18px 0px',
    fontFamily: 'Silka',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '150%',
    color: '#FFFFFF',
    cursor: 'pointer',
  },
  code: {
    color: '#D8527C',
  },
}));
export const useStylesDark = makeStyles((theme) => ({
  container: {
    marginTop: '16px',
    marginBottom: '16px',
    width: '100%',
    height: '102px',
    backgroundImage: `url(${bgdark})`,
    backgroundSize: 'cover',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '2px',
    boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.05)',
  },
  content: {
    height: '100%',
    padding: '0px',
    position: 'relative',
    margin: '0 20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  left: {
    fontFamily: 'Silka',
    fontStyle: 'normal',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  p1: {
    position: 'relative',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '130%',
    color: '#FFF',
  },
  p2: {
    position: 'relative',
    fontWeight: 700,
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
    lineHeight: '110%',
    color: '#45CFD7',
  },
  span: {
    color: '#45CFD7',
  },
  p3: {
    position: 'relative',
    fontWeight: 600,
    fontSize: '12px',
    lineheight: '130%',
    color: '#FFF',
  },
  right: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  },
  button: {
    //all: 'unset',
    position: 'relative',
    top: '-3px',
    cursor: 'pointer',
    padding: '6px 12px 6px 12px',
    background: '#45CFD7',
    borderRadius: '18px 18px 18px 0px',
    fontFamily: 'Silka',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '150%',
    color: '#FFFFFF',
  },
  code: {
    color: '#3B34AF ',
  },
}));
