import CompanyLogo from 'assets/logo/companies/intel_logo_square.svg';

export const BOX_PATH_SUMMARY_DATA = {
  title: 'Podsumowanie ścieżki',
  barValues: [142, 36, 32, 101],
  information: {
    'Twoj poziom:': 'Mid',
    'Lata doswiadczenia:': '5 lat',
    'szukam pracy:': 'tak',
  },
};

export const BOX_STAT_DATA = {
  popularityPerCategory: [
    {
      category: 'all',
      trends: [
        {
          timestamp: 1619834406876,
          Git: 23,
          JavaScript: 24,
          Java: 19,
          Docker: 18,
          SQL: 18,
        },
        {
          timestamp: 1620093609776,
          Git: 23,
          JavaScript: 23,
          Java: 18,
          Docker: 18,
          SQL: 17,
        },
        {
          timestamp: 1620266406462,
          Git: 23,
          JavaScript: 23,
          Java: 19,
          Docker: 18,
          SQL: 18,
        },
        {
          timestamp: 1620439211311,
          Git: 23,
          JavaScript: 23,
          Java: 18,
          Docker: 18,
          SQL: 18,
        },
        {
          timestamp: 1620698413947,
          Git: 22,
          JavaScript: 23,
          Java: 18,
          Docker: 18,
          SQL: 18,
        },
        {
          timestamp: 1621044007710,
          Git: 23,
          JavaScript: 23,
          Java: 18,
          Docker: 18,
          SQL: 18,
        },
        {
          timestamp: 1621303206821,
          Git: 23,
          JavaScript: 23,
          Java: 18,
          Docker: 18,
          SQL: 18,
        },
        {
          timestamp: 1621476006839,
          Git: 22,
          JavaScript: 23,
          Java: 18,
          Docker: 17,
          SQL: 18,
        },
        {
          timestamp: 1621648807626,
          Git: 23,
          JavaScript: 23,
          Java: 18,
          Docker: 17,
          SQL: 18,
        },
        {
          timestamp: 1621908006917,
          Git: 22,
          JavaScript: 23,
          Java: 19,
          Docker: 17,
          SQL: 18,
        },
        {
          timestamp: 1622080808739,
          Git: 23,
          JavaScript: 23,
          Java: 19,
          Docker: 17,
          SQL: 19,
        },
        {
          timestamp: 1622253604989,
          Git: 22,
          JavaScript: 23,
          Java: 19,
          Docker: 17,
          SQL: 19,
        },
        {
          timestamp: 1622512806118,
          Git: 23,
          JavaScript: 23,
          Java: 19,
          Docker: 17,
          SQL: 19,
        },
        {
          timestamp: 1622685606731,
          Git: 22,
          JavaScript: 23,
          Java: 19,
          Docker: 17,
          SQL: 19,
        },
        {
          timestamp: 1623117606258,
          Git: 32,
          JavaScript: 25,
          Java: 23,
          Docker: 23,
          SQL: 24,
        },
        {
          timestamp: 1623722404984,
          Git: 23,
          JavaScript: 22,
          Java: 19,
          Docker: 17,
          SQL: 19,
        },
        {
          timestamp: 1624068007484,
          Git: 22,
          JavaScript: 22,
          Java: 20,
          Docker: 18,
          SQL: 19,
        },
        {
          timestamp: 1624327208082,
          Git: 22,
          JavaScript: 22,
          Java: 19,
          Docker: 18,
          SQL: 19,
        },
        {
          timestamp: 1624672806043,
          Git: 23,
          JavaScript: 22,
          Java: 20,
          Docker: 18,
          SQL: 19,
        },
        {
          timestamp: 1625104807609,
          Git: 23,
          JavaScript: 22,
          Java: 19,
          Docker: 18,
          SQL: 19,
        },
        {
          timestamp: 1625277611194,
          Git: 31,
          JavaScript: 25,
          Java: 23,
          Docker: 25,
          SQL: 24,
        },
        {
          timestamp: 1625709606250,
          Git: 22,
          JavaScript: 23,
          Java: 19,
          Docker: 18,
          SQL: 18,
        },
        {
          timestamp: 1625882410283,
          Git: 22,
          JavaScript: 22,
          Java: 19,
          Docker: 18,
          SQL: 18,
        },
        {
          timestamp: 1626919207357,
          Git: 22,
          JavaScript: 22,
          Java: 20,
          Docker: 17,
          SQL: 18,
        },
        {
          timestamp: 1627524005680,
          Git: 22,
          JavaScript: 22,
          Java: 20,
          Docker: 17,
          SQL: 17,
        },
        {
          timestamp: 1627696804982,
          Git: 21,
          JavaScript: 22,
          Java: 19,
          Docker: 17,
          SQL: 17,
        },
        {
          timestamp: 1627956006659,
          Git: 22,
          JavaScript: 22,
          Java: 20,
          Docker: 17,
          SQL: 18,
        },
        {
          timestamp: 1628128806058,
          Git: 22,
          JavaScript: 22,
          Java: 20,
          Docker: 17,
          SQL: 17,
        },
        {
          timestamp: 1628301604448,
          Git: 22,
          JavaScript: 22,
          Java: 19,
          Docker: 17,
          SQL: 18,
        },
        {
          timestamp: 1630548004812,
          Git: 30,
          JavaScript: 24,
          Java: 26,
          Docker: 24,
          SQL: 23,
        },
        {
          timestamp: 1631325603711,
          Git: 29,
          JavaScript: 24,
          Java: 25,
          Docker: 24,
          SQL: 23,
        },
        {
          timestamp: 1632794406223,
          Git: 30,
          JavaScript: 25,
          Java: 25,
          Docker: 25,
          SQL: 23,
        },
        {
          timestamp: 1634004004078,
          Git: 21,
          JavaScript: 22,
          Java: 19,
          Docker: 17,
          SQL: 17,
        },
        {
          timestamp: 1634176805748,
          Git: 30,
          JavaScript: 25,
          Java: 24,
          Docker: 24,
          SQL: 22,
        },
      ],
      skillNames: ['Git', 'JavaScript', 'Java', 'Docker', 'SQL'],
      currentTopSkills: [
        { name: 'Git', popularity: 30 },
        { name: 'JavaScript', popularity: 25 },
        { name: 'Java', popularity: 24 },
        { name: 'Docker', popularity: 24 },
        { name: 'SQL', popularity: 22 },
      ],
    },
    {
      category: 'DevOps',
      trends: [
        {
          timestamp: 1619834406876,
          Docker: 70,
          AWS: 56,
          Linux: 55,
          Kubernetes: 51,
          Python: 47,
        },
        {
          timestamp: 1620093609776,
          Docker: 72,
          AWS: 55,
          Linux: 56,
          Kubernetes: 52,
          Python: 47,
        },
        {
          timestamp: 1620266406462,
          Docker: 72,
          AWS: 54,
          Linux: 56,
          Kubernetes: 51,
          Python: 49,
        },
        {
          timestamp: 1620439211311,
          Docker: 71,
          AWS: 56,
          Linux: 56,
          Kubernetes: 52,
          Python: 48,
        },
        {
          timestamp: 1620698413947,
          Docker: 72,
          AWS: 55,
          Linux: 55,
          Kubernetes: 51,
          Python: 48,
        },
        {
          timestamp: 1620871204757,
          Docker: 72,
          AWS: 55,
          Linux: 55,
          Kubernetes: 49,
          Python: 47,
        },
        {
          timestamp: 1621044007710,
          Docker: 73,
          AWS: 53,
          Linux: 57,
          Kubernetes: 50,
          Python: 48,
        },
        {
          timestamp: 1621303206821,
          Docker: 72,
          AWS: 54,
          Linux: 55,
          Kubernetes: 50,
          Python: 47,
        },
        {
          timestamp: 1621476006839,
          Docker: 71,
          AWS: 52,
          Linux: 56,
          Kubernetes: 51,
          Python: 47,
        },
        {
          timestamp: 1621648807626,
          Docker: 70,
          AWS: 51,
          Linux: 55,
          Kubernetes: 50,
          Python: 48,
        },
        {
          timestamp: 1621908006917,
          Docker: 70,
          AWS: 50,
          Linux: 56,
          Kubernetes: 49,
          Python: 49,
        },
        {
          timestamp: 1622080808739,
          Docker: 69,
          AWS: 48,
          Linux: 57,
          Kubernetes: 50,
          Python: 50,
        },
        {
          timestamp: 1622253604989,
          Docker: 71,
          AWS: 49,
          Linux: 57,
          Kubernetes: 51,
          Python: 48,
        },
        {
          timestamp: 1622512806118,
          Docker: 71,
          AWS: 49,
          Linux: 57,
          Kubernetes: 51,
          Python: 49,
        },
        {
          timestamp: 1622685606731,
          Docker: 72,
          AWS: 51,
          Linux: 58,
          Kubernetes: 52,
          Python: 48,
        },
        {
          timestamp: 1622858409790,
          Docker: 72,
          AWS: 52,
          Linux: 58,
          Kubernetes: 53,
          Python: 48,
        },
        {
          timestamp: 1623117606258,
          Docker: 72,
          AWS: 51,
          Linux: 60,
          Kubernetes: 53,
          Python: 48,
        },
        {
          timestamp: 1623463205279,
          Docker: 69,
          AWS: 51,
          Linux: 59,
          Kubernetes: 53,
          Python: 49,
        },
        {
          timestamp: 1623722404984,
          Docker: 70,
          AWS: 52,
          Linux: 60,
          Kubernetes: 54,
          Python: 47,
        },
        {
          timestamp: 1624068007484,
          Docker: 67,
          AWS: 51,
          Linux: 58,
          Kubernetes: 53,
          Python: 51,
        },
        {
          timestamp: 1624327208082,
          Docker: 66,
          AWS: 51,
          Linux: 57,
          Kubernetes: 53,
          Python: 49,
        },
        {
          timestamp: 1624672806043,
          Docker: 63,
          AWS: 50,
          Linux: 55,
          Kubernetes: 55,
          Python: 47,
        },
        {
          timestamp: 1624932007464,
          Docker: 63,
          AWS: 50,
          Linux: 57,
          Kubernetes: 55,
          Python: 48,
        },
        {
          timestamp: 1625104807609,
          Docker: 63,
          AWS: 52,
          Linux: 61,
          Kubernetes: 57,
          Python: 49,
        },
        {
          timestamp: 1625277611194,
          Docker: 63,
          AWS: 55,
          Linux: 59,
          Kubernetes: 59,
          Python: 50,
        },
        {
          timestamp: 1625536810671,
          Docker: 62,
          AWS: 54,
          Linux: 59,
          Kubernetes: 59,
          Python: 49,
        },
        {
          timestamp: 1625709606250,
          Docker: 64,
          AWS: 54,
          Linux: 57,
          Kubernetes: 59,
          Python: 47,
        },
        {
          timestamp: 1625882410283,
          Docker: 64,
          AWS: 53,
          Linux: 57,
          Kubernetes: 61,
          Python: 43,
        },
        {
          timestamp: 1626314409438,
          Docker: 64,
          AWS: 50,
          Linux: 56,
          Kubernetes: 61,
          Python: 46,
        },
        {
          timestamp: 1626919207357,
          Docker: 68,
          AWS: 55,
          Linux: 60,
          Kubernetes: 61,
          Python: 41,
        },
        {
          timestamp: 1627524005680,
          Docker: 67,
          AWS: 58,
          Linux: 58,
          Kubernetes: 61,
          Python: 41,
        },
        {
          timestamp: 1627696804982,
          Docker: 67,
          AWS: 56,
          Linux: 55,
          Kubernetes: 63,
          Python: 41,
        },
        {
          timestamp: 1627956006659,
          Docker: 66,
          AWS: 53,
          Linux: 55,
          Kubernetes: 60,
          Python: 40,
        },
        {
          timestamp: 1628128806058,
          Docker: 64,
          AWS: 51,
          Linux: 53,
          Kubernetes: 57,
          Python: 42,
        },
        {
          timestamp: 1628301604448,
          Docker: 65,
          AWS: 51,
          Linux: 55,
          Kubernetes: 57,
          Python: 45,
        },
        {
          timestamp: 1628560807508,
          Docker: 66,
          AWS: 53,
          Linux: 54,
          Kubernetes: 57,
          Python: 49,
        },
        {
          timestamp: 1628733605935,
          Docker: 68,
          AWS: 57,
          Linux: 58,
          Kubernetes: 56,
          Python: 49,
        },
        {
          timestamp: 1628906405405,
          Docker: 68,
          AWS: 58,
          Linux: 58,
          Kubernetes: 55,
          Python: 50,
        },
        {
          timestamp: 1629338405136,
          Docker: 66,
          AWS: 58,
          Linux: 60,
          Kubernetes: 51,
          Python: 54,
        },
        {
          timestamp: 1629511205084,
          Docker: 67,
          AWS: 57,
          Linux: 60,
          Kubernetes: 51,
          Python: 53,
        },
        {
          timestamp: 1629943206138,
          Docker: 64,
          AWS: 54,
          Linux: 59,
          Kubernetes: 49,
          Python: 52,
        },
        {
          timestamp: 1630375206475,
          Docker: 63,
          AWS: 53,
          Linux: 58,
          Kubernetes: 50,
          Python: 50,
        },
        {
          timestamp: 1630548004812,
          Docker: 63,
          AWS: 53,
          Linux: 57,
          Kubernetes: 48,
          Python: 48,
        },
        {
          timestamp: 1631325603711,
          Docker: 63,
          AWS: 51,
          Linux: 55,
          Kubernetes: 51,
          Python: 45,
        },
        {
          timestamp: 1632189604607,
          Docker: 64,
          AWS: 57,
          Linux: 57,
          Kubernetes: 53,
          Python: 47,
        },
        {
          timestamp: 1632794406223,
          Docker: 67,
          AWS: 57,
          Linux: 55,
          Kubernetes: 52,
          Python: 48,
        },
        {
          timestamp: 1633744805168,
          Docker: 62,
          AWS: 56,
          Linux: 54,
          Kubernetes: 53,
          Python: 48,
        },
        {
          timestamp: 1634004004078,
          Docker: 63,
          AWS: 57,
          Linux: 53,
          Kubernetes: 52,
          Python: 49,
        },
        {
          timestamp: 1634176805748,
          Docker: 63,
          AWS: 59,
          Linux: 55,
          Kubernetes: 52,
          Python: 50,
        },
      ],
      skillNames: ['Docker', 'AWS', 'Linux', 'Kubernetes', 'Python'],
      currentTopSkills: [
        { name: 'Docker', popularity: 63 },
        { name: 'AWS', popularity: 59 },
        { name: 'Linux', popularity: 55 },
        { name: 'Kubernetes', popularity: 52 },
        { name: 'Python', popularity: 50 },
      ],
    },
    {
      category: 'QA/Testing',
      trends: [
        {
          timestamp: 1619834406876,
          Selenium: 54,
          JIRA: 44,
          Java: 38,
          SQL: 42,
          Python: 29,
        },
        {
          timestamp: 1620093609776,
          Selenium: 55,
          JIRA: 44,
          Java: 39,
          SQL: 43,
          Python: 29,
        },
        {
          timestamp: 1620266406462,
          Selenium: 56,
          JIRA: 43,
          Java: 39,
          SQL: 43,
          Python: 30,
        },
        {
          timestamp: 1620439211311,
          Selenium: 54,
          JIRA: 42,
          Java: 37,
          SQL: 41,
          Python: 29,
        },
        {
          timestamp: 1620698413947,
          Selenium: 54,
          JIRA: 40,
          Java: 37,
          SQL: 42,
          Python: 29,
        },
        {
          timestamp: 1620871204757,
          Selenium: 53,
          JIRA: 40,
          Java: 38,
          SQL: 44,
          Python: 29,
        },
        {
          timestamp: 1621044007710,
          Selenium: 53,
          JIRA: 41,
          Java: 39,
          SQL: 43,
          Python: 31,
        },
        {
          timestamp: 1621303206821,
          Selenium: 53,
          JIRA: 42,
          Java: 39,
          SQL: 45,
          Python: 31,
        },
        {
          timestamp: 1621476006839,
          Selenium: 52,
          JIRA: 42,
          Java: 39,
          SQL: 44,
          Python: 30,
        },
        {
          timestamp: 1621648807626,
          Selenium: 50,
          JIRA: 44,
          Java: 41,
          SQL: 41,
          Python: 30,
        },
        {
          timestamp: 1621908006917,
          Selenium: 51,
          JIRA: 44,
          Java: 41,
          SQL: 42,
          Python: 28,
        },
        {
          timestamp: 1622080808739,
          Selenium: 48,
          JIRA: 43,
          Java: 41,
          SQL: 43,
          Python: 29,
        },
        {
          timestamp: 1622253604989,
          Selenium: 49,
          JIRA: 42,
          Java: 43,
          SQL: 44,
          Python: 25,
        },
        {
          timestamp: 1622512806118,
          Selenium: 49,
          JIRA: 42,
          Java: 44,
          SQL: 43,
          Python: 25,
        },
        {
          timestamp: 1622685606731,
          Selenium: 49,
          JIRA: 41,
          Java: 41,
          SQL: 43,
          Python: 25,
        },
        {
          timestamp: 1622858409790,
          Selenium: 49,
          JIRA: 41,
          Java: 42,
          SQL: 42,
          Python: 25,
        },
        {
          timestamp: 1623117606258,
          Selenium: 51,
          JIRA: 40,
          Java: 43,
          SQL: 43,
          Python: 23,
        },
        {
          timestamp: 1623463205279,
          Selenium: 51,
          JIRA: 41,
          Java: 42,
          SQL: 42,
          Python: 22,
        },
        {
          timestamp: 1623722404984,
          Selenium: 51,
          JIRA: 39,
          Java: 39,
          SQL: 41,
          Python: 22,
        },
        {
          timestamp: 1624068007484,
          Selenium: 51,
          JIRA: 38,
          Java: 39,
          SQL: 40,
          Python: 25,
        },
        {
          timestamp: 1624327208082,
          Selenium: 52,
          JIRA: 37,
          Java: 39,
          SQL: 44,
          Python: 27,
        },
        {
          timestamp: 1624672806043,
          Selenium: 53,
          JIRA: 39,
          Java: 42,
          SQL: 43,
          Python: 28,
        },
        {
          timestamp: 1624932007464,
          Selenium: 52,
          JIRA: 39,
          Java: 40,
          SQL: 41,
          Python: 29,
        },
        {
          timestamp: 1625104807609,
          Selenium: 55,
          JIRA: 41,
          Java: 40,
          SQL: 41,
          Python: 28,
        },
        {
          timestamp: 1625277611194,
          Selenium: 54,
          JIRA: 42,
          Java: 41,
          SQL: 42,
          Python: 28,
        },
        {
          timestamp: 1625536810671,
          Selenium: 55,
          JIRA: 44,
          Java: 41,
          SQL: 43,
          Python: 27,
        },
        {
          timestamp: 1625709606250,
          Selenium: 56,
          JIRA: 44,
          Java: 43,
          SQL: 44,
          Python: 26,
        },
        {
          timestamp: 1625882410283,
          Selenium: 58,
          JIRA: 43,
          Java: 43,
          SQL: 43,
          Python: 26,
        },
        {
          timestamp: 1626314409438,
          Selenium: 60,
          JIRA: 41,
          Java: 45,
          SQL: 42,
          Python: 24,
        },
        {
          timestamp: 1626919207357,
          Selenium: 58,
          JIRA: 39,
          Java: 41,
          SQL: 39,
          Python: 23,
        },
        {
          timestamp: 1627524005680,
          Selenium: 54,
          JIRA: 38,
          Java: 41,
          SQL: 38,
          Python: 22,
        },
        {
          timestamp: 1627696804982,
          Selenium: 53,
          JIRA: 35,
          Java: 43,
          SQL: 37,
          Python: 23,
        },
        {
          timestamp: 1627956006659,
          Selenium: 55,
          JIRA: 36,
          Java: 44,
          SQL: 36,
          Python: 22,
        },
        {
          timestamp: 1628128806058,
          Selenium: 56,
          JIRA: 34,
          Java: 42,
          SQL: 36,
          Python: 21,
        },
        {
          timestamp: 1628301604448,
          Selenium: 55,
          JIRA: 36,
          Java: 41,
          SQL: 35,
          Python: 21,
        },
        {
          timestamp: 1628560807508,
          Selenium: 51,
          JIRA: 39,
          Java: 40,
          SQL: 34,
          Python: 22,
        },
        {
          timestamp: 1628733605935,
          Selenium: 51,
          JIRA: 40,
          Java: 40,
          SQL: 33,
          Python: 24,
        },
        {
          timestamp: 1628906405405,
          Selenium: 52,
          JIRA: 40,
          Java: 39,
          SQL: 34,
          Python: 22,
        },
        {
          timestamp: 1629338405136,
          Selenium: 52,
          JIRA: 42,
          Java: 41,
          SQL: 34,
          Python: 23,
        },
        {
          timestamp: 1629511205084,
          Selenium: 54,
          JIRA: 42,
          Java: 39,
          SQL: 33,
          Python: 23,
        },
        {
          timestamp: 1629943206138,
          Selenium: 56,
          JIRA: 43,
          Java: 42,
          SQL: 37,
          Python: 24,
        },
        {
          timestamp: 1630375206475,
          Selenium: 53,
          JIRA: 42,
          Java: 41,
          SQL: 34,
          Python: 26,
        },
        {
          timestamp: 1630548004812,
          Selenium: 50,
          JIRA: 41,
          Java: 41,
          SQL: 33,
          Python: 25,
        },
        {
          timestamp: 1631325603711,
          Selenium: 48,
          JIRA: 42,
          Java: 38,
          SQL: 36,
          Python: 26,
        },
        {
          timestamp: 1632189604607,
          Selenium: 50,
          JIRA: 40,
          Java: 38,
          SQL: 35,
          Python: 26,
        },
        {
          timestamp: 1632794406223,
          Selenium: 49,
          JIRA: 41,
          Java: 35,
          SQL: 33,
          Python: 28,
        },
        {
          timestamp: 1633744805168,
          Selenium: 47,
          JIRA: 42,
          Java: 38,
          SQL: 35,
          Python: 28,
        },
        {
          timestamp: 1634004004078,
          Selenium: 46,
          JIRA: 42,
          Java: 39,
          SQL: 34,
          Python: 28,
        },
        {
          timestamp: 1634176805748,
          Selenium: 44,
          JIRA: 42,
          Java: 37,
          SQL: 35,
          Python: 28,
        },
      ],
      skillNames: ['Selenium', 'JIRA', 'Java', 'SQL', 'Python'],
      currentTopSkills: [
        { name: 'Selenium', popularity: 44 },
        { name: 'JIRA', popularity: 42 },
        { name: 'Java', popularity: 37 },
        { name: 'SQL', popularity: 35 },
        { name: 'Python', popularity: 28 },
      ],
    },
    {
      category: 'Fullstack',
      trends: [
        {
          timestamp: 1619834406876,
          JavaScript: 66,
          React: 43,
          Angular: 38,
          Git: 41,
          Java: 31,
        },
        {
          timestamp: 1620093609776,
          JavaScript: 65,
          React: 43,
          Angular: 39,
          Git: 40,
          Java: 31,
        },
        {
          timestamp: 1620266406462,
          JavaScript: 63,
          React: 43,
          Angular: 38,
          Git: 40,
          Java: 31,
        },
        {
          timestamp: 1620439211311,
          JavaScript: 62,
          React: 41,
          Angular: 38,
          Git: 39,
          Java: 31,
        },
        {
          timestamp: 1620698413947,
          JavaScript: 61,
          React: 39,
          Angular: 38,
          Git: 38,
          Java: 32,
        },
        {
          timestamp: 1620871204757,
          JavaScript: 60,
          React: 40,
          Angular: 37,
          Git: 40,
          Java: 31,
        },
        {
          timestamp: 1621044007710,
          JavaScript: 60,
          React: 39,
          Angular: 38,
          Git: 40,
          Java: 32,
        },
        {
          timestamp: 1621303206821,
          JavaScript: 59,
          React: 39,
          Angular: 38,
          Git: 40,
          Java: 32,
        },
        {
          timestamp: 1621476006839,
          JavaScript: 59,
          React: 38,
          Angular: 39,
          Git: 39,
          Java: 32,
        },
        {
          timestamp: 1621648807626,
          JavaScript: 58,
          React: 38,
          Angular: 38,
          Git: 39,
          Java: 33,
        },
        {
          timestamp: 1621908006917,
          JavaScript: 57,
          React: 38,
          Angular: 38,
          Git: 38,
          Java: 32,
        },
        {
          timestamp: 1622080808739,
          JavaScript: 57,
          React: 38,
          Angular: 39,
          Git: 38,
          Java: 32,
        },
        {
          timestamp: 1622253604989,
          JavaScript: 57,
          React: 38,
          Angular: 39,
          Git: 37,
          Java: 32,
        },
        {
          timestamp: 1622512806118,
          JavaScript: 57,
          React: 37,
          Angular: 38,
          Git: 38,
          Java: 30,
        },
        {
          timestamp: 1622685606731,
          JavaScript: 57,
          React: 38,
          Angular: 39,
          Git: 38,
          Java: 30,
        },
        {
          timestamp: 1622858409790,
          JavaScript: 59,
          React: 38,
          Angular: 39,
          Git: 38,
          Java: 30,
        },
        {
          timestamp: 1623117606258,
          JavaScript: 59,
          React: 38,
          Angular: 40,
          Git: 39,
          Java: 30,
        },
        {
          timestamp: 1623463205279,
          JavaScript: 61,
          React: 39,
          Angular: 41,
          Git: 37,
          Java: 29,
        },
        {
          timestamp: 1623722404984,
          JavaScript: 60,
          React: 40,
          Angular: 39,
          Git: 39,
          Java: 28,
        },
        {
          timestamp: 1624068007484,
          JavaScript: 60,
          React: 42,
          Angular: 37,
          Git: 38,
          Java: 27,
        },
        {
          timestamp: 1624327208082,
          JavaScript: 60,
          React: 42,
          Angular: 38,
          Git: 38,
          Java: 26,
        },
        {
          timestamp: 1624672806043,
          JavaScript: 61,
          React: 42,
          Angular: 36,
          Git: 39,
          Java: 28,
        },
        {
          timestamp: 1624932007464,
          JavaScript: 61,
          React: 41,
          Angular: 36,
          Git: 38,
          Java: 28,
        },
        {
          timestamp: 1625104807609,
          JavaScript: 62,
          React: 41,
          Angular: 36,
          Git: 38,
          Java: 27,
        },
        {
          timestamp: 1625277611194,
          JavaScript: 62,
          React: 41,
          Angular: 36,
          Git: 38,
          Java: 27,
        },
        {
          timestamp: 1625536810671,
          JavaScript: 63,
          React: 41,
          Angular: 36,
          Git: 39,
          Java: 26,
        },
        {
          timestamp: 1625709606250,
          JavaScript: 64,
          React: 41,
          Angular: 36,
          Git: 38,
          Java: 27,
        },
        {
          timestamp: 1625882410283,
          JavaScript: 63,
          React: 41,
          Angular: 35,
          Git: 39,
          Java: 26,
        },
        {
          timestamp: 1626314409438,
          JavaScript: 64,
          React: 40,
          Angular: 36,
          Git: 38,
          Java: 28,
        },
        {
          timestamp: 1626919207357,
          JavaScript: 63,
          React: 40,
          Angular: 35,
          Git: 39,
          Java: 30,
        },
        {
          timestamp: 1627524005680,
          JavaScript: 61,
          React: 40,
          Angular: 35,
          Git: 40,
          Java: 31,
        },
        {
          timestamp: 1627696804982,
          JavaScript: 62,
          React: 41,
          Angular: 35,
          Git: 41,
          Java: 31,
        },
        {
          timestamp: 1627956006659,
          JavaScript: 62,
          React: 41,
          Angular: 36,
          Git: 40,
          Java: 32,
        },
        {
          timestamp: 1628128806058,
          JavaScript: 61,
          React: 41,
          Angular: 35,
          Git: 40,
          Java: 32,
        },
        {
          timestamp: 1628301604448,
          JavaScript: 61,
          React: 40,
          Angular: 35,
          Git: 39,
          Java: 31,
        },
        {
          timestamp: 1628560807508,
          JavaScript: 60,
          React: 41,
          Angular: 36,
          Git: 39,
          Java: 34,
        },
        {
          timestamp: 1628733605935,
          JavaScript: 61,
          React: 41,
          Angular: 35,
          Git: 40,
          Java: 36,
        },
        {
          timestamp: 1628906405405,
          JavaScript: 61,
          React: 41,
          Angular: 35,
          Git: 40,
          Java: 34,
        },
        {
          timestamp: 1629338405136,
          JavaScript: 60,
          React: 42,
          Angular: 33,
          Git: 39,
          Java: 33,
        },
        {
          timestamp: 1629511205084,
          JavaScript: 59,
          React: 43,
          Angular: 33,
          Git: 38,
          Java: 32,
        },
        {
          timestamp: 1629943206138,
          JavaScript: 56,
          React: 44,
          Angular: 32,
          Git: 40,
          Java: 32,
        },
        {
          timestamp: 1630375206475,
          JavaScript: 54,
          React: 44,
          Angular: 32,
          Git: 38,
          Java: 32,
        },
        {
          timestamp: 1630548004812,
          JavaScript: 55,
          React: 46,
          Angular: 32,
          Git: 39,
          Java: 33,
        },
        {
          timestamp: 1631325603711,
          JavaScript: 56,
          React: 46,
          Angular: 31,
          Git: 36,
          Java: 32,
        },
        {
          timestamp: 1632189604607,
          JavaScript: 57,
          React: 44,
          Angular: 34,
          Git: 37,
          Java: 34,
        },
        {
          timestamp: 1632794406223,
          JavaScript: 57,
          React: 48,
          Angular: 36,
          Git: 36,
          Java: 34,
        },
        {
          timestamp: 1633744805168,
          JavaScript: 56,
          React: 44,
          Angular: 38,
          Git: 35,
          Java: 34,
        },
        {
          timestamp: 1634004004078,
          JavaScript: 56,
          React: 46,
          Angular: 37,
          Git: 34,
          Java: 34,
        },
        {
          timestamp: 1634176805748,
          JavaScript: 55,
          React: 46,
          Angular: 38,
          Git: 35,
          Java: 35,
        },
      ],
      skillNames: ['JavaScript', 'React', 'Angular', 'Git', 'Java'],
      currentTopSkills: [
        { name: 'JavaScript', popularity: 55 },
        { name: 'React', popularity: 46 },
        { name: 'Angular', popularity: 38 },
        { name: 'Git', popularity: 35 },
        { name: 'Java', popularity: 35 },
      ],
    },
    {
      category: 'Frontend',
      trends: [
        {
          timestamp: 1619834406876,
          JavaScript: 84,
          React: 65,
          TypeScript: 62,
          CSS: 58,
          HTML: 55,
        },
        {
          timestamp: 1620093609776,
          JavaScript: 84,
          React: 65,
          TypeScript: 62,
          CSS: 58,
          HTML: 55,
        },
        {
          timestamp: 1620266406462,
          JavaScript: 84,
          React: 66,
          TypeScript: 61,
          CSS: 58,
          HTML: 55,
        },
        {
          timestamp: 1620439211311,
          JavaScript: 83,
          React: 67,
          TypeScript: 61,
          CSS: 57,
          HTML: 54,
        },
        {
          timestamp: 1620698413947,
          JavaScript: 83,
          React: 67,
          TypeScript: 62,
          CSS: 56,
          HTML: 53,
        },
        {
          timestamp: 1620871204757,
          JavaScript: 84,
          React: 68,
          TypeScript: 64,
          CSS: 56,
          HTML: 54,
        },
        {
          timestamp: 1621044007710,
          JavaScript: 84,
          React: 69,
          TypeScript: 63,
          CSS: 56,
          HTML: 53,
        },
        {
          timestamp: 1621303206821,
          JavaScript: 83,
          React: 70,
          TypeScript: 63,
          CSS: 56,
          HTML: 54,
        },
        {
          timestamp: 1621476006839,
          JavaScript: 83,
          React: 70,
          TypeScript: 62,
          CSS: 57,
          HTML: 54,
        },
        {
          timestamp: 1621648807626,
          JavaScript: 84,
          React: 69,
          TypeScript: 62,
          CSS: 59,
          HTML: 56,
        },
        {
          timestamp: 1621908006917,
          JavaScript: 84,
          React: 69,
          TypeScript: 62,
          CSS: 59,
          HTML: 56,
        },
        {
          timestamp: 1622080808739,
          JavaScript: 85,
          React: 69,
          TypeScript: 63,
          CSS: 60,
          HTML: 57,
        },
        {
          timestamp: 1622253604989,
          JavaScript: 85,
          React: 69,
          TypeScript: 60,
          CSS: 61,
          HTML: 57,
        },
        {
          timestamp: 1622512806118,
          JavaScript: 85,
          React: 69,
          TypeScript: 61,
          CSS: 61,
          HTML: 58,
        },
        {
          timestamp: 1622685606731,
          JavaScript: 86,
          React: 69,
          TypeScript: 61,
          CSS: 62,
          HTML: 59,
        },
        {
          timestamp: 1622858409790,
          JavaScript: 86,
          React: 68,
          TypeScript: 63,
          CSS: 62,
          HTML: 60,
        },
        {
          timestamp: 1623117606258,
          JavaScript: 87,
          React: 69,
          TypeScript: 64,
          CSS: 62,
          HTML: 60,
        },
        {
          timestamp: 1623463205279,
          JavaScript: 86,
          React: 66,
          TypeScript: 62,
          CSS: 63,
          HTML: 60,
        },
        {
          timestamp: 1623722404984,
          JavaScript: 87,
          React: 66,
          TypeScript: 63,
          CSS: 60,
          HTML: 58,
        },
        {
          timestamp: 1624068007484,
          JavaScript: 86,
          React: 63,
          TypeScript: 61,
          CSS: 60,
          HTML: 58,
        },
        {
          timestamp: 1624327208082,
          JavaScript: 86,
          React: 64,
          TypeScript: 60,
          CSS: 59,
          HTML: 58,
        },
        {
          timestamp: 1624672806043,
          JavaScript: 85,
          React: 63,
          TypeScript: 61,
          CSS: 60,
          HTML: 58,
        },
        {
          timestamp: 1624932007464,
          JavaScript: 85,
          React: 61,
          TypeScript: 63,
          CSS: 59,
          HTML: 57,
        },
        {
          timestamp: 1625104807609,
          JavaScript: 86,
          React: 62,
          TypeScript: 62,
          CSS: 59,
          HTML: 57,
        },
        {
          timestamp: 1625277611194,
          JavaScript: 86,
          React: 63,
          TypeScript: 62,
          CSS: 58,
          HTML: 57,
        },
        {
          timestamp: 1625536810671,
          JavaScript: 86,
          React: 63,
          TypeScript: 61,
          CSS: 58,
          HTML: 56,
        },
        {
          timestamp: 1625709606250,
          JavaScript: 85,
          React: 62,
          TypeScript: 60,
          CSS: 59,
          HTML: 57,
        },
        {
          timestamp: 1625882410283,
          JavaScript: 84,
          React: 62,
          TypeScript: 61,
          CSS: 59,
          HTML: 57,
        },
        {
          timestamp: 1626314409438,
          JavaScript: 85,
          React: 63,
          TypeScript: 60,
          CSS: 60,
          HTML: 58,
        },
        {
          timestamp: 1626919207357,
          JavaScript: 86,
          React: 63,
          TypeScript: 61,
          CSS: 58,
          HTML: 55,
        },
        {
          timestamp: 1627524005680,
          JavaScript: 86,
          React: 61,
          TypeScript: 58,
          CSS: 57,
          HTML: 56,
        },
        {
          timestamp: 1627696804982,
          JavaScript: 85,
          React: 60,
          TypeScript: 58,
          CSS: 56,
          HTML: 54,
        },
        {
          timestamp: 1627956006659,
          JavaScript: 84,
          React: 60,
          TypeScript: 59,
          CSS: 55,
          HTML: 54,
        },
        {
          timestamp: 1628128806058,
          JavaScript: 85,
          React: 61,
          TypeScript: 60,
          CSS: 57,
          HTML: 56,
        },
        {
          timestamp: 1628301604448,
          JavaScript: 85,
          React: 61,
          TypeScript: 60,
          CSS: 56,
          HTML: 54,
        },
        {
          timestamp: 1628560807508,
          JavaScript: 87,
          React: 62,
          TypeScript: 60,
          CSS: 59,
          HTML: 57,
        },
        {
          timestamp: 1628733605935,
          JavaScript: 88,
          React: 62,
          TypeScript: 62,
          CSS: 59,
          HTML: 57,
        },
        {
          timestamp: 1628906405405,
          JavaScript: 87,
          React: 61,
          TypeScript: 62,
          CSS: 59,
          HTML: 58,
        },
        {
          timestamp: 1629338405136,
          JavaScript: 87,
          React: 61,
          TypeScript: 62,
          CSS: 59,
          HTML: 58,
        },
        {
          timestamp: 1629511205084,
          JavaScript: 87,
          React: 63,
          TypeScript: 63,
          CSS: 60,
          HTML: 58,
        },
        {
          timestamp: 1629943206138,
          JavaScript: 85,
          React: 65,
          TypeScript: 65,
          CSS: 58,
          HTML: 55,
        },
        {
          timestamp: 1630375206475,
          JavaScript: 87,
          React: 67,
          TypeScript: 66,
          CSS: 57,
          HTML: 54,
        },
        {
          timestamp: 1630548004812,
          JavaScript: 86,
          React: 66,
          TypeScript: 65,
          CSS: 58,
          HTML: 54,
        },
        {
          timestamp: 1631325603711,
          JavaScript: 84,
          React: 65,
          TypeScript: 62,
          CSS: 54,
          HTML: 54,
        },
        {
          timestamp: 1632189604607,
          JavaScript: 83,
          React: 67,
          TypeScript: 61,
          CSS: 57,
          HTML: 55,
        },
        {
          timestamp: 1632794406223,
          JavaScript: 83,
          React: 68,
          TypeScript: 61,
          CSS: 58,
          HTML: 57,
        },
        {
          timestamp: 1633744805168,
          JavaScript: 82,
          React: 69,
          TypeScript: 64,
          CSS: 60,
          HTML: 57,
        },
        {
          timestamp: 1634004004078,
          JavaScript: 82,
          React: 70,
          TypeScript: 64,
          CSS: 59,
          HTML: 57,
        },
        {
          timestamp: 1634176805748,
          JavaScript: 81,
          React: 68,
          TypeScript: 64,
          CSS: 59,
          HTML: 56,
        },
      ],
      skillNames: ['JavaScript', 'React', 'TypeScript', 'CSS', 'HTML'],
      currentTopSkills: [
        { name: 'JavaScript', popularity: 81 },
        { name: 'React', popularity: 68 },
        { name: 'TypeScript', popularity: 64 },
        { name: 'CSS', popularity: 59 },
        { name: 'HTML', popularity: 56 },
      ],
    },
    {
      category: 'Support',
      trends: [
        {
          timestamp: 1619834406876,
          SQL: 35,
          Linux: 26,
          Windows: 36,
          ActiveDirectory: 18,
          Microsoft: 25,
        },
        {
          timestamp: 1620093609776,
          SQL: 36,
          Linux: 26,
          Windows: 38,
          ActiveDirectory: 19,
          Microsoft: 26,
        },
        {
          timestamp: 1620266406462,
          SQL: 38,
          Linux: 28,
          Windows: 38,
          ActiveDirectory: 19,
          Microsoft: 25,
        },
        {
          timestamp: 1620439211311,
          SQL: 38,
          Linux: 28,
          Windows: 39,
          ActiveDirectory: 20,
          Microsoft: 24,
        },
        {
          timestamp: 1620698413947,
          SQL: 37,
          Linux: 27,
          Windows: 37,
          ActiveDirectory: 19,
          Microsoft: 22,
        },
        {
          timestamp: 1620871204757,
          SQL: 40,
          Linux: 28,
          Windows: 35,
          ActiveDirectory: 18,
          Microsoft: 22,
        },
        {
          timestamp: 1621044007710,
          SQL: 36,
          Linux: 28,
          Windows: 37,
          ActiveDirectory: 20,
          Microsoft: 21,
        },
        {
          timestamp: 1621303206821,
          SQL: 38,
          Linux: 33,
          Windows: 32,
          ActiveDirectory: 19,
          Microsoft: 14,
        },
        {
          timestamp: 1621476006839,
          SQL: 38,
          Linux: 32,
          Windows: 30,
          ActiveDirectory: 19,
          Microsoft: 14,
        },
        {
          timestamp: 1621648807626,
          SQL: 40,
          Linux: 35,
          Windows: 31,
          ActiveDirectory: 18,
          Microsoft: 12,
        },
        {
          timestamp: 1621908006917,
          SQL: 41,
          Linux: 35,
          Windows: 29,
          ActiveDirectory: 17,
          Microsoft: 11,
        },
        {
          timestamp: 1622080808739,
          SQL: 41,
          Linux: 32,
          Windows: 31,
          ActiveDirectory: 19,
          Microsoft: 12,
        },
        {
          timestamp: 1622253604989,
          SQL: 42,
          Linux: 28,
          Windows: 31,
          ActiveDirectory: 16,
          Microsoft: 10,
        },
        {
          timestamp: 1622512806118,
          SQL: 44,
          Linux: 29,
          Windows: 32,
          ActiveDirectory: 15,
          Microsoft: 11,
        },
        {
          timestamp: 1622685606731,
          SQL: 43,
          Linux: 28,
          Windows: 31,
          ActiveDirectory: 18,
          Microsoft: 13,
        },
        {
          timestamp: 1622858409790,
          SQL: 41,
          Linux: 26,
          Windows: 28,
          ActiveDirectory: 16,
          Microsoft: 12,
        },
        {
          timestamp: 1623117606258,
          SQL: 38,
          Linux: 25,
          Windows: 26,
          ActiveDirectory: 17,
          Microsoft: 12,
        },
        {
          timestamp: 1623463205279,
          SQL: 34,
          Linux: 24,
          Windows: 26,
          ActiveDirectory: 15,
          Microsoft: 12,
        },
        {
          timestamp: 1623722404984,
          SQL: 35,
          Linux: 26,
          Windows: 26,
          ActiveDirectory: 14,
          Microsoft: 14,
        },
        {
          timestamp: 1624068007484,
          SQL: 30,
          Linux: 26,
          Windows: 31,
          ActiveDirectory: 14,
          Microsoft: 14,
        },
        {
          timestamp: 1624327208082,
          SQL: 28,
          Linux: 26,
          Windows: 30,
          ActiveDirectory: 17,
          Microsoft: 16,
        },
        {
          timestamp: 1624672806043,
          SQL: 29,
          Linux: 32,
          Windows: 32,
          ActiveDirectory: 18,
          Microsoft: 18,
        },
        {
          timestamp: 1624932007464,
          SQL: 33,
          Linux: 33,
          Windows: 32,
          ActiveDirectory: 16,
          Microsoft: 20,
        },
        {
          timestamp: 1625104807609,
          SQL: 34,
          Linux: 38,
          Windows: 33,
          ActiveDirectory: 19,
          Microsoft: 20,
        },
        {
          timestamp: 1625277611194,
          SQL: 36,
          Linux: 37,
          Windows: 32,
          ActiveDirectory: 19,
          Microsoft: 19,
        },
        {
          timestamp: 1625536810671,
          SQL: 37,
          Linux: 39,
          Windows: 30,
          ActiveDirectory: 20,
          Microsoft: 20,
        },
        {
          timestamp: 1625709606250,
          SQL: 37,
          Linux: 41,
          Windows: 31,
          ActiveDirectory: 21,
          Microsoft: 20,
        },
        {
          timestamp: 1625882410283,
          SQL: 39,
          Linux: 43,
          Windows: 32,
          ActiveDirectory: 22,
          Microsoft: 19,
        },
        {
          timestamp: 1626314409438,
          SQL: 36,
          Linux: 39,
          Windows: 30,
          ActiveDirectory: 22,
          Microsoft: 22,
        },
        {
          timestamp: 1626919207357,
          SQL: 37,
          Linux: 32,
          Windows: 27,
          ActiveDirectory: 19,
          Microsoft: 19,
        },
        {
          timestamp: 1627524005680,
          SQL: 35,
          Linux: 29,
          Windows: 28,
          ActiveDirectory: 17,
          Microsoft: 14,
        },
        {
          timestamp: 1627696804982,
          SQL: 37,
          Linux: 27,
          Windows: 27,
          ActiveDirectory: 15,
          Microsoft: 13,
        },
        {
          timestamp: 1627956006659,
          SQL: 40,
          Linux: 26,
          Windows: 26,
          ActiveDirectory: 14,
          Microsoft: 14,
        },
        {
          timestamp: 1628128806058,
          SQL: 37,
          Linux: 27,
          Windows: 29,
          ActiveDirectory: 13,
          Microsoft: 14,
        },
        {
          timestamp: 1628301604448,
          SQL: 37,
          Linux: 27,
          Windows: 27,
          ActiveDirectory: 13,
          Microsoft: 13,
        },
        {
          timestamp: 1628560807508,
          SQL: 38,
          Linux: 30,
          Windows: 27,
          ActiveDirectory: 11,
          Microsoft: 13,
        },
        {
          timestamp: 1628733605935,
          SQL: 37,
          Linux: 29,
          Windows: 25,
          ActiveDirectory: 10,
          Microsoft: 14,
        },
        {
          timestamp: 1628906405405,
          SQL: 38,
          Linux: 28,
          Windows: 30,
          ActiveDirectory: 12,
          Microsoft: 13,
        },
        {
          timestamp: 1629338405136,
          SQL: 36,
          Linux: 30,
          Windows: 29,
          ActiveDirectory: 8.9,
          Microsoft: 14,
        },
        {
          timestamp: 1629511205084,
          SQL: 42,
          Linux: 32,
          Windows: 26,
          ActiveDirectory: 10,
          Microsoft: 16,
        },
        {
          timestamp: 1629943206138,
          SQL: 42,
          Linux: 27,
          Windows: 27,
          ActiveDirectory: 10,
          Microsoft: 19,
        },
        {
          timestamp: 1630375206475,
          SQL: 40,
          Linux: 27,
          Windows: 27,
          ActiveDirectory: 10,
          Microsoft: 17,
        },
        {
          timestamp: 1630548004812,
          SQL: 38,
          Linux: 26,
          Windows: 26,
          ActiveDirectory: 12,
          Microsoft: 16,
        },
        {
          timestamp: 1631325603711,
          SQL: 41,
          Linux: 33,
          Windows: 33,
          ActiveDirectory: 17,
          Microsoft: 13,
        },
        {
          timestamp: 1632189604607,
          SQL: 46,
          Linux: 33,
          Windows: 33,
          ActiveDirectory: 14,
          Microsoft: 16,
        },
        {
          timestamp: 1632794406223,
          SQL: 39,
          Linux: 39,
          Windows: 32,
          ActiveDirectory: 19,
          Microsoft: 18,
        },
        {
          timestamp: 1633744805168,
          SQL: 41,
          Linux: 37,
          Windows: 35,
          ActiveDirectory: 24,
          Microsoft: 21,
        },
        {
          timestamp: 1634004004078,
          SQL: 43,
          Linux: 32,
          Windows: 30,
          ActiveDirectory: 21,
          Microsoft: 21,
        },
        {
          timestamp: 1634176805748,
          SQL: 38,
          Linux: 29,
          Windows: 27,
          ActiveDirectory: 23,
          Microsoft: 18,
        },
      ],
      skillNames: ['SQL', 'Linux', 'Windows', 'Active Directory', 'Microsoft'],
      currentTopSkills: [
        { name: 'SQL', popularity: 38 },
        { name: 'Linux', popularity: 29 },
        { name: 'Windows', popularity: 27 },
        { name: 'Active Directory', popularity: 23 },
        { name: 'Microsoft', popularity: 18 },
      ],
    },
    {
      category: 'Backend',
      trends: [
        {
          timestamp: 1619834406876,
          Java: 43,
          Docker: 42,
          Git: 46,
          REST: 34,
          Spring: 28,
        },
        {
          timestamp: 1620093609776,
          Java: 41,
          Docker: 42,
          Git: 46,
          REST: 33,
          Spring: 28,
        },
        {
          timestamp: 1620266406462,
          Java: 41,
          Docker: 42,
          Git: 45,
          REST: 33,
          Spring: 28,
        },
        {
          timestamp: 1620439211311,
          Java: 39,
          Docker: 42,
          Git: 46,
          REST: 32,
          Spring: 27,
        },
        {
          timestamp: 1620698413947,
          Java: 39,
          Docker: 41,
          Git: 45,
          REST: 32,
          Spring: 26,
        },
        {
          timestamp: 1620871204757,
          Java: 38,
          Docker: 41,
          Git: 45,
          REST: 33,
          Spring: 26,
        },
        {
          timestamp: 1621044007710,
          Java: 39,
          Docker: 42,
          Git: 46,
          REST: 33,
          Spring: 27,
        },
        {
          timestamp: 1621303206821,
          Java: 39,
          Docker: 41,
          Git: 45,
          REST: 33,
          Spring: 26,
        },
        {
          timestamp: 1621476006839,
          Java: 40,
          Docker: 40,
          Git: 45,
          REST: 33,
          Spring: 26,
        },
        {
          timestamp: 1621648807626,
          Java: 40,
          Docker: 40,
          Git: 45,
          REST: 31,
          Spring: 26,
        },
        {
          timestamp: 1621908006917,
          Java: 40,
          Docker: 40,
          Git: 44,
          REST: 31,
          Spring: 26,
        },
        {
          timestamp: 1622080808739,
          Java: 42,
          Docker: 40,
          Git: 44,
          REST: 32,
          Spring: 29,
        },
        {
          timestamp: 1622253604989,
          Java: 42,
          Docker: 39,
          Git: 44,
          REST: 32,
          Spring: 29,
        },
        {
          timestamp: 1622512806118,
          Java: 42,
          Docker: 39,
          Git: 44,
          REST: 33,
          Spring: 30,
        },
        {
          timestamp: 1622685606731,
          Java: 41,
          Docker: 39,
          Git: 43,
          REST: 32,
          Spring: 29,
        },
        {
          timestamp: 1622858409790,
          Java: 42,
          Docker: 39,
          Git: 43,
          REST: 33,
          Spring: 30,
        },
        {
          timestamp: 1623117606258,
          Java: 43,
          Docker: 40,
          Git: 44,
          REST: 33,
          Spring: 30,
        },
        {
          timestamp: 1623463205279,
          Java: 41,
          Docker: 41,
          Git: 44,
          REST: 32,
          Spring: 29,
        },
        {
          timestamp: 1623722404984,
          Java: 41,
          Docker: 41,
          Git: 43,
          REST: 31,
          Spring: 28,
        },
        {
          timestamp: 1624068007484,
          Java: 41,
          Docker: 43,
          Git: 45,
          REST: 31,
          Spring: 29,
        },
        {
          timestamp: 1624327208082,
          Java: 40,
          Docker: 43,
          Git: 44,
          REST: 32,
          Spring: 29,
        },
        {
          timestamp: 1624672806043,
          Java: 41,
          Docker: 43,
          Git: 44,
          REST: 31,
          Spring: 29,
        },
        {
          timestamp: 1624932007464,
          Java: 41,
          Docker: 44,
          Git: 45,
          REST: 31,
          Spring: 29,
        },
        {
          timestamp: 1625104807609,
          Java: 41,
          Docker: 44,
          Git: 45,
          REST: 31,
          Spring: 28,
        },
        {
          timestamp: 1625277611194,
          Java: 41,
          Docker: 45,
          Git: 45,
          REST: 30,
          Spring: 28,
        },
        {
          timestamp: 1625536810671,
          Java: 41,
          Docker: 44,
          Git: 46,
          REST: 31,
          Spring: 28,
        },
        {
          timestamp: 1625709606250,
          Java: 40,
          Docker: 44,
          Git: 45,
          REST: 31,
          Spring: 28,
        },
        {
          timestamp: 1625882410283,
          Java: 40,
          Docker: 45,
          Git: 45,
          REST: 32,
          Spring: 28,
        },
        {
          timestamp: 1626314409438,
          Java: 41,
          Docker: 44,
          Git: 45,
          REST: 32,
          Spring: 31,
        },
        {
          timestamp: 1626919207357,
          Java: 43,
          Docker: 42,
          Git: 45,
          REST: 33,
          Spring: 31,
        },
        {
          timestamp: 1627524005680,
          Java: 42,
          Docker: 41,
          Git: 44,
          REST: 33,
          Spring: 30,
        },
        {
          timestamp: 1627696804982,
          Java: 42,
          Docker: 41,
          Git: 44,
          REST: 33,
          Spring: 31,
        },
        {
          timestamp: 1627956006659,
          Java: 43,
          Docker: 41,
          Git: 45,
          REST: 34,
          Spring: 32,
        },
        {
          timestamp: 1628128806058,
          Java: 43,
          Docker: 42,
          Git: 44,
          REST: 33,
          Spring: 31,
        },
        {
          timestamp: 1628301604448,
          Java: 44,
          Docker: 42,
          Git: 42,
          REST: 33,
          Spring: 31,
        },
        {
          timestamp: 1628560807508,
          Java: 45,
          Docker: 42,
          Git: 42,
          REST: 34,
          Spring: 32,
        },
        {
          timestamp: 1628733605935,
          Java: 46,
          Docker: 42,
          Git: 43,
          REST: 34,
          Spring: 33,
        },
        {
          timestamp: 1628906405405,
          Java: 46,
          Docker: 42,
          Git: 43,
          REST: 33,
          Spring: 33,
        },
        {
          timestamp: 1629338405136,
          Java: 47,
          Docker: 42,
          Git: 42,
          REST: 33,
          Spring: 35,
        },
        {
          timestamp: 1629511205084,
          Java: 47,
          Docker: 42,
          Git: 41,
          REST: 32,
          Spring: 35,
        },
        {
          timestamp: 1629943206138,
          Java: 46,
          Docker: 42,
          Git: 41,
          REST: 32,
          Spring: 35,
        },
        {
          timestamp: 1630375206475,
          Java: 46,
          Docker: 41,
          Git: 40,
          REST: 31,
          Spring: 34,
        },
        {
          timestamp: 1630548004812,
          Java: 45,
          Docker: 41,
          Git: 41,
          REST: 32,
          Spring: 34,
        },
        {
          timestamp: 1631325603711,
          Java: 43,
          Docker: 41,
          Git: 40,
          REST: 33,
          Spring: 33,
        },
        {
          timestamp: 1632189604607,
          Java: 42,
          Docker: 41,
          Git: 41,
          REST: 34,
          Spring: 32,
        },
        {
          timestamp: 1632794406223,
          Java: 43,
          Docker: 42,
          Git: 42,
          REST: 35,
          Spring: 32,
        },
        {
          timestamp: 1633744805168,
          Java: 42,
          Docker: 43,
          Git: 41,
          REST: 35,
          Spring: 32,
        },
        {
          timestamp: 1634004004078,
          Java: 42,
          Docker: 42,
          Git: 41,
          REST: 34,
          Spring: 32,
        },
        {
          timestamp: 1634176805748,
          Java: 42,
          Docker: 42,
          Git: 40,
          REST: 33,
          Spring: 33,
        },
      ],
      skillNames: ['Java', 'Docker', 'Git', 'REST', 'Spring'],
      currentTopSkills: [
        { name: 'Java', popularity: 42 },
        { name: 'Docker', popularity: 42 },
        { name: 'Git', popularity: 40 },
        { name: 'REST', popularity: 33 },
        { name: 'Spring', popularity: 33 },
      ],
    },
    {
      category: 'Business Analytics',
      trends: [
        {
          timestamp: 1619834406876,
          Businessanalysis: 66,
          SQL: 56,
          UML: 46,
          JIRA: 39,
          BPMN: 41,
        },
        {
          timestamp: 1620093609776,
          Businessanalysis: 65,
          SQL: 57,
          UML: 48,
          JIRA: 37,
          BPMN: 43,
        },
        {
          timestamp: 1620266406462,
          Businessanalysis: 61,
          SQL: 60,
          UML: 48,
          JIRA: 38,
          BPMN: 41,
        },
        {
          timestamp: 1620439211311,
          Businessanalysis: 59,
          SQL: 59,
          UML: 48,
          JIRA: 40,
          BPMN: 41,
        },
        {
          timestamp: 1620698413947,
          Businessanalysis: 59,
          SQL: 57,
          UML: 46,
          JIRA: 41,
          BPMN: 40,
        },
        {
          timestamp: 1620871204757,
          Businessanalysis: 61,
          SQL: 54,
          UML: 46,
          JIRA: 43,
          BPMN: 43,
        },
        {
          timestamp: 1621044007710,
          Businessanalysis: 64,
          SQL: 53,
          UML: 45,
          JIRA: 44,
          BPMN: 43,
        },
        {
          timestamp: 1621303206821,
          Businessanalysis: 64,
          SQL: 54,
          UML: 44,
          JIRA: 46,
          BPMN: 42,
        },
        {
          timestamp: 1621476006839,
          Businessanalysis: 60,
          SQL: 56,
          UML: 46,
          JIRA: 48,
          BPMN: 44,
        },
        {
          timestamp: 1621648807626,
          Businessanalysis: 62,
          SQL: 55,
          UML: 48,
          JIRA: 55,
          BPMN: 45,
        },
        {
          timestamp: 1621908006917,
          Businessanalysis: 65,
          SQL: 58,
          UML: 48,
          JIRA: 56,
          BPMN: 45,
        },
        {
          timestamp: 1622080808739,
          Businessanalysis: 63,
          SQL: 59,
          UML: 46,
          JIRA: 53,
          BPMN: 44,
        },
        {
          timestamp: 1622253604989,
          Businessanalysis: 61,
          SQL: 57,
          UML: 46,
          JIRA: 49,
          BPMN: 41,
        },
        {
          timestamp: 1622512806118,
          Businessanalysis: 59,
          SQL: 59,
          UML: 45,
          JIRA: 53,
          BPMN: 41,
        },
        {
          timestamp: 1622685606731,
          Businessanalysis: 58,
          SQL: 58,
          UML: 46,
          JIRA: 49,
          BPMN: 43,
        },
        {
          timestamp: 1622858409790,
          Businessanalysis: 60,
          SQL: 58,
          UML: 45,
          JIRA: 48,
          BPMN: 43,
        },
        {
          timestamp: 1623117606258,
          Businessanalysis: 60,
          SQL: 55,
          UML: 43,
          JIRA: 45,
          BPMN: 42,
        },
        {
          timestamp: 1623463205279,
          Businessanalysis: 64,
          SQL: 53,
          UML: 44,
          JIRA: 48,
          BPMN: 43,
        },
        {
          timestamp: 1623722404984,
          Businessanalysis: 61,
          SQL: 52,
          UML: 45,
          JIRA: 48,
          BPMN: 42,
        },
        {
          timestamp: 1624068007484,
          Businessanalysis: 61,
          SQL: 48,
          UML: 41,
          JIRA: 43,
          BPMN: 36,
        },
        {
          timestamp: 1624327208082,
          Businessanalysis: 57,
          SQL: 50,
          UML: 43,
          JIRA: 42,
          BPMN: 39,
        },
        {
          timestamp: 1624672806043,
          Businessanalysis: 60,
          SQL: 51,
          UML: 47,
          JIRA: 40,
          BPMN: 41,
        },
        {
          timestamp: 1624932007464,
          Businessanalysis: 61,
          SQL: 54,
          UML: 43,
          JIRA: 39,
          BPMN: 39,
        },
        {
          timestamp: 1625104807609,
          Businessanalysis: 64,
          SQL: 51,
          UML: 42,
          JIRA: 38,
          BPMN: 38,
        },
        {
          timestamp: 1625277611194,
          Businessanalysis: 64,
          SQL: 52,
          UML: 41,
          JIRA: 40,
          BPMN: 37,
        },
        {
          timestamp: 1625536810671,
          Businessanalysis: 65,
          SQL: 52,
          UML: 44,
          JIRA: 41,
          BPMN: 39,
        },
        {
          timestamp: 1625709606250,
          Businessanalysis: 65,
          SQL: 56,
          UML: 43,
          JIRA: 39,
          BPMN: 38,
        },
        {
          timestamp: 1625882410283,
          Businessanalysis: 66,
          SQL: 55,
          UML: 45,
          JIRA: 40,
          BPMN: 42,
        },
        {
          timestamp: 1626314409438,
          Businessanalysis: 66,
          SQL: 54,
          UML: 38,
          JIRA: 40,
          BPMN: 36,
        },
        {
          timestamp: 1626919207357,
          Businessanalysis: 64,
          SQL: 50,
          UML: 41,
          JIRA: 43,
          BPMN: 37,
        },
        {
          timestamp: 1627524005680,
          Businessanalysis: 62,
          SQL: 50,
          UML: 37,
          JIRA: 41,
          BPMN: 34,
        },
        {
          timestamp: 1627696804982,
          Businessanalysis: 60,
          SQL: 52,
          UML: 37,
          JIRA: 42,
          BPMN: 34,
        },
        {
          timestamp: 1627956006659,
          Businessanalysis: 59,
          SQL: 52,
          UML: 36,
          JIRA: 41,
          BPMN: 33,
        },
        {
          timestamp: 1628128806058,
          Businessanalysis: 60,
          SQL: 50,
          UML: 35,
          JIRA: 43,
          BPMN: 33,
        },
        {
          timestamp: 1628301604448,
          Businessanalysis: 59,
          SQL: 53,
          UML: 36,
          JIRA: 42,
          BPMN: 34,
        },
        {
          timestamp: 1628560807508,
          Businessanalysis: 57,
          SQL: 56,
          UML: 35,
          JIRA: 39,
          BPMN: 32,
        },
        {
          timestamp: 1628733605935,
          Businessanalysis: 57,
          SQL: 55,
          UML: 35,
          JIRA: 43,
          BPMN: 32,
        },
        {
          timestamp: 1628906405405,
          Businessanalysis: 55,
          SQL: 53,
          UML: 39,
          JIRA: 43,
          BPMN: 35,
        },
        {
          timestamp: 1629338405136,
          Businessanalysis: 56,
          SQL: 51,
          UML: 36,
          JIRA: 41,
          BPMN: 35,
        },
        {
          timestamp: 1629511205084,
          Businessanalysis: 55,
          SQL: 49,
          UML: 33,
          JIRA: 40,
          BPMN: 32,
        },
        {
          timestamp: 1629943206138,
          Businessanalysis: 52,
          SQL: 54,
          UML: 32,
          JIRA: 34,
          BPMN: 30,
        },
        {
          timestamp: 1630375206475,
          Businessanalysis: 58,
          SQL: 53,
          UML: 34,
          JIRA: 34,
          BPMN: 33,
        },
        {
          timestamp: 1630548004812,
          Businessanalysis: 58,
          SQL: 53,
          UML: 33,
          JIRA: 35,
          BPMN: 33,
        },
        {
          timestamp: 1631325603711,
          Businessanalysis: 62,
          SQL: 58,
          UML: 35,
          JIRA: 33,
          BPMN: 34,
        },
        {
          timestamp: 1632189604607,
          Businessanalysis: 69,
          SQL: 63,
          UML: 31,
          JIRA: 33,
          BPMN: 28,
        },
        {
          timestamp: 1632794406223,
          Businessanalysis: 73,
          SQL: 61,
          UML: 36,
          JIRA: 35,
          BPMN: 30,
        },
        {
          timestamp: 1633744805168,
          Businessanalysis: 64,
          SQL: 56,
          UML: 41,
          JIRA: 38,
          BPMN: 33,
        },
        {
          timestamp: 1634004004078,
          Businessanalysis: 65,
          SQL: 55,
          UML: 41,
          JIRA: 37,
          BPMN: 35,
        },
        {
          timestamp: 1634176805748,
          Businessanalysis: 64,
          SQL: 51,
          UML: 40,
          JIRA: 35,
          BPMN: 34,
        },
      ],
      skillNames: ['Business analysis', 'SQL', 'UML', 'JIRA', 'BPMN'],
      currentTopSkills: [
        { name: 'Business analysis', popularity: 64 },
        { name: 'SQL', popularity: 51 },
        { name: 'UML', popularity: 40 },
        { name: 'JIRA', popularity: 35 },
        { name: 'BPMN', popularity: 34 },
      ],
    },
    {
      category: 'Artificial Intelligence',
      trends: [
        {
          timestamp: 1619834406876,
          Python: 83,
          MachineLearning: 83,
          Azure: 24,
          TensorFlow: 41,
          PyTorch: 28,
        },
        {
          timestamp: 1620093609776,
          Python: 81,
          MachineLearning: 81,
          Azure: 27,
          TensorFlow: 42,
          PyTorch: 27,
        },
        {
          timestamp: 1620266406462,
          Python: 90,
          MachineLearning: 83,
          Azure: 27,
          TensorFlow: 40,
          PyTorch: 33,
        },
        {
          timestamp: 1620439211311,
          Python: 90,
          MachineLearning: 84,
          Azure: 26,
          TensorFlow: 39,
          PyTorch: 35,
        },
        {
          timestamp: 1620698413947,
          Python: 86,
          MachineLearning: 86,
          Azure: 29,
          TensorFlow: 39,
          PyTorch: 36,
        },
        {
          timestamp: 1620871204757,
          Python: 85,
          MachineLearning: 89,
          Azure: 33,
          TensorFlow: 41,
          PyTorch: 33,
        },
        {
          timestamp: 1621044007710,
          Python: 86,
          MachineLearning: 89,
          Azure: 36,
          TensorFlow: 39,
          PyTorch: 32,
        },
        {
          timestamp: 1621303206821,
          Python: 93,
          MachineLearning: 93,
          Azure: 34,
          TensorFlow: 38,
          PyTorch: 31,
        },
        {
          timestamp: 1621476006839,
          Python: 93,
          MachineLearning: 93,
          Azure: 38,
          TensorFlow: 38,
          PyTorch: 31,
        },
        {
          timestamp: 1621648807626,
          Python: 93,
          MachineLearning: 93,
          Azure: 37,
          TensorFlow: 40,
          PyTorch: 27,
        },
        {
          timestamp: 1621908006917,
          Python: 93,
          MachineLearning: 93,
          Azure: 38,
          TensorFlow: 38,
          PyTorch: 28,
        },
        {
          timestamp: 1622080808739,
          Python: 91,
          MachineLearning: 88,
          Azure: 34,
          TensorFlow: 38,
          PyTorch: 25,
        },
        {
          timestamp: 1622253604989,
          Python: 90,
          MachineLearning: 87,
          Azure: 32,
          TensorFlow: 39,
          PyTorch: 26,
        },
        {
          timestamp: 1622512806118,
          Python: 90,
          MachineLearning: 86,
          Azure: 34,
          TensorFlow: 34,
          PyTorch: 28,
        },
        {
          timestamp: 1622685606731,
          Python: 87,
          MachineLearning: 87,
          Azure: 37,
          TensorFlow: 33,
          PyTorch: 27,
        },
        {
          timestamp: 1622858409790,
          Python: 78,
          MachineLearning: 85,
          Azure: 41,
          TensorFlow: 33,
          PyTorch: 19,
        },
        {
          timestamp: 1623117606258,
          Python: 77,
          MachineLearning: 88,
          Azure: 42,
          TensorFlow: 27,
          PyTorch: 12,
        },
        {
          timestamp: 1623463205279,
          Python: 76,
          MachineLearning: 79,
          Azure: 42,
          TensorFlow: 42,
          PyTorch: 12,
        },
        {
          timestamp: 1623722404984,
          Python: 79,
          MachineLearning: 76,
          Azure: 38,
          TensorFlow: 47,
          PyTorch: 21,
        },
        {
          timestamp: 1624068007484,
          Python: 80,
          MachineLearning: 71,
          Azure: 40,
          TensorFlow: 49,
          PyTorch: 20,
        },
        {
          timestamp: 1624327208082,
          Python: 81,
          MachineLearning: 72,
          Azure: 42,
          TensorFlow: 50,
          PyTorch: 19,
        },
        {
          timestamp: 1624672806043,
          Python: 86,
          MachineLearning: 74,
          Azure: 34,
          TensorFlow: 46,
          PyTorch: 17,
        },
        {
          timestamp: 1624932007464,
          Python: 83,
          MachineLearning: 71,
          Azure: 34,
          TensorFlow: 39,
          PyTorch: 15,
        },
        {
          timestamp: 1625104807609,
          Python: 83,
          MachineLearning: 71,
          Azure: 31,
          TensorFlow: 40,
          PyTorch: 14,
        },
        {
          timestamp: 1625277611194,
          Python: 82,
          MachineLearning: 73,
          Azure: 33,
          TensorFlow: 40,
          PyTorch: 16,
        },
        {
          timestamp: 1625536810671,
          Python: 82,
          MachineLearning: 73,
          Azure: 34,
          TensorFlow: 41,
          PyTorch: 16,
        },
        {
          timestamp: 1625709606250,
          Python: 82,
          MachineLearning: 73,
          Azure: 34,
          TensorFlow: 41,
          PyTorch: 16,
        },
        {
          timestamp: 1625882410283,
          Python: 81,
          MachineLearning: 71,
          Azure: 31,
          TensorFlow: 36,
          PyTorch: 19,
        },
        {
          timestamp: 1626314409438,
          Python: 83,
          MachineLearning: 74,
          Azure: 33,
          TensorFlow: 31,
          PyTorch: 14,
        },
        {
          timestamp: 1626919207357,
          Python: 82,
          MachineLearning: 68,
          Azure: 30,
          TensorFlow: 27,
          PyTorch: 16,
        },
        {
          timestamp: 1627524005680,
          Python: 82,
          MachineLearning: 71,
          Azure: 29,
          TensorFlow: 38,
          PyTorch: 22,
        },
        {
          timestamp: 1627696804982,
          Python: 80,
          MachineLearning: 68,
          Azure: 27,
          TensorFlow: 36,
          PyTorch: 20,
        },
        {
          timestamp: 1627956006659,
          Python: 83,
          MachineLearning: 66,
          Azure: 24,
          TensorFlow: 41,
          PyTorch: 22,
        },
        {
          timestamp: 1628128806058,
          Python: 83,
          MachineLearning: 68,
          Azure: 24,
          TensorFlow: 44,
          PyTorch: 22,
        },
        {
          timestamp: 1628301604448,
          Python: 79,
          MachineLearning: 64,
          Azure: 29,
          TensorFlow: 43,
          PyTorch: 21,
        },
        {
          timestamp: 1628560807508,
          Python: 75,
          MachineLearning: 68,
          Azure: 25,
          TensorFlow: 43,
          PyTorch: 25,
        },
        {
          timestamp: 1628733605935,
          Python: 76,
          MachineLearning: 73,
          Azure: 27,
          TensorFlow: 38,
          PyTorch: 27,
        },
        {
          timestamp: 1628906405405,
          Python: 76,
          MachineLearning: 70,
          Azure: 30,
          TensorFlow: 39,
          PyTorch: 27,
        },
        {
          timestamp: 1629338405136,
          Python: 73,
          MachineLearning: 64,
          Azure: 30,
          TensorFlow: 42,
          PyTorch: 21,
        },
        {
          timestamp: 1629511205084,
          Python: 76,
          MachineLearning: 66,
          Azure: 26,
          TensorFlow: 50,
          PyTorch: 26,
        },
        {
          timestamp: 1629943206138,
          Python: 77,
          MachineLearning: 65,
          Azure: 32,
          TensorFlow: 45,
          PyTorch: 29,
        },
        {
          timestamp: 1630375206475,
          Python: 83,
          MachineLearning: 72,
          Azure: 34,
          TensorFlow: 52,
          PyTorch: 31,
        },
        {
          timestamp: 1630548004812,
          Python: 81,
          MachineLearning: 74,
          Azure: 35,
          TensorFlow: 52,
          PyTorch: 35,
        },
        {
          timestamp: 1631325603711,
          Python: 82,
          MachineLearning: 73,
          Azure: 30,
          TensorFlow: 45,
          PyTorch: 24,
        },
        {
          timestamp: 1632189604607,
          Python: 82,
          MachineLearning: 82,
          Azure: 33,
          TensorFlow: 36,
          PyTorch: 18,
        },
        {
          timestamp: 1632794406223,
          Python: 84,
          MachineLearning: 78,
          Azure: 32,
          TensorFlow: 46,
          PyTorch: 24,
        },
        {
          timestamp: 1633744805168,
          Python: 90,
          MachineLearning: 77,
          Azure: 36,
          TensorFlow: 44,
          PyTorch: 33,
        },
        {
          timestamp: 1634004004078,
          Python: 89,
          MachineLearning: 74,
          Azure: 39,
          TensorFlow: 39,
          PyTorch: 34,
        },
        {
          timestamp: 1634176805748,
          Python: 84,
          MachineLearning: 70,
          Azure: 41,
          TensorFlow: 41,
          PyTorch: 35,
        },
      ],
      skillNames: ['Python', 'Machine Learning', 'Azure', 'TensorFlow', 'PyTorch'],
      currentTopSkills: [
        { name: 'Python', popularity: 84 },
        { name: 'Machine Learning', popularity: 70 },
        { name: 'Azure', popularity: 41 },
        { name: 'TensorFlow', popularity: 41 },
        { name: 'PyTorch', popularity: 35 },
      ],
    },
    {
      category: 'IT Administration',
      trends: [
        {
          timestamp: 1619834406876,
          Linux: 54,
          Windows: 38,
          Ansible: 15,
          Powershell: 15,
          'TCP/IP': 25,
        },
        {
          timestamp: 1620093609776,
          Linux: 55,
          Windows: 39,
          Ansible: 16,
          Powershell: 16,
          'TCP/IP': 24,
        },
        {
          timestamp: 1620266406462,
          Linux: 55,
          Windows: 39,
          Ansible: 15,
          Powershell: 15,
          'TCP/IP': 24,
        },
        {
          timestamp: 1620439211311,
          Linux: 52,
          Windows: 38,
          Ansible: 15,
          Powershell: 14,
          'TCP/IP': 23,
        },
        {
          timestamp: 1620698413947,
          Linux: 53,
          Windows: 40,
          Ansible: 15,
          Powershell: 13,
          'TCP/IP': 23,
        },
        {
          timestamp: 1620871204757,
          Linux: 55,
          Windows: 38,
          Ansible: 17,
          Powershell: 11,
          'TCP/IP': 23,
        },
        {
          timestamp: 1621044007710,
          Linux: 52,
          Windows: 41,
          Ansible: 14,
          Powershell: 11,
          'TCP/IP': 22,
        },
        {
          timestamp: 1621303206821,
          Linux: 51,
          Windows: 43,
          Ansible: 14,
          Powershell: 13,
          'TCP/IP': 23,
        },
        {
          timestamp: 1621476006839,
          Linux: 49,
          Windows: 42,
          Ansible: 14,
          Powershell: 14,
          'TCP/IP': 23,
        },
        {
          timestamp: 1621648807626,
          Linux: 51,
          Windows: 43,
          Ansible: 13,
          Powershell: 12,
          'TCP/IP': 24,
        },
        {
          timestamp: 1621908006917,
          Linux: 54,
          Windows: 42,
          Ansible: 14,
          Powershell: 13,
          'TCP/IP': 21,
        },
        {
          timestamp: 1622080808739,
          Linux: 54,
          Windows: 39,
          Ansible: 13,
          Powershell: 12,
          'TCP/IP': 25,
        },
        {
          timestamp: 1622253604989,
          Linux: 54,
          Windows: 38,
          Ansible: 13,
          Powershell: 13,
          'TCP/IP': 27,
        },
        {
          timestamp: 1622512806118,
          Linux: 49,
          Windows: 36,
          Ansible: 13,
          Powershell: 13,
          'TCP/IP': 25,
        },
        {
          timestamp: 1622685606731,
          Linux: 48,
          Windows: 38,
          Ansible: 11,
          Powershell: 13,
          'TCP/IP': 24,
        },
        {
          timestamp: 1622858409790,
          Linux: 49,
          Windows: 38,
          Ansible: 12,
          Powershell: 13,
          'TCP/IP': 25,
        },
        {
          timestamp: 1623117606258,
          Linux: 52,
          Windows: 43,
          Ansible: 12,
          Powershell: 14,
          'TCP/IP': 29,
        },
        {
          timestamp: 1623463205279,
          Linux: 53,
          Windows: 38,
          Ansible: 7.9,
          Powershell: 14,
          'TCP/IP': 29,
        },
        {
          timestamp: 1623722404984,
          Linux: 56,
          Windows: 36,
          Ansible: 11,
          Powershell: 14,
          'TCP/IP': 30,
        },
        {
          timestamp: 1624068007484,
          Linux: 56,
          Windows: 33,
          Ansible: 8.6,
          Powershell: 10,
          'TCP/IP': 31,
        },
        {
          timestamp: 1624327208082,
          Linux: 55,
          Windows: 37,
          Ansible: 9.2,
          Powershell: 11,
          'TCP/IP': 29,
        },
        {
          timestamp: 1624672806043,
          Linux: 62,
          Windows: 35,
          Ansible: 7.9,
          Powershell: 14,
          'TCP/IP': 29,
        },
        {
          timestamp: 1624932007464,
          Linux: 63,
          Windows: 39,
          Ansible: 6.3,
          Powershell: 14,
          'TCP/IP': 28,
        },
        {
          timestamp: 1625104807609,
          Linux: 62,
          Windows: 37,
          Ansible: 5.9,
          Powershell: 13,
          'TCP/IP': 26,
        },
        {
          timestamp: 1625277611194,
          Linux: 64,
          Windows: 34,
          Ansible: 6,
          Powershell: 12,
          'TCP/IP': 28,
        },
        {
          timestamp: 1625536810671,
          Linux: 63,
          Windows: 34,
          Ansible: 5.7,
          Powershell: 11,
          'TCP/IP': 29,
        },
        {
          timestamp: 1625709606250,
          Linux: 60,
          Windows: 33,
          Ansible: 9.9,
          Powershell: 14,
          'TCP/IP': 31,
        },
        {
          timestamp: 1625882410283,
          Linux: 59,
          Windows: 33,
          Ansible: 10,
          Powershell: 14,
          'TCP/IP': 29,
        },
        {
          timestamp: 1626314409438,
          Linux: 55,
          Windows: 31,
          Ansible: 8.5,
          Powershell: 15,
          'TCP/IP': 27,
        },
        {
          timestamp: 1626919207357,
          Linux: 60,
          Windows: 34,
          Ansible: 11,
          Powershell: 13,
          'TCP/IP': 26,
        },
        {
          timestamp: 1627524005680,
          Linux: 58,
          Windows: 29,
          Ansible: 13,
          Powershell: 10,
          'TCP/IP': 22,
        },
        {
          timestamp: 1627696804982,
          Linux: 56,
          Windows: 29,
          Ansible: 12,
          Powershell: 10,
          'TCP/IP': 22,
        },
        {
          timestamp: 1627956006659,
          Linux: 57,
          Windows: 28,
          Ansible: 13,
          Powershell: 11,
          'TCP/IP': 22,
        },
        {
          timestamp: 1628128806058,
          Linux: 60,
          Windows: 29,
          Ansible: 14,
          Powershell: 9.5,
          'TCP/IP': 23,
        },
        {
          timestamp: 1628301604448,
          Linux: 59,
          Windows: 27,
          Ansible: 15,
          Powershell: 8.5,
          'TCP/IP': 22,
        },
        {
          timestamp: 1628560807508,
          Linux: 63,
          Windows: 27,
          Ansible: 16,
          Powershell: 8.4,
          'TCP/IP': 23,
        },
        {
          timestamp: 1628733605935,
          Linux: 61,
          Windows: 27,
          Ansible: 16,
          Powershell: 8.2,
          'TCP/IP': 22,
        },
        {
          timestamp: 1628906405405,
          Linux: 62,
          Windows: 29,
          Ansible: 16,
          Powershell: 10,
          'TCP/IP': 22,
        },
        {
          timestamp: 1629338405136,
          Linux: 61,
          Windows: 25,
          Ansible: 15,
          Powershell: 11,
          'TCP/IP': 20,
        },
        {
          timestamp: 1629511205084,
          Linux: 57,
          Windows: 26,
          Ansible: 16,
          Powershell: 13,
          'TCP/IP': 18,
        },
        {
          timestamp: 1629943206138,
          Linux: 56,
          Windows: 30,
          Ansible: 16,
          Powershell: 16,
          'TCP/IP': 17,
        },
        {
          timestamp: 1630375206475,
          Linux: 51,
          Windows: 32,
          Ansible: 15,
          Powershell: 17,
          'TCP/IP': 19,
        },
        {
          timestamp: 1630548004812,
          Linux: 49,
          Windows: 34,
          Ansible: 14,
          Powershell: 18,
          'TCP/IP': 16,
        },
        {
          timestamp: 1631325603711,
          Linux: 54,
          Windows: 35,
          Ansible: 11,
          Powershell: 17,
          'TCP/IP': 15,
        },
        {
          timestamp: 1632189604607,
          Linux: 63,
          Windows: 31,
          Ansible: 13,
          Powershell: 15,
          'TCP/IP': 19,
        },
        {
          timestamp: 1632794406223,
          Linux: 68,
          Windows: 28,
          Ansible: 18,
          Powershell: 13,
          'TCP/IP': 22,
        },
        {
          timestamp: 1633744805168,
          Linux: 66,
          Windows: 27,
          Ansible: 20,
          Powershell: 17,
          'TCP/IP': 19,
        },
        {
          timestamp: 1634004004078,
          Linux: 71,
          Windows: 29,
          Ansible: 23,
          Powershell: 16,
          'TCP/IP': 19,
        },
        {
          timestamp: 1634176805748,
          Linux: 65,
          Windows: 29,
          Ansible: 21,
          Powershell: 17,
          'TCP/IP': 17,
        },
      ],
      skillNames: ['Linux', 'Windows', 'Ansible', 'Powershell', 'TCP/IP'],
      currentTopSkills: [
        { name: 'Linux', popularity: 65 },
        { name: 'Windows', popularity: 29 },
        { name: 'Ansible', popularity: 21 },
        { name: 'Powershell', popularity: 17 },
        { name: 'TCP/IP', popularity: 17 },
      ],
    },
    {
      category: 'Mobile',
      trends: [
        {
          timestamp: 1619834406876,
          Android: 61,
          iOS: 64,
          Git: 59,
          Kotlin: 40,
          Swift: 45,
        },
        {
          timestamp: 1620093609776,
          Android: 64,
          iOS: 64,
          Git: 57,
          Kotlin: 41,
          Swift: 45,
        },
        {
          timestamp: 1620266406462,
          Android: 66,
          iOS: 63,
          Git: 55,
          Kotlin: 44,
          Swift: 43,
        },
        {
          timestamp: 1620439211311,
          Android: 63,
          iOS: 66,
          Git: 55,
          Kotlin: 41,
          Swift: 45,
        },
        {
          timestamp: 1620698413947,
          Android: 62,
          iOS: 64,
          Git: 52,
          Kotlin: 41,
          Swift: 43,
        },
        {
          timestamp: 1620871204757,
          Android: 57,
          iOS: 68,
          Git: 52,
          Kotlin: 38,
          Swift: 46,
        },
        {
          timestamp: 1621044007710,
          Android: 60,
          iOS: 64,
          Git: 52,
          Kotlin: 40,
          Swift: 43,
        },
        {
          timestamp: 1621303206821,
          Android: 60,
          iOS: 65,
          Git: 52,
          Kotlin: 40,
          Swift: 42,
        },
        {
          timestamp: 1621476006839,
          Android: 59,
          iOS: 70,
          Git: 53,
          Kotlin: 38,
          Swift: 43,
        },
        {
          timestamp: 1621648807626,
          Android: 58,
          iOS: 73,
          Git: 55,
          Kotlin: 37,
          Swift: 45,
        },
        {
          timestamp: 1621908006917,
          Android: 58,
          iOS: 74,
          Git: 56,
          Kotlin: 39,
          Swift: 46,
        },
        {
          timestamp: 1622080808739,
          Android: 59,
          iOS: 75,
          Git: 55,
          Kotlin: 38,
          Swift: 45,
        },
        {
          timestamp: 1622253604989,
          Android: 58,
          iOS: 76,
          Git: 55,
          Kotlin: 36,
          Swift: 45,
        },
        {
          timestamp: 1622512806118,
          Android: 58,
          iOS: 75,
          Git: 52,
          Kotlin: 37,
          Swift: 45,
        },
        {
          timestamp: 1622685606731,
          Android: 59,
          iOS: 73,
          Git: 52,
          Kotlin: 39,
          Swift: 44,
        },
        {
          timestamp: 1622858409790,
          Android: 55,
          iOS: 75,
          Git: 50,
          Kotlin: 36,
          Swift: 46,
        },
        {
          timestamp: 1623117606258,
          Android: 55,
          iOS: 72,
          Git: 50,
          Kotlin: 37,
          Swift: 45,
        },
        {
          timestamp: 1623463205279,
          Android: 59,
          iOS: 74,
          Git: 51,
          Kotlin: 37,
          Swift: 48,
        },
        {
          timestamp: 1623722404984,
          Android: 58,
          iOS: 78,
          Git: 51,
          Kotlin: 36,
          Swift: 50,
        },
        {
          timestamp: 1624068007484,
          Android: 63,
          iOS: 69,
          Git: 51,
          Kotlin: 39,
          Swift: 48,
        },
        {
          timestamp: 1624327208082,
          Android: 62,
          iOS: 71,
          Git: 49,
          Kotlin: 39,
          Swift: 47,
        },
        {
          timestamp: 1624672806043,
          Android: 60,
          iOS: 68,
          Git: 48,
          Kotlin: 39,
          Swift: 47,
        },
        {
          timestamp: 1624932007464,
          Android: 63,
          iOS: 65,
          Git: 51,
          Kotlin: 41,
          Swift: 45,
        },
        {
          timestamp: 1625104807609,
          Android: 64,
          iOS: 64,
          Git: 53,
          Kotlin: 41,
          Swift: 43,
        },
        {
          timestamp: 1625277611194,
          Android: 61,
          iOS: 68,
          Git: 50,
          Kotlin: 38,
          Swift: 45,
        },
        {
          timestamp: 1625536810671,
          Android: 61,
          iOS: 68,
          Git: 53,
          Kotlin: 39,
          Swift: 45,
        },
        {
          timestamp: 1625709606250,
          Android: 63,
          iOS: 68,
          Git: 54,
          Kotlin: 41,
          Swift: 44,
        },
        {
          timestamp: 1625882410283,
          Android: 61,
          iOS: 69,
          Git: 52,
          Kotlin: 40,
          Swift: 42,
        },
        {
          timestamp: 1626314409438,
          Android: 62,
          iOS: 64,
          Git: 48,
          Kotlin: 41,
          Swift: 38,
        },
        {
          timestamp: 1626919207357,
          Android: 61,
          iOS: 61,
          Git: 45,
          Kotlin: 40,
          Swift: 36,
        },
        {
          timestamp: 1627524005680,
          Android: 65,
          iOS: 64,
          Git: 46,
          Kotlin: 42,
          Swift: 37,
        },
        {
          timestamp: 1627696804982,
          Android: 61,
          iOS: 64,
          Git: 47,
          Kotlin: 41,
          Swift: 39,
        },
        {
          timestamp: 1627956006659,
          Android: 63,
          iOS: 62,
          Git: 47,
          Kotlin: 42,
          Swift: 39,
        },
        {
          timestamp: 1628128806058,
          Android: 64,
          iOS: 59,
          Git: 45,
          Kotlin: 41,
          Swift: 37,
        },
        {
          timestamp: 1628301604448,
          Android: 61,
          iOS: 60,
          Git: 43,
          Kotlin: 40,
          Swift: 39,
        },
        {
          timestamp: 1628560807508,
          Android: 63,
          iOS: 60,
          Git: 46,
          Kotlin: 40,
          Swift: 39,
        },
        {
          timestamp: 1628733605935,
          Android: 62,
          iOS: 58,
          Git: 48,
          Kotlin: 40,
          Swift: 38,
        },
        {
          timestamp: 1628906405405,
          Android: 62,
          iOS: 59,
          Git: 51,
          Kotlin: 39,
          Swift: 38,
        },
        {
          timestamp: 1629338405136,
          Android: 63,
          iOS: 60,
          Git: 53,
          Kotlin: 39,
          Swift: 38,
        },
        {
          timestamp: 1629511205084,
          Android: 63,
          iOS: 64,
          Git: 48,
          Kotlin: 40,
          Swift: 41,
        },
        {
          timestamp: 1629943206138,
          Android: 63,
          iOS: 63,
          Git: 51,
          Kotlin: 41,
          Swift: 41,
        },
        {
          timestamp: 1630375206475,
          Android: 66,
          iOS: 63,
          Git: 53,
          Kotlin: 43,
          Swift: 40,
        },
        {
          timestamp: 1630548004812,
          Android: 66,
          iOS: 63,
          Git: 53,
          Kotlin: 44,
          Swift: 41,
        },
        {
          timestamp: 1631325603711,
          Android: 65,
          iOS: 62,
          Git: 56,
          Kotlin: 43,
          Swift: 42,
        },
        {
          timestamp: 1632189604607,
          Android: 65,
          iOS: 63,
          Git: 58,
          Kotlin: 43,
          Swift: 42,
        },
        {
          timestamp: 1632794406223,
          Android: 69,
          iOS: 61,
          Git: 53,
          Kotlin: 46,
          Swift: 36,
        },
        {
          timestamp: 1633744805168,
          Android: 69,
          iOS: 66,
          Git: 54,
          Kotlin: 45,
          Swift: 37,
        },
        {
          timestamp: 1634004004078,
          Android: 68,
          iOS: 68,
          Git: 53,
          Kotlin: 43,
          Swift: 36,
        },
        {
          timestamp: 1634176805748,
          Android: 70,
          iOS: 67,
          Git: 52,
          Kotlin: 45,
          Swift: 35,
        },
      ],
      skillNames: ['Android', 'iOS', 'Git', 'Kotlin', 'Swift'],
      currentTopSkills: [
        { name: 'Android', popularity: 70 },
        { name: 'iOS', popularity: 67 },
        { name: 'Git', popularity: 52 },
        { name: 'Kotlin', popularity: 45 },
        { name: 'Swift', popularity: 35 },
      ],
    },
    {
      category: 'businessIntelligence',
      trends: [
        {
          timestamp: 1619834406876,
          SQL: 70,
          ETL: 44,
          PowerBI: 37,
          Python: 37,
          Azure: 36,
        },
        {
          timestamp: 1620093609776,
          SQL: 68,
          ETL: 43,
          PowerBI: 38,
          Python: 40,
          Azure: 38,
        },
        {
          timestamp: 1620266406462,
          SQL: 69,
          ETL: 42,
          PowerBI: 38,
          Python: 39,
          Azure: 39,
        },
        {
          timestamp: 1620439211311,
          SQL: 73,
          ETL: 45,
          PowerBI: 38,
          Python: 38,
          Azure: 37,
        },
        {
          timestamp: 1620698413947,
          SQL: 68,
          ETL: 41,
          PowerBI: 38,
          Python: 38,
          Azure: 36,
        },
        {
          timestamp: 1620871204757,
          SQL: 69,
          ETL: 43,
          PowerBI: 39,
          Python: 40,
          Azure: 36,
        },
        {
          timestamp: 1621044007710,
          SQL: 73,
          ETL: 47,
          PowerBI: 39,
          Python: 41,
          Azure: 35,
        },
        {
          timestamp: 1621303206821,
          SQL: 74,
          ETL: 43,
          PowerBI: 35,
          Python: 42,
          Azure: 32,
        },
        {
          timestamp: 1621476006839,
          SQL: 73,
          ETL: 46,
          PowerBI: 35,
          Python: 41,
          Azure: 31,
        },
        {
          timestamp: 1621648807626,
          SQL: 76,
          ETL: 47,
          PowerBI: 37,
          Python: 43,
          Azure: 30,
        },
        {
          timestamp: 1621908006917,
          SQL: 75,
          ETL: 46,
          PowerBI: 38,
          Python: 41,
          Azure: 30,
        },
        {
          timestamp: 1622080808739,
          SQL: 76,
          ETL: 47,
          PowerBI: 39,
          Python: 39,
          Azure: 30,
        },
        {
          timestamp: 1622253604989,
          SQL: 77,
          ETL: 45,
          PowerBI: 39,
          Python: 37,
          Azure: 28,
        },
        {
          timestamp: 1622512806118,
          SQL: 75,
          ETL: 43,
          PowerBI: 35,
          Python: 39,
          Azure: 25,
        },
        {
          timestamp: 1622685606731,
          SQL: 72,
          ETL: 44,
          PowerBI: 35,
          Python: 39,
          Azure: 25,
        },
        {
          timestamp: 1622858409790,
          SQL: 70,
          ETL: 45,
          PowerBI: 36,
          Python: 37,
          Azure: 22,
        },
        {
          timestamp: 1623117606258,
          SQL: 70,
          ETL: 43,
          PowerBI: 35,
          Python: 40,
          Azure: 21,
        },
        {
          timestamp: 1623463205279,
          SQL: 72,
          ETL: 45,
          PowerBI: 35,
          Python: 36,
          Azure: 24,
        },
        {
          timestamp: 1623722404984,
          SQL: 75,
          ETL: 44,
          PowerBI: 38,
          Python: 38,
          Azure: 25,
        },
        {
          timestamp: 1624068007484,
          SQL: 73,
          ETL: 41,
          PowerBI: 39,
          Python: 38,
          Azure: 23,
        },
        {
          timestamp: 1624327208082,
          SQL: 71,
          ETL: 41,
          PowerBI: 39,
          Python: 37,
          Azure: 24,
        },
        {
          timestamp: 1624672806043,
          SQL: 71,
          ETL: 40,
          PowerBI: 39,
          Python: 39,
          Azure: 27,
        },
        {
          timestamp: 1624932007464,
          SQL: 70,
          ETL: 39,
          PowerBI: 39,
          Python: 40,
          Azure: 26,
        },
        {
          timestamp: 1625104807609,
          SQL: 75,
          ETL: 42,
          PowerBI: 43,
          Python: 40,
          Azure: 28,
        },
        {
          timestamp: 1625277611194,
          SQL: 78,
          ETL: 44,
          PowerBI: 47,
          Python: 40,
          Azure: 30,
        },
        {
          timestamp: 1625536810671,
          SQL: 79,
          ETL: 44,
          PowerBI: 47,
          Python: 40,
          Azure: 30,
        },
        {
          timestamp: 1625709606250,
          SQL: 74,
          ETL: 43,
          PowerBI: 48,
          Python: 34,
          Azure: 29,
        },
        {
          timestamp: 1625882410283,
          SQL: 72,
          ETL: 45,
          PowerBI: 45,
          Python: 38,
          Azure: 25,
        },
        {
          timestamp: 1626314409438,
          SQL: 70,
          ETL: 48,
          PowerBI: 40,
          Python: 36,
          Azure: 27,
        },
        {
          timestamp: 1626919207357,
          SQL: 68,
          ETL: 48,
          PowerBI: 40,
          Python: 37,
          Azure: 28,
        },
        {
          timestamp: 1627524005680,
          SQL: 71,
          ETL: 49,
          PowerBI: 39,
          Python: 39,
          Azure: 27,
        },
        {
          timestamp: 1627696804982,
          SQL: 71,
          ETL: 49,
          PowerBI: 38,
          Python: 38,
          Azure: 27,
        },
        {
          timestamp: 1627956006659,
          SQL: 70,
          ETL: 46,
          PowerBI: 34,
          Python: 38,
          Azure: 25,
        },
        {
          timestamp: 1628128806058,
          SQL: 68,
          ETL: 47,
          PowerBI: 38,
          Python: 37,
          Azure: 28,
        },
        {
          timestamp: 1628301604448,
          SQL: 70,
          ETL: 47,
          PowerBI: 39,
          Python: 38,
          Azure: 30,
        },
        {
          timestamp: 1628560807508,
          SQL: 71,
          ETL: 46,
          PowerBI: 42,
          Python: 36,
          Azure: 32,
        },
        {
          timestamp: 1628733605935,
          SQL: 69,
          ETL: 46,
          PowerBI: 44,
          Python: 37,
          Azure: 31,
        },
        {
          timestamp: 1628906405405,
          SQL: 72,
          ETL: 47,
          PowerBI: 46,
          Python: 41,
          Azure: 30,
        },
        {
          timestamp: 1629338405136,
          SQL: 68,
          ETL: 42,
          PowerBI: 44,
          Python: 36,
          Azure: 26,
        },
        {
          timestamp: 1629511205084,
          SQL: 68,
          ETL: 43,
          PowerBI: 46,
          Python: 36,
          Azure: 29,
        },
        {
          timestamp: 1629943206138,
          SQL: 68,
          ETL: 45,
          PowerBI: 46,
          Python: 36,
          Azure: 26,
        },
        {
          timestamp: 1630375206475,
          SQL: 67,
          ETL: 45,
          PowerBI: 48,
          Python: 36,
          Azure: 25,
        },
        {
          timestamp: 1630548004812,
          SQL: 69,
          ETL: 46,
          PowerBI: 51,
          Python: 39,
          Azure: 24,
        },
        {
          timestamp: 1631325603711,
          SQL: 72,
          ETL: 47,
          PowerBI: 45,
          Python: 36,
          Azure: 23,
        },
        {
          timestamp: 1632189604607,
          SQL: 76,
          ETL: 48,
          PowerBI: 49,
          Python: 34,
          Azure: 22,
        },
        {
          timestamp: 1632794406223,
          SQL: 75,
          ETL: 45,
          PowerBI: 52,
          Python: 38,
          Azure: 25,
        },
        {
          timestamp: 1633744805168,
          SQL: 73,
          ETL: 48,
          PowerBI: 47,
          Python: 41,
          Azure: 25,
        },
        {
          timestamp: 1634004004078,
          SQL: 73,
          ETL: 52,
          PowerBI: 47,
          Python: 41,
          Azure: 30,
        },
        {
          timestamp: 1634176805748,
          SQL: 74,
          ETL: 56,
          PowerBI: 43,
          Python: 38,
          Azure: 33,
        },
      ],
      skillNames: ['SQL', 'ETL', 'Power BI', 'Python', 'Azure'],
      currentTopSkills: [
        { name: 'SQL', popularity: 74 },
        { name: 'ETL', popularity: 56 },
        { name: 'Power BI', popularity: 43 },
        { name: 'Python', popularity: 38 },
        { name: 'Azure', popularity: 33 },
      ],
    },
    {
      category: 'UX',
      trends: [
        {
          timestamp: 1619834406876,
          UX: 68,
          UI: 60,
          Figma: 68,
          Sketch: 51,
          VisualDesign: 29,
        },
        {
          timestamp: 1620093609776,
          UX: 65,
          UI: 58,
          Figma: 68,
          Sketch: 52,
          VisualDesign: 25,
        },
        {
          timestamp: 1620266406462,
          UX: 66,
          UI: 59,
          Figma: 69,
          Sketch: 52,
          VisualDesign: 26,
        },
        {
          timestamp: 1620439211311,
          UX: 61,
          UI: 56,
          Figma: 64,
          Sketch: 50,
          VisualDesign: 25,
        },
        {
          timestamp: 1620698413947,
          UX: 59,
          UI: 54,
          Figma: 64,
          Sketch: 51,
          VisualDesign: 28,
        },
        {
          timestamp: 1620871204757,
          UX: 61,
          UI: 55,
          Figma: 62,
          Sketch: 50,
          VisualDesign: 27,
        },
        {
          timestamp: 1621044007710,
          UX: 63,
          UI: 54,
          Figma: 65,
          Sketch: 47,
          VisualDesign: 28,
        },
        {
          timestamp: 1621303206821,
          UX: 65,
          UI: 55,
          Figma: 64,
          Sketch: 48,
          VisualDesign: 29,
        },
        {
          timestamp: 1621476006839,
          UX: 67,
          UI: 57,
          Figma: 62,
          Sketch: 46,
          VisualDesign: 30,
        },
        {
          timestamp: 1621648807626,
          UX: 72,
          UI: 58,
          Figma: 58,
          Sketch: 44,
          VisualDesign: 35,
        },
        {
          timestamp: 1621908006917,
          UX: 70,
          UI: 56,
          Figma: 57,
          Sketch: 46,
          VisualDesign: 37,
        },
        {
          timestamp: 1622080808739,
          UX: 72,
          UI: 57,
          Figma: 60,
          Sketch: 42,
          VisualDesign: 39,
        },
        {
          timestamp: 1622253604989,
          UX: 69,
          UI: 57,
          Figma: 58,
          Sketch: 42,
          VisualDesign: 37,
        },
        {
          timestamp: 1622512806118,
          UX: 70,
          UI: 58,
          Figma: 59,
          Sketch: 45,
          VisualDesign: 41,
        },
        {
          timestamp: 1622685606731,
          UX: 69,
          UI: 57,
          Figma: 58,
          Sketch: 44,
          VisualDesign: 39,
        },
        {
          timestamp: 1622858409790,
          UX: 70,
          UI: 56,
          Figma: 58,
          Sketch: 41,
          VisualDesign: 38,
        },
        {
          timestamp: 1623117606258,
          UX: 74,
          UI: 59,
          Figma: 61,
          Sketch: 42,
          VisualDesign: 39,
        },
        {
          timestamp: 1623463205279,
          UX: 73,
          UI: 58,
          Figma: 59,
          Sketch: 42,
          VisualDesign: 36,
        },
        {
          timestamp: 1623722404984,
          UX: 72,
          UI: 59,
          Figma: 57,
          Sketch: 44,
          VisualDesign: 33,
        },
        {
          timestamp: 1624068007484,
          UX: 67,
          UI: 57,
          Figma: 60,
          Sketch: 43,
          VisualDesign: 28,
        },
        {
          timestamp: 1624327208082,
          UX: 66,
          UI: 58,
          Figma: 64,
          Sketch: 46,
          VisualDesign: 24,
        },
        {
          timestamp: 1624672806043,
          UX: 70,
          UI: 60,
          Figma: 64,
          Sketch: 46,
          VisualDesign: 22,
        },
        {
          timestamp: 1624932007464,
          UX: 74,
          UI: 62,
          Figma: 68,
          Sketch: 48,
          VisualDesign: 25,
        },
        {
          timestamp: 1625104807609,
          UX: 75,
          UI: 64,
          Figma: 65,
          Sketch: 51,
          VisualDesign: 24,
        },
        {
          timestamp: 1625277611194,
          UX: 75,
          UI: 65,
          Figma: 67,
          Sketch: 50,
          VisualDesign: 24,
        },
        {
          timestamp: 1625536810671,
          UX: 74,
          UI: 65,
          Figma: 65,
          Sketch: 51,
          VisualDesign: 23,
        },
        {
          timestamp: 1625709606250,
          UX: 74,
          UI: 63,
          Figma: 62,
          Sketch: 49,
          VisualDesign: 24,
        },
        {
          timestamp: 1625882410283,
          UX: 76,
          UI: 64,
          Figma: 61,
          Sketch: 50,
          VisualDesign: 27,
        },
        {
          timestamp: 1626314409438,
          UX: 76,
          UI: 61,
          Figma: 57,
          Sketch: 46,
          VisualDesign: 25,
        },
        {
          timestamp: 1626919207357,
          UX: 80,
          UI: 65,
          Figma: 59,
          Sketch: 52,
          VisualDesign: 26,
        },
        {
          timestamp: 1627524005680,
          UX: 80,
          UI: 70,
          Figma: 59,
          Sketch: 51,
          VisualDesign: 30,
        },
        {
          timestamp: 1627696804982,
          UX: 81,
          UI: 71,
          Figma: 62,
          Sketch: 49,
          VisualDesign: 26,
        },
        {
          timestamp: 1627956006659,
          UX: 81,
          UI: 71,
          Figma: 59,
          Sketch: 50,
          VisualDesign: 26,
        },
        {
          timestamp: 1628128806058,
          UX: 82,
          UI: 70,
          Figma: 59,
          Sketch: 48,
          VisualDesign: 29,
        },
        {
          timestamp: 1628301604448,
          UX: 82,
          UI: 68,
          Figma: 58,
          Sketch: 48,
          VisualDesign: 30,
        },
        {
          timestamp: 1628560807508,
          UX: 82,
          UI: 64,
          Figma: 59,
          Sketch: 51,
          VisualDesign: 31,
        },
        {
          timestamp: 1628733605935,
          UX: 82,
          UI: 66,
          Figma: 62,
          Sketch: 51,
          VisualDesign: 34,
        },
        {
          timestamp: 1628906405405,
          UX: 82,
          UI: 69,
          Figma: 64,
          Sketch: 51,
          VisualDesign: 36,
        },
        {
          timestamp: 1629338405136,
          UX: 80,
          UI: 65,
          Figma: 63,
          Sketch: 48,
          VisualDesign: 37,
        },
        {
          timestamp: 1629511205084,
          UX: 84,
          UI: 66,
          Figma: 63,
          Sketch: 46,
          VisualDesign: 36,
        },
        {
          timestamp: 1629943206138,
          UX: 81,
          UI: 62,
          Figma: 58,
          Sketch: 46,
          VisualDesign: 33,
        },
        {
          timestamp: 1630375206475,
          UX: 75,
          UI: 56,
          Figma: 52,
          Sketch: 38,
          VisualDesign: 33,
        },
        {
          timestamp: 1630548004812,
          UX: 74,
          UI: 56,
          Figma: 56,
          Sketch: 41,
          VisualDesign: 33,
        },
        {
          timestamp: 1631325603711,
          UX: 75,
          UI: 58,
          Figma: 51,
          Sketch: 35,
          VisualDesign: 20,
        },
        {
          timestamp: 1632189604607,
          UX: 75,
          UI: 59,
          Figma: 56,
          Sketch: 33,
          VisualDesign: 26,
        },
        {
          timestamp: 1632794406223,
          UX: 74,
          UI: 57,
          Figma: 49,
          Sketch: 32,
          VisualDesign: 23,
        },
        {
          timestamp: 1633744805168,
          UX: 72,
          UI: 62,
          Figma: 54,
          Sketch: 35,
          VisualDesign: 28,
        },
        {
          timestamp: 1634004004078,
          UX: 75,
          UI: 67,
          Figma: 58,
          Sketch: 42,
          VisualDesign: 30,
        },
        {
          timestamp: 1634176805748,
          UX: 74,
          UI: 67,
          Figma: 58,
          Sketch: 45,
          VisualDesign: 32,
        },
      ],
      skillNames: ['UX', 'UI', 'Figma', 'Sketch', 'Visual Design'],
      currentTopSkills: [
        { name: 'UX', popularity: 74 },
        { name: 'UI', popularity: 67 },
        { name: 'Figma', popularity: 58 },
        { name: 'Sketch', popularity: 45 },
        { name: 'Visual Design', popularity: 32 },
      ],
    },
    {
      category: 'Embedded',
      trends: [
        {
          timestamp: 1619834406876,
          Cpp: 86,
          C: 68,
          Linux: 65,
          Python: 54,
          Git: 32,
        },
        {
          timestamp: 1620093609776,
          Cpp: 85,
          C: 65,
          Linux: 65,
          Python: 50,
          Git: 32,
        },
        {
          timestamp: 1620266406462,
          Cpp: 87,
          C: 66,
          Linux: 63,
          Python: 47,
          Git: 34,
        },
        {
          timestamp: 1620439211311,
          Cpp: 81,
          C: 72,
          Linux: 66,
          Python: 38,
          Git: 28,
        },
        {
          timestamp: 1620698413947,
          Cpp: 77,
          C: 77,
          Linux: 58,
          Python: 32,
          Git: 26,
        },
        {
          timestamp: 1620871204757,
          Cpp: 80,
          C: 74,
          Linux: 60,
          Python: 31,
          Git: 26,
        },
        {
          timestamp: 1621044007710,
          Cpp: 77,
          C: 74,
          Linux: 64,
          Python: 33,
          Git: 23,
        },
        {
          timestamp: 1621303206821,
          Cpp: 77,
          C: 74,
          Linux: 66,
          Python: 40,
          Git: 23,
        },
        {
          timestamp: 1621476006839,
          Cpp: 76,
          C: 73,
          Linux: 62,
          Python: 41,
          Git: 27,
        },
        {
          timestamp: 1621648807626,
          Cpp: 74,
          C: 71,
          Linux: 61,
          Python: 39,
          Git: 24,
        },
        {
          timestamp: 1621908006917,
          Cpp: 80,
          C: 69,
          Linux: 60,
          Python: 40,
          Git: 20,
        },
        {
          timestamp: 1622080808739,
          Cpp: 83,
          C: 73,
          Linux: 60,
          Python: 40,
          Git: 20,
        },
        {
          timestamp: 1622253604989,
          Cpp: 85,
          C: 72,
          Linux: 56,
          Python: 41,
          Git: 21,
        },
        {
          timestamp: 1622512806118,
          Cpp: 76,
          C: 71,
          Linux: 60,
          Python: 40,
          Git: 21,
        },
        {
          timestamp: 1622685606731,
          Cpp: 76,
          C: 72,
          Linux: 61,
          Python: 39,
          Git: 22,
        },
        {
          timestamp: 1622858409790,
          Cpp: 81,
          C: 72,
          Linux: 55,
          Python: 43,
          Git: 19,
        },
        {
          timestamp: 1623117606258,
          Cpp: 81,
          C: 72,
          Linux: 57,
          Python: 43,
          Git: 23,
        },
        {
          timestamp: 1623463205279,
          Cpp: 74,
          C: 72,
          Linux: 57,
          Python: 39,
          Git: 26,
        },
        {
          timestamp: 1623722404984,
          Cpp: 70,
          C: 72,
          Linux: 58,
          Python: 38,
          Git: 26,
        },
        {
          timestamp: 1624068007484,
          Cpp: 67,
          C: 69,
          Linux: 60,
          Python: 38,
          Git: 24,
        },
        {
          timestamp: 1624327208082,
          Cpp: 68,
          C: 68,
          Linux: 57,
          Python: 36,
          Git: 23,
        },
        {
          timestamp: 1624672806043,
          Cpp: 68,
          C: 67,
          Linux: 56,
          Python: 35,
          Git: 25,
        },
        {
          timestamp: 1624932007464,
          Cpp: 66,
          C: 66,
          Linux: 57,
          Python: 36,
          Git: 26,
        },
        {
          timestamp: 1625104807609,
          Cpp: 69,
          C: 67,
          Linux: 53,
          Python: 33,
          Git: 25,
        },
        {
          timestamp: 1625277611194,
          Cpp: 72,
          C: 66,
          Linux: 57,
          Python: 40,
          Git: 23,
        },
        {
          timestamp: 1625536810671,
          Cpp: 65,
          C: 63,
          Linux: 63,
          Python: 35,
          Git: 22,
        },
        {
          timestamp: 1625709606250,
          Cpp: 65,
          C: 65,
          Linux: 65,
          Python: 35,
          Git: 21,
        },
        {
          timestamp: 1625882410283,
          Cpp: 60,
          C: 64,
          Linux: 58,
          Python: 38,
          Git: 25,
        },
        {
          timestamp: 1626314409438,
          Cpp: 60,
          C: 63,
          Linux: 54,
          Python: 38,
          Git: 25,
        },
        {
          timestamp: 1626919207357,
          Cpp: 62,
          C: 71,
          Linux: 60,
          Python: 44,
          Git: 29,
        },
        {
          timestamp: 1627524005680,
          Cpp: 59,
          C: 71,
          Linux: 53,
          Python: 45,
          Git: 29,
        },
        {
          timestamp: 1627696804982,
          Cpp: 57,
          C: 71,
          Linux: 55,
          Python: 43,
          Git: 29,
        },
        {
          timestamp: 1627956006659,
          Cpp: 50,
          C: 70,
          Linux: 48,
          Python: 34,
          Git: 34,
        },
        {
          timestamp: 1628128806058,
          Cpp: 51,
          C: 74,
          Linux: 49,
          Python: 37,
          Git: 35,
        },
        {
          timestamp: 1628301604448,
          Cpp: 50,
          C: 73,
          Linux: 48,
          Python: 38,
          Git: 35,
        },
        {
          timestamp: 1628560807508,
          Cpp: 59,
          C: 74,
          Linux: 59,
          Python: 37,
          Git: 30,
        },
        {
          timestamp: 1628733605935,
          Cpp: 65,
          C: 65,
          Linux: 62,
          Python: 42,
          Git: 31,
        },
        {
          timestamp: 1628906405405,
          Cpp: 77,
          C: 70,
          Linux: 70,
          Python: 37,
          Git: 27,
        },
        {
          timestamp: 1629338405136,
          Cpp: 87,
          C: 63,
          Linux: 67,
          Python: 33,
          Git: 27,
        },
        {
          timestamp: 1629511205084,
          Cpp: 88,
          C: 62,
          Linux: 65,
          Python: 35,
          Git: 38,
        },
        {
          timestamp: 1629943206138,
          Cpp: 88,
          C: 65,
          Linux: 68,
          Python: 32,
          Git: 38,
        },
        {
          timestamp: 1630375206475,
          Cpp: 90,
          C: 63,
          Linux: 65,
          Python: 35,
          Git: 35,
        },
        {
          timestamp: 1630548004812,
          Cpp: 92,
          C: 63,
          Linux: 66,
          Python: 37,
          Git: 34,
        },
        {
          timestamp: 1631325603711,
          Cpp: 88,
          C: 63,
          Linux: 67,
          Python: 35,
          Git: 28,
        },
        {
          timestamp: 1632189604607,
          Cpp: 79,
          C: 62,
          Linux: 64,
          Python: 44,
          Git: 26,
        },
        {
          timestamp: 1632794406223,
          Cpp: 64,
          C: 61,
          Linux: 57,
          Python: 36,
          Git: 27,
        },
        {
          timestamp: 1633744805168,
          Cpp: 71,
          C: 71,
          Linux: 59,
          Python: 34,
          Git: 27,
        },
        {
          timestamp: 1634004004078,
          Cpp: 74,
          C: 69,
          Linux: 62,
          Python: 36,
          Git: 26,
        },
        {
          timestamp: 1634176805748,
          Cpp: 76,
          C: 67,
          Linux: 58,
          Python: 38,
          Git: 24,
        },
      ],
      skillNames: ['C++', 'C', 'Linux', 'Python', 'Git'],
      currentTopSkills: [
        { name: 'C++', popularity: 76 },
        { name: 'C', popularity: 67 },
        { name: 'Linux', popularity: 58 },
        { name: 'Python', popularity: 38 },
        { name: 'Git', popularity: 24 },
      ],
    },
    {
      category: 'Other',
      trends: [
        {
          timestamp: 1619834406876,
          Python: 16,
          Organizationalskills: 8.5,
          Leadershipskills: 11,
          Projectmanagement: 5.3,
          Salesforce: 6.4,
        },
        {
          timestamp: 1620093609776,
          Python: 16,
          Organizationalskills: 9,
          Leadershipskills: 11,
          Projectmanagement: 5.6,
          Salesforce: 5.6,
        },
        {
          timestamp: 1620266406462,
          Python: 15,
          Organizationalskills: 8.8,
          Leadershipskills: 11,
          Projectmanagement: 6.6,
          Salesforce: 6.6,
        },
        {
          timestamp: 1620439211311,
          Python: 15,
          Organizationalskills: 11,
          Leadershipskills: 12,
          Projectmanagement: 6.4,
          Salesforce: 4.3,
        },
        {
          timestamp: 1620698413947,
          Python: 15,
          Organizationalskills: 9.4,
          Leadershipskills: 13,
          Projectmanagement: 8.2,
          Salesforce: 2.4,
        },
        {
          timestamp: 1620871204757,
          Python: 14,
          Organizationalskills: 9.3,
          Leadershipskills: 12,
          Projectmanagement: 8.1,
          Salesforce: 2.3,
        },
        {
          timestamp: 1621044007710,
          Python: 15,
          Organizationalskills: 10,
          Leadershipskills: 15,
          Projectmanagement: 7.3,
          Salesforce: 4.2,
        },
        {
          timestamp: 1621303206821,
          Python: 14,
          Organizationalskills: 16,
          Leadershipskills: 15,
          Projectmanagement: 8,
          Salesforce: 4,
        },
        {
          timestamp: 1621476006839,
          Python: 12,
          Organizationalskills: 18,
          Leadershipskills: 14,
          Projectmanagement: 7.6,
          Salesforce: 3.8,
        },
        {
          timestamp: 1621648807626,
          Python: 11,
          Organizationalskills: 18,
          Leadershipskills: 16,
          Projectmanagement: 8.7,
          Salesforce: 3.9,
        },
        {
          timestamp: 1621908006917,
          Python: 10,
          Organizationalskills: 18,
          Leadershipskills: 14,
          Projectmanagement: 8.1,
          Salesforce: 4,
        },
        {
          timestamp: 1622080808739,
          Python: 8.4,
          Organizationalskills: 19,
          Leadershipskills: 13,
          Projectmanagement: 6.5,
          Salesforce: 7.5,
        },
        {
          timestamp: 1622253604989,
          Python: 8.3,
          Organizationalskills: 18,
          Leadershipskills: 13,
          Projectmanagement: 6.4,
          Salesforce: 7.3,
        },
        {
          timestamp: 1622512806118,
          Python: 5.8,
          Organizationalskills: 17,
          Leadershipskills: 12,
          Projectmanagement: 5.8,
          Salesforce: 7.7,
        },
        {
          timestamp: 1622685606731,
          Python: 6.8,
          Organizationalskills: 18,
          Leadershipskills: 14,
          Projectmanagement: 6.8,
          Salesforce: 7.7,
        },
        {
          timestamp: 1622858409790,
          Python: 7.2,
          Organizationalskills: 18,
          Leadershipskills: 14,
          Projectmanagement: 6.3,
          Salesforce: 7.2,
        },
        {
          timestamp: 1623117606258,
          Python: 7.4,
          Organizationalskills: 18,
          Leadershipskills: 15,
          Projectmanagement: 6.5,
          Salesforce: 7.4,
        },
        {
          timestamp: 1623463205279,
          Python: 8.8,
          Organizationalskills: 18,
          Leadershipskills: 16,
          Projectmanagement: 6.1,
          Salesforce: 7.9,
        },
        {
          timestamp: 1623722404984,
          Python: 8.9,
          Organizationalskills: 19,
          Leadershipskills: 17,
          Projectmanagement: 5.9,
          Salesforce: 6.9,
        },
        {
          timestamp: 1624068007484,
          Python: 8.2,
          Organizationalskills: 14,
          Leadershipskills: 15,
          Projectmanagement: 4.7,
          Salesforce: 9.4,
        },
        {
          timestamp: 1624327208082,
          Python: 9.4,
          Organizationalskills: 15,
          Leadershipskills: 15,
          Projectmanagement: 4.7,
          Salesforce: 8.2,
        },
        {
          timestamp: 1624672806043,
          Python: 8,
          Organizationalskills: 17,
          Leadershipskills: 16,
          Projectmanagement: 9.2,
          Salesforce: 3.4,
        },
        {
          timestamp: 1624932007464,
          Python: 7.3,
          Organizationalskills: 17,
          Leadershipskills: 17,
          Projectmanagement: 8.5,
          Salesforce: 2.4,
        },
        {
          timestamp: 1625104807609,
          Python: 8,
          Organizationalskills: 17,
          Leadershipskills: 16,
          Projectmanagement: 8,
          Salesforce: 2.3,
        },
        {
          timestamp: 1625277611194,
          Python: 6.3,
          Organizationalskills: 14,
          Leadershipskills: 13,
          Projectmanagement: 6.3,
          Salesforce: 1.3,
        },
        {
          timestamp: 1625536810671,
          Python: 8.4,
          Organizationalskills: 13,
          Leadershipskills: 13,
          Projectmanagement: 6,
          Salesforce: 1.2,
        },
        {
          timestamp: 1625709606250,
          Python: 8.5,
          Organizationalskills: 13,
          Leadershipskills: 12,
          Projectmanagement: 4.9,
          Salesforce: 1.2,
        },
        {
          timestamp: 1625882410283,
          Python: 8.8,
          Organizationalskills: 15,
          Leadershipskills: 10,
          Projectmanagement: 6.3,
          Salesforce: 1.3,
        },
        {
          timestamp: 1626314409438,
          Python: 7.5,
          Organizationalskills: 18,
          Leadershipskills: 8.8,
          Projectmanagement: 6.3,
          Salesforce: 1.3,
        },
        {
          timestamp: 1626919207357,
          Python: 7.8,
          Organizationalskills: 18,
          Leadershipskills: 14,
          Projectmanagement: 6.5,
          Salesforce: 0,
        },
        {
          timestamp: 1627524005680,
          Python: 9.2,
          Organizationalskills: 18,
          Leadershipskills: 15,
          Projectmanagement: 4.6,
          Salesforce: 0,
        },
        {
          timestamp: 1627696804982,
          Python: 11,
          Organizationalskills: 20,
          Leadershipskills: 16,
          Projectmanagement: 6.6,
          Salesforce: 1.6,
        },
        {
          timestamp: 1627956006659,
          Python: 11,
          Organizationalskills: 20,
          Leadershipskills: 16,
          Projectmanagement: 6.6,
          Salesforce: 3.3,
        },
        {
          timestamp: 1628128806058,
          Python: 12,
          Organizationalskills: 22,
          Leadershipskills: 16,
          Projectmanagement: 7.5,
          Salesforce: 3,
        },
        {
          timestamp: 1628301604448,
          Python: 11,
          Organizationalskills: 23,
          Leadershipskills: 17,
          Projectmanagement: 8.5,
          Salesforce: 2.8,
        },
        {
          timestamp: 1628560807508,
          Python: 14,
          Organizationalskills: 20,
          Leadershipskills: 17,
          Projectmanagement: 6.8,
          Salesforce: 3.4,
        },
        {
          timestamp: 1628733605935,
          Python: 14,
          Organizationalskills: 16,
          Leadershipskills: 17,
          Projectmanagement: 6.3,
          Salesforce: 3.2,
        },
        {
          timestamp: 1628906405405,
          Python: 13,
          Organizationalskills: 14,
          Leadershipskills: 17,
          Projectmanagement: 6.3,
          Salesforce: 3.1,
        },
        {
          timestamp: 1629338405136,
          Python: 12,
          Organizationalskills: 17,
          Leadershipskills: 17,
          Projectmanagement: 6.7,
          Salesforce: 3.3,
        },
        {
          timestamp: 1629511205084,
          Python: 11,
          Organizationalskills: 16,
          Leadershipskills: 15,
          Projectmanagement: 6.5,
          Salesforce: 3.2,
        },
        {
          timestamp: 1629943206138,
          Python: 11,
          Organizationalskills: 21,
          Leadershipskills: 17,
          Projectmanagement: 7.9,
          Salesforce: 3.2,
        },
        {
          timestamp: 1630375206475,
          Python: 14,
          Organizationalskills: 16,
          Leadershipskills: 16,
          Projectmanagement: 6.3,
          Salesforce: 1.6,
        },
        {
          timestamp: 1630548004812,
          Python: 15,
          Organizationalskills: 18,
          Leadershipskills: 18,
          Projectmanagement: 9,
          Salesforce: 3,
        },
        {
          timestamp: 1631325603711,
          Python: 11,
          Organizationalskills: 17,
          Leadershipskills: 15,
          Projectmanagement: 14,
          Salesforce: 3,
        },
        {
          timestamp: 1632189604607,
          Python: 14,
          Organizationalskills: 14,
          Leadershipskills: 16,
          Projectmanagement: 12,
          Salesforce: 7,
        },
        {
          timestamp: 1632794406223,
          Python: 16,
          Organizationalskills: 9.8,
          Leadershipskills: 9.8,
          Projectmanagement: 11,
          Salesforce: 9.8,
        },
        {
          timestamp: 1633744805168,
          Python: 26,
          Organizationalskills: 11,
          Leadershipskills: 9.3,
          Projectmanagement: 15,
          Salesforce: 9.3,
        },
        {
          timestamp: 1634004004078,
          Python: 25,
          Organizationalskills: 16,
          Leadershipskills: 13,
          Projectmanagement: 13,
          Salesforce: 16,
        },
        {
          timestamp: 1634176805748,
          Python: 21,
          Organizationalskills: 17,
          Leadershipskills: 16,
          Projectmanagement: 16,
          Salesforce: 16,
        },
      ],
      skillNames: ['Python', 'Organizational skills', 'Leadership skills', 'Project management', 'Salesforce'],
      currentTopSkills: [
        { name: 'Python', popularity: 21 },
        { name: 'Organizational skills', popularity: 17 },
        { name: 'Leadership skills', popularity: 16 },
        { name: 'Project management', popularity: 16 },
        { name: 'Salesforce', popularity: 16 },
      ],
    },
    {
      category: 'Product Management',
      trends: [
        {
          timestamp: 1619834406876,
          ProductManagement: 66,
          'Cooperationwith stakeholders': 55,
          Productvision: 39,
          Leadershipskills: 34,
          Backlogmanagement: 34,
        },
        {
          timestamp: 1620093609776,
          ProductManagement: 64,
          'Cooperationwith stakeholders': 55,
          Productvision: 33,
          Leadershipskills: 30,
          Backlogmanagement: 33,
        },
        {
          timestamp: 1620266406462,
          ProductManagement: 61,
          'Cooperationwith stakeholders': 58,
          Productvision: 36,
          Leadershipskills: 31,
          Backlogmanagement: 36,
        },
        {
          timestamp: 1620439211311,
          ProductManagement: 67,
          'Cooperationwith stakeholders': 61,
          Productvision: 36,
          Leadershipskills: 33,
          Backlogmanagement: 36,
        },
        {
          timestamp: 1620698413947,
          ProductManagement: 67,
          'Cooperationwith stakeholders': 61,
          Productvision: 36,
          Leadershipskills: 33,
          Backlogmanagement: 36,
        },
        {
          timestamp: 1620871204757,
          ProductManagement: 69,
          'Cooperationwith stakeholders': 58,
          Productvision: 36,
          Leadershipskills: 31,
          Backlogmanagement: 36,
        },
        {
          timestamp: 1621044007710,
          ProductManagement: 69,
          'Cooperationwith stakeholders': 54,
          Productvision: 37,
          Leadershipskills: 26,
          Backlogmanagement: 31,
        },
        {
          timestamp: 1621303206821,
          ProductManagement: 74,
          'Cooperationwith stakeholders': 63,
          Productvision: 43,
          Leadershipskills: 34,
          Backlogmanagement: 34,
        },
        {
          timestamp: 1621476006839,
          ProductManagement: 76,
          'Cooperationwith stakeholders': 62,
          Productvision: 49,
          Leadershipskills: 35,
          Backlogmanagement: 38,
        },
        {
          timestamp: 1621648807626,
          ProductManagement: 75,
          'Cooperationwith stakeholders': 67,
          Productvision: 53,
          Leadershipskills: 36,
          Backlogmanagement: 42,
        },
        {
          timestamp: 1621908006917,
          ProductManagement: 77,
          'Cooperationwith stakeholders': 63,
          Productvision: 49,
          Leadershipskills: 34,
          Backlogmanagement: 43,
        },
        {
          timestamp: 1622080808739,
          ProductManagement: 78,
          'Cooperationwith stakeholders': 65,
          Productvision: 49,
          Leadershipskills: 38,
          Backlogmanagement: 46,
        },
        {
          timestamp: 1622253604989,
          ProductManagement: 80,
          'Cooperationwith stakeholders': 66,
          Productvision: 51,
          Leadershipskills: 40,
          Backlogmanagement: 49,
        },
        {
          timestamp: 1622512806118,
          ProductManagement: 77,
          'Cooperationwith stakeholders': 63,
          Productvision: 54,
          Leadershipskills: 40,
          Backlogmanagement: 46,
        },
        {
          timestamp: 1622685606731,
          ProductManagement: 77,
          'Cooperationwith stakeholders': 63,
          Productvision: 54,
          Leadershipskills: 40,
          Backlogmanagement: 46,
        },
        {
          timestamp: 1622858409790,
          ProductManagement: 80,
          'Cooperationwith stakeholders': 60,
          Productvision: 57,
          Leadershipskills: 43,
          Backlogmanagement: 47,
        },
        {
          timestamp: 1623117606258,
          ProductManagement: 79,
          'Cooperationwith stakeholders': 57,
          Productvision: 54,
          Leadershipskills: 43,
          Backlogmanagement: 50,
        },
        {
          timestamp: 1623463205279,
          ProductManagement: 71,
          'Cooperationwith stakeholders': 58,
          Productvision: 52,
          Leadershipskills: 52,
          Backlogmanagement: 45,
        },
        {
          timestamp: 1623722404984,
          ProductManagement: 70,
          'Cooperationwith stakeholders': 64,
          Productvision: 45,
          Leadershipskills: 58,
          Backlogmanagement: 55,
        },
        {
          timestamp: 1624068007484,
          ProductManagement: 64,
          'Cooperationwith stakeholders': 67,
          Productvision: 44,
          Leadershipskills: 47,
          Backlogmanagement: 50,
        },
        {
          timestamp: 1624327208082,
          ProductManagement: 66,
          'Cooperationwith stakeholders': 66,
          Productvision: 43,
          Leadershipskills: 49,
          Backlogmanagement: 49,
        },
        {
          timestamp: 1624672806043,
          ProductManagement: 60,
          'Cooperationwith stakeholders': 60,
          Productvision: 43,
          Leadershipskills: 43,
          Backlogmanagement: 49,
        },
        {
          timestamp: 1624932007464,
          ProductManagement: 60,
          'Cooperationwith stakeholders': 60,
          Productvision: 43,
          Leadershipskills: 37,
          Backlogmanagement: 51,
        },
        {
          timestamp: 1625104807609,
          ProductManagement: 61,
          'Cooperationwith stakeholders': 64,
          Productvision: 42,
          Leadershipskills: 39,
          Backlogmanagement: 48,
        },
        {
          timestamp: 1625277611194,
          ProductManagement: 58,
          'Cooperationwith stakeholders': 61,
          Productvision: 39,
          Leadershipskills: 42,
          Backlogmanagement: 44,
        },
        {
          timestamp: 1625536810671,
          ProductManagement: 58,
          'Cooperationwith stakeholders': 61,
          Productvision: 37,
          Leadershipskills: 42,
          Backlogmanagement: 45,
        },
        {
          timestamp: 1625709606250,
          ProductManagement: 58,
          'Cooperationwith stakeholders': 61,
          Productvision: 39,
          Leadershipskills: 47,
          Backlogmanagement: 45,
        },
        {
          timestamp: 1625882410283,
          ProductManagement: 60,
          'Cooperationwith stakeholders': 60,
          Productvision: 40,
          Leadershipskills: 43,
          Backlogmanagement: 49,
        },
        {
          timestamp: 1626314409438,
          ProductManagement: 59,
          'Cooperationwith stakeholders': 62,
          Productvision: 44,
          Leadershipskills: 38,
          Backlogmanagement: 44,
        },
        {
          timestamp: 1626919207357,
          ProductManagement: 64,
          'Cooperationwith stakeholders': 61,
          Productvision: 44,
          Leadershipskills: 39,
          Backlogmanagement: 58,
        },
        {
          timestamp: 1627524005680,
          ProductManagement: 63,
          'Cooperationwith stakeholders': 61,
          Productvision: 41,
          Leadershipskills: 34,
          Backlogmanagement: 51,
        },
        {
          timestamp: 1627696804982,
          ProductManagement: 67,
          'Cooperationwith stakeholders': 60,
          Productvision: 42,
          Leadershipskills: 40,
          Backlogmanagement: 56,
        },
        {
          timestamp: 1627956006659,
          ProductManagement: 68,
          'Cooperationwith stakeholders': 63,
          Productvision: 44,
          Leadershipskills: 39,
          Backlogmanagement: 59,
        },
        {
          timestamp: 1628128806058,
          ProductManagement: 67,
          'Cooperationwith stakeholders': 60,
          Productvision: 48,
          Leadershipskills: 40,
          Backlogmanagement: 57,
        },
        {
          timestamp: 1628301604448,
          ProductManagement: 62,
          'Cooperationwith stakeholders': 53,
          Productvision: 44,
          Leadershipskills: 36,
          Backlogmanagement: 56,
        },
        {
          timestamp: 1628560807508,
          ProductManagement: 65,
          'Cooperationwith stakeholders': 58,
          Productvision: 44,
          Leadershipskills: 33,
          Backlogmanagement: 56,
        },
        {
          timestamp: 1628733605935,
          ProductManagement: 64,
          'Cooperationwith stakeholders': 57,
          Productvision: 41,
          Leadershipskills: 32,
          Backlogmanagement: 55,
        },
        {
          timestamp: 1628906405405,
          ProductManagement: 64,
          'Cooperationwith stakeholders': 52,
          Productvision: 38,
          Leadershipskills: 31,
          Backlogmanagement: 52,
        },
        {
          timestamp: 1629338405136,
          ProductManagement: 60,
          'Cooperationwith stakeholders': 57,
          Productvision: 31,
          Leadershipskills: 31,
          Backlogmanagement: 45,
        },
        {
          timestamp: 1629511205084,
          ProductManagement: 59,
          'Cooperationwith stakeholders': 59,
          Productvision: 28,
          Leadershipskills: 33,
          Backlogmanagement: 46,
        },
        {
          timestamp: 1629943206138,
          ProductManagement: 65,
          'Cooperationwith stakeholders': 56,
          Productvision: 35,
          Leadershipskills: 35,
          Backlogmanagement: 47,
        },
        {
          timestamp: 1630375206475,
          ProductManagement: 60,
          'Cooperationwith stakeholders': 63,
          Productvision: 43,
          Leadershipskills: 33,
          Backlogmanagement: 40,
        },
        {
          timestamp: 1630548004812,
          ProductManagement: 63,
          'Cooperationwith stakeholders': 60,
          Productvision: 47,
          Leadershipskills: 33,
          Backlogmanagement: 47,
        },
        {
          timestamp: 1631325603711,
          ProductManagement: 68,
          'Cooperationwith stakeholders': 68,
          Productvision: 44,
          Leadershipskills: 24,
          Backlogmanagement: 44,
        },
        {
          timestamp: 1632189604607,
          ProductManagement: 62,
          'Cooperationwith stakeholders': 65,
          Productvision: 44,
          Leadershipskills: 26,
          Backlogmanagement: 44,
        },
        {
          timestamp: 1632794406223,
          ProductManagement: 63,
          'Cooperationwith stakeholders': 58,
          Productvision: 34,
          Leadershipskills: 32,
          Backlogmanagement: 45,
        },
        {
          timestamp: 1633744805168,
          ProductManagement: 66,
          'Cooperationwith stakeholders': 53,
          Productvision: 43,
          Leadershipskills: 42,
          Backlogmanagement: 40,
        },
        {
          timestamp: 1634004004078,
          ProductManagement: 71,
          'Cooperationwith stakeholders': 54,
          Productvision: 44,
          Leadershipskills: 42,
          Backlogmanagement: 42,
        },
        {
          timestamp: 1634176805748,
          ProductManagement: 71,
          'Cooperationwith stakeholders': 51,
          Productvision: 43,
          Leadershipskills: 41,
          Backlogmanagement: 39,
        },
      ],
      skillNames: [
        'Product Management',
        'Cooperation with stakeholders',
        'Product vision',
        'Leadership skills',
        'Backlog management',
      ],
      currentTopSkills: [
        { name: 'Product Management', popularity: 71 },
        { name: 'Cooperation with stakeholders', popularity: 51 },
        { name: 'Product vision', popularity: 43 },
        { name: 'Leadership skills', popularity: 41 },
        { name: 'Backlog management', popularity: 39 },
      ],
    },
    {
      category: 'Project Management',
      trends: [
        {
          timestamp: 1619834406876,
          Projectmanagement: 70,
          Scrum: 46,
          JIRA: 53,
          Teammanagement: 49,
          Leadershipskills: 45,
        },
        {
          timestamp: 1620093609776,
          Projectmanagement: 70,
          Scrum: 44,
          JIRA: 50,
          Teammanagement: 48,
          Leadershipskills: 47,
        },
        {
          timestamp: 1620266406462,
          Projectmanagement: 69,
          Scrum: 44,
          JIRA: 50,
          Teammanagement: 49,
          Leadershipskills: 47,
        },
        {
          timestamp: 1620439211311,
          Projectmanagement: 69,
          Scrum: 42,
          JIRA: 49,
          Teammanagement: 53,
          Leadershipskills: 49,
        },
        {
          timestamp: 1620698413947,
          Projectmanagement: 69,
          Scrum: 43,
          JIRA: 48,
          Teammanagement: 54,
          Leadershipskills: 48,
        },
        {
          timestamp: 1620871204757,
          Projectmanagement: 71,
          Scrum: 44,
          JIRA: 49,
          Teammanagement: 55,
          Leadershipskills: 50,
        },
        {
          timestamp: 1621044007710,
          Projectmanagement: 73,
          Scrum: 45,
          JIRA: 46,
          Teammanagement: 59,
          Leadershipskills: 51,
        },
        {
          timestamp: 1621303206821,
          Projectmanagement: 71,
          Scrum: 45,
          JIRA: 45,
          Teammanagement: 51,
          Leadershipskills: 49,
        },
        {
          timestamp: 1621476006839,
          Projectmanagement: 74,
          Scrum: 49,
          JIRA: 48,
          Teammanagement: 52,
          Leadershipskills: 51,
        },
        {
          timestamp: 1621648807626,
          Projectmanagement: 70,
          Scrum: 47,
          JIRA: 48,
          Teammanagement: 47,
          Leadershipskills: 48,
        },
        {
          timestamp: 1621908006917,
          Projectmanagement: 69,
          Scrum: 49,
          JIRA: 50,
          Teammanagement: 46,
          Leadershipskills: 48,
        },
        {
          timestamp: 1622080808739,
          Projectmanagement: 71,
          Scrum: 49,
          JIRA: 49,
          Teammanagement: 46,
          Leadershipskills: 48,
        },
        {
          timestamp: 1622253604989,
          Projectmanagement: 75,
          Scrum: 47,
          JIRA: 43,
          Teammanagement: 43,
          Leadershipskills: 48,
        },
        {
          timestamp: 1622512806118,
          Projectmanagement: 77,
          Scrum: 50,
          JIRA: 42,
          Teammanagement: 46,
          Leadershipskills: 51,
        },
        {
          timestamp: 1622685606731,
          Projectmanagement: 75,
          Scrum: 51,
          JIRA: 43,
          Teammanagement: 48,
          Leadershipskills: 52,
        },
        {
          timestamp: 1622858409790,
          Projectmanagement: 75,
          Scrum: 52,
          JIRA: 42,
          Teammanagement: 48,
          Leadershipskills: 53,
        },
        {
          timestamp: 1623117606258,
          Projectmanagement: 75,
          Scrum: 54,
          JIRA: 44,
          Teammanagement: 48,
          Leadershipskills: 54,
        },
        {
          timestamp: 1623463205279,
          Projectmanagement: 73,
          Scrum: 55,
          JIRA: 44,
          Teammanagement: 48,
          Leadershipskills: 51,
        },
        {
          timestamp: 1623722404984,
          Projectmanagement: 74,
          Scrum: 57,
          JIRA: 46,
          Teammanagement: 49,
          Leadershipskills: 55,
        },
        {
          timestamp: 1624068007484,
          Projectmanagement: 74,
          Scrum: 59,
          JIRA: 49,
          Teammanagement: 51,
          Leadershipskills: 53,
        },
        {
          timestamp: 1624327208082,
          Projectmanagement: 75,
          Scrum: 61,
          JIRA: 51,
          Teammanagement: 55,
          Leadershipskills: 53,
        },
        {
          timestamp: 1624672806043,
          Projectmanagement: 72,
          Scrum: 52,
          JIRA: 47,
          Teammanagement: 51,
          Leadershipskills: 51,
        },
        {
          timestamp: 1624932007464,
          Projectmanagement: 74,
          Scrum: 57,
          JIRA: 49,
          Teammanagement: 55,
          Leadershipskills: 50,
        },
        {
          timestamp: 1625104807609,
          Projectmanagement: 74,
          Scrum: 60,
          JIRA: 47,
          Teammanagement: 54,
          Leadershipskills: 46,
        },
        {
          timestamp: 1625277611194,
          Projectmanagement: 74,
          Scrum: 59,
          JIRA: 50,
          Teammanagement: 53,
          Leadershipskills: 41,
        },
        {
          timestamp: 1625536810671,
          Projectmanagement: 74,
          Scrum: 56,
          JIRA: 48,
          Teammanagement: 49,
          Leadershipskills: 41,
        },
        {
          timestamp: 1625709606250,
          Projectmanagement: 74,
          Scrum: 55,
          JIRA: 46,
          Teammanagement: 49,
          Leadershipskills: 40,
        },
        {
          timestamp: 1625882410283,
          Projectmanagement: 76,
          Scrum: 54,
          JIRA: 45,
          Teammanagement: 49,
          Leadershipskills: 39,
        },
        {
          timestamp: 1626314409438,
          Projectmanagement: 72,
          Scrum: 47,
          JIRA: 48,
          Teammanagement: 48,
          Leadershipskills: 40,
        },
        {
          timestamp: 1626919207357,
          Projectmanagement: 71,
          Scrum: 49,
          JIRA: 47,
          Teammanagement: 49,
          Leadershipskills: 41,
        },
        {
          timestamp: 1627524005680,
          Projectmanagement: 72,
          Scrum: 53,
          JIRA: 50,
          Teammanagement: 50,
          Leadershipskills: 43,
        },
        {
          timestamp: 1627696804982,
          Projectmanagement: 71,
          Scrum: 49,
          JIRA: 51,
          Teammanagement: 50,
          Leadershipskills: 49,
        },
        {
          timestamp: 1627956006659,
          Projectmanagement: 70,
          Scrum: 47,
          JIRA: 49,
          Teammanagement: 49,
          Leadershipskills: 49,
        },
        {
          timestamp: 1628128806058,
          Projectmanagement: 73,
          Scrum: 49,
          JIRA: 52,
          Teammanagement: 51,
          Leadershipskills: 45,
        },
        {
          timestamp: 1628301604448,
          Projectmanagement: 71,
          Scrum: 50,
          JIRA: 50,
          Teammanagement: 46,
          Leadershipskills: 41,
        },
        {
          timestamp: 1628560807508,
          Projectmanagement: 68,
          Scrum: 49,
          JIRA: 47,
          Teammanagement: 42,
          Leadershipskills: 40,
        },
        {
          timestamp: 1628733605935,
          Projectmanagement: 72,
          Scrum: 49,
          JIRA: 42,
          Teammanagement: 42,
          Leadershipskills: 40,
        },
        {
          timestamp: 1628906405405,
          Projectmanagement: 74,
          Scrum: 54,
          JIRA: 40,
          Teammanagement: 42,
          Leadershipskills: 40,
        },
        {
          timestamp: 1629338405136,
          Projectmanagement: 72,
          Scrum: 50,
          JIRA: 41,
          Teammanagement: 39,
          Leadershipskills: 41,
        },
        {
          timestamp: 1629511205084,
          Projectmanagement: 71,
          Scrum: 52,
          JIRA: 43,
          Teammanagement: 38,
          Leadershipskills: 38,
        },
        {
          timestamp: 1629943206138,
          Projectmanagement: 69,
          Scrum: 58,
          JIRA: 44,
          Teammanagement: 42,
          Leadershipskills: 41,
        },
        {
          timestamp: 1630375206475,
          Projectmanagement: 65,
          Scrum: 56,
          JIRA: 44,
          Teammanagement: 44,
          Leadershipskills: 38,
        },
        {
          timestamp: 1630548004812,
          Projectmanagement: 69,
          Scrum: 57,
          JIRA: 43,
          Teammanagement: 45,
          Leadershipskills: 36,
        },
        {
          timestamp: 1631325603711,
          Projectmanagement: 66,
          Scrum: 54,
          JIRA: 49,
          Teammanagement: 44,
          Leadershipskills: 40,
        },
        {
          timestamp: 1632189604607,
          Projectmanagement: 71,
          Scrum: 54,
          JIRA: 46,
          Teammanagement: 47,
          Leadershipskills: 47,
        },
        {
          timestamp: 1632794406223,
          Projectmanagement: 71,
          Scrum: 54,
          JIRA: 46,
          Teammanagement: 41,
          Leadershipskills: 41,
        },
        {
          timestamp: 1633744805168,
          Projectmanagement: 78,
          Scrum: 57,
          JIRA: 57,
          Teammanagement: 48,
          Leadershipskills: 49,
        },
        {
          timestamp: 1634004004078,
          Projectmanagement: 76,
          Scrum: 57,
          JIRA: 56,
          Teammanagement: 51,
          Leadershipskills: 48,
        },
        {
          timestamp: 1634176805748,
          Projectmanagement: 75,
          Scrum: 54,
          JIRA: 53,
          Teammanagement: 50,
          Leadershipskills: 43,
        },
      ],
      skillNames: ['Project management', 'Scrum', 'JIRA', 'Team management', 'Leadership skills'],
      currentTopSkills: [
        { name: 'Project management', popularity: 75 },
        { name: 'Scrum', popularity: 54 },
        { name: 'JIRA', popularity: 53 },
        { name: 'Team management', popularity: 50 },
        { name: 'Leadership skills', popularity: 43 },
      ],
    },
    {
      category: 'Data Engineering',
      trends: [
        {
          timestamp: 1619834406876,
          SQL: 54,
          Python: 60,
          Spark: 51,
          AWS: 24,
          DataEngineering: 24,
        },
        {
          timestamp: 1620093609776,
          SQL: 53,
          Python: 60,
          Spark: 50,
          AWS: 22,
          DataEngineering: 26,
        },
        {
          timestamp: 1620266406462,
          SQL: 57,
          Python: 66,
          Spark: 51,
          AWS: 25,
          DataEngineering: 25,
        },
        {
          timestamp: 1620439211311,
          SQL: 59,
          Python: 66,
          Spark: 50,
          AWS: 22,
          DataEngineering: 24,
        },
        {
          timestamp: 1620698413947,
          SQL: 58,
          Python: 68,
          Spark: 52,
          AWS: 25,
          DataEngineering: 25,
        },
        {
          timestamp: 1620871204757,
          SQL: 57,
          Python: 70,
          Spark: 54,
          AWS: 25,
          DataEngineering: 25,
        },
        {
          timestamp: 1621044007710,
          SQL: 56,
          Python: 68,
          Spark: 52,
          AWS: 21,
          DataEngineering: 24,
        },
        {
          timestamp: 1621303206821,
          SQL: 62,
          Python: 65,
          Spark: 49,
          AWS: 25,
          DataEngineering: 29,
        },
        {
          timestamp: 1621476006839,
          SQL: 62,
          Python: 67,
          Spark: 49,
          AWS: 23,
          DataEngineering: 28,
        },
        {
          timestamp: 1621648807626,
          SQL: 67,
          Python: 71,
          Spark: 49,
          AWS: 24,
          DataEngineering: 26,
        },
        {
          timestamp: 1621908006917,
          SQL: 69,
          Python: 72,
          Spark: 50,
          AWS: 26,
          DataEngineering: 28,
        },
        {
          timestamp: 1622080808739,
          SQL: 66,
          Python: 70,
          Spark: 49,
          AWS: 28,
          DataEngineering: 25,
        },
        {
          timestamp: 1622253604989,
          SQL: 66,
          Python: 73,
          Spark: 53,
          AWS: 30,
          DataEngineering: 29,
        },
        {
          timestamp: 1622512806118,
          SQL: 64,
          Python: 76,
          Spark: 51,
          AWS: 30,
          DataEngineering: 26,
        },
        {
          timestamp: 1622685606731,
          SQL: 62,
          Python: 78,
          Spark: 51,
          AWS: 31,
          DataEngineering: 25,
        },
        {
          timestamp: 1622858409790,
          SQL: 58,
          Python: 75,
          Spark: 49,
          AWS: 29,
          DataEngineering: 29,
        },
        {
          timestamp: 1623117606258,
          SQL: 59,
          Python: 75,
          Spark: 52,
          AWS: 28,
          DataEngineering: 27,
        },
        {
          timestamp: 1623463205279,
          SQL: 59,
          Python: 72,
          Spark: 47,
          AWS: 25,
          DataEngineering: 22,
        },
        {
          timestamp: 1623722404984,
          SQL: 57,
          Python: 74,
          Spark: 48,
          AWS: 25,
          DataEngineering: 22,
        },
        {
          timestamp: 1624068007484,
          SQL: 55,
          Python: 72,
          Spark: 45,
          AWS: 30,
          DataEngineering: 25,
        },
        {
          timestamp: 1624327208082,
          SQL: 51,
          Python: 69,
          Spark: 49,
          AWS: 31,
          DataEngineering: 27,
        },
        {
          timestamp: 1624672806043,
          SQL: 51,
          Python: 68,
          Spark: 47,
          AWS: 28,
          DataEngineering: 29,
        },
        {
          timestamp: 1624932007464,
          SQL: 51,
          Python: 68,
          Spark: 42,
          AWS: 28,
          DataEngineering: 29,
        },
        {
          timestamp: 1625104807609,
          SQL: 52,
          Python: 67,
          Spark: 47,
          AWS: 29,
          DataEngineering: 32,
        },
        {
          timestamp: 1625277611194,
          SQL: 55,
          Python: 64,
          Spark: 47,
          AWS: 28,
          DataEngineering: 31,
        },
        {
          timestamp: 1625536810671,
          SQL: 57,
          Python: 64,
          Spark: 49,
          AWS: 28,
          DataEngineering: 26,
        },
        {
          timestamp: 1625709606250,
          SQL: 58,
          Python: 67,
          Spark: 49,
          AWS: 28,
          DataEngineering: 26,
        },
        {
          timestamp: 1625882410283,
          SQL: 59,
          Python: 64,
          Spark: 54,
          AWS: 29,
          DataEngineering: 27,
        },
        {
          timestamp: 1626314409438,
          SQL: 57,
          Python: 65,
          Spark: 59,
          AWS: 32,
          DataEngineering: 29,
        },
        {
          timestamp: 1626919207357,
          SQL: 56,
          Python: 66,
          Spark: 56,
          AWS: 25,
          DataEngineering: 17,
        },
        {
          timestamp: 1627524005680,
          SQL: 59,
          Python: 70,
          Spark: 63,
          AWS: 27,
          DataEngineering: 19,
        },
        {
          timestamp: 1627696804982,
          SQL: 59,
          Python: 67,
          Spark: 58,
          AWS: 26,
          DataEngineering: 18,
        },
        {
          timestamp: 1627956006659,
          SQL: 57,
          Python: 67,
          Spark: 57,
          AWS: 25,
          DataEngineering: 21,
        },
        {
          timestamp: 1628128806058,
          SQL: 58,
          Python: 67,
          Spark: 58,
          AWS: 26,
          DataEngineering: 23,
        },
        {
          timestamp: 1628301604448,
          SQL: 57,
          Python: 64,
          Spark: 58,
          AWS: 25,
          DataEngineering: 28,
        },
        {
          timestamp: 1628560807508,
          SQL: 56,
          Python: 61,
          Spark: 54,
          AWS: 20,
          DataEngineering: 31,
        },
        {
          timestamp: 1628733605935,
          SQL: 61,
          Python: 63,
          Spark: 53,
          AWS: 25,
          DataEngineering: 26,
        },
        {
          timestamp: 1628906405405,
          SQL: 64,
          Python: 66,
          Spark: 54,
          AWS: 28,
          DataEngineering: 30,
        },
        {
          timestamp: 1629338405136,
          SQL: 62,
          Python: 63,
          Spark: 56,
          AWS: 29,
          DataEngineering: 30,
        },
        {
          timestamp: 1629511205084,
          SQL: 67,
          Python: 66,
          Spark: 57,
          AWS: 28,
          DataEngineering: 31,
        },
        {
          timestamp: 1629943206138,
          SQL: 70,
          Python: 65,
          Spark: 56,
          AWS: 30,
          DataEngineering: 32,
        },
        {
          timestamp: 1630375206475,
          SQL: 71,
          Python: 65,
          Spark: 60,
          AWS: 31,
          DataEngineering: 31,
        },
        {
          timestamp: 1630548004812,
          SQL: 65,
          Python: 68,
          Spark: 65,
          AWS: 32,
          DataEngineering: 32,
        },
        {
          timestamp: 1631325603711,
          SQL: 61,
          Python: 73,
          Spark: 64,
          AWS: 34,
          DataEngineering: 39,
        },
        {
          timestamp: 1632189604607,
          SQL: 59,
          Python: 79,
          Spark: 63,
          AWS: 33,
          DataEngineering: 41,
        },
        {
          timestamp: 1632794406223,
          SQL: 61,
          Python: 80,
          Spark: 59,
          AWS: 39,
          DataEngineering: 39,
        },
        {
          timestamp: 1633744805168,
          SQL: 65,
          Python: 71,
          Spark: 42,
          AWS: 36,
          DataEngineering: 34,
        },
        {
          timestamp: 1634004004078,
          SQL: 66,
          Python: 68,
          Spark: 44,
          AWS: 37,
          DataEngineering: 36,
        },
        {
          timestamp: 1634176805748,
          SQL: 66,
          Python: 66,
          Spark: 44,
          AWS: 35,
          DataEngineering: 35,
        },
      ],
      skillNames: ['SQL', 'Python', 'Spark', 'AWS', 'Data Engineering'],
      currentTopSkills: [
        { name: 'SQL', popularity: 66 },
        { name: 'Python', popularity: 66 },
        { name: 'Spark', popularity: 44 },
        { name: 'AWS', popularity: 35 },
        { name: 'Data Engineering', popularity: 35 },
      ],
    },
    {
      category: 'sales',
      trends: [
        {
          timestamp: 1619834406876,
          B2Bsales: 69,
          Salesexperience: 69,
          Buildingrelationships: 69,
          Dealsclosing: 69,
          CRM: 38,
        },
        {
          timestamp: 1620093609776,
          B2Bsales: 69,
          Salesexperience: 69,
          Buildingrelationships: 69,
          Dealsclosing: 69,
          CRM: 38,
        },
        {
          timestamp: 1620266406462,
          B2Bsales: 82,
          Salesexperience: 82,
          Buildingrelationships: 82,
          Dealsclosing: 82,
          CRM: 45,
        },
        {
          timestamp: 1620439211311,
          B2Bsales: 81,
          Salesexperience: 81,
          Buildingrelationships: 69,
          Dealsclosing: 81,
          CRM: 63,
        },
        {
          timestamp: 1620698413947,
          B2Bsales: 81,
          Salesexperience: 81,
          Buildingrelationships: 69,
          Dealsclosing: 81,
          CRM: 63,
        },
        {
          timestamp: 1620871204757,
          B2Bsales: 76,
          Salesexperience: 76,
          Buildingrelationships: 65,
          Dealsclosing: 76,
          CRM: 59,
        },
        {
          timestamp: 1621044007710,
          B2Bsales: 78,
          Salesexperience: 78,
          Buildingrelationships: 61,
          Dealsclosing: 72,
          CRM: 61,
        },
        {
          timestamp: 1621303206821,
          B2Bsales: 76,
          Salesexperience: 76,
          Buildingrelationships: 59,
          Dealsclosing: 76,
          CRM: 59,
        },
        {
          timestamp: 1621476006839,
          B2Bsales: 71,
          Salesexperience: 71,
          Buildingrelationships: 52,
          Dealsclosing: 67,
          CRM: 52,
        },
        {
          timestamp: 1621648807626,
          B2Bsales: 73,
          Salesexperience: 73,
          Buildingrelationships: 55,
          Dealsclosing: 68,
          CRM: 55,
        },
        {
          timestamp: 1621908006917,
          B2Bsales: 73,
          Salesexperience: 73,
          Buildingrelationships: 55,
          Dealsclosing: 68,
          CRM: 55,
        },
        {
          timestamp: 1622080808739,
          B2Bsales: 75,
          Salesexperience: 75,
          Buildingrelationships: 54,
          Dealsclosing: 71,
          CRM: 54,
        },
        {
          timestamp: 1622253604989,
          B2Bsales: 79,
          Salesexperience: 79,
          Buildingrelationships: 47,
          Dealsclosing: 74,
          CRM: 63,
        },
        {
          timestamp: 1622512806118,
          B2Bsales: 85,
          Salesexperience: 85,
          Buildingrelationships: 55,
          Dealsclosing: 75,
          CRM: 65,
        },
        {
          timestamp: 1622685606731,
          B2Bsales: 86,
          Salesexperience: 86,
          Buildingrelationships: 52,
          Dealsclosing: 76,
          CRM: 67,
        },
        {
          timestamp: 1622858409790,
          B2Bsales: 86,
          Salesexperience: 86,
          Buildingrelationships: 52,
          Dealsclosing: 76,
          CRM: 67,
        },
        {
          timestamp: 1623117606258,
          B2Bsales: 85,
          Salesexperience: 85,
          Buildingrelationships: 50,
          Dealsclosing: 75,
          CRM: 65,
        },
        {
          timestamp: 1623463205279,
          B2Bsales: 83,
          Salesexperience: 87,
          Buildingrelationships: 57,
          Dealsclosing: 78,
          CRM: 61,
        },
        {
          timestamp: 1623722404984,
          B2Bsales: 83,
          Salesexperience: 88,
          Buildingrelationships: 58,
          Dealsclosing: 75,
          CRM: 63,
        },
        {
          timestamp: 1624068007484,
          B2Bsales: 82,
          Salesexperience: 86,
          Buildingrelationships: 59,
          Dealsclosing: 77,
          CRM: 64,
        },
        {
          timestamp: 1624327208082,
          B2Bsales: 77,
          Salesexperience: 82,
          Buildingrelationships: 55,
          Dealsclosing: 73,
          CRM: 59,
        },
        {
          timestamp: 1624672806043,
          B2Bsales: 74,
          Salesexperience: 79,
          Buildingrelationships: 58,
          Dealsclosing: 68,
          CRM: 58,
        },
        {
          timestamp: 1624932007464,
          B2Bsales: 75,
          Salesexperience: 80,
          Buildingrelationships: 55,
          Dealsclosing: 65,
          CRM: 55,
        },
        {
          timestamp: 1625104807609,
          B2Bsales: 71,
          Salesexperience: 76,
          Buildingrelationships: 52,
          Dealsclosing: 67,
          CRM: 52,
        },
        {
          timestamp: 1625277611194,
          B2Bsales: 71,
          Salesexperience: 76,
          Buildingrelationships: 52,
          Dealsclosing: 67,
          CRM: 52,
        },
        {
          timestamp: 1625536810671,
          B2Bsales: 75,
          Salesexperience: 80,
          Buildingrelationships: 55,
          Dealsclosing: 70,
          CRM: 55,
        },
        {
          timestamp: 1625709606250,
          B2Bsales: 75,
          Salesexperience: 80,
          Buildingrelationships: 60,
          Dealsclosing: 70,
          CRM: 50,
        },
        {
          timestamp: 1625882410283,
          B2Bsales: 77,
          Salesexperience: 77,
          Buildingrelationships: 59,
          Dealsclosing: 64,
          CRM: 50,
        },
        {
          timestamp: 1626314409438,
          B2Bsales: 75,
          Salesexperience: 75,
          Buildingrelationships: 50,
          Dealsclosing: 60,
          CRM: 55,
        },
        {
          timestamp: 1626919207357,
          B2Bsales: 68,
          Salesexperience: 74,
          Buildingrelationships: 47,
          Dealsclosing: 63,
          CRM: 53,
        },
        {
          timestamp: 1627524005680,
          B2Bsales: 71,
          Salesexperience: 71,
          Buildingrelationships: 52,
          Dealsclosing: 67,
          CRM: 52,
        },
        {
          timestamp: 1627696804982,
          B2Bsales: 75,
          Salesexperience: 70,
          Buildingrelationships: 55,
          Dealsclosing: 70,
          CRM: 50,
        },
        {
          timestamp: 1627956006659,
          B2Bsales: 75,
          Salesexperience: 70,
          Buildingrelationships: 55,
          Dealsclosing: 70,
          CRM: 50,
        },
        {
          timestamp: 1628128806058,
          B2Bsales: 74,
          Salesexperience: 68,
          Buildingrelationships: 53,
          Dealsclosing: 68,
          CRM: 47,
        },
        {
          timestamp: 1628301604448,
          B2Bsales: 70,
          Salesexperience: 65,
          Buildingrelationships: 50,
          Dealsclosing: 65,
          CRM: 45,
        },
        {
          timestamp: 1628560807508,
          B2Bsales: 73,
          Salesexperience: 53,
          Buildingrelationships: 47,
          Dealsclosing: 53,
          CRM: 40,
        },
        {
          timestamp: 1628733605935,
          B2Bsales: 75,
          Salesexperience: 56,
          Buildingrelationships: 50,
          Dealsclosing: 50,
          CRM: 44,
        },
        {
          timestamp: 1628906405405,
          B2Bsales: 71,
          Salesexperience: 50,
          Buildingrelationships: 50,
          Dealsclosing: 50,
          CRM: 36,
        },
        {
          timestamp: 1629338405136,
          B2Bsales: 71,
          Salesexperience: 50,
          Buildingrelationships: 50,
          Dealsclosing: 50,
          CRM: 36,
        },
        {
          timestamp: 1629511205084,
          B2Bsales: 69,
          Salesexperience: 46,
          Buildingrelationships: 46,
          Dealsclosing: 46,
          CRM: 38,
        },
        {
          timestamp: 1629943206138,
          B2Bsales: 75,
          Salesexperience: 50,
          Buildingrelationships: 50,
          Dealsclosing: 50,
          CRM: 25,
        },
        {
          timestamp: 1630375206475,
          B2Bsales: 77,
          Salesexperience: 54,
          Buildingrelationships: 54,
          Dealsclosing: 46,
          CRM: 31,
        },
        {
          timestamp: 1630548004812,
          B2Bsales: 77,
          Salesexperience: 54,
          Buildingrelationships: 54,
          Dealsclosing: 46,
          CRM: 31,
        },
        {
          timestamp: 1631325603711,
          B2Bsales: 82,
          Salesexperience: 64,
          Buildingrelationships: 55,
          Dealsclosing: 64,
          CRM: 27,
        },
        {
          timestamp: 1632189604607,
          B2Bsales: 86,
          Salesexperience: 64,
          Buildingrelationships: 57,
          Dealsclosing: 64,
          CRM: 29,
        },
        {
          timestamp: 1632794406223,
          B2Bsales: 77,
          Salesexperience: 62,
          Buildingrelationships: 46,
          Dealsclosing: 62,
          CRM: 31,
        },
        {
          timestamp: 1633744805168,
          B2Bsales: 71,
          Salesexperience: 57,
          Buildingrelationships: 43,
          Dealsclosing: 50,
          CRM: 29,
        },
        {
          timestamp: 1634004004078,
          B2Bsales: 71,
          Salesexperience: 57,
          Buildingrelationships: 43,
          Dealsclosing: 50,
          CRM: 29,
        },
        {
          timestamp: 1634176805748,
          B2Bsales: 75,
          Salesexperience: 56,
          Buildingrelationships: 44,
          Dealsclosing: 44,
          CRM: 38,
        },
      ],
      skillNames: ['B2B sales', 'Sales experience', 'Building relationships', 'Deals closing', 'CRM'],
      currentTopSkills: [
        { name: 'B2B sales', popularity: 75 },
        { name: 'Sales experience', popularity: 56 },
        { name: 'Building relationships', popularity: 44 },
        { name: 'Deals closing', popularity: 44 },
        { name: 'CRM', popularity: 38 },
      ],
    },
    {
      category: 'marketing',
      trends: [
        {
          timestamp: 1619834406876,
          DigitalMarketing: 33,
          Marketingcampaigns: 28,
          GoogleAnalytics: 5.6,
          GoalOriented: 22,
          SEO: 28,
        },
        {
          timestamp: 1620093609776,
          DigitalMarketing: 29,
          Marketingcampaigns: 24,
          GoogleAnalytics: 5.9,
          GoalOriented: 18,
          SEO: 24,
        },
        {
          timestamp: 1620266406462,
          DigitalMarketing: 29,
          Marketingcampaigns: 24,
          GoogleAnalytics: 5.9,
          GoalOriented: 18,
          SEO: 24,
        },
        {
          timestamp: 1620439211311,
          DigitalMarketing: 35,
          Marketingcampaigns: 24,
          GoogleAnalytics: 5.9,
          GoalOriented: 18,
          SEO: 24,
        },
        {
          timestamp: 1620698413947,
          DigitalMarketing: 35,
          Marketingcampaigns: 24,
          GoogleAnalytics: 5.9,
          GoalOriented: 18,
          SEO: 24,
        },
        {
          timestamp: 1620871204757,
          DigitalMarketing: 35,
          Marketingcampaigns: 24,
          GoogleAnalytics: 5.9,
          GoalOriented: 18,
          SEO: 24,
        },
        {
          timestamp: 1621044007710,
          DigitalMarketing: 33,
          Marketingcampaigns: 20,
          GoogleAnalytics: 6.7,
          GoalOriented: 20,
          SEO: 27,
        },
        {
          timestamp: 1621303206821,
          DigitalMarketing: 33,
          Marketingcampaigns: 20,
          GoogleAnalytics: 6.7,
          GoalOriented: 20,
          SEO: 33,
        },
        {
          timestamp: 1621476006839,
          DigitalMarketing: 38,
          Marketingcampaigns: 19,
          GoogleAnalytics: 6.3,
          GoalOriented: 25,
          SEO: 31,
        },
        {
          timestamp: 1621648807626,
          DigitalMarketing: 36,
          Marketingcampaigns: 14,
          GoogleAnalytics: 7.1,
          GoalOriented: 21,
          SEO: 29,
        },
        {
          timestamp: 1621908006917,
          DigitalMarketing: 36,
          Marketingcampaigns: 14,
          GoogleAnalytics: 7.1,
          GoalOriented: 21,
          SEO: 29,
        },
        {
          timestamp: 1622080808739,
          DigitalMarketing: 43,
          Marketingcampaigns: 7.1,
          GoogleAnalytics: 7.1,
          GoalOriented: 21,
          SEO: 29,
        },
        {
          timestamp: 1622253604989,
          DigitalMarketing: 46,
          Marketingcampaigns: 7.7,
          GoogleAnalytics: 0,
          GoalOriented: 23,
          SEO: 23,
        },
        {
          timestamp: 1622512806118,
          DigitalMarketing: 47,
          Marketingcampaigns: 13,
          GoogleAnalytics: 0,
          GoalOriented: 27,
          SEO: 27,
        },
        {
          timestamp: 1622685606731,
          DigitalMarketing: 47,
          Marketingcampaigns: 12,
          GoogleAnalytics: 5.9,
          GoalOriented: 24,
          SEO: 24,
        },
        {
          timestamp: 1622858409790,
          DigitalMarketing: 50,
          Marketingcampaigns: 17,
          GoogleAnalytics: 5.6,
          GoalOriented: 28,
          SEO: 28,
        },
        {
          timestamp: 1623117606258,
          DigitalMarketing: 50,
          Marketingcampaigns: 22,
          GoogleAnalytics: 11,
          GoalOriented: 33,
          SEO: 22,
        },
        {
          timestamp: 1623463205279,
          DigitalMarketing: 48,
          Marketingcampaigns: 29,
          GoogleAnalytics: 14,
          GoalOriented: 38,
          SEO: 33,
        },
        {
          timestamp: 1623722404984,
          DigitalMarketing: 48,
          Marketingcampaigns: 29,
          GoogleAnalytics: 14,
          GoalOriented: 38,
          SEO: 33,
        },
        {
          timestamp: 1624068007484,
          DigitalMarketing: 61,
          Marketingcampaigns: 30,
          GoogleAnalytics: 13,
          GoalOriented: 35,
          SEO: 26,
        },
        {
          timestamp: 1624327208082,
          DigitalMarketing: 61,
          Marketingcampaigns: 30,
          GoogleAnalytics: 13,
          GoalOriented: 35,
          SEO: 26,
        },
        {
          timestamp: 1624672806043,
          DigitalMarketing: 58,
          Marketingcampaigns: 33,
          GoogleAnalytics: 13,
          GoalOriented: 33,
          SEO: 21,
        },
        {
          timestamp: 1624932007464,
          DigitalMarketing: 62,
          Marketingcampaigns: 31,
          GoogleAnalytics: 12,
          GoalOriented: 35,
          SEO: 19,
        },
        {
          timestamp: 1625104807609,
          DigitalMarketing: 61,
          Marketingcampaigns: 26,
          GoogleAnalytics: 13,
          GoalOriented: 35,
          SEO: 13,
        },
        {
          timestamp: 1625277611194,
          DigitalMarketing: 57,
          Marketingcampaigns: 26,
          GoogleAnalytics: 8.7,
          GoalOriented: 35,
          SEO: 13,
        },
        {
          timestamp: 1625536810671,
          DigitalMarketing: 55,
          Marketingcampaigns: 23,
          GoogleAnalytics: 9.1,
          GoalOriented: 32,
          SEO: 9.1,
        },
        {
          timestamp: 1625709606250,
          DigitalMarketing: 57,
          Marketingcampaigns: 19,
          GoogleAnalytics: 4.8,
          GoalOriented: 29,
          SEO: 14,
        },
        {
          timestamp: 1625882410283,
          DigitalMarketing: 57,
          Marketingcampaigns: 24,
          GoogleAnalytics: 0,
          GoalOriented: 29,
          SEO: 14,
        },
        {
          timestamp: 1626314409438,
          DigitalMarketing: 67,
          Marketingcampaigns: 24,
          GoogleAnalytics: 0,
          GoalOriented: 19,
          SEO: 14,
        },
        {
          timestamp: 1626919207357,
          DigitalMarketing: 56,
          Marketingcampaigns: 22,
          GoogleAnalytics: 0,
          GoalOriented: 28,
          SEO: 22,
        },
        {
          timestamp: 1627524005680,
          DigitalMarketing: 47,
          Marketingcampaigns: 24,
          GoogleAnalytics: 0,
          GoalOriented: 24,
          SEO: 29,
        },
        {
          timestamp: 1627696804982,
          DigitalMarketing: 42,
          Marketingcampaigns: 21,
          GoogleAnalytics: 0,
          GoalOriented: 21,
          SEO: 26,
        },
        {
          timestamp: 1627956006659,
          DigitalMarketing: 44,
          Marketingcampaigns: 22,
          GoogleAnalytics: 0,
          GoalOriented: 22,
          SEO: 28,
        },
        {
          timestamp: 1628128806058,
          DigitalMarketing: 40,
          Marketingcampaigns: 25,
          GoogleAnalytics: 0,
          GoalOriented: 20,
          SEO: 30,
        },
        {
          timestamp: 1628301604448,
          DigitalMarketing: 43,
          Marketingcampaigns: 24,
          GoogleAnalytics: 0,
          GoalOriented: 19,
          SEO: 33,
        },
        {
          timestamp: 1628560807508,
          DigitalMarketing: 31,
          Marketingcampaigns: 25,
          GoogleAnalytics: 0,
          GoalOriented: 6.3,
          SEO: 38,
        },
        {
          timestamp: 1628733605935,
          DigitalMarketing: 29,
          Marketingcampaigns: 21,
          GoogleAnalytics: 0,
          GoalOriented: 7.1,
          SEO: 29,
        },
        {
          timestamp: 1628906405405,
          DigitalMarketing: 23,
          Marketingcampaigns: 23,
          GoogleAnalytics: 0,
          GoalOriented: 7.7,
          SEO: 31,
        },
        {
          timestamp: 1629338405136,
          DigitalMarketing: 21,
          Marketingcampaigns: 14,
          GoogleAnalytics: 7.1,
          GoalOriented: 0,
          SEO: 21,
        },
        {
          timestamp: 1629511205084,
          DigitalMarketing: 21,
          Marketingcampaigns: 14,
          GoogleAnalytics: 7.1,
          GoalOriented: 0,
          SEO: 21,
        },
        {
          timestamp: 1629943206138,
          DigitalMarketing: 15,
          Marketingcampaigns: 7.7,
          GoogleAnalytics: 7.7,
          GoalOriented: 0,
          SEO: 23,
        },
        {
          timestamp: 1630375206475,
          DigitalMarketing: 18,
          Marketingcampaigns: 9.1,
          GoogleAnalytics: 9.1,
          GoalOriented: 0,
          SEO: 27,
        },
        {
          timestamp: 1630548004812,
          DigitalMarketing: 18,
          Marketingcampaigns: 9.1,
          GoogleAnalytics: 9.1,
          GoalOriented: 0,
          SEO: 27,
        },
        {
          timestamp: 1631325603711,
          DigitalMarketing: 20,
          Marketingcampaigns: 10,
          GoogleAnalytics: 10,
          GoalOriented: 0,
          SEO: 20,
        },
        {
          timestamp: 1632189604607,
          DigitalMarketing: 36,
          Marketingcampaigns: 36,
          GoogleAnalytics: 9.1,
          GoalOriented: 27,
          SEO: 36,
        },
        {
          timestamp: 1632794406223,
          DigitalMarketing: 36,
          Marketingcampaigns: 36,
          GoogleAnalytics: 9.1,
          GoalOriented: 36,
          SEO: 45,
        },
        {
          timestamp: 1633744805168,
          DigitalMarketing: 46,
          Marketingcampaigns: 46,
          GoogleAnalytics: 38,
          GoalOriented: 38,
          SEO: 38,
        },
        {
          timestamp: 1634004004078,
          DigitalMarketing: 46,
          Marketingcampaigns: 46,
          GoogleAnalytics: 38,
          GoalOriented: 38,
          SEO: 38,
        },
        {
          timestamp: 1634176805748,
          DigitalMarketing: 46,
          Marketingcampaigns: 46,
          GoogleAnalytics: 38,
          GoalOriented: 38,
          SEO: 38,
        },
      ],
      skillNames: ['Digital Marketing', 'Marketing campaigns', 'Google Analytics', 'Goal-oriented', 'SEO'],
      currentTopSkills: [
        { name: 'Digital Marketing', popularity: 46 },
        { name: 'Marketing campaigns', popularity: 46 },
        { name: 'Google Analytics', popularity: 38 },
        { name: 'Goal-oriented', popularity: 38 },
        { name: 'SEO', popularity: 38 },
      ],
    },
    {
      category: 'Security',
      trends: [
        {
          timestamp: 1619834406876,
          Security: 120,
          Linux: 21,
          Python: 33,
          AWS: 31,
          Azure: 23,
        },
        {
          timestamp: 1620093609776,
          Security: 120,
          Linux: 22,
          Python: 30,
          AWS: 32,
          Azure: 24,
        },
        {
          timestamp: 1620266406462,
          Security: 120,
          Linux: 23,
          Python: 31,
          AWS: 31,
          Azure: 26,
        },
        {
          timestamp: 1620439211311,
          Security: 120,
          Linux: 21,
          Python: 29,
          AWS: 31,
          Azure: 24,
        },
        {
          timestamp: 1620698413947,
          Security: 120,
          Linux: 19,
          Python: 29,
          AWS: 31,
          Azure: 21,
        },
        {
          timestamp: 1620871204757,
          Security: 110,
          Linux: 21,
          Python: 33,
          AWS: 26,
          Azure: 24,
        },
        {
          timestamp: 1621044007710,
          Security: 110,
          Linux: 23,
          Python: 33,
          AWS: 23,
          Azure: 23,
        },
        {
          timestamp: 1621303206821,
          Security: 110,
          Linux: 23,
          Python: 33,
          AWS: 23,
          Azure: 23,
        },
        {
          timestamp: 1621476006839,
          Security: 110,
          Linux: 20,
          Python: 32,
          AWS: 22,
          Azure: 24,
        },
        {
          timestamp: 1621648807626,
          Security: 110,
          Linux: 21,
          Python: 34,
          AWS: 21,
          Azure: 21,
        },
        {
          timestamp: 1621908006917,
          Security: 110,
          Linux: 22,
          Python: 35,
          AWS: 19,
          Azure: 22,
        },
        {
          timestamp: 1622080808739,
          Security: 120,
          Linux: 29,
          Python: 42,
          AWS: 18,
          Azure: 24,
        },
        {
          timestamp: 1622253604989,
          Security: 130,
          Linux: 35,
          Python: 43,
          AWS: 16,
          Azure: 24,
        },
        {
          timestamp: 1622512806118,
          Security: 130,
          Linux: 34,
          Python: 43,
          AWS: 14,
          Azure: 26,
        },
        {
          timestamp: 1622685606731,
          Security: 120,
          Linux: 32,
          Python: 39,
          AWS: 16,
          Azure: 29,
        },
        {
          timestamp: 1622858409790,
          Security: 120,
          Linux: 30,
          Python: 38,
          AWS: 16,
          Azure: 27,
        },
        {
          timestamp: 1623117606258,
          Security: 130,
          Linux: 31,
          Python: 41,
          AWS: 16,
          Azure: 31,
        },
        {
          timestamp: 1623463205279,
          Security: 120,
          Linux: 24,
          Python: 39,
          AWS: 18,
          Azure: 27,
        },
        {
          timestamp: 1623722404984,
          Security: 130,
          Linux: 23,
          Python: 37,
          AWS: 17,
          Azure: 23,
        },
        {
          timestamp: 1624068007484,
          Security: 110,
          Linux: 29,
          Python: 37,
          AWS: 17,
          Azure: 17,
        },
        {
          timestamp: 1624327208082,
          Security: 110,
          Linux: 28,
          Python: 41,
          AWS: 15,
          Azure: 18,
        },
        {
          timestamp: 1624672806043,
          Security: 100,
          Linux: 28,
          Python: 33,
          AWS: 18,
          Azure: 18,
        },
        {
          timestamp: 1624932007464,
          Security: 100,
          Linux: 26,
          Python: 34,
          AWS: 18,
          Azure: 18,
        },
        {
          timestamp: 1625104807609,
          Security: 100,
          Linux: 24,
          Python: 32,
          AWS: 14,
          Azure: 16,
        },
        {
          timestamp: 1625277611194,
          Security: 95,
          Linux: 26,
          Python: 34,
          AWS: 16,
          Azure: 16,
        },
        {
          timestamp: 1625536810671,
          Security: 92,
          Linux: 24,
          Python: 32,
          AWS: 16,
          Azure: 16,
        },
        {
          timestamp: 1625709606250,
          Security: 97,
          Linux: 24,
          Python: 32,
          AWS: 18,
          Azure: 18,
        },
        {
          timestamp: 1625882410283,
          Security: 94,
          Linux: 25,
          Python: 33,
          AWS: 19,
          Azure: 19,
        },
        {
          timestamp: 1626314409438,
          Security: 88,
          Linux: 32,
          Python: 26,
          AWS: 18,
          Azure: 21,
        },
        {
          timestamp: 1626919207357,
          Security: 89,
          Linux: 34,
          Python: 29,
          AWS: 26,
          Azure: 26,
        },
        {
          timestamp: 1627524005680,
          Security: 86,
          Linux: 31,
          Python: 29,
          AWS: 26,
          Azure: 26,
        },
        {
          timestamp: 1627696804982,
          Security: 84,
          Linux: 32,
          Python: 35,
          AWS: 30,
          Azure: 27,
        },
        {
          timestamp: 1627956006659,
          Security: 91,
          Linux: 34,
          Python: 37,
          AWS: 29,
          Azure: 26,
        },
        {
          timestamp: 1628128806058,
          Security: 95,
          Linux: 38,
          Python: 38,
          AWS: 27,
          Azure: 24,
        },
        {
          timestamp: 1628301604448,
          Security: 92,
          Linux: 42,
          Python: 42,
          AWS: 25,
          Azure: 22,
        },
        {
          timestamp: 1628560807508,
          Security: 94,
          Linux: 45,
          Python: 30,
          AWS: 27,
          Azure: 27,
        },
        {
          timestamp: 1628733605935,
          Security: 110,
          Linux: 51,
          Python: 37,
          AWS: 31,
          Azure: 31,
        },
        {
          timestamp: 1628906405405,
          Security: 110,
          Linux: 47,
          Python: 38,
          AWS: 35,
          Azure: 35,
        },
        {
          timestamp: 1629338405136,
          Security: 110,
          Linux: 51,
          Python: 38,
          AWS: 41,
          Azure: 38,
        },
        {
          timestamp: 1629511205084,
          Security: 120,
          Linux: 47,
          Python: 40,
          AWS: 43,
          Azure: 37,
        },
        {
          timestamp: 1629943206138,
          Security: 130,
          Linux: 50,
          Python: 43,
          AWS: 43,
          Azure: 36,
        },
        {
          timestamp: 1630375206475,
          Security: 140,
          Linux: 63,
          Python: 42,
          AWS: 42,
          Azure: 33,
        },
        {
          timestamp: 1630548004812,
          Security: 120,
          Linux: 56,
          Python: 41,
          AWS: 48,
          Azure: 30,
        },
        {
          timestamp: 1631325603711,
          Security: 95,
          Linux: 38,
          Python: 32,
          AWS: 46,
          Azure: 32,
        },
        {
          timestamp: 1632189604607,
          Security: 91,
          Linux: 41,
          Python: 32,
          AWS: 35,
          Azure: 24,
        },
        {
          timestamp: 1632794406223,
          Security: 91,
          Linux: 37,
          Python: 30,
          AWS: 30,
          Azure: 21,
        },
        {
          timestamp: 1633744805168,
          Security: 91,
          Linux: 30,
          Python: 23,
          AWS: 21,
          Azure: 16,
        },
        {
          timestamp: 1634004004078,
          Security: 91,
          Linux: 32,
          Python: 19,
          AWS: 21,
          Azure: 21,
        },
        {
          timestamp: 1634176805748,
          Security: 92,
          Linux: 34,
          Python: 25,
          AWS: 25,
          Azure: 19,
        },
      ],
      skillNames: ['Security', 'Linux', 'Python', 'AWS', 'Azure'],
      currentTopSkills: [
        { name: 'Security', popularity: 92 },
        { name: 'Linux', popularity: 34 },
        { name: 'Python', popularity: 25 },
        { name: 'AWS', popularity: 25 },
        { name: 'Azure', popularity: 19 },
      ],
    },
    {
      category: 'Agile',
      trends: [
        {
          timestamp: 1619834406876,
          Agile: 96,
          Scrum: 100,
          JIRA: 60,
          Kanban: 36,
          Leadershipskills: 28,
        },
        {
          timestamp: 1620093609776,
          Agile: 96,
          Scrum: 100,
          JIRA: 58,
          Kanban: 38,
          Leadershipskills: 29,
        },
        {
          timestamp: 1620266406462,
          Agile: 92,
          Scrum: 100,
          JIRA: 56,
          Kanban: 36,
          Leadershipskills: 28,
        },
        {
          timestamp: 1620439211311,
          Agile: 89,
          Scrum: 100,
          JIRA: 61,
          Kanban: 36,
          Leadershipskills: 29,
        },
        {
          timestamp: 1620698413947,
          Agile: 88,
          Scrum: 100,
          JIRA: 63,
          Kanban: 38,
          Leadershipskills: 29,
        },
        {
          timestamp: 1620871204757,
          Agile: 90,
          Scrum: 95,
          JIRA: 65,
          Kanban: 40,
          Leadershipskills: 35,
        },
        {
          timestamp: 1621044007710,
          Agile: 90,
          Scrum: 95,
          JIRA: 55,
          Kanban: 35,
          Leadershipskills: 30,
        },
        {
          timestamp: 1621303206821,
          Agile: 90,
          Scrum: 95,
          JIRA: 52,
          Kanban: 33,
          Leadershipskills: 29,
        },
        {
          timestamp: 1621476006839,
          Agile: 88,
          Scrum: 96,
          JIRA: 50,
          Kanban: 42,
          Leadershipskills: 25,
        },
        {
          timestamp: 1621648807626,
          Agile: 89,
          Scrum: 96,
          JIRA: 61,
          Kanban: 54,
          Leadershipskills: 36,
        },
        {
          timestamp: 1621908006917,
          Agile: 90,
          Scrum: 97,
          JIRA: 66,
          Kanban: 55,
          Leadershipskills: 41,
        },
        {
          timestamp: 1622080808739,
          Agile: 90,
          Scrum: 97,
          JIRA: 68,
          Kanban: 55,
          Leadershipskills: 45,
        },
        {
          timestamp: 1622253604989,
          Agile: 90,
          Scrum: 97,
          JIRA: 68,
          Kanban: 58,
          Leadershipskills: 48,
        },
        {
          timestamp: 1622512806118,
          Agile: 90,
          Scrum: 97,
          JIRA: 71,
          Kanban: 58,
          Leadershipskills: 45,
        },
        {
          timestamp: 1622685606731,
          Agile: 91,
          Scrum: 97,
          JIRA: 67,
          Kanban: 55,
          Leadershipskills: 45,
        },
        {
          timestamp: 1622858409790,
          Agile: 94,
          Scrum: 100,
          JIRA: 70,
          Kanban: 58,
          Leadershipskills: 48,
        },
        {
          timestamp: 1623117606258,
          Agile: 93,
          Scrum: 97,
          JIRA: 63,
          Kanban: 60,
          Leadershipskills: 47,
        },
        {
          timestamp: 1623463205279,
          Agile: 91,
          Scrum: 97,
          JIRA: 65,
          Kanban: 56,
          Leadershipskills: 44,
        },
        {
          timestamp: 1623722404984,
          Agile: 91,
          Scrum: 97,
          JIRA: 69,
          Kanban: 59,
          Leadershipskills: 44,
        },
        {
          timestamp: 1624068007484,
          Agile: 91,
          Scrum: 94,
          JIRA: 71,
          Kanban: 59,
          Leadershipskills: 50,
        },
        {
          timestamp: 1624327208082,
          Agile: 90,
          Scrum: 97,
          JIRA: 63,
          Kanban: 53,
          Leadershipskills: 47,
        },
        {
          timestamp: 1624672806043,
          Agile: 90,
          Scrum: 97,
          JIRA: 58,
          Kanban: 45,
          Leadershipskills: 42,
        },
        {
          timestamp: 1624932007464,
          Agile: 90,
          Scrum: 97,
          JIRA: 58,
          Kanban: 45,
          Leadershipskills: 42,
        },
        {
          timestamp: 1625104807609,
          Agile: 90,
          Scrum: 97,
          JIRA: 55,
          Kanban: 41,
          Leadershipskills: 45,
        },
        {
          timestamp: 1625277611194,
          Agile: 85,
          Scrum: 96,
          JIRA: 56,
          Kanban: 48,
          Leadershipskills: 44,
        },
        {
          timestamp: 1625536810671,
          Agile: 85,
          Scrum: 96,
          JIRA: 56,
          Kanban: 48,
          Leadershipskills: 44,
        },
        {
          timestamp: 1625709606250,
          Agile: 89,
          Scrum: 100,
          JIRA: 52,
          Kanban: 44,
          Leadershipskills: 44,
        },
        {
          timestamp: 1625882410283,
          Agile: 86,
          Scrum: 96,
          JIRA: 50,
          Kanban: 46,
          Leadershipskills: 46,
        },
        {
          timestamp: 1626314409438,
          Agile: 89,
          Scrum: 96,
          JIRA: 52,
          Kanban: 52,
          Leadershipskills: 48,
        },
        {
          timestamp: 1626919207357,
          Agile: 90,
          Scrum: 95,
          JIRA: 52,
          Kanban: 43,
          Leadershipskills: 52,
        },
        {
          timestamp: 1627524005680,
          Agile: 89,
          Scrum: 94,
          JIRA: 56,
          Kanban: 56,
          Leadershipskills: 50,
        },
        {
          timestamp: 1627696804982,
          Agile: 88,
          Scrum: 94,
          JIRA: 59,
          Kanban: 53,
          Leadershipskills: 53,
        },
        {
          timestamp: 1627956006659,
          Agile: 94,
          Scrum: 94,
          JIRA: 63,
          Kanban: 44,
          Leadershipskills: 50,
        },
        {
          timestamp: 1628128806058,
          Agile: 86,
          Scrum: 95,
          JIRA: 62,
          Kanban: 48,
          Leadershipskills: 48,
        },
        {
          timestamp: 1628301604448,
          Agile: 86,
          Scrum: 90,
          JIRA: 67,
          Kanban: 48,
          Leadershipskills: 48,
        },
        {
          timestamp: 1628560807508,
          Agile: 77,
          Scrum: 82,
          JIRA: 68,
          Kanban: 41,
          Leadershipskills: 50,
        },
        {
          timestamp: 1628733605935,
          Agile: 75,
          Scrum: 80,
          JIRA: 65,
          Kanban: 35,
          Leadershipskills: 45,
        },
        {
          timestamp: 1628906405405,
          Agile: 71,
          Scrum: 81,
          JIRA: 62,
          Kanban: 33,
          Leadershipskills: 43,
        },
        {
          timestamp: 1629338405136,
          Agile: 72,
          Scrum: 84,
          JIRA: 56,
          Kanban: 32,
          Leadershipskills: 36,
        },
        {
          timestamp: 1629511205084,
          Agile: 76,
          Scrum: 90,
          JIRA: 48,
          Kanban: 38,
          Leadershipskills: 29,
        },
        {
          timestamp: 1629943206138,
          Agile: 78,
          Scrum: 87,
          JIRA: 43,
          Kanban: 39,
          Leadershipskills: 35,
        },
        {
          timestamp: 1630375206475,
          Agile: 83,
          Scrum: 88,
          JIRA: 54,
          Kanban: 46,
          Leadershipskills: 29,
        },
        {
          timestamp: 1630548004812,
          Agile: 82,
          Scrum: 86,
          JIRA: 55,
          Kanban: 50,
          Leadershipskills: 32,
        },
        {
          timestamp: 1631325603711,
          Agile: 88,
          Scrum: 83,
          JIRA: 50,
          Kanban: 46,
          Leadershipskills: 25,
        },
        {
          timestamp: 1632189604607,
          Agile: 96,
          Scrum: 79,
          JIRA: 42,
          Kanban: 33,
          Leadershipskills: 29,
        },
        {
          timestamp: 1632794406223,
          Agile: 96,
          Scrum: 85,
          JIRA: 41,
          Kanban: 26,
          Leadershipskills: 26,
        },
        {
          timestamp: 1633744805168,
          Agile: 100,
          Scrum: 88,
          JIRA: 36,
          Kanban: 36,
          Leadershipskills: 24,
        },
        {
          timestamp: 1634004004078,
          Agile: 100,
          Scrum: 92,
          JIRA: 38,
          Kanban: 38,
          Leadershipskills: 25,
        },
        {
          timestamp: 1634176805748,
          Agile: 100,
          Scrum: 88,
          JIRA: 42,
          Kanban: 38,
          Leadershipskills: 27,
        },
      ],
      skillNames: ['Agile', 'Scrum', 'JIRA', 'Kanban', 'Leadership skills'],
      currentTopSkills: [
        { name: 'Agile', popularity: 100 },
        { name: 'Scrum', popularity: 88 },
        { name: 'JIRA', popularity: 42 },
        { name: 'Kanban', popularity: 38 },
        { name: 'Leadership skills', popularity: 27 },
      ],
    },
    {
      category: 'hr',
      trends: [
        {
          timestamp: 1619834406876,
          Recruitmentexperience: 82,
          Sourcing: 82,
          HRexperience: 45,
          EmployerBranding: 45,
          ATS: 45,
        },
        {
          timestamp: 1620093609776,
          Recruitmentexperience: 82,
          Sourcing: 73,
          HRexperience: 45,
          EmployerBranding: 45,
          ATS: 45,
        },
        {
          timestamp: 1620266406462,
          Recruitmentexperience: 80,
          Sourcing: 70,
          HRexperience: 40,
          EmployerBranding: 40,
          ATS: 40,
        },
        {
          timestamp: 1620439211311,
          Recruitmentexperience: 64,
          Sourcing: 73,
          HRexperience: 36,
          EmployerBranding: 36,
          ATS: 55,
        },
        {
          timestamp: 1620698413947,
          Recruitmentexperience: 69,
          Sourcing: 77,
          HRexperience: 38,
          EmployerBranding: 38,
          ATS: 46,
        },
        {
          timestamp: 1620871204757,
          Recruitmentexperience: 75,
          Sourcing: 75,
          HRexperience: 31,
          EmployerBranding: 44,
          ATS: 56,
        },
        {
          timestamp: 1621044007710,
          Recruitmentexperience: 75,
          Sourcing: 75,
          HRexperience: 31,
          EmployerBranding: 44,
          ATS: 56,
        },
        {
          timestamp: 1621303206821,
          Recruitmentexperience: 71,
          Sourcing: 71,
          HRexperience: 36,
          EmployerBranding: 36,
          ATS: 57,
        },
        {
          timestamp: 1621476006839,
          Recruitmentexperience: 75,
          Sourcing: 75,
          HRexperience: 31,
          EmployerBranding: 38,
          ATS: 56,
        },
        {
          timestamp: 1621648807626,
          Recruitmentexperience: 78,
          Sourcing: 72,
          HRexperience: 39,
          EmployerBranding: 39,
          ATS: 61,
        },
        {
          timestamp: 1621908006917,
          Recruitmentexperience: 88,
          Sourcing: 76,
          HRexperience: 41,
          EmployerBranding: 35,
          ATS: 65,
        },
        {
          timestamp: 1622080808739,
          Recruitmentexperience: 94,
          Sourcing: 76,
          HRexperience: 47,
          EmployerBranding: 41,
          ATS: 59,
        },
        {
          timestamp: 1622253604989,
          Recruitmentexperience: 95,
          Sourcing: 80,
          HRexperience: 40,
          EmployerBranding: 45,
          ATS: 60,
        },
        {
          timestamp: 1622512806118,
          Recruitmentexperience: 95,
          Sourcing: 81,
          HRexperience: 38,
          EmployerBranding: 48,
          ATS: 62,
        },
        {
          timestamp: 1622685606731,
          Recruitmentexperience: 95,
          Sourcing: 86,
          HRexperience: 33,
          EmployerBranding: 48,
          ATS: 67,
        },
        {
          timestamp: 1622858409790,
          Recruitmentexperience: 95,
          Sourcing: 86,
          HRexperience: 33,
          EmployerBranding: 48,
          ATS: 67,
        },
        {
          timestamp: 1623117606258,
          Recruitmentexperience: 100,
          Sourcing: 85,
          HRexperience: 35,
          EmployerBranding: 50,
          ATS: 65,
        },
        {
          timestamp: 1623463205279,
          Recruitmentexperience: 90,
          Sourcing: 76,
          HRexperience: 38,
          EmployerBranding: 43,
          ATS: 57,
        },
        {
          timestamp: 1623722404984,
          Recruitmentexperience: 90,
          Sourcing: 76,
          HRexperience: 38,
          EmployerBranding: 43,
          ATS: 57,
        },
        {
          timestamp: 1624068007484,
          Recruitmentexperience: 90,
          Sourcing: 76,
          HRexperience: 43,
          EmployerBranding: 48,
          ATS: 62,
        },
        {
          timestamp: 1624327208082,
          Recruitmentexperience: 89,
          Sourcing: 79,
          HRexperience: 37,
          EmployerBranding: 47,
          ATS: 58,
        },
        {
          timestamp: 1624672806043,
          Recruitmentexperience: 85,
          Sourcing: 70,
          HRexperience: 30,
          EmployerBranding: 45,
          ATS: 65,
        },
        {
          timestamp: 1624932007464,
          Recruitmentexperience: 81,
          Sourcing: 63,
          HRexperience: 44,
          EmployerBranding: 50,
          ATS: 69,
        },
        {
          timestamp: 1625104807609,
          Recruitmentexperience: 80,
          Sourcing: 60,
          HRexperience: 40,
          EmployerBranding: 40,
          ATS: 67,
        },
        {
          timestamp: 1625277611194,
          Recruitmentexperience: 75,
          Sourcing: 56,
          HRexperience: 50,
          EmployerBranding: 38,
          ATS: 63,
        },
        {
          timestamp: 1625536810671,
          Recruitmentexperience: 75,
          Sourcing: 56,
          HRexperience: 50,
          EmployerBranding: 38,
          ATS: 63,
        },
        {
          timestamp: 1625709606250,
          Recruitmentexperience: 75,
          Sourcing: 56,
          HRexperience: 50,
          EmployerBranding: 38,
          ATS: 63,
        },
        {
          timestamp: 1625882410283,
          Recruitmentexperience: 75,
          Sourcing: 56,
          HRexperience: 50,
          EmployerBranding: 38,
          ATS: 63,
        },
        {
          timestamp: 1626314409438,
          Recruitmentexperience: 79,
          Sourcing: 57,
          HRexperience: 50,
          EmployerBranding: 43,
          ATS: 57,
        },
        {
          timestamp: 1626919207357,
          Recruitmentexperience: 73,
          Sourcing: 53,
          HRexperience: 40,
          EmployerBranding: 40,
          ATS: 53,
        },
        {
          timestamp: 1627524005680,
          Recruitmentexperience: 76,
          Sourcing: 65,
          HRexperience: 41,
          EmployerBranding: 35,
          ATS: 35,
        },
        {
          timestamp: 1627696804982,
          Recruitmentexperience: 76,
          Sourcing: 59,
          HRexperience: 41,
          EmployerBranding: 41,
          ATS: 35,
        },
        {
          timestamp: 1627956006659,
          Recruitmentexperience: 82,
          Sourcing: 65,
          HRexperience: 29,
          EmployerBranding: 47,
          ATS: 35,
        },
        {
          timestamp: 1628128806058,
          Recruitmentexperience: 82,
          Sourcing: 65,
          HRexperience: 29,
          EmployerBranding: 47,
          ATS: 35,
        },
        {
          timestamp: 1628301604448,
          Recruitmentexperience: 83,
          Sourcing: 61,
          HRexperience: 33,
          EmployerBranding: 50,
          ATS: 33,
        },
        {
          timestamp: 1628560807508,
          Recruitmentexperience: 79,
          Sourcing: 63,
          HRexperience: 37,
          EmployerBranding: 53,
          ATS: 37,
        },
        {
          timestamp: 1628733605935,
          Recruitmentexperience: 80,
          Sourcing: 65,
          HRexperience: 40,
          EmployerBranding: 50,
          ATS: 40,
        },
        {
          timestamp: 1628906405405,
          Recruitmentexperience: 79,
          Sourcing: 63,
          HRexperience: 37,
          EmployerBranding: 47,
          ATS: 42,
        },
        {
          timestamp: 1629338405136,
          Recruitmentexperience: 78,
          Sourcing: 61,
          HRexperience: 44,
          EmployerBranding: 50,
          ATS: 33,
        },
        {
          timestamp: 1629511205084,
          Recruitmentexperience: 83,
          Sourcing: 67,
          HRexperience: 50,
          EmployerBranding: 50,
          ATS: 39,
        },
        {
          timestamp: 1629943206138,
          Recruitmentexperience: 79,
          Sourcing: 64,
          HRexperience: 43,
          EmployerBranding: 50,
          ATS: 36,
        },
        {
          timestamp: 1630375206475,
          Recruitmentexperience: 81,
          Sourcing: 75,
          HRexperience: 56,
          EmployerBranding: 50,
          ATS: 44,
        },
        {
          timestamp: 1630548004812,
          Recruitmentexperience: 79,
          Sourcing: 71,
          HRexperience: 64,
          EmployerBranding: 50,
          ATS: 43,
        },
        {
          timestamp: 1631325603711,
          Recruitmentexperience: 88,
          Sourcing: 75,
          HRexperience: 56,
          EmployerBranding: 44,
          ATS: 44,
        },
        {
          timestamp: 1632189604607,
          Recruitmentexperience: 92,
          Sourcing: 77,
          HRexperience: 62,
          EmployerBranding: 38,
          ATS: 38,
        },
        {
          timestamp: 1632794406223,
          Recruitmentexperience: 88,
          Sourcing: 75,
          HRexperience: 50,
          EmployerBranding: 31,
          ATS: 31,
        },
        {
          timestamp: 1633744805168,
          Recruitmentexperience: 82,
          Sourcing: 76,
          HRexperience: 41,
          EmployerBranding: 29,
          ATS: 24,
        },
        {
          timestamp: 1634004004078,
          Recruitmentexperience: 82,
          Sourcing: 76,
          HRexperience: 41,
          EmployerBranding: 29,
          ATS: 24,
        },
        {
          timestamp: 1634176805748,
          Recruitmentexperience: 82,
          Sourcing: 76,
          HRexperience: 35,
          EmployerBranding: 29,
          ATS: 29,
        },
      ],
      skillNames: ['Recruitment experience', 'Sourcing', 'HR experience', 'Employer Branding', 'ATS'],
      currentTopSkills: [
        { name: 'Recruitment experience', popularity: 82 },
        { name: 'Sourcing', popularity: 76 },
        { name: 'HR experience', popularity: 35 },
        { name: 'Employer Branding', popularity: 29 },
        { name: 'ATS', popularity: 29 },
      ],
    },
    {
      category: 'Game Development',
      trends: [
        {
          timestamp: 1619834406876,
          UnrealEngine: 27,
          Unity: 160,
          Csharp: 64,
          Cpp: 9.1,
          JavaScript: 0,
        },
        {
          timestamp: 1620093609776,
          UnrealEngine: 22,
          Unity: 180,
          Csharp: 78,
          Cpp: 0,
          JavaScript: 0,
        },
        {
          timestamp: 1620266406462,
          UnrealEngine: 20,
          Unity: 160,
          Csharp: 70,
          Cpp: 0,
          JavaScript: 0,
        },
        {
          timestamp: 1620439211311,
          UnrealEngine: 27,
          Unity: 150,
          Csharp: 64,
          Cpp: 9.1,
          JavaScript: 0,
        },
        {
          timestamp: 1620698413947,
          UnrealEngine: 25,
          Unity: 130,
          Csharp: 58,
          Cpp: 17,
          JavaScript: 0,
        },
        {
          timestamp: 1620871204757,
          UnrealEngine: 25,
          Unity: 130,
          Csharp: 58,
          Cpp: 17,
          JavaScript: 0,
        },
        {
          timestamp: 1621044007710,
          UnrealEngine: 25,
          Unity: 130,
          Csharp: 50,
          Cpp: 17,
          JavaScript: 0,
        },
        {
          timestamp: 1621303206821,
          UnrealEngine: 23,
          Unity: 120,
          Csharp: 54,
          Cpp: 15,
          JavaScript: 0,
        },
        {
          timestamp: 1621476006839,
          UnrealEngine: 15,
          Unity: 120,
          Csharp: 62,
          Cpp: 15,
          JavaScript: 7.7,
        },
        {
          timestamp: 1621648807626,
          UnrealEngine: 18,
          Unity: 130,
          Csharp: 55,
          Cpp: 18,
          JavaScript: 9.1,
        },
        {
          timestamp: 1621908006917,
          UnrealEngine: 18,
          Unity: 130,
          Csharp: 55,
          Cpp: 18,
          JavaScript: 9.1,
        },
        {
          timestamp: 1622080808739,
          UnrealEngine: 18,
          Unity: 130,
          Csharp: 55,
          Cpp: 18,
          JavaScript: 9.1,
        },
        {
          timestamp: 1622253604989,
          UnrealEngine: 20,
          Unity: 110,
          Csharp: 50,
          Cpp: 20,
          JavaScript: 10,
        },
        {
          timestamp: 1622512806118,
          UnrealEngine: 11,
          Unity: 130,
          Csharp: 56,
          Cpp: 22,
          JavaScript: 11,
        },
        {
          timestamp: 1622685606731,
          UnrealEngine: 13,
          Unity: 130,
          Csharp: 50,
          Cpp: 25,
          JavaScript: 0,
        },
        {
          timestamp: 1622858409790,
          UnrealEngine: 14,
          Unity: 140,
          Csharp: 57,
          Cpp: 29,
          JavaScript: 0,
        },
        {
          timestamp: 1623117606258,
          UnrealEngine: 0,
          Unity: 160,
          Csharp: 80,
          Cpp: 20,
          JavaScript: 0,
        },
        {
          timestamp: 1623463205279,
          UnrealEngine: 0,
          Unity: 150,
          Csharp: 100,
          Cpp: 17,
          JavaScript: 0,
        },
        {
          timestamp: 1623722404984,
          UnrealEngine: 0,
          Unity: 150,
          Csharp: 100,
          Cpp: 25,
          JavaScript: 0,
        },
        {
          timestamp: 1624068007484,
          UnrealEngine: 10,
          Unity: 150,
          Csharp: 70,
          Cpp: 20,
          JavaScript: 0,
        },
        {
          timestamp: 1624327208082,
          UnrealEngine: 10,
          Unity: 150,
          Csharp: 70,
          Cpp: 20,
          JavaScript: 0,
        },
        {
          timestamp: 1624672806043,
          UnrealEngine: 7.7,
          Unity: 160,
          Csharp: 77,
          Cpp: 15,
          JavaScript: 0,
        },
        {
          timestamp: 1624932007464,
          UnrealEngine: 7.1,
          Unity: 150,
          Csharp: 71,
          Cpp: 21,
          JavaScript: 0,
        },
        {
          timestamp: 1625104807609,
          UnrealEngine: 7.7,
          Unity: 150,
          Csharp: 69,
          Cpp: 23,
          JavaScript: 0,
        },
        {
          timestamp: 1625277611194,
          UnrealEngine: 7.1,
          Unity: 140,
          Csharp: 71,
          Cpp: 21,
          JavaScript: 0,
        },
        {
          timestamp: 1625536810671,
          UnrealEngine: 5.9,
          Unity: 140,
          Csharp: 71,
          Cpp: 18,
          JavaScript: 12,
        },
        {
          timestamp: 1625709606250,
          UnrealEngine: 5.9,
          Unity: 140,
          Csharp: 71,
          Cpp: 18,
          JavaScript: 12,
        },
        {
          timestamp: 1625882410283,
          UnrealEngine: 5.9,
          Unity: 140,
          Csharp: 71,
          Cpp: 18,
          JavaScript: 12,
        },
        {
          timestamp: 1626314409438,
          UnrealEngine: 12,
          Unity: 140,
          Csharp: 71,
          Cpp: 18,
          JavaScript: 12,
        },
        {
          timestamp: 1626919207357,
          UnrealEngine: 16,
          Unity: 130,
          Csharp: 68,
          Cpp: 16,
          JavaScript: 16,
        },
        {
          timestamp: 1627524005680,
          UnrealEngine: 25,
          Unity: 100,
          Csharp: 58,
          Cpp: 17,
          JavaScript: 25,
        },
        {
          timestamp: 1627696804982,
          UnrealEngine: 33,
          Unity: 110,
          Csharp: 58,
          Cpp: 8.3,
          JavaScript: 25,
        },
        {
          timestamp: 1627956006659,
          UnrealEngine: 40,
          Unity: 100,
          Csharp: 60,
          Cpp: 10,
          JavaScript: 30,
        },
        {
          timestamp: 1628128806058,
          UnrealEngine: 50,
          Unity: 75,
          Csharp: 50,
          Cpp: 13,
          JavaScript: 13,
        },
        {
          timestamp: 1628301604448,
          UnrealEngine: 44,
          Unity: 89,
          Csharp: 56,
          Cpp: 11,
          JavaScript: 11,
        },
        {
          timestamp: 1628560807508,
          UnrealEngine: 43,
          Unity: 71,
          Csharp: 57,
          Cpp: 14,
          JavaScript: 14,
        },
        {
          timestamp: 1628733605935,
          UnrealEngine: 18,
          Unity: 73,
          Csharp: 36,
          Cpp: 27,
          JavaScript: 9.1,
        },
        {
          timestamp: 1628906405405,
          UnrealEngine: 18,
          Unity: 73,
          Csharp: 36,
          Cpp: 27,
          JavaScript: 9.1,
        },
        {
          timestamp: 1629338405136,
          UnrealEngine: 11,
          Unity: 89,
          Csharp: 44,
          Cpp: 22,
          JavaScript: 0,
        },
        {
          timestamp: 1629511205084,
          UnrealEngine: 13,
          Unity: 75,
          Csharp: 38,
          Cpp: 25,
          JavaScript: 0,
        },
        {
          timestamp: 1629943206138,
          UnrealEngine: 22,
          Unity: 67,
          Csharp: 33,
          Cpp: 22,
          JavaScript: 0,
        },
        {
          timestamp: 1630375206475,
          UnrealEngine: 17,
          Unity: 67,
          Csharp: 17,
          Cpp: 33,
          JavaScript: 0,
        },
        {
          timestamp: 1630548004812,
          UnrealEngine: 25,
          Unity: 88,
          Csharp: 25,
          Cpp: 25,
          JavaScript: 0,
        },
        {
          timestamp: 1631325603711,
          UnrealEngine: 40,
          Unity: 120,
          Csharp: 60,
          Cpp: 0,
          JavaScript: 0,
        },
        {
          timestamp: 1632189604607,
          UnrealEngine: 40,
          Unity: 120,
          Csharp: 60,
          Cpp: 0,
          JavaScript: 0,
        },
        {
          timestamp: 1632794406223,
          UnrealEngine: 50,
          Unity: 130,
          Csharp: 50,
          Cpp: 25,
          JavaScript: 0,
        },
        {
          timestamp: 1633744805168,
          UnrealEngine: 67,
          Unity: 50,
          Csharp: 33,
          Cpp: 33,
          JavaScript: 33,
        },
        {
          timestamp: 1634004004078,
          UnrealEngine: 67,
          Unity: 50,
          Csharp: 33,
          Cpp: 33,
          JavaScript: 33,
        },
        {
          timestamp: 1634176805748,
          UnrealEngine: 63,
          Unity: 50,
          Csharp: 38,
          Cpp: 38,
          JavaScript: 25,
        },
      ],
      skillNames: ['Unreal Engine', 'Unity', 'C#', 'C++', 'JavaScript'],
      currentTopSkills: [
        { name: 'Unreal Engine', popularity: 63 },
        { name: 'Unity', popularity: 50 },
        { name: 'C#', popularity: 38 },
        { name: 'C++', popularity: 38 },
        { name: 'JavaScript', popularity: 25 },
      ],
    },
  ],
  chart: [
    {
      timestamp: '2021-08-03T02:00:06.659Z',
      Git: 21.903412445389066,
      JavaScript: 21.643641516117608,
      Docker: 17.357421183138506,
      Java: 19.778013933168022,
      SQL: 17.522729956311252,
    },
    {
      timestamp: '2021-08-05T02:00:06.058Z',
      Git: 22.049151955694015,
      JavaScript: 21.82992961809161,
      Docker: 17.410868812737974,
      Java: 19.7069343486789,
      SQL: 17.15703242182993,
    },
    {
      timestamp: '2021-08-07T02:00:04.448Z',
      Git: 21.898910271272896,
      JavaScript: 21.933688847669835,
      Docker: 17.412473916067704,
      Java: 19.464409923487132,
      SQL: 17.5284025040575,
    },
    {
      timestamp: '2021-09-02T02:00:04.812Z',
      Git: 29.708599857853592,
      JavaScript: 23.95167022032694,
      Docker: 24.16488983653163,
      Java: 25.79957356076759,
      SQL: 22.8500355366027,
    },
    {
      timestamp: '2021-09-11T02:00:03.711Z',
      Git: 29.24200278164117,
      JavaScript: 23.713490959666203,
      Docker: 23.887343532684284,
      Java: 24.82614742698192,
      SQL: 23.331015299026426,
    },
    {
      timestamp: '2021-09-28T02:00:06.223Z',
      Git: 30.46767537826685,
      JavaScript: 24.65612104539202,
      Docker: 24.65612104539202,
      Java: 24.65612104539202,
      SQL: 22.661623108665747,
    },
    {
      timestamp: '2021-10-12T02:00:04.078Z',
      Git: 21.12569952647439,
      JavaScript: 21.846749892380544,
      Docker: 16.67025398191993,
      Java: 18.60740421868274,
      SQL: 17.025398191993112,
    },
    {
      timestamp: '2021-10-14T02:00:05.748Z',
      Git: 29.66183574879227,
      JavaScript: 24.92753623188406,
      Docker: 23.92914653784219,
      Java: 23.768115942028984,
      SQL: 21.932367149758456,
    },
  ],
  chart_old: [
    {
      timestamp: 0,
      React: 50,
      Vue: 50,
    },
    {
      timestamp: 2,
      React: 60,
      Vue: 50,
    },
    {
      timestamp: 3,
      React: 50,
      Vue: 50,
    },
    {
      timestamp: 4,
      React: 70,
      Vue: 50,
    },
    {
      timestamp: 5,
      React: 10,
      Vue: 50,
    },
    {
      timestamp: 6,
      React: 90,
      Vue: 50,
    },
    {
      timestamp: 7,
      React: 30,
      Vue: 50,
    },
    {
      timestamp: 8,
      React: 40,
      Vue: 50,
    },
    {
      timestamp: 9,
      React: 70,
      Vue: 50,
    },
  ],
  other: [
    { label: 'Aktualne:', value: 11, color: '#219653' },
    { label: 'Rozwojowe umiejętności:', value: 88, color: '#F2994A' },
  ],
  technology: 'front end',
};

export const BOX_OFFERS_DATA = [
  {
    logo: CompanyLogo,
    company: 'Intel',
    location: 'Warszawa',
    mode: 'Zdalnie',
    salary: '8000 - 10000 PLN',
    contractType: '+ VAT / B2B',
  },
  {
    logo: CompanyLogo,
    company: 'Intel',
    location: 'Warszawa',
    mode: 'Zdalnie',
    salary: '8000 - 10000 PLN',
    contractType: '+ VAT / B2B',
  },
];
