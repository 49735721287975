import { HrMeTheme } from '@hrme/shared/lib/theme/theme';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: HrMeTheme) => ({
  wrapper: {
    borderRadius: '2px',
    backgroundColor: theme.progress.light,
    height: '5px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '29px',
    },
  },
  completed: {
    backgroundColor: theme.progress.dark,
    cursor: 'pointer',
  },
  active: {
    backgroundColor: theme.progress.active,
  },
}));
