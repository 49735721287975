import React from 'react';
import { Box, Divider, Typography, useTheme } from '@material-ui/core';

import LocationIcon from 'assets/icon/location.svg';
import ModeIcon from 'assets/icon/arrow-right.svg';

import { BoxAcceptProps } from './BoxAccept.types';
import { useStyles } from './BoxAccept.styles';
import { ButtonsAcceptCancel } from './boxAcceptButtons/buttonsAcceptCancel/ButtonsAcceptCancel';
import { LabelAccepted } from './boxAcceptButtons/labelAccepted/LabelAccepted';

export const BoxAccept: React.FC<BoxAcceptProps> = ({ data, onAccept, onReject }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { isNetto, company, location, logo, mode, salary, contractType, accepted, currency, salaryOnHand } = data;

  return (
    <div className={classes.box}>
      <div className={classes.company}>
        <img src={logo} alt="logo" className={classes.companyLogo} />
        <Box mr={3} />
        <div className={classes.companyDetails}>
          <Typography className={classes.boxDescriptionTitle}>{company}</Typography>
          <Box mb={0.5} />
          <div className={classes.companyDetailsItem}>
            <img src={LocationIcon} alt="location_icon" />
            <Box mr={0.75} />
            <Typography>{location}</Typography>
          </div>
          <div className={classes.companyDetailsItem}>
            <img src={ModeIcon} alt="location_icon" />
            <Box mr={0.75} />
            <Typography>{mode}</Typography>
          </div>
        </div>
      </div>
      <Box mb={3} />
      <Divider className={classes.divider} />
      <div className={classes.salary}>
        <Typography variant="h2" className={classes.salaryMain}>
          {salary} {currency} {isNetto ?? salaryOnHand ? 'netto' : 'brutto'}
        </Typography>
        <Typography variant="body2" className={classes.salaryDescription}>
          {contractType}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      {accepted ? <LabelAccepted /> : <ButtonsAcceptCancel onAccept={onAccept} onReject={onReject} />}
    </div>
  );
};
