import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '32px',
    position: 'fixed',
    width: '100%',
    background: 'rgba(216, 82,124, 0.9)',
    right: 0,
    left: 0,
    bottom: 0,
    [theme.breakpoints.up('md')]: {
      right: '10px',
      left: 'auto',
      maxWidth: '652px',
      bottom: '10px',
      borderRadius: '10px',
    },
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '150%',
    color: '#FFFFFF',
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: '15px',
    right: '15px',
  },
}));
