import { ProfileSettings } from 'app/profile/settings/profileSettings/ProfileSettings.types';
import { PositionState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';

export const getPositionFromArray = (data: ProfileSettings | PositionState): { position_name: string } => {
  if (data.position_name === 'other') {
    return { position_name: data.other_position[0] };
  }

  return { position_name: data.position_name };
};
