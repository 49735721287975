import React from 'react';
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Link } from '@hrme/shared';

import { LearningSourcesProps } from './LearningSources.types';
import { useStyles } from './LearningSources.styles';

export const LearningSources: React.FC<LearningSourcesProps> = ({ sources = [], mobile }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const rows = sources.map((source) => ({ title: source.title, platform: source.platform, link: source.link }));

  if (mobile)
    return (
      <>
        {rows.map((row) => (
          <>
            <div style={{ border: '1px solid lightgray', padding: '10px' }}>
              <Typography variant="body2" style={{ textAlign: 'left' }}>
                {row.title}
              </Typography>
              <Typography variant="caption" style={{ textAlign: 'left', color: 'gray' }}>
                Platforma: {row.platform}
              </Typography>
              <Divider light style={{ margin: '10px 0' }} />
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Link href={row.link} external>
                  Przejdź
                </Link>
              </div>
            </div>
            <Box mb={2} />
          </>
        ))}
      </>
    );

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ paddingLeft: 0 }}>
              <Typography variant="subtitle1" className={classes.tableHeader}>
                nazwa
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="subtitle1" className={classes.tableHeader}>
                platforma
              </Typography>
            </TableCell>
            <TableCell align="right" style={{ paddingRight: 0 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.title}>
              <TableCell component="th" scope="row" style={{ paddingLeft: 0 }}>
                <Typography variant="body2" style={{ textAlign: 'left' }}>
                  {row.title}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" style={{ textAlign: 'right' }}>
                  {row.platform}
                </Typography>
              </TableCell>
              <TableCell align="right" style={{ paddingRight: 0 }}>
                <Link href={row.link} external style={{ textAlign: 'right' }}>
                  Przejdź
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
