import { makeStyles } from '@material-ui/core';

export const getHeader = (index: number): string => {
  switch (index) {
    case 0:
      return 'Znam';
    case 1:
      return 'Uczę się';
    case 2:
      return 'Chcę się nauczyć';
    default:
      return '';
  }
};

export const getColor = (index: number): string => {
  switch (index) {
    case 0:
      return '#219653';
    case 1:
      return '#F2994A';
    case 2:
      return '#F2C94C';
    default:
      return '';
  }
};

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },

  rectangle: {
    display: 'block',
    height: 24,
    width: 6,
    borderRadius: 100,
  },
}));
