/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, useTheme } from '@material-ui/core';
// @ts-ignore
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Autocomplete } from '@material-ui/lab';

import { checkEventKey } from 'helpers/checkEventKey';
import { CircularProgressWithLabel } from '../../../../ui/circularProgressWithLabel/CircularProgressWithLabel';

import { Item } from './item/Item';
import { DragItemType } from './ItemData';
import { BoardHeader } from './boardHeader/BoardHeader';
import { BoardOrigin, BoardProps } from './Board.types';
import { getListStyle, useStyles } from './Board.styles';
import { autocompleteSuggestions } from './BoardSkillsSuggestions';

export const Board = ({
  state,
  setState,
  autocompleteItems,
  origin = BoardOrigin.MAIN,
  user,
}: BoardProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [colState, setColState] = useState(['', '', '']);
  const [helperText, setHelperText] = useState(['', '', '']);
  const [arrID, setArrID] = useState(0);
  const [forceRerender, setForceRerender] = useState(true);

  const reorder = (list: DragItemType[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Moves an item from one list to another list.
   */
  const move = (
    source: DragItemType[],
    destination: DragItemType[],
    droppableSource: any,
    droppableDestination: any,
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    // removes the rest of appearances of 'removed' in 'source'
    const sourceCloneCleared = [...sourceClone.filter((item) => item.id.toLowerCase() !== removed.id.toLowerCase())];
    sourceClone.length = 0;
    sourceClone.push(...sourceCloneCleared);

    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};

    // @ts-ignore
    result[droppableSource.droppableId] = sourceClone;
    // @ts-ignore
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const onSubmit = (arrId: number) => {
    const newState = [...state];

    const response = newState.map((internalArray) =>
      internalArray.find((el) => el.label.toLowerCase() === colState[arrId].toLowerCase()),
    );

    if (!!response[0] || !!response[1] || !!response[2]) {
      const newHelperState = [...helperText];
      newHelperState[arrId] = 'Technologia jest już dodana.';
      setHelperText(newHelperState);

      return;
    }

    newState[arrId].push({ id: colState[arrId].toLowerCase(), label: colState[arrId] });

    setState(newState);
    setColState(['', '', '']);
  };

  const onChange = (value: string, arrId: number) => {
    if (value.includes(',')) return;

    const newState = [...colState];
    const newHelperState = [...helperText];

    newState[arrId] = value;
    newHelperState[arrId] = '';

    setHelperText(newHelperState);
    setColState(newState);
    setArrID(arrId);
  };

  function onDragEnd(result: any) {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      // @ts-ignore
      newState[sInd] = result[sInd];
      // @ts-ignore
      newState[dInd] = result[dInd];

      setState(newState);
    }
  }

  const interestsSuggestions = new Array<{ item: string; category: string; popularity?: number }>();
  const autocompleteItemsModified = new Array<{ item: string; category: string; popularity?: number }>();

  autocompleteSuggestions?.forEach((e) => {
    const categoryNameNormalized = String(e.category).toLowerCase().replace(' ', '_');
    if (user?.position?.position_name === categoryNameNormalized) autocompleteItemsModified.push(e);
    else if (
      user?.specialization?.specialization !== undefined &&
      user?.specialization?.specialization.indexOf(categoryNameNormalized) !== -1
    )
      interestsSuggestions.push(e);
  });

  autocompleteItemsModified.push(...interestsSuggestions);

  autocompleteItems?.forEach((e) => {
    autocompleteItemsModified.push({ item: e, category: 'general' });
  });

  useEffect(() => {
    if (colState[arrID]) onSubmit(arrID);
  }, [forceRerender]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container style={{ width: '100%', margin: 0 }} spacing={6}>
        {state.map((el, ind) => (
          <Grid key={ind} item xs={12} md={4} className={classes.boardItem}>
            <BoardHeader index={ind} />
            <Box mb={2} />
            <Droppable key={ind} droppableId={`${ind}`}>
              {(provided: any, snapshot: any) => (
                <div className={classes.boardStyles}>
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver, origin)}
                    {...provided.droppableProps}
                    className={classes.droppable}
                  >
                    {el.map((item, index) => (
                      <Item
                        onRemove={() => {
                          const newState = [...state];
                          newState[ind].splice(index, 1);
                          setState(newState);
                        }}
                        key={index}
                        item={item}
                        index={index}
                        origin={origin}
                      />
                    ))}
                    {!!autocompleteItemsModified ? (
                      <Autocomplete
                        options={autocompleteItemsModified}
                        getOptionLabel={(option) => option.item}
                        fullWidth
                        noOptionsText="Brak opcji"
                        groupBy={(option) => option.category}
                        onChange={(e, value) => {
                          if (typeof value === 'string') {
                            return onChange(value || '', ind);
                          } else if (typeof value !== 'undefined' && value !== null) {
                            return onChange(value.item || '', ind);
                          }
                        }}
                        onClose={() => setForceRerender(!forceRerender)}
                        clearOnBlur
                        clearOnEscape
                        freeSolo
                        autoComplete
                        clearText="wyczyść"
                        renderOption={(params) => (
                          <>
                            {params.item}{' '}
                            {params.popularity && (
                              <CircularProgressWithLabel
                                value={params.popularity}
                                fontSize={15}
                                style={{ marginLeft: 'auto' }}
                              />
                            )}
                          </>
                        )}
                        renderInput={(params) => (
                          <TextField
                            inputProps={{ ...params.inputProps, value: colState[ind] }}
                            InputProps={{ disableUnderline: true }}
                            placeholder="Wprowadź lub przeciągnij"
                            fullWidth
                            helperText={helperText[ind]}
                            onChange={(e) => onChange(e.target.value, ind)}
                            className={classes.input}
                            ref={params.InputProps.ref}
                            onKeyPress={(event) => {
                              if (checkEventKey(event) && colState[ind]) onSubmit(ind);
                            }}
                          />
                        )}
                      />
                    ) : (
                      <TextField
                        placeholder="Wprowadź lub przeciągnij"
                        value={colState[ind]}
                        helperText={helperText[ind]}
                        onChange={(e) => onChange(e.target.value, ind)}
                        className={classes.input}
                        onKeyUp={(event) => {
                          if (checkEventKey(event) && colState[ind]) {
                            onSubmit(ind);
                          }
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </Droppable>
          </Grid>
        ))}
      </Grid>
    </DragDropContext>
  );
};
