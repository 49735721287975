import { makeStyles } from '@material-ui/core';

import bgsqueredark from './bgsqueredark.png';
import bgsquerelight from './bgsquerelight.png';
import logoDark from './logoDark.png';
import logoLight from './logoLight.png';

export const useStylesLightSquere = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '300px',
    pading: 0,
    backgroundImage: `url(${bgsquerelight})`,
    backgroundSize: 'cover',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    boxShadow: '0px 7px 64px rgba(0, 0, 0, 0.07)',
    borderRadius: '2px',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 40px',
    gap: '237.15px',
    width: '100%',
    top: '0.5px',
  },
  logo: {
    backgroundImage: `url(${logoLight})`,
    backgroundSize: 'cover',
    width: '69.23px',
    height: '21.04px',
  },
  topText: {
    width: '36px',
    height: '13px',
    fontFamily: "'Silka'",
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '9.945px',
    lineHeight: '130%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.3em',
    color: '#1D194B',
  },
  center: {
    padding: '10px 40px',
    width: '100%',
    pading: 0,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontFamily: 'Silka',
    fontStyle: 'normal',
    textAlign: 'left',
  },
  p1: {
    position: 'relative',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '130%',
    color: '#1D194B',
  },
  p2: {
    marginTop: '8px',
    position: 'relative',
    fontWeight: 700,
    fontSize: '26px',
    lineHeight: '110%',
    color: '#D33078',
  },
  p3: {
    marginTop: '8px',
    position: 'relative',
    fontWeight: 700,
    fontSize: '15px',
    lineheight: '130%',
    color: '#1D194B',
  },
  button: {
    marginTop: '28px',
    position: 'relative',
    top: '-3px',
    cursor: 'pointer',
    padding: '6px 12px 6px 12px',
    background: '#1D194B',
    borderRadius: '18px 18px 18px 0px',
    fontFamily: 'Silka',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '150%',
    color: '#FFFFFF',
  },
  code: {
    color: '#D8527C',
  },
}));
export const useStylesDarkSquere = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '300px',
    padingRight: 20,
    backgroundImage: `url(${bgsqueredark})`,
    backgroundSize: 'cover',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    boxShadow: '0px 7px 64px rgba(0, 0, 0, 0.07)',
    borderRadius: '2px',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 40px',
    gap: '237.15px',
    width: '100%',
    top: '0.5px',
  },
  logo: {
    backgroundImage: `url(${logoDark})`,
    backgroundSize: 'cover',
    width: '69.23px',
    height: '21.04px',
  },
  topText: {
    width: '36px',
    height: '13px',
    fontFamily: "'Silka'",
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '9.945px',
    lineHeight: '130%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.3em',
    color: '#E5E4F3',
  },
  center: {
    padding: '10px 40px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    top: '10px',
    buttom: '40px',
    right: '40px',
    fontFamily: 'Silka',
    fontStyle: 'normal',
    textAlign: 'left',
  },
  p1: {
    position: 'relative',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '130%',
    color: '#FFF',
  },
  p2: {
    marginTop: '8px',
    position: 'relative',
    fontWeight: 700,
    fontSize: '26px',
    lineHeight: '110%',
    color: '#45CFD7',
  },
  p3: {
    marginTop: '8px',
    position: 'relative',
    fontWeight: 700,
    fontSize: '15px',
    lineheight: '130%',
    color: '#FFF',
  },
  button: {
    marginTop: '28px',
    position: 'relative',
    top: '-3px',
    cursor: 'pointer',
    padding: '6px 12px 6px 12px',
    background: '#45CFD7',
    borderRadius: '18px 18px 18px 0px',
    fontFamily: 'Silka',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '150%',
    color: '#FFFFFF',
  },
  code: {
    color: '#3B34AF ',
  },
}));
