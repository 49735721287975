import React from 'react';
import { useTheme } from '@material-ui/core';

import { PlatformFooterMain } from 'ui/platformFooterMain/PlatformFooterMain';
import { NavMenu } from 'ui/navMenu/NavMenu';

import { useStyles } from './ProfileLayout.styles';
import { PROFILE_FOOTER_LINKS, ProfileLayoutProps } from './ProfileLayout.types';

export const ProfileLayout: React.FC<ProfileLayoutProps> = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.wrapper}>
      <NavMenu />
      <div className={classes.container}>{children}</div>
      <PlatformFooterMain items={PROFILE_FOOTER_LINKS} />
    </div>
  );
};
