import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 600,
    color: '#99a89f',
  },
  circular: {
    color: '#99a89f',
  },
}));
