import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { Link } from 'react-router-dom';

import { AsideType } from './AsideItem.types';
import { useStyles } from './AsideItem.styles';

export const AsideItem = ({ item, pathname, hasAlert, theme }: AsideType): JSX.Element => {
  const classes = useStyles(theme);

  return (
    <Link className={classes.linkStyles} to={item.path}>
      <Box
        bgcolor={pathname === item.path ? theme.palette.background.default : theme.palette.common.white}
        borderRadius={5}
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        display="flex"
        padding={2}
      >
        {hasAlert && <ReportProblemOutlinedIcon style={{ color: theme.palette.grey.A200, marginRight: 8 }} />}
        <Typography>{item.name}</Typography>
      </Box>
    </Link>
  );
};
