import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { Link, TextSelect } from '@hrme/shared';
import { useHistory } from 'react-router';

import { ProfileLayout } from 'ui/layouts/profileLayout/ProfileLayout';
import { BoxSubtitle } from 'ui/boxes/boxSubtitle/BoxSubtitle';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { StepStatus } from 'ui/careerPath/careerSteps/careerPathStepSummary/CareerPathStepSummary.types';
import { careerPathsConverter } from 'helpers/careerPathsConverter';
import { findSkillLevelByTechnologyName } from 'helpers/findSkillLevelByTechnologyName/findSkillLevelByTechnologyName';
import { ProfilePaths } from 'routing/profile/ProfilePaths';
import ArrowLeftIcon from 'assets/icon/arrow-left.svg';
import { useCareerPathContext } from 'hooks/useCareerPathContext/useCareerPathContext';
import { UserCareerPath } from 'context/careerPath/CareerPathContext.types';
import { useDiscountsOnTutorials } from 'hooks/useDiscountsOnTutorials/useDiscountsOnTutorials';

import { skillPopularityPerCategory } from './skillPopularityPerCategory';
import { CareerPathData, CareerPathViewProps, CareerPathViewRouteParams, ViewSelect } from './CareerPathView.types';
import { useStyles } from './CareerPathView.styles';
import { CareerPathViewPanelLeft } from './careerPathViewPanelLeft/CareerPathViewPanelLeft';
import { CareerPathViewPanelRight } from './careerPathViewPanelRight/CareerPathViewPanelRight';
import { CareerPathStepTask } from './careerPathViewPanelLeft/careerPathStepDetails/CareerPathStepDetails.types';

export const CareerPathView: React.FC<CareerPathViewProps> = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { id } = useParams<CareerPathViewRouteParams>();
  const { user } = useUserContext();
  const history = useHistory();

  const [userCareerPaths, setUserCareerPaths] = useState(user?.careerPaths);
  const [careerPath, setCareerPath] = useState(userCareerPaths?.filter((item) => item.pathRef === id)[0]);
  const [technology, setTechnology] = useState(careerPath?.technology || []);
  const { careerPaths, fetchCareerPaths, loading } = useCareerPathContext();
  const { fetchDiscountsOnTutorials } = useDiscountsOnTutorials();
  const [selectedView, setSetSelectedView] = useState<ViewSelect>(ViewSelect.CAREER_PATH);

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const technologyArray = useMemo(() => {
    const currentPath = careerPaths?.filter((item) => item.pathRef === id)[0];

    return { technology: currentPath?.technology };
  }, [technology]);

  const careerData = useMemo(() => ({ ...careerPath, ...technologyArray }), [careerPath]);

  useEffect(() => {
    if (careerPath === undefined) {
      setCareerPath(userCareerPaths?.filter((item) => item.pathRef === id)[0]);
    }
  }, [userCareerPaths]);

  useEffect(() => {
    const fetchCareers = async () => {
      await fetchCareerPaths();
    };
    fetchCareers();
    fetchDiscountsOnTutorials();
  }, []);

  useEffect(() => {
    if (loading && careerPaths.length > 0)
      setTechnology(careerPaths?.filter((item) => item.pathRef === id)[0]?.technology);
  }, [loading, careerPaths]);

  const handleClick = () => {
    history.replace(ProfilePaths.dashboard);
  };

  const pathName = careerPath?.name || '';
  const pathType = pathName.split('-')[0].trim();

  const careerPathData: CareerPathData[] | undefined = useMemo(
    () =>
      technology?.map((technologyItem, index) => {
        const skillLevel = findSkillLevelByTechnologyName({ name: technologyItem.name, user });
        const { tasks } = technologyItem;

        const completedTasks = technologyItem?.tasks?.map((task) => ({
          completed: !!user?.taskHashes?.includes(task.hash || ''),
          title: task.title,
          link: task.link,
          hash: task.hash,
        }));

        const skills = tasks?.length;
        const skillsCompleted = tasks?.reduce(
          (accumulator: number, currentValue: CareerPathStepTask) =>
            currentValue.completed ? accumulator + 1 : accumulator,
          0,
        );

        let shareOfPositions = '0%';
        let shareOfOffers = '0%';
        skillPopularityPerCategory
          .filter((e) => e.category === pathType)
          .forEach((e) => {
            if (e.item === technologyItem.name) {
              shareOfPositions = `${String(e.popularity)}%`;
              shareOfOffers = '?';
            }
          });

        skillPopularityPerCategory
          .filter((e) => e.category === 'all')
          .forEach((e) => {
            if (e.item === technologyItem.name) {
              shareOfOffers = `${String(e.popularity)}%`;
            }
          });

        return {
          summary: {
            title: technologyItem.name,
            pathName: pathType,
            status: [StepStatus.RED, StepStatus.YELLOW, StepStatus.ORANGE, StepStatus.GREEN][skillLevel],
            skillsCompleted,
            skills,

            // TODO: still hardcoded
            shareOfPositions,
            shareOfOffers,
          },
          details: {
            info: technologyItem.description,
            sources: technologyItem.sources,
            tasks: completedTasks,
            technologyId: index,
            name: technologyItem.name,
            group: technologyItem.hasGroup || '',
          },
        };
      }),
    [user, technology],
  );

  useEffect(() => {
    if (user) {
      setUserCareerPaths(careerPathsConverter(user.careerPaths));
      setCareerPath(userCareerPaths?.filter((item) => item.pathRef === id)[0]);
    }
  }, [user]);

  return (
    <ProfileLayout>
      {isDesktop ? (
        <div className={classes.container}>
          <Box mb={6} />
          <div className={classes.header}>
            <Link>
              <Typography onClick={handleClick} variant={'body2'} className={classes.headerLinkBack}>
                <img src={ArrowLeftIcon} alt="arrow_left" className={classes.arrowIcon} />
                {'Powrót'}
              </Typography>
            </Link>
            <Box mb={1.25} />
            <Typography variant="h2" className={classes.title}>
              {careerPath?.pathName}
            </Typography>
            <Box mb={1.25} />
            <BoxSubtitle items={[{ label: 'Ścieżka kariery:', value: careerPath?.name || '' }]} />
          </div>
          <Box mb={2} />
          <div className={classes.main}>
            {careerPathData && <CareerPathViewPanelLeft data={careerPathData} idPath={id} />}
            {careerPath && (
              <CareerPathViewPanelRight
                careerPath={careerData as UserCareerPath}
                careerPathCategory={pathType}
                idPath={id}
              />
            )}
          </div>
        </div>
      ) : (
        <div className={classes.container}>
          {careerPath && (
            <CareerPathViewPanelRight
              careerPath={careerData as UserCareerPath}
              openModal={selectedView === ViewSelect.EDIT_DATA}
              closeModal={setSetSelectedView}
              careerPathCategory={pathType}
              idPath={id}
            />
          )}
          <TextSelect
            items={[
              { label: 'Ścieżka rozwoju', value: ViewSelect.CAREER_PATH },
              { label: 'Edycja danych', value: ViewSelect.EDIT_DATA },
            ]}
            value={selectedView}
            onChange={(e) => setSetSelectedView(e.target.value as ViewSelect)}
            label="widok"
          />
          <Box mb={1} />
          <Box className={classes.divider} />
          <Box mb={3} />
          {careerPathData && <CareerPathViewPanelLeft data={careerPathData} idPath={id} />}
        </div>
      )}
    </ProfileLayout>
  );
};
