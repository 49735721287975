import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { ButtonDefault, TextInput } from '@hrme/shared';
import { useForm } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import { AlertMessage } from '@hrme/shared';

import { BoxWrapper } from '../components/BoxWrapper';
import { SettingsLayout } from 'ui/layouts/settingsLayout/SettingsLayout';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';

import { AccountFormProps, AccountSettingsProps } from './AccountSettings.types';

export const AccountSettings: React.FC<AccountSettingsProps> = () => {
  const { changePassword, currentUser } = useAuthentication();
  const { register, handleSubmit } = useForm<AccountFormProps>();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });
  const [showSavePrompt, setShowSavePrompt] = useState(false);
  const [messageResponse, setMessageResponse] = useState<string | undefined>();

  const userEmail = currentUser?.email ?? '';

  const onContentChange = async () => setShowSavePrompt(true);

  const onFormSubmit = async (data: AccountFormProps) => {
    if (data.password !== data.secondPassword) {
      setMessageResponse('Hasła muszą być takie same');
      setShowAlertMessage({ show: true, success: false });

      return;
    }

    const result = await changePassword(data.password);
    setMessageResponse('Dane zapisano');
    setShowAlertMessage({ show: true, success: result.success });
    setShowSavePrompt(false);
  };

  return (
    <SettingsLayout>
      <BoxWrapper>
        <Prompt when={showSavePrompt} message="Twoje dane nie zostały zapisane. Czy chcesz kontynuować?" />
        <form onSubmit={handleSubmit(onFormSubmit)} onChange={onContentChange}>
          <Typography align="left" variant="h4">
            Ustawienia Konta
          </Typography>
          <Box mb={4} />
          <TextInput label="twój adres e-mail" placeholder={userEmail} disabled />
          <TextInput label="aktualne hasło" placeholder="*******" type="password" disabled />
          <TextInput ref={register} name="password" label="nowe hasło" placeholder="Podaj nowe hasło" type="password" />
          <TextInput
            ref={register}
            name="secondPassword"
            label="powtórz nowe hasło"
            placeholder="Powtórz nowe hasło"
            type="password"
          />
          <Box mb={1} />
          <ButtonDefault>zapisz hasło</ButtonDefault>
        </form>
      </BoxWrapper>
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} message={messageResponse} />
    </SettingsLayout>
  );
};
