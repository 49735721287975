import { UseFormMethods } from 'react-hook-form';

import { FormControlLabelItem } from 'ui/inputs/radioChipGroup/RadioChipGroup.types';
import { SoftSkillsState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';

export type SoftSkillsProps = {
  methods: UseFormMethods<SoftSkillsState>;
  onFormSubmit: (data: SoftSkillsState, isBack?: boolean, isIdle?: boolean) => Promise<void>;
};

export const SOFT_SKILLS_ITEMS: FormControlLabelItem[] = [
  { value: 'team_play', label: 'praca w zespole' },
  { value: 'relations', label: 'nawiązywanie kontaktów' },
  { value: 'self_presentation', label: 'autoprezentacja' },
  { value: 'conflict_solving', label: 'rozwiązywanie konfliktów' },
  { value: 'leadership', label: 'zdolności przywódcze' },
  { value: 'negotiation', label: 'zdolności negocjacyjne' },
  { value: 'critical_thinking', label: 'myślenie krytyczne' },
];
