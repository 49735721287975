import { makeStyles } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/lib/theme/theme';

import CheckIcon from 'assets/icon/check.svg';
import CancelButton from 'assets/icon/cancel.svg';

export const useStyles = makeStyles((theme: HrMeTheme) => ({
  buttons: {
    width: '100%',
    height: '78px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  button: {
    backgroundImage: `url(${CheckIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    verticalAlign: 'bottom',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  buttonAccept: {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundImage: `url(${CheckIcon})`,
  },
  buttonCancel: {
    backgroundImage: `url(${CancelButton})`,
  },
  buttonCaption: {
    fontSize: '12px',
    color: theme.borders.main,
    marginBottom: 0,
  },
}));
