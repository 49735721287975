/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { ButtonDefault } from '@hrme/shared';

import { ExperienceItem } from 'ui/experienceItem/ExperienceItem';
import { ButtonOther } from 'ui/buttonOther/ButtonOther';

import { ExperienceSettingsProps } from './ExperienceSettings.types';
import { useStyles } from './ExperienceSettings.styles';

export const ExperienceSettings: React.FC<ExperienceSettingsProps> = ({
  methods,
  onFormSubmit,
  isAwaiting = false,
  experienceItems,
  addItem,
  deleteItem,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <Typography align="left" variant="h4">
        Doświadczenie zawodowe
      </Typography>
      <Box mb={3} />
      <div className={classes.itemsContainer}>
        {experienceItems.map((item, key) => {
          const fieldName = `experience[${key}]`;

          return (
            <div key={key}>
              <ExperienceItem methods={methods} prefix={fieldName} deleteItem={() => deleteItem(key)} {...item} />
              {key !== experienceItems.length - 1 && <Box mb={4} />}
            </div>
          );
        })}
      </div>
      <Box mb={4} />
      <ButtonOther onClick={addItem}>dodaj kolejne miejsce pracy</ButtonOther>
      <Box mb={4} />
      <ButtonDefault type="submit" disabled={isAwaiting}>
        zapisz dane
      </ButtonDefault>
    </>
  );
};
