import React from 'react';
import { Box, Modal, Typography, useTheme } from '@material-ui/core';

import { ModalType } from 'ui/careerPath/careerSteps/careerPathStepSummary/CareerPathStepSummary.types';
import { CareerPathStepDetails } from 'app/careerPathView/careerPathViewPanelLeft/careerPathStepDetails/CareerPathStepDetails';

import { MobilePopupProps } from './MobilePopup.types';
import { StyledButton, useStyles } from './MobilePopup.styles';

export const MobilePopup: React.FC<MobilePopupProps> = ({ openModal, data, setOpenModal, techBoard }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Modal open={openModal.openModal}>
      <div className={classes.wrapper}>
        <div className={classes.topBar}>{data.summary.title}</div>
        <div className={classes.contentWrapper}>
          {openModal.type === ModalType.INFO && (
            <>
              <Typography variant="h2" style={{ textAlign: 'left' }}>
                Informacje
              </Typography>
              <Box mt={2} />
              {data.details.info}
            </>
          )}

          {openModal.type === ModalType.LEVEL && (
            <>
              <Typography variant="h2" style={{ textAlign: 'left' }}>
                Poziom doświadczenia
              </Typography>
              <Box mt={2} />
              <CareerPathStepDetails data={data.details} type={ModalType.LEVEL} index={0} techBoard={techBoard} />
            </>
          )}

          {openModal.type === ModalType.KNOWLEDGE && (
            <>
              <Typography variant="h2" style={{ textAlign: 'left' }}>
                Źródła nauki
              </Typography>
              <Box mt={2} />
              <CareerPathStepDetails data={data.details} type={ModalType.KNOWLEDGE} index={0} />
            </>
          )}

          {openModal.type === ModalType.TASKS && (
            <>
              <Typography variant="h2" style={{ textAlign: 'left' }}>
                Zadania (
                {data?.details?.tasks?.reduce(
                  (accumulator: number, currentValue: { completed: boolean }) =>
                    currentValue.completed ? accumulator + 1 : accumulator,
                  0,
                )}
                /{data?.details?.tasks?.length})
              </Typography>
              <Box mt={2} />
              <CareerPathStepDetails data={data.details} type={ModalType.TASKS} index={0} />
            </>
          )}
        </div>
        <div className={classes.buttonWrapper}>
          <StyledButton
            variant="outlined"
            disableElevation
            size="large"
            onClick={() => setOpenModal({ openModal: false, type: 0 })}
            style={{ width: '95%' }}
          >
            zamknij
          </StyledButton>
        </div>
      </div>
    </Modal>
  );
};
