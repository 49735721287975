import { useEffect, useState } from 'react';
import { AnalyticsBrowser } from '@june-so/analytics-next';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useJune(): any {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [analytics, setAnalytics] = useState<any>(undefined);
  const writeKey = process.env['REACT_APP_JUNE_API_KEY'] ? process.env['REACT_APP_JUNE_API_KEY'] : 'JUNE-OFF';
  useEffect(() => {
    const loadAnalytics = async (writeKey: string) => {
      const response = AnalyticsBrowser.load({
        writeKey,
      });
      setAnalytics(response);
    };
    if (writeKey !== 'JUNE-OFF') {
      loadAnalytics(writeKey);
    }
  }, [writeKey]);

  return analytics;
}
