import {
  AvailabilityState,
  EducationState,
  ExperienceState,
  FillState,
  LanguagesState,
  PortfolioSocialMediaState,
  PositionState,
  SoftSkillsState,
  SpecializationState,
  TechnologyState,
} from '../careerPathFormContext/CareerPathFormContext.types';
import {
  FormAction,
  RESET_FORM,
  SET_FORM_AVAILABILITY,
  SET_FORM_EDUCATION,
  SET_FORM_EXPERIENCE,
  SET_FORM_FILL,
  SET_FORM_LANGUAGES,
  SET_FORM_PORTFOLIO_SOCIAL_MEDIA,
  SET_FORM_POSITION,
  SET_FORM_SOFTSKILLS,
  SET_FORM_SPECIALIZATION,
  SET_FORM_TECHNOLOGY,
} from '../careerPathFormReducer/CareerPathFormReducer.types';

type SetSpecializationType = (payload: NonNullable<SpecializationState>) => FormAction;
type SetAvailabilityType = (payload: NonNullable<AvailabilityState>) => FormAction;
type SetPositionType = (payload: NonNullable<PositionState>) => FormAction;
type SetSoftSkillsType = (payload: NonNullable<SoftSkillsState>) => FormAction;
type SetEducationType = (payload: NonNullable<EducationState>) => FormAction;
type SetFillType = (payload: NonNullable<FillState>) => FormAction;
type SetTechnologyType = (payload: NonNullable<TechnologyState>) => FormAction;
type SetExperienceType = (payload: NonNullable<ExperienceState>) => FormAction;
type SetPortfolioSocialMediaType = (payload: NonNullable<PortfolioSocialMediaState>) => FormAction;
type SetLanguagesType = (payload: NonNullable<LanguagesState>) => FormAction;
type ResetFormType = () => FormAction;

export const setSpecialization: SetSpecializationType = (specialization) => ({
  type: SET_FORM_SPECIALIZATION,
  payload: specialization,
});

export const setAvailability: SetAvailabilityType = (availability) => ({
  type: SET_FORM_AVAILABILITY,
  payload: availability,
});

export const setPosition: SetPositionType = (position) => ({
  type: SET_FORM_POSITION,
  payload: position,
});

export const setSoftSkills: SetSoftSkillsType = (soft_skills) => ({
  type: SET_FORM_SOFTSKILLS,
  payload: soft_skills,
});

export const setEducation: SetEducationType = (education) => ({
  type: SET_FORM_EDUCATION,
  payload: education,
});

export const setExperience: SetExperienceType = (experience) => ({
  type: SET_FORM_EXPERIENCE,
  payload: experience,
});

export const setFill: SetFillType = (fill) => ({
  type: SET_FORM_FILL,
  payload: fill,
});

export const setTechnology: SetTechnologyType = (technology) => ({
  type: SET_FORM_TECHNOLOGY,
  payload: technology,
});

export const setPortfolioSocialMedia: SetPortfolioSocialMediaType = (portfolio_socialmedia) => ({
  type: SET_FORM_PORTFOLIO_SOCIAL_MEDIA,
  payload: portfolio_socialmedia,
});

export const setLanguages: SetLanguagesType = (languages) => ({
  type: SET_FORM_LANGUAGES,
  payload: languages,
});

export const resetForm: ResetFormType = () => ({
  type: RESET_FORM,
  payload: undefined,
});
