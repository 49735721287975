import { Currency } from '@hrme/shared/src/types/Currency.types';

import { FirebaseBasicResponse } from 'api/Firebase.types';

export type JobOfferContextProps = {
  fetchJobOffers: () => Promise<FirebaseBasicResponse>;
  updateJobStatus: (data: UpdateJobStatus) => Promise<FirebaseBasicResponse>;
  loading: boolean;
  jobOffers: JobOffers;
  checkOfferID: (offerID: string) => Promise<FirebaseBasicResponse>;
  jobOfferDetails: JobOffer | null;
};

export enum OfferStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DRAFT = 'draft',
}

export type CompanyData = {
  companyName: string;
  companyAddressLine1: string;
  companyAddressLine2: string;
  country: string;
  city: string;
  postcode: string;
  state: string;
  nip: string;
  industry: string;
  facebook: string;
  linkedin: string;
  otherSocialMedia?: string[];
  companySize: string;
  companyDescription: string;
  predefinedMessage: string;
  logo: string;
};

export type JobOffer = {
  offerStatus: InvitationStatus;
  applicants: number;
  benefits: string[];
  category: string;
  collaborators: string[];
  collectionType: 'matched_candidates' | 'potential_candidates';
  company: string;
  companyCity: string;
  companyId: string;
  companyStreet: string;
  confirmed: boolean;
  score: string;
  generalScore?: string;
  growthScore: string;
  contractType: string[];
  createdAt: { seconds: number; nanoseconds: number };
  created: string;
  currency: Currency;
  companyData: CompanyData;
  dailyTasks: string[];
  endDate: string;
  experienceTime: string;
  experienceType: string;
  hireAsap: true;
  hireSince: string;
  interview: string;
  jobOfferRef: string;
  language: string[];
  status: OfferStatus;
  mainTechnologies: string[];
  methodology: string[];
  offersAccepted: boolean;
  offersSent: boolean;
  officeHours: string;
  officeHoursFromHours: number;
  officeHoursFromMinutes: number;
  officeHoursToHours: number;
  officeHoursToMinutes: number;
  otherContractType: string;
  otherPlaceOfWork: string;
  otherWorkingTime: string;
  paidLeave: boolean;
  personalGrowth: string[];
  placeOfWork: string;
  position: string;
  positionDescription: string;
  positionTechnologies: string[];
  relocationPackage: boolean;
  salaryOnHand?: boolean;
  isNetto?: boolean;
  salaryRange: boolean;
  salaryRangeFrom: number;
  salaryRangeTo: number;
  userId: string;
  valueLanguageLevel: string;
  validUntil: number;
  welcomeTechnologies: string[];
  willLearn: string[];
  willLearnTechnologies: string[];
  workingTime: string;
};

export type JobOffers = JobOffer[];

export enum InvitationStatus {
  WAITS = 'waits',
  SENT = 'sent',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  PAID = 'paid',
}

export type UpdateJobStatus = {
  jobOfferRef: string;
  status: InvitationStatus;
  userName: string;
  collection: 'matched_candidates' | 'potential_candidates';
};
