import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(4),
  },
  textInput: {
    backgroundColor: theme.palette.background.paper,
  },
  periodContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `1fr 1fr`,
    columnGap: theme.spacing(2),
  },
}));
