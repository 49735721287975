import { makeStyles } from '@material-ui/core';
import { CONTAINER_WIDTH_TABLET } from '@hrme/shared/lib/theme/theme';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'start',
    // padding: theme.spacing(0, 3),
    maxWidth: CONTAINER_WIDTH_TABLET,
    // padding: theme.spacing(0, 3),
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
      padding: 0,
    },
  },
  header: {
    fontWeight: 600,
  },
  innerContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridColumnGap: theme.spacing(3),
    gridRowGap: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
