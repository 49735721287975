export const autocompleteSuggestions = [
  { item: 'Git', category: 'all', popularity: 30 },
  { item: 'JavaScript', category: 'all', popularity: 25 },
  { item: 'Docker', category: 'all', popularity: 24 },
  { item: 'Java', category: 'all', popularity: 24 },
  { item: 'SQL', category: 'all', popularity: 22 },
  { item: 'REST', category: 'all', popularity: 20 },
  { item: 'Python', category: 'all', popularity: 20 },
  { item: 'React', category: 'all', popularity: 18 },
  { item: 'AWS', category: 'all', popularity: 18 },
  { item: 'TypeScript', category: 'all', popularity: 15 },
  { item: 'CSS', category: 'all', popularity: 14 },
  { item: 'Linux', category: 'all', popularity: 13 },
  { item: 'HTML', category: 'all', popularity: 13 },
  { item: 'Spring', category: 'all', popularity: 13 },
  { item: 'Angular', category: 'all', popularity: 12 },
  { item: 'Kubernetes', category: 'all', popularity: 11 },
  { item: 'JIRA', category: 'all', popularity: 11 },
  { item: 'Azure', category: 'all', popularity: 11 },
  { item: '.NET', category: 'all', popularity: 10 },
  { item: 'Leadership skills', category: 'all', popularity: 9.3 },
  { item: 'Docker', category: 'DevOps', popularity: 63 },
  { item: 'AWS', category: 'DevOps', popularity: 59 },
  { item: 'Linux', category: 'DevOps', popularity: 55 },
  { item: 'Kubernetes', category: 'DevOps', popularity: 52 },
  { item: 'Python', category: 'DevOps', popularity: 50 },
  { item: 'Terraform', category: 'DevOps', popularity: 47 },
  { item: 'Git', category: 'DevOps', popularity: 38 },
  { item: 'Jenkins', category: 'DevOps', popularity: 35 },
  { item: 'Ansible', category: 'DevOps', popularity: 35 },
  { item: 'Azure', category: 'DevOps', popularity: 31 },
  { item: 'CI/CD', category: 'DevOps', popularity: 19 },
  { item: 'Java', category: 'DevOps', popularity: 14 },
  { item: 'Shell', category: 'DevOps', popularity: 14 },
  { item: 'Bash', category: 'DevOps', popularity: 13 },
  { item: 'GCP', category: 'DevOps', popularity: 11 },
  { item: 'Puppet', category: 'DevOps', popularity: 11 },
  { item: 'Virtualization', category: 'DevOps', popularity: 10 },
  { item: 'PostgreSQL', category: 'DevOps', popularity: 9.7 },
  { item: 'Golang', category: 'DevOps', popularity: 8.4 },
  { item: 'Nginx', category: 'DevOps', popularity: 8.4 },
  { item: 'Selenium', category: 'QA/Testing', popularity: 44 },
  { item: 'JIRA', category: 'QA/Testing', popularity: 42 },
  { item: 'Java', category: 'QA/Testing', popularity: 37 },
  { item: 'SQL', category: 'QA/Testing', popularity: 35 },
  { item: 'Python', category: 'QA/Testing', popularity: 28 },
  { item: 'REST', category: 'QA/Testing', popularity: 27 },
  { item: 'Postman', category: 'QA/Testing', popularity: 27 },
  { item: 'JavaScript', category: 'QA/Testing', popularity: 26 },
  { item: 'Git', category: 'QA/Testing', popularity: 24 },
  { item: 'Jenkins', category: 'QA/Testing', popularity: 20 },
  { item: 'Docker', category: 'QA/Testing', popularity: 15 },
  { item: 'Linux', category: 'QA/Testing', popularity: 12 },
  { item: 'Cypress', category: 'QA/Testing', popularity: 11 },
  { item: 'Test Automation', category: 'QA/Testing', popularity: 10 },
  { item: 'C#', category: 'QA/Testing', popularity: 9.9 },
  { item: 'SoapUI', category: 'QA/Testing', popularity: 9.6 },
  { item: '.NET', category: 'QA/Testing', popularity: 9.2 },
  { item: 'Agile', category: 'QA/Testing', popularity: 8.5 },
  { item: 'Scrum', category: 'QA/Testing', popularity: 7.4 },
  { item: 'ISTQB', category: 'QA/Testing', popularity: 7.4 },
  { item: 'JavaScript', category: 'Fullstack', popularity: 55 },
  { item: 'React', category: 'Fullstack', popularity: 46 },
  { item: 'Angular', category: 'Fullstack', popularity: 38 },
  { item: 'Git', category: 'Fullstack', popularity: 35 },
  { item: 'Java', category: 'Fullstack', popularity: 35 },
  { item: 'CSS', category: 'Fullstack', popularity: 33 },
  { item: 'HTML', category: 'Fullstack', popularity: 32 },
  { item: 'TypeScript', category: 'Fullstack', popularity: 31 },
  { item: 'Docker', category: 'Fullstack', popularity: 30 },
  { item: '.NET', category: 'Fullstack', popularity: 29 },
  { item: 'REST', category: 'Fullstack', popularity: 28 },
  { item: 'SQL', category: 'Fullstack', popularity: 27 },
  { item: 'Spring', category: 'Fullstack', popularity: 25 },
  { item: 'Node.js', category: 'Fullstack', popularity: 19 },
  { item: 'AWS', category: 'Fullstack', popularity: 19 },
  { item: 'Python', category: 'Fullstack', popularity: 18 },
  { item: 'Azure', category: 'Fullstack', popularity: 14 },
  { item: 'C#', category: 'Fullstack', popularity: 14 },
  { item: 'PHP', category: 'Fullstack', popularity: 13 },
  { item: 'MySQL', category: 'Fullstack', popularity: 12 },
  { item: 'JavaScript', category: 'Frontend', popularity: 81 },
  { item: 'React', category: 'Frontend', popularity: 68 },
  { item: 'TypeScript', category: 'Frontend', popularity: 64 },
  { item: 'CSS', category: 'Frontend', popularity: 59 },
  { item: 'HTML', category: 'Frontend', popularity: 56 },
  { item: 'Angular', category: 'Frontend', popularity: 41 },
  { item: 'Git', category: 'Frontend', popularity: 40 },
  { item: 'REST', category: 'Frontend', popularity: 26 },
  { item: 'Node.js', category: 'Frontend', popularity: 23 },
  { item: 'Redux', category: 'Frontend', popularity: 20 },
  { item: 'Vue.js', category: 'Frontend', popularity: 20 },
  { item: 'webpack', category: 'Frontend', popularity: 17 },
  { item: 'Sass', category: 'Frontend', popularity: 17 },
  { item: 'Docker', category: 'Frontend', popularity: 12 },
  { item: 'RxJS', category: 'Frontend', popularity: 11 },
  { item: 'GraphQL', category: 'Frontend', popularity: 8.5 },
  { item: 'AWS', category: 'Frontend', popularity: 8 },
  { item: 'Leadership skills', category: 'Frontend', popularity: 8 },
  { item: 'React Native', category: 'Frontend', popularity: 8 },
  { item: 'Less', category: 'Frontend', popularity: 7 },
  { item: 'SQL', category: 'Support', popularity: 38 },
  { item: 'Linux', category: 'Support', popularity: 29 },
  { item: 'Windows', category: 'Support', popularity: 27 },
  { item: 'Active Directory', category: 'Support', popularity: 23 },
  { item: 'Microsoft', category: 'Support', popularity: 18 },
  { item: 'German', category: 'Support', popularity: 14 },
  { item: 'VMware', category: 'Support', popularity: 14 },
  { item: 'ITIL', category: 'Support', popularity: 12 },
  { item: 'SAP', category: 'Support', popularity: 12 },
  { item: 'Security', category: 'Support', popularity: 11 },
  { item: 'Windows Server', category: 'Support', popularity: 11 },
  { item: 'Docker', category: 'Support', popularity: 9.1 },
  { item: 'Azure', category: 'Support', popularity: 7.6 },
  { item: 'MS Office', category: 'Support', popularity: 7.6 },
  { item: 'JavaScript', category: 'Support', popularity: 6.1 },
  { item: 'Python', category: 'Support', popularity: 6.1 },
  { item: 'HTML', category: 'Support', popularity: 6.1 },
  { item: 'JIRA', category: 'Support', popularity: 6.1 },
  { item: 'MySQL', category: 'Support', popularity: 6.1 },
  { item: 'Mac OS', category: 'Support', popularity: 6.1 },
  { item: 'Docker', category: 'Backend', popularity: 42 },
  { item: 'Java', category: 'Backend', popularity: 42 },
  { item: 'Git', category: 'Backend', popularity: 40 },
  { item: 'REST', category: 'Backend', popularity: 33 },
  { item: 'Spring', category: 'Backend', popularity: 33 },
  { item: 'SQL', category: 'Backend', popularity: 29 },
  { item: 'AWS', category: 'Backend', popularity: 27 },
  { item: 'Python', category: 'Backend', popularity: 20 },
  { item: 'PostgreSQL', category: 'Backend', popularity: 18 },
  { item: '.NET', category: 'Backend', popularity: 16 },
  { item: 'Kubernetes', category: 'Backend', popularity: 16 },
  { item: 'JavaScript', category: 'Backend', popularity: 15 },
  { item: 'PHP', category: 'Backend', popularity: 13 },
  { item: 'Hibernate', category: 'Backend', popularity: 13 },
  { item: 'Linux', category: 'Backend', popularity: 12 },
  { item: 'Microservices', category: 'Backend', popularity: 12 },
  { item: 'React', category: 'Backend', popularity: 11 },
  { item: 'Azure', category: 'Backend', popularity: 11 },
  { item: 'MySQL', category: 'Backend', popularity: 11 },
  { item: 'Jenkins', category: 'Backend', popularity: 10 },
  {
    item: 'Business analysis',
    category: 'Business Analytics',
    popularity: 64,
  },
  { item: 'SQL', category: 'Business Analytics', popularity: 51 },
  { item: 'UML', category: 'Business Analytics', popularity: 40 },
  { item: 'JIRA', category: 'Business Analytics', popularity: 35 },
  { item: 'BPMN', category: 'Business Analytics', popularity: 34 },
  {
    item: 'System analysis',
    category: 'Business Analytics',
    popularity: 34,
  },
  {
    item: 'Confluence',
    category: 'Business Analytics',
    popularity: 24,
  },
  { item: 'Excel', category: 'Business Analytics', popularity: 19 },
  {
    item: 'Enterprise Architect',
    category: 'Business Analytics',
    popularity: 16,
  },
  {
    item: 'Leadership skills',
    category: 'Business Analytics',
    popularity: 12,
  },
  { item: 'Python', category: 'Business Analytics', popularity: 8.1 },
  { item: 'Scrum', category: 'Business Analytics', popularity: 8.1 },
  { item: 'Tableau', category: 'Business Analytics', popularity: 7 },
  {
    item: 'Financial business domain',
    category: 'Business Analytics',
    popularity: 5.8,
  },
  { item: 'Agile', category: 'Business Analytics', popularity: 4.7 },
  {
    item: 'Salesforce',
    category: 'Business Analytics',
    popularity: 4.7,
  },
  { item: 'C#', category: 'Business Analytics', popularity: 3.5 },
  {
    item: 'Project management',
    category: 'Business Analytics',
    popularity: 3.5,
  },
  {
    item: 'Machine Learning',
    category: 'Business Analytics',
    popularity: 3.5,
  },
  { item: 'Power BI', category: 'Business Analytics', popularity: 3.5 },
  {
    item: 'Python',
    category: 'Artificial Intelligence',
    popularity: 84,
  },
  {
    item: 'Machine Learning',
    category: 'Artificial Intelligence',
    popularity: 70,
  },
  {
    item: 'Azure',
    category: 'Artificial Intelligence',
    popularity: 41,
  },
  {
    item: 'TensorFlow',
    category: 'Artificial Intelligence',
    popularity: 41,
  },
  {
    item: 'PyTorch',
    category: 'Artificial Intelligence',
    popularity: 35,
  },
  { item: 'AWS', category: 'Artificial Intelligence', popularity: 24 },
  {
    item: 'Scikit-learn',
    category: 'Artificial Intelligence',
    popularity: 24,
  },
  { item: 'SQL', category: 'Artificial Intelligence', popularity: 22 },
  {
    item: 'Cloud',
    category: 'Artificial Intelligence',
    popularity: 16,
  },
  {
    item: 'Docker',
    category: 'Artificial Intelligence',
    popularity: 14,
  },
  { item: 'C++', category: 'Artificial Intelligence', popularity: 14 },
  { item: 'GCP', category: 'Artificial Intelligence', popularity: 14 },
  {
    item: 'Spark',
    category: 'Artificial Intelligence',
    popularity: 14,
  },
  { item: 'R', category: 'Artificial Intelligence', popularity: 14 },
  {
    item: 'Pandas',
    category: 'Artificial Intelligence',
    popularity: 14,
  },
  {
    item: 'OpenCV',
    category: 'Artificial Intelligence',
    popularity: 14,
  },
  { item: 'Git', category: 'Artificial Intelligence', popularity: 11 },
  {
    item: 'Scala',
    category: 'Artificial Intelligence',
    popularity: 11,
  },
  {
    item: 'Keras',
    category: 'Artificial Intelligence',
    popularity: 11,
  },
  {
    item: 'Linux',
    category: 'Artificial Intelligence',
    popularity: 8.1,
  },
  { item: 'Linux', category: 'IT Administration', popularity: 65 },
  { item: 'Windows', category: 'IT Administration', popularity: 29 },
  { item: 'Ansible', category: 'IT Administration', popularity: 21 },
  { item: 'Powershell', category: 'IT Administration', popularity: 17 },
  { item: 'TCP/IP', category: 'IT Administration', popularity: 17 },
  { item: 'JIRA', category: 'IT Administration', popularity: 15 },
  {
    item: 'Virtualization',
    category: 'IT Administration',
    popularity: 15,
  },
  { item: 'Docker', category: 'IT Administration', popularity: 14 },
  { item: 'Azure', category: 'IT Administration', popularity: 14 },
  {
    item: 'Windows Server',
    category: 'IT Administration',
    popularity: 14,
  },
  { item: 'Bash', category: 'IT Administration', popularity: 12 },
  {
    item: 'Active Directory',
    category: 'IT Administration',
    popularity: 12,
  },
  { item: 'Git', category: 'IT Administration', popularity: 11 },
  { item: 'MySQL', category: 'IT Administration', popularity: 11 },
  { item: 'Python', category: 'IT Administration', popularity: 9.1 },
  {
    item: 'Kubernetes',
    category: 'IT Administration',
    popularity: 9.1,
  },
  {
    item: 'PostgreSQL',
    category: 'IT Administration',
    popularity: 9.1,
  },
  {
    item: 'Confluence',
    category: 'IT Administration',
    popularity: 9.1,
  },
  { item: 'MS SQL', category: 'IT Administration', popularity: 9.1 },
  { item: 'SQL', category: 'IT Administration', popularity: 7.6 },
  { item: 'Android', category: 'Mobile', popularity: 70 },
  { item: 'iOS', category: 'Mobile', popularity: 67 },
  { item: 'Git', category: 'Mobile', popularity: 52 },
  { item: 'Kotlin', category: 'Mobile', popularity: 45 },
  { item: 'Swift', category: 'Mobile', popularity: 35 },
  { item: 'Java', category: 'Mobile', popularity: 33 },
  { item: 'REST', category: 'Mobile', popularity: 31 },
  { item: 'MVVM', category: 'Mobile', popularity: 31 },
  { item: 'Objective-C', category: 'Mobile', popularity: 19 },
  { item: 'RxJava', category: 'Mobile', popularity: 19 },
  { item: 'Gradle', category: 'Mobile', popularity: 14 },
  { item: 'Firebase', category: 'Mobile', popularity: 14 },
  { item: 'Flutter', category: 'Mobile', popularity: 12 },
  { item: 'Xcode', category: 'Mobile', popularity: 12 },
  { item: 'Retrofit', category: 'Mobile', popularity: 12 },
  { item: 'JSON', category: 'Mobile', popularity: 11 },
  { item: 'Dagger', category: 'Mobile', popularity: 11 },
  { item: 'CocoaPods', category: 'Mobile', popularity: 11 },
  { item: 'React Native', category: 'Mobile', popularity: 10 },
  { item: 'Unit Testing', category: 'Mobile', popularity: 9.8 },
  { item: 'SQL', category: 'businessIntelligence', popularity: 74 },
  { item: 'ETL', category: 'businessIntelligence', popularity: 56 },
  {
    item: 'Power BI',
    category: 'businessIntelligence',
    popularity: 43,
  },
  { item: 'Python', category: 'businessIntelligence', popularity: 38 },
  { item: 'Azure', category: 'businessIntelligence', popularity: 33 },
  {
    item: 'SQL Server',
    category: 'businessIntelligence',
    popularity: 26,
  },
  { item: 'Tableau', category: 'businessIntelligence', popularity: 20 },
  { item: 'AWS', category: 'businessIntelligence', popularity: 13 },
  { item: 'R', category: 'businessIntelligence', popularity: 13 },
  { item: 'SSIS', category: 'businessIntelligence', popularity: 13 },
  { item: 'Oracle', category: 'businessIntelligence', popularity: 11 },
  {
    item: 'Machine Learning',
    category: 'businessIntelligence',
    popularity: 11,
  },
  { item: 'DWH', category: 'businessIntelligence', popularity: 11 },
  { item: 'DAX', category: 'businessIntelligence', popularity: 9.8 },
  { item: 'Git', category: 'businessIntelligence', popularity: 8.2 },
  { item: 'SSRS', category: 'businessIntelligence', popularity: 8.2 },
  { item: 'SSAS', category: 'businessIntelligence', popularity: 8.2 },
  { item: 'Spark', category: 'businessIntelligence', popularity: 6.6 },
  { item: 'T-SQL', category: 'businessIntelligence', popularity: 6.6 },
  {
    item: 'Azure Data Factory',
    category: 'businessIntelligence',
    popularity: 6.6,
  },
  { item: 'UX', category: 'UX', popularity: 74 },
  { item: 'UI', category: 'UX', popularity: 67 },
  { item: 'Figma', category: 'UX', popularity: 58 },
  { item: 'Sketch', category: 'UX', popularity: 45 },
  { item: 'Visual Design', category: 'UX', popularity: 32 },
  { item: 'Prototyping', category: 'UX', popularity: 28 },
  { item: 'Photoshop', category: 'UX', popularity: 28 },
  { item: 'Wireframing', category: 'UX', popularity: 25 },
  { item: 'Adobe XD', category: 'UX', popularity: 23 },
  { item: 'Illustrator', category: 'UX', popularity: 20 },
  { item: 'CSS', category: 'UX', popularity: 14 },
  { item: 'HTML', category: 'UX', popularity: 14 },
  { item: 'Mobile', category: 'UX', popularity: 14 },
  { item: 'InVision', category: 'UX', popularity: 14 },
  { item: 'Zeplin', category: 'UX', popularity: 12 },
  { item: 'Axure', category: 'UX', popularity: 12 },
  { item: 'Leadership skills', category: 'UX', popularity: 8.7 },
  { item: 'JIRA', category: 'UX', popularity: 7.2 },
  { item: 'design thinking', category: 'UX', popularity: 7.2 },
  { item: 'Product design', category: 'UX', popularity: 5.8 },
  { item: 'C++', category: 'Embedded', popularity: 76 },
  { item: 'C', category: 'Embedded', popularity: 67 },
  { item: 'Linux', category: 'Embedded', popularity: 58 },
  { item: 'Python', category: 'Embedded', popularity: 38 },
  { item: 'Git', category: 'Embedded', popularity: 24 },
  { item: 'JIRA', category: 'Embedded', popularity: 16 },
  { item: 'German', category: 'Embedded', popularity: 13 },
  { item: 'SVN', category: 'Embedded', popularity: 13 },
  { item: 'Embedded', category: 'Embedded', popularity: 13 },
  { item: 'Autosar', category: 'Embedded', popularity: 13 },
  { item: 'Docker', category: 'Embedded', popularity: 8.9 },
  { item: 'Networking', category: 'Embedded', popularity: 8.9 },
  { item: 'RTOS', category: 'Embedded', popularity: 8.9 },
  { item: 'Yocto', category: 'Embedded', popularity: 8.9 },
  { item: 'Linux Kernel', category: 'Embedded', popularity: 8.9 },
  { item: 'Security', category: 'Embedded', popularity: 6.7 },
  { item: 'CMake', category: 'Embedded', popularity: 6.7 },
  { item: 'Routing', category: 'Embedded', popularity: 6.7 },
  { item: 'Java', category: 'Embedded', popularity: 4.4 },
  { item: 'Leadership skills', category: 'Embedded', popularity: 4.4 },
  { item: 'Python', category: 'Other', popularity: 21 },
  { item: 'Organizational skills', category: 'Other', popularity: 17 },
  { item: 'Leadership skills', category: 'Other', popularity: 16 },
  { item: 'Project management', category: 'Other', popularity: 16 },
  { item: 'Salesforce', category: 'Other', popularity: 16 },
  { item: 'Git', category: 'Other', popularity: 14 },
  { item: 'Goal-oriented', category: 'Other', popularity: 14 },
  { item: 'Building relationships', category: 'Other', popularity: 14 },
  { item: 'Java', category: 'Other', popularity: 12 },
  { item: 'Docker', category: 'Other', popularity: 12 },
  { item: 'AWS', category: 'Other', popularity: 12 },
  { item: 'SQL', category: 'Other', popularity: 10 },
  { item: 'Azure', category: 'Other', popularity: 10 },
  { item: 'Terraform', category: 'Other', popularity: 10 },
  { item: 'JavaScript', category: 'Other', popularity: 8.6 },
  { item: 'CSS', category: 'Other', popularity: 8.6 },
  { item: 'Linux', category: 'Other', popularity: 8.6 },
  { item: 'HTML', category: 'Other', popularity: 8.6 },
  { item: 'JIRA', category: 'Other', popularity: 8.6 },
  { item: 'Jenkins', category: 'Other', popularity: 8.6 },
  {
    item: 'Product Management',
    category: 'Product Management',
    popularity: 71,
  },
  {
    item: 'Cooperation with stakeholders',
    category: 'Product Management',
    popularity: 51,
  },
  {
    item: 'Product vision',
    category: 'Product Management',
    popularity: 43,
  },
  {
    item: 'Leadership skills',
    category: 'Product Management',
    popularity: 41,
  },
  {
    item: 'Backlog management',
    category: 'Product Management',
    popularity: 39,
  },
  {
    item: 'Business analysis',
    category: 'Product Management',
    popularity: 37,
  },
  {
    item: 'Defining business requirements',
    category: 'Product Management',
    popularity: 35,
  },
  { item: 'Scrum', category: 'Product Management', popularity: 24 },
  { item: 'Agile', category: 'Product Management', popularity: 24 },
  {
    item: 'Market analytics',
    category: 'Product Management',
    popularity: 20,
  },
  { item: 'JIRA', category: 'Product Management', popularity: 12 },
  {
    item: 'Confluence',
    category: 'Product Management',
    popularity: 9.8,
  },
  {
    item: 'Budget management',
    category: 'Product Management',
    popularity: 9.8,
  },
  { item: 'SQL', category: 'Product Management', popularity: 7.8 },
  {
    item: 'Project management',
    category: 'Product Management',
    popularity: 7.8,
  },
  { item: 'SDLC', category: 'Product Management', popularity: 7.8 },
  { item: 'SaaS', category: 'Product Management', popularity: 5.9 },
  {
    item: 'Organizational skills',
    category: 'Product Management',
    popularity: 5.9,
  },
  { item: 'Java', category: 'Product Management', popularity: 3.9 },
  { item: 'Cloud', category: 'Product Management', popularity: 3.9 },
  {
    item: 'Project management',
    category: 'Project Management',
    popularity: 75,
  },
  { item: 'Scrum', category: 'Project Management', popularity: 54 },
  { item: 'JIRA', category: 'Project Management', popularity: 53 },
  {
    item: 'Team management',
    category: 'Project Management',
    popularity: 50,
  },
  {
    item: 'Leadership skills',
    category: 'Project Management',
    popularity: 43,
  },
  {
    item: 'Defining priorities',
    category: 'Project Management',
    popularity: 38,
  },
  {
    item: 'Personal organization',
    category: 'Project Management',
    popularity: 35,
  },
  {
    item: 'Business analysis',
    category: 'Project Management',
    popularity: 29,
  },
  {
    item: 'Risk management',
    category: 'Project Management',
    popularity: 26,
  },
  {
    item: 'Backlog management',
    category: 'Project Management',
    popularity: 22,
  },
  {
    item: 'Confluence',
    category: 'Project Management',
    popularity: 19,
  },
  { item: 'Agile', category: 'Project Management', popularity: 15 },
  { item: 'Kanban', category: 'Project Management', popularity: 13 },
  { item: 'UX', category: 'Project Management', popularity: 10 },
  { item: 'SQL', category: 'Project Management', popularity: 4.4 },
  { item: 'Asana', category: 'Project Management', popularity: 4.4 },
  {
    item: 'JavaScript',
    category: 'Project Management',
    popularity: 2.9,
  },
  { item: 'Git', category: 'Project Management', popularity: 2.9 },
  { item: 'Azure', category: 'Project Management', popularity: 2.9 },
  { item: 'Oracle', category: 'Project Management', popularity: 2.9 },
  { item: 'SQL', category: 'Data Engineering', popularity: 66 },
  { item: 'Python', category: 'Data Engineering', popularity: 66 },
  { item: 'Spark', category: 'Data Engineering', popularity: 44 },
  { item: 'AWS', category: 'Data Engineering', popularity: 35 },
  {
    item: 'Data Engineering',
    category: 'Data Engineering',
    popularity: 35,
  },
  { item: 'ETL', category: 'Data Engineering', popularity: 30 },
  { item: 'Java', category: 'Data Engineering', popularity: 29 },
  { item: 'Scala', category: 'Data Engineering', popularity: 25 },
  { item: 'Airflow', category: 'Data Engineering', popularity: 24 },
  { item: 'Hadoop', category: 'Data Engineering', popularity: 19 },
  { item: 'Azure', category: 'Data Engineering', popularity: 16 },
  { item: 'Kubernetes', category: 'Data Engineering', popularity: 15 },
  { item: 'Kafka', category: 'Data Engineering', popularity: 15 },
  { item: 'GCP', category: 'Data Engineering', popularity: 15 },
  { item: 'Git', category: 'Data Engineering', popularity: 14 },
  { item: 'NoSQL', category: 'Data Engineering', popularity: 14 },
  { item: 'Docker', category: 'Data Engineering', popularity: 8.8 },
  { item: 'MongoDB', category: 'Data Engineering', popularity: 8.8 },
  { item: 'MySQL', category: 'Data Engineering', popularity: 7.5 },
  {
    item: 'Leadership skills',
    category: 'Data Engineering',
    popularity: 7.5,
  },
  { item: 'B2B sales', category: 'sales', popularity: 75 },
  { item: 'Sales experience', category: 'sales', popularity: 56 },
  { item: 'Building relationships', category: 'sales', popularity: 44 },
  { item: 'Deals closing', category: 'sales', popularity: 44 },
  { item: 'CRM', category: 'sales', popularity: 38 },
  { item: 'Goal-oriented', category: 'sales', popularity: 31 },
  { item: 'Sales skills', category: 'sales', popularity: 31 },
  { item: 'Client service', category: 'sales', popularity: 31 },
  { item: 'Presentation skills', category: 'sales', popularity: 25 },
  { item: 'Negotiation skills', category: 'sales', popularity: 19 },
  { item: 'Business culture', category: 'sales', popularity: 19 },
  { item: 'HR experience', category: 'sales', popularity: 13 },
  { item: 'IT Knowledge', category: 'sales', popularity: 13 },
  { item: 'Data Center', category: 'sales', popularity: 13 },
  { item: 'Cloud', category: 'sales', popularity: 6.3 },
  { item: 'Salesforce', category: 'sales', popularity: 6.3 },
  { item: 'Software Architecture', category: 'sales', popularity: 6.3 },
  { item: 'MS Office', category: 'sales', popularity: 6.3 },
  { item: 'Organizational skills', category: 'sales', popularity: 6.3 },
  { item: 'integration', category: 'sales', popularity: 6.3 },
  { item: 'Digital Marketing', category: 'marketing', popularity: 46 },
  {
    item: 'Marketing campaigns',
    category: 'marketing',
    popularity: 46,
  },
  { item: 'Google Analytics', category: 'marketing', popularity: 38 },
  { item: 'Goal-oriented', category: 'marketing', popularity: 38 },
  { item: 'SEO', category: 'marketing', popularity: 38 },
  { item: 'E-commerce', category: 'marketing', popularity: 31 },
  { item: 'Leadership skills', category: 'marketing', popularity: 23 },
  { item: 'Social Media', category: 'marketing', popularity: 23 },
  { item: 'SEM', category: 'marketing', popularity: 23 },
  { item: 'Google Adwords', category: 'marketing', popularity: 23 },
  {
    item: 'Marketing Automation',
    category: 'marketing',
    popularity: 23,
  },
  { item: 'Copywriting', category: 'marketing', popularity: 15 },
  {
    item: 'nastawienie na realizację wyznaczonych celów',
    category: 'marketing',
    popularity: 15,
  },
  { item: 'Facebook Ads', category: 'marketing', popularity: 15 },
  { item: 'SQL', category: 'marketing', popularity: 7.7 },
  { item: 'Python', category: 'marketing', popularity: 7.7 },
  { item: 'Power BI', category: 'marketing', popularity: 7.7 },
  { item: 'Excel', category: 'marketing', popularity: 7.7 },
  { item: 'SaaS', category: 'marketing', popularity: 7.7 },
  { item: 'MS Office', category: 'marketing', popularity: 7.7 },
  { item: 'Security', category: 'Security', popularity: 92 },
  { item: 'Linux', category: 'Security', popularity: 34 },
  { item: 'Python', category: 'Security', popularity: 25 },
  { item: 'AWS', category: 'Security', popularity: 25 },
  { item: 'Azure', category: 'Security', popularity: 19 },
  { item: 'CISSP', category: 'Security', popularity: 19 },
  { item: 'Bash', category: 'Security', popularity: 13 },
  { item: 'Windows Server', category: 'Security', popularity: 13 },
  { item: 'SIEM', category: 'Security', popularity: 13 },
  { item: 'CEH', category: 'Security', popularity: 13 },
  { item: 'Kubernetes', category: 'Security', popularity: 11 },
  { item: 'Networks', category: 'Security', popularity: 11 },
  { item: 'IDS', category: 'Security', popularity: 11 },
  { item: 'WAF', category: 'Security', popularity: 11 },
  { item: 'CCSP', category: 'Security', popularity: 11 },
  { item: 'Java', category: 'Security', popularity: 9.4 },
  { item: 'Docker', category: 'Security', popularity: 9.4 },
  { item: 'IPS', category: 'Security', popularity: 9.4 },
  { item: 'CISA', category: 'Security', popularity: 9.4 },
  { item: 'SQL', category: 'Security', popularity: 5.7 },
  { item: 'Agile', category: 'Agile', popularity: 100 },
  { item: 'Scrum', category: 'Agile', popularity: 88 },
  { item: 'JIRA', category: 'Agile', popularity: 42 },
  { item: 'Kanban', category: 'Agile', popularity: 38 },
  { item: 'Leadership skills', category: 'Agile', popularity: 27 },
  { item: 'Confluence', category: 'Agile', popularity: 19 },
  { item: 'SAFe', category: 'Agile', popularity: 15 },
  { item: 'Facilitation', category: 'Agile', popularity: 15 },
  { item: 'Project management', category: 'Agile', popularity: 12 },
  { item: 'Java', category: 'Agile', popularity: 3.8 },
  { item: 'UX', category: 'Agile', popularity: 3.8 },
  { item: 'Azure DevOps', category: 'Agile', popularity: 3.8 },
  { item: 'ITIL', category: 'Agile', popularity: 3.8 },
  { item: 'GitHub', category: 'Agile', popularity: 3.8 },
  { item: 'Personal organization', category: 'Agile', popularity: 3.8 },
  { item: 'Mobile Apps', category: 'Agile', popularity: 3.8 },
  { item: 'Lean', category: 'Agile', popularity: 3.8 },
  { item: 'Interpersonal skills', category: 'Agile', popularity: 3.8 },
  { item: 'Trello', category: 'Agile', popularity: 3.8 },
  {
    item: 'problem-solving skills',
    category: 'Agile',
    popularity: 3.8,
  },
  { item: 'Recruitment experience', category: 'hr', popularity: 82 },
  { item: 'Sourcing', category: 'hr', popularity: 76 },
  { item: 'HR experience', category: 'hr', popularity: 35 },
  { item: 'Employer Branding', category: 'hr', popularity: 29 },
  { item: 'ATS', category: 'hr', popularity: 29 },
  { item: 'Negotiation skills', category: 'hr', popularity: 24 },
  { item: 'Employment law', category: 'hr', popularity: 12 },
  { item: 'Candidate experience', category: 'hr', popularity: 12 },
  { item: 'JIRA', category: 'hr', popularity: 5.9 },
  { item: 'German', category: 'hr', popularity: 5.9 },
  { item: 'Teambuilding', category: 'hr', popularity: 5.9 },
  { item: 'Coaching', category: 'hr', popularity: 5.9 },
  { item: 'Payroll experience', category: 'hr', popularity: 5.9 },
  { item: 'Conflict resolution', category: 'hr', popularity: 5.9 },
  {
    item: 'Znajomość języka angielskiego na poziomie min.B2',
    category: 'hr',
    popularity: 5.9,
  },
  { item: 'HR Analytics', category: 'hr', popularity: 5.9 },
  { item: 'HRBP Experience', category: 'hr', popularity: 5.9 },
  { item: 'Passion for HR', category: 'hr', popularity: 5.9 },
  { item: 'Student status', category: 'hr', popularity: 5.9 },
  {
    item: 'doskonała organizacja swojej pracy',
    category: 'hr',
    popularity: 5.9,
  },
  {
    item: 'Unreal Engine',
    category: 'Game Development',
    popularity: 63,
  },
  { item: 'Unity', category: 'Game Development', popularity: 50 },
  { item: 'C#', category: 'Game Development', popularity: 38 },
  { item: 'C++', category: 'Game Development', popularity: 38 },
  { item: 'JavaScript', category: 'Game Development', popularity: 25 },
  { item: 'Java', category: 'Game Development', popularity: 13 },
  { item: 'CSS', category: 'Game Development', popularity: 13 },
  { item: '.NET', category: 'Game Development', popularity: 13 },
  { item: 'TDD', category: 'Game Development', popularity: 13 },
  { item: 'OOP', category: 'Game Development', popularity: 13 },
  { item: 'Clean Code', category: 'Game Development', popularity: 13 },
  {
    item: 'Design Patterns',
    category: 'Game Development',
    popularity: 13,
  },
  { item: 'CI', category: 'Game Development', popularity: 13 },
  { item: 'Mobile', category: 'Game Development', popularity: 13 },
  { item: 'CD', category: 'Game Development', popularity: 13 },
  { item: 'adobe', category: 'Game Development', popularity: 13 },
  {
    item: 'Unreal Engine 4',
    category: 'Game Development',
    popularity: 13,
  },
  { item: 'objectivec', category: 'Game Development', popularity: 13 },
  { item: 'Git', category: 'Game Development', popularity: 0 },
  { item: 'Docker', category: 'Game Development', popularity: 0 },
  { item: 'Excel', category: 'backoffice', popularity: 100 },
  { item: 'MS Office', category: 'backoffice', popularity: 100 },
  {
    item: 'Accounting experience',
    category: 'backoffice',
    popularity: 100,
  },
  { item: 'Goal-oriented', category: 'backoffice', popularity: 50 },
  { item: 'Data analysis', category: 'backoffice', popularity: 50 },
  { item: 'Autonomy', category: 'backoffice', popularity: 50 },
  { item: 'MS Windows', category: 'backoffice', popularity: 50 },
  {
    item: 'Financial planning',
    category: 'backoffice',
    popularity: 50,
  },
  { item: 'JavaScript', category: 'backoffice', popularity: 0 },
  { item: 'Git', category: 'backoffice', popularity: 0 },
  { item: 'Java', category: 'backoffice', popularity: 0 },
  { item: 'Docker', category: 'backoffice', popularity: 0 },
  { item: 'SQL', category: 'backoffice', popularity: 0 },
  { item: 'Python', category: 'backoffice', popularity: 0 },
  { item: 'AWS', category: 'backoffice', popularity: 0 },
  { item: 'React', category: 'backoffice', popularity: 0 },
  { item: 'TypeScript', category: 'backoffice', popularity: 0 },
  { item: 'CSS', category: 'backoffice', popularity: 0 },
  { item: 'Linux', category: 'backoffice', popularity: 0 },
  { item: 'REST', category: 'backoffice', popularity: 0 },
];
