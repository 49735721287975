import React, { useEffect } from 'react';
import { DateInput, Link, TextInput } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';

import { TagInputExtended } from 'ui/inputs/tagInputExtended/TagInputExtended';

import { useStyles } from './TrainingItem.styles';
import { TrainingItemProps } from './TrainingItem.types';

export const TrainingItem: React.FC<TrainingItemProps> = ({ methods, index, prefix, training, deleteItem }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { register, setValue, control, watch } = methods;

  const minDateToPick = `${prefix}.dateStarted`;
  const minDateToPickValue: string = watch(minDateToPick);

  useEffect(() => {
    setValue(`${prefix}.training`, training.training);
    setValue(`${prefix}.company`, training.company);
    setValue(`${prefix}.skillsLearned`, training.skillsLearned);
    setValue(`${prefix}.dateStarted`, training.dateStarted);
    setValue(`${prefix}.dateEnded`, training.dateEnded);
    setValue(`${prefix}.trainingTime`, training.trainingTime);
  }, []);

  return (
    <div className={classes.container}>
      <TextInput
        label="nazwa szkolenia"
        placeholder="Podaj nazwę szkolenia"
        name={`${prefix}.training`}
        ref={register}
      />
      <TextInput
        label="nazwa firmy organizującej szkolenie"
        placeholder="Podaj nazwę firmy"
        name={`${prefix}.company`}
        ref={register}
      />
      <TagInputExtended
        label={'czego nauczyłeś się na szkoleniu?'}
        items={training.skillsLearned.map((el, index) => {
          return { key: index, label: el };
        })}
        name={`${prefix}.skillsLearned`}
        subtitle={'Jakich technologii i umiejętności dotyczyło szkolenie?'}
        maxItems={99}
        inverse={true}
        tiny={true}
        noMinHeight={true}
        methods={methods}
      />
      <Box mb={3} />
      <div className={classes.periodContainer}>
        <DateInput
          label="rozpoczęcie szkolenia"
          placeholder="DD.MM.RRRR"
          name={`${prefix}.dateStarted`}
          control={control}
        />
        <DateInput
          label="koniec szkolenia"
          placeholder="DD.MM.RRRR"
          name={`${prefix}.dateEnded`}
          control={control}
          minDate={minDateToPickValue}
        />
      </div>
      <Box mb={3} />
      <TextInput
        label="czas szkolenia (w dniach albo w godzinach)"
        placeholder="Podaj liczbę dni albo godzin (np. 3 dni)"
        name={`${prefix}.trainingTime`}
        ref={register}
      />
      <Box mb={2} />
      {deleteItem && (
        <Link>
          <Typography
            onClick={() => {
              if (deleteItem) deleteItem(index);
            }}
          >
            usuń
          </Typography>
        </Link>
      )}
    </div>
  );
};
