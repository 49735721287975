/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { ButtonDefault, Link } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router';

import { MainFlowLayout } from 'ui/layouts/mainFlowLayout/MainFlowLayout';
import { ExperienceItem } from 'ui/experienceItem/ExperienceItem';
import { ButtonOther } from 'ui/buttonOther/ButtonOther';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { Topic } from 'ui/topic/Topic';
import { ExperienceItemType } from 'ui/experienceItem/ExperienceItem.types';
import { checkKeyDown } from 'helpers/checkKeyDown';

import { ADD_EXPERIENCE_ITEM, ExperienceProps } from './Experience.types';
import { useStyles } from './Experience.styles';

export const Experience: React.FC<ExperienceProps> = ({ methods, onFormSubmit }) => {
  const { handleSubmit } = methods;
  const { user } = useUserContext();
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const [experienceItems, setExperienceItems] = useState<ExperienceItemType[]>([]);

  const saveData = () => onFormSubmit(methods.getValues(), undefined, true);

  useEffect(() => {
    methods.reset({ experience: [...experienceItems] });
    methods.setValue('experience', [...experienceItems]);
  }, [experienceItems]);

  useEffect(() => {
    if (user && user.experience) setExperienceItems(user.experience);
  }, [user]);

  const getFormValues = async () => {
    const values = methods.getValues();
    if (values) setExperienceItems(values.experience);
  };

  const addItem = async () => {
    if (experienceItems.length > 0) await getFormValues();
    setExperienceItems((prevState) => {
      const newExperienceItems = [...prevState];
      newExperienceItems.push({ ...ADD_EXPERIENCE_ITEM });

      return [...newExperienceItems];
    });
  };

  const deleteItem = async (index: number) => {
    await methods.reset({ experience: [...experienceItems] });
    await methods.setValue('experience', [...experienceItems]);

    setExperienceItems((prevState) => {
      const newExperienceItems = [...prevState];
      newExperienceItems.splice(index, 1);

      return [...newExperienceItems];
    });

    saveData();
  };

  const stepBack = () => {
    onFormSubmit(methods.getValues(), true);
    history.goBack();
  };

  useEffect(() => {
    if (!user?.experience) saveData();
  }, []);

  return (
    <MainFlowLayout topic={<Topic />} progress={{ label: 'Doświadczenie', length: 10, current: 4 }}>
      <div className={classes.container}>
        <Box mb={3}>
          <Typography variant="h2" className={classes.header}>
            Twoje doświadczenie zawodowe
          </Typography>
        </Box>
        <Box mb={6}>
          <Typography variant="body2" className={classes.subheader}>
            Wpisz swoje doświadczenie zawodowe. Jeżeli go nie posiadasz, przejdź dalej.
          </Typography>
        </Box>
        <form
          className={classes.wrapper}
          onSubmit={handleSubmit((d) => onFormSubmit(d))}
          key={experienceItems.length}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <div className={classes.itemsContainer}>
            {experienceItems.map((item, key) => {
              return (
                <div key={key}>
                  <ExperienceItem
                    methods={methods}
                    prefix={`experience[${key}]`}
                    deleteItem={() => deleteItem(key)}
                    saveOnBlur={saveData}
                    {...item}
                  />
                  {key !== experienceItems.length - 1 && <Box mb={4} />}
                </div>
              );
            })}
          </div>
          <Box mb={4} />
          <ButtonOther onClick={addItem}>dodaj kolejne miejsce pracy</ButtonOther>
          <Box mb={4} />
          <ButtonDefault>Następny krok (technologia)</ButtonDefault>
          <Box mb={2} />
          <Link>
            <Typography onClick={stepBack}>cofnij</Typography>
          </Link>
        </form>
      </div>
    </MainFlowLayout>
  );
};
