import React from 'react';
import { Box, FilledInput, FormControl, IconButton, InputAdornment, OutlinedInput, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { TextInputVariant } from 'ui/textInputExtendableList/TextInputExtendableList.types';

import { TextInputWithCloseProps } from './TextInputWithClose.types';
import { useStyles } from './TextInputWithClose.styles';

export const TextInputWithClose: React.FC<TextInputWithCloseProps> = ({
  value,
  onClick,
  variant = TextInputVariant.White,
}) => {
  const hrMeTheme = useTheme();
  const classes = useStyles(hrMeTheme);

  return (
    <FormControl variant="outlined" fullWidth className={classes.textFieldList}>
      {variant === TextInputVariant.White && (
        <>
          <OutlinedInput
            multiline={true}
            rowsMax={2}
            value={value}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end" onClick={onClick}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
          />
          <Box mb={1} />
        </>
      )}
      {variant === TextInputVariant.Grey && (
        <FilledInput
          className={classes.filledInput}
          multiline={true}
          rowsMax={2}
          value={value}
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end" onClick={onClick}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          }
        />
      )}
    </FormControl>
  );
};
