import { HrMeTheme } from '@hrme/shared/lib/theme/theme';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: HrMeTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    width: '100%',
  },
  wrapper: {
    width: '100%',
  },
  inputLabel: {
    width: '100%',
  },
  label: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    fontWeight: 400,
    color: theme.palette.grey[800],
  },
  formControlLabel: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    margin: 0,
    borderBottom: '1px solid #D8D5DC',
    width: '100%',
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      borderBottom: 'none',
      padding: theme.spacing(0.5, 3, 0.5, 0.5),
      margin: theme.spacing(0, 1.5, 3),
      flexDirection: 'row',
      borderRadius: theme.spacing(3),
    },
  },
  formControlLabel_settings: {
    padding: theme.spacing(0, 3, 0, 0.5),
    margin: theme.spacing(0, 1, 1),
  },
  formControlLabelChecked: {
    [theme.breakpoints.up('md')]: {
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
  },
  formControlLabelBackground: {
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.common.white,
    },
  },
  formControlLabelOutlined: {
    width: '100%',
    borderBottom: '1px solid #D8D5DC',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      border: `1px solid ${theme.borders.main}`,
      borderRadius: theme.spacing(3),
    },
  },
  formControlLabelNoMargin: {
    margin: 0,
  },
  radioGroup: {
    // flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      flexDirection: 'row',
    },
  },
  radioGroup_settings: {
    justifyContent: 'flex-start',
  },
  radioGroupMargin: {},
  radio: {
    paddingLeft: theme.spacing(0.5),
    fontSize: theme.typography.body2.fontSize,
  },
  formControl: {},
}));
