import { BarValues } from 'app/careerPathView/careerPathViewPanelRight/CareerPathViewPanelRight.types';
import { SKILL_LEVEL } from '../findSkillLevelByTechnologyName/findSkillLevelByTechnologyName.constants';
import { findSkillLevelByTechnologyName } from '../findSkillLevelByTechnologyName/findSkillLevelByTechnologyName';

import { FindBarValuesParams } from './findBarValues.types';

export const findBarValues = ({ careerPath, user }: FindBarValuesParams): BarValues<typeof SKILL_LEVEL> => {
  const barValues: BarValues<typeof SKILL_LEVEL> = {
    NONE: 0,
    WANT_TO_LEARN: 0,
    LEARNING: 0,
    KNOWN: 0,
  };

  if (careerPath === undefined || careerPath.technology === undefined) return barValues;

  const { technology } = careerPath;

  technology.forEach((item) => {
    const { name } = item;
    const skillLevel = `${findSkillLevelByTechnologyName({ name, user })}`;
    switch (skillLevel) {
      case SKILL_LEVEL.NONE.selectValue:
        barValues.NONE++;
        break;
      case SKILL_LEVEL.WANT_TO_LEARN.selectValue:
        barValues.WANT_TO_LEARN++;
        break;
      case SKILL_LEVEL.LEARNING.selectValue:
        barValues.LEARNING++;
        break;
      case SKILL_LEVEL.KNOWN.selectValue:
        barValues.KNOWN++;
        break;
      default:
        break;
    }
  });

  return barValues;
};
