import React, { useState } from 'react';

import { CareerPath } from 'context/careerPath/CareerPathContext.types';
import { firebaseRef } from 'api/firebaseRef';
import { onError, onSuccess } from 'providers/providersHelpers';
import { CareerPathContext } from 'context/careerPath/CareerPathContext';

export const CareerPathProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [careerPaths, setCareerPaths] = useState<CareerPath[]>([]);
  const [technologies, setTechnologies] = useState<string[]>([]);

  const fetchCareerPaths = async () => {
    setLoading(true);
    setCareerPaths([]);

    const careerPathQuery = await firebaseRef.firestore().collection('career_paths').get();

    if (careerPathQuery !== null) {
      const careerPathArray = [] as CareerPath[];

      careerPathQuery.forEach((careerPath) => {
        const item = careerPath.data() as CareerPath; // data() doesn't have id

        careerPathArray.push({ ...item, pathRef: careerPath.id });
      });

      setCareerPaths((prevData) => [...prevData, ...careerPathArray]);

      return onSuccess(setLoading, 'Career paths have been successfully fetched');
    } else {
      return onError(setLoading, 'Unable to fetch any career paths');
    }
  };

  const fetchTechnologies = async () => {
    setLoading(true);
    setTechnologies([]);

    const careerPathQuery = await firebaseRef.firestore().collection('career_paths').get();

    if (careerPathQuery !== null) {
      const technologiesArray: string[] = [];

      careerPathQuery.forEach((careerPathsItem) => {
        const careerPath = careerPathsItem.data() as CareerPath;
        careerPath.technology.map((item) => technologiesArray.push(item.name));
      });
      setTechnologies([...technologiesArray]);

      return onSuccess(setLoading, `Technologies' names have been successfully fetched`);
    } else {
      return onError(setLoading, 'Unable to fetch any technology name');
    }
  };

  return (
    <CareerPathContext.Provider value={{ careerPaths, fetchCareerPaths, technologies, fetchTechnologies, loading }}>
      {children}
    </CareerPathContext.Provider>
  );
};
