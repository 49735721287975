import { Dashboard } from 'app/profile/dashboard/Dashboard';
import { ProfileJobOffers } from 'app/profile/profileJobOffers/ProfileJobOffers';
import { JobOfferView } from 'app/jobOfferView/JobOfferView';
import { CareerPathView } from 'app/careerPathView/CareerPathView';
import { TechnologyWrapper } from 'app/common/technologyWrapper/TechnologyWrapper';

import { ProfilePaths } from './ProfilePaths';

export const ProfileRoutes = [
  {
    id: 0,
    path: ProfilePaths.dashboard,
    component: Dashboard,
  },
  {
    id: 1,
    path: ProfilePaths.jobOffers,
    component: ProfileJobOffers,
  },
  {
    id: 2,
    path: ProfilePaths.jobOfferView,
    component: JobOfferView,
  },
  {
    id: 3,
    path: ProfilePaths.careerPathView,
    component: CareerPathView,
  },
  {
    id: 4,
    path: ProfilePaths.profileTechnology,
    component: TechnologyWrapper,
  },
];
