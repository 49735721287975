import React from 'react';
import { Box, useTheme } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/lib/index.types';
import { Link } from 'react-router-dom';

import { PlatformFooterMainProps } from './PlatformFooterMain.types';
import { useStyles } from './PlatformFooterMain.styles';

export const PlatformFooterMain: React.FC<PlatformFooterMainProps> = ({ items }) => {
  const theme = useTheme<HrMeTheme>();
  const classes = useStyles(theme);

  return (
    <div className={classes.footerWrapper}>
      <div className={classes.footer}>
        {' '}
        <ul className={classes.list}>
          {items.map((item, index) => (
            <div className={classes.listItem} key={index}>
              <Link to={item.link} className={classes.linkStyles} target={item.target}>
                {item.label}
              </Link>
              {items.length - 1 !== index && <Box mr={4} />}
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};
