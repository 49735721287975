import React, { useEffect } from 'react';
import { ButtonDefault, Link, TextSelect } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { useHistory } from 'react-router';

import { MainFlowLayout } from 'ui/layouts/mainFlowLayout/MainFlowLayout';
import { Topic } from 'ui/topic/Topic';

import { AVAILABILITY_HOURS, AVAILABILITY_ITEMS, AVAILABILITY_TYPES, AvailabilityProps } from './Availability.types';
import { useStyles } from './Availability.styles';

export const Availability: React.FC<AvailabilityProps> = ({ methods, onFormSubmit }) => {
  const { handleSubmit, setValue } = methods;
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const availabilitySelected = methods.watch(['hours', 'work', 'workType']);
  const { hours, work, workType } = availabilitySelected;

  const notLookingForJob = work === 'none';
  const allFieldsSelected = !!(hours && work && workType) || notLookingForJob;

  useEffect(() => {
    if ((hours || work || workType) === undefined) {
      setValue(`workType`, '');
      setValue(`hours`, '');
      setValue(`work`, '');
    }
  }, []);

  useEffect(() => {
    onFormSubmit(methods.getValues(), undefined, true);
  }, [hours, work, workType]);

  const stepBack = () => {
    onFormSubmit(methods.getValues(), true);
    history.goBack();
  };

  return (
    <MainFlowLayout topic={<Topic />} progress={{ label: 'Dostępność', length: 10, current: 3 }}>
      <form className={classes.container} onSubmit={handleSubmit((d) => onFormSubmit(d))}>
        <div className={classes.wrapper}>
          <Typography variant="h2" className={classes.header}>
            Twoja dostępność do podjęcia pracy
          </Typography>
          <Box mb={3} />
          <Typography variant="body2" className={classes.subheader}>
            {`Określ swoją dostępność do podjęcia nowej pracy.`}
          </Typography>
          <Box mb={6} />

          <Controller
            control={methods.control}
            name={'work'}
            render={({ onChange, value, ref }) => (
              <TextSelect
                defaultValue={value}
                items={AVAILABILITY_ITEMS}
                label="dostępność do podjęcia pracy"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                ref={ref}
              />
            )}
          />
          <Box mb={1.75} />
          {!notLookingForJob && (
            <>
              <Controller
                control={methods.control}
                name={'hours'}
                render={({ onChange, value, ref }) => (
                  <TextSelect
                    defaultValue={value}
                    items={AVAILABILITY_HOURS}
                    label="liczba dostępnych godzin w tygodniu"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    ref={ref}
                  />
                )}
              />
              <Box mb={1.75} />

              <Controller
                control={methods.control}
                name={'workType'}
                render={({ onChange, value, ref }) => (
                  <TextSelect
                    defaultValue={value}
                    items={AVAILABILITY_TYPES}
                    label="preferowany tryb pracy"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    ref={ref}
                  />
                )}
              />
              <Box mb={1.75} />
            </>
          )}
          <ButtonDefault disabled={!allFieldsSelected}>następny krok (doświadczenie)</ButtonDefault>
          <Box mb={2} />
          <Link>
            <Typography onClick={stepBack}>cofnij</Typography>
          </Link>
        </div>
      </form>
    </MainFlowLayout>
  );
};
