import { HrMeTheme } from '@hrme/shared/lib/theme/theme';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: HrMeTheme) => ({
  formGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: -11,
  },
  justify: {
    justifyContent: 'center',
  },
  justify_settings: {
    justifyContent: 'start',
  },
  formControlLabel: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    margin: 0,
    padding: theme.spacing(2, 0),
    width: '100%',
    borderBottom: '1px solid #D8D5DC',
    border: 'none',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0.5, 3, 0.5, 0.5),
      margin: theme.spacing(0, 1.5, 3),
      flexDirection: 'row',
      width: 'auto',
      border: `1px solid ${theme.borders.main}`,
      borderRadius: theme.spacing(3),
    },
  },
  spacings: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0.5, 3, 0.5, 0.5),
      margin: theme.spacing(0, 1.5, 3),
    },
  },
  spacings_settings: {
    padding: theme.spacing(0, 3, 0, 0.5),
    margin: theme.spacing(0, 1, 1),
  },
  formControlLabelChecked: {
    [theme.breakpoints.up('md')]: {
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
  },
  formControlLabelBackground: {
    backgroundColor: theme.palette.background.default,
  },
  checkbox: {
    paddingLeft: theme.spacing(0.5),
    fontSize: theme.typography.body2.fontSize,
  },
  icon: {
    color: theme.borders.main,
  },
}));
