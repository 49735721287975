import React from 'react';
import { FormControl, FormControlLabel, InputLabel, Radio, RadioGroup, Typography, useTheme } from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';

import { RadioChipGroupProps } from './RadioChipGroup.types';
import { useStyles } from './RadioChipGroup.styles';

export const RadioChipGroup: React.FC<RadioChipGroupProps> = ({
  label,
  items,
  background = false,
  noOutline = false,
  noMargin = false,
  name = '',
  methods,
  required = false,
  defaultValue,
  alignItems,
  justifyContent,
  setFieldValid,
  inSettings,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      {label && (
        <InputLabel className={classes.inputLabel}>
          <Typography variant="subtitle1" className={classes.label}>
            <span>{label}</span>
            {required && <span style={{ color: theme.palette.primary.main }}>*</span>}
          </Typography>
        </InputLabel>
      )}
      <FormControl component="fieldset" className={classes.wrapper}>
        <Controller
          control={methods?.control}
          name={name}
          render={({ onChange, value, ref }) => (
            <RadioGroup
              defaultValue={defaultValue}
              className={clsx({
                [inSettings ? classes.radioGroup_settings : classes.radioGroup]: true,
                [classes.radioGroupMargin]: true,
              })}
              style={{ justifyContent, alignItems }}
              row={true}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onClick={(e: any) => {
                if (e.target.value === value && methods) methods.setValue('position_name', 'other');
              }}
              onChange={(e) => {
                onChange(e.target.value);
                if (setFieldValid)
                  setFieldValid((prevState) => ({
                    ...prevState,
                    chosenLevel: e.target.value,
                  }));
              }}
            >
              {items.map((item, index) => (
                <FormControlLabel
                  key={index}
                  className={clsx({
                    [inSettings ? classes.formControlLabel_settings : classes.formControlLabel]: true,
                    [classes.formControlLabelOutlined]: !noOutline,
                    [classes.formControlLabelChecked]: !noOutline && item.value === value,
                    [classes.formControlLabelBackground]: background && item.value !== value,
                    [classes.formControlLabelNoMargin]: noMargin,
                    [classes.formControl]: true,
                  })}
                  checked={value === item.value}
                  value={item.value}
                  control={<Radio checkedIcon={<CheckCircleOutlineOutlinedIcon />} ref={ref} />}
                  label={item.label}
                  classes={{ label: classes.radio }}
                />
              ))}
            </RadioGroup>
          )}
        />
      </FormControl>
    </div>
  );
};
