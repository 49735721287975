import React, { FormEvent } from 'react';
import { Box, Fade, Typography, useTheme } from '@material-ui/core';
import parse from 'html-react-parser';
import { ButtonDefault } from '@hrme/shared';

import { JobOfferRejectionProps } from './JobOfferRejection.types';
import { useStyles } from './JobOfferRejection.styles';

export const JobOfferRejection: React.FC<JobOfferRejectionProps> = ({ isOpen, onClose, onConfirm }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Fade in={isOpen}>
      <div className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          Odrzucenie oferty pracy
        </Typography>
        <Box mb={3} />
        <Typography>
          {parse(
            `Czy na pewno chcesz odrzucić tę ofertę?<br>Pamiętaj, że po odrzuceniu nie będzie<br>możliwości` +
              ` zmiany zdania`,
          )}
        </Typography>
        <Box mb={3} />
        <form onSubmit={handleSubmit}>
          <ButtonDefault>
            <Typography variant="body2">potwierdzam, nie interesuje mnie ta oferta</Typography>
          </ButtonDefault>
          <Box mb={3} />
          <Typography onClick={onClose} variant="body2" className={classes.link}>
            anuluj
          </Typography>
        </form>
      </div>
    </Fade>
  );
};
