import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  typographyStyle: {
    fontSize: '18px',
    fontWeight: 600,
  },
  spanStyle: {
    color: theme.palette.primary.main,
  },
}));
