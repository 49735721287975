import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { AlertMessage } from '@hrme/shared';

import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { useCareerPathFormDispatch } from 'hooks/useCareerPathFormDispatch/useCareerPathFormDispatch';
import { MainPaths } from 'routing/main/MainPaths';
import { setTechnology } from 'context/careerPathForm/careerPathActionCreators/careerPathActionCreators';
import { User } from 'context/user/UserContext.types';
import { removeDupes } from 'helpers/removeDupes';
import { useKnowledge } from 'hooks/useKnowledge/useKnowledge';
import { Technology } from 'app/main/technology/Technology';
import { DragItemType } from 'app/main/technology/board/ItemData';
import { ProfileTechnology } from 'app/profile/profileTechnology/ProfileTechnology';

export const TechnologyWrapper: React.FC = () => {
  const { user, setUserData } = useUserContext();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });
  const { skills } = useKnowledge();
  const location = useLocation();

  const isMainFlow = location.pathname === MainPaths.technology;

  const history = useHistory();

  const known: DragItemType[] | undefined = user?.technology?.known.map((el) => ({ label: el, id: el.toLowerCase() }));

  const learning: DragItemType[] | undefined = user?.technology?.learning.map((el) => ({
    label: el,
    id: el.toLowerCase(),
  }));

  const wantToLearn: DragItemType[] | undefined = user?.technology?.wantToLearn.map((el) => ({
    label: el,
    id: el.toLowerCase(),
  }));

  const techArray = [known ? removeDupes(known) : [], learning ? learning : [], wantToLearn ? wantToLearn : []];

  const [state, setState] = useState<DragItemType[][]>(techArray);

  useEffect(() => {
    if (JSON.stringify(techArray) !== JSON.stringify(state)) {
      setState(techArray);
    }
  }, [user]);

  const dispatch = useCareerPathFormDispatch();

  if (!user) {
    return <Redirect to={MainPaths.welcomeMain} />;
  }

  const onSubmit = async (isBack?: boolean, isIdle?: boolean) => {
    const [currentKnownArray, currentLearningArray, currentWantToLearnArray] = state;
    const skillsCopy = [...skills].map((item) => item.toLowerCase());

    const { technology, known, learning, wantToLearn } = createTechnologyFromCurrentState(
      currentKnownArray,
      currentLearningArray,
      currentWantToLearnArray,
      skillsCopy,
      skills,
    );

    if (technology === user.technology && isMainFlow) {
      history.push(MainPaths.softSkills);

      return;
    }

    const correctedTechnology = [
      known.map((item) => ({ label: item, id: item.toLowerCase() })),
      learning.map((item) => ({ label: item, id: item.toLowerCase() })),
      wantToLearn.map((item) => ({ label: item, id: item.toLowerCase() })),
    ];

    if (JSON.stringify(state) !== JSON.stringify(correctedTechnology)) setState(correctedTechnology);

    const newUser: User = { ...user, technology };

    const result = await setUserData(newUser);

    if (!result.success) {
      return setShowAlertMessage({ show: true, success: false });
    }

    if (isIdle) return dispatch(setTechnology(technology));

    dispatch(setTechnology(technology));
    !isBack && isMainFlow && history.push(MainPaths.softSkills);
  };

  return (
    <>
      {isMainFlow ? (
        <Technology state={state} setState={setState} onSubmit={onSubmit} user={user} />
      ) : (
        <ProfileTechnology state={state} setState={setState} onSubmit={onSubmit} user={user} />
      )}

      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} />
    </>
  );
};

function createTechnologyFromCurrentState(
  currentKnownArray: DragItemType[],
  currentLearningArray: DragItemType[],
  currentWantToLearnArray: DragItemType[],
  skillsCopy: string[],
  skills: string[],
) {
  const knownArrayIDs = currentKnownArray.map((item) => item.id);
  const filteredCurrentLearningArray = currentLearningArray.filter((item) => !knownArrayIDs.includes(item.id));
  const filteredLearningIDs = filteredCurrentLearningArray.map((item) => item.id);
  const filteredWantToLearnArray = currentWantToLearnArray.filter(
    (item) => !filteredLearningIDs.includes(item.id) && !knownArrayIDs.includes(item.id),
  );

  const correctLabels = (el: DragItemType) =>
    skillsCopy.indexOf(el.label.toLowerCase()) !== -1 ? skills[skillsCopy.indexOf(el.label.toLowerCase())] : el.label;

  const known = currentKnownArray.map(correctLabels);
  const learning = filteredCurrentLearningArray.map(correctLabels);
  const wantToLearn = filteredWantToLearnArray.map(correctLabels);

  const technology = { learning, known, wantToLearn };

  return { technology, known, learning, wantToLearn };
}
