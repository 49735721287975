import { useEffect, useState } from 'react';
import { ButtonDefault, PlatformSelect, TextInput } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Redirect, useParams } from 'react-router-dom';
import firebase from 'firebase';
import { yupResolver } from '@hookform/resolvers/yup';
import { AlertMessage } from '@hrme/shared';

import { MainAuthLayout } from 'ui/layouts/mainAuthLayout/MainAuthLayout';
import { AuthenticationPaths } from 'routing/auth/AuthenticationPaths';

import { useStyles } from './EnterNewPassword.styles';
import { EnterNewPasswordParams, NewPasswordProps, newPasswordValidation } from './EnterNewPassword.types';

export const EnterNewPassword = (): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { actionCode } = useParams<EnterNewPasswordParams>();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });
  const [responseMessage, setResponseMessage] = useState<string | undefined>();
  const [platformSelect, setPlatformSelect] = useState(false);

  const { register, handleSubmit, errors } = useForm<NewPasswordProps>({
    resolver: yupResolver(newPasswordValidation),
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    if (actionCode) {
      firebase.auth().verifyPasswordResetCode(actionCode);
    }
  }, [actionCode]);

  if (!actionCode) {
    return <Redirect to={AuthenticationPaths.login} />;
  }
  const onFormSubmit = (data: NewPasswordProps) => {
    firebase
      .auth()
      .confirmPasswordReset(actionCode, data.password)
      .then(() => {
        setResponseMessage('Sukces. Pomyślnie zmieniono hasło.');
        setShowAlertMessage({ show: true, success: true });
        setPlatformSelect(true);
      })
      .catch((error) => {
        setResponseMessage('Wystąpił błąd. Nie udało się zresetować hasła.' + error);
        setShowAlertMessage({ show: true, success: false });
      });
  };

  if (platformSelect)
    return (
      <MainAuthLayout>
        <PlatformSelect />
        <AlertMessage open={showAlertMessage} close={setShowAlertMessage} message={responseMessage} />
      </MainAuthLayout>
    );

  return (
    <MainAuthLayout>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Typography variant="h4" className={classes.header}>
          Ustaw nowe hasło do konta
        </Typography>
        <Box mb={3} />
        <Typography variant="subtitle1" className={classes.subheader}>
          Wprowadź nowe hasło i powtórz je.
        </Typography>
        <Box mb={4} />
        <TextInput
          ref={register}
          type="password"
          name="password"
          label="nowe hasło"
          placeholder="Wpisz nowe hasło do konta"
          required={true}
          error={!!errors.password}
          helperText={errors.password && errors.password.message}
        />
        <TextInput
          ref={register}
          type="password"
          name="secondPassword"
          label="powtórz hasło"
          placeholder="Potwierdź swoje hasło"
          required={true}
          error={!!errors.secondPassword}
          helperText={errors.secondPassword && errors.secondPassword.message}
        />
        <Box mb={0.75} />
        <ButtonDefault>zresetuj hasło</ButtonDefault>
      </form>
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} message={responseMessage} />
    </MainAuthLayout>
  );
};
