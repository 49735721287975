import React, { useEffect, useState, useMemo } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { ButtonDefault, TextInput, TextSelect } from '@hrme/shared';
import { Grid } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { Redirect, Prompt } from 'react-router-dom';
import { AlertMessage } from '@hrme/shared';

import { SettingsLayout } from 'ui/layouts/settingsLayout/SettingsLayout';
import { BoxWrapper } from '../components/BoxWrapper';
import { GENDER_ITEMS } from 'app/main/fillData/FillData.types';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { arrayOfStringToFormControlLabelItem } from 'helpers/arrayOfStringToFormControlLabelItem';
import { ProfilePaths } from 'routing/profile/ProfilePaths';
import { AVAILABILITY_HOURS, AVAILABILITY_ITEMS, AVAILABILITY_TYPES } from 'app/main/availability/Availability.types';
import { User } from 'context/user/UserContext.types';
import { useKnowledge } from 'hooks/useKnowledge/useKnowledge';
import { PositionState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';
import { getPositionFromArray } from 'helpers/getPositionFromArray';
import { RadioChipGroupComponent } from 'ui/radioChipGroupComponent/RadioChipGroupComponent';
import { RadioChipGroupComponentType } from 'ui/radioChipGroupComponent/RadioChipGroupComponent.types';
import { Loader } from 'ui/loader/Loader';
import { StyledCheckbox } from 'ui/inputs/StyledCheckbox/StyledCheckbox';

import { ProfileSettings as ProfSettingsTypes } from './ProfileSettings.types';
import { useStyles } from './ProfileSettings.styles';

export const ProfileSettings: React.FC = () => {
  const { user, setUserData } = useUserContext();
  const { loading, fetchSuggestions, categories } = useKnowledge();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });
  const [showSavePrompt, setShowSavePrompt] = useState(false);
  const theme = useTheme();
  const classes = useStyles(theme);

  const defaultValues = {
    fillData: user?.fillData,
    availability: user?.availability,
    position_name: user?.position?.position_name,
    specialization: user?.specialization?.specialization,
    other: user?.specialization?.other,
    newsletter_email: user?.newsletter_email,
  };

  const methods = useForm<ProfSettingsTypes>({
    defaultValues,
  });

  const categoriesOrNew = ['New to IT', ...categories];

  const positionItems = useMemo(() => {
    const items = arrayOfStringToFormControlLabelItem(categoriesOrNew);

    return items;
  }, [categoriesOrNew]);

  const positionSelected: string = methods.watch('position_name');

  const isOtherPosition = !!positionItems.find(({ value }) => value.toLowerCase() === positionSelected?.toLowerCase())
    ? []
    : positionSelected
    ? [positionSelected]
    : [];

  const userOtherPosition = isOtherPosition?.map((el, index) => {
    return { key: index, label: el };
  });

  const showWhenLookingForJob = methods.watch('availability.work') === 'none';
  const allowSave =
    methods.watch('availability.work') === 'none' ||
    (!!methods.watch('availability.hours') && !!methods.watch('availability.workType'));

  useEffect(() => {
    const getSuggestions = async () => {
      await fetchSuggestions();
    };
    getSuggestions();
  }, []);

  useEffect(() => {
    if (methods.watch('availability.work') === 'none') {
      methods.setValue('availability.hours', '');
      methods.setValue('availability.workType', '');
    }
  }, []);

  if (user === undefined) {
    return <Redirect to={ProfilePaths.dashboard} />;
  }

  if (loading) return <Loader />;

  const onFormSubmit = async (data: ProfSettingsTypes) => {
    const { availability, fillData, confirmNewsletter } = data;

    const position = getPositionFromArray(data as ProfSettingsTypes) as PositionState;

    if (availability === user.availability && fillData === user.fillData && position === user.position) {
      return;
    }

    const specialization = {
      other: data.other,
      specialization: data.specialization,
    };

    const newUser: User = {
      ...user,
      fillData,
      availability,
      position,
      specialization,
      newsletter_email: confirmNewsletter ? user?.email : null,
    };

    const result = await setUserData(newUser);
    setShowAlertMessage({ show: true, success: result.success });
    setShowSavePrompt(false);
  };

  const onContentChange = async () => {
    setShowSavePrompt(true);
  };
  const signedToNewsletter = methods.watch('newsletter_email');

  return (
    <SettingsLayout>
      <BoxWrapper>
        <Prompt when={showSavePrompt} message="Twoje dane nie zostały zapisane. Czy chcesz kontynuować?" />
        <form onSubmit={methods.handleSubmit(onFormSubmit)} onChange={onContentChange}>
          <Typography align="left" variant="h4">
            Specjalizacja
          </Typography>
          <Box mb={3} />
          <RadioChipGroupComponent
            positionItems={positionItems}
            methods={methods}
            userOtherPosition={userOtherPosition}
            inSettings
            name={RadioChipGroupComponentType.POSITION_NAME}
          />
          <Box mb={3} />
          <Typography align="left" variant="h4">
            Zainteresowania
          </Typography>
          <Box mb={3} />
          <RadioChipGroupComponent
            positionItems={positionItems}
            methods={methods}
            inSettings
            name={RadioChipGroupComponentType.SPECIALIZATION}
          />
          <Box mb={3} />
          <TextInput
            label="miejsce zamieszkania"
            name={'fillData.city'}
            ref={methods.register}
            placeholder="Warszawa"
          />
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <TextInput
                placeholder="20"
                name={'fillData.age'}
                ref={methods.register}
                type="number"
                label="wiek"
                limitChars={2}
              />
            </Grid>
            <Grid item xs={10}>
              <Controller
                control={methods.control}
                name={'fillData.gender'}
                render={({ onChange, value, ref }) => (
                  <TextSelect
                    items={GENDER_ITEMS}
                    label="płeć"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    ref={ref}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Controller
            control={methods.control}
            name={'availability.work'}
            render={({ onChange, value, ref }) => (
              <TextSelect
                items={AVAILABILITY_ITEMS}
                label="dostępność do podjęcia pracy"
                defaultValue={value}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                ref={ref}
              />
            )}
          />
          {!showWhenLookingForJob && (
            <>
              <Controller
                control={methods.control}
                name={'availability.hours'}
                render={({ onChange, value, ref }) => (
                  <TextSelect
                    defaultValue={value}
                    items={AVAILABILITY_HOURS}
                    label="ilość dostępnych godzin"
                    value={value || ''}
                    onChange={(e) => onChange(e.target.value)}
                    ref={ref}
                  />
                )}
              />
              <Controller
                control={methods.control}
                name={'availability.workType'}
                render={({ onChange, value, ref }) => (
                  <TextSelect
                    defaultValue={value}
                    items={AVAILABILITY_TYPES}
                    label="preferowany tryb pracy"
                    value={value || ''}
                    onChange={(e) => onChange(e.target.value)}
                    ref={ref}
                  />
                )}
              />
            </>
          )}

          <label className={classes.checkbox}>
            <StyledCheckbox
              name={'confirmNewsletter'}
              inputRef={methods.register}
              defaultChecked={!!signedToNewsletter}
            />
            <Box mr={2} />
            <Typography variant="subtitle1">
              Chcę otrzymywać informacje o nowych ścieżkach kariery i rozwoju platformy na mój adres email
            </Typography>
          </label>
          <Box mb={3} />

          <ButtonDefault type="submit" disabled={!allowSave}>
            zapisz dane
          </ButtonDefault>
        </form>
      </BoxWrapper>
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} />
    </SettingsLayout>
  );
};
