import { makeStyles } from '@material-ui/core';
import { CONTAINER_WIDTH, HrMeTheme } from '@hrme/shared/lib/theme/theme';

export const useStyles = makeStyles((theme: HrMeTheme) => ({
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  siteWrapper: {
    padding: theme.spacing(0, 3),
    maxWidth: CONTAINER_WIDTH,
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flex: 1,
    maxWidth: CONTAINER_WIDTH,
    width: '100%',
  },
  aside: {
    flex: 1,
    padding: theme.spacing(4),
    alignItems: 'flex-start',
    textAlign: 'left',
    border: `1px solid ${theme.borders.light}`,
    marginBottom: theme.spacing(4),
  },
  container: {
    flex: 2.4,
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(4),
    },
  },
}));
