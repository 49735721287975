import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { ScrollTo } from 'ui/scrollTo/ScrollTo';
import { getCareerPathSkillsBanner } from 'ui/baners/Banners';
import { useDiscountsOnTutorials } from 'hooks/useDiscountsOnTutorials/useDiscountsOnTutorials';
import { bannerOfTutorialFromFB } from 'context/discountsOnTutorials/DiscountsOnTutorialsContext.types';

import { CareerPathViewPanelLeftProps } from './CareerPathViewPanelLeft.types';
import { useStyles } from './CareerPathViewPanelLeft.styles';
import { CareerPathStepAccordion } from './careerPathStepAccordion/CareerPathStepAccordion';

export const CareerPathViewPanelLeft: React.FC<CareerPathViewPanelLeftProps> = ({ data, idPath }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [expanded, setExpanded] = useState<string>('');
  const [visible, setVisible] = useState({ isVisible: false, index: 0 });
  const [bannerObjs, setBannerObjs] = useState<bannerOfTutorialFromFB[]>([]);
  const { banners } = useDiscountsOnTutorials();

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : '');
  };
  const addIndexToBanners = (bannerOnThisPath: bannerOfTutorialFromFB[]) => {
    const newBannerObjs = [...bannerObjs];
    bannerOnThisPath.forEach((banner) => {
      if (banner.showOverCareerPathStep !== undefined) {
        const index = Number(banner.showOverCareerPathStep);
        if (index >= 0 && index < data.length) {
          newBannerObjs[index] = banner;
        }
      }
    });
    setBannerObjs(newBannerObjs);
  };
  const showBanner = (index: number) => {
    if (bannerObjs[index + 2]) return getCareerPathSkillsBanner(bannerObjs[index + 2]);
  };
  const { isVisible, index } = visible;
  const showScrollTo = !isVisible && !!expanded && false;
  useEffect(() => {
    const bannersOnThisPath = banners.filter((obj) => obj.career_path_with_banner.includes(idPath));
    addIndexToBanners(bannersOnThisPath);
  }, [banners, data]);

  return (
    <div className={classes.panel}>
      <div className={classes.line} />
      <ScrollTo index={index} showScroll={showScrollTo} />

      {data.map((item, index) => {
        const showGroup = item.details.group && (index === 0 || item.details.group !== data[index - 1].details.group);
        const singleElement = item.details.group && item.details.group === data[index + 1]?.details.group;

        return (
          <div key={index} className={clsx({ [classes.item]: true, [classes.border]: singleElement })}>
            {showGroup && <Typography className={classes.top}>{item.details.group}</Typography>}
            <CareerPathStepAccordion
              expanded={expanded}
              index={index}
              handleChange={handleChange}
              item={item}
              setVisible={setVisible}
            />
            {!singleElement && <Box mb={2} />}
            {showBanner(index)}
          </div>
        );
      })}
    </div>
  );
};
