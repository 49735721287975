import React from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@material-ui/core';

import { useStyles } from './Loader.styles';
import { LoaderProps } from './Loader.types';

export const Loader: React.FC<LoaderProps> = ({ message = undefined }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box className={classes.wrapper}>
      <div>
        <CircularProgress />
        {message && (
          <>
            <Typography>{message}</Typography>
          </>
        )}
      </div>
    </Box>
  );
};
