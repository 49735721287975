import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Prompt, Redirect } from 'react-router-dom';
import { AlertMessage } from '@hrme/shared';

import { ADD_EXPERIENCE_ITEM, ExperienceFormState } from 'app/main/experience/Experience.types';
import { User } from 'context/user/UserContext.types';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { ProfilePaths } from 'routing/profile/ProfilePaths';
import { TechnologyState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';
import { ExperienceItemType } from 'ui/experienceItem/ExperienceItem.types';
import { BoxWrapper } from 'ui/boxes/boxWrapper/BoxWrapper';
import { SettingsLayout } from 'ui/layouts/settingsLayout/SettingsLayout';
import { useKnowledge } from 'hooks/useKnowledge/useKnowledge';

import { ExperienceSettings } from './ExperienceSettings';

export const ExperienceSettingsContainer = (): JSX.Element => {
  const { user, setUserData } = useUserContext();
  const [isAwaiting, setIsAwaiting] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });
  const [experienceItems, setExperienceItems] = useState([ADD_EXPERIENCE_ITEM]);
  const { fetchSuggestions } = useKnowledge();
  const [showSavePrompt, setShowSavePrompt] = useState(false);

  useEffect(() => {
    const getSuggestions = async () => {
      await fetchSuggestions();
    };
    getSuggestions();
  }, []);

  useEffect(() => {
    if (!user) return;

    const { experience } = user;

    if (!experience) return;
    methods.setValue('experience', experience);

    setExperienceItems([...experience]);
  }, []);

  const methods = useForm<ExperienceFormState>({
    defaultValues: { experience: user?.experience },
  });

  if (user === undefined) {
    return <Redirect to={ProfilePaths.dashboard} />;
  }

  const addItem = () => {
    setExperienceItems((prevState) => {
      const newExperienceItems = [...prevState];
      newExperienceItems.push(ADD_EXPERIENCE_ITEM);

      return [...newExperienceItems];
    });
  };

  const deleteItem = (index: number) => {
    setExperienceItems((prevState) => {
      const newExperienceItems = [...prevState];
      newExperienceItems.splice(index, 1);

      return [...newExperienceItems];
    });
  };

  const onContentChange = async () => setShowSavePrompt(true);

  const onFormSubmit = async (data: ExperienceFormState) => {
    const experience: ExperienceItemType[] = [];

    if (data && data.experience) {
      experience.push(
        ...data.experience.map((el) => {
          const { end, ...expValues } = el;

          return {
            ...expValues,
            end: end || null,
            seniority: el.seniority ? el.seniority : '',
            type: el.type ? el.type : '',
          };
        }),
      );
    }

    if (experience === user.experience) {
      return;
    }

    const technologyKnown = experience
      .map((item) => {
        return item.skills;
      }) // get all skills as string[][]
      .reduce((acc, curVal) => {
        const filteredDupes = curVal.filter((el) => el !== el.toLowerCase());

        return acc.concat(filteredDupes);
      }, []) // flatten the array to be one-dimension
      .reduce((a, b) => {
        if (a.indexOf(b) < 0) a.push(b);

        return a;
      }, [] as string[]); // remove all duplicates in array

    const technologyState: TechnologyState = {
      known: technologyKnown,
      wantToLearn: [],
      learning: [],
    };

    const technologyKnownNotInTechnologyTable: string[] = [];
    technologyKnown.map((item) => {
      if (
        !user.technology?.known.includes(item) &&
        !user.technology?.learning.includes(item) &&
        !user.technology?.wantToLearn.includes(item)
      )
        technologyKnownNotInTechnologyTable.push(item);

      return null;
    });

    const newUser: User = {
      ...user,
      experience,
      technology: user.technology
        ? {
            ...user.technology,
            known: user.technology.known.concat(technologyKnownNotInTechnologyTable),
            learning: user.technology.learning,
            wantToLearn: user.technology.wantToLearn,
          }
        : technologyState,
    };

    setIsAwaiting(true);
    const response = await setUserData(newUser);
    setIsAwaiting(false);
    setShowAlertMessage({ show: true, success: response.success });
    setShowSavePrompt(false);
  };

  return (
    <>
      <SettingsLayout>
        <BoxWrapper>
          <Prompt when={showSavePrompt} message="Twoje dane nie zostały zapisane. Czy chcesz kontynuować?" />
          <form onSubmit={methods.handleSubmit(onFormSubmit)} onChange={onContentChange}>
            <ExperienceSettings
              methods={methods}
              onFormSubmit={onFormSubmit}
              isAwaiting={isAwaiting}
              experienceItems={experienceItems}
              addItem={addItem}
              deleteItem={deleteItem}
            />
          </form>
        </BoxWrapper>
      </SettingsLayout>
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} />
    </>
  );
};
