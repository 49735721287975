import { LoginPage } from 'app/auth/login/LoginPage';
import { CheckEmail } from 'app/auth/password/checkEmail/CheckEmail';
import { EnterNewPassword } from 'app/auth/password/enterNewPassword/EnterNewPassword';
import { PasswordResetConfirmation } from 'app/auth/password/passwordResetConfirmation/PasswordResetConfirmation';
import { ResetPasswordContainer } from 'app/auth/password/resetPassword/ResetPasswordContainer';
import { RegisterPageContainer } from 'app/auth/register/RegisterPageContainer';
import { WelcomeRegister } from 'app/auth/register/welcomeRegister/WelcomeRegister';
import { EmailVerification } from 'app/auth/emailVerification/EmailVerification';
import { AuthPage } from 'app/auth/AuthPage';
import { TermsAndPolicyWrapper } from 'ui/termsAndPolicyWrapper/termsAndPolicyWrapper';

import { AuthenticationPaths } from './AuthenticationPaths';

export const AuthenticationRoutes = [
  {
    id: 0,
    path: AuthenticationPaths.login,
    component: LoginPage,
  },
  {
    id: 1,
    path: AuthenticationPaths.register,
    component: RegisterPageContainer,
  },
  {
    id: 2,
    path: AuthenticationPaths.welcomeRegister,
    component: WelcomeRegister,
  },
  {
    id: 3,
    path: AuthenticationPaths.resetPassword,
    component: ResetPasswordContainer,
  },
  {
    id: 4,
    path: AuthenticationPaths.checkEmail,
    component: CheckEmail,
  },
  {
    id: 5,
    path: AuthenticationPaths.enterNewPassword,
    component: EnterNewPassword,
  },
  {
    id: 6,
    path: AuthenticationPaths.passwordResetConfirmation,
    component: PasswordResetConfirmation,
  },
  {
    id: 7,
    path: AuthenticationPaths.authPage,
    component: AuthPage,
  },
  {
    id: 8,
    path: AuthenticationPaths.emailVerification,
    component: EmailVerification,
  },
  {
    id: 9,
    path: AuthenticationPaths.termsAndConditions,
    component: TermsAndPolicyWrapper,
  },
  {
    id: 10,
    path: AuthenticationPaths.policy,
    component: TermsAndPolicyWrapper,
  },
];
