/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';

export const ProtectedRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const { currentUser, authPending } = useAuthentication();

  const canOpenProtectedRoute = authPending || (currentUser !== null && currentUser.emailVerified);

  return (
    <Route
      {...rest}
      //@ts-ignore
      render={(routeProps) => (canOpenProtectedRoute ? <Component {...routeProps} /> : <Redirect to="/" />)}
    />
  );
};
