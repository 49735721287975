import React, { useReducer } from 'react';

import { careerPathFormReducer } from '../careerPathFormReducer/CareerPathFormReducer';
import { CareerPathFormContextType } from '../careerPathFormContext/CareerPathFormContext.types';
import { CareerPathFormContext, CareerPathFormDispatchContext } from '../careerPathFormContext/CareerPathFormContext';

import { CareerPathFormContextControllerProps } from './CareerPathFormController.types';

export const initialFormState: CareerPathFormContextType = {};

export const CareerPathFormController = ({ children }: CareerPathFormContextControllerProps): JSX.Element => {
  const [state, dispatch] = useReducer(careerPathFormReducer, initialFormState);

  return (
    <CareerPathFormContext.Provider value={state}>
      <CareerPathFormDispatchContext.Provider value={dispatch}>{children}</CareerPathFormDispatchContext.Provider>
    </CareerPathFormContext.Provider>
  );
};
