import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

import CancelIcon from 'assets/icon/cancel_black.svg';
import HamburgerIcon from 'assets/icon/hamburger.svg';
import ArrowLeftIcon from 'assets/icon/arrow-left-mobile.svg';
import { ProfilePaths } from 'routing/profile/ProfilePaths';
import { PROFILE_MENU_ITEMS } from 'ui/layouts/profileLayout/ProfileLayout.types';
import { PlatformHeaderMain } from 'ui/platformHeaderMain/PlatformHeaderMain';
import { ProfileMenuBar } from 'ui/profileMenuBar/ProfileMenuBar';

import { useStyles } from './NavMenu.styles';

export const NavMenu: React.FC = React.memo(() => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [menuOpen, setMenuOpen] = useState(false);

  const history = useHistory();

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    document.body.style.overflow = menuOpen ? 'hidden' : 'auto';
  }, [menuOpen]);

  return (
    <>
      <div className={classes.headerWrapper}>
        <div className={classes.header}>
          {isDesktop ? (
            <>
              <PlatformHeaderMain link={ProfilePaths.dashboard} mono />
              <ProfileMenuBar items={PROFILE_MENU_ITEMS} />
            </>
          ) : (
            <div className={classes.navWrapper}>
              <img src={ArrowLeftIcon} alt="ArrowLeftIcon" onClick={() => history.goBack()} />
              <PlatformHeaderMain link={ProfilePaths.dashboard} mono />
              <img
                src={menuOpen ? CancelIcon : HamburgerIcon}
                alt="toggleIcon"
                onClick={() => setMenuOpen(!menuOpen)}
              />
            </div>
          )}
        </div>
      </div>

      {menuOpen && (
        <div className={classes.menuWrapper}>
          <ul className={classes.list}>
            {PROFILE_MENU_ITEMS.map((item, index) => (
              <div className={classes.listItem} key={index}>
                <li>
                  <Link to={item.link} onClick={() => setMenuOpen(false)} className={classes.linkStyles}>
                    {item.label}
                  </Link>
                </li>
              </div>
            ))}
          </ul>
        </div>
      )}
    </>
  );
});
