/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeStyles } from '@material-ui/core';
import { CSSProperties } from 'react';

import { BoardOrigin } from '../Board.types';

const backgroundColorForOrigin = (isDragging: boolean, origin: BoardOrigin): string => {
  const defaultResult = isDragging ? '#e3e3e3' : '#F5F5F5';

  if (origin === BoardOrigin.PROFILE) {
    return isDragging ? '#F5F5F5' : '#fff';
  }
  if (origin === BoardOrigin.MAIN) {
    return defaultResult;
  }

  return defaultResult;
};

export const getItemStyle = (isDragging: boolean, draggableStyle: StyleSheet, origin: BoardOrigin): CSSProperties => ({
  userSelect: 'none',
  display: 'flex',

  // change background colour if dragging
  backgroundColor: backgroundColorForOrigin(isDragging, origin),
  boxShadow: isDragging ? '0px 6px 10px 4px rgba(0,0,0,0.14)' : 'none',

  borderBottom: '1px solid #DADADA',

  // styles we need to apply on draggables
  ...draggableStyle,
});

export const useStyles = makeStyles((theme) => ({
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    textAlign: 'left',
    flexDirection: 'row',
  },
  button: {
    width: '40px',
    height: '40px',
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1, 0, 1, 1),
  },
}));
