import { MessageSectionProps } from '../messageSection/MessageSection.types';

export const SAMPLE_BAR_DATA = [
  { label: 'znam', color: '#219653', value: 142 },
  { label: 'nie znam', color: '#EB5757', value: 36 },
  { label: 'uczę się', color: '#F2994A', value: 198 },
  { label: 'chcę się nauczyć', color: '#F2C94C', value: 101 },
];

export const SAMPLE_MESSAGE_DATA: MessageSectionProps = {
  message: 'Twój profil nie jest w pełni uzupełniony',
  messageData: {
    type: 'warning',
    onAction: () => console.log('DOKOŃCZ... button clicked'),
    actionCaption: 'dokończ uzupełnianie profilu',
    onClose: () => console.log('CLOSE button clicked'),
  },
};
