import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AlertMessage } from '@hrme/shared';

import { useCareerPathFormDispatch } from 'hooks/useCareerPathFormDispatch/useCareerPathFormDispatch';
import { PositionState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';
import { setPosition } from 'context/careerPathForm/careerPathActionCreators/careerPathActionCreators';
import { MainPaths } from 'routing/main/MainPaths';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { User } from 'context/user/UserContext.types';
import { getPositionFromArray } from 'helpers/getPositionFromArray';

import { Position } from './Position';

export const PositionContainer = (): JSX.Element => {
  const history = useHistory();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });

  const { setUserData, user } = useUserContext();
  const dispatch = useCareerPathFormDispatch();
  const methods = useForm<PositionState>({ defaultValues: { position_name: user?.position?.position_name } });

  if (user === undefined) {
    return <Redirect to={MainPaths.welcomeMain} />;
  }

  const onFormSubmit = async (data: PositionState, isIdle?: boolean) => {
    if (data.position_name === undefined) return;
    if (isIdle && !user.position?.position_name) return;

    const newUser: User = { ...user, position: getPositionFromArray(data) as PositionState };
    const response = await setUserData(newUser);

    if (!response.success) setShowAlertMessage({ show: true, success: false });
    if (response.success && isIdle) return dispatch(setPosition(getPositionFromArray(data) as PositionState));

    if (response.success) {
      dispatch(setPosition(getPositionFromArray(data) as PositionState));
      history.push(MainPaths.specialization);
    }
  };

  return (
    <>
      <Position onFormSubmit={onFormSubmit} methods={methods} />
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} />
    </>
  );
};
