import { TextSelectItem } from '@hrme/shared/lib/components/inputs/textSelect/TextSelect.types';

export enum ExperienceTypesKey {
  NONE = 'none',
  JUNIOR = 'junior',
  MID = 'mid',
  SENIOR = 'senior',
  EXPERT = 'expert',
}

export const ExperienceTypesLabel: { [key: string]: string } = {
  [ExperienceTypesKey.NONE]: 'Brak',
  [ExperienceTypesKey.JUNIOR]: 'Junior',
  [ExperienceTypesKey.MID]: 'Mid',
  [ExperienceTypesKey.SENIOR]: 'Senior',
  [ExperienceTypesKey.EXPERT]: 'Ekspert',
};

export const SAMPLE_EXPERIENCE_TYPES: TextSelectItem[] = [
  { value: ExperienceTypesKey.NONE, label: ExperienceTypesLabel[ExperienceTypesKey.NONE] },
  { value: ExperienceTypesKey.JUNIOR, label: ExperienceTypesLabel[ExperienceTypesKey.JUNIOR] },
  { value: ExperienceTypesKey.MID, label: ExperienceTypesLabel[ExperienceTypesKey.MID] },
  { value: ExperienceTypesKey.SENIOR, label: ExperienceTypesLabel[ExperienceTypesKey.SENIOR] },
  { value: ExperienceTypesKey.EXPERT, label: ExperienceTypesLabel[ExperienceTypesKey.EXPERT] },
];

export enum ExperienceYearsKey {
  NONE = 'none',
  LESS1 = 'less1',
  FROM1TO3 = 'from1to3',
  FROM3TO5 = 'from3to5',
  MORE5 = 'more5',
}

export const ExperienceYearsLabel: { [key: string]: string } = {
  [ExperienceYearsKey.NONE]: 'Brak',
  [ExperienceYearsKey.LESS1]: '< 1 rok',
  [ExperienceYearsKey.FROM1TO3]: '1-3 lata',
  [ExperienceYearsKey.FROM3TO5]: '3-5 lat',
  [ExperienceYearsKey.MORE5]: '> 5 lat',
};

export const SAMPLE_EXPERIENCE_YEARS: TextSelectItem[] = [
  { value: ExperienceYearsKey.NONE, label: ExperienceYearsLabel[ExperienceYearsKey.NONE] },
  { value: ExperienceYearsKey.LESS1, label: ExperienceYearsLabel[ExperienceYearsKey.LESS1] },
  { value: ExperienceYearsKey.FROM1TO3, label: ExperienceYearsLabel[ExperienceYearsKey.FROM1TO3] },
  { value: ExperienceYearsKey.FROM3TO5, label: ExperienceYearsLabel[ExperienceYearsKey.FROM3TO5] },
  { value: ExperienceYearsKey.MORE5, label: ExperienceYearsLabel[ExperienceYearsKey.MORE5] },
];
