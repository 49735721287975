/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseFormMethods } from 'react-hook-form';

import { FormControlLabelItem } from 'ui/inputs/radioChipGroup/RadioChipGroup.types';

export enum RadioChipGroupComponentType {
  SPECIALIZATION = 'specialization',
  POSITION_NAME = 'position_name',
}

export type RadioChipGroupComponentProps = {
  positionItems: FormControlLabelItem[];
  name: RadioChipGroupComponentType;
  methods: UseFormMethods<any>;
  inSettings?: boolean;
  userOtherPosition?: {
    key: number;
    label: string;
  }[];
};
