import React, { useState } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { ButtonDefault } from '@hrme/shared';
import { Controller, useForm } from 'react-hook-form';
import { Prompt, Redirect } from 'react-router-dom';
import { AlertMessage } from '@hrme/shared';

import { SettingsLayout } from 'ui/layouts/settingsLayout/SettingsLayout';
import { BoxWrapper } from '../components/BoxWrapper';
import { SOFT_SKILLS_ITEMS } from 'app/main/softSkills/SoftSkills.types';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { SoftSkillsState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';
import { User } from 'context/user/UserContext.types';
import { ProfilePaths } from 'routing/profile/ProfilePaths';
import { CheckboxChipGrouControllerProps } from 'ui/inputs/checkboxChipGroup/CheckboxChipGroup.types';
import { TagInputExtended } from 'ui/inputs/tagInputExtended/TagInputExtended';

import { SoftSkillsSettingsProps } from './SoftSkillsSettings.types';

const formDefaultValues = { softSkills: [], other: [] };

export const SoftSkillsSettings: React.FC<SoftSkillsSettingsProps> = () => {
  const { setUserData, user } = useUserContext();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });
  const [showSavePrompt, setShowSavePrompt] = useState(false);

  const methods = useForm<SoftSkillsState>({
    defaultValues: user?.softSkills !== undefined ? user.softSkills : formDefaultValues,
  });
  const { handleSubmit, control, watch } = methods;

  const chipsValues: string[] = watch('other');

  const skillsChipsArray = chipsValues?.map((el, index) => {
    return { key: index, label: el };
  });

  if (user === undefined) {
    return <Redirect to={ProfilePaths.dashboard} />;
  }

  const onContentChange = async () => setShowSavePrompt(true);

  const onFormSubmit = async (data: SoftSkillsState) => {
    const { softSkills, other } = data;

    const payload: SoftSkillsState = {
      softSkills: softSkills.filter((el) => el !== ''),
      other: other,
    };
    if (user.softSkills === payload) {
      return;
    }
    const newUser: User = { ...user, softSkills: payload };
    const result = await setUserData(newUser);

    setShowAlertMessage({ show: true, success: result.success });
    setShowSavePrompt(false);
  };

  return (
    <SettingsLayout>
      <BoxWrapper>
        <Prompt when={showSavePrompt} message="Twoje dane nie zostały zapisane. Czy chcesz kontynuować?" />
        <form onSubmit={handleSubmit(onFormSubmit)} onChange={onContentChange}>
          <Typography align="left" variant="h4">
            Umiejętności miękkie
          </Typography>
          <Box mb={3} />
          <Box mb={2}>
            <Controller
              control={control}
              name={'softSkills'}
              render={({ onChange, value }: CheckboxChipGrouControllerProps) => (
                <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="flex-start">
                  {SOFT_SKILLS_ITEMS.map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onChange={() => {
                            const index = value.indexOf(item.value);

                            return index === -1
                              ? onChange([...value, item.value])
                              : onChange(value.filter((el) => el !== item.value));
                          }}
                          defaultChecked={false}
                          checked={value !== undefined && value.indexOf(item.value) > -1}
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </Box>
              )}
              defaultValue={['']}
            />
          </Box>
          <TagInputExtended
            label={'inne'}
            items={skillsChipsArray}
            maxItems={10}
            inverse
            noMinHeight
            name={`other`}
            methods={methods}
            wide
          />
          <Box mb={4} />
          <ButtonDefault>zapisz zmiany</ButtonDefault>
        </form>
      </BoxWrapper>
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} />
    </SettingsLayout>
  );
};
