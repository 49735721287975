import { Dispatch } from 'react';

import { FormAction } from 'context/careerPathForm/careerPathFormReducer/CareerPathFormReducer.types';
import { ExperienceItemType } from 'ui/experienceItem/ExperienceItem.types';

export enum Gender {
  FEMALE = 'female',
  MALE = 'male',
  OTHER = 'other',
  NONE = 'none',
}

export enum AvailabilityWork {
  ALL = 'all',
  OTHER = 'other',
  ONEMONTH = 'onemonth',
  NONE = 'none',
}

export enum AvailabilityHours {
  TO_TEN = 1,
  TO_FOURTY = 2,
  OVER_FOURTY = 3,
  TO_TWENTY = 4,
}

export enum AvailabilityWorkType {
  OFFICE = 'office',
  REMOTE = 'remote',
  HYBRID = 'hybrid',
}

export type SchoolItem = {
  name: string;
  major: string;
  city: string;
  country: string;
  stillLearning: boolean;
  start: string;
  end: string | null;
};

export type PositionState = {
  position_name: string;
  other_position: string[];
  otherValue: string;
};

export type SpecializationState = {
  specialization: string[];
  other: string[];
};

export type AvailabilityState = {
  work: AvailabilityWork;
  hours: AvailabilityHours;
  workType: AvailabilityWorkType;
};

export type ExperienceState = ExperienceItemType[];

export type SchoolState = SchoolItem[];

export type TechnologyState = {
  known: string[];
  learning: string[];
  wantToLearn: string[];
};

export type SoftSkillsState = {
  softSkills: string[];
  other: string[];
};

export type EducationState = {
  education: string;
};

export type LanguagesState = Array<{
  language: string;
  level: string;
  certificate: string[];
}>;

export type PortfolioSocialMediaState = {
  github: string;
  linkedin: string;
  facebook: string;
  instagram: string;
  otherLinks: string[];
};

export type FillState = {
  gender: Gender;
  age: number;
  city: string;
};

export type CareerPathFormContextType = {
  position?: PositionState;
  specialization?: SpecializationState;
  availability?: AvailabilityState;
  experience?: ExperienceState;
  technology?: TechnologyState;
  softSkills?: SoftSkillsState;
  education?: EducationState;
  languages?: LanguagesState;
  portfolioSocialMedia?: PortfolioSocialMediaState;
  fill?: FillState;
};

export type CareerPathDispatchContextType = Dispatch<FormAction>;
