import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    // margin: '0 -32px',
    // position: 'relative',
    // left: '-' + theme.spacing(3) + 'px',
    width: 'calc(100% + ' + theme.spacing(6) + 'px)',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  innerContainer: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  titleBar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleButton: {
    color: theme.palette.grey['A200'],
  },
}));
