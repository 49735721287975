import { makeStyles } from '@material-ui/core';
import { CONTAINER_WIDTH } from '@hrme/shared/lib/theme/theme';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
  },

  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: '1',
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('md')]: {
      maxWidth: CONTAINER_WIDTH,
    },
  },
}));
