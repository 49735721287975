import { initialFormState } from 'context/careerPathForm/careerPathFormController/CareerPathFormController';

import {
  FormReducerType,
  RESET_FORM,
  SET_FORM_AVAILABILITY,
  SET_FORM_EDUCATION,
  SET_FORM_EXPERIENCE,
  SET_FORM_FILL,
  SET_FORM_LANGUAGES,
  SET_FORM_PORTFOLIO_SOCIAL_MEDIA,
  SET_FORM_POSITION,
  SET_FORM_SOFTSKILLS,
  SET_FORM_SPECIALIZATION,
  SET_FORM_TECHNOLOGY,
} from './CareerPathFormReducer.types';

export const careerPathFormReducer: FormReducerType = (state, action) => {
  switch (action.type) {
    case SET_FORM_SPECIALIZATION:
      return {
        ...state,
        specialization: {
          ...state.specialization,
          ...action.payload,
        },
      };
    case SET_FORM_AVAILABILITY:
      return {
        ...state,
        availability: {
          ...state.availability,
          ...action.payload,
        },
      };
    case SET_FORM_POSITION:
      return {
        ...state,
        position: {
          ...state.position,
          ...action.payload,
        },
      };
    case SET_FORM_SOFTSKILLS:
      return {
        ...state,
        softSkills: {
          ...state.softSkills,
          ...action.payload,
        },
      };
    case SET_FORM_EDUCATION:
      return {
        ...state,
        education: {
          ...state.education,
          ...action.payload,
        },
      };
    case SET_FORM_EXPERIENCE:
      return {
        ...state,
        experience: {
          ...state.experience,
          ...action.payload,
        },
      };
    case SET_FORM_FILL:
      return {
        ...state,
        fill: {
          ...state.fill,
          ...action.payload,
        },
      };
    case SET_FORM_TECHNOLOGY:
      return {
        ...state,
        technology: {
          ...state.technology,
          ...action.payload,
        },
      };
    case SET_FORM_PORTFOLIO_SOCIAL_MEDIA:
      return {
        ...state,
        portfolioSocialMedia: {
          ...state.portfolioSocialMedia,
          ...action.payload,
        },
      };
    case SET_FORM_LANGUAGES:
      return {
        ...state,
        languages: {
          ...state.languages,
          ...action.payload,
        },
      };
    case RESET_FORM:
      return initialFormState;
    default:
      throw new Error(`Unhandled action type in FormReducer: ${JSON.stringify(action)}`);
  }
};
