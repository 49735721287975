import { Button, makeStyles, styled } from '@material-ui/core';

export const StyledButton = styled(Button)({
  textTransform: 'lowercase',
  width: '45%',
});

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
  },
  topBar: {
    width: '100%',
    height: '50px',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(4, 3),
    textAlign: 'left',
    overflow: 'auto',
  },
  buttonWrapper: {
    width: '100%',
    height: '65px',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
}));
