import { WelcomeMain } from 'app/main/welcomeMain/WelcomeMain';
import { Congratulations } from 'app/main/congratulations/Congratulations';
import { PortfolioSocialMediaContainer } from 'app/main/portfolioSocialMedia/PortfolioSocialMediaContainer';
import { FillDataContainer } from 'app/main/fillData/FillDataContainer';
import { SpecializationContainer } from 'app/main/specialization/SpecializationContainer';
import { AvailabilityContainer } from 'app/main/availability/AvailabilityContainer';
import { EducationContainer } from 'app/main/education/EducationContainer';
import { ExperienceContainer } from 'app/main/experience/ExperienceContainer';
import { LanguagesContainer } from 'app/main/languages/LanguagesContainer';
import { PositionContainer } from 'app/main/position/PositionContainer';
import { SoftSkillsContainer } from 'app/main/softSkills/SoftSkillsContainer';
import { SchoolsContainer } from 'app/main/schools/SchoolsContainer';
import { Blog } from 'app/main/blog/Blog';
import { Facebook } from 'app/main/facebook/Facebook';
import { TechnologyWrapper } from 'app/common/technologyWrapper/TechnologyWrapper';

import { MainPaths } from './MainPaths';

export const MainRoutes = [
  {
    id: 0,
    path: MainPaths.welcomeMain,
    component: WelcomeMain,
  },
  {
    id: 1,
    path: MainPaths.position,
    component: PositionContainer,
  },
  {
    id: 2,
    path: MainPaths.specialization,
    component: SpecializationContainer,
  },
  {
    id: 3,
    path: MainPaths.availability,
    component: AvailabilityContainer,
  },

  {
    id: 4,
    path: MainPaths.experience,
    component: ExperienceContainer,
  },
  {
    id: 5,
    path: MainPaths.technology,
    component: TechnologyWrapper,
  },
  {
    id: 6,
    path: MainPaths.softSkills,
    component: SoftSkillsContainer,
  },
  {
    id: 7,
    path: MainPaths.education,
    component: EducationContainer,
  },
  {
    id: 8,
    path: MainPaths.languages,
    component: LanguagesContainer,
  },
  {
    id: 9,
    path: MainPaths.portfolioSocialMedia,
    component: PortfolioSocialMediaContainer,
  },
  {
    id: 10,
    path: MainPaths.fillData,
    component: FillDataContainer,
  },
  {
    id: 11,
    path: MainPaths.schools,
    component: SchoolsContainer,
  },
  {
    id: 12,
    path: MainPaths.congratulations,
    component: Congratulations,
  },
  {
    id: 13,
    path: MainPaths.blog,
    component: Blog,
  },
  {
    id: 14,
    path: MainPaths.facebook,
    component: Facebook,
  },
];
