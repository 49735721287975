import { makeStyles } from '@material-ui/core';
import { CONTAINER_WIDTH, WRAPPER_WIDTH } from '@hrme/shared/lib/theme/theme';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    maxWidth: CONTAINER_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
  },
  header: {
    width: '100%',
  },
  subheader: {
    width: '100%',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: WRAPPER_WIDTH,
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));
