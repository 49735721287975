import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import { AlertMessage } from '@hrme/shared';

import { LoginUserProps } from 'context/authentication/AuthenticationContext.types';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { MainPaths } from 'routing/main/MainPaths';

import { LoginForm } from './LoginForm';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Must be an valid email.').required('Field required.'),
  password: Yup.string().min(4, 'Password too short.').required('Field required.'),
});

export const LoginFormContainer = (): JSX.Element => {
  const { loginUser, loading } = useAuthentication();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });
  const [loginResponse, setLoginResponse] = useState<string | undefined>();

  const history = useHistory();

  const methods = useForm<LoginUserProps>({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
  });

  const onFormSubmit = async (data: LoginUserProps) => {
    const loginResponse = await loginUser(data);

    if (loginResponse.success) {
      history.replace(MainPaths.welcomeMain);
    } else if (loginResponse.needAction) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(loginResponse.message)) {
        history.replace(MainPaths.welcomeMain);
      }
    } else {
      setLoginResponse(
        'Odnotowalismy problemy z Firebase Auth - prosimy sprawdź folder spam, kliknij link aktywacyjny i spróbuj ponownie. Jesli nie zadziała - zresetuj swoje hasło lub spróbuj ponownie jutro. Postaramy się też ponawiać wysyłanie maili rejestracyjnych manualnie z panelu Google Cloud. Aktualnie pracujemy nad innym sposobem logowania. Przepraszamy za kłopoty.',
      );
      setShowAlertMessage({ show: true, success: false });
    }
  };

  return (
    <>
      <LoginForm loading={loading} methods={methods} onFormSubmit={onFormSubmit} />
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} message={loginResponse} />
    </>
  );
};
