import React from 'react';
import { Box, useTheme } from '@material-ui/core';

import { PlatformHeader } from '../platformHeader/PlatformHeader';

import { useStyles } from './LoginRegisterFormContainer.styles';

export const LoginRegisterFormContainer: React.FC = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      <PlatformHeader />
      <Box className={classes.wrapper} mt={{ xs: 5, md: 8 }}>
        {children}
      </Box>
    </div>
  );
};
