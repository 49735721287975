import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { ProfileLayout } from 'ui/layouts/profileLayout/ProfileLayout';
import { JobOfferBox } from 'ui/jobOfferBox/JobOfferBox';
import { useJobOffers } from 'hooks/useJobOffers/useJobOffers';
import { InvitationStatus, JobOffer } from 'context/jobOffer/JobOfferContext.types';
import { ProfilePaths } from 'routing/profile/ProfilePaths';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import Icon from 'assets/icon/verify.svg';
import { Loader } from 'ui/loader/Loader';

import { useStyles } from './ProfileJobOffers.styles';
import { ProfileJobOffersProps } from './ProfileJobOffers.types';
import { createJobOfferBoxDetailsItemFromJobOffer } from './ProfileJobOffers.utils';

export const ProfileJobOffers: React.FC<ProfileJobOffersProps> = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { fetchJobOffers, loading: jobOffersLoading, jobOffers, updateJobStatus } = useJobOffers();
  const { user } = useUserContext();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      await fetchJobOffers();
    };
    fetchData();
  }, []);

  const actionView = (job: JobOffer) => {
    history.push({
      pathname: ProfilePaths.jobOfferView,
      state: { job },
    });
  };

  const actionAllow = async (job: JobOffer) => {
    return await updateJobStatus({
      jobOfferRef: job.jobOfferRef,
      status: InvitationStatus.ACCEPTED,
      userName: user?.name || '',
      collection: job.collectionType,
    });
  };

  const actionAbandon = async (job: JobOffer) => {
    return await updateJobStatus({
      jobOfferRef: job.jobOfferRef,
      status: InvitationStatus.REJECTED,
      userName: user?.name || '',
      collection: job.collectionType,
    });
  };

  const data = jobOffers.map((job) =>
    createJobOfferBoxDetailsItemFromJobOffer(job, actionView, actionAllow, actionAbandon),
  );
  const breadcrumbs = false;

  return (
    <ProfileLayout>
      <Box mb={6} />
      <div className={classes.container}>
        {breadcrumbs ? (
          <ul className={classes.Bradcrubms}>
            <li className={classes.BradcrubmsItem}>
              <Link to="/" className={classes.BradcrubmsItemLink}>
                Dashboard
              </Link>
            </li>
            <li className={classes.BradcrubmsItem}>/</li>
            <li className={classes.BradcrubmsItem}>Oferty pracy</li>
          </ul>
        ) : (
          <Typography variant="h4" className={classes.header}>
            Oferty pracy
          </Typography>
        )}
      </div>

      <Box mb={3} />
      {jobOffersLoading ? (
        <div>
          <Loader message="Szukamy dla Ciebie ofert pracy..." />
        </div>
      ) : jobOffers.length ? (
        data.map((item, index) => (
          <div key={index} style={{ width: '100%' }}>
            <JobOfferBox details={item} />
            {/*add space after item, except the last one*/}
            {index < data?.length - 1 && <Box mb={2} />}
          </div>
        ))
      ) : (
        <div className={classes.noOffers}>
          <Typography variant="h4" className={classes.headerNoOffers}>
            Wygląda na to, że nie masz obecnie dostępnych ofert pracy.
          </Typography>

          <div className={classes.informations}>
            <Typography>Codziennie dopasowujemy nowe oferty pracy do pasujących kandydatów.</Typography>
            <Typography>
              Aby zwiększyć swoje szanse na otrzymanie spersonalizowanej oferty pracy, cyklicznie uzupełniaj swój
              progress rozwijając swoją tablicę technologii i doświadczenie.
            </Typography>
            <Typography>Upewnij się, że wypełniłeś/aś dokładnie swój profil, a w szczególności:</Typography>
          </div>
          <ul className={classes.noOffersList}>
            <li className={classes.noOffersListItem}>
              <span className={classes.point}>
                <img src={Icon} alt="point" />
              </span>
              <div>
                Uzupełniłeś/aś
                <span> </span>
                <Link to="settings/experience" className={classes.noOffersListItemLink}>
                  doświadczenie zawodowe
                </Link>
              </div>
            </li>
            <li className={classes.noOffersListItem}>
              <span className={classes.point}>
                <img src={Icon} alt="point" />
              </span>
              <div>
                Uzupełniłeś/aś
                <span> </span>
                <Link to="profile-technology" className={classes.noOffersListItemLink}>
                  tablicę technologii
                </Link>
              </div>
            </li>
          </ul>
        </div>
      )}

      <Box mb={10} />
    </ProfileLayout>
  );
};
