import React, { useMemo } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import { useUserContext } from 'hooks/useUserContext/useUserContext';

import { ProgressBarMainProps } from './ProgressBarMain.types';
import { useStyles } from './ProgressBarMain.styles';
import { ProgressBarElement } from './progressBarElement/ProgressBarElement';

export const ProgressBarMain: React.FC<ProgressBarMainProps> = ({ label = '', current = 0, length = 0 }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { user } = useUserContext();
  const stepsDone = useMemo(() => {
    const currentStepIndex = () => {
      if (user) {
        if (label === 'Dane osobowe' || user.portfolioSocialMedia) return 10;
        if (label === 'Portfolio' || user.portfolioSocialMedia) return 9;
        if (label === 'Języki' || user.languages) return 8;
        if (label === 'Wykształcenie' || user.education) return 7;
        if (label === 'Umiejętności miękkie' || user.softSkills) return 6;
        if (label === 'Tablica technologii' || user.technology) return 5;
        if (label === 'Doświadczenie' || user.experience) return 4;
        if (user.availability) return 3;
        if (user.position) return 2;
      }

      return 1;
    };

    return currentStepIndex();
  }, [user]);

  const progressBarElements: JSX.Element[] = useMemo(() => {
    const elements = [];
    for (let i = 1; i <= length; i++) {
      i <= stepsDone
        ? elements.push(<ProgressBarElement completed key={i} index={i} active={current === i} />)
        : elements.push(<ProgressBarElement key={i} index={i} />);
      if (i !== length) elements.push(<Box mr={0.5} key={`separator-${i}`} />);
    }

    return elements;
  }, [current, length, stepsDone]);

  return (
    <div className={classes.wrapper}>
      <Box mr={3} display={{ xs: 'none', md: 'block' }}>
        <Typography variant="subtitle1">{label}</Typography>
      </Box>
      {progressBarElements.map((element) => element)}
      <Box ml={3} display={{ xs: 'none', md: 'block' }}>
        <Typography variant="subtitle1" className={classes.completed}>{`${current}/${length}`}</Typography>
      </Box>
    </div>
  );
};
