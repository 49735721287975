import React from 'react';
import { useTheme } from '@material-ui/core';

import { BoxWrapperProps } from './BoxWrapper.types';
import { useStyles } from './BoxWrapper.styles';

export const BoxWrapper: React.FC<BoxWrapperProps> = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return <div className={classes.container}>{children}</div>;
};
