import React, { useEffect, useState } from 'react';

import { onError, onSuccess } from '../providersHelpers';
import { AuthenticationContext } from 'context/authentication/AuthenticationContext';
import { firebaseRef } from 'api/firebaseRef';
import { LoginUserProps, RegisterUserProps } from 'context/authentication/AuthenticationContext.types';
import { useJune } from 'hooks/useJune/useJune';

export const AuthenticationProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [authPending, setAuthPending] = useState(true);
  const [currentUser, setCurrentUser] = useState<firebase.default.User | null>(null);
  const analytics = useJune();

  useEffect(() => {
    firebaseRef.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setAuthPending(false);
    });
  }, []);

  const registerUser = async (registeUserObject: RegisterUserProps) => {
    setLoading(true);
    const { name, email, password, purpose, newsletter_email } = registeUserObject;

    try {
      const userResponse = await firebaseRef.auth().createUserWithEmailAndPassword(email, password);

      if (userResponse.user !== null) {
        const { user } = userResponse;

        const documentObject = {
          name,
          email,
          newsletter_email,
          purpose,
          profileLevel: 0,
          lastEvent: 'CreatedAccount',
        };

        await firebaseRef.firestore().collection('users_customers').doc(user.uid).set(documentObject);
        user.sendEmailVerification();

        return onSuccess(setLoading, 'Account successfully created');
      }
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        return onError(
          setLoading,
          `Your email is already in use. This may happen when you want to create multiple accounts for same email address OR you sign up on B2B app.`,
        );
      }

      return onError(setLoading, `Error occured: ${error.message}`);
    }

    return onError(setLoading, `Unable to create account. Try again later.`);
  };

  const loginUser = async (loginUserObject: LoginUserProps) => {
    const { email, password } = loginUserObject;
    setLoading(true);

    try {
      const userResponse = await firebaseRef.auth().signInWithEmailAndPassword(email, password);

      if (userResponse.user !== null) {
        const { emailVerified } = userResponse.user;

        if (emailVerified) {
          return onSuccess(setLoading, 'User logged in successfully');
        } else {
          return onError(setLoading, 'You need to verify your email address.');
        }
      }
    } catch (error) {
      return onError(setLoading, `Error occured: ${error.message}`);
    }

    return onError(setLoading, `Unable to log in. Try again later.`);
  };

  const logoutUser = async () => {
    setLoading(true);
    try {
      await firebaseRef.auth().signOut();

      return onSuccess(setLoading, 'User logged out successfully');
    } catch (error) {
      return onError(setLoading, `Unable to log out. Try again later.`);
    }
  };

  const changePassword = async (newPassword: string) => {
    setLoading(true);
    try {
      if (currentUser !== null) {
        await currentUser.updatePassword(newPassword);

        return onSuccess(setLoading, 'Password changes successfully.');
      } else {
        return onError(setLoading, `There is no user logged in, so we can not change the password.`);
      }
    } catch (error) {
      return onError(setLoading, `Error occured: ${error.message}`);
    }
  };

  const forgotPassword = async (email: string) => {
    setLoading(true);
    try {
      await firebaseRef.auth().sendPasswordResetEmail(email);

      return onSuccess(setLoading, 'Password reset link sent');
    } catch (error) {
      return onError(setLoading, `Error occured: ${error.message}`);
    }
  };

  const verifyEmail = async (actionCode: string) => {
    setLoading(true);
    try {
      await firebaseRef.auth().applyActionCode(actionCode);

      return onSuccess(setLoading, 'Email verified!');
    } catch (error) {
      return onError(setLoading, `\nError occurred by applying an action code:\n\n${error.message}`);
    }
  };
  useEffect(() => {
    analytics &&
      currentUser &&
      analytics.identify(currentUser.email, { email: currentUser.email, uid: currentUser.uid });
  }, [currentUser]);

  return (
    <AuthenticationContext.Provider
      value={{
        loading,
        authPending,
        currentUser,
        registerUser,
        loginUser,
        logoutUser,
        forgotPassword,
        changePassword,
        verifyEmail,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
