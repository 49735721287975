import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    bottom: '50%',
    left: '55%',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    border: '1px solid black',
    transition: '0.5s ease',
    cursor: 'pointer',
    zIndex: 999,
  },
  arrow: {
    width: '10px',
  },
}));
