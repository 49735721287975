import React, { useEffect } from 'react';
import { Box, Divider, Typography, useTheme } from '@material-ui/core';

import { ProfileLayout } from 'ui/layouts/profileLayout/ProfileLayout';
import { Board } from 'app/main/technology/board/Board';
import { useKnowledge } from 'hooks/useKnowledge/useKnowledge';
import { BoardOrigin } from 'app/main/technology/board/Board.types';
import { Loader } from 'ui/loader/Loader';

import { ProfileTechnologyProps } from './ProfileTechnology.types';
import { useStyles } from './ProfileTechnology.styles';

export const ProfileTechnology: React.FC<ProfileTechnologyProps> = ({ state, setState, onSubmit, user }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { loading, fetchSuggestions, skills } = useKnowledge();

  useEffect(() => {
    onSubmit();
  }, [state]);

  useEffect(() => {
    const getSuggestions = async () => {
      await fetchSuggestions();
    };
    getSuggestions();
  }, []);

  if (loading) return <Loader />;

  return (
    <ProfileLayout>
      <div className={classes.container}>
        <Box mb={6} />
        <div className={classes.title}>
          <Typography variant="h4">Tablica technologii</Typography>
          <Box mb={2} />
          <Typography className={classes.subtitle}>
            Zastanawiasz się skąd wziąć inspiracje? Wybierz z listy rozwijanej nasze propozycje{' '}
            <b>najbardziej popularnych umiejętności</b> dla Twoich zainteresowań lub sprawdź przygotowane przez nas
            ścieżki kariery!
          </Typography>
        </div>
        <Box mb={5} />
        <Divider className={classes.divider} />
        <Box mb={5} />
        <Board state={state} setState={setState} user={user} autocompleteItems={skills} origin={BoardOrigin.PROFILE} />
      </div>
    </ProfileLayout>
  );
};
