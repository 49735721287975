import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router';
import { AlertMessage } from '@hrme/shared';

import { useCareerPathContext } from 'hooks/useCareerPathContext/useCareerPathContext';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { UserCareerPath, UserRequestCareerPath } from 'context/careerPath/CareerPathContext.types';
import { ProfilePaths } from 'routing/profile/ProfilePaths';
import { Loader } from 'ui/loader/Loader';

import { NewCareerPathFormData, NewCareerPathPropsContainer } from './NewCareerPath.types';
import { NewCareerPath } from './NewCareerPath';

const validationSchema = yup.object().shape({
  pathName: yup.string().required('Pole nie może być puste'),
  expectedSalary: yup.number().min(0, 'kwota nie może być ujemna').typeError('Pole nie może być puste'),
});

export const NewCareerPathContainer: React.FC<NewCareerPathPropsContainer> = ({
  isOpen,
  onClose,
  onAddNewCareerPath,
  pathId,
}) => {
  const { careerPaths, fetchCareerPaths, loading } = useCareerPathContext();
  const { addCareerPath, user, updateCareerPath, deleteCareerPath } = useUserContext();
  const history = useHistory();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });
  const [responseMessage, setResponseMessage] = useState<string | undefined>();
  const methods = useForm<NewCareerPathFormData>({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  });
  const currentPath = user?.careerPaths?.filter((careerPath) => careerPath.pathId === pathId)[0];

  const restoreFormValues = () => {
    if (!pathId || !currentPath) return;

    const { pathName, experienceLevel, experienceYears, openForOffers, expectedSalary, name } = currentPath;
    const currentValues = { pathName, experienceLevel, experienceYears, openForOffers, expectedSalary, name };
    methods.reset(currentValues);
  };

  const careerPathItems = useMemo(
    () =>
      careerPaths.map((path) => {
        return { value: path.pathRef, label: path.name };
      }),
    [careerPaths],
  );

  const availableCareerPaths = useMemo(() => {
    const newArray = user?.careerPaths?.map((careerPath) => careerPath.pathRef);
    const filteredArray = careerPaths.filter((careerPath) => !newArray?.includes(careerPath.pathRef));

    return filteredArray.map((path) => {
      return { value: path.pathRef, label: path.name };
    });
  }, [careerPaths]);

  const careerPathIndex = careerPathItems.findIndex((item) => item.value === currentPath?.pathRef);

  const handleSubmit = async (data: NewCareerPathFormData) => {
    if (!data.openForOffers) {
      data.experienceLevel = '';
      data.expectedSalary = 0;
    }

    if (pathId) {
      // edit/update existing career path
      const updatedCareerPath = { ...currentPath, ...data } as UserRequestCareerPath;
      const result = await updateCareerPath(pathId, updatedCareerPath);
      if (!result.success) {
        setResponseMessage('Wystąpił błąd podczas aktualizacji');
        setShowAlertMessage({ show: true, success: false });
      }
    } else {
      // add new career path
      // compose user career path with career path and user input

      const { technology, ...filteredPath } = careerPaths.filter((path) => path.pathRef === data.pathRef)[0];

      const careerPathToAdd = {
        ...filteredPath,
        ...data,
      };
      // add career path to user data
      const result = await addCareerPath(careerPathToAdd as UserCareerPath);
      if (!result.success) {
        setResponseMessage('Wystapił błąd podczas dodawania ścieżki kariery');
        setShowAlertMessage({ show: true, success: false });
      }
    }

    if (onClose) onClose();

    if (onAddNewCareerPath) onAddNewCareerPath(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchCareerPaths();
      if (!result.success) {
        setResponseMessage('Wystąpił błąd podczas pobierania danych');
        setShowAlertMessage({ show: true, success: false });

        return;
      }
      restoreFormValues();
    };

    fetchData();
  }, []);

  if (loading || careerPaths.length < 1) {
    return <Loader />;
  }

  const handleDeleteCurrentCareerPath = async () => {
    if (pathId) {
      const result = await deleteCareerPath(pathId);
      history.replace(ProfilePaths.dashboard);
      if (result.success && onClose) onClose();
    }
  };

  return (
    <>
      <NewCareerPath
        methods={methods}
        isOpen={isOpen}
        handleSubmit={handleSubmit}
        onClose={onClose}
        careerPathItems={availableCareerPaths}
        fullPathTypeItems={careerPathItems}
        pathId={pathId}
        valueIndex={careerPathIndex}
        onDelete={handleDeleteCurrentCareerPath}
      />
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} message={responseMessage} />
    </>
  );
};
