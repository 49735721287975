import { useContext } from 'react';

import { CareerPathContextProps } from 'context/careerPath/CareerPathContext.types';
import { CareerPathContext } from 'context/careerPath/CareerPathContext';

export const useCareerPathContext: () => CareerPathContextProps = () => {
  const context = useContext(CareerPathContext);

  if (context === undefined) {
    throw Error('useCareerPathContext must be used within a CareerPathContext');
  }

  return context;
};
