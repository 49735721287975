export const AuthenticationPaths = {
  login: '/login',
  register: '/register',
  welcomeRegister: '/welcome-register',
  resetPassword: '/reset-password',
  checkEmail: '/check-email',
  enterNewPassword: '/enter-new-password/:actionCode',
  passwordResetConfirmation: '/password-reset-confirmation',
  authPage: '/auth',
  emailVerification: '/verify-email/:actionCode',
  termsAndConditions: '/terms',
  policy: '/policy',
};
