import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: 0,
      justifyContent: 'flex-start',
    },
  },
  logo: {
    width: 100,
  },
  logoWrapper: {
    lineHeight: 0,
  },
  logoInfo: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  divider: {
    width: 1,
    minHeight: 38,
    display: 'block',
    marginLeft: 20,
    marginRight: 20,
    background: '#3E2950',
    opacity: 0.25,
  },
}));
