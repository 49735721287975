import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  offerBoxDetails: {
    display: 'flex',
    justifyContent: 'center',
    rowGap: '8px',
    columnGap: '24px',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  },
  offerBoxDetailsItem: {
    display: 'flex',
    columnGap: '4px',
  },
  strong: {
    fontWeight: 700,
  },
}));
