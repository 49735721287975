import React from 'react';
import { Box, Divider, Typography, useTheme } from '@material-ui/core';
import { CircularProgressWithLabel } from '@hrme/shared/lib/components/ui';

import { BoxProgressProps } from './BoxProgress.types';
import { useStyles } from './BoxProgress.styles';

export const BoxProgress: React.FC<BoxProgressProps> = ({ data }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { actual, developed } = data;

  return (
    <div className={classes.box}>
      <div className={classes.item}>
        <CircularProgressWithLabel value={actual} size={90} fontSize={40.5} />
        <Box mr={3} />
        <Typography variant="body2" className={classes.label}>
          Aktualne umiejętności
        </Typography>
      </div>
      <Box mb={3} />
      <Divider style={{ width: '100%' }} />
      <Box mb={3} />
      <div className={classes.item}>
        <CircularProgressWithLabel value={developed} size={90} fontSize={40.5} mode="warning" />
        <Box mr={3} />
        <Typography variant="body2" className={classes.label}>
          Umiejętności rozwojowe
        </Typography>
      </div>
    </div>
  );
};
