import React from 'react';
import { Backdrop, Modal, useTheme } from '@material-ui/core';

import { AddEditCareerPathModalProps } from './AddEditCareerPathModal.types';
import { useStyles } from './AddEditCareerPathModal.styles';

export const AddEditCareerPathModal: React.FC<AddEditCareerPathModalProps> = ({ children, isModalOpen, onClose }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isModalOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      {children}
    </Modal>
  );
};
