import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  divider: {
    width: '100%',
    margin: theme.spacing(3, 0),
    backgroundColor: '#ccc',
  },
  topWrapper: {
    display: 'grid',
    rowGap: '32px',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  topLeft: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  topRight: {
    display: 'grid',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    rowGap: '16px',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      columnGap: '16px',
      gridTemplateColumns: '1fr 1fr auto',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'auto auto auto',
    },
  },
  circularLabel: {
    fontSize: '20px',
  },
}));
