import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AlertMessage } from '@hrme/shared';

import { useCareerPathFormDispatch } from 'hooks/useCareerPathFormDispatch/useCareerPathFormDispatch';
import { SoftSkillsState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';
import { MainPaths } from 'routing/main/MainPaths';
import { setSoftSkills } from 'context/careerPathForm/careerPathActionCreators/careerPathActionCreators';
import { User } from 'context/user/UserContext.types';
import { useUserContext } from 'hooks/useUserContext/useUserContext';

import { SoftSkills } from './SoftSkills';

const formDefaultValues = { softSkills: [], otherValue: [] };

export const SoftSkillsContainer = (): JSX.Element => {
  const { setUserData, user } = useUserContext();
  const dispatch = useCareerPathFormDispatch();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });

  const history = useHistory();
  const methods = useForm<SoftSkillsState>({
    defaultValues: user?.softSkills !== undefined ? user.softSkills : formDefaultValues,
  });

  if (user === undefined) {
    return <Redirect to={MainPaths.welcomeMain} />;
  }

  const onFormSubmit = async (data: SoftSkillsState, isBack?: boolean, isIdle?: boolean) => {
    const { softSkills, other } = data;

    const payload: SoftSkillsState = {
      softSkills: softSkills.filter((el) => el !== ''),
      other: other,
    };
    if (user.softSkills === payload) {
      history.push(MainPaths.education);

      return;
    }
    const newUser: User = { ...user, softSkills: payload };
    const result = await setUserData(newUser);

    if (!result.success) setShowAlertMessage({ show: true, success: false });
    if (result.success && isIdle) return dispatch(setSoftSkills(data));

    if (result.success) {
      dispatch(setSoftSkills(data));
      !isBack && history.push(MainPaths.education);
    }
  };

  return (
    <>
      <SoftSkills onFormSubmit={onFormSubmit} methods={methods} />
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} />
    </>
  );
};
