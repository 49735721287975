import { User } from 'context/user/UserContext.types';

import { DragItemType } from './ItemData';

export type BoardProps = {
  state: DragItemType[][];
  setState: React.Dispatch<React.SetStateAction<DragItemType[][]>>;
  autocompleteItems?: string[];
  origin?: BoardOrigin;
  user: User;
};

export enum BoardOrigin {
  MAIN = 'main',
  PROFILE = 'profile',
}
