import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { StatsBar } from '@hrme/shared/lib/components/ui';
import { BarElement } from '@hrme/shared/lib/components/ui/statsBar/StatsBar.types';
import { Link } from '@hrme/shared';

import { SKILL_LEVEL } from 'helpers/findSkillLevelByTechnologyName/findSkillLevelByTechnologyName.constants';
import { AddEditCareerPathModal } from 'ui/careerPath/addEditCareerPathModal/AddEditCareerPathModal';
import { NewCareerPathContainer } from 'app/profile/dashboard/careerPathsSection/newCareerPath/NewCareerPathContainer';
import { BoxPathSummaryData } from 'app/careerPathView/careerPathViewPanelRight/CareerPathViewPanelRight.types';
import { ExperienceTypesLabel } from 'app/profile/dashboard/careerPathsSection/newCareerPath/sectionInfo/SectionInfo.constants';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { ViewSelect } from 'app/careerPathView/CareerPathView.types';

import { BoxSummaryProps, HandleAddNewCareerPathParams } from './BoxSummary.types';
import { useStyles } from './BoxSummary.styles';

export const BoxSummary: React.FC<BoxSummaryProps> = ({ data, openModal, closeModal }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { fetchUserData } = useUserContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const { title, barValues, information, pathName, name } = data;

  const [pathInformation, setPathInformation] = useState<BoxPathSummaryData>({ title, barValues, information });

  const barData: BarElement[] = [
    { label: SKILL_LEVEL.KNOWN.label.toLowerCase(), color: SKILL_LEVEL.KNOWN.color, value: barValues.KNOWN },
    { label: SKILL_LEVEL.NONE.label.toLowerCase(), color: SKILL_LEVEL.NONE.color, value: barValues.NONE },
    { label: SKILL_LEVEL.LEARNING.label.toLowerCase(), color: SKILL_LEVEL.LEARNING.color, value: barValues.LEARNING },
    {
      label: SKILL_LEVEL.WANT_TO_LEARN.label.toLowerCase(),
      color: SKILL_LEVEL.WANT_TO_LEARN.color,
      value: barValues.WANT_TO_LEARN,
    },
  ];

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    if (closeModal) closeModal(ViewSelect.CAREER_PATH);
  };

  useEffect(() => {
    if (openModal) handleOpen();
  }, [openModal]);

  const handleAddNewCareerPath = (pathData: HandleAddNewCareerPathParams) => {
    const currentPathInformation = { ...pathInformation };
    if (!pathData) return;
    currentPathInformation.information = {
      'Twoj poziom:': ExperienceTypesLabel[pathData.experienceLevel],
      'szukam pracy:': pathData.openForOffers ? 'tak' : 'nie',
    };
    setPathInformation((prevState) => ({ ...prevState, ...currentPathInformation }));
    fetchUserData();
  };

  return (
    <div className={classes.box}>
      <Typography className={classes.boxDescriptionTitle}>{isDesktop ? title : pathName}</Typography>
      <Box mb={4} />
      {isDesktop && (
        <Typography variant="body2" className={classes.progressTitle}>
          Progress
        </Typography>
      )}
      <Typography>Ścieżka {name}</Typography>
      <Box mb={2} />
      <StatsBar data={barData} />
      <Box mb={4} />
      {isDesktop && (
        <Link className={classes.link}>
          <Typography onClick={handleOpen} variant="body2" style={{ color: theme.palette.grey.A200 }}>
            edytuj
          </Typography>
        </Link>
      )}

      <AddEditCareerPathModal isModalOpen={isModalOpen} onClose={handleClose}>
        {/* wrapping with <></> fought down this message: https://photos.app.goo.gl/b3ikxwt9Y9WX78HS6 */}
        <NewCareerPathContainer
          isOpen={isModalOpen}
          onClose={handleClose}
          pathId={data.pathId}
          onAddNewCareerPath={handleAddNewCareerPath}
        />
      </AddEditCareerPathModal>
    </div>
  );
};
