/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
//@ts-ignore
import { Draggable } from 'react-beautiful-dnd';
import { Typography, Box, IconButton, useTheme, useMediaQuery } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import Pen from 'assets/icon/change.svg';
import Trash from 'assets/icon/trash.svg';
import DndHandler from 'assets/dnd-handler.svg';
import { MobilePopup } from 'ui/mobilePopup/MobilePopup';
import { ModalType, StepStatus } from 'ui/careerPath/careerSteps/careerPathStepSummary/CareerPathStepSummary.types';

import { ItemProps } from './Item.types';
import { getItemStyle, useStyles } from './Item.styles';

export const Item = ({ item, index, onRemove, origin }: ItemProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [openModal, setOpenModal] = useState({ openModal: false, type: ModalType.INFO });

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const data = {
    summary: {
      status: StepStatus.GREEN,
      title: item.label,
      skillsCompleted: 0,
      skills: 0,
      pathName: '',
      shareOfPositions: '',
      shareOfOffers: '',
    },
    details: {
      info: '',
      technologyId: 0,
      name: item.label,
      group: '',
    },
  };

  return (
    <div>
      <Draggable key={item.id} draggableId={item.id} index={index}>
        {(provided: any, snapshot: any) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, origin)}
          >
            <img src={DndHandler} alt="icon for handling" />
            <Box mr={2} />
            <div className={classes.itemContainer}>
              <Typography>{item.label}</Typography>

              {isDesktop ? (
                <IconButton onClick={onRemove} aria-label="delete">
                  <Close />
                </IconButton>
              ) : (
                <div style={{ display: 'flex' }}>
                  <div
                    className={classes.button}
                    onClick={() => setOpenModal({ openModal: true, type: ModalType.LEVEL })}
                  >
                    <img src={Pen} alt="pen" />
                  </div>
                  <div className={classes.button} onClick={onRemove}>
                    <img src={Trash} alt="delete" />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Draggable>
      <MobilePopup openModal={openModal} data={data} setOpenModal={setOpenModal} techBoard />
    </div>
  );
};
