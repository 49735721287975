/* eslint-disable react/jsx-props-no-spreading */
import { Box, useTheme } from '@material-ui/core';
import React from 'react';

import { CheckboxChipGroup } from 'ui/inputs/checkboxChipGroup/CheckboxChipGroup';
import { RadioChipGroup } from 'ui/inputs/radioChipGroup/RadioChipGroup';
import { TagInputExtended } from 'ui/inputs/tagInputExtended/TagInputExtended';

import { useStyles } from './RadioChipGroupComponent.styles';
import { RadioChipGroupComponentProps, RadioChipGroupComponentType } from './RadioChipGroupComponent.types';

export const RadioChipGroupComponent: React.FC<RadioChipGroupComponentProps> = ({
  positionItems,
  methods,
  userOtherPosition = [],
  inSettings,
  name,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const otherListener = methods.watch('other') as string[];

  const otherItems = otherListener?.map((el: string, index: number) => {
    return { key: index, label: el };
  });

  const tagsCommonOptions = {
    label: 'inne (wprowadź)',
    inverse: true,
    noMinHeight: true,
    methods: methods,
    wide: true,
  };

  if (name === RadioChipGroupComponentType.SPECIALIZATION) {
    return (
      <>
        <CheckboxChipGroup label="" items={positionItems || []} name={name} methods={methods} inSettings={inSettings} />
        <Box mb={3} />
        <div className={classes.chipGroupWrapper}>
          <TagInputExtended items={otherItems} maxItems={10} name={`other`} {...tagsCommonOptions} />
        </div>
      </>
    );
  }

  return (
    <>
      <RadioChipGroup
        label={''}
        items={positionItems}
        name={name}
        methods={methods}
        background={true}
        inSettings={inSettings}
      />
      <Box mb={3} />
      <div className={classes.inputWrapper} onClick={() => methods.setValue('position_name', 'other')}>
        <TagInputExtended items={userOtherPosition} maxItems={1} name={`other_position`} {...tagsCommonOptions} />
      </div>
    </>
  );
};
