import React, { useState } from 'react';

import { firebaseRef } from 'api/firebaseRef';
import { onError, onSuccess } from '../providersHelpers';
import { DiscountsOnTutorialsContext } from 'context/discountsOnTutorials/DiscountsOnTutorialsContext';
import {
  tutorialFromFirebase,
  bannerOfTutorialFromFB,
  descriptionOfTutorialFromFB,
} from 'context/discountsOnTutorials/DiscountsOnTutorialsContext.types';

export const DiscountsOnTutorialsProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [tutorials, setTutorials] = useState<descriptionOfTutorialFromFB[]>([]);
  const [banners, setBanners] = useState<bannerOfTutorialFromFB[]>([]);

  const fetchDiscountsOnTutorials = async () => {
    if (!tutorials.length && !banners.length) {
      setLoading(true);
      const suggestionsQuery = await firebaseRef.firestore().collection('discounts_on_tutorials').get();
      const tutorialsArray: descriptionOfTutorialFromFB[] = [];
      const bannersArray: bannerOfTutorialFromFB[] = [];
      if (suggestionsQuery !== null) {
        suggestionsQuery.forEach((careerPathsItem) => {
          const data = careerPathsItem.data() as tutorialFromFirebase;
          const {
            banner,
            discount,
            imageURL,
            link,
            code,
            autor,
            description,
            name,
            career_path_with_banner,
            showOverCareerPathStep,
            showOnCareerPathSummeryBanner = false,
          } = data;
          bannersArray.push({
            banner,
            code,
            career_path_with_banner,
            link,
            discount,
            name,
            showOverCareerPathStep,
            showOnCareerPathSummeryBanner,
          });
          tutorialsArray.push({ discount, imageURL, link, code, autor, description, name });
        });
        setTutorials(tutorialsArray);
        setBanners(bannersArray);

        return onSuccess(setLoading, `Technologies' names have been successfully fetched`);
      } else {
        return onError(setLoading, 'Unable to fetch any technology name');
      }
    }
  };

  return (
    <DiscountsOnTutorialsContext.Provider value={{ loading, fetchDiscountsOnTutorials, tutorials, banners }}>
      {children}
    </DiscountsOnTutorialsContext.Provider>
  );
};
