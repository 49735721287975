import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { delay } from 'helpers/delay';

import { ResetPassword } from './ResetPassword';
import { Alert, ResetPasswordFormData } from './ResetPassword.types';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Must be an valid email.').required('Field required.'),
});

export const ResetPasswordContainer = (): JSX.Element => {
  const { forgotPassword } = useAuthentication();
  const [alert, setAlert] = useState<Alert>({ canShow: false, success: false, message: '' });

  const methods = useForm<ResetPasswordFormData>({ resolver: yupResolver(validationSchema), reValidateMode: 'onBlur' });

  const onFormSubmit = async (data: ResetPasswordFormData) => {
    const response = await forgotPassword(data.email);

    setAlert({ canShow: true, success: response.success, message: response.message ? response.message : '' });

    await delay(4000);

    setAlert({ canShow: false, success: response.success, message: response.message ? response.message : '' });
  };

  return <ResetPassword methods={methods} onFormSubmit={onFormSubmit} alert={alert} />;
};
