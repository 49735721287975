import React, { useState } from 'react';
import { Backdrop, Box, Modal, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { BoxList } from 'ui/boxes/boxList/BoxList';
import { BoxListItem } from 'ui/boxes/boxListItem/BoxListItem';
import { BoxSocialMedia } from 'ui/boxes/boxSocialMedia/BoxSocialMedia';
import { BoxProgress } from 'ui/boxes/boxProgress/BoxProgress';
import { BoxAccept } from 'ui/boxes/boxAccept/BoxAccept';
import { JobOfferRejection } from 'ui/modals/jobOfferRejection/JobOfferRejection';
import { InvitationStatus } from 'context/jobOffer/JobOfferContext.types';
import { useJobOffers } from 'hooks/useJobOffers/useJobOffers';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { ProfilePaths } from 'routing/profile/ProfilePaths';

import { JobOfferViewPanelLeftProps } from './JobOfferViewPanelLeft.types';
import { useStyles } from './JobOfferViewPanelLeft.styles';

export const JobOfferViewPanelLeft: React.FC<JobOfferViewPanelLeftProps> = ({
  BOX_ACCEPT_DATA,
  BOX_PERKS_DATA,
  BOX_PROGRESS_DATA,
  BOX_SOCIALMEDIA_DATA,
  BOX_TIME_PLACE_DATA,
  jobOfferRef,
  collectionType,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const { user } = useUserContext();

  const { updateJobStatus } = useJobOffers();

  const [accepted, setAccepted] = useState<boolean>(BOX_ACCEPT_DATA.accepted);
  const [rejected, setRejected] = useState<boolean>(BOX_ACCEPT_DATA.rejected);

  const [open, setOpen] = React.useState(false);

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    updateJobStatus({
      jobOfferRef,
      status: InvitationStatus.ACCEPTED,
      userName: user?.name || '',
      collection: collectionType,
    });
    setAccepted(true);
  };

  const handleReject = () => {
    handleModalOpen();
  };

  const handleRejectionConfirmed = () => {
    updateJobStatus({
      jobOfferRef,
      status: InvitationStatus.REJECTED,
      userName: user?.name || '',
      collection: collectionType,
    });
    setRejected(() => true);
    handleModalClose();
    history.push(ProfilePaths.dashboard);
  };

  return (
    <div className={classes.panel}>
      <BoxAccept data={{ ...BOX_ACCEPT_DATA, accepted, rejected }} onAccept={handleAccept} onReject={handleReject} />
      <Box mb={2} />

      <BoxProgress data={BOX_PROGRESS_DATA} />
      <Box mb={2} />

      {/* Section: CZAS I MIEJSCE PRACY */}
      <BoxList
        title={'Czas i miejsce pracy'}
        content={[
          <BoxListItem label={'Od kiedy:'} description={BOX_TIME_PLACE_DATA['Od kiedy:']} />,
          <BoxListItem label={'Wymiar czasu pracy:'} description={BOX_TIME_PLACE_DATA['Wymiar czasu pracy:']} />,
          <BoxListItem label={'Miejsce pracy:'} description={BOX_TIME_PLACE_DATA['Miejsce pracy:']} />,
          <BoxListItem label={'Miasto:'} description={BOX_TIME_PLACE_DATA['Miasto:']} />,
          <BoxListItem label={'Ulica:'} description={BOX_TIME_PLACE_DATA['Ulica:']} />,
          <BoxListItem label={'Godziny pracy:'} description={BOX_TIME_PLACE_DATA['Godziny pracy:']} />,
        ]}
      />
      <Box mb={2} />

      {/* Section: DODATKI */}
      <BoxList
        title={'Dodatki'}
        content={[
          <BoxListItem label={'Płatny urlop:'} description={BOX_PERKS_DATA['Platny urlop:']} />,
          <BoxListItem label={'Pakiet relokacyjny:'} description={BOX_PERKS_DATA['Pakiet relokacyjny:']} />,
          <BoxListItem label={'Benefity:'} description={BOX_PERKS_DATA['Benefity:']} />,
        ]}
      />
      <Box mb={2} />

      <BoxSocialMedia data={BOX_SOCIALMEDIA_DATA} />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <JobOfferRejection isOpen={open} onClose={handleModalClose} onConfirm={handleRejectionConfirmed} />
      </Modal>
    </div>
  );
};
