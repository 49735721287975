import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { AlertMessage } from '@hrme/shared';

import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { AuthenticationPaths } from 'routing/auth/AuthenticationPaths';
import { EmailConfirmation } from 'ui/emailConfirmation/EmailConfirmation';

import { EmailVerificationParams } from './EmailVerification.types';

export const EmailVerification = (): JSX.Element => {
  const { verifyEmail, loading, logoutUser } = useAuthentication();
  const history = useHistory();
  const { actionCode } = useParams<EmailVerificationParams>();
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });
  const [responseMessage, setResponseMessage] = useState<string | undefined>();

  const handleClick = () => history.replace(AuthenticationPaths.login);

  useEffect(() => {
    const logout = async () => {
      await logoutUser();
    };
    logout();
  }, []);

  useEffect(() => {
    const performEmailValidation = async () => {
      const verificationResponse = await verifyEmail(actionCode);
      if (!verificationResponse.success) {
        setResponseMessage('Błąd weryfikacji adresu email.');
        setShowAlertMessage({ show: true, success: false });

        return;
      }
    };
    performEmailValidation();
  }, []);

  return (
    <>
      <EmailConfirmation
        title={'Witaj w gronie HR-ME'}
        subtitle={'Kliknięcie "zaloguj się" aktywuje Twoje konto w systemie'}
        caption={'zaloguj się'}
        onCaptionClicked={handleClick}
        noEmailMessage={false}
        loading={loading}
        platformSelect={true}
      />
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} message={responseMessage} />
    </>
  );
};
