/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Prompt, Redirect } from 'react-router-dom';
import { ButtonDefault } from '@hrme/shared';
import { AlertMessage } from '@hrme/shared';

import { SettingsLayout } from 'ui/layouts/settingsLayout/SettingsLayout';
import { BoxWrapper } from '../components/BoxWrapper';
import { LanguageItem } from 'ui/languageItem/LanguageItem';
import { ADD_LANGUAGE_ITEM, LanguageFormState } from 'app/main/languages/Languages.types';
import { ButtonOther } from 'ui/buttonOther/ButtonOther';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { User } from 'context/user/UserContext.types';
import { ProfilePaths } from 'routing/profile/ProfilePaths';
import { ChipData } from 'ui/inputs/tagInputExtended/TagInputExtended.types';
import { LanguagesState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';

import { LanguagesSettingsProps } from './LanguagesSettings.types';
import { useStyles } from './LanguagesSettings.styles';

export const LanguagesSettings: React.FC<LanguagesSettingsProps> = () => {
  const { user, setUserData } = useUserContext();
  const theme = useTheme();
  const classes = useStyles(theme);

  const methods = useForm<LanguageFormState>({ defaultValues: { language: user?.languages } });
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });
  const [showSavePrompt, setShowSavePrompt] = useState(false);

  const [languageItems, setLanguageItems] = useState<LanguagesState>(
    user?.languages ? user.languages : [ADD_LANGUAGE_ITEM],
  );

  useEffect(() => {
    setLanguageItems(user?.languages ? user.languages : [ADD_LANGUAGE_ITEM]);
  }, [user]);

  if (user === undefined) {
    return <Redirect to={ProfilePaths.dashboard} />;
  }

  const onContentChange = async () => setShowSavePrompt(true);

  const onFormSubmit = async (data: LanguageFormState) => {
    const language = data.language.filter((item) => !!item.language);

    if (language === user.languages) {
      return;
    }

    const newUser: User = { ...user, languages: language };

    setLanguageItems([...language]);

    const response = await setUserData(newUser);
    setShowAlertMessage({ show: true, success: response.success });
    setShowSavePrompt(false);
  };

  const addItem = () => {
    setLanguageItems((prevState) => {
      const newLanguageItems = [...prevState];
      newLanguageItems.push({ ...ADD_LANGUAGE_ITEM });

      return [...newLanguageItems];
    });
  };

  const deleteItem = (index: number) => {
    setLanguageItems((prevState) => {
      const newLanguageItems = [...prevState];
      newLanguageItems.splice(index, 1);

      return [...newLanguageItems];
    });
  };

  return (
    <SettingsLayout>
      <BoxWrapper>
        <Prompt when={showSavePrompt} message="Twoje dane nie zostały zapisane. Czy chcesz kontynuować?" />
        <form onSubmit={methods.handleSubmit(onFormSubmit)} onChange={onContentChange}>
          <Typography align="left" variant="h4">
            Języki obce
          </Typography>
          <Box mb={4} />
          <div className={classes.itemsContainer}>
            {languageItems.map((item, key) => {
              const fieldName = `language[${key}]`;
              const { certificate, ...values } = item;

              const chipCertificates: ChipData[] = certificate.map((item, index) => {
                return { key: index, label: item };
              });

              return (
                <div key={key}>
                  <LanguageItem
                    certificate={chipCertificates}
                    methods={methods}
                    prefix={fieldName}
                    deleteItem={() => deleteItem(key)}
                    {...values}
                  />
                  {key !== languageItems.length - 1 && <Box mb={4} />}
                </div>
              );
            })}
          </div>
          <Box mb={2} />
          <ButtonOther onClick={addItem}>Dodaj kolejny język</ButtonOther>
          <Box mb={4} />
          <ButtonDefault>zapisz dane</ButtonDefault>
        </form>
      </BoxWrapper>
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} />
    </SettingsLayout>
  );
};
