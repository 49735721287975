import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { MainRoutes } from 'routing/main/MainRoutes';
import { ProfileRoutes } from 'routing/profile/ProfileRoutes';
import { SettingsRoutes } from 'routing/settings/SettingsRoutes';
import { useUserContext } from 'hooks/useUserContext/useUserContext';

export const DebugHelper = (): JSX.Element => {
  const { currentUser } = useAuthentication();
  const { user } = useUserContext();
  const [open, setOpen] = useState(false);

  return (
    <div
      style={{
        position: 'absolute',
        background: '#bababa',
        padding: 10,
        zIndex: 999,
        borderRadius: 8,
        top: 8,
        left: 8,
        opacity: '0.2',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <p>Magic Debug Helper</p>
        <Box mr={2} />
        <button onClick={() => setOpen(!open)}>{open ? 'hide' : 'open'}</button>
      </div>
      <div style={{ display: open ? 'block' : 'none' }}>
        <p style={{ marginBottom: 0 }}>firebase user: {currentUser?.email}</p>
        <p style={{ margin: 0 }}>app user: {user?.email}</p>
        <p style={{ margin: 0 }}>email verified: {currentUser?.emailVerified.toString()}</p>
        <p style={{ marginTop: 0 }}>id: {currentUser?.uid}</p>

        {MainRoutes.map((item, index) => (
          <div key={index} style={{ padding: 4, borderRadius: 8, background: '#f0f0f0', marginBottom: 10 }}>
            <Link style={{ display: 'block', color: '#000' }} to={item.path}>
              {item.path}
            </Link>
          </div>
        ))}

        <p style={{ marginBottom: 8 }}>PROFILE</p>

        {ProfileRoutes.map((item, index) => (
          <div key={index} style={{ padding: 4, borderRadius: 8, background: '#f0f0f0', marginBottom: 10 }}>
            <Link style={{ display: 'block', color: '#000' }} to={item.path}>
              {item.path}
            </Link>
          </div>
        ))}
        {SettingsRoutes.map((item, index) => (
          <div key={index} style={{ padding: 4, borderRadius: 8, background: '#f0f0f0', marginBottom: 10 }}>
            <Link style={{ display: 'block', color: '#000' }} to={item.path}>
              {item.path}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
