import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  tableContainer: {},
  table: {
    width: '100%',
    minWidth: 350,
    border: 0,
  },
  tableHeader: {
    color: theme.palette.grey['800'],
  },
}));
