import { AccountSettings } from 'app/profile/settings/accountSettings/AccountSettings';
import { CertificatesSettings } from 'app/profile/settings/certificatesSettings/CertificatesSettings';
import { EducationSettings } from 'app/profile/settings/educationSettings/EducationSettings';
import { ExperienceSettingsContainer } from 'app/profile/settings/experienceSettings/ExperienceSettingsContainer';
import { LanguagesSettings } from 'app/profile/settings/languagesSettings/LanguagesSettings';
import { PortfolioSettings } from 'app/profile/settings/portfolioSettings/PortfolioSettings';
import { ProfileSettings } from 'app/profile/settings/profileSettings/ProfileSettings';
import { SoftSkillsSettings } from 'app/profile/settings/softSkillsSettings/SoftSkillsSettings';

import { SettingsPaths } from './SettingsPaths';

const SETTINGS_SLUG = '/settings';

export const SettingsRoutes = [
  {
    id: 0,
    path: SETTINGS_SLUG + SettingsPaths.profile,
    component: ProfileSettings,
    name: 'Podstawowe Dane',
  },
  {
    id: 1,
    path: SETTINGS_SLUG + SettingsPaths.experience,
    component: ExperienceSettingsContainer,
    name: 'Doświadczenie',
  },
  {
    id: 2,
    path: SETTINGS_SLUG + SettingsPaths.softSkills,
    component: SoftSkillsSettings,
    name: 'Umiejętności miękkie',
  },
  {
    id: 1,
    path: SETTINGS_SLUG + SettingsPaths.education,
    component: EducationSettings,
    name: 'Wykształcenie i edukacja',
  },
  {
    id: 2,
    path: SETTINGS_SLUG + SettingsPaths.certificates,
    component: CertificatesSettings,
    name: 'Certyfikaty i szkolenia',
  },
  {
    id: 5,
    path: SETTINGS_SLUG + SettingsPaths.languages,
    component: LanguagesSettings,
    name: 'Języki obce',
  },
  {
    id: 6,
    path: SETTINGS_SLUG + SettingsPaths.portfolio,

    component: PortfolioSettings,
    name: 'Portfolio',
  },
  {
    id: 7,
    path: SETTINGS_SLUG + SettingsPaths.account,
    component: AccountSettings,
    name: 'Ustawienia konta',
  },
];
