import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

import { BoxSubtitleProps } from './BoxSubtitle.types';
import { useStyles } from './BoxSubtitle.styles';

export const BoxSubtitle: React.FC<BoxSubtitleProps> = ({ items = [] }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.subtitle}>
      {items.map((item, index) => (
        <div key={index} className={classes.subtitle}>
          <Typography variant="body2" align="left">
            {item.label}
          </Typography>
          <Box mr={0.5} />
          <Typography className={classes.subtitleStrong} align="left">
            {item.value}
          </Typography>
          {index !== items?.length - 1} <Box mr={2} />
        </div>
      ))}
    </div>
  );
};
