import React from 'react';
import { Typography, useTheme } from '@material-ui/core';

import { useStyles } from './Topic.styles';

export const Topic = (): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Typography className={classes.typographyStyle}>
      Career path <span className={classes.spanStyle}>Beta</span>
    </Typography>
  );
};
