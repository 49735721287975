/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Box, Chip, InputLabel, Paper, Typography, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useState } from 'react';
import clsx from 'clsx';

import { ChipAutocomplete } from 'ui/inputs/chipAutocomplete/ChipAutocomplete';
import { AutocompleteOptionType } from 'ui/inputs/chipAutocomplete/ChipAutocomplete.types';
import { useUserContext } from 'hooks/useUserContext/useUserContext';

import { TagInputExtendedProps } from './TagInputExtended.types';
import { ChipData } from './TagInputExtended.types';
import { useStyles } from './TagInputExtended.styles';

export const TagInputExtended: React.FC<TagInputExtendedProps> = ({
  label,
  subtitle,
  required,
  items = [],
  methods,
  name,
  maxItems,
  autocompleteOptions = [],
  inverse = false,
  tiny = false,
  noMinHeight = false,
  wide,
  setChips,
  saveOnBlur,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { user, setUserData } = useUserContext();

  const [chipData, setChipData] = useState<ChipData[]>(items ? items : []);
  const [autocompleteValue, setAutocompleteValue] = useState<AutocompleteOptionType | null>(null);

  useEffect(() => {
    if (setChips) setChips(chipData);
  }, [chipData]);

  useEffect(() => {
    if (name) methods?.register(name);
  }, [methods, name]);

  useEffect(() => {
    if (name)
      methods?.setValue(
        name,
        chipData.map((item) => item.label),
      );
  }, [name, chipData]);

  const addChip = async () => {
    if (!autocompleteValue || (!autocompleteValue.inputValue && !autocompleteValue.title)) return;
    if (chipData.some((item) => item.label.toLowerCase() === autocompleteValue.title.toLowerCase())) return;

    await setChipData((prevData) => [
      ...prevData,
      {
        key: chipData.length,
        label: autocompleteValue?.inputValue
          ? (autocompleteValue?.inputValue as string)
          : (autocompleteValue?.title as string),
      },
    ]);
    setAutocompleteValue(null);
    if (saveOnBlur) saveOnBlur();
  };
  useEffect(() => {
    if (name === 'other_position') {
      if (!chipData.length || !items.length) setChipData([]);

      if (!chipData.length && items.length) {
        setChipData([]);
        methods?.setValue('position_name', 'other');
      }
    }
  }, [chipData.length, items.length]);

  const deleteChip = async (label: string) => {
    if (!methods || !user || !user.technology) return;

    const {
      technology: { known, wantToLearn, learning },
    } = user;

    const filteredTech = known.filter((item: string) => item !== label);

    const newUser = {
      ...user,
      technology: {
        known: filteredTech,
        wantToLearn: wantToLearn,
        learning: learning,
      },
    };
    setUserData(newUser);
  };

  return (
    <div className={classes.container} {...(wide && { style: { width: '100%' } })}>
      <InputLabel>
        <Typography variant="subtitle1" className={classes.label}>
          {label}
          {required && <span style={{ color: theme.palette.primary.main }}>*</span>}
        </Typography>
        {subtitle && (
          <Typography variant="caption" className={classes.subtitle}>
            {subtitle}
          </Typography>
        )}
      </InputLabel>
      <Box mb={1} />
      <Paper
        variant="outlined"
        // component={'div' as 'ul'}
        className={clsx({
          [classes.root]: true,
          [classes.rootInverse]: inverse,
          [classes.rootNoMinHeight]: noMinHeight,
        })}
      >
        {chipData.map((data) => {
          const handleDelete = (chipToDelete: ChipData) => () => {
            deleteChip(data.label);
            setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
          };

          return (
            <li key={data.key}>
              <Chip
                size="medium"
                label={data.label}
                onDelete={handleDelete(data)}
                className={clsx({ [classes.chip]: true, [classes.chipTiny]: tiny, [classes.chipInverse]: inverse })}
                deleteIcon={<CloseIcon fontSize="small" />}
              />
            </li>
          );
        })}
        <Box ml={0} />
        {(maxItems === undefined || chipData.length < maxItems) && (
          <ChipAutocomplete
            autocompleteOptions={autocompleteOptions}
            addChip={addChip}
            autocompleteValue={autocompleteValue}
            setAutocompleteValue={setAutocompleteValue}
            disableUnderline={false}
            autocompleteClassName={classes.autocomplete}
            textFieldClassName={classes.textField}
          />
        )}
      </Paper>
    </div>
  );
};
