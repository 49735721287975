import React from 'react';
import { Box, useTheme } from '@material-ui/core';

import { CheckboxChipGroup } from 'ui/inputs/checkboxChipGroup/CheckboxChipGroup';
import { TagInputExtended } from 'ui/inputs/tagInputExtended/TagInputExtended';

import { CheckboxChipGroupWithOthersInputProps } from './CheckboxChipGroupWithOthersInput.types';
import { useStyles } from './CheckboxChipGroupWithOthersInput.styles';

export const CheckboxChipGroupWithOthersInput: React.FC<CheckboxChipGroupWithOthersInputProps> = ({
  label,
  items,
  name,
  placeholder,
  methods,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const otherListener = methods.watch('other');

  const otherItems = otherListener?.map((el: string, index: number) => {
    return { key: index, label: el };
  });

  return (
    <>
      <CheckboxChipGroup label={label} items={items} name={name} methods={methods} />
      <Box mb={3} />
      <div className={classes.chipGroupWrapper}>
        <TagInputExtended
          label={'inne'}
          items={otherItems || []}
          maxItems={10}
          inverse
          noMinHeight
          name={`other`}
          methods={methods}
          wide
        />
      </div>
    </>
  );
};
