import React, { useEffect } from 'react';
import { ButtonDefault, Link } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router';

import { MainFlowLayout } from 'ui/layouts/mainFlowLayout/MainFlowLayout';
import { useKnowledge } from 'hooks/useKnowledge/useKnowledge';
import { Topic } from 'ui/topic/Topic';
import { Loader } from 'ui/loader/Loader';

import { TechnologyProps } from './Technology.types';
import { useStyles } from './Technology.styles';
import { Board } from './board/Board';

export const Technology: React.FC<TechnologyProps> = ({ state, setState, onSubmit, user }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const { loading, fetchSuggestions, skills } = useKnowledge();

  useEffect(() => {
    const getSuggestions = async () => {
      await fetchSuggestions();
    };
    getSuggestions();
  }, []);

  const stepBack = () => {
    onSubmit(true);
    history.goBack();
  };

  useEffect(() => {
    onSubmit(undefined, true);
  }, [state]);

  if (loading) return <Loader />;

  return (
    <MainFlowLayout topic={<Topic />} progress={{ label: 'Tablica technologii', length: 10, current: 5 }}>
      <div className={classes.container}>
        <Box mb={3}>
          <Typography variant="h2" className={classes.header}>
            Twoje umiejętności
          </Typography>
        </Box>
        <Box mb={6}>
          <Typography variant="body2" className={classes.subheader}>
            Wpisz swoje umiejętności. Jeżeli ich nie posiadasz, przejdź dalej. Zastanawiasz się skąd wziąć inspiracje?
            <br />
            <strong>
              W rozwijanej liście znajdziesz nasze propozycje najbardziej popularnych umiejętności dla Twoich
              zainteresowań!
            </strong>
          </Typography>
        </Box>
        <div className={classes.wrapper}>
          <Board state={state} setState={setState} autocompleteItems={skills} user={user} />
        </div>
        <div className={classes.buttonContainer}>
          <ButtonDefault onClick={() => onSubmit()}>następny krok (umiejętności miękkie)</ButtonDefault>
          <Box mb={2} />
          <Link>
            <Typography onClick={stepBack}>cofnij</Typography>
          </Link>
        </div>
      </div>
    </MainFlowLayout>
  );
};
