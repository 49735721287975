import React, { useEffect } from 'react';
import { ButtonDefault, Link } from '@hrme/shared';
import { useHistory } from 'react-router';
import { Box, Typography, useTheme } from '@material-ui/core';

import { MainFlowLayout } from 'ui/layouts/mainFlowLayout/MainFlowLayout';
import { CheckboxChipGroupWithOthersInput } from 'ui/checkboxChipGroupWithOthersInput/CheckboxChipGroupWithOthersInput';
import { Topic } from 'ui/topic/Topic';
import { checkKeyDown } from 'helpers/checkKeyDown';

import { useStyles } from './SoftSkills.styles';
import { SOFT_SKILLS_ITEMS, SoftSkillsProps } from './SoftSkills.types';

export const SoftSkills: React.FC<SoftSkillsProps> = ({ methods, onFormSubmit }) => {
  const { handleSubmit } = methods;
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const skills = methods.watch(['other', 'softSkills']);
  const { other, softSkills } = skills;
  const allowNextStep = other?.length > 0 || softSkills?.length > 0;

  const stepBack = () => {
    onFormSubmit(methods.getValues(), true);
    history.goBack();
  };

  useEffect(() => {
    onFormSubmit(methods.getValues(), undefined, true);
  }, [other, softSkills]);

  return (
    <>
      <MainFlowLayout topic={<Topic />} progress={{ label: 'Umiejętności miękkie', length: 10, current: 6 }}>
        <form
          className={classes.container}
          onSubmit={handleSubmit((d) => onFormSubmit(d))}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <Typography variant="h2" className={classes.header}>
            Umiejętności miękkie
          </Typography>
          <Box mb={3} />
          <Typography variant="body2" className={classes.subheader}>
            Wybierz najbardziej pasujące umiejętności
          </Typography>
          <Box mb={4.25} />
          <CheckboxChipGroupWithOthersInput
            placeholder="Wprowadź umiejętność"
            label={''}
            items={SOFT_SKILLS_ITEMS}
            name="softSkills"
            methods={methods}
          />
          <Box mb={3.75} />
          <div className={classes.wrapper}>
            <ButtonDefault disabled={!allowNextStep}>następny krok (wykształcenie)</ButtonDefault>
            <Box mb={2} />
            <Link>
              <Typography onClick={stepBack}>cofnij</Typography>
            </Link>
          </div>
        </form>
      </MainFlowLayout>
    </>
  );
};
