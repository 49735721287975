import React from 'react';
import { Typography, useTheme } from '@material-ui/core';

import { PlatformHeaderMain } from 'ui/platformHeaderMain/PlatformHeaderMain';
import { ProgressBarMain } from 'ui/progressBarMain/ProgressBarMain';

import { MainFlowLayoutProps } from './MainFlowLayout.types';
import { useStyles } from './MainFlowLayout.styles';

export const MainFlowLayout: React.FC<MainFlowLayoutProps> = ({
  children,
  topic = <Typography variant="body2">Platforma rozwoju kariery</Typography>,
  progress = {},
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <div className={classes.header}>
        <div className={classes.headerWrapper}>
          <PlatformHeaderMain>{topic}</PlatformHeaderMain>
          {progress.current && (
            <ProgressBarMain label={progress.label} length={progress.length} current={progress.current} />
          )}
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </>
  );
};
