import { useContext } from 'react';

import { UserContextProps } from 'context/user/UserContext.types';
import { UserContext } from 'context/user/UserContext';

export const useUserContext: () => UserContextProps = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw Error('useUserContext must be used within an UserContext');
  }

  return context;
};
