import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  boxDescriptionTitle: {
    fontSize: '18px',
    fontWeight: 600,
  },
  progressTitle: {
    fontWeight: 600,
  },
}));
