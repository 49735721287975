import React from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { typeConvert } from '@hrme/shared';

import { JobOfferBoxDetailsProps } from './JobOfferBoxDetails.types';
import { useStyles } from './JobOfferBoxDetails.styles';

export const JobOfferBoxDetails: React.FC<JobOfferBoxDetailsProps> = ({ details }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.offerBoxDetails}>
      <div className={classes.offerBoxDetailsItem}>
        <Typography variant="subtitle1">Firma:</Typography>
        <Typography variant="subtitle1" className={classes.strong}>
          {details.company}
        </Typography>
      </div>

      <div className={classes.offerBoxDetailsItem}>
        <Typography variant="subtitle1">Zarobki:</Typography>
        <Typography variant="subtitle1" className={classes.strong}>
          {`${details.salaryRangeFrom}`} {`${+details.salaryRangeTo > 0 ? `- ${details.salaryRangeTo} ` : ''}`}
          {details.currency} {details.isNetto ?? details.salaryOnHand ? 'netto' : 'brutto'}
        </Typography>
      </div>
      <div className={classes.offerBoxDetailsItem}>
        <Typography variant="subtitle1">Rodzaj umowy:</Typography>
        <Typography variant="subtitle1" className={classes.strong}>
          {details.contractType.map((type) => typeConvert(type)).join(', ')}
        </Typography>
      </div>
      <div className={classes.offerBoxDetailsItem}>
        <Typography variant="subtitle1">Lokalizacja:</Typography>
        <Typography variant="subtitle1" className={classes.strong}>
          {details.companyCity}
        </Typography>
      </div>
      <div className={classes.offerBoxDetailsItem}>
        <Typography variant="subtitle1">Główna technologia:</Typography>
        <Typography variant="subtitle1" className={classes.strong}>
          {details.mainTechnology}
        </Typography>
      </div>
    </div>
  );
};
