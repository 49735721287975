import React, { useEffect } from 'react';
import { Link, TextSelect } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';
import { Controller, UseFormMethods } from 'react-hook-form';

import { RadioChipGroup } from 'ui/inputs/radioChipGroup/RadioChipGroup';
import { TagInputExtended } from 'ui/inputs/tagInputExtended/TagInputExtended';

import { LanguageItemProps, LANGUAGES, LEVEL_ITEMS } from './LanguageItem.types';
import { useStyles } from './LanguageItem.styles';

export const LanguageItem: React.FC<LanguageItemProps> = ({
  language,
  level,
  certificate,
  methods,
  prefix,
  deleteItem,
  setFieldValid,
  indexKey,
  setChips,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { watch, setValue } = methods as UseFormMethods<Record<string, string>>;

  useEffect(() => {
    setValue(`${prefix}.language`, language);
    setValue(`${prefix}.level`, level);
  }, [watch]);

  return (
    <div className={classes.container}>
      <Controller
        control={methods?.control}
        name={`${prefix || ''}.language`}
        render={({ onChange, value = language, ref }) => (
          <TextSelect
            label="wybór języka"
            items={LANGUAGES}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
              if (setFieldValid)
                setFieldValid((prevState) => ({
                  ...prevState,
                  chosenLanguage: e.target.value as string,
                  fieldIndex: indexKey as number,
                }));
            }}
            ref={ref}
          />
        )}
      />
      <RadioChipGroup
        label={'poziom znajomości języka'}
        items={LEVEL_ITEMS}
        noOutline
        noMargin
        name={`${prefix || ''}.level`}
        methods={methods}
        justifyContent="flex-start"
        defaultValue={level}
        setFieldValid={setFieldValid}
      />
      <Box mb={1.5} />
      <TagInputExtended
        label={'certyfikat'}
        items={certificate}
        subtitle={'Posiadasz certyfikat językowy? Wprowadź go poniżej!'}
        inverse={true}
        tiny={true}
        noMinHeight={true}
        name={`${prefix || ''}.certificate`}
        methods={methods}
        setChips={setChips}
      />
      {deleteItem && (
        <>
          <Box mb={3} />
          <Link>
            <Typography
              onClick={() => {
                if (deleteItem) deleteItem();
              }}
            >
              usuń
            </Typography>
          </Link>
        </>
      )}
    </div>
  );
};
