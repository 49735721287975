import React from 'react';
import { Add } from '@material-ui/icons';
import { Box, Typography, useTheme } from '@material-ui/core';

import { ButtonOtherProps } from './ButtonOther.types';
import { useStyles } from './ButtonOther.styles';

export const ButtonOther: React.FC<ButtonOtherProps> = ({ children, onClick, disabled }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <button type="button" disabled={disabled} onClick={onClick} className={classes.wrapper}>
      <Add />
      <Box mr={3} />
      <Typography>{children}</Typography>
    </button>
  );
};
