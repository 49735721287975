import React from 'react';
import { useTheme } from '@material-ui/core';

import { BaseAuthLayout } from 'ui/layouts/baseAuthLayout/BaseAuthLayout';
import { LoginRegisterFormContainer } from 'ui/loginRegisterFormContainer/LoginRegisterFormContainer';

import { useStyles } from './MainAuthLayout.styles';

export const MainAuthLayout: React.FC = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <BaseAuthLayout>
      <div className={classes.innerWrapper}>
        <div className={classes.container}>
          <LoginRegisterFormContainer>{children}</LoginRegisterFormContainer>
        </div>
      </div>
    </BaseAuthLayout>
  );
};
