import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { ButtonDefault } from '@hrme/shared';
import { Redirect } from 'react-router';
import { Prompt } from 'react-router-dom';
import { AlertMessage } from '@hrme/shared';

import { SettingsLayout } from 'ui/layouts/settingsLayout/SettingsLayout';
import { BoxWrapper } from '../components/BoxWrapper';
import { RadioChipGroup } from 'ui/inputs/radioChipGroup/RadioChipGroup';
import { EDUCATION_ITEMS } from 'app/main/education/Education.types';
import { ButtonOther } from 'ui/buttonOther/ButtonOther';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { User } from 'context/user/UserContext.types';
import { ProfilePaths } from 'routing/profile/ProfilePaths';
import { EducationItem } from 'app/common/education/educationItem/EducationItem';
import { EMPTY_EDUCATION } from 'app/common/education/EducationSettings.constants';
import { SchoolsForm } from 'app/main/schools/Schools.types';

import { EducationSettingsForm } from './EducationSettings.types';

export const EducationSettings = (): JSX.Element => {
  const { user, setUserData } = useUserContext();
  const [educationItems, setEducationItems] = useState([EMPTY_EDUCATION]);
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });
  const [showSavePrompt, setShowSavePrompt] = useState(false);

  useEffect(() => {
    if (!user) return;

    const { school, education } = user;
    methods.setValue('education', education);
    methods.setValue('school', school);

    if (!school) return;

    setEducationItems([...school]);
  }, []);

  const methods = useForm<EducationSettingsForm | SchoolsForm>({
    defaultValues: { education: user?.education, school: user?.school },
  });

  if (user === undefined) {
    return <Redirect to={ProfilePaths.dashboard} />;
  }

  const onContentChange = async () => setShowSavePrompt(true);

  const onFormSubmit = async (data: EducationSettingsForm) => {
    const { education } = data;

    const school = !data.school
      ? []
      : data.school.map((el) => {
          const { end, ...expValues } = el;

          return { ...expValues, end: end || null };
        });

    if (education === user.education && school === user.school) {
      return;
    }

    const newUser: User = {
      ...user,
      education,
      school,
    };

    const result = await setUserData(newUser);
    setShowAlertMessage({ show: true, success: result.success });
    setShowSavePrompt(false);
  };

  const addEducation = () => {
    setEducationItems((prevState) => {
      const newEducations = [...prevState];
      newEducations.push(EMPTY_EDUCATION);

      return [...newEducations];
    });
  };

  const deleteEducation = (index: number) => {
    setEducationItems((prevState) => {
      const newEducations = [...prevState];
      newEducations.splice(index, 1);

      return [...newEducations];
    });
  };

  return (
    <SettingsLayout>
      <Prompt when={showSavePrompt} message="Twoje dane nie zostały zapisane. Czy chcesz kontynuować?" />
      <form onSubmit={methods.handleSubmit(onFormSubmit)} onChange={onContentChange}>
        <BoxWrapper>
          <Typography align="left" variant="h4">
            Twoje wykształcenie
          </Typography>
          <Box style={{ marginLeft: -13, marginTop: 16 }}>
            <RadioChipGroup
              label={''}
              items={EDUCATION_ITEMS}
              alignItems="flex-start"
              name="education.education"
              methods={methods}
              noOutline
              noMargin
            />
          </Box>
        </BoxWrapper>
        <Box mb={4} />
        <BoxWrapper>
          <Typography align="left" variant="h4">
            Twoja edukacja
          </Typography>
          <Box mb={4} />
          {educationItems.map((item, index) => {
            const fieldName = `school[${index}]`;

            return (
              <Box mb={4}>
                <EducationItem
                  prefix={fieldName}
                  educationItem={item}
                  index={index}
                  methods={methods}
                  deleteItem={deleteEducation}
                />
              </Box>
            );
          })}
          <ButtonOther onClick={addEducation}>dodaj uczelnię</ButtonOther>
          <Box mb={4} />
          <ButtonDefault>zapisz zmiany</ButtonDefault>
        </BoxWrapper>
      </form>
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} />
    </SettingsLayout>
  );
};
