/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseFormMethods } from 'react-hook-form';

import { FormControlLabelItem } from 'ui/inputs/radioChipGroup/RadioChipGroup.types';

export type ExperienceItemProps = ExperienceItemType & {
  methods: UseFormMethods<any>;
  prefix?: string;
  deleteItem?: () => void;
  saveOnBlur?: () => void;
};

export type ExperienceItemType = {
  company: string;
  position: string;
  seniority: string;
  city: string;
  country: string;
  type: string;
  skills: string[];
  stillWorking: boolean;
  start: string;
  end: string | null;
};

export const SENIORITY: FormControlLabelItem[] = [
  { value: 'n/a', label: 'Nie dotyczy' },
  { value: 'junior', label: 'Junior' },
  { value: 'mid', label: 'Mid' },
  { value: 'senior', label: 'Senior' },
  { value: 'expert', label: 'Ekspert' },
];

export const WORKING_TIME_TYPE: FormControlLabelItem[] = [
  { value: 'full', label: 'pełny etat' },
  { value: 'part', label: 'pół etatu' },
  { value: 'casually', label: 'dorywczo' },
  { value: 'other', label: 'inny wymiar' },
];
