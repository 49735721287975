import React, { useEffect, useState } from 'react';
import { ButtonDefault, Link, TextInput, TextSelect } from '@hrme/shared';
import { Box, CircularProgress, Typography, useTheme } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller } from 'react-hook-form';

import { MainAuthLayout } from 'ui/layouts/mainAuthLayout/MainAuthLayout';
import { StyledCheckbox } from 'ui/inputs/StyledCheckbox/StyledCheckbox';
import { AuthenticationPaths } from 'routing/auth/AuthenticationPaths';
import { checkEnv } from 'helpers/envChecker';

import { useStyles } from './RegisterPage.styles';
import { objectiveItems, RegisterPageProps } from './RegisterPage.types';

export const RegisterPage = ({
  methods,
  onFormSubmit,
  captchaError,
  setCaptchaError,
  setCaptchaValue,
  reset,
  newsletterAccepted,
  setNewsletterAccepted,
  captchaValue,
}: RegisterPageProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const captchaKey = checkEnv('REACT_APP_CAPTCHA_KEY');
  const { register, errors, handleSubmit, control } = methods;
  const [confirmedChecked, setConfirmedChecked] = useState(false);
  const [buttonBlocked, setButtonBlocked] = useState(false);

  const recaptchaRef = React.createRef<ReCAPTCHA>();

  useEffect(() => {
    if (reset) {
      setButtonBlocked(false);
      setConfirmedChecked(false);
      setNewsletterAccepted(false);
      methods.reset();
      recaptchaRef.current?.reset();
    }
  }, [reset]);

  const handleCaptcha = (value: string | null) => {
    setCaptchaValue(value);
    if (value !== null) {
      setCaptchaError(false);
    }
  };

  if (captchaKey === undefined) {
    return <Typography>Setup Google reCaptcha properly</Typography>;
  }

  return (
    <MainAuthLayout>
      <Typography variant="h4" className={classes.header}>
        Zarejestruj się
      </Typography>
      <Box mb={2} />
      <Typography variant="subtitle1" className={classes.subheader}>
        Wprowadź dane i załóż konto na platformie Hr-Me
      </Typography>
      <Box mb={4} />

      <form
        onSubmit={handleSubmit((d) => {
          if (captchaValue) {
            onFormSubmit(d);
            setButtonBlocked(true);
          } else {
            setCaptchaError(true);
          }
        })}
      >
        <TextInput
          name="name"
          ref={register}
          error={!!errors.name}
          helperText={errors.name && errors.name.message}
          label="imię i nazwisko"
          placeholder="Wpisz swoje imię i nazwisko"
        />
        <TextInput
          name="email"
          ref={register}
          error={!!errors.email}
          helperText={errors.email && errors.email.message}
          label="adres e-mail"
          placeholder="Wpisz swój adres e-mail"
        />
        <TextInput
          name="password"
          ref={register}
          error={!!errors.password}
          helperText={errors.password && errors.password.message}
          label="hasło"
          type="password"
          placeholder="Wpisz swoje hasło"
        />
        <Controller
          control={control}
          name={'purpose'}
          defaultValue=""
          render={({ onChange, value, ref }) => (
            <TextSelect
              items={objectiveItems}
              label="cel rejestracji na platformie"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              ref={ref}
            />
          )}
        />
        <label htmlFor="acceptedRules" className={classes.confirmContainer}>
          <StyledCheckbox
            name={'confirmed'}
            id="acceptedRules"
            className={classes.confirmCheckbox}
            checked={confirmedChecked}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
              const { checked } = target;
              setConfirmedChecked(checked);
            }}
          />
          <Box mr={2} />
          <Typography variant="subtitle1" className={classes.confirmLabel}>
            rejestrując się akceptuję{' '}
            <Link href={AuthenticationPaths.termsAndConditions} className={classes.hyperlink}>
              regulamin serwisu
            </Link>{' '}
            oraz{' '}
            <Link href={AuthenticationPaths.policy} className={classes.hyperlink}>
              politykę prywatności
            </Link>
          </Typography>
        </label>
        <label htmlFor="acceptedNewsletter" className={classes.confirmContainer}>
          <StyledCheckbox
            name={'newsletter_email'}
            id="acceptedNewsletter"
            className={classes.confirmCheckbox}
            checked={newsletterAccepted}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
              const { checked } = target;
              setNewsletterAccepted(checked);
            }}
          />
          <Box mr={2} />
          <Typography variant="subtitle1" className={classes.confirmLabel}>
            chcę otrzymywać informacje o nowych ścieżkach kariery i rozwoju platformy na mój adres email
          </Typography>
        </label>
        <Box mb={2} />
        <Box mb={2}>
          <ReCAPTCHA size="normal" sitekey={captchaKey} onChange={handleCaptcha} ref={recaptchaRef} />
          {captchaError && (
            <Box mt={2}>
              <Typography color="error" align="left" variant="body1">
                Musisz uzupełnić captcha.
              </Typography>
            </Box>
          )}
        </Box>
        <ButtonDefault type="submit" disabled={!confirmedChecked || buttonBlocked}>
          {buttonBlocked ? <CircularProgress size={20} style={{ color: 'gray' }} /> : 'Zarejestruj się'}
        </ButtonDefault>
      </form>

      <Box mb={4} />
      <Typography variant="body2">
        Posiadasz już konto?{' '}
        <Link href="/login" className={classes.link}>
          Zaloguj się
        </Link>
      </Typography>
    </MainAuthLayout>
  );
};
