import { makeStyles } from '@material-ui/core';
import { WIDER_WRAPPER_WIDTH } from '@hrme/shared/lib/theme/theme';

export const useStyles = makeStyles((theme) => ({
  paper: {
    width: WIDER_WRAPPER_WIDTH,
    maxWidth: WIDER_WRAPPER_WIDTH,
    borderRadius: 5,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxHeight: '90vh',
    overflow: 'auto',
  },
  title: {
    fontWeight: 600,
  },
  link: {
    color: theme.palette.divider,
    opacity: '0.35',
    cursor: 'pointer',
  },
}));
