import { SKILL_LEVEL } from 'helpers/findSkillLevelByTechnologyName/findSkillLevelByTechnologyName.constants';

import { FindSkillLevelByTechnologyNameParams } from './findSkillLevelByTechnologyName.types';

export const findSkillLevelByTechnologyName = ({ name, user }: FindSkillLevelByTechnologyNameParams): number => {
  if (user && user.technology) {
    const { known, learning, wantToLearn } = user.technology;
    if (known.includes(name)) return parseInt(SKILL_LEVEL.KNOWN.selectValue);
    if (learning.includes(name)) return parseInt(SKILL_LEVEL.LEARNING.selectValue);
    if (wantToLearn.includes(name)) return parseInt(SKILL_LEVEL.WANT_TO_LEARN.selectValue);
  }

  return parseInt(SKILL_LEVEL.NONE.selectValue);
};
