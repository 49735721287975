import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  divider: {
    width: '100%',
    display: 'none',
    background: '#DDDDDD',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}));
