import React from 'react';
import { ButtonDefault, Message, TextInput } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';

import { MainAuthLayout } from 'ui/layouts/mainAuthLayout/MainAuthLayout';

import { useStyles } from './ResetPassword.styles';
import { ResetPasswordProps } from './ResetPassword.types';

export const ResetPassword = ({ methods, onFormSubmit, alert }: ResetPasswordProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { register, errors, handleSubmit } = methods;

  const allertType = alert.success ? 'success' : 'error';
  const allertMessage = alert.success ? 'Wysłano link resetujący hasło' : 'Wystąpił błąd';

  return (
    <MainAuthLayout>
      {alert.canShow === true && (
        <div>
          <Message type={allertType} text={allertMessage} />
          <Box mb={2} />
        </div>
      )}

      <Typography variant="h4" className={classes.header}>
        Zresetuj hasło do konta
      </Typography>
      <Box mb={3} />
      <Typography variant="subtitle1" className={classes.subheader}>
        Wprowadź swój adres e-mail, a otrzymasz na niego link resetujący hasło.
      </Typography>
      <Box mb={4} />
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <TextInput
          ref={register}
          name="email"
          error={!!errors.email}
          helperText={errors.email && errors.email.message}
          label="adres e-mail"
          placeholder="Podaj swój adres e-mail"
        />
        <ButtonDefault type="submit">Wyślij link resetujący hasło</ButtonDefault>
      </form>
    </MainAuthLayout>
  );
};
