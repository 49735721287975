import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    },
  },
  textInput: {
    backgroundColor: theme.palette.background.paper,
  },
  periodContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `1fr`,
    rowGap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      columnGap: theme.spacing(2),
      rowGap: 0,
      gridTemplateColumns: `1fr 1fr`,
    },
  },
}));
