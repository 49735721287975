import React from 'react';
import { Box, useTheme } from '@material-ui/core';

import { BoxDescription } from 'ui/boxes/boxDescription/BoxDescription';
import { BoxSubtitle } from 'ui/boxes/boxSubtitle/BoxSubtitle';
import { BoxList } from 'ui/boxes/boxList/BoxList';

import { JobOfferViewPanelRightProps } from './JobOfferViewPanelRight.types';
import { useStyles } from './JobOfferViewPanelRight.styles';

export const JobOfferViewPanelRight: React.FC<JobOfferViewPanelRightProps> = ({
  positionDescription,
  aboutCompany,
  positionTechnologies,
  positionMethodology,
  growthPosibility,
  dailyTasks,
  offerLanguages,
  extraInfo,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.panel}>
      {/* Section: OPIS STANOWISKA */}
      <BoxDescription title={positionDescription.title} content={positionDescription.content} />
      <Box mb={2} />

      {/* Section: O FIRMIE */}
      <BoxDescription
        title={'O firmie'}
        subtitle={<BoxSubtitle items={aboutCompany.items} />}
        content={aboutCompany.content}
      />
      <Box mb={2} />

      {/* Section: TECHNOLOGIE NA STANOWISKU */}
      <BoxList title={positionTechnologies.title} content={positionTechnologies.content} />
      <Box mb={2} />

      {/* Section: METODOLOGIA STANOWISKA */}
      <BoxList title={positionMethodology.title} content={positionMethodology.content} />
      <Box mb={2} />

      {/* Section: CODZIENNE ZADANIA */}
      <BoxList title={dailyTasks.title} content={dailyTasks.content} />
      <Box mb={2} />

      {/* Section: MOŻLIWOŚCI ROZWOJU */}
      <BoxList title={growthPosibility.title} content={growthPosibility.content} />
      <Box mb={2} />

      {/* Section: WYBRANE JĘZYKI OBCE */}
      <BoxList title={offerLanguages.title} content={offerLanguages.content} />
      <Box mb={2} />

      {/* Section: DODATKOWE INFORMACJE */}
      <BoxList title={extraInfo.title} content={extraInfo.content} />
      <Box mb={2} />
    </div>
  );
};
