/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseFormMethods } from 'react-hook-form';
import { TextSelectItem } from '@hrme/shared/lib/components/inputs/textSelect/TextSelect.types';
import { FocusEventHandler } from 'react';

export enum TextInputVariant {
  White = 'WHITE',
  Grey = 'GREY',
}

export type TextInputExtendableListProps = {
  label: string;
  listItems?: string[];
  buttonCaption: string;
  addInputLabel?: string;
  addInputPlaceholder?: string;
  variant?: TextInputVariant;
  selectItems?: TextSelectItem[];
  name?: string;
  methods?: UseFormMethods<any>;
  addItemLabel?: string;
  onBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
  setTriggerSave?: (triggerSave: number) => void;
};
