import React, { useCallback, useEffect, useState } from 'react';
import { ButtonDefault, Link, TextInput } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';

import { MainFlowLayout } from 'ui/layouts/mainFlowLayout/MainFlowLayout';
import { TextInputExtendableList } from 'ui/textInputExtendableList/TextInputExtendableList';
import { Topic } from 'ui/topic/Topic';
import { checkKeyDown } from 'helpers/checkKeyDown';
import { useUserContext } from 'hooks/useUserContext/useUserContext';

import { PortfolioSocialMediaProps } from './PortfolioSocialMedia.types';
import { useStyles } from './PortfolioSocialMedia.styles';

export const PortfolioSocialMedia: React.FC<PortfolioSocialMediaProps> = ({ methods, onFormSubmit }) => {
  const { register, handleSubmit } = methods;
  const { user } = useUserContext();

  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [triggerSave, setTriggerSave] = useState(0);

  const stepBack = () => {
    onFormSubmit(methods.getValues(), true);
    history.goBack();
  };

  const saveOnBlur = () => setTriggerSave(Math.random());

  const debouncer = useCallback(debounce(saveOnBlur, 400), []);

  useEffect(() => {
    const saveData = () => onFormSubmit(methods.getValues(), undefined, true);
    saveData();
  }, [triggerSave]);

  return (
    <MainFlowLayout topic={<Topic />} progress={{ label: 'Portfolio', length: 10, current: 9 }}>
      <div className={classes.container}>
        <Box mb={3}>
          <Typography variant="h2" className={classes.header}>
            Portfolio / Social media
          </Typography>
        </Box>
        <Box mb={6}>
          <Typography variant="body2" className={classes.subheader}>
            Dokumentujesz swoje projekty? Pochwal się swoim portfolio!
          </Typography>
        </Box>
        <div className={classes.wrapper}>
          <form
            className={classes.header}
            onSubmit={handleSubmit((d) => onFormSubmit(d))}
            onKeyDown={(e) => checkKeyDown(e)}
            onChange={() => debouncer()}
          >
            <TextInput ref={register} placeholder="Wprowadź adres" label="github" name="github" onBlur={saveOnBlur} />
            <TextInput
              ref={register}
              placeholder="Wprowadź adres"
              label="linkedin"
              name="linkedin"
              onBlur={saveOnBlur}
            />
            <TextInput
              ref={register}
              placeholder="Wprowadź adres"
              label="facebook"
              name="facebook"
              onBlur={saveOnBlur}
            />
            <TextInput
              ref={register}
              placeholder="Wprowadź adres"
              label="instagram"
              name="instagram"
              onBlur={saveOnBlur}
            />
            <Box mb={2} />
            <TextInputExtendableList
              methods={methods}
              label="inne portale"
              name="otherLinks"
              buttonCaption="dodaj"
              addInputPlaceholder="Wpisz link"
              listItems={user?.portfolioSocialMedia?.otherLinks}
              setTriggerSave={setTriggerSave}
            />
            <Box mb={6} />
            <ButtonDefault>następny krok (Dane osobowe)</ButtonDefault>
          </form>
          <Box mb={2} />
          <Link>
            <Typography onClick={stepBack}>cofnij</Typography>
          </Link>
        </div>
      </div>
    </MainFlowLayout>
  );
};
