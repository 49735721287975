import React, { useEffect } from 'react';

import { ProfileLayout } from 'ui/layouts/profileLayout/ProfileLayout';
import { useDiscountsOnTutorials } from 'hooks/useDiscountsOnTutorials/useDiscountsOnTutorials';
import { descriptionOfTutorialFromFB } from 'context/discountsOnTutorials/DiscountsOnTutorialsContext.types';

import { TutorialBox } from './TutorialBox/TutorialBox';

export const DiscountsOnTutorials: React.FC = () => {
  const { fetchDiscountsOnTutorials, tutorials } = useDiscountsOnTutorials();
  useEffect(() => {
    fetchDiscountsOnTutorials();
  }, []);
  const showTutorials = () => {
    const TutorialsList = tutorials.map((tutorial: descriptionOfTutorialFromFB) => {
      return <TutorialBox tutorial={tutorial} key={tutorial.name} />;
    });

    return TutorialsList;
  };

  return (
    <ProfileLayout>
      <h2 style={{ marginTop: '24px' }}>
        Zniżki na kursy są realizowane w ramach komercyjnych współprac z twórcami kursów.
      </h2>
      {showTutorials()}
    </ProfileLayout>
  );
};
