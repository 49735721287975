import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'start',
  },
  header: {
    fontWeight: 600,
  },
  headerNoOffers: {
    fontWeight: 600,
    marginBottom: '24px',
    textAlign: 'left',
  },
  informations: {
    rowGap: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  noOffers: {
    textAlign: 'left',
    background: '#fff',
    padding: '40px 32px',
    width: '100%',
  },
  noOffersList: {
    listStyle: 'none',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    fontWeight: 600,
  },
  noOffersListItem: {
    display: 'flex',
    columnGap: '8px',
  },
  point: {
    width: '24px',
    height: '24px',
    display: 'block',
  },
  noOffersListItemLink: {
    color: '#BC2869',
  },
  Bradcrubms: {
    display: 'flex',
    padding: 0,
    columnGap: '8px',
    listStyle: 'none',
  },
  BradcrubmsItem: {},
  BradcrubmsItemLink: {
    color: '#BC2869',
    display: 'inline-block',
    textDecoration: 'none',
  },
}));
