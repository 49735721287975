/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { ButtonDefault, Link } from '@hrme/shared';
import { useHistory } from 'react-router';
import { Box, Typography, useTheme } from '@material-ui/core';

import { MainFlowLayout } from 'ui/layouts/mainFlowLayout/MainFlowLayout';
import { LanguageItem } from 'ui/languageItem/LanguageItem';
import { ButtonOther } from 'ui/buttonOther/ButtonOther';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { LanguagesState } from 'context/careerPathForm/careerPathFormContext/CareerPathFormContext.types';
import { ChipData } from 'ui/inputs/tagInputExtended/TagInputExtended.types';
import { Topic } from 'ui/topic/Topic';
import { checkKeyDown } from 'helpers/checkKeyDown';

import { ADD_LANGUAGE_ITEM, LanguagesProps } from './Languages.types';
import { useStyles } from './Languages.styles';

export const Languages: React.FC<LanguagesProps> = ({ methods, onFormSubmit }) => {
  const { handleSubmit } = methods;
  const { user } = useUserContext();
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const [fieldValid, setFieldValid] = useState({ chosenLanguage: '', chosenLevel: '', fieldIndex: 0 });
  const [allowNextStep, setAllowNextStep] = useState(false);
  const [chips, setChips] = useState<ChipData[]>([]);
  const [languageItems, setLanguageItems] = useState<LanguagesState>(
    user?.languages?.length ? user.languages : [ADD_LANGUAGE_ITEM],
  );
  const { chosenLanguage, chosenLevel, fieldIndex } = fieldValid;
  const disabledButton = languageItems.length > 1 && !allowNextStep;
  const langItemsWithIndex = languageItems[languageItems.length - 1];

  const revalidateItems = () => {
    methods.reset({ language: [...languageItems] });
    methods.setValue('language', [...languageItems]);
  };

  const addItem = () => {
    setFieldValid({ chosenLanguage: '', chosenLevel: '', fieldIndex: 0 });
    setLanguageItems((prevState) => {
      const newLanguageItems = [...prevState];
      newLanguageItems.push({ ...ADD_LANGUAGE_ITEM });

      return [...newLanguageItems];
    });
  };

  const deleteItem = (index: number) => {
    revalidateItems();
    setLanguageItems((prevState) => {
      const newLanguageItems = [...prevState];
      newLanguageItems.splice(index, 1);

      return [...newLanguageItems];
    });
  };

  useEffect(() => {
    if (!!chosenLanguage && !!chosenLevel && fieldIndex === languageItems.length - 1) {
      return setAllowNextStep(true);
    }
    if (langItemsWithIndex?.language === '' || langItemsWithIndex?.level === '') {
      return setAllowNextStep(false);
    }
    if (!chosenLanguage || (!chosenLevel && fieldIndex === languageItems.length - 1)) {
      return setAllowNextStep(true);
    }

    return setAllowNextStep(true);
  }, [languageItems, fieldValid]);

  const stepBack = () => {
    onFormSubmit(methods.getValues(), true);
    history.goBack();
  };

  useEffect(() => {
    onFormSubmit(methods.getValues(), undefined, true);
  }, [chips, languageItems]);

  useEffect(() => {
    const values = methods.getValues();
    if (values.language) setLanguageItems(values.language);
  }, [fieldValid]);

  return (
    <MainFlowLayout topic={<Topic />} progress={{ label: 'Języki', length: 10, current: 8 }}>
      <div className={classes.container}>
        <Box mb={3}>
          <Typography variant="h2" className={classes.header}>
            Języki obce
          </Typography>
        </Box>
        <Box mb={6}>
          <Typography variant="body2" className={classes.subheader}>
            Wybierz jakie języki znasz i określ ich poziom
          </Typography>
        </Box>
        <form
          className={classes.wrapper}
          onSubmit={handleSubmit((d) => onFormSubmit(d))}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <div className={classes.itemsContainer}>
            {languageItems.map((item, key) => {
              const fieldName = `language[${key}]`;
              const { certificate, ...values } = item;
              const chipCertificates: ChipData[] = certificate.map((item, index) => {
                return { key: index, label: item };
              });

              return (
                <div key={key}>
                  <LanguageItem
                    certificate={chipCertificates}
                    methods={methods}
                    prefix={fieldName}
                    deleteItem={() => deleteItem(key)}
                    setFieldValid={setFieldValid}
                    indexKey={key}
                    setChips={setChips}
                    {...values}
                  />
                  {key !== languageItems.length - 1 && <Box mb={4} />}
                </div>
              );
            })}
          </div>
          <Box mb={2} />
          {allowNextStep && <ButtonOther onClick={addItem}>Dodaj kolejny język</ButtonOther>}
          <Box mb={4} />
          <ButtonDefault disabled={disabledButton}>Następny krok (portfolio)</ButtonDefault>
          <Box mb={2} />
          <Link>
            <Typography onClick={stepBack}>cofnij</Typography>
          </Link>
        </form>
      </div>
    </MainFlowLayout>
  );
};
