import React, { useEffect, useState } from 'react';
import { Box, Divider, useTheme } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { BarElement } from '@hrme/shared/lib/components/ui/statsBar/StatsBar.types';

import { ProfileLayout } from 'ui/layouts/profileLayout/ProfileLayout';
import { MessageSection } from '../messageSection/MessageSection';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { SKILL_LEVEL } from 'helpers/findSkillLevelByTechnologyName/findSkillLevelByTechnologyName.constants';
import { ProfilePaths } from 'routing/profile/ProfilePaths';
import { SettingsPaths } from 'routing/settings/SettingsPaths';

import { useStyles } from './Dashboard.styles';
import { StatsSection } from './StatsSection/StatsSection';
import { CareerPathsSection } from './careerPathsSection/CareerPathsSection';
import { SAMPLE_MESSAGE_DATA } from './Dashboard.constants';
import { FetchUserTechnologyStatsParams } from './Dashboard.types';

export const Dashboard = (): JSX.Element => {
  const { user, fetchUserData } = useUserContext();
  const { state } = useLocation<{ openCareer?: boolean }>();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);

  const messageAvailable = user && (user?.certificates === undefined || user?.school === undefined);

  const [showMessage, setShowMessage] = useState(messageAvailable);
  const [technologyStats, setTechnologyStats] = useState<BarElement[]>([]);

  const hideMessageBar = () => setShowMessage(false);

  const fetchUserTechnologyStats = ({ user }: FetchUserTechnologyStatsParams): BarElement[] => {
    if (!user) return [];

    const { technology } = user;

    if (!technology) return [];

    return [
      { label: SKILL_LEVEL.KNOWN.label.toLowerCase(), color: SKILL_LEVEL.KNOWN.color, value: technology.known.length },
      {
        label: SKILL_LEVEL.LEARNING.label.toLowerCase(),
        color: SKILL_LEVEL.LEARNING.color,
        value: technology.learning.length,
      },
      {
        label: SKILL_LEVEL.WANT_TO_LEARN.label.toLowerCase(),
        color: SKILL_LEVEL.WANT_TO_LEARN.color,
        value: technology.wantToLearn.length,
      },
    ];
  };

  useEffect(() => {
    if (!user) {
      const refreshUser = async () => {
        await fetchUserData();
      };
      refreshUser();
    }

    setTechnologyStats(fetchUserTechnologyStats({ user }));

    if (!!SAMPLE_MESSAGE_DATA.message && user) {
      if (!messageAvailable) return;

      SAMPLE_MESSAGE_DATA.messageData = {
        ...SAMPLE_MESSAGE_DATA.messageData,
        onClose: hideMessageBar,
        onAction: () => history.push('/settings' + SettingsPaths.profile),
      };

      setShowMessage(true);
    }
  }, [fetchUserData, user]);

  return (
    <ProfileLayout>
      <Box mb={6} display={{ xs: 'none', md: 'block' }} />
      {showMessage && false && (
        <MessageSection message={SAMPLE_MESSAGE_DATA.message} messageData={SAMPLE_MESSAGE_DATA.messageData} />
      )}
      {technologyStats.length > 0 && (
        <>
          <StatsSection action={() => history.push(ProfilePaths.profileTechnology)} data={technologyStats} />
          <Box mb={8} display={{ xs: 'none', md: 'block' }} />
          <Divider className={classes.divider} />
        </>
      )}
      <Box mb={8} />
      <CareerPathsSection initialOpen={state !== undefined ? state.openCareer : false} />
      <Box mb={8} />
    </ProfileLayout>
  );
};
