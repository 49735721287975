import { HrMeTheme } from '@hrme/shared/lib/index.types';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: HrMeTheme) => ({
  panel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    justifySelf: 'flex-end',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.spacing(4.5)}px)`,
    },
  },
  item: {
    width: '100%',
  },
  border: {
    borderBottom: `1px solid ${theme.borders.main}`,
  },
  line: {
    borderLeft: `2px dashed ${theme.borders.main}`,
    height: 'calc(100% - 38px)',
    position: 'absolute',
    left: '-26px',
    top: '38px',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  top: {
    width: '100%',
    height: 68,
    padding: theme.spacing(2.25, 3),
    paddingLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.borders.main}`,
  },
}));
