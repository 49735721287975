import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  narrowColumn: {
    backgroundColor: '#F5F5F5',
    minHeight: '100vh',
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'white',
      padding: '0 80px',
    },
  },
  wideColumn: {
    overflow: 'hidden',
    paddingLeft: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  title: {
    textAlign: 'left',
    maxWidth: 500,
    width: '100%',
  },
  highlighted: {
    color: theme.palette.primary.main,
  },
  subtitle: {
    textAlign: 'left',
    maxWidth: 430,
    width: '100%',
  },
  seekerImgContainer: {
    position: 'relative',
    padding: 0,
    marign: 0,
  },
  image: {
    height: 'auto',
    width: '100%',
  },
}));
