import { makeStyles } from '@material-ui/core';
import { HrMeTheme } from '@hrme/shared/lib/theme/theme';

export const useStyles = makeStyles((theme: HrMeTheme) => ({
  box: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  company: {
    width: '100%',
    padding: theme.spacing(0, 3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  salary: {
    padding: theme.spacing(3),
    alignSelf: 'center',
  },
  salaryMain: {
    fontSize: '24px',
    lineHeight: '32px',
  },
  salaryDescription: {
    color: theme.palette.grey.A200,
  },
  divider: {
    width: '100%',
  },
  companyLogo: {
    height: '104px',
    width: '94px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    objectFit: 'contain',
  },
  companyDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
  },
  boxDescriptionTitle: {
    fontSize: '18px',
    fontWeight: 600,
  },
  companyDetailsItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}));
