import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { AlertMessage } from '@hrme/shared';

import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { useJune } from 'hooks/useJune/useJune';
import { RegisterUserProps } from 'context/authentication/AuthenticationContext.types';
import { AuthenticationPaths } from 'routing/auth/AuthenticationPaths';

import { RegisterPage } from './RegisterPage';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Must be an valid email.').required('Field required.'),
  name: Yup.string().required('Field required.'),
  password: Yup.string().min(4, 'Password too short.').required('Field required.'),
});

export const RegisterPageContainer = (): JSX.Element => {
  const { registerUser, loading, logoutUser } = useAuthentication();
  const history = useHistory();
  const analytics = useJune();
  const methods = useForm<RegisterUserProps>({ resolver: yupResolver(validationSchema), reValidateMode: 'onBlur' });
  const [captchaError, setCaptchaError] = useState(false);
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [showAlertMessage, setShowAlertMessage] = useState({ show: false, success: false });
  const [responseMessage, setResponseMessage] = useState<string | undefined>();
  const [newsletterAccepted, setNewsletterAccepted] = useState(false);

  const onFormSubmit = async (data: RegisterUserProps) => {
    const registerData = { ...data, newsletter_email: newsletterAccepted ? data.email : null };

    if (captchaValue === null) {
      setCaptchaError(true);

      return;
    }

    setCaptchaError(false);

    const registerResponse = await registerUser(registerData);

    const infoMessage = registerResponse.message?.includes('Your email is already in use')
      ? 'Masz już konto na platformie - potwierdź rejestrację klikając link aktywacyjny z wiadomości email'
      : 'Wystąpił błąd, proszę spróbować ponownie';

    if (registerResponse.success) {
      analytics &&
        registerData &&
        analytics.identify(registerData.email, { email: registerData.email, name: registerData.name });
      await logoutUser();
      history.push(AuthenticationPaths.welcomeRegister);
    } else if (registerResponse.needAction) {
      history.replace(AuthenticationPaths.checkEmail);
    } else {
      setResponseMessage(infoMessage);
      setShowAlertMessage({ show: true, success: false });
    }
  };
  useEffect(() => {
    if (responseMessage && showAlertMessage.show === false) setResponseMessage('reset');
  }, [methods.errors, showAlertMessage.show]);

  return (
    <>
      <RegisterPage
        methods={methods}
        captchaError={captchaError}
        setCaptchaError={setCaptchaError}
        captchaValue={captchaValue}
        setCaptchaValue={setCaptchaValue}
        loading={loading}
        onFormSubmit={onFormSubmit}
        reset={responseMessage === 'reset'}
        newsletterAccepted={newsletterAccepted}
        setNewsletterAccepted={setNewsletterAccepted}
      />
      <AlertMessage open={showAlertMessage} close={setShowAlertMessage} message={responseMessage} />
    </>
  );
};
