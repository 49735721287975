import StatusLow from 'assets/icon/signal_low.svg';
import SignalStrong from 'assets/icon/signal_strong.svg';
import SignalMedium from 'assets/icon/signal_medium.svg';
import { StepStatus } from 'ui/careerPath/careerSteps/careerPathStepSummary/CareerPathStepSummary.types';
import StatusRed from 'assets/icon/circle_indicator_red.svg';
import StatusOrange from 'assets/icon/circle_indicator_orange.svg';
import StatusYellow from 'assets/icon/circle_indicator_yellow.svg';
import StatusGreen from 'assets/icon/circle_indicator_green.svg';

export const stepStatusIcon = (status: StepStatus, mobile?: boolean): string => {
  if (status === StepStatus.RED) return mobile ? StatusLow : StatusRed;
  if (status === StepStatus.ORANGE) return mobile ? SignalMedium : StatusOrange;
  if (status === StepStatus.YELLOW) return mobile ? SignalStrong : StatusYellow;

  return mobile ? SignalStrong : StatusGreen;
};
