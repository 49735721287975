import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 16,
    background: 'white',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dashed #D8D5DC',
    boxSizing: 'border-box',
    borderRadius: 5,
    outline: 'none',
    cursor: 'pointer',
  },
}));
