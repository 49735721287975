import React from 'react';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Link } from '@hrme/shared';
import { Check } from '@material-ui/icons';

import { StyledCheckbox } from 'ui/inputs/StyledCheckbox/StyledCheckbox';

import { CareerPathTasksProps } from './CareerPathTasks.types';
import { useStyles } from './CareerPathTasks.styles';

export const CareerPathTasks: React.FC<CareerPathTasksProps> = ({ tasks = [], onPathTaskChange, mobile }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  if (mobile)
    return (
      <FormControl component="fieldset">
        <RadioGroup>
          {tasks.map((item, index) => (
            <>
              {index !== 0 && <Divider light />}
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={onPathTaskChange}
                    id={`${index}`}
                    checkedIcon={
                      <span className={classes.checkIcon} style={{ border: '1px solid red' }}>
                        <Check />
                      </span>
                    }
                    icon={
                      <span className={classes.checkIcon} style={{ border: '1px solid gray' }}>
                        <Check />
                      </span>
                    }
                  />
                }
                label={
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <span style={{ fontSize: '12px' }}>{item.title}</span>

                    <Link href={item.link} external className={classes.link}>
                      Zobacz źródło
                    </Link>
                  </div>
                }
                labelPlacement="start"
                style={{ margin: 0, justifyContent: 'space-between', padding: '10px 0' }}
                checked={item.completed}
              />
            </>
          ))}
        </RadioGroup>
      </FormControl>
    );

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableBody>
          {tasks.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={{ paddingLeft: 0, maxWidth: 24, width: 24 }}>
                <StyledCheckbox id={`${index}`} onChange={onPathTaskChange} checked={row.completed} />
              </TableCell>
              <TableCell>
                <Typography variant="body2" style={{ textAlign: 'left' }}>
                  {row.title}
                </Typography>
              </TableCell>
              <TableCell style={{ paddingRight: 0 }} align="right">
                <Link href={row.link} external style={{ textAlign: 'right' }}>
                  Źródło
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
