import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { CustomChipGroup } from '@hrme/shared/lib/components/ui';
import { typeConvert } from '@hrme/shared';
import { useEffect } from 'react';

import { ProfileLayout } from 'ui/layouts/profileLayout/ProfileLayout';
import { BoxSubtitle } from 'ui/boxes/boxSubtitle/BoxSubtitle';
import { InvitationStatus } from 'context/jobOffer/JobOfferContext.types';
import { BoxListItem } from 'ui/boxes/boxListItem/BoxListItem';
import { useUserContext } from 'hooks/useUserContext/useUserContext';
import { ProfilePaths } from 'routing/profile/ProfilePaths';

import { JobOfferViewProps } from './JobOfferView.types';
import { useStyles } from './JobOfferView.styles';
import { JobOfferViewPanelLeft } from './jobOfferViewPanelLeft/JobOfferViewPanelLeft';
import { JobOfferViewPanelRight } from './jobOfferViewPanelRight/JobOfferViewPanelRight';

export const JobOfferView: React.FC<JobOfferViewProps> = ({
  location: {
    state: { job },
  },
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const { user } = useUserContext();
  const { fetchUserData, setUserData } = useUserContext();

  useEffect(() => {
    fetchUserData();
    if (user === undefined) return;
    if (user.tags === undefined) {
      user.tags = ['JobOfferOpened'];
    } else {
      user.tags = user.tags.filter((el) => el !== 'JobOfferSent');
      user.tags.push('JobOfferOpened');
    }
    setUserData(user);
  }, []);
  const collectionType = job.collectionType ?? 'matched_candidates';
  const offerPosition = job.position;
  const offerCategory = job.category;
  const offerExperience = job.experienceType;
  const offerCompany = job.company;
  const offerLogo = job.companyData.logo;
  const offerCity = job.companyCity;
  const offerStreet = job.companyStreet;
  const offerPlaceOfWork = typeConvert(job.placeOfWork);
  const offerSalaryFrom = job.salaryRangeFrom;
  const offerSalaryTo = job.salaryRangeTo;
  const offerContractType = job.contractType.map((type) => typeConvert(type)).join(', ');
  const offerStatus = job.offerStatus ?? job.status;
  const offerMatchingScore = job.generalScore ?? job.score;
  const offerGeneralScore = job.growthScore;
  const offerHireWhen = job.hireAsap ? 'ASAP' : job.hireSince;
  const offerWorkTime = typeConvert(job.workingTime);
  const offerHoliday = job.paidLeave ? 'Tak' : 'Nie';
  const offerRelocation = job.relocationPackage ? 'Tak' : 'Nie';
  const offerBenefits = [...job.benefits].join(', ');
  const offerDescription = job.positionDescription;
  const offerMainTechnology = job.mainTechnologies[0];
  const offerRequiredTechnologies = job.positionTechnologies;
  const offerWelcomeTechnologies = job.welcomeTechnologies;
  const offerWillLearnTechnologies = job.willLearnTechnologies;
  const offerMethodology = job.methodology;
  const offerGrowthOptions = job.personalGrowth;
  const offerDailTasks = job.dailyTasks;
  const offerRequiredLanguages = job.language;
  const offerCollaborators = job.collaborators;
  const offerWillLearn = job.willLearn;
  const currency = job.currency;
  const companyBranch = job.companyData.industry;
  const companySize = job.companyData.companySize;
  const companyDescription = job.companyData.companyDescription;
  const salaryOnHand = job?.salaryOnHand;
  const isNetto = job?.isNetto || false;
  const companyFB = job.companyData.facebook;
  const companyLinkedIn = job.companyData.linkedin;
  const companyHome = job.companyData.otherSocialMedia || [];

  const jobOfferRef = job.jobOfferRef;

  const BOX_ACCEPT_DATA = {
    logo: offerLogo,
    company: offerCompany,
    location: offerCity,
    mode: offerPlaceOfWork,
    salary: `${offerSalaryFrom} ${offerSalaryTo > 0 ? `- ${offerSalaryTo}` : ''}`,
    contractType: offerContractType,
    accepted: offerStatus === InvitationStatus.ACCEPTED,
    rejected: offerStatus === InvitationStatus.REJECTED,
    currency,
    salaryOnHand,
    isNetto,
  };

  const BOX_PROGRESS_DATA = {
    actual: +offerMatchingScore,
    developed: +offerGeneralScore,
  };

  const BOX_TIME_PLACE_DATA = {
    'Od kiedy:': offerHireWhen,
    'Wymiar czasu pracy:': offerWorkTime,
    'Miejsce pracy:': offerPlaceOfWork,
    'Miasto:': offerCity,
    'Ulica:': offerStreet,
    'Godziny pracy:': offerWorkTime,
  };

  const BOX_PERKS_DATA = {
    'Platny urlop:': offerHoliday,
    'Pakiet relokacyjny:': offerRelocation,
    'Benefity:': offerBenefits,
  };

  const BOX_SOCIALMEDIA_DATA = {
    home: companyHome,
    facebook: companyFB,
    linkedin: companyLinkedIn,
  };

  const positionDescription = {
    title: 'Opis stanowiska',
    content: offerDescription,
  };

  const aboutCompany = {
    items: [
      { label: 'Branża:', value: companyBranch },
      { label: 'Rozmiar firmy:', value: companySize },
    ],
    content: companyDescription,
  };

  const positionTechnologies = {
    title: 'Technologie na stanowisku',
    content: [
      <BoxListItem
        label={'Główna technologia:'}
        content={
          <CustomChipGroup
            chips={[
              {
                label: offerMainTechnology,
                checked: user?.technology?.known.some((element) => {
                  return element.toLowerCase() === offerMainTechnology.toLowerCase();
                }),
              },
            ]}
          />
        }
      />,
      <BoxListItem
        label={'Umiejętności obowiązkowe:'}
        content={
          <CustomChipGroup
            chips={offerRequiredTechnologies.map((technology) => ({
              label: technology,
              checked: user?.technology?.known.some((element) => {
                return element.toLowerCase() === technology.toLowerCase();
              }),
            }))}
          />
        }
      />,
      <BoxListItem
        label={'Umiejętności mile widziane:'}
        content={
          <CustomChipGroup
            chips={offerWelcomeTechnologies.map((technology) => ({
              label: technology,
              checked: user?.technology?.known.some((element) => {
                return element.toLowerCase() === technology.toLowerCase();
              }),
            }))}
          />
        }
      />,
      <BoxListItem
        label={'Umiejętności które zdobędziesz:'}
        content={
          <CustomChipGroup
            chips={offerWillLearnTechnologies.map((technology) => ({
              label: technology,

              checked:
                user?.technology?.wantToLearn.some((element) => {
                  return element.toLowerCase() === technology.toLowerCase();
                }) ||
                user?.technology?.learning.some((element) => {
                  return element.toLowerCase() === technology.toLowerCase();
                }),
            }))}
          />
        }
      />,
      <BoxListItem label={'Dotatkowo nauczysz się:'} content={[...offerWillLearn].join(', ')} />,
    ],
  };

  // const positionMethodology = {
  //   title: 'Metodologia stanowiska',
  //   content: offerMethodology.map((item) => (
  //     <BoxListItem label={item.substring(0, item.indexOf('-'))} content={item.substring(item.indexOf('-') + 1)} />
  //   )),
  // };

  const positionMethodology = {
    title: 'Metodyka pracy',
    content: offerMethodology.map((item) => <BoxListItem label={item} />),
  };

  const growthPosibility = {
    title: 'Możliwości rozwoju',
    content: offerGrowthOptions.map((item) => <BoxListItem label={item} />),
  };

  const dailyTasks = {
    title: 'Codzienne zadania',
    content: offerDailTasks.map((item) => <BoxListItem label={item} />),
  };

  const offerLanguages = {
    title: 'Wybrane języki obce',
    content: offerRequiredLanguages.map((item) => <BoxListItem label={item} />),
  };

  const extraInfo = {
    title: 'Dodatkowe informacje',
    content: [<BoxListItem label={'Z kim będziesz pracować:'} content={[...offerCollaborators].join(', ')} />],
  };

  return (
    <ProfileLayout>
      <div className={classes.container}>
        <Box mb={6} />
        <div className={classes.header}>
          <Typography
            variant={'body2'}
            className={classes.headerLinkBack}
            onClick={() => history.push(ProfilePaths.jobOffers)}
          >
            {'< Powrót'}
          </Typography>
          <Box mb={1.25} />
          <Typography variant="h2" className={classes.title}>
            {offerPosition}
          </Typography>
          <Box mb={1.25} />
          <BoxSubtitle
            items={[
              { label: 'Kategoria:', value: offerCategory },
              { label: 'Poziom:', value: offerExperience },
            ]}
          />
        </div>
        <Box mb={2} />
        <div className={classes.main}>
          <JobOfferViewPanelLeft
            BOX_ACCEPT_DATA={BOX_ACCEPT_DATA}
            BOX_PROGRESS_DATA={BOX_PROGRESS_DATA}
            BOX_TIME_PLACE_DATA={BOX_TIME_PLACE_DATA}
            BOX_PERKS_DATA={BOX_PERKS_DATA}
            BOX_SOCIALMEDIA_DATA={BOX_SOCIALMEDIA_DATA}
            jobOfferRef={jobOfferRef}
            collectionType={collectionType}
          />
          <JobOfferViewPanelRight
            positionDescription={positionDescription}
            aboutCompany={aboutCompany}
            positionTechnologies={positionTechnologies}
            positionMethodology={positionMethodology}
            growthPosibility={growthPosibility}
            dailyTasks={dailyTasks}
            offerLanguages={offerLanguages}
            extraInfo={extraInfo}
          />
        </div>
      </div>
    </ProfileLayout>
  );
};
