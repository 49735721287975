import { JobOffer } from 'context/jobOffer/JobOfferContext.types';
import { FirebaseBasicResponse } from 'api/Firebase.types';
import { JobOfferBoxDetailsItem } from 'ui/jobOfferBox/jobOfferBoxDetails/JobOfferBoxDetails.types';

export function createJobOfferBoxDetailsItemFromJobOffer(
  job: JobOffer,
  actionView: (job: JobOffer) => void,
  actionAllow: (job: JobOffer) => Promise<FirebaseBasicResponse>,
  actionAbandon: (job: JobOffer) => Promise<FirebaseBasicResponse>,
): JobOfferBoxDetailsItem {
  return {
    title: job.position,
    progress: +job.score,
    company: job.company,
    salaryRangeFrom: job.salaryRangeFrom + '',
    salaryRangeTo: job.salaryRangeTo + '',
    contractType: job.contractType,
    companyCity: job.companyCity,
    mainTechnology: job.mainTechnologies[0],
    offerStatus: job.offerStatus,
    currency: job.currency,
    salaryOnHand: job?.salaryOnHand,
    isNetto: job?.isNetto,
    actionView: () => actionView(job),
    actionAllow: () => actionAllow(job),
    actionAbandon: () => actionAbandon(job),
  };
}
