import React from 'react';
import { useTheme } from '@material-ui/core';

import { useStyles } from './BaseAuthLayout.styles';

export const BaseAuthLayout: React.FC = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return <div className={classes.wrapper}>{children}</div>;
};
