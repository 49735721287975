/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import moment from 'moment';
import { Box, Typography, useTheme } from '@material-ui/core';
import { ResponsiveContainer, ComposedChart, Area, Legend, Tooltip, XAxis } from 'recharts';

import { PathChartProps } from './PathChart.types';
import { useStyles } from './PathChart.styles';

export const PathChart: React.FC<PathChartProps> = ({ data, careerPathCategory }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const colorPalette = ['#432967', '#782D65', '#C3305D', '#F0445E', '#FD9367', '#FDCD83'].reverse();

  const seriesIds = Object.keys(data[0]).filter((element) => element !== 'timestamp');

  const formatXAxis = (tickItem: number) => {
    return moment(tickItem).format('MMM Do YYYY');
  };

  return (
    <Box className={classes.wrapper}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={data}>
          <Tooltip labelFormatter={formatXAxis} />

          {seriesIds.map((element, index) => {
            const color = colorPalette[index % colorPalette.length];

            return <Area dataKey={element} r={3} fill={color} stroke={color} strokeWidth={3} dot={{ r: 3 }} />;
          })}

          <Legend
            verticalAlign="bottom"
            content={
              <Typography className={classes.legend}>
                {seriesIds.map((element, index) => {
                  const color = colorPalette[index % colorPalette.length];

                  return (
                    <>
                      <Box className={classes.circle} style={{ borderColor: color, backgroundColor: color }} />
                      {element}
                      {index < seriesIds.length - 1 && <Box mr={3} />}
                    </>
                  );
                })}
              </Typography>
            }
          />
          <XAxis
            dataKey="timestamp"
            domain={['auto', 'auto']}
            type="number"
            tickFormatter={formatXAxis}
            padding={{ left: 3, right: 3 }}
            scale="time"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};
