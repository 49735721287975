import React from 'react';
import { ButtonDefault, Link } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';
import { PlatformSelect } from '@hrme/shared';

import { MainAuthLayout } from 'ui/layouts/mainAuthLayout/MainAuthLayout';
import { AuthenticationPaths } from 'routing/auth/AuthenticationPaths';
import { Loader } from 'ui/loader/Loader';

import { EmailConfirmationProps } from './EmailConfirmation.types';
import { useStyles } from './EmailConfirmation.styles';

export const EmailConfirmation: React.FC<EmailConfirmationProps> = ({
  title,
  subtitle,
  comment,
  caption,
  onCaptionClicked,
  noEmailMessage = false,
  loading = false,
  platformSelect,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const setThisTextBold = 'link aktywacyjny';
  const boldText = subtitle?.replace(setThisTextBold, '<b>' + setThisTextBold + '</b>');

  if (platformSelect)
    return (
      <MainAuthLayout>
        <PlatformSelect />
      </MainAuthLayout>
    );

  return (
    <MainAuthLayout>
      <Typography variant="h4" className={classes.header}>
        {title}
      </Typography>
      <Box mb={3} />
      <Typography variant="subtitle1" className={classes.subheader}>
        <span dangerouslySetInnerHTML={{ __html: boldText }} />
      </Typography>
      {comment && (
        <Typography variant="subtitle1" className={classes.subheader}>
          {comment}
        </Typography>
      )}
      {caption && <Box mb={4} />}
      {caption && (!loading ? <ButtonDefault onClick={onCaptionClicked}>{caption}</ButtonDefault> : <Loader />)}
      {noEmailMessage && (
        <>
          <Box mb={4} />
          <Typography variant="body2">
            E-mail nie dotarł?{' '}
            <Link href={AuthenticationPaths.resetPassword} className={classes.link}>
              Wyślij ponownie
            </Link>
          </Typography>
        </>
      )}
    </MainAuthLayout>
  );
};
