export const MainPaths = {
  notFound: '/not-found',
  welcomeMain: '/welcome-main',
  position: '/position',
  specialization: '/specialization',
  availability: '/availability',
  experience: '/experience',
  softSkills: '/soft-skills',
  technology: '/technology',
  education: '/education',
  schools: '/schools',
  portfolioSocialMedia: '/portfolio-social-media',
  fillData: '/fill-data',
  congratulations: '/congratulations',
  languages: '/languages',
  blog: '/blog',
  facebook: '/facebook',
};
