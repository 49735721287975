import { CareerPathStepSummary } from 'ui/careerPath/careerSteps/careerPathStepSummary/CareerPathStepSummary.types';
import { CareerPathStepDetails } from 'app/careerPathView/careerPathViewPanelLeft/careerPathStepDetails/CareerPathStepDetails.types';

export type CareerPathViewProps = {
  placebo?: string;
};

export type CareerPathViewRouteParams = {
  id: string;
};

export type CareerPathData = {
  summary: CareerPathStepSummary;
  details: CareerPathStepDetails;
};

export enum ViewSelect {
  CAREER_PATH = 'Ścieżka rozwoju',
  EDIT_DATA = 'Edycja danych',
}
