import React, { useEffect } from 'react';
import { ButtonDefault, Link } from '@hrme/shared';
import { Box, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router';

import { MainFlowLayout } from 'ui/layouts/mainFlowLayout/MainFlowLayout';
import { RadioChipGroup } from 'ui/inputs/radioChipGroup/RadioChipGroup';
import { Topic } from 'ui/topic/Topic';

import { EDUCATION_ITEMS, EducationTypes } from './Education.types';
import { useStyles } from './Education.styles';

export const Education: React.FC<EducationTypes> = ({ methods, onFormSubmit }) => {
  const { handleSubmit } = methods;
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const stepBack = () => {
    onFormSubmit(methods.getValues(), true);
    history.goBack();
  };

  const educationSelected = methods.watch('education');

  useEffect(() => {
    onFormSubmit(methods.getValues(), undefined, true);
  }, [educationSelected]);

  return (
    <>
      <MainFlowLayout topic={<Topic />} progress={{ label: 'Wykształcenie', length: 10, current: 7 }}>
        <form className={classes.container} onSubmit={handleSubmit((d) => onFormSubmit(d))}>
          <Typography variant="h2" className={classes.header}>
            Twoje wykształcenie
          </Typography>
          <Box mb={3} />
          <Typography variant="body2" className={classes.subheader}>
            Podaj swoje wykształcenie.
          </Typography>
          <Box mb={4.5} />
          <RadioChipGroup label={''} items={EDUCATION_ITEMS} name="education" methods={methods} noOutline />
          <Box mb={4} />
          <div className={classes.wrapper}>
            <ButtonDefault disabled={!educationSelected}>następny krok (języki obce)</ButtonDefault>
            <Box mb={2} />
            <Link>
              <Typography onClick={stepBack}>cofnij</Typography>
            </Link>
          </div>
        </form>
      </MainFlowLayout>
    </>
  );
};
