export const SettingsPaths = {
  profile: '/profile',
  experience: '/experience',
  softSkills: '/soft-skills',
  education: '/education',
  certificates: '/certificates',
  languages: '/languages',
  portfolio: '/portfolio',
  account: '/account',
};
