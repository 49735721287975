import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthenticationPaths } from 'routing/auth/AuthenticationPaths';
import { EmailConfirmation } from 'ui/emailConfirmation/EmailConfirmation';

export const CheckEmail = (): JSX.Element => {
  const history = useHistory();

  const handleCaption = useCallback(() => {
    history.replace(AuthenticationPaths.login);
  }, []);

  return (
    <EmailConfirmation
      title={'Sprawdź swój e-mail'}
      subtitle={'Na Twój adres e-mail został wysłany link aktywacyjny.'}
      comment={'Kliknij w niego, a następnie zaloguj się do platformy.'}
      caption={'zaloguj'}
      onCaptionClicked={handleCaption}
    />
  );
};
