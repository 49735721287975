import { useTheme } from '@material-ui/core';
import firebase from 'firebase';

import { descriptionOfTutorialFromFB } from 'context/discountsOnTutorials/DiscountsOnTutorialsContext.types';

import { useStyles } from './TutorialBox.styled';

export const TutorialBox = ({ tutorial }: { tutorial: descriptionOfTutorialFromFB }): JSX.Element => {
  const { discount, imageURL, link, code, autor, description, name } = tutorial;
  const theme = useTheme();
  const classes = useStyles(theme);
  const set = (str: string): JSX.Element => {
    const firstDotIndex = str.indexOf('.', str.indexOf('.') + 1);
    const penultimateDotIndex = str.lastIndexOf('.');

    if (firstDotIndex !== -1 && penultimateDotIndex !== -1) {
      const firstLine = str.substring(0, firstDotIndex + 1);
      const secondLine = str.substring(firstDotIndex + 1, penultimateDotIndex + 1);
      const thirdLine = str.substring(penultimateDotIndex + 1);

      return (
        <div>
          {firstLine}
          <br />
          {secondLine}
          <br />
          {thirdLine}
        </div>
      );
    } else {
      return <div>{str}</div>;
    }
  };
  const sendAnalytics = () => {
    firebase.analytics().logEvent(`Click link to ${name} tutorial page`);
  };
  const onclickHandler = () => {
    sendAnalytics();
    window.open(link);
  };

  return (
    <div className={classes.root}>
      <div className={classes.imageBox}>
        <img className={classes.image} src={imageURL} alt="Tutorial banner" />
      </div>
      <div className={classes.contentBox}>
        <div className={classes.discountBox}>{discount}</div>
        <div className={classes.name}>{name}</div>
        <div className={classes.description}>{set(description)}</div>
        <div className={classes.autor}>Autor: {autor}</div>
        <div className={classes.codeBox}>
          <div>
            <b>Kod rabatowy:</b> <span className={classes.code}>{code}</span>
          </div>
          <div className={classes.link} onClick={onclickHandler}>
            Strona kursu
          </div>
        </div>
      </div>
    </div>
  );
};
