import { useContext } from 'react';

import { DiscountsOnTutorialsContextProps } from 'context/discountsOnTutorials/DiscountsOnTutorialsContext.types';
import { DiscountsOnTutorialsContext } from 'context/discountsOnTutorials/DiscountsOnTutorialsContext';

export const useDiscountsOnTutorials: () => DiscountsOnTutorialsContextProps = () => {
  const context = useContext(DiscountsOnTutorialsContext);

  if (context === undefined) {
    throw Error('useDiscountsOnTutorials must be used within a TutorialsContext');
  }

  return context;
};
